import _ from 'lodash';
import angular from 'angular';
import EditApiController from './api-modal/edit-api-key.component';

export class ApiKeysComponent {
    $http;
    $uibModal;
    $scope;
    Auth;
    toastr;
    $ngConfirm;

    /* @ngInject */
    constructor($http, $scope, $sce, NgTableParams, $ngConfirm, $uibModal, Auth, toastr) {
        this.$http = $http;
        this.$scope = $scope;
        this.$sce = $sce;
        this.$ngConfirm = $ngConfirm;
        this.$uibModal = $uibModal;
        this.Auth = Auth;
        this.NgTableParams = NgTableParams;
        this.selectedAccount = {};
        this.availableAccounts = [];
        this.filter = '';
        this.newAccount = {};
        this.collection = '';
        this.toastr = toastr;
    }

    $onDestroy() {

    }

    $onInit() {
        const self = this;
        this.currentAccountName = this.Auth.getCurrentAccountSync().name;

        self.keyValid = false;
        // TODO:  Get current account and check  <17-09-19, yourname> //
        self.selectedColumns = [];
        self.cols = [
            {
                title: 'Name',
                field: 'alias',
                show: true,
                sortable: 'alias',
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'ID',
                field: '_id',
                show: true,
                sortable: '_id',
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Active',
                field: 'active',
                show: true,
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Accounts',
                field: 'accounts',
                show: true,
                // sortable: "accounts",
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Copy to Clipboard',
                field: 'clipboard',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
        ];

        self.colValues = {};

        _.forEach(self.cols, (col) => {
            if (col.show) {
                self.selectedColumns.push(col.title);
            }
            self.colValues[col.field] = '';
        });

        // TODO: Refactor the index on the backend
        self.tableParams = new self.NgTableParams(
            {
                page: 1, // start with first page
                count: 10, // count per page
                sorting: {
                    alias: 'asc', // initial sorting
                },
            },
            {
                total: 0,
                getData(params) {
                    let order;
                    const headers = { 'x-js-all-accounts': 'true' };
                    if (params && params.sorting) {
                        order = params.sorting();
                        const dataPromise = self.$http.get('/api/apiKeys', {
                            params: {
                                filter: self.filter.length ? self.filter : undefined,
                                skip: (params.page() - 1) * params.count(),
                                limit: params.count(),
                                by: Object.keys(order)[0],
                                order: order[Object.keys(order)[0]],
                            },
                            headers
                        })
                        const countPromise = self.$http.get('/api/apiKeys/count', {
                            params: {
                                filter: self.filter.length ? self.filter : undefined,
                            },
                            headers
                        })
                        return Promise.all([dataPromise, countPromise])
                            .then((response) => {
                                self.users = response[0].data;
                                self.total = response[1].data;
                                params.total(self.total);
                                return self.users;
                            })
                            .catch((err) => {
                                console.error('Error caught when getting data for users: ', err.data.err);
                            });
                    }
                },
            },
        );
        // this.tableParams.reload();
    }

    onColumnSelected($item, $model) {
        $item.show = true;
    }

    onColumnRemoved($item, $model) {
        $item.show = false;
    }

    applyFilter() {
        this.tableParams.page(1);
        this.tableParams.reload();
    }

    hexToBase64(str) {
        return btoa(String.fromCharCode.apply(
            null,
            str.replace(/\r|\n/g, '')
                .replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
                .replace(/ +$/, '')
                .split(' '),
        ));
    }

    handleDisplay(self, col, apiKey) {
        let html = '';
        switch (col.field) {
        case 'alias': {
            html += "<a ng-click='$ctrl.editDetails(apiKey)'>{{apiKey.alias}}</a>";
            return self.$sce.trustAsHtml(html);
        }
        case '_id': {
            html += '<button uib-tooltip="Copy ID to clipboard" ng-click="$ctrl.copyKey($ctrl.hexToBase64(apiKey._id))" ><span class="fa fa-copy"></span></button>';
            return self.$sce.trustAsHtml(html);
        }
        case 'accounts': {
            html += `<div>
                                <span style="margin-right:0.2em" class="label label-info" ng-repeat="account in apiKey.accounts">
                                    {{account.name}}
                                </span>
                            </div>`;
            return self.$sce.trustAsHtml(html);
        }
        case 'active': {
            if (apiKey.active === true) {
                return '<i ng-show="{{apiKey.active}}" class="fa fa-check text-success"></i>';
            }
            return '<i ng-show="{{!apiKey.active}}" class="glyphicon glyphicon-remove-circle text-danger"></i>';
        }
        case 'clipboard': {
            html += '<button uib-tooltip="Copy key to clipboard" ng-click="$ctrl.copyKey(apiKey.key)" ><span class="fa fa-copy"></span></button>';
            return self.$sce.trustAsHtml(html);
        }
        default:
            // The default cases are those who adopt boolean values
            // "internet || chreosis || ethernet || vpn || usb || wan"
            return apiKey[col.field];
        }
    }

    newAPIKey() {
        const self = this;
        const modalInstance = self.$uibModal.open({
            controller: EditApiController,
            controllerAs: '$ctrl',
            template: require('./api-modal/edit-api-key.html'),
            // bindToController: true,
            size: 'md',
            backdrop: 'static',
            resolve: {
                apiKey() {
                    return {
                        alias: 'API Key', integrationPartner: 'execuguard', accounts: [], active: true,
                    };
                },
                edit() {
                    return false;
                },
            },
        });

        modalInstance.result.then((result) => {
            self.tableParams.reload();
        });
    }

    editDetails(apiKey) {
        const self = this;
        const modalInstance = self.$uibModal.open({
            controller: EditApiController,
            controllerAs: '$ctrl',
            template: require('./api-modal/edit-api-key.html'),
            // bindToController: true,
            size: 'md',
            backdrop: 'static',
            resolve: {
                apiKey() {
                    return _.cloneDeep(apiKey);
                },
                edit() {
                    return true;
                },
            },
        });

        modalInstance.result.then((result) => {
            self.tableParams.reload();
        });
    }

    doLog() {
        console.log(this);
    }

    copyKey(key) {
        const self = this;
        const text_to_share = key;

        // create temp element
        const copyElement = document.createElement('span');
        copyElement.appendChild(document.createTextNode(text_to_share));
        copyElement.id = 'tempCopyToClipboard';
        angular.element(document.body.append(copyElement));

        // select the text
        const range = document.createRange();
        range.selectNode(copyElement);
        window.getSelection()
            .removeAllRanges();
        window.getSelection()
            .addRange(range);

        // copy & cleanup
        document.execCommand('copy');
        window.getSelection()
            .removeAllRanges();
        copyElement.remove();
    }
}

export default angular.module('cameraViewerApp.apiKeys')
    .component('apiKeys', {
        template: require('./apiKeys.html'),
        controller: ApiKeysComponent,
        controllerAs: '$ctrl',
    })
    .name;
