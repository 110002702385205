export default class TelegramAlertsController {
    $http;
    $scope;
    changeToAccount;
    $uibModalInstance;
    asideModalInstance;
    currentSite;
    pauseTime;
    resumeTime;

    /* @ngInject */

    constructor($uibModalInstance, $http, currentSite, toastr, moment, asideModalInstance) {
        this.$http = $http;
        this.$uibModalInstance = $uibModalInstance;
        this.toastr = toastr;
        this.moment = moment;
        this.asideModalInstance = asideModalInstance;
        this.currentSite = currentSite;

        this.availableDurations = [
            {
                data: 2,
                name: '2 hours',
            },
            {
                data: 6,
                name: '6 hours',
            },
            {
                data: 12,
                name: '12 hours',
            },
            {
                data: 24,
                name: '24 hours',
            },
        ];

        [this.selectedDuration] = this.availableDurations;
        this.updateTimes();
    }

    updateTimes() {
        this.pauseTime = this.moment();
        this.resumeTime = this.moment().add(this.selectedDuration.data, 'h');
        this.resumeTimeDate = this.moment(this.resumeTime).format('YY/MM/DD hh:mm A');
    }

    pause() {
        const self = this;
        self.updateTimes();
        self.$uibModalInstance.close({
            noChange: false,
            pauseState: true,
            pauseTime: self.pauseTime.toDate(),
            resumeTime: self.resumeTime.toDate(),
        });
    }

    unpause() {
        const self = this;
        self.$uibModalInstance.close({
            noChange: false,
            pauseState: false,
            pauseTime: undefined,
            resumeTime: undefined,
        });
    }

    cancel() {
        this.$uibModalInstance.close({
            noChange: true,
        });
    }
}
