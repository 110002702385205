export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.biReport', {
            url: '/accDataExport',
            views: {
                'content@main': {
                    template: '<bi-report></bi-report>',
                },
            },
            authenticate: 'Reports',
            data: {
                title: 'Account Data Export',
            },
        });
}
