import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

// import routing from './account.routes'; this won't be necessary
import login from './login';
import reset from './reset';
// import settings from './settings'; NOTE: Moved settings to app
import signup from './signup';
// import projects from './projects'; NOTE: changed to account
import verify from './verify';

export default angular.module('cameraViewerApp.account', [uiRouter, login, signup, reset, verify])
    .run(($rootScope) => {
        'ngInject';

        $rootScope.$on('$stateChangeStart', (event, next, nextParams, current) => {
            if (next.name === 'logout' && current && current.name && !current.authenticate) {
                next.referrer = current.name;
            }
        });
    })
    .name;
