export default class AccountModalController {
    $uibModalInstance;
    $uibModal;
    $http;
    $state;
    modalInstance;

    /* @ngInject */
    constructor($uibModalInstance, $state, $uibModal, $stateParams, $http, $scope, $timeout, moment, toastr) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
        this.moment = moment;
        this.toastr = toastr;
        this.$http = $http;
    }

    $onInit() {
        const self = this;
        self.account = {};
    }

    doLog() {
        // console.log(this.schedule.rruleSet.between(self.moment().subtract(2,'days').toDate(),self.moment().add(7,'days').toDate()));
        console.log(this);
    }

    // Setup all the models for display
    // Modal accept: send resulting object back to promise in parent controller
    ok() {
        const self = this;
        self.$http.post('api/accounts/', self.account).then((result) => {
            if (result.status == 201) {
                self.toastr.info('Account created');
                self.$uibModalInstance.close();
            }
        })
            .catch((err) => {
                console.log(err);
                if (err.status == 406) {
                    self.toastr.warning('Account name already exists', 'Invalid Account Name');
                } else {
                    self.toastr.error('Account create failed');
                    console.error(err);
                }
            });
    }

    // Modal cancelled: either cancel pressed or clicked off screen
    cancel() {
        this.$uibModalInstance.dismiss('Cancel pressed');
    }
}
