import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import routes from './reset.routes';

export default angular.module('cameraViewerApp.reset', [uiRouter])
    .config(routes)
    .name;

require('./reset.password.component');
require('./reset.password.request.component');
