import {
    omit, has, pick, get,
} from 'lodash-es';

const collapsing = {
    lsInput: {
        models: [
            'lsEnabled',
            'lsDateQuota',
            'lsChunkSize',
            'lsOnlyMotion',
            'lsSched',
            'lsSchedDefaults',
            'lsSchedule',
        ],
        camera: [
            'configuration.localstorage.enabled',
            'configuration.localstorage.dateQuota',
            'configuration.localstorage.chunkSize',
            'configuration.localstorage.onlyMotion',
            'configuration.localstorage.localSchedule.enabled',
            'configuration.localstorage.localSchedule.defaults',
            'configuration.localstorage.localSchedule.schedules',
        ],
        zone: [],
    },
    cloudInput: {
        models: [
            'cloudScale',
            'cloudQuality',
            'cloudrotation',
            'cloudSchedEnabled',
            'cloudSchedDefault',
            'cloudSched',
        ],
        camera: [
            'configuration.scaleDown',
            'configuration.jpegquality',
            'configuration.rotation',
            'configuration.cloudSchedule.enabled',
            'configuration.cloudSchedule.defaults',
            'configuration.cloudSchedule.schedules',
        ],
        zone: [],
    },
    motionInput: {
        models: [
            'triggerMotion',
            'minBursts',
            'maxBursts',
            'initialDelay',
            'burstDelay',
            'burstCooloff',
            'motionCool',
            'motionAlg',
            'learningRate',
            'motionScale',
            'deltaThreshold',
            'dilations',
            'erosions',
            'motionMin',
            'motionMax',
            'recordHeatmap',
            'crossLines',
            'lineCrossingTrigger',
            'crossMaxDistance',
            'crossMaxAge',
            'crossMaxTrack',
            'crossEpsilon',
            'linecrossdetect',
        ],
        camera: [
            'configuration.analyticsConfiguration.motionConfiguration.motionTrigger',
            'configuration.analyticsConfiguration.motionConfiguration.minBursts',
            'configuration.analyticsConfiguration.motionConfiguration.maxBursts',
            'configuration.analyticsConfiguration.motionConfiguration.initialDelay',
            'configuration.analyticsConfiguration.motionConfiguration.burstDelay',
            'configuration.analyticsConfiguration.motionConfiguration.burstCooloff',
            'configuration.analyticsConfiguration.motionConfiguration.motionCooloff',
            'configuration.analyticsConfiguration.motionConfiguration.algorithm',
            'configuration.analyticsConfiguration.motionConfiguration.learningRate',
            'configuration.analyticsConfiguration.motionConfiguration.scaledown',
            'configuration.analyticsConfiguration.motionConfiguration.deltaThreshold',
            'configuration.analyticsConfiguration.motionConfiguration.dilations',
            'configuration.analyticsConfiguration.motionConfiguration.erosions',
            'configuration.analyticsConfiguration.motionConfiguration.minarea',
            'configuration.analyticsConfiguration.motionConfiguration.maxarea',
            'configuration.analyticsConfiguration.motionConfiguration.recordHeatmap',
            'configuration.analyticsConfiguration.motionConfiguration.crossLines',
            'configuration.analyticsConfiguration.motionConfiguration.lineCrossingTrigger',
            'configuration.analyticsConfiguration.motionConfiguration.crossMaxDistance',
            'configuration.analyticsConfiguration.motionConfiguration.crossMaxAge',
            'configuration.analyticsConfiguration.motionConfiguration.crossMaxTrack',
            'configuration.analyticsConfiguration.motionConfiguration.crossEpsilon',
            'configuration.analyticsConfiguration.motionConfiguration.lineCrossingDetection',
        ],
        zone: [],
    },
    faceInput: {
        models: [
            'facescaledown',
            'scalefactor',
            'faceMinneighbors',
            'faceMinSize',
            'faceMinConf',
            'faceCountThreshold',
            'cloudAnalytics',
        ],
        camera: [
            'configuration.analyticsConfiguration.faceConfiguration.scaledown',
            'configuration.analyticsConfiguration.faceConfiguration.scalefactor',
            'configuration.analyticsConfiguration.faceConfiguration.minneighbors',
            'configuration.analyticsConfiguration.faceConfiguration.minsize',
            'configuration.analyticsConfiguration.faceConfiguration.minsize',
            'configuration.analyticsConfiguration.faceConfiguration.minConfidence',
            'configuration.analyticsConfiguration.faceConfiguration.countThresholdEnabled',
            'configuration.analyticsConfiguration.faceConfiguration.countThreshold',
        ],
        zone: [
            'analyticsConfiguration.faceConfiguration.scaledown',
            'analyticsConfiguration.faceConfiguration.minsize',
            'analyticsConfiguration.faceConfiguration.minConfidence',
            'cloudAnalytics',
        ],
    },
    peopleInput: {
        models: [
            'peopleMinSize',
            'peopleMinConf',
            'peopleCountThreshold',
            'cloudAnalyticsPeople',
            'peoplescaledown',
        ],
        camera: [
            'configuration.analyticsConfiguration.peopleConfiguration.scaledown',
            'configuration.analyticsConfiguration.peopleConfiguration.minsize',
            'configuration.analyticsConfiguration.peopleConfiguration.minConfidence',
            'configuration.analyticsConfiguration.peopleConfiguration.countThresholdEnabled',
            'configuration.analyticsConfiguration.peopleConfiguration.countThreshold',
        ],
        zone: [
            'analyticsConfiguration.peopleConfiguration.scaledown',
            'analyticsConfiguration.peopleConfiguration.minsize',
            'analyticsConfiguration.peopleConfiguration.minConfidence',
            'cloudAnalyticsPeople',
        ],
    },
    edgeInput: {
        models: [
            'edgeFaceEnabled',
            'edgeFaceAlgorithm',
            'edgeFaceTrigger',
            'edgePeopleTrigger',
            'edgePeopleEnabled',

            'edgeVehicleEnabled',
            'vehicleTrigger',

            'edgeHumanPoseEnabled',
            'edgeHumanPoseTrigger',

            'edgeSSDEnabled',
            'edgeSSDTrigger',
        ],
        camera: [
            'configuration.analyticsConfiguration.detectfaces',
            'configuration.analyticsConfiguration.faceConfiguration.triggerOn',
            'configuration.analyticsConfiguration.faceConfiguration.algorithm',

            'configuration.analyticsConfiguration.peopleConfiguration.enabled',
            'configuration.analyticsConfiguration.peopleConfiguration.triggerOn',

            'configuration.analyticsConfiguration.vehicleConfiguration.enabled',
            'configuration.analyticsConfiguration.vehicleConfiguration.triggerOn',

            'configuration.analyticsConfiguration.humanPoseConfiguration.enabled',
            'configuration.analyticsConfiguration.humanPoseConfiguration.triggerOn',

            'configuration.analyticsConfiguration.ssdConfiguration.enabled',
            'configuration.analyticsConfiguration.ssdConfiguration.triggerOn',
        ],
        zone: [],
    },
    humanPoseInput: {
        models: [
            'humanPoseMinSize',
            'humanPoseMinConf',
            'poseCountThreshold',
            'humanPoseFilter',
            'humanPoseMinClassConf',
            'cloudAnalyticsPose',
            'humanPosescaledown',
        ],
        camera: [
            'configuration.analyticsConfiguration.humanPoseConfiguration.scaledown',
            'configuration.analyticsConfiguration.humanPoseConfiguration.minsize',
            'configuration.analyticsConfiguration.humanPoseConfiguration.minConfidence',
            'configuration.analyticsConfiguration.humanPoseConfiguration.countThresholdEnabled',
            'configuration.analyticsConfiguration.humanPoseConfiguration.countThreshold',
            'configuration.analyticsConfiguration.humanPoseConfiguration.poseFilter',
            'configuration.analyticsConfiguration.humanPoseConfiguration.minClassConfidence',
        ],
        zone: [
            'analyticsConfiguration.humanPoseConfiguration.scaledown',
            'analyticsConfiguration.humanPoseConfiguration.minsize',
            'analyticsConfiguration.humanPoseConfiguration.minConfidence',
            'cloudAnalyticsPose',
        ],
    },
    ssdInput: {
        models: [
            'ssdMinSize',
            'ssdMinConf',
            'cloudAnalyticsSSD',
            'ssdscaledown',
        ],
        camera: [
            'configuration.analyticsConfiguration.ssdConfiguration.scaledown',
            'configuration.analyticsConfiguration.ssdConfiguration.minsize',
            'configuration.analyticsConfiguration.ssdConfiguration.minConfidence',
        ],
        zone: [
            'analyticsConfiguration.ssdConfiguration.scaledown',
            'analyticsConfiguration.ssdConfiguration.minsize',
            'analyticsConfiguration.ssdConfiguration.minConfidence',
            'cloudAnalyticsSSD',
        ],
    },
    vehicleInput: {
        models: [
            'vehicleMinSize',
            'vehicleMinConf',
            'vehicleCountThreshold',
            'cloudAnalyticsVehicle',
            'vehiclescaledown',
        ],
        camera: [
            'configuration.analyticsConfiguration.vehicleConfiguration.scaledown',
            'configuration.analyticsConfiguration.vehicleConfiguration.minsize',
            'configuration.analyticsConfiguration.vehicleConfiguration.minConfidence',
            'configuration.analyticsConfiguration.vehicleConfiguration.countThresholdEnabled',
            'configuration.analyticsConfiguration.vehicleConfiguration.countThreshold',
        ],
        zone: [
            'analyticsConfiguration.vehicleConfiguration.scaledown',
            'analyticsConfiguration.vehicleConfiguration.minsize',
            'analyticsConfiguration.vehicleConfiguration.minConfidence',
            'cloudAnalyticsVehicle',
        ],
    },
    lprInput: {
        models: [
            'edgeLPR',
            'lprTrigger',
            'lprAnalytics',
            'country',
            'countryList',
        ],
        camera: [
            'configuration.analyticsConfiguration.lprConfiguration.edgeLPR',
            'configuration.analyticsConfiguration.lprConfiguration.lprTrigger',
        ],
        zone: [
            'lprAnalytics',
            'country',
            'countryList',
        ],
    },
    liveInput: {
        models: [
            'liveforcebase',
            'liveReencode',
            'encodingType',
            'livestreamScale',
            'livestreamFrameLimit',
            'livestreamHWEnc',
            'recordAudio',
        ],
        camera: [
            'configuration.liveStreamConfiguration.forcebaseline',
            'configuration.liveStreamConfiguration.reencode',
            'configuration.liveStreamConfiguration.encodingType',
            'configuration.liveStreamConfiguration.scaleDown',
            'configuration.liveStreamConfiguration.frameRateLimit',
            'configuration.liveStreamConfiguration.hardwareencoding',
            'configuration.liveStreamConfiguration.recordAudio',
        ],
        zone: [],
    },
};

export default class TemplateSaveController {
    $uibModalInstance;
    $scope;
    $http;
    Auth;
    selectedZone;
    loadTemplate;
    multiTemplate;

    /* @ngInject */
    constructor($scope, $document, $uibModalInstance, $http, socket, Auth, $uibModal, selectedZone, loadTemplate, multiTemplate, appConfig, $timeout, unit) {
        this.$uibModalInstance = $uibModalInstance;
        this.$document = $document;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.socket = socket;
        this.unit = unit;
        this.selectedZone = selectedZone;
        this.loadTemplate = loadTemplate;
        this.multiTemplate = multiTemplate;
        this.Auth = Auth;
        this.$http = $http;
        this.$uibModal = $uibModal;
        this.motionRulesVariable = 'Milliseconds';
        this.availableTime = ['Milliseconds', 'Seconds'];
        this.availableTemplates = [];
        this.omitList = {
            zone: [],
            camera: [
                'configuration.localstorage.localSchedule.defaultState',
                'configuration.cloudSchedule.defaultState',
                'configuration.localstorage.sizeQuota',
            ],
        };
        this.faceAlgorithms = [
            {
                data: 'OpenCVHaarCascades',
                name: 'OpenCVHaarCascades',
            },
            {
                data: 'DlibHOG',
                name: 'DlibHOG',
            },
            {
                data: 'Openvino',
                name: 'Custom',
            },
        ];
        this.countryOptions = appConfig.default.countryList;
        this.templateZonePick = appConfig.default.templateZonePick;
        this.templateCameraPick = appConfig.default.templateCameraPick;
        this.liveStreamEncodingOptions = [
            'VP8',
            // "H264",
            // "MPEG4",
            // "JPEG",
            // "YUYV422",
            // "MJPEG",
            // "H265"
        ];

        this.poseClassifications = [
            {
                key: 'laying',
                text: 'Laying Down',
            },
            {
                key: 'handsup',
                text: 'Hands Up',
            },
            {
                key: 'crawling',
                text: 'Crawling',
            },
            {
                key: 'standing',
                text: 'Standing',
            },
            {
                key: 'sitting',
                text: 'Sitting',
            },
        ];

        this.limitTo = new Array(3);
        this.limitTo[0] = 'All';
        this.limitTo[1] = 'Motion';
        this.limitTo[2] = 'None';

        this.limitToCloud = new Array(2);
        this.limitToCloud[0] = 'Motion & Lines';
        this.limitToCloud[1] = 'Lines';
        this.limitToCloud[2] = 'Motion';
        this.limitToCloud[3] = 'None';
    }

    doLog() {
        console.log('DEBUG:', this);
    }

    $onInit() {
        const self = this;

        if (!self.loadTemplate) {
            self.initSaveTemplate();
        } else {
            self.initLoadTemplates();
        }
    }

    initSaveTemplate() {
        const self = this;
        self.saveTemplate = {
            name: self.selectedZone.alias,
            description: 'No Description',
        };
        self.checkCapabilities();
        const localSched = get(self.selectedZone, 'camera.configuration.localstorage.localSchedule');
        const cloudSched = get(self.selectedZone, 'camera.configuration.cloudSchedule');

        if (localSched) {
            self.localstorageDefaultState = self.limitFlagsToString(localSched.defaults.motion, localSched.defaults.nonmotion, localSched.defaults.lines);
        }
        if (cloudSched) {
            self.cloudDefaultState = self.limitFlagsToString(cloudSched.defaults.motion, cloudSched.defaults.nonmotion, cloudSched.defaults.lines);
        }
    }

    templateSelected(template) {
        const self = this;
        self.$timeout(() => {
            self.selectedZone = {
                ...template.zoneConfig,
                camera: template.cameraConfig,
                unit: self.unit,
            };

            const localSched = get(self.selectedZone, 'camera.configuration.localstorage.localSchedule');
            const cloudSched = get(self.selectedZone, 'camera.configuration.cloudSchedule');

            if (localSched) {
                self.localstorageDefaultState = self.limitFlagsToString(localSched.defaults.motion, localSched.defaults.nonmotion, localSched.defaults.lines);
            }
            if (cloudSched) {
                self.cloudDefaultState = self.limitFlagsToString(cloudSched.defaults.motion, cloudSched.defaults.nonmotion, cloudSched.defaults.lines);
            }
        }, 0);
    }

    initLoadTemplates() {
        const self = this;
        self.$http.get('/api/templates')
            .then((response) => {
                self.availableTemplates = response.data;
            }, (err) => {
                if (err.status !== 401 && err.status !== 403) console.error(err);
            });
    }

    checkCapabilities() {
        const self = this;
        // We need to check for the following:
        //* Can we do edge LPR? If not, omit certain fields
        //* Can we do Openvino edge analytics? If not, omit
        //* Do we have privileges to configure certain features, if not, omit..

        const caps = [
            {
                cap: get(self.selectedZone, 'camera.deviceInfo.lprSupport'),
                fields: {
                    camera: [
                        'configuration.analyticsConfiguration.lprConfiguration.edgeLPR',
                        'configuration.analyticsConfiguration.lprConfiguration.lprTrigger',
                    ],
                    zone: [],
                },
            },
            {
                cap: get(self.selectedZone, 'unit.capabilities.openvino_people'),
                fields: {
                    camera: [
                        'configuration.analyticsConfiguration.peopleConfiguration',
                    ],
                    zone: [],
                },
            },
            {
                cap: get(self.selectedZone, 'unit.capabilities.openvino_vehicle'),
                fields: {
                    camera: [
                        'configuration.analyticsConfiguration.vehicleConfiguration',
                    ],
                    zone: [],
                },
            },
        ];

        caps.forEach((o) => {
            if (!o.cap) {
                self.omitList.camera = self.omitList.camera.concat(o.fields.camera);
                self.omitList.zone = self.omitList.zone.concat(o.fields.zone);
            }
        });

        const privs = [
            {
                priv: 'secuvue.SiteView.Settings.Zones.Analytics.PeopleDetection',
                fields: {
                    zone: [
                        'analyticsConfiguration.peopleConfiguration',
                    ],
                    camera: [
                        'configuration.analyticsConfiguration.peopleConfiguration',
                    ],
                },
            },
            {
                priv: 'secuvue.SiteView.Settings.Zones.Analytics.VehicleDetection',
                fields: {
                    zone: [
                        'analyticsConfiguration.vehicleConfiguration',
                    ],
                    camera: [
                        'configuration.analyticsConfiguration.vehicleConfiguration',
                    ],
                },
            },
        ];
        privs.forEach((o) => {
            if (!self.Auth.hasRoleSync(o.priv)) {
                self.omitList.zone = self.omitList.zone.concat(o.fields.zone);
                self.omitList.camera = self.omitList.camera.concat(o.fields.camera);
            }
        });
    }

    limitFlagsToString(motion, nonMotion, lines) {
        if (motion && nonMotion && lines) return 'All';
        if (motion && !nonMotion && !lines) return 'Motion';
        if (motion && !nonMotion && lines) return 'Motion & Lines';
        if (!motion && !nonMotion && lines) return 'Lines';
        return 'None';
    }

    applyTemplate(template) {
        const self = this;

        const zoneOmit = self.omitList.zone.concat(['camera', 'unit', 'site']);

        let camera = omit(template.camera, self.omitList.camera);
        camera = pick(camera, self.templateCameraPick);

        let zone = omit(template, zoneOmit);
        zone = pick(zone, self.templateZonePick);

        const temp = {
            cameraConfig: camera,
            zoneConfig: zone,
        };

        this.$uibModalInstance.close({
            loadTemplate: temp,
            multi: self.multiTemplate,
        });
    }

    createTemplate() {
        const self = this;
        const zoneOmit = self.omitList.zone.concat(['camera', 'unit', 'site']);

        let camera = omit(self.selectedZone.camera, self.omitList.camera);
        camera = pick(camera, self.templateCameraPick);

        let zone = omit(self.selectedZone, zoneOmit);
        zone = pick(zone, self.templateZonePick);

        return self.$uibModalInstance.close({
            saveTemplate: {
                zone,
                camera,
                name: self.saveTemplate.name,
                description: self.saveTemplate.description,
            },
        });
    }

    hasProp(o, prop) {
        const self = this;
        if (self.loadTemplate) {
            return has(o, prop);
        }
        return true;
    }

    checkVar(val, cam, variable) {
        const self = this;
        if (cam === 'zone') {
            if (val === false) {
                self.omitList.zone.push(variable);
            } else {
                const ind = self.omitList.zone.indexOf(variable);
                if (ind > -1) {
                    self.omitList.zone.splice(ind, 1);
                }
            }
        } else if (cam === 'camera') {
            if (val === false) {
                self.omitList.camera.push(variable);
            } else {
                const ind = self.omitList.camera.indexOf(variable);
                if (ind > -1) {
                    self.omitList.camera.splice(ind, 1);
                }
            }
        }
    }

    checkPrivVar(val, cam, variable, priv) {
        const self = this;
        if (self.Auth.hasRoleSync(priv)) {
            self.checkVar(val, cam, variable);
        }
    }

    selectAll(className, bool) {
        const self = this;

        collapsing[className].models.forEach((mod) => {
            self[mod] = bool;
        });

        collapsing[className].camera.forEach((field) => {
            // Make sure every field is removed from omit list
            const ind = self.omitList.camera.indexOf(field);
            if (bool === false) {
                if (ind === -1) {
                    self.omitList.camera.push(field);
                }
            } else if (ind !== -1) {
                self.omitList.camera.splice(ind, 1);
            }
        });

        collapsing[className].zone.forEach((field) => {
            // Make sure every field is added to omit list
            const ind = self.omitList.zone.indexOf(field);
            if (bool === false) {
                if (ind === -1) {
                    self.omitList.zone.push(field);
                }
            } else if (ind !== -1) {
                self.omitList.zone.splice(ind, 1);
            }
        });
    }
}
