import angular from 'angular';

export class VerifyEmailComponent {
    $stateParams;
    User;
    token;
    uid;
    verified = false;
    verifying = false;

    /* @ngInject */
    constructor($stateParams, Auth, $window, $http) {
        this.$stateParams = $stateParams;
        this.Auth = Auth;
        this.$window = $window;
        this.$http = $http;
        this.uid = $stateParams.userId;
        this.token = $stateParams.token;

        if (this.token && this.uid) {
            this.verifying = true;
            this.Auth.verifyEmail(this.uid, this.token)
                .then(() => {
                    this.verified = true;
                    this.verifying = false;
                })
                .catch((err) => {
                    console.log('HELLO')
                    console.error(err);
                    this.verifying = false;
                });
        }
    }

    $onInit() {
        this.hostnameIcon = `assets/images/domainicons/${this.$window.location.hostname}.png`;
        this.$http
            .get(`assets/images/domainicons/${this.$window.location.hostname}.png`)
            .then((data) => {
                this.hostnameIcon = `assets/images/domainicons/${this.$window.location.hostname}.png`;
            })
            .catch((err) => {
                //
                this.hostnameIcon = 'assets/images/domainicons/secuvue.net.png';
            });
    }
}

export default angular.module('cameraViewerApp.verify').component('verifyemail', {
    template: require('./verify.email.html'),
    controller: VerifyEmailComponent,
    controllerAs: '$ctrl',
}).name;
