import angular from 'angular';
import uiSelect from 'ui-select';
import ngAside from 'angular-aside';
import uiRouter from '@uirouter/angularjs';
import uiBootstrap from 'angular-ui-bootstrap';

import ngSticky from 'ngsticky';
import uiScroll from 'angular-ui-scroll';
import uiScrollGrid from 'angular-ui-scroll/dist/ui-scroll-grid';
import schedule from '../../components/schedule/schedule.component';
import routes from './sites.routes';

export default angular.module(
    'cameraViewerApp.sites',
    [
        uiRouter,
        uiSelect,
        ngAside,
        uiBootstrap,
        'sticky',
        'ui-select-infinity',
        'ui.scroll',
        'ui.scroll.grid',
        'angularMoment',
        'ngDragDrop',
        schedule,
    ],
)
    .config(routes)
    .name;

require('./sites.component');
require('./new-site/new-site.component');
