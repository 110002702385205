import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import moment from 'moment';
import uiScroll from 'angular-ui-scroll';
import uiScrollGrid from 'angular-ui-scroll/dist/ui-scroll-grid';
import _ from 'lodash-es';
import angularMoment from 'angular-moment';
import { Dropzone } from 'dropzone';
import routes from './reference.routes';
import 'ngdropzone';

export default angular.module('cameraViewerApp.reference', [
    uiRouter,
    'thatisuday.dropzone',
    'angularMoment',
])
    .config(routes)
    .name;

require('./reference.component');
