import angular from 'angular';

/*
Automatic phone number formatting in input field

Credits:
    - Wade Tandy via
    http://stackoverflow.com/questions/19094150/using-angularjs-directive-to-format-input-field-while-leaving-scope-variable-unc

    - kstep via
    http://stackoverflow.com/questions/12700145/how-to-format-a-telephone-number-in-angularjs

    - hans via
    https://codepen.io/hans/details/uDmzf/
*/

angular.module('cameraViewerApp.directives')
    .directive('phoneInput', ($filter, $browser) => ({
        require: 'ngModel',
        link($scope, $element, $attrs, ngModelCtrl) {
            const listener = function () {
                const value = $element.val().replace(/[^0-9]/g, '');
                $element.val($filter('tel')(value, false));
            };

            // This runs when we update the text field
            ngModelCtrl.$parsers.push((viewValue) => viewValue.replace(/[^0-9]/g, '').slice(0, 10));

            // This runs when the model gets updated on the scope directly and keeps our view in sync
            ngModelCtrl.$render = function () {
                $element.val($filter('tel')(ngModelCtrl.$viewValue, false));
            };

            $element.bind('change', listener);
            $element.bind('keydown', (event) => {
                const key = event.keyCode;
                // If the keys include the CTRL, SHIFT, ALT, or META keys, or the arrow keys, do nothing.
                // This lets us support copy and paste too
                if (key == 91 || key > 15 && key < 19 || key >= 37 && key <= 40) {
                    return;
                }
                $browser.defer(listener); // Have to do this or changes don't get picked up properly
            });

            $element.bind('paste cut', () => {
                $browser.defer(listener);
            });
        },

    }));

angular.module('cameraViewerApp.directives')
    .filter('tel', () => function (tel) {
        //  console.log(tel);
        if (!tel) {
            return '';
        }

        const value = tel.toString().trim()
            .replace(/^\+/, '');

        if (value.match(/[^0-9]/)) {
            return tel;
        }

        let country; let city; let
            number;

        switch (value.length) {
        case 1:
        case 2:
        case 3:
            city = value;
            break;

        default:
            city = value.slice(0, 3);
            number = value.slice(3);
        }

        if (number) {
            if (number.length > 3) {
                number = `${number.slice(0, 3)}-${number.slice(3, 7)}`;
            } else {
                number = number;
            }

            return `(${city}) ${number}`.trim();
        }
        return `(${city}`;
    });
