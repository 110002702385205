export default function routes($stateProvider) {
    'ngInject';

    $stateProvider.state('main.live', {
        url: '/live?before&after&pagestart&pagesize&filter',
        params: {
            before: { dynamic: true },
            after: { dynamic: true },
            pagestart: { dynamic: true },
            pagesize: { dynamic: true },
            filter: { dynamic: true },
        },
        views: {
            'content@main': {
                template: '<live></live>',
            },
        },
        data: {
            title: 'Account View',
        },
        authenticate: true,
    });
}
