import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import uiBootstrap from 'angular-ui-bootstrap';

import Restangular from 'restangular';
import uiSelect from 'ui-select';
import ngSanitize from 'angular-sanitize';
import NgTable from 'ng-table/bundles/ng-table';
import socket from '../../components/socket/socket.service';
import routes from './apiCallbacks.routes';

export default angular.module('cameraViewerApp.apiCallbacks', [uiRouter, uiBootstrap, Restangular,
    uiSelect, 'ngSanitize', 'ngTable'])
    .config(routes)
    .name;

require('./apiCallbacks-list/apiCallbacks.list.component');
require('./apiCallbacks-list/new-apiCallback/new-apiCallback-modal.component');
