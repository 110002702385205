export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.units', {
            url: '/units',
            views: {
                'content@main': {
                    template: '<units></units>',
                },
            },
            authenticate: 'UnitManagement',
            data: {
                title: 'Units',
            },
        });
}
