export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.operationalReport', {
            url: '/operationalReport',
            views: {
                'content@main': {
                // Remember that camel case gets changed to dash-case
                // ie. operationalReport becomes operational-report
                    template: '<operational-report></operational-report>',
                },
            },
            authenticate: 'OperationalReports',
            data: {
                title: 'Snapshot Reports',
            },
        });
}
