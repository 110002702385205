import _ from "lodash";

export default class AddCamerasComponent {
    $scope;
    $http;
    $uibModalInstance;
    Auth;

    /* @ngInject */
    constructor($scope, $http, $uibModalInstance, $uibModal, $timeout, socket, Auth, currentCameras) {
        this.$scope = $scope;
        this.$http = $http;
        this.$uibModalInstance = $uibModalInstance;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
        this.socket = socket;
        this.Auth = Auth;
        this.currentCameras = currentCameras;
        this.selectedSite = undefined;
        this.availableCams = [];
        this.commonCameras = [];
        this.camsToAdd = [];
    }

    $onInit() {
        const self = this;

        self.$http.get('/api/sites/lite')
            .then((response) => {
                self.availableSites = _.filter(response.data, (o) => o.initialized && o.configured);
            });
    }

    getCameras() {
        const self = this;
        self.camsToAdd = [];
        self.commonCameras = [];
        self.availableCams = [];
        self.$http.get(`/api/cameras/dashCams/${self.selectedSite._id}`)
            .then((response) => {
                self.availableCams = response.data.zones;
                self.currentCameras.forEach((cam) => {
                    // Remove already selected cameras from options
                    const commonIndex = _.findIndex(self.availableCams, (o) => o._id === cam._id)
                    if (commonIndex >= 0) {
                        self.commonCameras.push(self.availableCams[commonIndex]);
                        self.availableCams.splice(commonIndex, 1);
                    }
                });
            });
    }

    addRemaining() {
        this.camsToAdd = [... this.availableCams];
    }

    sendChanges() {
        this.$uibModalInstance.close({ camsToAdd: this.camsToAdd });
    }

    doLog() {
        console.log('DEBUG:', this);
    }
}
