angular.module('SmartAdmin.Layout', []);
// Actions
require('./actions/fullScreen');
require('./actions/minifyMenu');
require('./actions/reloadState');
require('./actions/resetWidgets');
require('./actions/searchMobile');
require('./actions/toggleMenu');

require('./directives/navigation/smartMenu');
require('./directives/navigation/smartMenuItems');

require('./directives/widgets/jarvisWidget');
require('./directives/widgets/widgetGrid');

require('./directives/bigBreadcrumbs');
require('./directives/dismisser');
require('./directives/hrefVoid');
require('./directives/radioToggle');
require('./directives/smartDeviceDetect');
require('./directives/smartFastClick');
require('./directives/smartFitAppView');
require('./directives/smartInclude');
require('./directives/smartLayout');
require('./directives/smartPageTitle');
require('./directives/smartRouterAnimationWrap');
require('./directives/smartSpeech');
require('./directives/stateBreadcrumbs');

require('./service/lazyScript');
require('./service/SmartCss');
