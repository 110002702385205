import _ from 'lodash';
import angular from 'angular';

export class ApiCallbacksListComponent {
    $scope;
    $stateParams;
    Restangular;
    tableParams;
    NgTableParams;
    $uibModal;
    socket;
    $log;
    filter;
    columns = [];
    selectedColumns = [];
    isColumnsCollapsed;
    columnsVisible = {};
    total;
    throttleRefresh;
    moment;

    // FUNCTIONS:
    shouldShow;
    onColumnSelected;
    onColumnRemoved;
    stringtoimei;
    imeitobytes;
    applyFilter;
    Auth;

    /* @ngInject */
    constructor($scope, $stateParams, $sce, Restangular, NgTableParams, $uibModal, socket, $log, Auth, moment, toastr, $timeout, $http) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.$sce = $sce;
        this.Restangular = Restangular;
        this.NgTableParams = NgTableParams;
        this.$uibModal = $uibModal;
        this.socket = socket;
        this.$log = $log;
        this.Auth = Auth;
        this.moment = moment;
        this.filter = '';
        this.isColumnsCollapsed = false;
        this.toastr = toastr;
        this.$timeout = $timeout;
        this.self = this;
        this.currentRooms = [];
        this.$http = $http;
    }

    $onInit() {
        const self = this;
        self.selectedColumns = [];
        self.apiCallbacks = [];

        self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().accountId}:*:apiCallbacks`);
        self.currentRooms.push(`${self.Auth.getCurrentAccountSync().accountId}:*:apiCallbacks`);
        self.theCallbackEventListener = self.onReceiptOfCallback.bind(self);
        self.theCallbackEventListenerRemove = self.onRemoveOfCallback.bind(self);
        self.socket.socket.on('apiCallback:save', self.theCallbackEventListener);
        self.socket.socket.on('apiCallback:remove', self.theCallbackEventListenerRemove);
        self.cols = [{
            title: 'Name',
            field: 'name',
            show: true,
            sortable: 'name',
            getValue: self.handleDisplay.bind(this),
            // context: self
        }, {
            title: 'Operation',
            field: 'operation',
            show: true,
            sortable: 'operation',
            getValue: self.handleDisplay.bind(this),
            // context: self
        }, {
            title: 'Used Count',
            field: 'count',
            show: true,
            sortable: 'count',
            getValue: self.handleDisplay.bind(this),
            // context: self
        }, {
            title: 'API Token',
            field: '_id',
            show: true,
            sortable: '_id',
            getValue: self.handleDisplay.bind(this),
            // context: self
        }, {
            title: 'Enabled',
            field: 'active',
            show: true,
            getValue: self.handleDisplay.bind(this),
            // context: self
        }, {
            title: 'Enable/Disable API',
            field: 'toggleActive',
            show: true,
            getValue: self.handleDisplay.bind(this),
            // context: self
        },
        ];

        /**
        * The purpose of colValues is to contain the HTML-code that we want
        * to be assumed in NgTable.
        * TODO: This still needs to be implemented, but I think this should stop
        * angular from calling handleDisplay() function continuously
        * We can then use $interval to handle update-intervals.
        * @type {Object}
        */
        self.colValues = {};

        _.forEach(self.cols, (col) => {
            if (col.show) {
                self.selectedColumns.push(col.title);
            }
            self.colValues[col.field] = '';
        });

        this.tableParams = new this.NgTableParams({
            page: 1, // start with first page
            count: 10, // count per page
            sorting: {
                alias: 'asc', // initial sorting
            },
        }, {
            total: 0,
            getData(params) {
                let order;
                if (params && params.sorting) {
                    order = params.sorting();
                    const dataPromise = self.$http.get('/api/apiCallbacks', {
                        params: {
                            filter: self.filter.length ? self.filter : undefined,
                            skip: (params.page() - 1) * params.count(),
                            limit: params.count(),
                            by: Object.keys(order)[0],
                            order: order[Object.keys(order)[0]],
                        },
                    })
                    const countPromise = self.$http.get('/api/apiCallbacks/count', {
                        params: {
                            filter: self.filter.length ? self.filter : undefined,
                        },
                    })
                    return Promise.all([dataPromise, countPromise])
                        .then((response) => {
                            self.apiCallbacks = response[0].data;
                            self.total = response[1].data;
                            params.total(self.total);
                            return self.apiCallbacks;
                        })
                        .catch((err) => {
                            console.error('Error caught when getting data for units: ', err);
                        });
                }
            },
        });

        this.tableParams.reload();
    }

    $onDestroy() {
        const self = this;
        if (self.theCallbackEventListener) {
            self.socket.socket.removeListener('apiCallback:save', self.theCallbackEventListener);
            self.socket.socket.removeListener('apiCallback:remove', self.theCallbackEventListenerRemove);
        }
        if (self.currentRooms.length > 0) {
            self.currentRooms.forEach((room) => {
                self.socket.leaveRoom(room);
            });
        }
    }

    onReceiptOfCallback(callback) {
        const self = this;
        const index = _.findIndex(self.apiCallbacks, (o) => o._id == callback._id);
        if (index !== -1) {
            self.apiCallbacks[index] = callback;
        }
    }

    onRemoveOfCallback(callback) {
        const self = this;
        const index = _.findIndex(self.apiCallbacks, (o) => o._id == callback._id);
        if (index !== -1) {
            self.tableParams.reload();
        }
    }
    /**
    * This function receives
    * @param self - a context
    * @param col - a current columns
    * @param unit - the unit we're iterating through
    * Based on values, such as true and false, 1, 0, or anything else, we are able to
    * define an HTML-value so that we can have icons, symbols and more to be displayed
    * in our table.
    */
    handleDisplay(self, col, callback) {
        let html = '';
        switch (col.field) {
        case '_id': {
            html = `<a href="/api/apiCallbacks/${callback.account}/${callback._id}/executeCallback">API Link</a>`;

            return self.$sce.trustAsHtml(html);
        }
        case 'active': {
            if (callback.active) {
                html += '<span class="fa-lg">'
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }

            return self.$sce.trustAsHtml(html);
        }
        case 'toggleActive': {
            if (callback.active) {
                html = '<button class="btn btn-default" ng-click="$ctrl.disableAPI(callback)">Disable API Callback</span></button>';
            } else {
                html = '<button class="btn btn-default" ng-click="$ctrl.enableAPI(callback)">Enable API Callback</span></button>';
            }
            return self.$sce.trustAsHtml(html);
        }
        default:
            // The default cases are those who adopt boolean values
            // "internet || chreosis || ethernet || vpn || usb || wan"
            return callback[col.field];
        }
    }

    onColumnSelected($item, $model) {
        $item.show = true;
    }

    onColumnRemoved($item, $model) {
        $item.show = false;
    }

    applyFilter() {
        this.tableParams.page(1);
        this.tableParams.reload();
    }

    createNewApiCallback() {
        const self = this;

        const modalInstance = self.$uibModal.open({
            component: 'newApiCallback',
            size: 'm',
            backdrop: 'static',
            resolve: {
                edit: false,
                create: true,
            },
        });

        modalInstance.result.then((apiCallback) => {
            // NOTE We should send the new, edited unit to the server
            console.log('Call : ', apiCallback);
            self.$http.post('api/apiCallbacks/', apiCallback).then((response) => {
                self.toastr.info('API Callback created');
            }).catch((err) => {
                console.error('Error in apiCallbacks 1: ', err);
                self.toastr.error('API Callback create failed');
            });
        });
    }

    enableAPI(callback) {
        const self = this;
        callback.active = true;
        callback = _.pick(callback, ['_id', 'active']);
        self.$http.patch(`api/apiCallbacks/${callback._id}`, callback).then((response) => {
            self.toastr.info('API Callback enabled');
        }).catch((err) => {
            console.error('Error in apiCallbacks 2: ', err);
            self.toastr.error('API Callback enable failed');
        });
    }
    disableAPI(callback) {
        const self = this;
        callback.active = false;
        callback = _.pick(callback, ['_id', 'active']);
        self.$http.patch(`api/apiCallbacks/${callback._id}`, callback).then((response) => {
            self.toastr.info('API Callback disabled');
        }).catch((err) => {
            console.error('Error in apiCallbacks 3: ', err);
            self.toastr.error('API Callback disable failed');
        });
    }
}

export default angular.module('cameraViewerApp.apiCallbacks')
    .component('apiCallbackList', {
        template: require('./apiCallbacks.list.html'),
        controller: ApiCallbacksListComponent,
        controllerAs: '$ctrl',
    })
    .name;
