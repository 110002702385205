import _ from 'lodash-es';
import angular from 'angular';
import moment from "moment";

export class StreamUsageComponent {
    $http;
    $scope;
    $ngConfirm;
    /* @ngInject */
    constructor($http, $sce, Auth, $scope, toastr, $uibModal, $ngConfirm, NgTableParams) {
        this.$http = $http;
        this.$sce = $sce;
        this.Auth = Auth;
        this.$scope = $scope;
        this.toastr = toastr;
        this.$uibModal = $uibModal;
        this.$ngConfirm = $ngConfirm;
        this.NgTableParams = NgTableParams;

        this.sessions = [];
        this.filter = '';
        this.allAccounts = false;
    }

    $onDestroy() {

    }

    statUpdate(item) {
        this.tableParams.reload();
    }

    $onInit() {
        const self = this;

        self.currentAccount = self.Auth.getCurrentAccountSync();

        self.selectedColumns = [];

        self.cols = [
            {
                title: 'User',
                field: 'userName',
                show: true,
                sortable: 'userName',
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Account',
                field: 'accountName',
                show: false,
                sortable: 'accountName',
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Site',
                field: 'siteName',
                show: true,
                sortable: 'siteName',
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Camera',
                field: 'zoneAlias',
                show: true,
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Start Time',
                field: 'startTime',
                sortable: 'startTime',
                show: true,
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Duration',
                field: 'duration',
                show: true,
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Data Usage',
                field: 'bytesUsed',
                sortable: 'bytesUsed',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
        ];

        self.colValues = {};

        _.forEach(self.cols, (col) => {
            if (col.show) {
                self.selectedColumns.push(col.title);
            }
            self.colValues[col.field] = '';
        });

        self.tableParams = new self.NgTableParams(
            {
                page: 1, // start with first page
                count: 10, // count per page
                sorting: {
                    name: 'asc', // initial sorting
                },
            },
            {
                total: 0,
                getData(params) {
                    let order;
                    const headers = {}
                    if(self.allAccounts) headers['x-js-all-accounts'] = 'true';
                    if (params && params.sorting) {
                        order = params.sorting();
                        const dataPromise = self.$http.get('/api/cameras/streamStats', {
                            params: {
                                filter: self.filter.length ? self.filter : undefined,
                                skip: (params.page() - 1) * params.count(),
                                limit: params.count(),
                                by: Object.keys(order)[0],
                                order: order[Object.keys(order)[0]],
                            },
                            headers
                        });
                        const countPromise = self.$http.get('/api/cameras/streamStatCount', {
                            params: {
                                filter: self.filter.length ? self.filter : undefined,
                            },
                            headers
                        });
                        return Promise.all([dataPromise, countPromise])
                            .then((response) => {
                                self.sessions = response[0].data;
                                self.total = response[1].data;
                                params.total(self.total);
                                return self.sessions;
                            })
                            .catch((err) => {
                                console.error('Error caught when getting data for cameraStats: ', err.data.err);
                            });
                    }
                },
            },
        );
        this.tableParams.reload();
    }

    onColumnSelected($item, $model) {
        $item.show = true;
    }

    onColumnRemoved($item, $model) {
        $item.show = false;
    }

    applyFilter() {
        this.tableParams.page(1);
        this.tableParams.reload();
    }

    handleDisplay(self, col, session) {
        let html = '';
        switch (col.field) {
        case 'bytesUsed':
            const bytes = session.bytesUsed;
            if (bytes < 0) {
                return bytes;
            }
            if (bytes === 0) return '0 Bytes';
            const k = 1024;
            const dm = 1 + 1 || 3;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            html = `<a uib-tooltip="Frames: ${session.framesReceived}">${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}</a>`
            return self.$sce.trustAsHtml(html);

        case 'duration':
            const duration = moment(session.lastUpdateTime) - moment(session.startTime);
            html = `${moment.duration(duration).humanize()}`;
            return self.$sce.trustAsHtml(html);

        case 'startTime':
            html = `${moment(session.startTime).format('lll')}`;
            return self.$sce.trustAsHtml(html);

        default:
            break;
        }
        return session[col.field];
    }

    toggleAcc() {
        const self = this;

        self.allAccounts = !self.allAccounts;
        self.tableParams.reload();
    }

    doLog() {
        console.log('DEBUG:', this);
    }
}

export default angular.module('cameraViewerApp.streamUsage')
    .component('streamUsage', {
        template: require('./streamUsage.html'),
        controller: StreamUsageComponent,
        controllerAs: '$ctrl',
    })
    .name;
