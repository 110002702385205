import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import routes from './verify.routes';

// import SignupController from '../signup/signup.component';

export default angular.module('cameraViewerApp.verify', [uiRouter])
    .config(routes)
    .name;

require('./verify.email.component');
require('./verify.email.request.component');
