import _ from 'lodash';
import angular from 'angular';
import AsideController from './aside.controller';
import SnapshotModalController from './modal.controller';

export class LiveController {
    $http;
    socket;
    $uibModal;
    $document;
    $rootScope;
    $stateParams;
    // initialLoad;
    Auth;
    $state;
    $scope;
    snapshots = [];
    moment;
    snapshotDataSource = {};
    snapshotAdapter = {
        adapter: {
            remain: true,
        },
    };
    startDT;
    lastFilter = {
        sites: [],
        startDate: undefined,
        startTime: undefined,
        endDate: undefined,
        endTime: undefined,
        onlyFaces: undefined,
        onlyVehicles: undefined,
        onlyXFS: undefined,
        onlyPeople: undefined,
        onlyPoses: undefined,
        onlyObjects: undefined,
        onlyPlates: undefined,
        onlyRecog: undefined,
        gender: undefined,
        beard: undefined,
        age: undefined,
        emotionFilter: undefined,
        eyeglasses: undefined,
        sunglasses: undefined,
        mustache: undefined,
        eyesOpen: undefined,
        mouthOpen: undefined,
        smile: undefined,
    };
    endDT;
    analyticsLabels = [];
    indexLabelsPos = [];
    indexLabelsNeg = [];
    awsFilter = [];
    paused;
    pauseBuffer;
    preBuffer;
    reasons = [];
    onlyFaces = false;
    onlyVehicles = false;
    onlyXFS = false;
    onlyPeople = false;
    onlyPoses = false;
    onlyObjects = false;
    onlyPlates = false;
    onlyRecog = false;
    filter = [];
    self;

    /* @ngInject */
    constructor($http, moment, $document, $scope, $rootScope, socket, $uibModal, $state, Auth, $stateParams, toastr, liveStreamService) {
        // angular.module('infinite-scroll').value('THROTTLE_MILLISECONDS', 500);
        this.$http = $http;
        this.socket = socket;
        this.moment = moment;
        this.$uibModal = $uibModal;
        // this.initialLoad = false;
        this.$rootScope = $rootScope;
        this.$document = $document;
        this.liveStreamService = liveStreamService;
        this.paused = false;
        this.$state = $state;
        this.snapPlaceholder = [];
        this.pauseBuffer = [];
        this.$scope = $scope;
        this.Auth = Auth;
        this.toastr = toastr;
        const self = this;
        $scope.$on('$destroy', () => {
            socket.unsyncUpdates('snapshot');
            if (self.currentRoom !== '') {
                self.socket.leaveRoom(self.currentRoom);
                // console.log(self.currentRoom, 'left');
            }
            // console.log("DESTROYING");
        });

        this.custClass = 'hidden-panel';
        this.startDT = undefined;
        this.preBuffer = [];
        this.lastFilter.startDT = undefined;
        this.endDT = undefined;
        this.lastFilter.endDT = undefined;

        this.$stateParams = $stateParams;
        this.currentRoom = '';
        this.availableSites = [];
        this.currentUser = this.Auth.getCurrentUserSync();
    }

    $onInit() {
        const self = this;

        self.snapshotDataSource.get = self.getSnapshots.bind(self);
        self.Auth.hasPrivilege('secuvue.snapshot.index')
            .then((has) => {
                if (has) {
                    self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().accountId}:*:*:snapshots`);
                    self.currentRoom = `${self.Auth.getCurrentAccountSync().accountId}:*:*:snapshots`;
                } else if (this.Auth.isLoggedInSync()) {
                    // self.toastr.info(`You require secuvue.snapshot privileges`, 'Unprivileged : ', {preventOpenDuplicates: true})
                }
            });

        if (this.Auth.hasPrivilegeSync('secuvue.camera.basicIndex')) {
            this.$http.get('/api/cameras', { params: { noreference: true } })
                .then((response) => {
                    self.availableSites = [];
                    response.data.forEach((camera) => {
                        self.availableSites.push({
                            siteAlias: camera.site.alias, alias: camera.alias, site: camera.site._id, camera: camera._id,
                        });
                    });
                }, (err) => {
                    if (err.status !== 401 && err.status !== 403) console.error(err);
                });
        }
        self.$rootScope.$on('accountChanged', (event) => {
            if (self.currentRoom !== '') {
                self.socket.leaveRoom(self.currentRoom);
            }
            if (self.Auth.hasPrivilegeSync('secuvue.snapshot.index')) {
                self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().accountId}:*:*:snapshots`);
                self.currentRoom = `${self.Auth.getCurrentAccountSync().accountId}:*:*:snapshots`;
            } else if (self.Auth.isLoggedInSync()) {
                // self.toastr.info(`You require secuvue.snapshot privileges`, 'Unprivileged : ', {preventOpenDuplicates: true})
            }
        });

        self.socket.socket.on('snapshot:thumbnail', (item) => {
         //   console.debug(`Got thumbnail update for ${item._id} (${item.sitename}:${item.cameraname})`);
            // apply the changes
            self.snapshotAdapter.applyUpdates((obj, scope, element) => {
                if (obj._id.toString() === item._id.toString()) {
                    obj.keyThumbnail = item.keyThumbnail;
                    obj.dataThumbnail = item.dataThumbnail;
                    obj.thumbSize = item.thumbSize;
                }
            });
        });
        self.socket.socket.on('snapshot:save', (item) => {
            // console.log("SNAPSHOTADAPTER: ", self.snapshotDataSource);
            // console.log("ITEM", item);
            let inPlace = false;
            const thisTime = self.moment(+item.timestamp)
                .utc();
            // console.log("ITEM",item);
            // console.log("FILTER",self.filter);
            const camIndex = _.findIndex(self.filter, (obj) => obj === item.camera);

            let tempEnd = self.endDT;
            let tempStart = self.startDT;

            if (!self.endDT) {
                tempEnd = self.moment()
                    .utc()
                    .add(1, 'y');
            }
            if (!self.startDT) {
                tempStart = self.moment(0)
                    .utc();
            }
            let reasonIndex;

            if (self.reasons?.length > 0) {
                // console.log("self.reasons: ", self.reasons);
                reasonIndex = _.findIndex(self.reasons, (obj) => obj === item.reason);
            }
            let letEmit;
            if (self.onlyPeople) {
                letEmit = item.people > 0;
            }
            if (!letEmit && self.onlyPoses) {
                letEmit = item.humanPoseResults && item.humanPoseResults.length > 0 || item.poses > 0;
            }
            if (!letEmit && self.onlyObjects) {
                letEmit = item.ssds > 0;
            }
            if (!letEmit && self.onlyVehicles) {
                letEmit = item.vehicles > 0;
            }
            if (!letEmit && self.onlyXFS) {
                letEmit = item.xfsEvents && true;
            }
            if (!letEmit && self.onlyFaces) {
                letEmit = item.faces > 0;
            }
            if (!letEmit && self.onlyPlates) {
                letEmit = item.lprResults && item.lprResults.length > 0;
            }
            if (!letEmit && self.onlyRecog) {
                letEmit = item.identities && item.identities.length > 0;
            }
            if (!letEmit && reasonIndex) {
                letEmit = reasonIndex === -1;
            }

            if (letEmit === false || thisTime.isBefore(tempStart) || thisTime.isAfter(tempEnd)
                || self.filter && self.filter.length > 0 && camIndex === -1
            ) {
                return;
            }

            if (item.indexLabels && self.lastFilter) {
                if (self.lastFilter.beard === false) {
                    if (item.indexLabels.includes('beard')) {
                        return;
                    }
                } else if (self.lastFilter.beard === true) {
                    if (!item.indexLabels.includes('beard')) {
                        return;
                    }
                }
                if (self.lastFilter.smile === false) {
                    if (item.indexLabels.includes('smile')) {
                        return;
                    }
                } else if (self.lastFilter.smile === true) {
                    if (!item.indexLabels.includes('smile')) {
                        return;
                    }
                }
                if (self.lastFilter.eyeglasses === false) {
                    if (item.indexLabels.includes('eyeglasses')) {
                        return;
                    }
                } else if (self.lastFilter.eyeglasses === true) {
                    if (!item.indexLabels.includes('eyeglasses')) {
                        return;
                    }
                }
                if (self.lastFilter.sunglasses === false) {
                    if (item.indexLabels.includes('sunglasses')) {
                        return;
                    }
                } else if (self.lastFilter.sunglasses === true) {
                    if (!item.indexLabels.includes('sunglasses')) {
                        return;
                    }
                }
                if (self.lastFilter.mouthOpen === false) {
                    if (item.indexLabels.includes('mouthOpen')) {
                        return;
                    }
                } else if (self.lastFilter.mouthOpen === true) {
                    if (!item.indexLabels.includes('mouthOpen')) {
                        return;
                    }
                }
                if (self.lastFilter.eyesOpen === false) {
                    if (item.indexLabels.includes('eyesOpen')) {
                        return;
                    }
                } else if (self.lastFilter.eyesOpen === true) {
                    if (!item.indexLabels.includes('eyesOpen')) {
                        return;
                    }
                }

                if (self.lastFilter.mustache === false) {
                    if (item.indexLabels.includes('mustache')) {
                        return;
                    }
                } else if (self.lastFilter.mustache === true) {
                    if (!item.indexLabels.includes('mustache')) {
                        return;
                    }
                }

                if (self.lastFilter.gender === 'male') {
                    if (!item.indexLabels.includes('male')) {
                        return;
                    }
                } else if (self.lastFilter.gender === 'female') {
                    if (!item.indexLabels.includes('female')) {
                        return;
                    }
                }

                if (self.lastFilter.emotionFilter) {
                    const retVal = self.lastFilter.emotionFilter.some((em) => item.indexLabels.includes(em));

                    if (!retVal) {
                        return;
                    }
                }

                if (self.lastFilter.age) {
                    const ageIndex = _.findIndex(item.indexLabels, (o) => o.low <= self.lastFilter.age && o.high >= self.lastFilter.age);

                    if (ageIndex === -1) {
                        return;
                    }
                }
            } else if (
                self.lastFilter.beard || self.lastFilter.age || self.lastFilter.mustache
                || self.lastFilter.emotionFilter || self.lastFilter.gender || self.lastFilter.eyesOpen
                || self.lastFilter.mouthOpen || self.lastFilter.smile
                || self.lastFilter.eyeglasses || self.lastFilter.sunglasses) {
                return;
            }

            self.handleBuddyEvents(item);

            self.snapshotAdapter.applyUpdates((obj, scope, element) => {
                if (obj._id.toString() === item._id.toString()) {
                    inPlace = true;
                    obj.identities = item.identities;
                    obj.openvinoDone = item.openvinoDone;
                    obj.openvinoResults = item.openvinoResults;
                    obj.faceRecallDone = item.faceRecallDone;
                    obj.faceRecognitions = item.faceRecognitions;
                    obj.lprResults = item.lprResults;
                    obj.humanPoseDone = item.humanPoseDone;
                    obj.humanPoseResults = item.humanPoseResults;
                    obj.openvinoPeopleDetections = item.openvinoPeopleDetections;
                    obj.peopleDetectionDone = item.peopleDetectionDone;
                    obj.openvinoVehicleDetections = item.openvinoVehicleDetections;
                    obj.vehicleDetectionDone = item.vehicleDetectionDone;
                    obj.keyThumbnail = item.keyThumbnail;
                    obj.dataThumbnail = item.dataThumbnail;
                    obj.thumbSize = item.thumbSize;
                }
            });

            if (!inPlace && !self.paused) {
                self.snapshotAdapter.prepend([item]);
                if (self.snapPlaceholder[0]) {
                    self.snapPlaceholder[0].first = false;
                }
                item.first = true;
                self.snapPlaceholder.unshift(item);
                // console.log("Prepended");
            } else if (!inPlace && self.paused) {
                self.pauseBuffer.push(item);
            }
            // if(self.snapshotAdapter.isLoading) {
            //    self.preBuffer.push(item);
            // }
        });
    }

    initiateStream(card) {
        const self = this;
        const camera = {
            id: card.camera,
            name: card.cameraname,
            account: card.account,
            site: card.site,
            siteName: card.sitename,
            unit: card.unit,
            source: 'LiveView',
            user: self.currentUser._id,
            userName: self.currentUser.name,
        };
        self.liveStreamService.addStream(camera);
    }

    handleBuddyEvents(item) {
        if (item.buddyEvents && item.buddyEvents.length > 0) {
            item.buddyLineDescript = '';
            item.buddyMotionDescript = '';
            item.buddyEvents.forEach((bud) => {
                if (bud.type === 'Motion') {
                    item.buddyMotionDescript = `${item.buddyMotionDescript} ${bud.zoneAlias},`;
                } else if (bud.type === 'LineCross') {
                    if (bud.AToB) {
                        item.buddyLineDescript = `${item.buddyLineDescript} ${bud.lineAlias} A->B (${bud.zoneAlias}),`;
                    } else {
                        item.buddyLineDescript = `${item.buddyLineDescript} ${bud.lineAlias} B->A (${bud.zoneAlias}),`;
                    }
                }
            });
            if (item.buddyMotionDescript.length > 0) {
                item.buddyMotionDescript = item.buddyMotionDescript.slice(0, -1);
            } else {
                Reflect.deleteProperty(item, 'buddyMotionDescript');
            }
            if (item.buddyLineDescript.length > 0) {
                item.buddyLineDescript = item.buddyLineDescript.slice(0, -1);
            } else {
                Reflect.deleteProperty(item, 'buddyLineDescript');
            }
        }
    }

    requestSnapshot(camera) {
        const self = this;
        self.$http.post(`/api/cameras/${camera}/requestSnapshot`, {})
            .then(
                () => {},
                (err) => {
                    if (err.status !== 401 && err.status !== 403) {
                        // let zone = _.find(self.currentSite.zones, o => {
                        //     return o._id === camera;
                        // });
                        console.error(err);
                        if (err.status === 400) {
                            if (err.data.reason === 'ZoneDown') {
                                self.toastr.warn('The camera at {{zone.alias}} is down', 'Zone Down : ', { preventOpenDuplicates: true });
                            } else {
                                self.toastr.warn('The unit at {{zone.alias}} is down', 'Unit Down : ', { preventOpenDuplicates: true });
                            }
                        } else if (err.status === 404) {
                            self.toastr.info('{{zone.alias}} has no camera assigned', 'Zone Not Assigned: ', { preventOpenDuplicates: true });
                        }
                    }
                },
            );
    }

    getSnapshots(descriptor, success) {
        success([]);
    }

    sortByTimestamp() {
        angular.copy(_.orderBy(this.snapshots, ['timestamp'], ['desc']), this.snapshots);
    }

    refreshFilter() {
        this.snapshotAdapter.reload();
    }

    formatDate(date) {
        const self = this;
        return self.moment(date)
            .local()
            .format('ll LTS');
    }

    openSnapshot(snapshot, overlay = undefined) {
        const self = this;
        self.$uibModal
            .open({
                animation: true,
                backdrop: 'static',
                keyboard: true,
                template: require('./modal.html'),
                controller: SnapshotModalController,
                controllerAs: '$ctrl',
                size: 'lg',
                resolve: {
                    snapshot() {
                        return snapshot;
                    },
                    snapPlaceholder() {
                        return self.snapPlaceholder;
                    },
                    overlay() {
                        return overlay;
                    },
                },
            })
            .result.then(
                (result) => {
                    if (result && result.newSnap) {
                        self.openSnapshot(
                            result.newSnap,
                            result.overlay
                        );
                    } else if (result && result.next) {
                        // self.openNext();
                    } else if (result && result.previous) {
                        // self.openPrevious();
                    }
                },
                () => {},
            );
    }

    clearFilter() {
        const self = this;
        self.lastFilter = {
            sites: undefined,
            reasons: undefined,
            startDate: undefined,
            startTime: undefined,
            endDate: undefined,
            endTime: undefined,
            onlyPlates: undefined,
            onlyRecog: undefined,
            onlyFaces: undefined,
            onlyPeople: undefined,
            onlyPoses: undefined,
            onlyObjects: undefined,
            onlyVehicles: undefined,
            onlyXFS: undefined,
            beard: undefined,
            gender: undefined,
            age: undefined,
            emotionFilter: undefined,
            eyeglasses: undefined,
            sunglasses: undefined,
            mustache: undefined,
            eyesOpen: undefined,
            mouthOpen: undefined,
            smile: undefined,
        };
        self.snapPlaceholder = [];
        self.applyFilter(self.lastFilter);
    }

    pause() {
        const self = this;
        self.paused = true;
    }

    resume() {
        const self = this;
        for (let i = 0; i < self.pauseBuffer.length; i++) {
            self.snapshotAdapter.prepend([self.pauseBuffer[i]]);
            self.snapPlaceholder.unshift(self.pauseBuffer[i]);
        }
        self.pauseBuffer = [];
        self.paused = false;
    }

    applyFilter(filter) {
        const self = this;

        self.snapPlaceholder = [];
        if (filter.startDate && filter.startTime) {
            self.startDT = self.moment(filter.startDate)
                .hours(filter.startTime.getHours())
                .minutes(filter.startTime.getMinutes());
        } else if (filter.startDate) {
            self.startDT = self.moment(filter.startDate);
        } else if (filter.startTime) {
            self.startDT = undefined;
        } else {
            self.startDT = undefined;
        }

        if (filter.endDate && filter.endTime) {
            self.endDT = self.moment(filter.endDate)
                .hours(filter.endTime.getHours())
                .minutes(filter.endTime.getMinutes());
        } else if (filter.endDate) {
            self.endDT = self.moment(filter.endDate);
        } else if (filter.endTime) {
            self.endDT = undefined;
        } else {
            self.endDT = undefined;
        }

        if (filter.sites && filter.sites.length > 0) {
            self.filter = filter.sites;
        } else {
            self.filter = [];
        }

        self.reasons = filter.reasons;
        self.onlyFaces = filter.onlyFaces;
        self.onlyVehicles = filter.onlyVehicles;
        self.onlyXFS = filter.onlyXFS;
        self.onlyPeople = filter.onlyPeople;
        self.onlyPoses = filter.onlyPoses;
        self.onlyObjects = filter.onlyObjects;
        self.onlyRecog = filter.onlyRecog;
        self.onlyPlates = filter.onlyPlates;

        self.indexLabelsPos = [];
        self.indexLabelsNeg = [];
        self.awsFilter = [];

        if (filter.gender === 'male') {
            self.indexLabelsPos.push('male');
            self.awsFilter.push('Male');
        } else if (filter.gender === 'female') {
            self.indexLabelsPos.push('female');
            self.awsFilter.push('Female');
        }

        if (filter.beard === true) {
            self.indexLabelsPos.push('beard');
            self.awsFilter.push('Beard');
        } else if (filter.beard === false) {
            self.indexLabelsNeg.push('beard');
            self.awsFilter.push('No Beard');
        }

        if (filter.mustache === true) {
            self.indexLabelsPos.push('mustache');
            self.awsFilter.push('Moustache');
        } else if (filter.mustache === false) {
            self.indexLabelsNeg.push('mustache');
            self.awsFilter.push('No Moustache');
        }

        if (filter.eyesOpen === true) {
            self.indexLabelsPos.push('eyesOpen');
            self.awsFilter.push('Eyes Open');
        } else if (filter.eyesOpen === false) {
            self.indexLabelsNeg.push('eyesOpen');
            self.awsFilter.push('Eyes Closed');
        }

        if (filter.mouthOpen === true) {
            self.indexLabelsPos.push('mouthOpen');
            self.awsFilter.push('Mouth Open');
        } else if (filter.mouthOpen === false) {
            self.indexLabelsNeg.push('mouthOpen');
            self.awsFilter.push('Mouth Closed');
        }

        if (filter.eyeglasses === true) {
            self.indexLabelsPos.push('eyeglasses');
            self.awsFilter.push('Glasses');
        } else if (filter.eyeglasses === false) {
            self.indexLabelsNeg.push('eyeglasses');
            self.awsFilter.push('No Glasses');
        }

        if (filter.sunglasses === true) {
            self.indexLabelsPos.push('sunglasses');
            self.awsFilter.push('Sunglasses');
        } else if (filter.sunglasses === false) {
            self.indexLabelsNeg.push('sunglasses');
            self.awsFilter.push('No Sunglasses');
        }

        if (filter.smile === true) {
            self.indexLabelsPos.push('smile');
            self.awsFilter.push('Smiling');
        } else if (filter.smile === false) {
            self.indexLabelsNeg.push('smile');
            self.awsFilter.push('Not Smiling');
        }

        self.age = filter.age;
        if (self.age) {
            self.awsFilter.push(`Age: ${self.age}`);
        }

        self.emotionFilter = filter.emotionFilter;
        if (self.emotionFilter && self.emotionFilter.length > 0) {
            self.emotionFilter.forEach((emotion) => {
                self.awsFilter.push(`${emotion}`);
            });
        }

        self.analyticsLabels = [];
        if (filter.onlyPoses) {
            self.analyticsLabels.push('analyticsCloudPose');
            if (self.Auth.hasRoleSync('secuvue.Analytics.Onboard.HumanPoseDetector')) {
                self.analyticsLabels.push('analyticsEdgePose');
            }
        }

        if (filter.onlyObjects) {
            self.analyticsLabels.push('analyticsCloudObject');
            if (self.Auth.hasRoleSync('secuvue.Analytics.Onboard.SSDDetector')) {
                self.analyticsLabels.push('analyticsEdgeObject');
            }
        }

        if (filter.onlyPeople) {
            self.analyticsLabels.push('analyticsCloudPeople');
            if (self.Auth.hasRoleSync('secuvue.Analytics.Onboard.PeopleDetector')) {
                self.analyticsLabels.push('analyticsEdgePeople');
            }
        }

        if (filter.onlyVehicles) {
            self.analyticsLabels.push('analyticsCloudVehicle');
            if (self.Auth.hasRoleSync('secuvue.Analytics.Onboard.VehicleDetector')) {
                self.analyticsLabels.push('analyticsEdgeVehicle');
            }
        }

        if (filter.onlyXFS) {
            self.analyticsLabels.push('xfsEvent');
        }

        self.refreshFilter();
    }

    doLog() {
        const self = this;
        console.log('Debug: ', self);
    }

    asideOpen() {
        const self = this;
        this.$uibModal.open({
            template: require('./aside.html'),
            placement: 'left',
            backdrop: 'static',
            keyboard: true,
            size: 'md',
            controller: AsideController,
            controllerAs: '$ctrl',
            resolve: {
                lastFilter() {
                    return self.lastFilter;
                },
            },
        })
        .result
        .then((result) => {
            if (result) {
                self.lastFilter = result;
                self.applyFilter(result);
            }
        });
    }

    getVideo(card) {
        const self = this;
        const modalScope = this.$scope.$new();
        modalScope.getParams = function () {
            return {
                id: card.site,
                cameras: [card.camera],
                date: card.timestamp,
            };
        };
        modalScope.isModal = true;
        const fileManagerModalInstance = self.$uibModal.open({
            animation: true,
            backdrop: 'static',
            keyboard: true,
            template: require('../fileManager/fileManager.html'),
            controller: require('../fileManager/fileManager.component').FileManagerComponent,
            controllerAs: '$ctrl',
            size: 'lg',
            scope: modalScope,
            resolve: {
                getParams() {
                    return {
                        id: card.site,
                        cameras: [card.camera],
                        date: card.timestamp,
                    };
                },
                isModal() {
                    return true;
                },
            },
        });
        modalScope.fileManagerModalInstance = fileManagerModalInstance;
        fileManagerModalInstance.result
            .then(
                () => {},
                () => {},
            );
    }

    hasMotion(snapshot) {
        return snapshot.detections.some((detection) => detection.detectionType === 'Motion');
    }

    hasFrontalFace(snapshot) {
        let result = _.find(snapshot.detections, (detection) => detection.detectionType === 'FrontalFace');

        result = result || snapshot?.snapRecognitions?.length || snapshot?.identities?.length;
        return result;
    }
}

export default angular.module('cameraViewerApp.live')
    .filter('escape', () => function (input) {
        if (input) {
            return window.encodeURIComponent(input);
        }
        return '';
    })
    .component('live', {
        template: require('./live.html'),
        controller: LiveController,
        controllerAs: '$ctrl',
    })
    .name;
