export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('verify', {
            url: '/authentication/verify/:userId/:token',
            views: {
                root: {
                    template: '<verifyemail></verifyemail>',
                },
            },
            authenticate: false,
        })
        .state('verifyRequest', {
            url: '/authentication/verify',
            views: {
                root: {
                    template: '<verifyemailrequest></verifyemailrequest>',
                },
            },
            authenticate: false,
        });
}
