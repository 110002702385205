import _ from 'lodash';
import angular from 'angular';
import SnapshotModalController from '../live/modal.controller';

export class EventsModalComponent {
    /* @ngInject */
    constructor(Auth, $uibModal, $http, $timeout) {
        this.Auth = Auth;
        this.$http = $http;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
    }
    doLog() {
        const self = this;
        console.log('DEBUG: ', self);
    }

    $onInit() {
        const self = this;
        self.event = self.resolve.settings.event;
        self.events = [];
        self.eventType = self.event.eventType;
        self.context = self.resolve.settings.context;
        self.showSlick = false;
        self.currentSite = {};
        self.notes = '';
        this.$http.get(`/api/sites/${this.event.site}`).then((response) => {
            // console.log("CURRENT SITE: ", response.data);
            self.currentSite = response.data;
        }, (err) => {
            if (err.status !== 401 && err.status !== 403) {
                console.error(err);
            }
        });
        // self.image = self.resolve.settings.image;
        // self.src = `assets/images/events/${self.image.label}.jpg`;
        // self.footageType = self.determineFootage(self.image.camera);
        // self.contactDetails = self.fetchContactDetailsFromSiteID(self.image.site);
        self.flattenEvents(self.event);
        // console.log(self.events);
        self.footages = null;
        self.tempfootages = [];
        const allSnapshots = [];
        self.events.forEach((event) => {
            if (event.snapshots.length < 20) {
                event.snapshots.forEach((snapshot) => {
                    const blah = new Promise((resolve, reject) => {
                        this.$http.get(`/api/snapshots/${snapshot}`).then((response) => {
                            self.tempfootages.push(response.data);
                            resolve(snapshot);
                        });
                    });
                    allSnapshots.push(blah);
                });
            } else {
                for (let i = 0; i < 20; i++) {
                    const snapshot = event.snapshots[i];
                    const blah = new Promise((resolve, reject) => {
                        this.$http.get(`/api/snapshots/${snapshot}`).then((response) => {
                            self.tempfootages.push(response.data);
                            resolve(snapshot);
                        });
                    });
                    allSnapshots.push(blah);
                }
            }
        });
        Promise.all(allSnapshots).then((values) => {
            self.showSlick = true;
            this.$timeout(
                () => {
                    self.footages = self.tempfootages;
                },
            );
            self.slickConfig.bogus = 5;
        });
        self.contacts = self.resolve.settings.contacts;
        self.loc = self.resolve.settings.loc;
        self.panelClasses = self.context.panelClasses[self.event.eventType];
        self.enableAction = false;
        self.checkedSOP = [];
        self.eventTypeInfo = {
            metaFlaggedVehicle: {
                description: 'Licence Plate Recognition',
                sop: [
                    '1: Verify recognition from image',
                    '2: In case of positive recognition, escalate to manager',
                ],
            },
            flaggedVehicle: {
                description: 'Licence Plate Recognition',
                sop: [
                    '1: Verify recognition from image',
                    '2: In case of positive recognition, escalate to manager',
                ],
            },
            linetrip: {
                description: 'Line Trip',
                sop: [
                    '1: Check received images for suspicious activities',
                    '2: If suspicious activities are observed, escalate and contact reaction.',
                    '3: If no suspicious activities are observed, request additional images',
                ],
            },
            motiontrigger: {
                description: 'Motion Trigger',
                sop: [
                    '1: Check received images for suspicious activities',
                    '2: If suspicious activities are observed, escalate and contact reaction.',
                    '3: If no suspicious activities are observed, request additional images',
                ],
            },
            nocomms: {
                description: 'No Communication',
                sop: [
                    '1: Inspect any of the last photos from the site for abnormalities.',
                    '2: Notify the site manager.',
                    '3: Log a maintenance call with the service provider',
                ],
            },
            camdown: {
                description: 'Camera Down',
                sop: [
                    '1: Ensure the camera is still offline.',
                    '2: Inspect last images for any suspicious activities.',
                    '3: Log a maintenance call with the service provider',
                ],
            },
            hddfail: {
                description: 'Storage Down',
                sop: [
                    '1: Ensure the disk is properly attached.',
                    '2: Ensure the disk is mounted in the OS.',
                    '3: Log a maintenance call with the service provider',
                ],
            },
            persondetected: {
                description: 'Person Detected',
                sop: [
                    '1: Verify the detection from the reference image.',
                    '2: In case of a positive detection, escalate to manager.',
                ],
            },
            recognition: {
                description: 'Recognition',
                sop: [
                    '1: Verify the detection from the reference image.',
                    '2: In case of a positive detection, escalate to manager.',
                ],
            },
            lowbattery: {
                description: 'Low Battery',
                sop: [
                    '1: See if other low-battery events from this site exist.',
                    '2: If so, contact the site manager',
                ],
            },
            tampers: {
                description: 'Tampers',
                sop: [
                    '1: Do this.',
                    '2: Phone that',
                    '3: Execute something then',
                ],
            },
            acfail: {
                description: 'AC Fail',
                sop: [
                    '1: Do this.',
                    '2: Phone that',
                    '3: Execute something then',
                ],
            },
            imagerequest: {
                description: 'Image Request',
                sop: [
                    '1: Check received images for suspicious activities',
                    '2: If suspicious activities are observed, escalate and contact reaction.',
                    '3: If no suspicious activities are observed, request additional images',
                ],
            },
        };
        // console.log(self);

        self.slickConfig = {
            enabled: true,
            autoplay: false,
            draggable: true,
            dots: true,
            initialSlide: 0,
            lazyLoad: 'progressive', // or 'ondemand'
            // fade: true,
            arrows: true,
            prevArrow: '<i class="fa fa-chevron-left slickArrPrev"> </i>',
            nextArrow: '<i class="fa fa-chevron-right slickArrNext"> </i>',
            autoplaySpeed: 3000,
            slidesToShow: 5,
            slidesToScroll: 5,
            method: {},
        };

        for (let i = 0; i < self.eventTypeInfo[self.event.eventType].sop.length; i++) {
            self.checkedSOP[i] = false;
        }
    }

    getTriggerCount(event) {
        switch (event.eventType) {
        case 'linetrip': {
            let results = event.snapshots.length;
            if (event.childEvent) {
                event.childEvent.forEach((cEvent) => {
                    results += cEvent.snapshots.length;
                });
            }
            return results;
        }
        case 'motiontrigger': {
            let results = event.snapshots.length;
            if (event.childEvent) {
                event.childEvent.forEach((cEvent) => {
                    results += cEvent.snapshots.length;
                });
            }
            return results;
        }
        case 'metaFlaggedVehicle': {
            let results = 1;
            if (event.childEvent) {
                results += event.childEvent.length;
            }
            return results;
        }
        case 'flaggedVehicle': {
            let results = 1;
            if (event.childEvent) {
                results += event.childEvent.length;
            }
            return results;
        }
        case 'camdown': {
            let results = 1;
            if (event.childEvent) {
                results += event.childEvent.length;
            }
            return results;
        }
        case 'hddfail': {
            let results = 1;
            if (event.childEvent) {
                results += event.childEvent.length;
            }
            return results;
        }
        case 'nocomms': {
            let results = 1;
            if (event.childEvent) {
                results += event.childEvent.length;
            }
            return results;
        }
        case 'persondetected': {
            let results = event.snapshots.length;
            if (event.childEvent) {
                event.childEvent.forEach((cEvent) => {
                    results += cEvent.snapshots.length;
                });
            }
            return results;
        }
        case 'recognition': {
            let results = 1;
            if (event.childEvent) {
                results += event.childEvent.length;
            }
            return results;
        }
        default:
            return 0;
        }
    }

    findFootage(snapshot) {
        return _.find(this.footages, (footage) => footage._id === snapshot);
    }

    flattenEvents(event) {
        const self = this;
        self.events.push(event);
        // console.log(event);

        if (event.identity) {
            // console.log('reference here', event.reference);
            self.$http.get(`/api/identities/${event.identity._id}/face`).then((response) => {
                // console.log(response.data);
                self.events[0].referencesnap = response.data.faces[0];
            });
        }
        if (!event.childEvent) {
            return;
        }
        for (let i = 0; i < event.childEvent.length; i++) {
            const idx = self.events.push(event.childEvent[i]) - 1;
            if (event.childEvent[i].identity) {
                self.$http
                    .get(`/api/identities/${self.events[idx].identity._id}`)
                    .then((response) => {
                        // console.log('received reference');
                        self.events[idx].referencesnap = response.data.faces[0];
                    });
            }
        }
    }

    close() {
        const result = 'stuff';
        this.modalInstance.close(result);
    }

    // This is only a dummy method for practical demo-purposes and should
    // be replaced by something much more sophisticated.
    determineFootage(cameraID) {
        if (cameraID.indexOf('camera') !== -1) {
            return 'Image';
        }
        return 'Video';
    }

    clickedSOP() {
        const self = this;
        let result = true;

        self.checkedSOP.forEach((sop) => {
            if (sop === false) {
                result = false;
            }
        });

        self.enableAction = result === true;
    }

    // TODO: After finishing image-modal, remember to set-up the display for each INDIVIDUAL site.
    // That is, know what site we're dealing with and displaying that site's events.
    // Also remember that video's might also come into play.
    openImageModal(image, context) {
        const self = this;

        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('../live/modal.html'),
            controller: SnapshotModalController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
                snapshot() {
                    return image;
                },
                snapPlaceholder() {
                    return [];
                },
                overlay() {
                    return undefined;
                },
            },
        }).result.then((res) => {
        }, (err) => {
        });
    }

    determineCurrentStatus() {
        const self = this;
        const createdDate = self.event.createdAt;
    }

    actionEvent() {
        const self = this;
        const actionedEvent = self.event;
        const eventStack = self.context.eventStack[self.eventType];

        self.events.forEach((event) => {
            const newEvent = _.merge(event, { sop: self.checkedSOP, active: false, notes: self.notes });
            self.$http.patch(`/api/events/${event._id}`, _.omit(newEvent, ['snapshots', 'childEvent', 'zone', 'referencesnap', 'reference'])).then((response) => {
            }, (err) => {
                if (err.status !== 401 && err.status !== 403) console.error(err);
            });
        });
        self.close();
    }
}
export default angular.module('cameraViewerApp.events')
    .component('eventmodal', {
        template: require('./events.modal.html'),
        bindings: {
            resolve: '<',
            //     close: '&',
            //     dismiss: '&',
            modalInstance: '<',
        },
        controller: EventsModalComponent,
        controllerAs: '$ctrl',
    })
    .name;
