import 'ngdropzone';
import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import uiScroll from 'angular-ui-scroll';
import uiScrollGrid from 'angular-ui-scroll/dist/ui-scroll-grid';
import angularMoment from 'angular-moment';
import { Dropzone } from 'dropzone';

import routes from './plate.routes';

export default angular.module('cameraViewerApp.plate', [uiRouter,
    'thatisuday.dropzone',
    'angularMoment',
])
    .config(routes)
    .name;

require('./plate.component');
