export function formatBytes(bytes, decimals) {
    if (bytes < 0) {
        return bytes;
    }
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals + 1 || 3;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function formatSeconds(secondsIn) {
    const oneMinute = 60;
    const oneHour = 60 * oneMinute;
    const oneDay = 24 * oneHour;
    const oneMonth = 30 * oneDay;
    const oneYear = 12 * oneMonth;

    const seconds = parseInt(secondsIn, 10);
    const years = Math.floor(seconds / oneYear);
    let left = seconds - years * oneYear;
    const months = Math.floor(left / oneMonth);
    left -= months * oneMonth;
    const days = Math.floor(left / oneDay);
    left -= days * oneDay;
    const hours = Math.floor(left / oneHour);
    left -= hours * oneHour;
    const minutes = Math.floor(left / oneMinute);
    left = Math.floor(left - minutes * oneMinute);
    let returnStr = '';
    if (years !== 0) {
        returnStr += `${years} Years `;
    }
    if (months !== 0) {
        returnStr += `${months} Months `;
    }
    if (days !== 0) {
        returnStr += `${days} Days `;
    }
    if (hours !== 0) {
        returnStr += `${hours} Hours `;
    }
    if (minutes !== 0 && returnStr === '') {
        returnStr += `${minutes} Minutes `;
    }
    if (left !== 0 && returnStr === '') {
        returnStr += `${left} Seconds `;
    }
    return returnStr;
}

export function formatDate(date) {
    const self = this;
    if (date) {
        date = self.moment(date * 1000);
        return date.format('llll');
    }
    return '';
}

export function formatLength(time) {
    const tempTime = this.moment.duration(time * 10, 'seconds');
    const hours = tempTime.get('hours');
    const minutes = tempTime.get('minutes');
    const seconds = tempTime.get('seconds');
    let string = '';
    if (hours) {
        string += `${hours} hr`;
    }
    if (hours && minutes) {
        string += ' and ';
    }
    if (minutes) {
        string += `${minutes} min`;
    }
    if (minutes && seconds) {
        string += ' and ';
    }
    if (seconds) {
        string += `${seconds} sec`;
    } else if (minutes || hours) {
        string += ' and 00 sec';
    } else {
        string += '00 sec';
    }

    return string;
}
