import angular from 'angular';

angular.module('cameraViewerApp.directives')
    .directive('timeParser', ['$document',
        function ($document) {
            return {
                restrict: 'A',
                require: 'ngModel',
                link(scope, el, attrs, modelCtrl) {
                    // Changes the user input from whatever was put in, to milliseconds
                    modelCtrl.$parsers.push((modelValue) => {
                        if (attrs.timeParser == 'Seconds') {
                            return modelValue * 1000;
                        } if (attrs.timeParser == 'Milliseconds') {
                            return modelValue;
                        }
                    });

                    // Changes the ng-model from milliseconds to the variable the user selects
                    modelCtrl.$formatters.push((modelValue) => {
                        if (attrs.timeParser == 'Seconds') {
                            return modelValue / 1000;
                        } if (attrs.timeParser == 'Milliseconds') {
                            return modelValue;
                        }
                    });
                },
            };
        },
    ]);
