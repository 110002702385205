import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import angularMoment from 'angular-moment';
import moment from 'moment';
import NgTable from 'ng-table/bundles/ng-table'; // TODO figure out why this is needed
import routes from './accessEvents.routes';

export default angular.module(
    'cameraViewerApp.accessEvents',
    [
        uiRouter,
        'ngTable',
        'angularMoment',
    ],
)
    .config(routes)
    .name;

require('./accessEvents.component');
require('./previewModal');
