import { Dropzone } from 'dropzone';
import 'dropzone/dist/dropzone.css';
import 'ngdropzone';

Dropzone.autoDiscover = false;

export default class UploadController {
    $state;
    $http;
    Auth;
    $scope;
    $uibModal;
    $uibModalInstance;
    allTags;
    plate = {};
    currentAccount;
    buttonClass = '';

    // eslint-disable-next-line spaced-comment
    /*@ngInject*/

    constructor($uibModalInstance, Auth, $http, $state, $scope, toastr, $uibModal, plate) {
        this.$uibModalInstance = $uibModalInstance;
        this.$state = $state;
        this.toastr = toastr;
        this.Auth = Auth;
        this.$http = $http;
        this.$scope = $scope;
        this.$uibModal = $uibModal;
        this.plate = plate;
    }

    doLog() {
        console.log('DEBUG:', this);
    }

    $onInit() {
        const self = this;
        self.currentAccount = self.Auth.getCurrentAccountSync().accountId;

        self.dzMethods = {};
        self.$http.get('api/plates/findTags')
            .then((response) => {
                self.allTags = response.data;
            });
    }

    upload() {
        const self = this;
        self.plate.upload = true;
        self.plate.plate = self.plate.plate.replace(/\s/g, '')
            .toUpperCase();
        self.$http.post('api/plates/', self.plate)
            .then(() => {
                self.toastr.info('Licence plate has been uploaded');
                // console.log("This is the response:", response.data);
            });
        self.$uibModalInstance.dismiss();
    }

    close() {
        const self = this;
        self.$uibModalInstance.dismiss();
    }

    removeTags(tags) {
        const label = ' (create new tag)';
        tags.forEach((tag, index) => {
            if (tag.slice(-label.length) === label) {
                tags[index] = tag.slice(0, -label.length);
            }
        });
    }
}
