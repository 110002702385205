import angular from 'angular';

export class AccessEventsComponent {
    $http;
    $state;
    socket;
    moment;
    toastr;
    Auth;
    NgTableParams;
    $sce;
    $uibModal;

    /* @ngInject */
    constructor($http, toastr, moment, socket, NgTableParams, $state, $scope, Auth, $sce, $uibModal) {
        this.$http = $http;
        this.$state = $state;
        this.socket = socket;
        this.Auth = Auth;
        this.toastr = toastr;
        this.moment = moment;
        this.NgTableParams = NgTableParams;
        this.$sce = $sce;
        this.$uibModal = $uibModal;
        this.$scope = $scope;
        this.currentRooms = [];
        this.accessEvents = [];
        this.filter = '';
    }

    doLog() {
        const self = this;
        console.log('DEBUG: ', self);
    }

    $onDestroy() {
        const self = this;
        // TODO: Leave socket after implementing sockets <10-05-19, Liaan> //
        // if(self.theUnitEventListener) {
        // self.socket.socket.removeListener('unit:save', self.theUnitEventListener);
        // self.socket.socket.removeListener('unit:remove', self.theUnitEventListenerRemove);
        // }
        // if(self.currentRooms.length > 0) {
        // self.currentRooms.forEach(room => {
        // self.socket.leaveRoom(room);
        // });
        // }
    }

    $onInit() {
        const self = this;

        // self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().accountId}:*:units`);
        // self.currentRooms.push(`${self.Auth.getCurrentAccountSync().accountId}:*:units`);
        // self.theUnitEventListener = self.onReceiptOfUnit.bind(self);
        // self.theUnitEventListenerRemove = self.onRemoveOfUnit.bind(self);
        // self.socket.socket.on('unit:save', self.theUnitEventListener);
        // self.socket.socket.on('unit:remove', self.theUnitEventListenerRemove);

        self.tooltipTemplate = require('./tooltipTemplate.html');
        this.selectedColumns = [
            'Date',
            'User',
            'Site',
            'Entry/Exit',
            'Allow',
            'Method',
        ];

        this.cols = [{
            title: 'Date',
            field: 'ts',
            show: true,
            sortable: 'ts',
            getValue: this.handleDisplay.bind(this),
            // context: this
        }, {
            title: 'User',
            field: 'username',
            show: true,
            sortable: 'username',
            getValue: this.handleDisplay.bind(this),
            // context: this
        },
        {
            title: 'Site',
            field: 'sitename',
            show: true,
            sortable: 'sitename',
            getValue: this.handleDisplay.bind(this),
            // context: this
        },
        {
            title: 'Device',
            field: 'deviceAlias',
            show: true,
            getValue: this.handleDisplay.bind(this),
            // context: this
        },
        {
            title: 'Entry/Exit',
            field: 'entry',
            show: true,
            getValue: this.handleDisplay.bind(this),
            // context: this
        },
        {
            title: 'Allow',
            field: 'allow',
            show: true,
            getValue: this.handleDisplay.bind(this),
            // context: this
        },
        {
            title: 'Method',
            field: 'method',
            show: true,
            getValue: this.handleDisplay.bind(this),
            // context: this
        },
        ];

        self.$http.get('/api/accessEvents/').then((response) => {
            if (response.data) {
                self.accessEvents = response.data;
            }
        });

        this.tableParams = new this.NgTableParams({
            page: 1, // start with first page
            count: 10, // count per page
            sorting: {
                ts: 'desc', // initial sorting
            },
        }, {
            total: 0,
            getData(params) {
                let order;
                if (params && params.sorting) {
                    order = params.sorting();
                    const dataPromise = self.$http.get('/api/accessEvents', {
                        params: {
                            filter: self.filter.length ? self.filter : undefined,
                            skip: (params.page() - 1) * params.count(),
                            limit: params.count(),
                            by: Object.keys(order)[0],
                            order: order[Object.keys(order)[0]],
                        },
                    })
                    const countPromise = self.$http.get('/api/accessEvents/count', {
                        params: {
                            filter: self.filter.length ? self.filter : undefined,
                        },
                    })
                    return Promise.all([dataPromise, countPromise]).then((response) => {
                        self.accessEvents = response[0].data;
                        self.total = response[1].data;
                        params.total(self.total);
                        return self.accessEvents;
                    })
                        .catch((err) => {
                            console.error('Error caught when getting data for accessEvents: ', err);
                        });
                }
            },
        });
        this.tableParams.reload();
    }

    handleDisplay(self, col, accessEvent) {
        let html = '';
        switch (col.field) {
        case 'ts': {
            html += '<span uib-tooltip="{{accessEvent.ts | date:\'yyyy-MM-dd HH:mm:ss\'}}" tooltip-append-to-body="true" am-time-ago="accessEvent.ts"></span>';
            return self.$sce.trustAsHtml(html);
        }
        case 'username': {
            html += '<span uib-tooltip="User ID : {{accessEvent.userid || \'N/A\'}}" tooltip-append-to-body="true">{{accessEvent.username || \'N/A\'}}</span>';
            return self.$sce.trustAsHtml(html);
        }
        case 'sitename': {
            html += '<span>{{accessEvent.sitename}}</span>';
            return self.$sce.trustAsHtml(html);
        }
        case 'deviceAlias': {
            html += '<span>{{accessEvent.deviceAlias}}</span>';
            return self.$sce.trustAsHtml(html);
        }
        case 'entry': {
            if (accessEvent.entry) {
                return 'Entry';
            }
            return 'Exit';
        }
        case 'method': {
            html += '<span ng-repeat="(method,val) in accessEvent.entryMethod" ng-if="val" class="label label-primary" style="margin-right:0.5em;">'
                + '{{method | uppercase}}'
                + '</span>';
            return self.$sce.trustAsHtml(html);
        }
        default: {
            // The default cases are those who adopt boolean values
            if (accessEvent[col.field]) {
                html += '<span class="fa-lg">'
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html += '<span class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }
            return self.$sce.trustAsHtml(html);
        }
        }
    }

    onColumnSelected($item, $model) {
        $item.show = true;
    }

    onColumnRemoved($item, $model) {
        $item.show = false;
    }

    applyFilter() {
        this.tableParams.page(1);
        this.tableParams.reload();
    }

    openPreview(accessEvent) {
        const self = this;

        const modalInstance = self.$uibModal.open({
            component: 'previewModal',
            backdrop: true,
            resolve: {
                accessEvent,
            },
        });
        modalInstance.result.then((result) => {

        });
    }
}

export default angular.module('cameraViewerApp.accessEvents')
    .component('accessEvents', {
        template: require('./accessEvents.html'),
        controller: AccessEventsComponent,
        controllerAs: '$ctrl',
    })
    .name;
