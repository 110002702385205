export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.dashboard', {
            url: '/dashboard',
            views: {
                'content@main': {
                    template: '<dashboard></dashboard>',
                },
            },
            authenticate: 'CustomView',
            data: {
                title: 'Custom View',
            },
            // resolve: {
            //     waitForPriv: function (Auth, $state) {
            //         console.log('In resolve function', $state);
            //         return Auth.hasPrivilege('secuvue.dashboard')
            //             .then((has) => {
            //                 console.log('Resolved wait function', has);
            //                 if (has) {
            //                     return has;
            //                 } else {
            //                     console.log('State go');
            //                     event.preventDefault();
            //                     $state.go(Auth.getLandingPage())
            //                         .catch((err) => {
            //                             console.log(err);
            //                         });
            //                     return has;
            //                 }
            //             });
            //     }
            // }
        });
}
