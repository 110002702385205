'use strict';

angular.module('SmartAdmin.Layout').directive('searchMobile', function() {
    return {
        restrict: 'A',
        compile(element, attributes) {
            element.removeAttr('search-mobile data-search-mobile');

            element.on('click', function(e) {
                $('body').addClass('search-mobile');
                e.preventDefault();
            });

            $('#cancel-search-js').on('click', function(e) {
                $('body').removeClass('search-mobile');
                e.preventDefault();
            });
        }
    };
});
