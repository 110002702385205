import angularXEditable from 'angular-xeditable';
import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import uiBootstrap from 'angular-ui-bootstrap';
import angularMoment from 'angular-moment';
import ngSticky from 'ngsticky';
import uiSelect from 'ui-select';
import moment from 'moment';
import async from 'async-es';
import _ from 'lodash-es';
import routes from './heatmaps.routes';

export default angular.module('cameraViewerApp.heatmaps', [uiRouter,
    uiBootstrap,
    'sticky',
    uiSelect,
    'angularMoment'])
    .config(routes)
    .name;

require('./heatmaps.component');
