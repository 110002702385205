'use strict';
import _ from 'lodash-es';
import { v1 as uuid } from 'uuid';
import moment from 'moment';

const INACTIVE_STREAM_SECONDS = 3; // Mark stream as inactive if no buffers have been received for this amount of seconds
const statUpdate = {
    count: 1,
    unit: 'minutes',
}

export class LiveStreamService {
	/*@ngInject*/
	constructor(Auth, $http, toastr, socket, $window, $ngConfirm) {
		this.Auth = Auth;
        this.$http = $http;
        this.toastr = toastr;
        this.socket = socket;
        this.$window = $window;
        this.$ngConfirm = $ngConfirm;
        this.rooms = [];
        this.streams = [];
        this.ws_conns = [];
        this.isDebug = this.Auth.hasUserPrivilege('debug');

        this.rtc_configuration = {
            iceServers: [
                {
                    urls: ['stun:stun.jerichosystems.co.za', 'turn:stun.jerichosystems.co.za'],
                    username: 'secuvue',
                    credential: 'jerichoturn'
                }
            ]
        };
        /* ws_conns description:
         {
             peerId: Signalling server Id,
             ws: websocket connection,
             pc: peer connection,
             status: status of connection,
             connect_attempts: number of attempts to connect to signalling server
         } */
	}

    $onDestroy() {
        const self = this;
		self.streams.forEach( (stream) => {
			self.closeStream(stream);
		});
        if (self.rooms && self.rooms.length > 0) {
            self.rooms.forEach((room) => {
                self.socket.leaveRoom(room);
            });
            self.rooms = [];
        }
    }

    $onInit() {
        const self = this;
    }

    initiateStream(stream) {
        const self = this;
        const peer = {
            guid: stream.guid,
            ws: null,
            pc: null,
            debugStatus: 'Connecting to server',
            connect_attempts: 0
        };
        self.ws_conns.push(peer);
        self.setStatus('Initiating Stream', peer);
        this.websocketServerConnect(stream.guid);
    }

    closeStream(stream) {
        const self = this;
		if (stream.statsUpdater) {
			clearInterval(stream.statsUpdater);
			Reflect.deleteProperty(stream,'statsUpdater');
		}
        const peer = self.ws_conns.find((o) => o.guid === stream.guid);
        if (peer) {
            self.closeAndUnsetPeer(peer);
            self.removeConn(peer.guid, stream);
        }
    }

	addStream(camera) {
        const self = this;

		if (!camera) {
			console.error('No camera provided when adding stream');
			return null;
		}

        if (!self.streams.find((o) => o.camera.id === camera.id)) {
			const stream = {
                active: false,
                minimized: false,
                guid: uuid(),
                debugStatus: 'Idle',
                lastUpdate: moment(),
                camera
            };
			self.streams.push(stream);
			self.initiateStream(stream);
		} else {
			self.toastr.warning('Only one stream per device allowed.', 'Stream already open.');
			return null;
		}
	}

	removeStream(stream) {
        const self = this;
        const streamIndex = self.streams.findIndex((o) => o.guid === stream.guid);
		if (streamIndex >= 0) {
			self.closeStream(stream);
			self.streams.splice(streamIndex,1);
		}
	}

    clear() {
        const self = this;
		while(self.streams.length) {
			self.removeStream(self.streams[0]);
		}
    }

    doLog() {
        const self = this;
        console.debug('DEBUG:', self);
    }

/************************************************WEBRTC MAGIC WILL HAPPEN HERE*************************************/

    resetState(peer) {
        peer.ws.close();
    }

    setStatus(msg, peer) {
        const self = this;
        const peerIndex = self.streams.findIndex((o) =>  o.guid === peer.guid);
        if (peerIndex !== -1) {
            self.streams[peerIndex].debugStatus = msg;
        }
        peer.debugStatus = msg;
    }

    handleIncomingError(error, peer) {
        const self = this;
        if (self.isDebug) {
            this.setStatus(`ERROR: ${error}`, peer);
        }
        this.resetState(peer);
    }

    removeConn(peerId, stream) {
		_.remove(this.ws_conns, (o) => {
			return o.guid === peerId;
		});
		if (stream) {
			stream.active = false;
			stream.debugStatus = "Idle";
		}
    }

    requestFullscreen(stream) {
        const videoElement = this.getVideoElement(stream.guid);
        if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
        } else if (videoElement.mozRequestFullScreen) {
            videoElement.mozRequestFullScreen();
        } else if (videoElement.webkitRequestFullscreen) {
            videoElement.webkitRequestFullscreen();
        } else if (videoElement.msRequestFullscreen) {
            videoElement.msRequestFullscreen();
        }
    }

    getVideoElement(peerId) {
        return document.getElementById(`${peerId}`);
    }

    resetVideoElement(peerId) {
        const videoElement = this.getVideoElement(peerId);
        if (videoElement) {
            videoElement.pause();
            videoElement.src = '';
            videoElement.load();
        }
    }

    onIncomingSDP(sdp, peer) {
        const self = this;
        peer.pc.setRemoteDescription(new RTCSessionDescription(sdp)).then(() => {
            if (self.isDebug) {
                this.setStatus('Remote SDP set', peer);
            }
            if (sdp.type !== 'offer') {
                return;
            }
            if (self.isDebug) {
                this.setStatus('Got SDP offer, creating answer', peer);
            }
            peer.pc.createAnswer()
                .then(this.onLocalDescription.bind(this, peer))
                .catch(this.setStatus.bind(this, peer));
        });
    }

    onLocalDescription(peer, desc) {
        const self = this;
        peer.pc.setLocalDescription(desc).then(function() {
            if (self.isDebug) {
                self.setStatus('Sending SDP answer', peer);
            }
            const sdp = { sdp: peer.pc.localDescription };
            peer.ws.send(JSON.stringify(sdp));
        });
    }

    onIncomingICE(ice, peer) {
        const candidate = new RTCIceCandidate(ice);
        peer.pc.addIceCandidate(candidate).catch(this.setStatus, peer);
    }

    onServerMessage(peerId, event) {
        const self = this;

        const peerIndex = self.ws_conns.findIndex((o) => peerId === o.guid);
        const peer = self.ws_conns[peerIndex];
        switch (event.data) {
            case 'HELLO':
                self.setStatus('Waiting for incoming stream', peer);
                const stream = self.streams.find((o) => o.guid === peerId);
				if (stream && stream.camera) {
                    return self.$http.post(`/api/cameras/initiateStream/${stream.camera.id}`, { peerId });
				}
                return;
            default:
                if (event.data.startsWith('ERROR')) {
                    self.handleIncomingError(event.data, peer);
                    return;
                }
                // Handle incoming JSON SDP and ICE messages
                let msg;
                try {
                    msg = JSON.parse(event.data);
                } catch(e) {
                    if (e instanceof SyntaxError) {
                        self.handleIncomingError(`Error parsing incoming JSON: ${e}`, peer);
                    } else {
                        self.handleIncomingError(`Unknown error parsing response: ${event.data}`, peer);
                    }
                    return;
                }
                // Incoming JSON signals the beginning of a call
                if (peer.pc === null) {
                    self.createCall(msg, peer);
                }

                if (msg.sdp) {
                    self.onIncomingSDP(msg.sdp, peer);
                } else if (msg.ice) {
                    self.onIncomingICE(msg.ice, peer);
                } else {
                    self.handleIncomingError(`Unknown incoming JSON: ${msg}`, peer);
                }
        }
    }

	resetStream(stream) {
		const self = this;
        const peer = self.ws_conns.find((o) => o.guid === stream.guid);
		if (!peer) return null;

        self.closeAndUnsetPeer(peer);
        peer.connect_attempts = 0;
		self.removeConn(peer.guid, stream);
		this.$window.setTimeout(() => {
			self.initiateStream(stream);
		}, 5000 );
	}

    closeAndUnsetPeer(peer) {
        const self = this;
        if (peer.pc !== null) {
            peer.pc.close();
            peer.pc = null;
            self.resetVideoElement(peer.guid);
        }
        if (peer.ws !== null) {
            peer.ws.close();
            peer.ws = null;
        }
    }

    onServerClose(peerId) {
        const peerIndex = this.ws_conns.findIndex((o) => peerId === o.guid);
        const peer = this.ws_conns[peerIndex];
		if (!peer) return null;
        this.resetVideoElement(peerId);
        if (peer) {
            if (peer.pc !== null) {
                peer.pc.close();
                peer.pc = null;
            }
			// Retry after 3 seconds
			this.$window.setTimeout(
                this.websocketServerConnect.bind(this, peerId),
            3000);
        }
    }

    onServerError(event, peerId) {
        const self = this;
        if (self.isDebug) {
            this.setStatus(
                'Unable to connect to server, did you add an exception for the certificate?',
                { id: peerId }
            );
        }
        // Retry after 3 seconds
        this.$window.setTimeout(this.websocketServerConnect.bind(this, peerId), 3000);
    }

    websocketServerConnect(peerId) {
        const self = this;
        const peerIndex = self.ws_conns.findIndex((o) => o.guid === peerId);
		if (peerIndex === -1) return null;

        const peer = this.ws_conns[peerIndex];
        peer.connect_attempts++;
        if (peer.connect_attempts > 5) {
            self.setStatus('Error Connecting. If problem persists, contact support.', peer);
            self.removeConn(peerId);
            return;
        }
        let loc = null;
        const isFile = self.$window.location.protocol.startsWith('file');
        const isHTTP = self.$window.location.protocol.startsWith('http');
        const isHTTPS = self.$window.location.protocol.startsWith('https');
        if (isFile) {
            loc = '127.0.0.1';
        } else if (isHTTP || isHTTPS) {
            loc = 'signal.jerichosystems.co.za';
        } else {
            throw new Error(
                `Don't know how to connect to the signalling server with uri ${window.location}`
            );
        }
        peer.ws = new WebSocket(`wss://${loc}:8443`);
        peer.debugStatus = 'Registering with server';

        peer.ws.addEventListener('open', () => {
			if (self.ws_conns[peerIndex].ws.readyState === 1) {
				self.ws_conns[peerIndex].ws.send(`HELLO ${peerId}`);
				self.setStatus("Stream initialising", peer);
			}
        });

        self.ws_conns[peerIndex].ws.addEventListener('error', self.onServerError.bind(self, peerId));
        self.ws_conns[peerIndex].ws.addEventListener('close', self.onServerClose.bind(self, peerId));
        self.ws_conns[peerIndex].ws.addEventListener('message', self.onServerMessage.bind(self, peerId));
    }

    onRemoteStreamAdded(peerId, event) {
        const self = this;
        const videoTracks = event.stream.getVideoTracks();
        const peerIndex = self.ws_conns.findIndex((o) => o.guid === peerId);
        if (videoTracks.length > 0) {
            const peer = self.ws_conns[peerIndex];
            self.setStatus('Viewing Stream', peer);
            self.getVideoElement(peerId).srcObject = event.stream;
            self.getVideoElement(peerId).play();
            const stream = self.streams.find((o) => o.guid === peerId);
			if (stream) {
				stream.statsUpdater = setInterval(self.updateStreamStats.bind(self, stream, videoTracks[0]), 1000);
			}
        } else {
            this.handleIncomingError('Stream with unknown tracks added, resetting');
        }
    }

    errorUserMediaHandler() {
        console.error('Browser doesn\'t support getUserMedia!');
    }

    createCall(msg, peer) {
        const self = this;
        peer.connect_attempts = 0; // Reset connection attempts because we connected successfully
        peer.pc = new RTCPeerConnection(this.rtc_configuration);

        if (peer.pc.addTrack !== undefined) {
            peer.pc.ontrack = (ev) => {
                ev.streams.forEach((stream) => this.onRemoteStreamAdded(peer.guid,{ stream }));
            };
        } else {
            peer.pc.onaddstream = this.onRemoteStreamAdded.bind(this, peer.guid);
        }

        /* Send our video/audio to the other peer */
        if (!msg.sdp) {
            console.error("WARNING: First message wasn't an SDP message!?");
        }

        peer.pc.onicecandidate = (event) => {
            // We have a candidate, send it to the remote party with the same uuid
            if (event.candidate !== null) {
                peer.ws.send(JSON.stringify({ ice: event.candidate }));
            }
        };

        if (self.isDebug) {
            this.setStatus('Created peer connection for call, waiting for SDP', peer);
        }
    }

    sendCameraStatUpdate(stream, stat) {
        const self = this;
        self.$http.put(`/api/cameras/updateStreamStats/${stream.guid}`,
            {
                framesReceived: stat.framesDecoded,
                bytesUsed: stat.bytesReceived,
                siteId: stream.camera.site,
                siteName: stream.camera.siteName,
                accountId: stream.camera.account,
                unitId: stream.camera.unit,
                zoneId: stream.camera.id,
                zoneAlias: stream.camera.name,
                source: stream.camera.source,
                userId: stream.camera.user,
                userName: stream.camera.userName,
            })
            .then((response) => {
                if (response.status === 200) {
                    stream.lastUpdate = moment();
                }
            });
    }

	updateStreamStats(stream, track) {
        const self = this;
        const peer = self.ws_conns.find((o) => o.guid === stream.guid);
        const timeElapsed = moment().isAfter(stream.lastUpdate.clone().add(statUpdate.count, statUpdate.unit));
		if (peer && peer.pc) {
			peer.pc.getStats(track).then((report) => {
				report.forEach((o) => {
                    if (o.type === 'inbound-rtp' && o.kind === 'video') {
                        if (o.framesDecoded && (stream.stats && stream.stats.framesDecoded !== o.framesDecoded)) {
                            if (!stream.active || timeElapsed) {
                                self.sendCameraStatUpdate(stream, o);
                            }
							stream.active = true;
							stream.inactiveCounter = 0;
                            self.setStatus('Stream running', peer);
						} else {
							if (!stream.inactiveCounter) {
								stream.inactiveCounter = 0;
							}
							stream.inactiveCounter++;
							if (stream.inactiveCounter >= INACTIVE_STREAM_SECONDS) {
								stream.active = false;
                                self.setStatus('Stream inactive', peer);
							}
						}
						stream.stats = o;
					}
				} );
			} );
		} else {
			stream.active = false;
			if (stream.statsUpdater) {
				clearInterval(stream.statsUpdater);
				Reflect.deleteProperty(stream,'statsUpdater');
			}
		}
	}
}

export default angular.module('cameraViewerApp.liveStream', [])
    .service('liveStreamService', LiveStreamService)
    .name;


