import angular from 'angular';

export default angular.module('cameraViewerApp.directives')
    .directive('validEmail', () => ({
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attrs, control) {
            control.$parsers.push((viewValue) => {
                const newEmail = control.$viewValue;
                control.$setValidity('invalidEmail', true);
                if (typeof newEmail === 'object' || newEmail == '') return newEmail; // pass through if we clicked date from popup
                if (!newEmail.match(/^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;,.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/)) {
                    control.$setValidity('invalidEmail', false);
                }
                return viewValue;
            });
        },
    }))
    .name;
