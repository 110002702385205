"use strict";

angular.module('SmartAdmin.Layout').directive('minifyMenu', function() {
    return {
        restrict: 'A',
        link(scope, element) {
                var $body = $('body');
            var minifyMenu = function() {
                if (!$body.hasClass("menu-on-top")) {
                    $body.toggleClass("minified");
                    $body.removeClass("hidden-menu");
                    $('html').removeClass("hidden-menu-mobile-lock");
                }
            };

            element.on('click', minifyMenu);
        }
    };
});
