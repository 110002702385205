/* eslint no-sync: 0 */

import angular from 'angular';
import uiSelect from 'ui-select';

const uiBootstrap = require('angular-ui-bootstrap');

export class NavbarComponent {
    menu = [{
        title: 'Home',
        state: 'main.testSmartAdmin',
    },
    ];

    constructor(Auth, $location, $state, $rootScope) {
        'ngInject';

        this.Auth = Auth;
        this.isLoggedIn = Auth.isLoggedInSync;
        this.getCurrentUser = Auth.getCurrentUserSync;
        this.getCurrentAccount = Auth.getCurrentAccountSync;
        this.setCurrentAccount = Auth.setCurrentAccount;
        /* this.hasPrivilege = Auth.hasPrivilegeSync; */
        this.hasUserPrivilegeSync = Auth.hasUserPrivilegeSync;
        this.$location = $location;
        this.$state = $state;
        this.$rootScope = $rootScope;
    }

    $onInit() {
        const self = this;
        this.currentAccount = this.Auth.getCurrentAccountSync();
        self.accountIcon = `assets/images/accounticons/${this.currentAccount.name}.png`;
        self.currentUser = self.Auth.getCurrentUserSync();
        // console.log(self.currentUser);
        // TODO: Implement functionality that if no currentAccount.png exists, a next .png [see below] should be chosen
        // this.accountIcon = `assets/images/domainicons/${this.$window.location.hostname}.png`;

        this.$rootScope.$on('accountChanged', (event) => {
            self.currentAccount = this.getCurrentAccount();
            self.accountIcon = `assets/images/accounticons/${self.currentAccount.name}.png`;
        });
    }

    navigateTo(state) {
        this.$state.go(state).catch((err) => {
            console.log(err);
        });
    }

    testMenuPrivilege(privilege) {
        if (privilege !== 'main') {
            return this.Auth.hasPrivilegeSync(privilege);
        }
        return true;
    }
}

export default angular.module('directives.navbar', [uiBootstrap, uiSelect])
    .component('navbar', {
        template: require('./navbar.html'),
        controller: NavbarComponent,
        controllerAs: '$ctrl',
    })
    .name;
