export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.maintenanceTimeline', {
            url: '/maintenanceTimeline',
            views: {
                'content@main': {
                // Remember that camel case gets changed to dash-case
                // ie. maintenanceTimeline becomes maintenance-Timeline
                    template: '<maintenance-timeline></maintenance-timeline>',
                },
            },
            authenticate: 'Maintenance',
            data: {
                title: 'Maintenance Timeline',
            },
        });
}
