import angular from 'angular';

angular.module('cameraViewerApp.directives')
    .directive('myUiSelect', ['$document', '$timeout',
        function ($document, $timeout) {
            return {
                require: 'uiSelect',
                restrict: 'A',
                link(scope, elm, attrs, $select) {
                    if (Object.keys(attrs).includes('autoOpen')) {
                        const waiting = attrs.autoOpen === '' ? 200 : +attrs.autoOpen;
                        $timeout(() => {
                            $select.toggle();
                        }, waiting);
                    }

                    if (Object.keys(attrs).includes('growOpen')) {
                        // in html: my-ui-select grow-open="150px"

                        const height = attrs.growOpen;
                        const parentContainer = angular.element(elm[0].parentElement);
                        scope.$on('uis:activate', ($event) => {
                            // console.log(elm);
                            parentContainer.css('height', height);
                            // elm.addClass('grow-when-open');
                        });
                        scope.$on('uis:close', ($event) => {
                            parentContainer.css('height', 'auto');
                            // elm.removeClass('grow-when-open');
                        });
                    }
                },
            };
        }]);
