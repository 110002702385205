'use strict';
import _ from 'lodash';

const angular = require('angular');

class _Tasks {
    tasks: array = [];
    $promise = undefined;
}

// TODO optimize -> remove allocation each iteration
// Adapted from http://stackoverflow.com/a/16245768/994263
function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
}

/*@ngInject*/
export function tasksService($rootScope, $http, $injector, socket, Auth, $location, $q, Util, toastr, $state) {
    // AngularJS will instantiate a singleton by calling "new" on this function
    let currentUser = {};
    let tasks = new _Tasks();
    let inited = false;
    let safeCb = Util.safeCb;
    let currentRoom = '';
    let videoTypeList = ['retrieveVideo', 'hikvisionVideoRetrieval'];
    // let $scope = $injector.get('$scope');

    let notifyFunction = function (task, event) {
        //Add custom toastr notification functions
        if (task.taskOptions && task.taskResults && task.status === 'done' && _.includes(videoTypeList, task.type)) {
            for (var cam in task.taskResults) {
                if (task.taskResults.hasOwnProperty(`${cam}`) && task.taskResults[cam].hasOwnProperty('video') && task.taskResults[cam].video.length > 0) {
                    // TODO write blob to localstorage and attempt to reuse instead of getting from unit
                    task.taskResults[cam].videourl = `/api/tasks/${task._id}/${task.type}/${cam}`;
                }
            }
            if (event !== 'deleted') {
                toastr.info(task.alias, 'Video Available! Click to go.', {
                    preventOpenDuplicates: true,
                    onTap() {
                        $state.go('main.fileManager', {
                            id: task.site,
                            openTasks: [task._id]
                        })
                            .catch(err => {
                                console.error(err);
                            });
                    },
                });
            } else {
                toastr.info(task.alias, 'Video Deleted', {
                    preventOpenDuplicates: true,
                });
            }
        }
    };

    let init = function () {
        if (inited) return;

        inited = true;
        tasks.$promise = Auth.getCurrentUser()
            .then(user => {
                currentUser = user;
                tasks.tasks = [];
                socket.unsyncUpdates('tasks');
                if (currentRoom !== '') {
                    socket.socket.emit('leave', {
                        room: currentRoom
                    });
                    // console.log("Tasks LEFT")
                }
                currentRoom = `${currentUser._id}:tasks`;

                socket.socket.emit('join', {
                    room: currentRoom
                });
                socket.syncUpdates('task', tasks.tasks, function (event, item, array) {
                    notifyFunction(item, event);
                    $rootScope.$emit('taskUpdate', {
                        event,
                        item,
                        array: tasks.tasks
                    });
                });
                return $http.get('/api/tasks/')
                    .then(response => {
                        response.data.forEach((resultTask) => {
                            let index = _.findIndex(tasks.tasks, (obj) => {
                                return obj._id == resultTask._id;
                            });
                            if (index >= 0) {
                                if (!tasks.tasks[index].taskResults && resultTask.updatedAt.getTime() > tasks.tasks[index].updatedAt.getTime()) {
                                    tasks.tasks.splice(index, 1, resultTask);
                                }
                            } else {
                                tasks.tasks.push(resultTask);
                            }
                        });
                        tasks.$promise = undefined;
                        // let tasks = [];
                        return tasks.tasks;
                    });
            });
    };

    $rootScope.$on('$destroy', () => {
        inited = false;
        // console.log('Destoyed');
        socket.unsyncUpdates('tasks');
        if (currentRoom != '') {
            socket.socket.emit('leave', {
                room: currentRoom
            });
        }
    });

    $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
        if (fromState.name === 'login') {
            init();
        }
    });

    Auth.isLoggedIn().then((is) => {
        if(is && $location.path() !== '/logout')
            init();
    })
    init();

    return {
        getTasks(callback) {
            const value = tasks.$promise ? tasks.$promise : tasks.tasks || [];
            return $q.when(value).then(
                (o) => {
                    safeCb(callback)(o);
                    return o;
                },
                () => {
                    safeCb(callback)([]);
                    return [];
                }
            );
        },
        getTasksSync() {
            return tasks.tasks;
        },
    };
}

export default angular.module('cameraViewerMainApp.tasks', [])
    .service('tasks', tasksService)
    .name;
