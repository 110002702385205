export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.report', {
            url: '/report',
            views: {
                'content@main': {
                // Remember that camel case gets changed to dash-case
                // ie. operationalReport becomes operational-report
                    template: '<report></report>',
                },
            },
            authenticate: 'Reports',
            data: {
                title: 'Report',
            },
        });
}
