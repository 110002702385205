import angular from 'angular';

export class ResetPasswordRequestComponent {
    /* @ngInject */
    constructor($stateParams, Restangular, $window, $http) {
        this.$stateParams = $stateParams;
        this.url = this.$stateParams.next;
        this.Restangular = Restangular;
        this.$window = $window;
        this.$http = $http;
        this.requested = false;
        this.requesting = false;
        this.failed = false;
        this.email = '';
        this.changed = false;
        this.changing = false;
    }

    $onInit() {
        this.hostnameIcon = `assets/images/domainicons/${this.$window.location.hostname}.png`;

        this.$http.get(`assets/images/domainicons/${this.$window.location.hostname}.png`)
            .then((data) => {
                this.hostnameIcon = `assets/images/domainicons/${this.$window.location.hostname}.png`;
            })
            .catch((err) => {
                //
                this.hostnameIcon = 'assets/images/domainicons/secuvue.net.png';
            });
    }

    forgotPassword(form) {
        this.requesting = true;
        this.requested = false;
        this.failed = false;
        if (form.$valid) {
            this.$http.get('api/users/forgot', { params: { email: this.email } })
                .then((/* value, requestHeaders */) => {
                    this.requested = true;
                    this.requesting = false;
                })
                .catch(() => {
                    this.failed = true;
                    this.requesting = false;
                });
        }
    }
}

export default angular.module('cameraViewerApp.reset')
    .component('resetpasswordrequest', {
        template: require('./reset.password.request.html'),
        controller: ResetPasswordRequestComponent,
        controllerAs: '$ctrl',
    })
    .name;
