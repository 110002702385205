// import angular from 'angular';
// import LoginController from './login.controller';

import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import routes from './login.routes';

export default angular.module('cameraViewerApp.login', [uiRouter])
    .config(routes)
    .name;

require('./login.component');
