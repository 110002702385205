// TODO TODO TODO TODO
// Potential Improvements:
//      Download All button - Check for snapshots already collected, collect the rest

import _ from 'lodash-es';
import angular from 'angular';
import exceljs from 'exceljs';
import FileSaver from 'file-saver';
import { fabric } from 'fabric-with-gestures';
import Geometry from '../../../server/components/utilities/geometry';
import SnapshotModalController from '../live/modal.controller';
import ZoneOverviewProcessing from '../zoneOverview/processing';
import ZoneOverviewColumns from '../zoneOverview/columnData';

const SnapshotModalTemplate = require('../live/modal.html');
const OperationalReportTemplate = require('./operationalReport.html');

export class OperationalReportComponent {
    $http;
    $scope;
    $state;
    canvases = {};
    moment;
    self;
    heatmapsOpen = {};
    NgTableParams;
    toastr;
    $sce;
    quickReportHour = ['Last Hour', 'Last 3 Hours', 'Last 6 Hours', 'Last 24 Hours', 'Last 7 Days', 'Last 31 Days', 'Today'];
    quickReportMonth = ['Last Month', 'This Month'];

    /* @ngInject */
    constructor($state, $document, $timeout, $sce, NgTableParams, $rootScope, moment, $scope, $http, socket, Auth, toastr, $uibModal) {
        this.$state = $state;
        this.$timeout = $timeout;
        this.$uibModal = $uibModal;
        this.$document = $document;
        this.moment = moment;
        this.$scope = $scope;
        this.$sce = $sce;
        this.$rootScope = $rootScope;
        this.busyMessage = '';
        this.includeAll = false;
        this.socket = socket;
        this.NgTableParams = NgTableParams;
        this.$http = $http;
        this.Auth = Auth;
        this.getCurrentUser = Auth.getCurrentUserSync;
        this.usageOpen = false;
        this.cameraUsageOpen = false;
        this.currentRouteView = '';
        this.installSnaps = [];
        this.currentRooms = [];
        this.numPhotos = 5;
        this.toastr = toastr;
        this.chosenType = 'Account';
        this.chosenResolution = 'Hourly';
        this.chosenMetrics = [];

        this.chosenZones = [];
        this.includeMaintenanceEvents = false;
        this.includeZoneConfigs = false;
        this.heatmapEnabled = false;
        this.popiEnabled = false;

        this.reportSnaps = [];
        this.reportIds = [];
        this.rawImages = {};
        this.customFields = [];

        this.siteUsageData = {};
        this.availableZones = [];
        this.availableLines = [];
        this.availableSites = [];

        this.endTime = moment()
            .add(1, 'hours')
            .startOf('hour')
            .toDate();
        this.endDate = moment()
            .startOf('day')
            .toDate();
        this.startTime = moment()
            .startOf('hour')
            .toDate();
        this.startDate = moment()
            .startOf('day')
            .toDate();

        this.eventEndTime = moment()
            .add(1, 'hours')
            .startOf('hour')
            .toDate();
        this.eventEndDate = moment()
            .startOf('day')
            .toDate();
        this.eventStartTime = moment()
            .startOf('hour')
            .toDate();
        this.eventStartDate = moment()
            .startOf('day')
            .toDate();

        this.cameras = [];
        this.cleanUpFuncions = [];
        this.onlymotion = false;
        this.filterOpen = false;
        this.progressOpen = false;
        this.videoOpen = false;
        this.siteVideos = [];
        this.videosOpen = {};
        this.params = {};
        if (this.$state.params) {
            this.params = this.$state.params;
        }

        try {
            this.isModal = this.$scope.isModal;
            this.params = this.$scope.getParams();
            // console.log('Params : ', this.params);
        } catch (e) {
            // console.log(e);
        }

        this.reasons = {
            ReferenceRequest: 'Reference Shot Request',
            SnapshotRequest: 'Snapshot Request',
            APISnapshotRequest: 'External API Snapshot Request',
            LineTrip: 'Line Cross Trigger',
            Motion: 'Motion Trigger',
            Face: 'Face Detection Trigger',
            PeerCameraMotion: 'Linked Zone Trigger',
            PeerCameraLine: 'Linked Line Trigger',
            FaceThreshold: 'Face Threshold Trigger',
            PersonTrigger: 'Person Detection Trigger',
            PersonThreshold: 'Person Threshold Trigger',
            VehicleTrigger: 'Vehicle Detection Trigger',
            VehicleThreshold: 'Vehicle Threshold Trigger',
            SSD: 'Object Detection Trigger',
            HumanPoseMatch: 'Pose Match Trigger',
            HumanPoseTrigger: 'Human Pose Trigger',
            HumanPoseThreshold: 'Human Pose Threshold Trigger',
            Scheduled: 'Scheduled Snapshot',
            AccessControl: 'Access Control Trigger',
            LPRDetection: 'Edge LPR Detection',
            AHD: 'Anti Hostage Door Trigger',
            XFSEvent: 'ATM XFS Trigger',
            Unknown: 'Unknown',

        };

        const self = this;
        this.$scope.$on('$destroy', () => {
            socket.unsyncUpdates('site');
            socket.unsyncUpdates('camera');
            if (self.currentRooms && self.currentRooms.length > 0) {
                self.currentRooms.forEach((room) => {
                    socket.leaveRoom(room);
                });
            }
        });

        this.includeCamMetadata = this.Auth.hasRoleSync('secuvue.SiteView.Settings.Zones.CameraMetadata');
        this.currentAccount = this.Auth.getCurrentAccountSync();
    }

    debug() {
        console.log(this);
    }

    $onInit() {
        const self = this;
        self.tableParams = new self.NgTableParams({
            // page: 1, // start with first page
            count: 10, // count per page
            sorting: {
                alias: 'asc', // initial sorting
            },
        }, {
            total: 0,
            getData(params) {
                let returnSnaps = [];
                if (self.installSnaps.length > 0) {
                    // self.installSnaps
                    returnSnaps = self.installSnaps.slice((params.page() - 1) * params.count(), (params.page() - 1) * params.count() + params.count());
                }
                params.total(self.installSnaps.length);
                returnSnaps.forEach((snap) => {
                    self.$timeout(() => {
                        self.drawCanvas(snap);
                    }, 0);
                });
                return returnSnaps;
            },
        });

        self.previewTableParams = new self.NgTableParams({
            // page: 1, // start with first page
            count: 10, // count per page
            sorting: {
                alias: 'asc', // initial sorting
            },
        }, {
            total: 0,
            getData(params) {
                let returnSnaps = [];

                if (self.reportSnaps.length > 0) {
                    returnSnaps = self.reportSnaps.slice((params.page() - 1) * params.count(), (params.page() - 1) * params.count() + params.count());
                }
                params.total(self.reportSnaps.length);
                return returnSnaps;
            },
        });
        self.$http.get('/api/sites/lite')
            .then((response) => {
                if (response.data) {
                    self.availableSites = response.data;
                }
            });

        self.selectedColumns = [
            'Added to Report',
            'Snapshot',
            'Zone Alias',
            'Timestamp',
            'Trigger',
            'Unit MAC Address',
        ];

        self.reportCols = [
            {
                title: 'Remove from Report',
                field: 'selected',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Snapshot',
                field: 'data',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Zone Alias',
                field: 'cameraname',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Trigger',
                field: 'reason',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Timestamp',
                field: 'timestamp',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Unit MAC Address',
                field: 'unit',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
        ];

        self.cols = [
            {
                title: 'Added to Report',
                field: 'selected',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Snapshot',
                field: 'data',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Zone Alias',
                field: 'cameraname',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Trigger',
                field: 'reason',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Timestamp',
                field: 'timestamp',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Unit MAC Address',
                field: 'unit',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
        ];

        self.zoneColumns = ZoneOverviewColumns.baseColumns;
        if (self.includeCamMetadata) {
            self.zoneColumns.concat(ZoneOverviewColumns.cameraMetadataColumns);
        }
    }

    drawCanvas(snap) {
        const self = this;
        self.canvases[snap._id] = new fabric.Canvas(`snapCanvas_${snap._id}`, { stopContextMenu: true, enableRetinaScaling: false });
        self.pausePanning = false;
        // if (!self.canvases[snap._id]) {
        //     self.canvases[snap._id].on({
        //         'mouse:wheel': function (opt) {
        //             var delta = opt.e.deltaY;
        //             //var pointer = canvas.getPointer(opt.e);
        //             var zoom = self.canvases[snap._id].getZoom();
        //             zoom = zoom - delta / 200;
        //             // limit zoom to 4x in
        //             if (zoom > 4) zoom = 4;
        //             // limit zoom to 1x out
        //             if (zoom < 1) {
        //                 zoom = 1;
        //                 self.canvases[snap._id].setViewportTransform([1, 0, 0, 1, 0, 0]);
        //             }
        //             self.canvases[snap._id].zoomToPoint({
        //                 x: opt.e.offsetX,
        //                 y: opt.e.offsetY
        //             }, zoom);
        //             opt.e.preventDefault();
        //             opt.e.stopPropagation();
        //         },
        //         'touch:gesture': function (e) {
        //             if (e.e.touches && e.e.touches.length == 2) {
        //                 self.pausePanning = true;
        //                 var point = new fabric.Point(e.self.x, e.self.y);
        //                 if (e.self.state == 'start') {
        //                     self.zoomStartScale = self.canvases[snap._id].getZoom();
        //                 }
        //                 var delta = self.zoomStartScale * e.self.scale;
        //                 self.canvases[snap._id].zoomToPoint(point, delta);
        //                 self.pausePanning = false;
        //                 // limit zoom to 4x in
        //                 if (delta > 4) delta = 4;
        //                 // limit zoom to 1x out
        //                 if (delta < 1) {
        //                     delta = 1;
        //                     self.canvases[snap._id].setViewportTransform([1, 0, 0, 1, 0, 0]);
        //                 }
        //             }
        //         },
        //         'touch:drag': function (e) {
        //             if (self.pausePanning == false && undefined != e.self.x && undefined != e.self.y) {
        //                 self.currentX = e.self.x;
        //                 self.currentY = e.self.y;
        //                 self.xChange = self.currentX - self.lastX;
        //                 self.yChange = self.currentY - self.lastY;
        //
        //                 if ((Math.abs(self.currentX - self.lastX) <= 100) &&
        //                     (Math.abs(self.currentY - self.lastY) <= 100)) {
        //                     var delta = new fabric.Point(self.xChange * 1.25, self.yChange * 1.25);
        //                     self.canvases[snap._id].relativePan(delta);
        //                 }
        //
        //                 self.lastX = e.self.x;
        //                 self.lastY = e.self.y;
        //             }
        //         }
        //     });
        // }
        const canvas = self.canvases[snap._id];
        canvas.clear();
        canvas.containerClass = 'snapshot-wrapper';
        fabric.Image.fromURL(snap.data, ((image) => {
            image.setOptions({
                left: 0,
                top: 0,
                opacity: 1,
                width: snap.width,
                height: snap.height,
                'max-height': '5em',
                'max-width': '20em',
            });
            canvas.setBackgroundImage(image, canvas.renderAll.bind(canvas), {
                originX: 'left',
                originY: 'top',
            });
            canvas.setWidth(image.width, { backstoreOnly: true });
            canvas.setHeight(image.height, { backstoreOnly: true });
            if (image.width > image.height) {
                canvas.setWidth('100%', { cssOnly: true });
                canvas.setHeight('auto', { cssOnly: true });
            } else {
                canvas.setWidth('auto', { cssOnly: true });
                canvas.setHeight('100%', { cssOnly: true });
            }
            canvas.selection = false;

            if (snap.lprResults.length > 0) {
                snap.lprResults.forEach((plate) => {
                    if (plate.boundingBox && plate.boundingBox.length > 0) {
                        const bb = [];
                        plate.boundingBox.forEach((co) => {
                            bb.push({
                                x: co.x * snap.width,
                                y: co.y * snap.height,
                            });
                        });

                        const poly = new fabric.Polygon(bb, {
                            stroke: 'orange',
                            strokeWidth: 3,
                            selectable: false,
                            fill: null,
                        });

                        // TODO: Ensure alias does not go out of canvas area
                        const textAlias = new fabric.Text(plate.plate, {
                            fontSize: 28,
                            fontFamily: 'Monospace',
                            textAlign: 'center',
                            left: poly.left + poly.width / 2,
                            top: poly.top + poly.height + 20,
                            shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
                            textBackgroundColor: 'rgba(200,200,200,0.5)',
                            originX: 'center',
                            originY: 'center',
                            // padding: 10,
                            selectable: false,
                            hasBorders: false,
                            hasControls: false,
                        });
                        canvas.add(textAlias);
                        canvas.add(poly);
                        canvas.renderAll();
                    }
                });
            }

            if (self.popiEnabled) {
                const faces = snap.identities ? snap.identities : snap.snapRecognitions;
                if (faces.length > 0) {
                    faces.forEach((rec) => {
                        fabric.Image.fromURL(snap.data, ((snapRecImage) => {
                            let clipRect = new fabric.Rect({
                                left: rec.boundingBox.Left * snap.width - snap.width / 2,
                                top: rec.boundingBox.Top * snap.height - snap.height / 2,
                                width: rec.boundingBox.Width * snap.width,
                                height: rec.boundingBox.Height * snap.height,
                                originX: 'left',
                                originY: 'top',
                                selectable: false
                            });
                            snapRecImage.setOptions({
                                left: 0,
                                top: 0,
                                opacity: 1,
                                width: canvas.width,
                                height: canvas.height,
                                selectable: false,
                                clipPath: clipRect,
                            });
                            snapRecImage.filters.push(new fabric.Image.filters.Pixelate({ blocksize: 20 }));
                            snapRecImage.applyFilters();
                            canvas.add(snapRecImage);
                            snapRecImage.moveTo(10);
                            canvas.renderAll();
                        }), { crossOrigin: 'anonymous' });
                    });
                } else {
                    snap.detections.forEach((detection) => {
                        if (detection.detectionType === 'Motion') {
                            return;
                        }
                        fabric.Image.fromURL(snap.data, (detectionImage) => {
                            let clipRect = new fabric.Rect({
                                left: detection.rect.left - snap.width / 2,
                                top: detection.rect.top - snap.height / 2,
                                width: detection.rect.right - detection.rect.left,
                                height: detection.rect.bottom - detection.rect.top,
                                originX: 'left',
                                originY: 'top',
                                selectable: false
                            });

                            detectionImage.setOptions({
                                left: 0,
                                top: 0,
                                opacity: 1,
                                width: canvas.width,
                                height: canvas.height,
                                selectable: false,
                                clipPath: clipRect,
                            });
                            detectionImage.filters.push(new fabric.Image.filters.Pixelate({ blocksize: 20 }));
                            detectionImage.applyFilters();
                            canvas.add(detectionImage);
                            detectionImage.moveTo(10);
                            canvas.renderAll();
                        }, { crossOrigin: 'anonymous' });
                    });
                }
            }
            // console.log("CURRENT CAMERA: ", self.currentCamera);
            // TODO: DO THE DRAWING OF LINES

            const anyInd = _.findIndex(snap.detections, (o) => o.lines.length > 0);
            if (anyInd !== -1) {
                const currentZone = _.find(self.selectedSite.zones, (o) => snap.camera === o._id);
                if (currentZone) {
                    const currentUnit = _.find(self.selectedSite.units, (o) => currentZone.unit === o._id);
                    if (currentUnit) {
                        const currentCamera = _.find(currentUnit.cameras, (o) => currentZone.camera === o._id);
                        if (currentCamera) {
                            const lineConfigs = currentCamera.configuration.analyticsConfiguration.motionConfiguration.crossLines;

                            const tripLines = [];
                            if (snap.reason && snap.reason === 'LineTrip') {
                                snap.detections.forEach((detection) => {
                                    if (detection.detectionType === 'Motion') {
                                        if (detection.lines && detection.lines.length > 0) {
                                            detection.lines.forEach((line) => {
                                                tripLines.push(line);
                                            });
                                        }
                                    }
                                });
                            }
                            if (lineConfigs) {
                                lineConfigs.forEach((config) => {
                                    const stats = _.find(snap.peopleCounters, (obj) => obj.line === config.id);

                                    if (stats !== undefined) {
                                        let color = 'rgba(255,0,0,0.8)';
                                        let dashArray = [25, 10];

                                        if (tripLines.includes(config.id)) {
                                            color = 'rgba(0, 255, 0, 0.8)';
                                            dashArray = [10, 2];
                                        }

                                        const line = new fabric.Line([
                                            config.line[0].x * canvas.width,
                                            config.line[0].y * canvas.height,
                                            config.line[1].x * canvas.width,
                                            config.line[1].y * canvas.height,
                                        ], {
                                            strokeWidth: 3,
                                            strokeDashArray: dashArray,
                                            stroke: `${color}`,
                                            originX: 'center',
                                            originY: 'center',
                                            perPixelTargetFind: true,
                                            selectable: false,
                                            targetFindTolerance: 10,
                                            // padding: 4,
                                            hasControls: false,
                                        });
                                        canvas.add(line);

                                        line.set('shadow', new fabric.Shadow({
                                            color: 'rgba(20, 20, 20, 1)',
                                            blur: 2,
                                            offsetX: 1,
                                            offsetY: 1,
                                        }));

                                        const coords = Geometry.calculatePerpendicularBisectPoints(line, 50);

                                        const textA = new fabric.Text(`A\n${stats.numBToA}`, {
                                            fontSize: 16,
                                            fontFamily: 'Monospace',
                                            textAlign: 'center',
                                            left: coords[2],
                                            top: coords[3],
                                            textBackgroundColor: 'rgba(0,200,0,1)',
                                            originX: 'center',
                                            originY: 'center',
                                            shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
                                            selectable: false,
                                            hasBorders: false,
                                            hasControls: false,
                                        });

                                        const textB = new fabric.Text(`B\n${stats.numAToB}`, {
                                            fontSize: 16,
                                            fontFamily: 'Monospace',
                                            textAlign: 'center',
                                            left: coords[0],
                                            top: coords[1],
                                            fill: 'white',
                                            shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
                                            textBackgroundColor: 'rgba(0,0,200,1)',
                                            originX: 'center',
                                            originY: 'center',
                                            selectable: false,
                                            hasBorders: false,
                                            hasControls: false,
                                        });

                                        const textAlias = new fabric.Text(config.alias, {
                                            fontSize: 16,
                                            fontFamily: 'Monospace',
                                            textAlign: 'center',
                                            left: line.left,
                                            top: line.top - line.height / 2 - 20,
                                            shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
                                            textBackgroundColor: 'rgba(200,200,200,0.5)',
                                            originX: 'center',
                                            originY: 'center',
                                            // padding: 10,
                                            selectable: false,
                                            hasBorders: false,
                                            hasControls: false,
                                        });

                                        canvas.add(textA);
                                        canvas.add(textB);
                                        canvas.add(textAlias);
                                        canvas.sendToBack(textAlias);
                                        line.textA = textA;
                                        line.textB = textB;
                                        line.textAlias = textAlias;
                                    }
                                });
                            }
                        }
                    }
                }
            }

            if (self.heatmapEnabled) {
                const url = `${self.currentCamera.heatmap}&ts=${+self.moment.now()}`;
                fabric.Image.fromURL(url, ((heatmapImage) => {
                    heatmapImage.setOptions({
                        left: 0,
                        top: 0,
                        opacity: 0.5,
                        scaleX: canvas.width / heatmapImage.width,
                        scaleY: canvas.height / heatmapImage.height,
                        originX: 'left',
                        originY: 'top',
                    });
                    heatmapImage.set('selectable', false);
                    canvas.add(heatmapImage);
                    heatmapImage.moveTo(20);
                    canvas.renderAll();
                }), { crossOrigin: 'anonymous' });
            }

            const faces = snap.identities ? snap.identities : snap.snapRecognitions;
            if (faces.length > 0) {
                faces.forEach((rec) => {
                    const rect = new fabric.Rect({
                        fill: 'rgba(0,0,0,0)',
                        width: rec.boundingBox.Width * image.width,
                        height: rec.boundingBox.Height * image.height,
                        left: rec.boundingBox.Left * image.width,
                        top: rec.boundingBox.Top * image.height,
                        stroke: 'rgba(0,255,0, 0.5)',
                        selectable: false,
                        strokeWidth: 3,
                    });
                    canvas.add(rect);
                    rect.moveTo(30);
                    rect.bringToFront();
                });
            }

            snap.detections.forEach((detection) => {
                if (detection.detectionType === 'Motion') {
                    const rect = new fabric.Rect({
                        fill: 'rgba(0,0,0,0)',
                        width: detection.rect.right - detection.rect.left,
                        height: detection.rect.bottom - detection.rect.top,
                        left: detection.rect.left,
                        top: detection.rect.top,
                        stroke: 'rgba(0,0,255, 0.5)',
                        selectable: false,
                        strokeWidth: 3,
                    });
                    canvas.add(rect);
                    rect.moveTo(30);
                    rect.bringToFront();
                } else if (detection.detectionType === 'FrontalFace' && snap.snapRecognitions.length === 0 && snap.identities?.length === 0) {
                    const rect = new fabric.Rect({
                        fill: 'rgba(0,0,0,0)',
                        width: detection.rect.right - detection.rect.left,
                        height: detection.rect.bottom - detection.rect.top,
                        left: detection.rect.left,
                        top: detection.rect.top,
                        stroke: 'rgba(0,255,0, 0.5)',
                        selectable: false,
                        strokeWidth: 3,
                    });
                    canvas.add(rect);
                    rect.moveTo(30);
                    rect.bringToFront();
                }
            });
            canvas.renderAll();
        }), { crossOrigin: 'anonymous' });
    }

    printDuration(time) {
        const hours = Math.floor(time / (1000 * 60 * 60));
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor(((time % (1000 * 60 * 60)) % (1000 * 60)) / 1000);
        return `${hours}:${minutes}:${seconds}`;
    }

    getSnapshots(params) {
        const self = this;
        const tempFilter = [];

        let tsStart;
        if (self.startDate && self.startTime) {
            tsStart = +self.moment(self.startDate)
                .hours(self.startTime.getHours())
                .minutes(self.startTime.getMinutes());
        }
        let tsEnd;
        if (self.endDate && self.endTime) {
            tsEnd = +self.moment(self.endDate)
                .hours(self.endTime.getHours())
                .minutes(self.endTime.getMinutes());
        }

        if (self.filter) {
            self.filter.forEach((cam) => {
                tempFilter.push(cam._id);
            });
        }
        return self.$http.get('/api/snapshots/', {
            params: {
                site: true,
                pagesize: params.count(),
                before: tsEnd,
                after: tsStart,
                filter: tempFilter,
                onlyRecog: self.onlyRecog,
                onlyPlates: self.onlyPlates,
                onlyFaces: self.onlyFaces,
                indexLabelsPos: self.indexLabelsPos,
                indexLabelsNeg: self.indexLabelsNeg,
                age: self.age,
                emotionFilter: self.emotionFilter,
            },
        })
            .then((response) => response);
    }

    onSiteSelect($item) {
        const self = this;
        this.$http.get(`/api/sites/${$item._id}`)
            .then((site) => {
                self.selectedSite = site.data;
                self.availableZones = _.filter(self.selectedSite.zones, (o) => !o.disabled);
                self.chosenZones = self.availableZones;
            });
    }

    handleDisplay(self, col, snap) {
        console.log(self)
        let html = '';
        switch (col.field) {
        case 'selected':
            html = '<i class="fa fa-trash-alt" style="cursor:pointer" ng-click="$ctrl.removeFromReport(snap)"></i>';
            return self.$sce.trustAsHtml(html);

        case 'data':
            if (snap[col.field]) {
                html += `<img ng-click="$ctrl.openSnap(snap)" id="snap_${snap._id}" `
                    + `ng-src="${snap[col.field] || 'https://via.placeholder.com/200x150'}" `
                    + 'style="max-width:15em; max-height:5em; width:auto; height:auto;" alt="snapshot">';
            }
            return self.$sce.trustAsHtml(html);

        case 'cameraname':
            return snap[col.field];

        case 'timestamp':
            html += `<span>{{$ctrl.formatDate(${snap[col.field]})}}</span>`;
            return self.$sce.trustAsHtml(html);

        case 'reason':
            return self.reasons[snap[col.field]] || 'Unknown';

        case 'unit':
            return snap[col.field];

        default:
            // The default cases are those who adopt boolean values
            // "internet || chreosis || ethernet || vpn || usb || wan"
            return snap[col.field];
        }
    }

    formatDate(date) {
        const self = this;
        return self.moment(date)
            .local()
            .format('ll LTS');
    }

    querySnaps() {
        const self = this;
        let tsStart;
        if (self.startDate && self.startTime) {
            tsStart = +self.moment(self.startDate)
                .hours(self.startTime.getHours())
                .minutes(self.startTime.getMinutes());
        }
        let tsEnd;
        if (self.endDate && self.endTime) {
            tsEnd = +self.moment(self.endDate)
                .hours(self.endTime.getHours())
                .minutes(self.endTime.getMinutes());
        }
        const zones = self.chosenZones.map((o) => o._id);
        self.$http.get('/api/snapshots/indexIncident', {
            params: {
                startTime: tsStart,
                endTime: tsEnd,
                numPhotos: self.numPhotos ? self.numPhotos : 5,
                zoneIds: zones,
            },
        })
            .then((response) => {
                if (response.data) {
                    if (response.data.length > 0) {
                        [self.installSnaps] = response.data;
                        response.data.forEach((snapSet, i) => {
                            if (i > 0) {
                                self.installSnaps = self.installSnaps.concat(snapSet);
                            }
                        });
                        if (self.installSnaps.length === 0) {
                            self.toastr.warning('No results for this time range', { preventOpenDuplicates: true });
                        }
                        self.toastr.success('Snapshots found', { preventOpenDuplicates: true });
                        self.reportSnaps.forEach((snap) => {
                            const index = _.findIndex(self.installSnaps, (o) => o._id === snap._id);
                            if (index !== -1) {
                                self.installSnaps[index].selected = snap.selected;
                            }
                        });
                        self.selectOpen = true;
                        self.tableParams.reload();
                    } else if (response.data.length === 0) {
                        self.toastr.warning('No results for this time range', { preventOpenDuplicates: true });
                    }
                }
            });
    }

    async recurseFunc(ctx, returnArr, times) {
        const numPhotos = 50;
        const zones = ctx.chosenZones.map((o) => o._id);
        const response = await ctx.$http.get('/api/snapshots/', {
            params: {
                before: times.tsEnd,
                after: times.tsStart,
                filter: zones,
                pagesize: numPhotos,
            },
        });

        if (response.data && response.data.length < numPhotos) {
            returnArr = returnArr.concat(response.data);
            return returnArr;
        }
        returnArr = returnArr.concat(response.data);
        return ctx.recurseFunc(ctx, returnArr, {
            tsEnd: returnArr[returnArr.length - 1].timestamp,
            tsStart: times.tsStart,
        });
    }

    async recurseMaintenanceFunc(ctx, returnArr, skip) {
        const numEvents = 100;
        if (skip >= 100) {
            ctx.toastr.warning(
                'Please choose a more selective filter',
                'Number of entries too large (>10 000)',
                { preventOpenDuplicates: true },
            );
            throw new Error('Too many iterations');
        }

        const queryParams = {
            site: ctx.selectedSite ? ctx.selectedSite._id : undefined,
            filter: undefined,
            // Get numEvents results at a time
            limit: numEvents,
            // The skip variable acts as the placeholder for the params.page(),starting at 0, and incrementing
            skip: skip * numEvents,
            by: 'timestamp',
            order: 'desc',
        };
        if (ctx.filterResults) {
            const filterUnits = [];
            const filterZones = [];
            const filterEvents = [];
            const filterStates = [];
            ctx.filterEntities.forEach((ent) => {
                if (ctx.filterTypes[ent] === 'unit') {
                    filterUnits.push(ent);
                } else if (ctx.filterTypes[ent] === 'zone') {
                    filterZones.push(ent);
                }
            });
            ctx.filterEventOptions.forEach((ev) => {
                if (['configChange', 'softwareVersionChange', 'boot'].includes(ev)) {
                    filterEvents.push(ev);
                } else {
                    filterStates.push(ev);
                }
            });

            if (filterUnits.length > 0) {
                queryParams.units = filterUnits;
            }
            if (filterZones.length > 0) {
                queryParams.zones = filterZones;
            }
            if (filterEvents.length > 0) {
                queryParams.events = filterEvents;
            }
            if (filterStates.length > 0) {
                queryParams.states = filterStates;
            }

            queryParams.startDate = ctx.startDate.getTime();
            queryParams.endDate = ctx.endDate.getTime();
        }
        const response = await ctx.$http.get('/api/maintenance/', { params: queryParams });
        if (response.data && response.data.data && response.data.data.length < numEvents) {
            returnArr = returnArr.concat(response.data.data);
            return returnArr;
        }
        if (response.data) {
            returnArr = returnArr.concat(response.data.data);
            skip++;
            return ctx.recurseMaintenanceFunc(ctx, returnArr, skip);
        }
        throw new Error('No response.data');
    }

    getZoneData() {
        const self = this;
        const formattedZones = [];
        self.chosenZones.forEach((zone) => {
            let cUnit = zone.unit;
            let cCam = zone.camera;
            const unitIsNotObject = zone.unit && !Object.prototype.hasOwnProperty.call(zone.unit, '_id');
            const cameraIsNotObject = zone.camera && !Object.prototype.hasOwnProperty.call(zone.camera, '_id');
            if (unitIsNotObject && cameraIsNotObject) {
                cUnit = _.find(self.selectedSite.units, (o) => o._id === zone.unit);
                if (cUnit) {
                    cCam = _.find(cUnit.cameras, (o) => o._id === zone.camera);
                }
            }
            zone.camera = cCam;
            zone.unit = cUnit;
            const formattedZone = ZoneOverviewProcessing.formatZone(zone, self.includeCamMetadata);
            if (formattedZone) {
                formattedZones.push(formattedZone);
            }
        });
        return formattedZones;
    }

    getReport() {
        const self = this;
        self.busyMessage = 'Downloading Report';
        if (self.includeAll || self.includeMaintenanceEvents) {
            let tsStart;
            if (self.eventStartDate && self.eventStartTime) {
                tsStart = +self.moment(self.eventStartDate)
                    .hours(self.eventStartTime.getHours())
                    .minutes(self.eventStartTime.getMinutes());
            }
            let tsEnd;
            if (self.eventEndDate && self.eventEndTime) {
                tsEnd = +self.moment(self.eventEndDate)
                    .hours(self.eventEndTime.getHours())
                    .minutes(self.eventEndTime.getMinutes());
            }

            if (self.includeAll) {
                return self.recurseFunc(self, [], {
                    tsStart,
                    tsEnd,
                })
                    .then((data) => {
                        if (!self.includeMaintenanceEvents) {
                            return self.saveWB(data);
                        }
                        return self.recurseMaintenanceFunc(self, [], 0)
                            .then((data2) => {
                                self.saveWB(data, data2);
                            });
                    })
                    .catch((err) => {
                        console.log('Error at async function:', err);
                    });
            }

            if (self.includeMaintenanceEvents) {
                return self.recurseMaintenanceFunc(self, [], 0)
                    .then((data2) => {
                        self.saveWB(undefined, data2);
                    })
                    .catch((err) => {
                        console.log('Error at async function:', err);
                    });
            }
        } else {
            return self.saveWB();
        }
        self.busyMessage = 'Report Download issue';
        return self.toastr.warning('Report download issue', { preventOpenDuplicates: true });
    }

    handleTitleSummary(sheet) {
        const self = this;
        const acc = self.Auth.getCurrentAccountSync();
        const user = self.Auth.getCurrentUserSync();

        sheet.addRow(['Report Date & Time:', `${self.moment()
            .format('DD-MM-YYYY HH:mm:ss')}`]);
        sheet.addRow(['Site:', `${self.selectedSite.alias}`]);
        sheet.addRow(['Account:', `${acc.name}`]);
        sheet.addRow(['Report By:', user.name, user.username]);
        if (self.customFields.length > 0) {
            sheet.addRow([]);
            sheet.addRow(['Custom Fields:']);
            self.customFields.forEach((field) => {
                sheet.addRow([field.fieldName, field.value]);
            });
        }
        sheet.addRow([]);
    }

    processColumnWidths(sheet) {
        const defaultWidth = 10;
        sheet.columns.forEach((column) => {
            let dataMax = 0;
            column.alignment = {
                vertical: 'top',
                horizontal: 'center',
            };
            column.values.forEach((value) => {
                if (value && value.length) {
                    const columnLength = value.length;
                    if (columnLength > dataMax) {
                        dataMax = columnLength + 1;
                    }
                }
            });
            column.width = dataMax < defaultWidth ? defaultWidth : dataMax;
        });
    }

    handleSnapshotDataSheet(workbook, data, titleHandled) {
        const self = this;
        const sheet = workbook.addWorksheet('Install Report');
        sheet.pageSetup.horizontalCentered = true;
        sheet.pageSetup.verticalCentered = true;

        const dataRows = [];
        const snapImages = {};

        if (!titleHandled) {
            self.handleTitleSummary(sheet);
            titleHandled = true;
        }

        const headers = [
            'Snapshot',
            'Zone Alias',
            'Trigger',
            'Timestamp',
            'Unit MAC Address',
        ];

        dataRows.push(headers);
        self.reportSnaps.forEach((snap) => {
            const rowData = [];
            // let snapImg = _.find(results, (o) => o._id === snap._id);
            const rawImg = self.rawImages[snap._id];
            const snapImg = rawImg.img ? rawImg.img : undefined;

            rowData.push('');
            rowData.push(snap.cameraname);
            rowData.push(self.reasons[snap.reason] || 'Unknown');
            rowData.push(self.moment(snap.timestamp)
                .format('YYYY-MM-DD HH:mm:ss ZZ'));
            // arr.push(snap.camera);
            rowData.push(snap.unit);

            snapImages[snap._id] = {
                _id: workbook.addImage({
                    base64: snapImg,
                    extension: 'jpeg',
                }),
            };
            snapImages[snap._id].width = snap.width;
            snapImages[snap._id].height = snap.height;

            dataRows.push(rowData);
        });
        dataRows.forEach((row) => {
            sheet.addRow(row);
        });
        const maxWidth = 400;
        const maxHeight = 400;
        let maxImgWidth = 0;
        self.reportSnaps.forEach((snap, i) => {
            if (Object.hasOwnProperty.call(snapImages, snap._id)) {
                const image = snapImages[snap._id];
                let height;
                let width;
                if (image.height >= image.width) {
                    height = maxHeight;
                    width = maxWidth * (image.width / image.height);
                } else if (image.height < image.width) {
                    height = maxHeight * (image.height / image.width);
                    width = maxWidth;
                }
                if (width > maxImgWidth) {
                    maxImgWidth = width;
                }
                sheet.addImage(image._id, {
                    tl: {
                        col: 0,
                        row: i + 6 + self.customFields.length,
                    },
                    ext: {
                        width,
                        height,
                    },
                });
                sheet.getRow(i + 7 + self.customFields.length).height = height * 0.75;
            }
        });
        self.processColumnWidths(sheet);
        sheet.columns[0].width = maxImgWidth / 7;
        return titleHandled;
    }

    handleMaintenanceDataSheet(workbook, data, timeFrame, titleHandled) {
        const self = this;
        const maintenanceSheet = workbook.addWorksheet('Maintenance Events');
        maintenanceSheet.pageSetup.horizontalCentered = true;
        maintenanceSheet.pageSetup.verticalCentered = true;

        if (!titleHandled) {
            self.handleTitleSummary(maintenanceSheet);
            titleHandled = true;
        }

        const headers = ['Timestamp', 'Type', 'Alias', 'State', 'Event'];
        maintenanceSheet.addRow(['Time Range:', 'From:', `${timeFrame.start}`, 'To:', `${timeFrame.end}`]);
        maintenanceSheet.addRow([]);
        maintenanceSheet.addRow(headers);
        data.forEach((ev) => {
            const rowData = [];
            rowData.push(self.moment(ev.timestamp)
                .format('YYYY-MM-DD HH:mm:ss'));
            if (ev.zone) {
                rowData.push('Zone');
            } else if (ev.unit) {
                rowData.push('Unit');
            } else {
                rowData.push('Site');
            }
            if (ev.unitAlias) {
                rowData.push(ev.unitAlias);
            } else if (ev.zoneAlias) {
                rowData.push(ev.zoneAlias);
            } else {
                rowData.push(self.selectedSite.alias);
            }
            if (ev.state) {
                rowData.push(ev.state.charAt(0)
                    .toUpperCase() + ev.state.slice(1));
                const state = ev.state.charAt(0)
                    .toUpperCase() + ev.state.slice(1);
                const oldState = ev.oldState.charAt(0)
                    .toUpperCase() + ev.oldState.slice(1);
                rowData.push(`${oldState} -> ${state}`);
            } else if (ev.event) {
                rowData.push('N/A');
                rowData.push(ev.event.charAt(0)
                    .toUpperCase() + ev.event.slice(1));
            }
            maintenanceSheet.addRow(rowData);
        });
        self.processColumnWidths(maintenanceSheet);
        return titleHandled;
    }

    handleEventDataSheet(workbook, data, timeFrame, titleHandled) {
        const self = this;
        const eventsSheet = workbook.addWorksheet('All Triggers');
        eventsSheet.pageSetup.horizontalCentered = true;
        eventsSheet.pageSetup.verticalCentered = true;

        if (!titleHandled) {
            self.handleTitleSummary(eventsSheet);
            titleHandled = true;
        }

        const headers = ['Event', 'Zone Alias', 'Timestamp', 'Unit MAC Address'];
        eventsSheet.addRow(['Time Range:', 'From:', `${timeFrame.start}`, 'To:', `${timeFrame.end}`]);
        eventsSheet.addRow([]);
        eventsSheet.addRow(headers);
        data.forEach((snap) => {
            const rowData = [];
            rowData.push(self.reasons[snap.reason] || 'Unknown');
            rowData.push(snap.cameraname);
            rowData.push(self.moment(snap.timestamp)
                .format('YYYY-MM-DD HH:mm:ss ZZ'));
            rowData.push(snap.unit);
            eventsSheet.addRow(rowData);
        });
        self.processColumnWidths(eventsSheet);
        return titleHandled;
    }

    handleZoneConfigSheet(workbook) {
        const self = this;
        const formattedZones = self.getZoneData();
        if (formattedZones.length > 0) {
            const sheet = workbook.addWorksheet('Zone Configuration');
            sheet.pageSetup.horizontalCentered = true;
            sheet.pageSetup.verticalCentered = true;
            ZoneOverviewProcessing.addHeaderBlock(sheet, self.zoneColumns, self.currentAccount, self.selectedSite);
            ZoneOverviewProcessing.populateData(sheet, self.zoneColumns, formattedZones, self);
            ZoneOverviewProcessing.processColumnWidths(sheet);
            ZoneOverviewProcessing.prepareImages(workbook, sheet, formattedZones, self.$document[0]);
        }
    }

    saveWB(eventData, maintenanceData) {
        const self = this;
        const workbook = new exceljs.Workbook();
        const timeFrame = {};
        let titleHandled = false;

        if (self.eventStartDate && self.eventStartTime) {
            timeFrame.start = self.moment(self.eventStartDate)
                .hours(self.eventStartTime.getHours())
                .minutes(self.startTime.getMinutes())
                .format('YYYY-MM-DD HH:mm:ss ZZ');
        }

        if (self.eventEndDate && self.eventEndTime) {
            timeFrame.end = self.moment(self.eventEndDate)
                .hours(self.eventEndTime.getHours())
                .minutes(self.endTime.getMinutes())
                .format('YYYY-MM-DD HH:mm:ss ZZ');
        }

        if (self.reportSnaps.length > 0) {
            titleHandled = self.handleSnapshotDataSheet(workbook, self.reportSnaps, titleHandled);
        }

        if (eventData) {
            titleHandled = self.handleEventDataSheet(workbook, eventData, timeFrame, titleHandled);
        }

        if (maintenanceData) {
            self.handleMaintenanceDataSheet(workbook, maintenanceData, timeFrame, titleHandled);
        }

        if (self.includeZoneConfigs) {
            self.handleZoneConfigSheet(workbook);
        }

        if (eventData || maintenanceData || self.reportSnaps.length > 0 || self.includeZoneConfigs) {
            workbook.xlsx.writeBuffer()
                .then((buffer) => {
                    FileSaver.saveAs(new Blob([buffer]), `${self.selectedSite.alias}_${self.moment()
                        .format('DD_MM_YYYY_HH:mm:ss')}.xlsx`);
                    self.toastr.success('Report Downloaded', { preventOpenDuplicates: true });
                    self.busyMessage = 'Report Downloaded';
                })
                .catch((err) => {
                    self.toastr.error('Report Download Failed', { preventOpenDuplicates: true });
                    self.busyMessage = 'Report Download Failed';
                    console.error('Error while writing buffer', err);
                });
        }
    }

    openDateTimePicker(index, picker, $event) {
        $event.preventDefault();
        $event.stopPropagation();
        this[picker] = !this[picker];
    }

    quickPickOption(option) {
        const self = this;
        const to = this.moment();
        const from = this.moment();
        switch (option) {
        case 'Last Hour':
            self.eventStartTime = from.subtract(1, 'hours')
                .startOf('minute')
                .toDate();
            self.eventStartDate = self.eventStartTime;
            self.eventEndTime = to.startOf('minute')
                .toDate();
            self.eventEndDate = self.eventEndTime;
            break;
        case 'Last 3 Hours':
            self.eventStartTime = from.subtract(3, 'hours')
                .startOf('minute')
                .toDate();
            self.eventStartDate = self.eventStartTime;
            self.eventEndTime = to.startOf('minute')
                .toDate();
            self.eventEndDate = self.eventEndTime;
            break;
        case 'Last 6 Hours':
            self.eventStartTime = from.subtract(6, 'hours')
                .startOf('minute')
                .toDate();
            self.eventStartDate = self.eventStartTime;
            self.eventEndTime = to.startOf('minute')
                .toDate();
            self.eventEndDate = self.eventEndTime;
            break;
        case 'Last 24 Hours':
            self.eventStartTime = from.subtract(24, 'hours')
                .startOf('hour')
                .toDate();
            self.eventStartDate = self.eventStartTime;
            self.eventEndTime = to.startOf('minute')
                .toDate();
            self.eventEndDate = self.eventEndTime;
            break;
        case 'Last 7 Days':
            self.eventStartTime = from.subtract(7, 'days')
                .startOf('hour')
                .toDate();
            self.eventStartDate = self.eventStartTime;
            self.eventEndTime = to.endOf('hour')
                .toDate();
            self.eventEndDate = self.eventEndTime;
            break;
        case 'Last 31 Days':
            self.eventStartTime = from.subtract(31, 'days')
                .startOf('hour')
                .toDate();
            self.eventStartDate = self.eventStartTime;
            self.eventEndTime = to.endOf('hour')
                .toDate();
            self.eventEndDate = self.eventEndTime;
            break;
        case 'Today':
            self.eventEndDate = to.add(1, 'days')
                .startOf('day')
                .toDate();
            self.eventEndTime = self.eventEndDate;
            self.eventStartTime = from.startOf('day')
                .toDate();
            self.eventStartDate = self.eventStartTime;
            break;
        default:
        }
    }

    quickPick(option) {
        const self = this;
        const to = this.moment();
        const from = this.moment();
        switch (option) {
        case 'Last Hour':
            self.startTime = from.subtract(1, 'hours')
                .startOf('minute')
                .toDate();
            self.startDate = self.startTime;
            self.endTime = to.startOf('minute')
                .toDate();
            self.endDate = self.endTime;
            break;
        case 'Last 3 Hours':
            self.startTime = from.subtract(3, 'hours')
                .startOf('minute')
                .toDate();
            self.startDate = self.startTime;
            self.endTime = to.startOf('minute')
                .toDate();
            self.endDate = self.endTime;
            break;
        case 'Last 6 Hours':
            self.startTime = from.subtract(6, 'hours')
                .startOf('minute')
                .toDate();
            self.startDate = self.startTime;
            self.endTime = to.startOf('minute')
                .toDate();
            self.endDate = self.endTime;
            break;
        case 'Last 24 Hours':
            self.startTime = from.subtract(24, 'hours')
                .startOf('minute')
                .toDate();
            self.startDate = self.startTime;
            self.endTime = to.startOf('minute')
                .toDate();
            self.endDate = self.endTime;
            break;
        case 'Last 7 Days':
            self.startTime = from.subtract(7, 'days')
                .startOf('hour')
                .toDate();
            self.startDate = self.startTime;
            self.endTime = to.endOf('hour')
                .toDate();
            self.endDate = self.endTime;
            break;
        case 'Last 31 Days':
            self.startTime = from.subtract(31, 'days')
                .startOf('hour')
                .toDate();
            self.startDate = self.startTime;
            self.endTime = to.endOf('hour')
                .toDate();
            self.endDate = self.endTime;
            break;
        case 'Today':
            self.endDate = to.add(1, 'days')
                .startOf('day')
                .toDate();
            self.endTime = self.endDate;
            self.startTime = from.startOf('day')
                .toDate();
            self.startDate = self.startTime;
            break;
        default:
        }
    }

    downloadHBTimeline() {
        const self = this;
        let tsStart;
        if (self.startDate && self.startTime) {
            tsStart = +self.moment(self.startDate)
                .hours(self.startTime.getHours())
                .minutes(self.startTime.getMinutes());
        }
        let tsEnd;
        if (self.endDate && self.endTime) {
            tsEnd = +self.moment(self.endDate)
                .hours(self.endTime.getHours())
                .minutes(self.endTime.getMinutes());
        }
        self.$http.get('/api/heartbeats/timelineReports', {
            params: {
                startTime: tsStart,
                endTime: tsEnd,
                site: self.selectedSite._id,
            },
        })
            .then((response) => {
                const {
                    camStats,
                    uStories,
                    cStories,
                    camNames,
                    unitStats,
                } = response.data;

                const summaryArrs = [];
                const headerHeaders = ['Start Time', 'End Time', 'Duration Under Investigation'];

                summaryArrs.push(headerHeaders);
                let didHeader = false;
                const unitStatsKeys = Object.keys(unitStats);
                unitStatsKeys.forEach((unit) => {
                    if (!didHeader) {
                        const duration = tsEnd - tsStart;
                        summaryArrs.push([
                            unitStats[unit].firstEntryTime.toString(),
                            unitStats[unit].lastEntryTime.toString(),
                            self.printDuration(duration),
                        ]);
                        const statsHeaders = ['Unit', '', 'Live Count', 'Live Duration(total)', 'Live Duration(avg)', 'Boot Count'];
                        summaryArrs.push(statsHeaders);
                        didHeader = true;
                    }
                    const liveDuration = self.printDuration(unitStats[unit].liveDuration);
                    const liveAvg = self.printDuration(Math.floor(unitStats[unit].liveDuration / unitStats[unit].live));
                    const row = [
                        unit,
                        '',
                        unitStats[unit].live,
                        liveDuration,
                        liveAvg,
                        unitStats[unit].boots,
                    ];
                    summaryArrs.push(row);
                });

                summaryArrs.push([]);
                summaryArrs.push([]);
                const camHeaders = [
                    'Unit',
                    'Camera',
                    'Live Count',
                    'Live Duration(total)',
                    'Live Duration(avg)',
                    'Unknown Count',
                    'Unknown Duration(total)',
                    'Unknown Duration(avg)',
                    'Comms Count', 'Comms Duration(total)',
                    'Comms Duration(avg)',
                    'Auth Count', 'Auth Duration(total)',
                    'Auth Duration(avg)',
                ];
                summaryArrs.push(camHeaders);

                const camStatsKeys = Object.keys(camStats);
                camStatsKeys.forEach((cam) => {
                    const liveDuration = self.printDuration(camStats[cam].durations.live);
                    const unknownDuration = self.printDuration(camStats[cam].durations.unknown);
                    const authDuration = self.printDuration(camStats[cam].durations.auth);
                    const commsDuration = self.printDuration(camStats[cam].durations.comms);

                    const liveAvg = self.printDuration(Math.floor(camStats[cam].durations.live / camStats[cam].counts.live));
                    const unknownAvg = self.printDuration(Math.floor(camStats[cam].durations.unknown / camStats[cam].counts.unknown));
                    const authAvg = self.printDuration(Math.floor(camStats[cam].durations.auth / camStats[cam].counts.auth));
                    const commsAvg = self.printDuration(Math.floor(camStats[cam].durations.comms / camStats[cam].counts.comms));

                    const row = [
                        camStats[cam].unit,
                        camNames[cam],
                        camStats[cam].counts.live,
                        liveDuration,
                        liveAvg,
                        camStats[cam].counts.unknown,
                        unknownDuration,
                        unknownAvg,
                        camStats[cam].counts.comms,
                        commsDuration,
                        commsAvg,
                        camStats[cam].counts.auth,
                        authDuration,
                        authAvg,
                    ];
                    summaryArrs.push(row);
                });

                const workbook = new exceljs.Workbook();
                const sheet = workbook.addWorksheet('Test sheet');
                sheet.pageSetup.horizontalCentered = true;
                sheet.pageSetup.verticalCentered = true;
                summaryArrs.forEach((row) => {
                    sheet.addRow(row);
                });

                let i = 1;
                const uStoriesKeys = Object.keys(uStories);
                uStoriesKeys.forEach((unit) => {
                    const uSheet = workbook.addWorksheet(`Unit ${i}`);
                    for (let z = 0; z < uStories[unit].length; z++) {
                        const row = uStories[unit][z];
                        if (z >= 3) {
                            if (row[1] !== '') {
                                row[1] = self.moment(row[1])
                                    .format('DD_MM_YYYY_HH:mm:ss');
                            }
                            if (row[1] !== '') {
                                row[2] = self.moment(row[2])
                                    .format('DD_MM_YYYY_HH:mm:ss');
                            }
                        }
                        uSheet.addRow(row);
                    }
                    i++;
                });
                let k = 1;
                const cHeaders = ['State', 'Timestamp(local)', 'Duration', 'Last Activity', 'Last Frame', 'Unit Boot?', 'Prev. Heartbeat'];
                const cStoriesKeys = Object.keys(cStories);
                cStoriesKeys.forEach((cam) => {
                    const cSheet = workbook.addWorksheet(`Cam ${k}`);
                    cSheet.addRow(['Camera: ', `${camNames[cam]}`]);
                    cSheet.addRow(cHeaders);
                    cStories[cam].forEach((row) => {
                        if (row[1] !== '') {
                            row[1] = self.moment(row[1])
                                .format('YYYY_MM_DD HH:mm:ss');
                        }
                        if (row[3] !== '') {
                            row[3] = self.moment(row[3])
                                .format('YYYY_MM_DD HH:mm:ss');
                        }
                        if (row[4] !== '') {
                            row[4] = self.moment(row[4])
                                .format('YYYY_MM_DD HH:mm:ss');
                        }
                        if (row[5] !== '') {
                            row[5] = self.moment(row[5])
                                .format('YYYY_MM_DD HH:mm:ss');
                        }
                        if (row[6] !== '') {
                            row[6] = self.moment(row[6])
                                .format('YYYY_MM_DD HH:mm:ss');
                        }
                        cSheet.addRow(row);
                    });
                    k++;
                });

                workbook.xlsx.writeBuffer()
                    .then((buffer) => {
                        FileSaver.saveAs(new Blob([buffer]), `${self.selectedSite.alias}_${self.moment()
                            .format('DD_MM_YYYY_HH:mm:ss')}.xlsx`);
                    })
                    .catch((err) => {
                        console.log('Error while writing buffer', err);
                    });
            });
    }

    getRawFromSnap(id) {
        const self = this;
        const img = self.canvases[id].toDataURL({ format: 'jpeg' });
        return { img };
    }

    addRemoveSnap(snap) {
        const self = this;
        if (snap.selected) {
            self.removeFromReport(snap);
        } else {
            self.addToReport(snap);
        }
    }

    addToReport(snapshot) {
        const self = this;
        self.reportIds.push(snapshot._id);
        self.previewOpen = true;
        self.reportSnaps.push(snapshot);
        self.previewTableParams.reload();
        snapshot.selected = true;
        if (!self.reportOpen) {
            self.reportOpen = true;
        }
        self.rawImages[snapshot._id] = self.getRawFromSnap(snapshot._id);
    }

    removeFromReport(snapshot) {
        const self = this;
        const index = self.reportIds.indexOf(snapshot._id);
        snapshot.selected = false;
        if (index !== -1) {
            self.reportIds.splice(index, 1);
        } else {
            console.error('No snapshot?');
        }
        if (index !== -1) {
            const reportIndex = _.findIndex(self.reportSnaps, (o) => o._id === snapshot._id);
            if (reportIndex !== -1) {
                self.reportSnaps.splice(reportIndex, 1);
            }
            const installIndex = _.findIndex(self.installSnaps, (o) => o._id === snapshot._id);
            if (installIndex !== -1) {
                self.installSnaps[installIndex].selected = false;
            }
        }
        delete self.rawImages[snapshot._id];
        self.previewTableParams.reload();
    }

    dateFormat() {
        if (this.chosenResolution === 'Monthly') {
            return 'yyyy/MM';
        }
        return 'yyyy/MM/dd';
    }

    openSnap(snapshot) {
        this.$uibModal.open({
            animation: true,
            backdrop: true,
            template: SnapshotModalTemplate,
            controller: SnapshotModalController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
                snapshot() {
                    return snapshot;
                },
                snapPlaceholder() {
                    return [];
                },
                overlay() {
                    return undefined;
                },
            },
        })
            .result
            .then(
                () => {},
                () => {},
            );
    }

    newCustomField() {
        this.customFields.push({
            fieldName: '',
            value: '',
        });
    }

    removeCustomField(index) {
        this.customFields.splice(index, 1);
    }
}

export default angular.module('cameraViewerApp.operationalReport')
    .component('operationalReport', {
        template: OperationalReportTemplate,
        controller: OperationalReportComponent,
        controllerAs: '$ctrl',
    })
    .name;
