// import tangram from 'tangram/dist/tangram.min.js';

export class MinimapModalComponent {
    /* @ngInject */
    constructor($timeout, toastr) {
        this.$timeout = $timeout;
        this.toastr = toastr;
    }

    $onInit() {
        const self = this;

        self.loc = { lat: '', lng: '' };

        self.address = {};

        self.myMarker;

        self.defaultMarker = L.icon({
            iconUrl: 'assets/images/markers/marker-icon.png',
        });

        self.map = L.map('minimap', {
            zoomControl: false,
            drawControl: false,
            scrollWheelZoom: true,
        });

        self.markerLayer = L.layerGroup().addTo(self.map);

        L.Icon.Default.imagePath = 'assets/images/markers/';

        // L.control.geocoder('mapzen-PWpm35S').addTo(self.map).setPosition('topright');
        const geocoder = L.control.geocoder('mapzen-PWpm35S', {
            position: 'topleft',
            markers: false,
        }).addTo(self.map);

        geocoder.on('select', ($event) => {
            if ($event.target) {
                // console.log("You have just made a selection: ", $event);

                self.address.street = $event.feature.properties.name;
                self.address.locality = $event.feature.properties.locality || '';

                if (self.myMarker) {
                    self.myMarker.setLatLng($event.latlng);
                } else {
                    self.myMarker = L.marker($event.latlng, { draggable: true }).addTo(self.map);
                    self.myMarker.on('dragend', self.handleMarkerDragged.bind(self));
                }
                self.setLatLng(self.myMarker.getLatLng());

                // Due to some weird bug that disables scrollWheelZoom
                if (!self.map.scrollWheelZoom.enabled()) {
                    self.map.scrollWheelZoom.enable();
                }
            }
        });

        geocoder.on('reset', ($event) => {
            // console.log("You have just resetted ", $event);
        });

        const layer = Tangram.leafletLayer({
            scene: 'cinnabar-style-more-labels.zip',
            attribution: '<a href="https://mapzen.com/tangram" target="_blank">Tangram</a> | &copy; OSM contributors | <a href="https://mapzen.com/" target="_blank">Mapzen</a>',
        }).addTo(self.map);

        new L.Control.Zoom({ position: 'bottomright' }).addTo(self.map);
        self.map.setView([-26.6145, 27.0950], 5);

        self.$timeout(() => {
            self.map.invalidateSize();
            if (self.resolve) {
                if (self.resolve.loc && self.resolve.loc.lat && self.resolve.loc.lng) {
                    self.myMarker = L.marker(self.resolve.loc, { draggable: true }).addTo(self.map);
                    self.myMarker.on('dragend', self.handleMarkerDragged.bind(self));
                    self.map.setView(self.resolve.loc, 5);
                    self.setLatLng(self.resolve.loc);
                }
            }
        });

        self.map.on('click', ($event) => {
            // console.log("you've clicked on the map ", $event);
            const { latlng } = $event;
            self.setLatLng(latlng);
        });

        self.map.scrollWheelZoom.enable();
    }

    $onDestroy() {
        const self = this;
        self.map.remove();
    }

    handleMarkerDragged($event) {
        const self = this;
        // console.log("The marker has been dragged");
        const latlng = self.myMarker.getLatLng();
        self.setLatLng(latlng);
        // console.log(self.map.scrollWheelZoom.enabled());
    }

    setLatLng(latlng) {
        // console.log("setLatLng being called with ", latlng);
        const self = this;
        self.loc.lat = latlng.lat;
        self.loc.lng = latlng.lng;
    }

    doLog() {
        const self = this;
        console.log(self);
    }

    dismissModal() {
        const self = this;
        self.modalInstance.dismiss('User close');
    }

    closeModal() {
        const self = this;

        if (self.loc.lat === '' || self.loc.lng === '') {
            self.toastr.error('Please make sure to choose valid coordinates', 'No Coordinates', {
                preventOpenDuplicates: true,
            });

            return;
        }
        const result = self.loc;
        result.address = self.address;
        self.modalInstance.close(result);
    }

    changeLatLng() {
        const self = this;
        const latlng = self.loc;

        if (latlng.lat !== '' && latlng.lng !== '') {
            if (self.myMarker) {
                self.myMarker.setLatLng(latlng);
            } else {
                self.myMarker = L.marker(latlng, { draggable: true }).addTo(self.map);
                self.myMarker.on('dragend', self.handleMarkerDragged.bind(self));
            }
        }
    }
}

export default angular.module('cameraViewerApp.sites')
    .component('minimapmodal', {
        template: require('./minimap-modal.html'),
        controller: MinimapModalComponent,
        controllerAs: '$ctrl',
        bindings: {
            resolve: '<',
            modalInstance: '<',
        },
    })
    .name;
