import angular from 'angular';

angular.module('cameraViewerApp.directives')
    .directive('percentageFormatter', ['$document',
        function ($document) {
            return {
                restrict: 'A',
                require: 'ngModel',
                link(scope, el, attrs, modelCtrl) {
                    // Changes the user input from whole number to float representation for percentage (50 to 0.5)
                    modelCtrl.$parsers.push((modelValue) => modelValue / 100);

                    // Changes the ng-model from float to rounded whole number representation for percentage (0.551 to 55)
                    modelCtrl.$formatters.push((modelValue) => Math.round(modelValue * 100));
                },
            };
        },
    ]);
