import * as xlsx from 'xlsx';
import _ from 'lodash-es';
import angular from 'angular';

export class BIReportComponent {
    $http;
    $scope;
    $state;
    availableSites = [];
    selectedSites = [];
    moment;
    self;
    toastr;
    quickReportHour = ['Last 24 Hours', 'Last 7 Days', 'Last 31 Days', 'Today', 'This Week', 'This Month', 'This Year'];
    quickReportMonth = ['Last Month', 'This Month', 'This Year'];

    /* @ngInject */
    constructor($state, $rootScope, moment, $scope, $http, socket, Auth, toastr) {
        this.$state = $state;
        this.moment = moment;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.busyMessage = '';
        this.socket = socket;
        this.$http = $http;
        this.Auth = Auth;
        this.getCurrentUser = Auth.getCurrentUserSync;
        this.currentRouteView = '';
        this.currentRooms = [];
        this.toastr = toastr;

        this.availableReportTypes = [
            { key: 'report_type_i', text: 'Type 1' },
            { key: 'report_type_ii', text: 'Type 2' },
            { key: 'report_type_iii', text: 'Type 3' },
            { key: 'report_type_iv', text: 'Type 4' },
            { key: 'report_type_v', text: 'Queue Counting' },
        ];
        this.reportType = 'report_type_i';

        this.availableFileTypes = [
            { key: 'xlsx', text: '.xlsx' },
            { key: 'csv', text: '.csv' },
        ];
        this.fileType = 'xlsx';

        this.masterZoneContainer = {
            entranceZones: [],
            exitZones: [],
            branchZones: [],
            ATMZones: [],
            queueZones: [],
            countingLines: [],
        };

        this.currentZoneContainer = this.masterZoneContainer;

        this.availableZones = [];
        this.availableSites = [];
        this.selectableSites = [{ alias: 'All Sites', _id: 'all' }];
        this.selectedSite = { alias: 'All Sites', _id: 'all', index: 0 };

        this.timePoint = moment()
            .endOf('month')
            .toDate();

        const self = this;
        this.$scope.$on('$destroy', () => {
            socket.unsyncUpdates('site');
            socket.unsyncUpdates('camera');
            if (self.currentRooms && self.currentRooms.length > 0) {
                self.currentRooms.forEach((room) => {
                    socket.leaveRoom(room);
                });
            }
        });
    }

    doLog() {
        console.log(this);
    }

    updateZoneContainer() {
        const self = this;

        if (self.selectedSite._id === 'all') {
            self.currentZoneContainer = self.masterZoneContainer;
        } else {
            const site = _.find(self.availableSites, { _id: self.selectedSite._id });
            self.currentZoneContainer = site.zoneContainer;
        }
    }

    $onInit() {
        const self = this;

        self.$http.get('/api/metrics/fetchBIReportParams')
            .then((response) => {
                if (response.data) {
                    self.availableSites = response.data.siteZoneContainers;
                    self.selectableSites = response.data.selectableSites;
                    self.masterZoneContainer = response.data.masterZoneContainer;
                    self.currentZoneContainer = self.masterZoneContainer;
                }
            });
    }

    issueDataRequest(inputStruct, timePoint) {
        const self = this;

        self.busyMessage = 'Requesting data...';
        const timezoneOffset = new Date().getTimezoneOffset();
        const params = {
            inputStruct,
            timezoneOffset,
            timePoint,
        };

        this.$http.post('/api/metrics/createBiReport', params)
            .then((response) => {
                if (response.data) {
                    // console.log(response);
                    const accountName = self.Auth.getCurrentAccountSync().name;
                    const outputTime = self.moment()
                        .format('MM-YYYY');

                    // Populate sheets
                    const sheets = [];

                    response.data.forEach((sheetItem, i) => {
                        sheets.push({
                            data: xlsx.utils.aoa_to_sheet(sheetItem.data),
                            title: sheetItem.title.length < 32 ? sheetItem.title : `Page${i}`,
                        });
                    });

                    if (self.fileType === 'xlsx') {
                        // Output standard spreadsheet
                        const wb = xlsx.utils.book_new();
                        sheets.forEach((item) => {
                            xlsx.utils.book_append_sheet(wb, item.data, item.title);
                        });

                        xlsx.write(wb, { bookType: 'xlsx', bookSST: true, type: 'base64' });
                        let title;
                        if (self.selectedSite._id === 'all') {
                            title = `${accountName}_${self.reportType}_BI_Data_Export_${outputTime}.xlsx`;
                        } else {
                            title = `${accountName}_${self.selectedSite.alias}_${self.reportType}_BI_Data_Export_${outputTime}.xlsx`;
                        }
                        xlsx.writeFile(wb, title.replaceAll(' ', '_'));
                    } else {
                        // Output delimiter separated files
                        sheets.forEach((item) => {
                            const wb = xlsx.utils.book_new();
                            let title;
                            if (self.selectedSite._id === 'all') {
                                title = `${accountName}_${item.title}_${outputTime}.csv`;
                            } else {
                                title = `${accountName}_${self.selectedSite.alias}_${item.title}_${outputTime}.csv`;
                            }
                            xlsx.utils.book_append_sheet(wb, item.data);
                            xlsx.writeFile(wb, title.replaceAll(' ', '_'), { bookType: 'csv' });
                        });
                    }
                    self.busyMessage = 'Request Complete';
                }
            })
            .catch((err) => {
                console.log('This is the err', err);
                self.busyMessage = 'Request Failed';
                if (err.status === 504) {
                    self.busyMessage = 'Process Timed Out..';
                    self.toastr.error('Process Timed Out..', {
                        preventOpenDuplicates: true,
                    });
                }
            });
    }

    getReport() {
        const self = this;

        const sheetTypes = {
            report_type_i: [
                {
                    name: 'Site Entries by Time and Day',
                    key: 'lines',
                    list: self.currentZoneContainer.countingLines,
                    metricList: ['People Count: A to B'],
                    reportLevel: 'Line',
                    groupBy: 'Site',
                    resolution: 'Hourly',
                },
                {
                    name: 'FR vs New Collections By Site',
                    key: 'fr_collections',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Recognitions', 'FR New', 'FR Unknown'],
                    reportLevel: 'Zone',
                    groupBy: 'Site',
                    resolution: 'Daily',
                },
                {
                    name: 'ATM Pinhole FR Metadata',
                    key: 'atm_meta',
                    list: self.currentZoneContainer.ATMZones,
                    metricList: ['FR Metadata'],
                    reportLevel: 'Zone',
                    groupBy: 'Site',
                    resolution: 'Daily',
                },
                {
                    name: 'ATM Pinhole FRMeta Rec-New',
                    key: 'atm_meta_split',
                    list: self.currentZoneContainer.ATMZones,
                    metricList: ['FR Metadata Split'],
                    reportLevel: 'Zone',
                    groupBy: 'Site',
                    resolution: 'Daily',
                },
                {
                    name: 'Branch Pinhole FR Metadata',
                    key: 'branch_meta',
                    list: self.currentZoneContainer.branchZones,
                    metricList: ['FR Metadata'],
                    reportLevel: 'Zone',
                    groupBy: 'Site',
                    resolution: 'Daily',
                },
                {
                    name: 'Branch Pinhole FRMeta Rec-New',
                    key: 'branch_meta_split',
                    list: self.currentZoneContainer.branchZones,
                    metricList: ['FR Metadata Split'],
                    reportLevel: 'Zone',
                    groupBy: 'Site',
                    resolution: 'Daily',
                },
            ],
            report_type_ii: [
                {
                    name: 'Branch Daily Enter FR Emotions',
                    key: 'branch_enter_emotion',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Emotion'],
                    reportLevel: 'Zone',
                    groupBy: 'Account',
                    resolution: 'Daily',
                },
                {
                    name: 'Branch Daily Exit FR Emotions',
                    key: 'branch_exit_emotion',
                    list: self.currentZoneContainer.exitZones,
                    metricList: ['FR Emotion'],
                    reportLevel: 'Zone',
                    groupBy: 'Account',
                    resolution: 'Daily',
                },
                {
                    name: 'Branch Monthly FR Emotions',
                    key: 'month_emotion',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Emotion'],
                    reportLevel: 'Zone',
                    groupBy: 'Account',
                    resolution: 'Monthly',
                },
                {
                    name: 'Branch FR Emotions Rec-New',
                    key: 'month_emotion_split',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Emotion Split'],
                    reportLevel: 'Zone',
                    groupBy: 'Account',
                    resolution: 'Monthly',
                },
                {
                    name: 'Branch Daily FR Age',
                    key: 'fr_age',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Age'],
                    reportLevel: 'Zone',
                    groupBy: 'Account',
                    resolution: 'Daily',
                },
                {
                    name: 'Branch Daily FR Age Rec-New',
                    key: 'fr_age_split',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Age Split'],
                    reportLevel: 'Zone',
                    groupBy: 'Account',
                    resolution: 'Daily',
                },
                {
                    name: 'Branch Daily FR Gender',
                    key: 'fr_gender',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Gender'],
                    reportLevel: 'Zone',
                    groupBy: 'Account',
                    resolution: 'Daily',
                },
                {
                    name: 'Branch Daily FR Gender Rec-New',
                    key: 'fr_gender_split',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Gender Split'],
                    reportLevel: 'Zone',
                    groupBy: 'Account',
                    resolution: 'Daily',
                },
                {
                    name: 'Branch Entries by Month',
                    key: 'lines_monthly',
                    list: self.currentZoneContainer.countingLines,
                    metricList: ['People Count: A to B'],
                    reportLevel: 'Line',
                    groupBy: 'Account',
                    resolution: 'Monthly',
                },
                {
                    name: 'Branch Entries by Time of Day',
                    key: 'lines_working_hours',
                    list: self.currentZoneContainer.countingLines,
                    metricList: ['People Count: A to B'],
                    reportLevel: 'Line',
                    groupBy: 'Account',
                    resolution: 'MonthDay',
                },
            ],
            report_type_iii: [
                {
                    name: 'Monthly FR vs New',
                    key: 'fr_collections',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Recognitions', 'FR New', 'FR Unknown'],
                    reportLevel: 'Zone',
                    groupBy: 'Account',
                    resolution: 'Monthly',
                },
                {
                    name: 'Monthly Entries by Time of Day',
                    key: 'lines_working_hours',
                    list: self.currentZoneContainer.countingLines,
                    metricList: ['People Count: A to B'],
                    reportLevel: 'Line',
                    groupBy: 'Account',
                    resolution: 'MonthDay',
                },
                {
                    name: 'Daily Entry FR Gender',
                    key: 'fr_gender',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Gender'],
                    reportLevel: 'Zone',
                    groupBy: 'Account',
                    resolution: 'Daily',
                },
                {
                    name: 'Daily Entry FR Gender Rec-New',
                    key: 'fr_gender_split',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Gender Split'],
                    reportLevel: 'Zone',
                    groupBy: 'Account',
                    resolution: 'Daily',
                },
                {
                    name: 'Daily Entry FR Age',
                    key: 'fr_age',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Age'],
                    reportLevel: 'Zone',
                    groupBy: 'Account',
                    resolution: 'Daily',
                },
                {
                    name: 'Daily Entry FR Age Rec-New',
                    key: 'fr_age_split',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Age Split'],
                    reportLevel: 'Zone',
                    groupBy: 'Account',
                    resolution: 'Daily',
                },
                {
                    name: 'Daily Entry FR Emotions',
                    key: 'entry_emotion',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Emotion'],
                    reportLevel: 'Zone',
                    groupBy: 'Account',
                    resolution: 'Daily',
                },
                {
                    name: 'Daily Entry FR Emotions Rec-New',
                    key: 'entry_emotion_split',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Emotion Split'],
                    reportLevel: 'Zone',
                    groupBy: 'Account',
                    resolution: 'Daily',
                },
                {
                    name: 'Daily Exit FR Emotions',
                    key: 'exit_emotion',
                    list: self.currentZoneContainer.exitZones,
                    metricList: ['FR Emotion'],
                    reportLevel: 'Zone',
                    groupBy: 'Account',
                    resolution: 'Daily',
                },
                {
                    name: 'Daily Exit FR Emotions Rec-New',
                    key: 'exit_emotion_split',
                    list: self.currentZoneContainer.exitZones,
                    metricList: ['FR Emotion Split'],
                    reportLevel: 'Zone',
                    groupBy: 'Account',
                    resolution: 'Daily',
                },
                {
                    name: 'Daily Entries per Month',
                    key: 'lines',
                    list: self.currentZoneContainer.countingLines,
                    metricList: ['People Count: A to B'],
                    reportLevel: 'Line',
                    groupBy: 'Account',
                    resolution: 'Daily',
                },
            ],
            report_type_iv: [
                {
                    name: 'FR Age',
                    key: 'fr_age',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Age'],
                    reportLevel: 'Zone',
                    groupBy: 'Site',
                    resolution: 'Daily',
                },
                {
                    name: 'FR Age Rec-New',
                    key: 'fr_age_split',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Age Split'],
                    reportLevel: 'Zone',
                    groupBy: 'Site',
                    resolution: 'Daily',
                },
                {
                    name: 'Enter FR Emotions',
                    key: 'enter_emotions',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Emotion'],
                    reportLevel: 'Zone',
                    groupBy: 'Site',
                    resolution: 'Daily',
                },
                {
                    name: 'Enter FR Emotions Rec-New',
                    key: 'enter_emotions_split',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Emotion Split'],
                    reportLevel: 'Zone',
                    groupBy: 'Site',
                    resolution: 'Daily',
                },
                {
                    name: 'Exit FR Emotions',
                    key: 'exit_emotions',
                    list: self.currentZoneContainer.exitZones,
                    metricList: ['FR Emotion'],
                    reportLevel: 'Zone',
                    groupBy: 'Site',
                    resolution: 'Daily',
                },
                {
                    name: 'Exit FR Emotions Rec-New',
                    key: 'exit_emotions_split',
                    list: self.currentZoneContainer.exitZones,
                    metricList: ['FR Emotion Split'],
                    reportLevel: 'Zone',
                    groupBy: 'Site',
                    resolution: 'Daily',
                },
                {
                    name: 'Monthly FR Emotions',
                    key: 'month_emotions',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Emotion'],
                    reportLevel: 'Zone',
                    groupBy: 'Site',
                    resolution: 'Monthly',
                },
                {
                    name: 'Monthly FR Emotions Rec-New',
                    key: 'month_emotions_split',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Emotion Split'],
                    reportLevel: 'Zone',
                    groupBy: 'Site',
                    resolution: 'Monthly',
                },
                {
                    name: 'FR vs New',
                    key: 'fr_recognitions',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Recognitions', 'FR New', 'FR Unknown'],
                    reportLevel: 'Zone',
                    groupBy: 'Site',
                    resolution: 'Daily',
                },
                {
                    name: 'FR Gender',
                    key: 'fr_gender',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Gender'],
                    reportLevel: 'Zone',
                    groupBy: 'Site',
                    resolution: 'Monthly',
                },
                {
                    name: 'FR Gender Rec-New',
                    key: 'fr_gender_split',
                    list: self.currentZoneContainer.entranceZones,
                    metricList: ['FR Gender Split'],
                    reportLevel: 'Zone',
                    groupBy: 'Site',
                    resolution: 'Monthly',
                },
                {
                    name: 'Entries by Month',
                    key: 'lines_monthly',
                    list: self.currentZoneContainer.countingLines,
                    metricList: ['People Count: A to B'],
                    reportLevel: 'Line',
                    groupBy: 'Site',
                    resolution: 'Monthly',
                },
                {
                    name: 'Entries by Time of Day',
                    key: 'lines_working_hours',
                    list: self.currentZoneContainer.countingLines,
                    metricList: ['People Count: A to B'],
                    reportLevel: 'Line',
                    groupBy: 'Site',
                    resolution: 'MonthDay',
                },
            ],
            report_type_v: [
                {
                    name: 'Hourly Queue Length',
                    key: 'queue_hourly',
                    list: self.currentZoneContainer.queueZones,
                    metricList: ['Queue Count'],
                    reportLevel: 'Zone',
                    groupBy: 'Site',
                    resolution: 'Hourly',
                },
                {
                    name: 'Queue Length per Snapshot',
                    key: 'queue_all',
                    list: self.currentZoneContainer.queueZones,
                    metricList: ['Queue Snapshots'],
                    reportLevel: 'Zone',
                    groupBy: 'Site',
                    resolution: 'Hourly',
                },
            ],
        };

        const sheetList = sheetTypes[self.reportType];

        const lineOptions = ['lines', 'lines_monthly', 'lines_working_hours'];

        if (self.currentZoneContainer.entranceZones.length > 0 || self.currentZoneContainer.exitZones.length > 0
            || self.currentZoneContainer.ATMZones.length > 0 || self.currentZoneContainer.queueZones.length > 0
            || self.currentZoneContainer.countingLines.length > 0) {
            const inputStructure = {};
            sheetList.forEach((sheet) => {
                const siteList = [];
                const zoneList = [];

                sheet.list.forEach((zone) => {
                    if (lineOptions.includes(sheet.key)) {
                        zoneList.push(zone.id);
                    } else {
                        zoneList.push(zone._id);
                    }
                    if (!siteList.includes(zone.site)) {
                        siteList.push(zone.site);
                    }
                });

                if (zoneList.length > 0 && siteList.length > 0) {
                    inputStructure[sheet.key] = {
                        name: sheet.name,
                        key: sheet.key,
                        type: sheet.reportLevel,
                        group: sheet.groupBy,
                        resolution: sheet.resolution,
                        sites: siteList,
                        zones: zoneList,
                        metrics: sheet.metricList,
                    };
                }
            });

            self.issueDataRequest(inputStructure, +self.moment(self.timePoint));
        } else {
            self.toastr.warning('Account has no sites or zones configured for BI reporting', {
                preventOpenDuplicates: true,
            });
        }
    }

    openDateTimePicker(index, picker, $event) {
        $event.preventDefault();
        $event.stopPropagation();
        this[picker] = !this[picker];
    }
}

export default angular.module('cameraViewerApp.biReport')
    .component('biReport', {
        template: require('./biReport.html'),
        controller: BIReportComponent,
        controllerAs: '$ctrl',
    })
    .name;
