export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.apiKeys', {
            url: '/apiKeys',
            views: {
                'content@main': {
                    template: '<api-keys></api-keys>',
                },
            },
            authenticate: 'ApiKey',
            data: {
                title: 'API Keys',
            },
        });
}
