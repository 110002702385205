const commands = [
    {
        name: 'PM2 Status',
        command: 'pm2 status',
        description: 'Shows the status and summary of the main unit process',
        usage: 'pm2 status',
        example: 'pm2 status',
        varLength: 0,
        vars: [],
    }, {
        name: 'IP Address',
        command: 'ip addr',
        description: 'Returns the IP and MAC addresses of the unit',
        usage: 'ip addr',
        example: 'ip addr',
        varLength: 0,
        vars: [],
    }, {
        name: 'IP Neighbour',
        command: 'ip neighbour',
        description: 'Returns the current neighbour table of the unit',
        usage: 'ip neighbour',
        example: 'ip neighbour',
        varLength: 0,
        vars: [],
    }, {
        name: 'IP Route',
        command: 'ip route',
        description: 'Returns current routing table',
        usage: 'ip route',
        example: 'ip route',
        varLength: 0,
        vars: [],
    }, {
        name: 'Ping',
        command: 'ping',
        description: 'Pings a chosen IP address',
        usage: 'ping -c 3 ipAddress',
        example: 'ping -c 3 8.8.8.8',
        varLength: 1,
        vars: ['8.8.8.8'],
    }, {
        name: 'Arp-Scan',
        command: 'arp-scan --quiet --localnet --numeric',
        description: 'Returns all IP addresses on the network',
        usage: 'arp-scan --quiet --localnet --numeric',
        example: 'arp-scan --quiet --localnet --numeric',
        varLength: 0,
        vars: [],
    }, {
        name: 'Traceroute',
        command: 'traceroute -n',
        description: 'Traces the route that packets take to reach a specified destination',
        usage: 'traceroute -n ipAddress',
        example: 'traceroute -n 8.8.8.8',
        varLength: 1,
        vars: ['8.8.8.8'],
    }, {
        name: 'Top',
        command: 'top -n 1',
        description: 'Shows a snapshot of resource usage on the unit',
        usage: 'top -n 1',
        example: 'top -n 1',
        varLength: 0,
        vars: [],
    }, {
        name: 'Free',
        command: 'free -m',
        description: 'Shows hard drive space usage (in MB with the -m flag)',
        usage: 'free -m',
        example: 'free -m',
        varLength: 0,
        vars: [],
    }, {
        name: 'Uptime',
        command: 'uptime',
        description: 'Shows load average and runtime of unit',
        usage: 'uptime',
        example: 'uptime',
        varLength: 0,
        vars: [],
    }, {
        name: 'Socket Stats',
        command: 'ss -ntupola',
        description: 'Shows socket stats',
        usage: 'ss -ntupola',
        example: 'ss -ntupola',
        varLength: 0,
        vars: [],
    }, {
        name: 'LSBLK',
        command: 'lsblk',
        description: 'List block devices',
        usage: 'lsblk',
        example: 'lsblk',
        varLength: 0,
        vars: [],
    }, {
        name: 'Test RTSP',
        command: 'ffprobe -hide_banner',
        description: 'Test an RTSP link',
        usage: 'ffprobe -hide_banner "URI"',
        example: 'ffprobe -hide_banner "rtsp://admin:password@192.168.1.200:4524/0"',
        varLength: 1,
        vars: ['"rtsp://admin:password@192.168.1.200:4524/0"'],
    },
];

module.exports = { commands };
