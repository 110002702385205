import _ from 'lodash';

export function authInterceptor($rootScope, $q, $injector, Util) {
    'ngInject';

    let state;
    const refresh = function (Auth, toastr) {
        Auth.refreshToken();
        Auth.refreshUser()
            .then((user) => {
                // console.log("Setting current account from interceptor");
                // Auth.setCurrentAccount();
                // $rootScope.$emit("accountChanged");
                if (toastr) {
                    toastr.info('Your privileges have been updated.', 'Privileges Updated', {
                        preventOpenDuplicates: true,
                    });
                }
            });
    };
    const refreshTh = _.throttle(refresh, 1000, { trailing: false });

    return {
        // Add authorization token to headers
        request(config) {
            const Auth = $injector.get('Auth');
            const $stateParams = $injector.get('$stateParams');

            config.headers = config.headers || {};

            if ($stateParams.accountID) {
                config.headers['X-JS-ACCOUNT'] = $stateParams.accountID;
            } else if (!config.headers['X-JS-ACCOUNT']) {
                if (Auth.getCurrentAccountSync()) {
                    config.headers['X-JS-ACCOUNT'] = Auth.getCurrentAccountSync().accountId;
                }
            }
            return config;
        },
        response(response) {
            const Auth = $injector.get('Auth');
            const toastr = $injector.get('toastr');
            if (response.headers('X-JS-REFRESH-TOKEN') === 'true') {
                refreshTh(Auth, toastr);
            }
            return response;
        },

        // Intercept 401s and redirect you to login
        responseError(response) {
            const Auth = $injector.get('Auth');
            const toastr = $injector.get('toastr');
            if (response.status === 401) {
                (state || (state = $injector.get('$state')))
                    .go('login')
                    .catch((err) => {
                        // console.log("State.go(login) catch error")
                    });
                // remove any stale tokens
                Auth.logout();
            } else if (response.status === 403) {
                refreshTh(Auth);
                // toastr.error("You do not have authorization for this action", "Access Forbidden",{preventOpenDuplicates:true});
            } else if (response.status === 307 && response.data && response.data.mfa) {
                const $uibModal = $injector.get('$uibModal');
                const $uibModalStack = $injector.get('$uibModalStack');

                $uibModalStack.dismissAll();
                $uibModal.open({
                    component: 'totp',
                    backdrop: 'static',
                    keyboard: false,
                    resolve: {
                        mfa: {
                            enabled: true,
                            enrolled: false,
                        },
                        modal: true,
                        forced: true,
                    },
                });
            }
            return $q.reject(response);
        },
    };
}
