const { version } = require('../../../package.json');

export const userRoles = ['monitor', 'monitorVideo', 'monitorAdvanced', 'techSupport', 'admin'];

const policyUpdated = {
    privacy: new Date('2021-06-28T00:00:00.000Z'),
    disclaimer: new Date('2021-06-28T00:00:00.000Z'),
    terms: new Date('2021-06-28T00:00:00.000Z'),
};

export const countryList = [
    { region: 'za', prediction: true, country: 'South Africa' },
    { region: 'na', prediction: false, country: 'Namibia' },
    { region: 'bw', prediction: false, country: 'Botswana' },
    { region: 'ls', prediction: false, country: 'Lesotho' },
    { region: 'sz', prediction: false, country: 'Swaziland' },
    { region: 'mz', prediction: false, country: 'Mozambique' },
    { region: 'ao', prediction: false, country: 'Angola' },
    { region: 'zm', prediction: false, country: 'Zambia' },
    { region: 'zm', prediction: false, country: 'Zimbabwe' },
    { region: 'ml', prediction: false, country: 'Malawi' },
    { region: 'au', prediction: true, country: 'Australia' },
    { region: 'ar', prediction: true, country: 'Argentina' },
    { region: 'am', prediction: true, country: 'Armenia' },
    { region: 'at', prediction: true, country: 'Austria' },
    { region: 'by', prediction: true, country: 'Belarus' },
    { region: 'be', prediction: true, country: 'Belgium' },
    { region: 'br', prediction: true, country: 'Brazil' },
    { region: 'bg', prediction: true, country: 'Bulgaria' },
    { region: 'gb', prediction: true, country: 'Great Britain' },
    { region: 'ca', prediction: true, country: 'Canada' },
    { region: 'cl', prediction: true, country: 'Chile' },
    { region: 'co', prediction: true, country: 'Colombia' },
    { region: 'cr', prediction: true, country: 'Costa Rica' },
    { region: 'hr', prediction: true, country: 'Croatia' },
    { region: 'cz', prediction: true, country: 'Czechia' },
    { region: 'dk', prediction: true, country: 'Denmark' },
    { region: 'ee', prediction: true, country: 'Estonia' },
    { region: 'fi', prediction: true, country: 'Finland' },
    { region: 'fr', prediction: true, country: 'France' },
    { region: 'ge', prediction: true, country: 'Georgia' },
    { region: 'de', prediction: true, country: 'Germany' },
    { region: 'gr', prediction: true, country: 'Greece' },
    { region: 'hu', prediction: true, country: 'Hungary' },
    { region: 'in', prediction: true, country: 'India' },
    { region: 'id', prediction: true, country: 'Indonesia' },
    { region: 'il', prediction: true, country: 'Israel' },
    { region: 'it', prediction: true, country: 'Italy' },
    { region: 'jp', prediction: false, country: 'Japan' },
    { region: 'kz', prediction: true, country: 'Kazakhstan' },
    { region: 'lv', prediction: true, country: 'Latvia' },
    { region: 'lt', prediction: true, country: 'Lithuania' },
    { region: 'lu', prediction: true, country: 'Luxembourg' },
    { region: 'my', prediction: false, country: 'Malaysia' },
    { region: 'md', prediction: true, country: 'Moldovia' },
    { region: 'mc', prediction: true, country: 'Monaco' },
    { region: 'me', prediction: true, country: 'Montenegro' },
    { region: 'nl', prediction: true, country: 'Netherlands' },
    { region: 'kr', prediction: false, country: 'Republic of Korea' },
    { region: 'nz', prediction: true, country: 'New Zealand' },
    { region: 'no', prediction: true, country: 'Norway' },
    { region: 'pl', prediction: true, country: 'Poland' },
    { region: 'pt', prediction: true, country: 'Portugal' },
    { region: 'ro', prediction: true, country: 'Romania' },
    { region: 'rs', prediction: true, country: 'Serbia' },
    { region: 'sk', prediction: true, country: 'Slovakia' },
    { region: 'es', prediction: true, country: 'Spain' },
    { region: 'se', prediction: true, country: 'Sweden' },
    { region: 'ch', prediction: true, country: 'Switzerland' },
    { region: 'th', prediction: true, country: 'Thailand' },
    { region: 'tr', prediction: true, country: 'Turkey' },
    { region: 'ua', prediction: true, country: 'Ukraine' },
    { region: 'ae', prediction: true, country: 'United Arab Emirates' },
    { region: 'vn', prediction: true, country: 'Vietnam' },
    { region: 'us', prediction: true, country: 'United States of America' },
    { region: 'uz', prediction: true, country: 'Uzbekistan' },
    { region: 'ru', prediction: true, country: 'Russian Federation' },
    { region: 'sg', prediction: false, country: 'Singapore' },
];

const templateZonePick = [
    'analyticsConfiguration.faceConfiguration.minsize',
    'analyticsConfiguration.faceConfiguration.minConfidence',
    'analyticsConfiguration.faceConfiguration.scaledown',

    'analyticsConfiguration.peopleConfiguration.minsize',
    'analyticsConfiguration.peopleConfiguration.minConfidence',
    'analyticsConfiguration.peopleConfiguration.scaledown',

    'analyticsConfiguration.vehicleConfiguration.minsize',
    'analyticsConfiguration.vehicleConfiguration.minConfidence',
    'analyticsConfiguration.vehicleConfiguration.scaledown',

    'analyticsConfiguration.humanPoseConfiguration.minsize',
    'analyticsConfiguration.humanPoseConfiguration.minConfidence',
    'analyticsConfiguration.humanPoseConfiguration.scaledown',

    'analyticsConfiguration.ssdConfiguration.minsize',
    'analyticsConfiguration.ssdConfiguration.minConfidence',
    'analyticsConfiguration.ssdConfiguration.scaledown',

    'country',
    'countryList',
    'cloudAnalyticsPeople',
    'cloudAnalyticsPose',
    'cloudAnalyticsVehicle',
    'cloudAnalyticsSSD',
    'cloudAnalytics',
    'lprAnalytics',
];

const templateCameraPick = [
    'configuration.liveStreamConfiguration.forcebaseline',
    'configuration.liveStreamConfiguration.reencode',
    'configuration.liveStreamConfiguration.scaleDown',
    'configuration.liveStreamConfiguration.frameRateLimit',
    'configuration.liveStreamConfiguration.recordAudio',
    'configuration.liveStreamConfiguration.hardwareencoding',

    'configuration.cloudSchedule.schedules',
    'configuration.cloudSchedule.defaults.motion',
    'configuration.cloudSchedule.defaults.nonmotion',
    'configuration.cloudSchedule.defaults.lines',
    'configuration.cloudSchedule.enabled',

    'configuration.localstorage.localSchedule.schedules',
    'configuration.localstorage.localSchedule.defaults.motion',
    'configuration.localstorage.localSchedule.defaults.nonmotion',
    'configuration.localstorage.localSchedule.defaults.lines',
    'configuration.localstorage.localSchedule.enabled',

    'configuration.localstorage.enabled',
    // "configuration.localstorage.sizeQuota", // TODO: Check whether this does anything anymore, because we believe it doesn't
    'configuration.localstorage.dateQuota',
    'configuration.localstorage.chunkSize',
    'configuration.localstorage.onlyMotion',

    'configuration.analyticsConfiguration.lprConfiguration.edgeLPR',
    'configuration.analyticsConfiguration.lprConfiguration.lprTrigger',

    'configuration.analyticsConfiguration.motionConfiguration.deltaThreshold',
    // "configuration.analyticsConfiguration.motionConfiguration.blursize",
    'configuration.analyticsConfiguration.motionConfiguration.minarea',
    'configuration.analyticsConfiguration.motionConfiguration.maxarea',
    'configuration.analyticsConfiguration.motionConfiguration.scaledown',
    'configuration.analyticsConfiguration.motionConfiguration.dilations',
    'configuration.analyticsConfiguration.motionConfiguration.erosions',
    'configuration.analyticsConfiguration.motionConfiguration.recordHeatmap',
    'configuration.analyticsConfiguration.motionConfiguration.minBursts',
    'configuration.analyticsConfiguration.motionConfiguration.maxBursts',
    'configuration.analyticsConfiguration.motionConfiguration.initialDelay',
    'configuration.analyticsConfiguration.motionConfiguration.burstDelay',
    'configuration.analyticsConfiguration.motionConfiguration.burstCooloff',
    'configuration.analyticsConfiguration.motionConfiguration.motionCooloff',
    'configuration.analyticsConfiguration.motionConfiguration.algorithm',
    'configuration.analyticsConfiguration.motionConfiguration.learningRate',
    'configuration.analyticsConfiguration.motionConfiguration.lineCrossingDetection',
    'configuration.analyticsConfiguration.motionConfiguration.lineCrossingTrigger',
    'configuration.analyticsConfiguration.motionConfiguration.motionTrigger',
    'configuration.analyticsConfiguration.motionConfiguration.crossMaxDistance',
    'configuration.analyticsConfiguration.motionConfiguration.crossMaxAge',
    'configuration.analyticsConfiguration.motionConfiguration.crossMaxTrack',
    'configuration.analyticsConfiguration.motionConfiguration.crossEpsilon',
    'configuration.analyticsConfiguration.motionConfiguration.crossLines',

    // "configuration.analyticsConfiguration.motionConfiguration.crossLines.tripAToB",
    // "configuration.analyticsConfiguration.motionConfiguration.crossLines.tripBToA",
    // "configuration.analyticsConfiguration.motionConfiguration.crossLines.countAToB",
    // "configuration.analyticsConfiguration.motionConfiguration.crossLines.countBToA",
    // "configuration.analyticsConfiguration.motionConfiguration.crossLines.numAToB",
    // "configuration.analyticsConfiguration.motionConfiguration.crossLines.numBToA",
    // "configuration.analyticsConfiguration.motionConfiguration.crossLines.areaThreshold",
    // "configuration.analyticsConfiguration.motionConfiguration.crossLines.alias",
    // "configuration.analyticsConfiguration.motionConfiguration.crossLines.line",
    // TODO: Should we do startTime???

    'configuration.analyticsConfiguration.faceConfiguration.algorithm',
    'configuration.analyticsConfiguration.faceConfiguration.triggerOn',
    'configuration.analyticsConfiguration.faceConfiguration.scaledown',
    'configuration.analyticsConfiguration.faceConfiguration.scalefactor',
    'configuration.analyticsConfiguration.faceConfiguration.minneighbors',
    'configuration.analyticsConfiguration.faceConfiguration.minsize',
    'configuration.analyticsConfiguration.faceConfiguration.minConfidence',
    'configuration.analyticsConfiguration.faceConfiguration.countThresholdEnabled',
    'configuration.analyticsConfiguration.faceConfiguration.countThreshold',

    'configuration.analyticsConfiguration.peopleConfiguration.enabled',
    'configuration.analyticsConfiguration.peopleConfiguration.scaledown',
    'configuration.analyticsConfiguration.peopleConfiguration.triggerOn',
    'configuration.analyticsConfiguration.peopleConfiguration.minConfidence',
    'configuration.analyticsConfiguration.peopleConfiguration.minsize',
    'configuration.analyticsConfiguration.peopleConfiguration.countThresholdEnabled',
    'configuration.analyticsConfiguration.peopleConfiguration.countThreshold',

    'configuration.analyticsConfiguration.vehicleConfiguration.enabled',
    'configuration.analyticsConfiguration.vehicleConfiguration.scaledown',
    'configuration.analyticsConfiguration.vehicleConfiguration.triggerOn',
    'configuration.analyticsConfiguration.vehicleConfiguration.minConfidence',
    'configuration.analyticsConfiguration.vehicleConfiguration.minsize',
    'configuration.analyticsConfiguration.vehicleConfiguration.countThresholdEnabled',
    'configuration.analyticsConfiguration.vehicleConfiguration.countThreshold',

    'configuration.analyticsConfiguration.humanPoseConfiguration.enabled',
    'configuration.analyticsConfiguration.humanPoseConfiguration.scaledown',
    'configuration.analyticsConfiguration.humanPoseConfiguration.triggerOn',
    'configuration.analyticsConfiguration.humanPoseConfiguration.minConfidence',
    'configuration.analyticsConfiguration.humanPoseConfiguration.minsize',
    'configuration.analyticsConfiguration.humanPoseConfiguration.countThresholdEnabled',
    'configuration.analyticsConfiguration.humanPoseConfiguration.countThreshold',
    'configuration.analyticsConfiguration.humanPoseConfiguration.poseFilter',
    'configuration.analyticsConfiguration.humanPoseConfiguration.minClassConfidence',

    'configuration.analyticsConfiguration.ssdConfiguration.enabled',
    'configuration.analyticsConfiguration.ssdConfiguration.scaledown',
    'configuration.analyticsConfiguration.ssdConfiguration.triggerOn',
    'configuration.analyticsConfiguration.ssdConfiguration.minConfidence',
    'configuration.analyticsConfiguration.ssdConfiguration.minsize',

    'configuration.analyticsConfiguration.frameRateLimit',
    'configuration.analyticsConfiguration.detectmotion',
    'configuration.analyticsConfiguration.detectfaces',

    // "configuration.stoppage",
    // "configuration.triggerPeriod",
    'configuration.scaleDown',
    'configuration.jpegquality',
    'configuration.rotation',
];

const siteNoteTagOptions = [
    'Hard Drive',
    'Camera',
    'Unit',
    'Network',
    'Firmware',
    'Configuration',
    'Proxy',
    'Comms',
    'Ticket',
    'Incident',
    'Custom',
];

export default {
    userRoles,
    tokenTTL: 7200,
    idleDuration: 600,
    policyUpdated,
    countryList,
    templateZonePick,
    templateCameraPick,
    siteNoteTagOptions,
    version,
};
