import angular from 'angular';
import ngCookies from 'angular-cookies';
import uiRouter from '@uirouter/angularjs';
import constants from '../../app/app.constants';
import util from '../util/util.module';
import { AuthService } from './auth.service';
import { UserResource } from './user.service';
import { routerDecorator } from './router.decorator';
import { authInterceptor } from './interceptor.service';

function addInterceptor($httpProvider) {
    'ngInject';

    $httpProvider.interceptors.push('authInterceptor');
}

export default angular.module('cameraViewerApp.auth', [constants, util, ngCookies, uiRouter])
    .factory('authInterceptor', authInterceptor)
    .run(routerDecorator)
    .factory('Auth', AuthService)
    .factory('User', UserResource)
    .config(['$httpProvider', addInterceptor])
    .name;
