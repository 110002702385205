import angular from 'angular';
import uiBootstrap from 'angular-ui-bootstrap';
import uiRouter from '@uirouter/angularjs';
import angularMoment from 'angular-moment';
import uiScroll from 'angular-ui-scroll';
import uiScrollGrid from 'angular-ui-scroll/dist/ui-scroll-grid';
import ngAside from 'angular-aside';
import ngSticky from 'ngsticky';
import uiSelectInfinity from 'ui-select-infinity';
import uiSelect from 'ui-select';
import moment from 'moment';
import _ from 'lodash-es';
import routing from './live.routes';

export default angular.module('cameraViewerApp.live', [uiRouter,
    uiBootstrap,
    'ui.scroll',
    'ui.scroll.grid',
    ngAside,
    'sticky',
    uiSelect,
    'ui-select-infinity',
    'angularMoment',
])
    .config(routing)
    .filter('escape', () => function (input) {
        if (input) {
            return window.encodeURIComponent(input);
        }
        return '';
    })
    .name;

require('./live.component');
