import _ from 'lodash';
import { fabric } from 'fabric-with-gestures';
import Geometry from '../../../server/components/utilities/geometry';

export default class SnapshotModalController {
    $http;
    $uibModalInstance;
    $uibModal;
    $document;
    $state;
    $scope;
    $ngConfirm;
    canvas;
    snapshot;
    toastr;
    Auth;
    image;
    heatmap;
    more;
    moment;
    actualSnapshot;
    showOverlay;
    hasAnalytics = {
        motion: false,
        faces: false,
        people: false,
        poses: false,
        ssd: false,
        vehicles: false,
        heatmap: false,
        lines: false,
        plates: false,
        any: false,
    };
    colormap = [
        'rgba(255,0,0,1)',
        'rgba(255,85,0,1)',
        'rgba(255,170,0,1)',
        'rgba(255,255,0,1)',
        'rgba(170,255,0,1)',
        'rgba(85,255,0,1)',
        'rgba(0,255,0,1)',
        'rgba(0,255,85,1)',
        'rgba(0,255,170,1)',
        'rgba(0,255,255,1)',
        'rgba(0,170,255,1)',
        'rgba(0,85,255,1)',
        'rgba(0,0,255,1)',
        'rgba(85,0,255,1)',
        'rgba(170,0,255,1)',
        'rgba(255,0,255,1)',
        'rgba(255,0,170,1)',
        'rgba(255,0,85,1)',
    ];
    keypointPairs = [
        [1, 2],
        [1, 5],
        [2, 3],
        [3, 4],
        [5, 6],
        [6, 7],
        [1, 8],
        [8, 9],
        [9, 10],
        [1, 11],
        [11, 12],
        [12, 13],
        [1, 0],
        [0, 14],
        [14, 16],
        [0, 15],
        [15, 17],
    ];
    plateOpen = {};
    tempIdentities;
    snapPlaceholder;
    recalling;
    last = false;
    labelling;
    tempSnaps = {};
    plateSnaps = {};
    identityFaces = {};
    emSize;
    lastFilter = {};
    plateWanted = {};
    tempCrop = {};
    currentCamera;
    tempWanted = {};
    snapshotValid = true;
    archiveRequestNeeded = false;
    archiveRequestInProgress = false;
    highResLoading = false;

    isLoggedIn: Function;
    isAdmin: Function;
    isSuperAdmin: Function;
    getCurrentUser: Function;

    // eslint-disable-next-line spaced-comment
    /*@ngInject*/

    constructor(
        $uibModalInstance,
        toastr,
        snapPlaceholder,
        moment,
        $http,
        $document,
        snapshot,
        overlay,
        Auth,
        appConfig,
        $state,
        $scope,
        $uibModal,
        socket,
        $timeout,
        $ngConfirm,
        unitService,
        liveStreamService
    ) {
        this.liveStreamService = liveStreamService;
        this.unitService = unitService;
        this.$http = $http;
        this.$ngConfirm = $ngConfirm;
        this.moment = moment;
        this.$uibModalInstance = $uibModalInstance;
        this.$uibModal = $uibModal;
        this.$document = $document;
        this.$timeout = $timeout;
        this.snapPlaceholder = snapPlaceholder;
        this.snapshot = snapshot;
        this.recalling = false;
        this.labelling = false;
        this.$state = $state;
        this.toastr = toastr;
        this.$scope = $scope;
        this.more = true;
        this.plateMore = true;
        this.isLoggedIn = Auth.isLoggedInSync;
        this.totalMotion = 0;
        this.ssdDetections = 0;
        this.isAdmin = Auth.isAdminSync;
        this.isSuperAdmin = Auth.isSuperAdminSync;
        this.getCurrentUser = Auth.getCurrentUserSync;
        this.socket = socket;
        this.Auth = Auth;
        this.currentRooms = [];
        this.countryList = appConfig.default.countryList;

        if (overlay) {
            this.showOverlay = overlay;
        } else {
            this.showOverlay = {
                motion: true,
                faces: true,
                people: true,
                poses: true,
                ssd: true,
                vehicles: true,
                heatmap: false,
                lines: false,
                plates: true,
                popi: false,
                osdTime: false,
                osdSite: false,
                osdZone: false,
            };
        }
        // this.pausePanning = True;
        let self = this;
        this.$scope.$on('$destroy', function () {
            socket.socket.removeListener('camera:save', self.theCameraListener);
            socket.socket.removeListener('snapshot:save', self.snapshotListener);
            self.socket.unsyncUpdates('site', 'sites:sites');
            if (self.currentRooms.length > 0) {
                self.currentRooms.forEach((room) => {
                    self.socket.leaveRoom(room);
                });
            }
        });
        this.$scope.handleImgError = function(scope) {
            self.$http
                .get(`api/snapshots/getS3Header/${self.snapshot._id}`)
                .then((response) => {
                    const header = response.data;
                    if (header.StorageClass === 'DEEP_ARCHIVE') {
                        if (header.Restore) {
                            console.log('Restoration in progress')
                            scope.$ctrl.archiveRequestInProgress = true;
                        } else {
                            console.log('Restoration needed')
                            scope.$ctrl.archiveRequestNeeded = true;
                        }
                    }
                })
                .catch((response) => {
                    console.error(response);
                    scope.$ctrl.snapshotValid = false;
                    // TODO: Provide feedback for broken snapshots
                });

        };
    }

    $onInit() {
        let self = this;
        if (self.snapshot.reason === 'LineTrip') {
            self.showOverlay.lines = true;
        }
        let lastIndex = _.findIndex(self.snapPlaceholder, (snap) => {
            return snap._id === self.snapshot._id;
        });
        if (self.snapPlaceholder.length === 0) {
            self.snapshot.first = true;
        } else {
            let selfIndex = _.findIndex(self.snapPlaceholder, (o) => {
                return o._id === self.snapshot._id;
            });
            if (selfIndex > 0) {
                self.snapshot.first = false;
            }
        }
        if (lastIndex === self.snapPlaceholder.length - 1) {
            self.last = true;
        }
        if (self.snapshot?.identities?.length > 0) {
            self.tempIdentities = self.snapshot.identities;
            self.tempIdentities.forEach((i) => {
                i.tempSimilarity = Math.floor(i.similarity);
            });
        }

        self.$http.get(`/api/sites/${self.snapshot.site}`).then((response) => {
            self.currentSite = response.data;
        });

        self.Auth.hasPrivilege('secuvue.snapshot.findOne').then((has) => {
            if (has) {
                self.$http.get(`/api/snapshots/${this.snapshot._id}`).then(
                    (response) => {
                        self.actualSnapshot = response.data;
                        self.actualSnapshot.detections.forEach((det) => {
                            if (det.detectionType === 'Motion') {
                                let width = det.rect.right - det.rect.left;
                                let height = det.rect.bottom - det.rect.top;
                                self.totalMotion = self.totalMotion + width * height;
                                det.totalMotion = width * height;
                            } else if (det.detectionType === 'SSD') {
                                self.ssdDetections += 1;
                            }
                        });
                        self.$timeout(function () {
                            self.drawCanvas();
                        }, 0);
                    },
                    (err) => {
                        if (err.status !== 401 && err.status !== 403) {
                            console.error(err);
                        }
                    }
                );
            } else if (self.Auth.isLoggedInSync()) {
                //self.toastr.info(`You require secuvue.snapshot privileges`, 'Unprivileged : ', {preventOpenDuplicates: true})
            }
        });

        self.Auth.hasPrivilege('secuvue.camera.findOne').then((has) => {
            if (has) {
                self.$http.get(`/api/cameras/${self.snapshot.camera}`).then(
                    (response) => {
                        self.currentCamera = response.data;
                        self.minFaceSize = Math.pow(
                            self.currentCamera.camera.configuration.analyticsConfiguration
                                .faceConfiguration.minsize[0],
                            2
                        );
                        self.tempIdentities.forEach((recog) => {
                            let pix =
                                recog.boundingBox.Width *
                                self.snapshot.width *
                                recog.boundingBox.Height *
                                self.snapshot.height;
                            recog.faceSize = Math.round(pix);
                        });
                        self.socket.joinRoom(
                            `${self.Auth.getCurrentAccountSync().accountId}:${self.snapshot.site}:${
                                self.snapshot.camera
                            }:cameras`
                        );
                        self.currentRooms.push(
                            `${self.Auth.getCurrentAccountSync().accountId}:${self.snapshot.site}:${
                                self.snapshot.camera
                            }:cameras`
                        );
                        self.theCameraListener = self.cameraListener.bind(self);
                        self.socket.socket.on('camera:save', self.theCameraListener);
                        self.$timeout(function () {
                            self.drawCanvas();
                        }, 0);
                    },
                    (err) => {
                        if (err.status !== 401 && err.status !== 403) {
                            console.error(err);
                        }
                    }
                );
            } else if (this.Auth.isLoggedInSync()) {
                //self.toastr.info(`You require secuvue.camera.configuration privileges`, 'Unprivileged : ', {preventOpenDuplicates: true})
            }
        });

        if (self.snapshot.identities?.length > 0) {
            self.populateWanted();
        }

        self.snapshotListener = function (item) {
            if (item._id === self.snapshot._id) {
                self.recalling = false;

                if (!self.snapshot.faceRecallDone && item.faceRecallDone) {
                    self.toastr.info('FaceRecall completed successfully', {
                        preventOpenDuplicates: true,
                    });
                    self.snapshot.faceRecallDone = item.faceRecallDone;
                }
                if (!self.snapshot.lprDone && item.lprDone) {
                    self.toastr.info('LPR completed successfully', { preventOpenDuplicates: true });
                    self.snapshot.lprDone = item.lprDone;
                }
                if (self.snapshot.identities?.length > 0) {
                    //self.populateWanted();
                    self.tempIdentities = item.identities;
                    self.tempIdentities.forEach((ident) => {
                        ident.tempSimilarity = Math.floor(ident.similarity);
                        let pix =
                            ident.boundingBox.Width *
                            self.snapshot.width *
                            ident.boundingBox.Height *
                            self.snapshot.height;
                        ident.faceSize = Math.round(pix);
                        self.findRecogResults(ident.userId);
                    });
                }
                if (item.openvinoPeopleDetections && item.openvinoPeopleDetections.length > 0) {
                    self.snapshot.openvinoPeopleDetections = item.openvinoPeopleDetections;
                }
                if (item.humanPoseResults && item.humanPoseResults.length > 0) {
                    self.snapshot.humanPoseResults = item.humanPoseResults;
                }
                self.snapshot.humanPoseDone = item.humanPoseDone;
                self.snapshot.peopleDetectionDone = item.peopleDetectionDone;

                if (item.openvinoVehicleDetections && item.openvinoVehicleDetections.length > 0) {
                    self.snapshot.openvinoVehicleDetections = item.openvinoVehicleDetections;
                }
                self.snapshot.vehicleDetectionDone = item.vehicleDetectionDone;

                // for self-uploaded s3
                if(!self.actualSnapshot.data || !self.snapshot.data) {
                    self.snapshot.key = item.key;
                    self.snapshot.data = item.data;
                    self.snapshot.size = item.size;

                    self.actualSnapshot.key = item.key;
                    self.actualSnapshot.data = item.data;
                    self.actualSnapshot.size = item.size;
                }

                self.$timeout(function () {
                    self.drawCanvas();
                }, 0);
            }
        };
        self.snapshotListener2 = function (item) {
            if (item._id === self.snapshot._id) {
                self.toastr.info('LPR completed successfully', { preventOpenDuplicates: true });
                self.snapshot.lprDone = item.lprDone;
            }
        };
        self.socket.socket.on('snapshot:save', self.snapshotListener);
        self.socket.socket.on('snapshot:lprAnalysed', self.snapshotListener2);
    }

    doLPR(vehicles) {
        let self = this;
        if (self.snapshot.edgeLPR) {
            self.$ngConfirm({
                title: 'Override LPR?',
                escapeKey: false,
                backgroundDismiss: false,
                scope: self.$scope,
                content: `
                This snapshot has already been analysed by an LPR camera.
                Running LPR again will override these results. Do you wish to proceed?
                `,
                buttons: {
                    enter: {
                        text: 'Yes',
                        btnClass: 'btn-blue',
                        action(scope, button) {
                            //return;
                            self.$http
                                .get(`api/snapshots/lprDetect/${self.snapshot._id}`)
                                .then((response) => {
                                    self.toastr.success('Snapshot added to LPR pipeline', {
                                        preventOpenDuplicates: true,
                                    });
                                });
                        },
                    },
                    close(scope, button) {},
                },
            });
        } else {
            self.$http.get(`api/snapshots/lprDetect/${self.snapshot._id}`).then(() => {
                self.toastr.success('Snapshot added to LPR pipeline', {
                    preventOpenDuplicates: true,
                });
            });
        }
    }

    close() {
        let result = 'stuff';
        this.$uibModalInstance.close(result);
    }

    doLog() {
        let self = this;
        console.log('DEBUG:', self);
    }

    next() {
        let self = this;
        let index = _.findIndex(self.snapPlaceholder, (snap) => {
            return snap._id === self.snapshot._id;
        });
        if (index !== -1 && index !== self.snapPlaceholder.length - 1) {
            this.$uibModalInstance.close({
                newSnap: self.snapPlaceholder[index + 1],
                overlay: self.showOverlay
            });
        }
    }

    previous() {
        let self = this;
        let index = _.findIndex(self.snapPlaceholder, (snap) => {
            return snap._id === self.snapshot._id;
        });
        if (index !== -1 && index !== 0) {
            this.$uibModalInstance.close({
                newSnap: self.snapPlaceholder[index - 1],
                overlay: self.showOverlay
            });
        }
    }

    updateRef(id, alias) {
        this.$http.patch(`/api/identities/${id}`, { alias }).then((response) => {});
    }

    divertSnap(snap) {
        this.$uibModalInstance.close({
            newSnap: snap,
            overlay: this.showOverlay
        });
    }

    cameraListener(camera) {
        let self = this;
        self.currentCamera = camera;

        self.$timeout(function () {
            self.drawCanvas();
        }, 0);
    }

    populateWanted() {
        let self = this;
        const query = self.snapshot.identities.map((obj) => obj.userId);
        self.$http
            .get('api/identities', {
                params: {
                    params: JSON.stringify([
                        { field: '_id', type: 'objectId', value: query, operator: '$in' },
                    ]),
                },
            })
            .then((response) => {
                response.data.forEach((obj) => {
                    self.tempWanted[obj._id] = obj;
                });
            });
    }

    redirectRef(id) {
        let self = this;
        self.$state.go('main.reference', { filter: id }).catch((err) => {
            console.log(err);
        });
        self.$uibModalInstance.close();
    }

    updateWanted(id) {
        this.$http
            .patch(`/api/identities/${id}`, { flag: true })
            .then((response) => {
                this.tempWanted[id].flag = true;
            });
    }

    requestSnapshot() {
        let self = this;
        self.$http.post(`/api/cameras/${this.currentCamera._id}/requestSnapshot`, {}).then(
            () => {},
            (err) => {
                if (err.status !== 401 && err.status !== 403) console.error(err);
            }
        );
    }

    initiateStream() {
        const self = this;
        const user = self.getCurrentUser;
        const camera = {
            id: self.snapshot.camera,
            name: self.snapshot.cameraname,
            account: self.snapshot.account,
            site: self.snapshot.site,
            siteName: self.snapshot.sitename,
            unit: self.snapshot,
            source: 'SnapshotModal',
            user: user._id,
            userName: user.name,
        };
        self.liveStreamService.addStream(camera);
    }

    saveCanvas() {
        const self = this;
        // use FabricJS's toDataURL() method to generate the data URL
        const dataURL = self.canvas.toDataURL({ format: 'jpeg', quality: 1 });

        // create a link element
        const link = document.createElement('a');
        link.href = dataURL;

        const snapshot = self.actualSnapshot;
        // set the download attribute to the desired file name
        const timestamp = this.moment(snapshot.timestamp).format('YYYY-MM-DDTHH_mm_ss');
        link.download = `${snapshot.sitename} - ${snapshot.cameraname} - ${timestamp}.jpg`;

        // trigger a click event on the link to start the download
        link.addEventListener('click', () => {
            // remove the link element when the download is complete
            document.body.removeChild(link);
        });

        // append the link to the body
        document.body.appendChild(link);

        // trigger a click event on the link
        link.click();
    }

    checkAnalyticsFlags() {
        let self = this;

        const hasOnboardVehicleDetections = self.snapshot?.vehicles > 0 && self.Auth.hasRoleSync('secuvue.Analytics.Onboard.VehicleDetector');
        const hasCloudVehicleDetections = self.snapshot?.openvinoVehicleDetections?.length > 0 && self.Auth.hasRoleSync('secuvue.Analytics.Cloud.VehicleDetector')
        const hasOnboardPeopleDetections = self.snapshot?.people > 0 && self.Auth.hasRoleSync('secuvue.Analytics.Onboard.PeopleDetector');
        const hasCloudPeopleDetections = self.snapshot?.openvinoPeopleDetections?.length > 0 && self.Auth.hasRoleSync('secuvue.Analytics.Cloud.PeopleDetector');
        const hasOnboardPoseDetections = self.snapshot?.poses > 0 && self.Auth.hasRoleSync('secuvue.Analytics.Onboard.HumanPoseDetector');
        const hasCloudPoseDetections = self.snapshot?.humanPoseResults?.length > 0 && self.Auth.hasRoleSync('secuvue.Analytics.Cloud.HumanPoseDetector');
        const hasSSDDetections = self.ssdDetections > 0 && self.Auth.hasRoleSync('secuvue.Analytics.Onboard.SSDDetector.Request');

        self.hasAnalytics.any = false;
        self.hasAnalytics.motion = self.snapshot?.motion;
        self.hasAnalytics.faces = self.snapshot?.faces || self.snapshot?.snapRecognitions?.length > 0 || self.snapshot?.identities?.length > 0;
        self.hasAnalytics.people = hasCloudPeopleDetections || hasOnboardPeopleDetections;
        self.hasAnalytics.poses = hasCloudPoseDetections || hasOnboardPoseDetections;
        self.hasAnalytics.ssd =  hasSSDDetections && self.Auth.hasUserPrivilegeSync('debug');
        self.hasAnalytics.vehicles = hasOnboardVehicleDetections || hasCloudVehicleDetections;
        self.hasAnalytics.heatmap = self.currentCamera?.heatmap;
        self.hasAnalytics.lines = self.snapshot?.peopleCounters?.length > 0;
        self.hasAnalytics.plates = self.snapshot?.lprResults?.length > 0;

        Object.keys(self.hasAnalytics).forEach((key) => {
            if (self.hasAnalytics[key] === true) {
                self.hasAnalytics.any = true;
            }
        });
    }

    toggleAllDisplay(action) {
        let self = this;

        switch (action) {
            case 'disable':
                Object.keys(self.showOverlay).forEach((key) => {
                    if (!key.includes('osd') && key !== 'popi') {
                        self.showOverlay[key] = false;
                    }
                });
                break;
            case 'enable':
                Object.keys(self.showOverlay).forEach((key) => {
                    if (!key.includes('osd') && key !== 'popi') {
                        self.showOverlay[key] = true;
                    }
                });
                break;
            default: console.error('toggleAllDisplay invalid action:', action);
        }

        self.drawCanvas();
    }

    drawCanvas() {
        let self = this;
        self.checkAnalyticsFlags();
        if (!self.actualSnapshot) {
            return;
        }
        self.emSize = Number(
            getComputedStyle(document.getElementById('snapshotCanvas'), '').fontSize
                .match(/(\d+)px/)[1]
        );
        if (!self.canvas) {
            self.canvas = new fabric.Canvas('snapshotCanvas', { stopContextMenu: true, enableRetinaScaling: false });
            self.pausePanning = false;
            self.canvas.on({
                'mouse:wheel'(opt) {
                    var delta = opt.e.deltaY;
                    var pointer = self.canvas.getPointer(opt.e);
                    var zoom = self.canvas.getZoom();
                    zoom = zoom - delta / 200;
                    // limit zoom to 4x in
                    if (zoom > 4) zoom = 4;
                    // limit zoom to 1x out
                    if (zoom < 1) {
                        zoom = 1;
                        self.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
                    }
                    self.canvas.zoomToPoint(
                        {
                            x: opt.e.offsetX,
                            y: opt.e.offsetY,
                        },
                        zoom
                    );
                    opt.e.preventDefault();
                    opt.e.stopPropagation();
                },
                'touch:gesture'(e) {
                    if (e.e.touches && e.e.touches.length === 2) {
                        self.pausePanning = true;
                        var point = new fabric.Point(e.self.x, e.self.y);
                        if (e.self.state === 'start') {
                            self.zoomStartScale = self.canvas.getZoom();
                        }
                        var delta = self.zoomStartScale * e.self.scale;
                        self.canvas.zoomToPoint(point, delta);
                        self.pausePanning = false;
                        // limit zoom to 4x in
                        if (delta > 4) delta = 4;
                        // limit zoom to 1x out
                        if (delta < 1) {
                            delta = 1;
                            self.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
                        }
                    }
                },
                'touch:drag'(e) {
                    if (
                        self.pausePanning === false &&
                        undefined !== e.self.x &&
                        undefined !== e.self.y
                    ) {
                        self.currentX = e.self.x;
                        self.currentY = e.self.y;
                        self.xChange = self.currentX - self.lastX;
                        self.yChange = self.currentY - self.lastY;

                        if (
                            Math.abs(self.currentX - self.lastX) <= 100 &&
                            Math.abs(self.currentY - self.lastY) <= 100
                        ) {
                            let delta = new fabric.Point(self.xChange * 1.25, self.yChange * 1.25);
                            self.canvas.relativePan(delta);
                        }

                        self.lastX = e.self.x;
                        self.lastY = e.self.y;
                    }
                },
            });
        }
        self.canvas.clear();
        self.canvas.containerClass = 'snapshot-wrapper';
        new fabric.Image.fromURL(
            self.actualSnapshot.dataThumbnail,
            function (image) {
                self.highResLoading = true;
                new fabric.Image.fromURL(
                    self.actualSnapshot.data,
                    function (image) {
                        image.setOptions({
                            left: 0,
                            top: 0,
                            opacity: 1,
                            width: self.snapshot.width,
                            height: self.snapshot.height,
                        });
                        self.canvas.setBackgroundImage(image, self.canvas.renderAll.bind(self.canvas), {
                            originX: 'left',
                            originY: 'top',
                        });
                        self.canvas.setWidth(image.width, { backstoreOnly: true });
                        self.canvas.setHeight(image.height, { backstoreOnly: true });
                        self.canvas.renderAll();
                        self.highResLoading = false;
                    },
                    { crossOrigin: 'anonymous' }
                );
                image.scaleToWidth(self.snapshot.width);
                image.scaleToHeight(self.snapshot.height);
                image.setOptions({
                    left: 0,
                    top: 0,
                    opacity: 1,
                    width: self.snapshot.width,
                    height: self.snapshot.height,
                });
                self.canvas.setBackgroundImage(image, self.canvas.renderAll.bind(self.canvas), {
                    originX: 'left',
                    originY: 'top',
                });
                self.canvas.setWidth(image.width, { backstoreOnly: true });
                self.canvas.setHeight(image.height, { backstoreOnly: true });
                if (image.width > image.height) {
                    self.canvas.setWidth('100%', { cssOnly: true });
                    self.canvas.setHeight('auto', { cssOnly: true });
                } else {
                    self.canvas.setWidth('auto', { cssOnly: true });
                    self.canvas.setHeight('100%', { cssOnly: true });
                }
                self.canvas.selection = false;
                if (self.showOverlay.plates) {
                    if (self.snapshot.lprResults.length > 0) {
                        self.snapshot.lprResults.forEach((plate) => {
                            if (plate.boundingBox && plate.boundingBox.length > 0) {
                                let bb = [];
                                plate.boundingBox.forEach((co) => {
                                    bb.push({
                                        x: co.x * self.snapshot.width,
                                        y: co.y * self.snapshot.height,
                                    });
                                });

                                let poly = new fabric.Polygon(bb, {
                                    stroke: 'orange',
                                    strokeWidth: 3,
                                    selectable: false,
                                    fill: null,
                                });

                                //TODO: Ensure alias does not go out of canvas area
                                let textAlias = new fabric.Text(plate.plate, {
                                    fontSize: 28,
                                    fontFamily: 'Monospace',
                                    textAlign: 'center',
                                    left: poly.left + poly.width / 2,
                                    top: poly.top + poly.height + 20,
                                    shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
                                    textBackgroundColor: 'rgba(200,200,200,0.5)',
                                    originX: 'center',
                                    originY: 'center',
                                    //padding: 10,
                                    selectable: false,
                                    hasBorders: false,
                                    hasControls: false,
                                });
                                self.canvas.add(textAlias);
                                self.canvas.add(poly);
                                self.canvas.renderAll();
                            }
                        });
                    }
                }

                if (self.showOverlay.popi) {
                    const faces = self.snapshot.identities ? self.snapshot.identities : self.snapshot.snapRecognitions;
                    if (faces.length > 0) {
                        faces.forEach((rec) => {
                            let image = new fabric.Image.fromURL(
                                self.actualSnapshot.data,
                                function (image) {
                                    let clipRect = new fabric.Rect({
                                        left: rec.boundingBox.Left * self.snapshot.width - self.snapshot.width / 2,
                                        top: rec.boundingBox.Top * self.snapshot.height - self.snapshot.height / 2,
                                        width: rec.boundingBox.Width * self.snapshot.width,
                                        height: rec.boundingBox.Height * self.snapshot.height,
                                        originX: 'left',
                                        originY: 'top',
                                        selectable: false
                                    });
                                    image.setOptions({
                                        left: 0,
                                        top: 0,
                                        opacity: 1,
                                        width: self.canvas.width,
                                        height: self.canvas.height,
                                        selectable: false,
                                        clipPath: clipRect,
                                    });
                                    image.filters.push(
                                        new fabric.Image.filters.Pixelate({ blocksize: 20 })
                                    );
                                    image.applyFilters();
                                    self.canvas.add(image);
                                    image.moveTo(10);
                                    self.canvas.renderAll();
                                },
                                { crossOrigin: 'anonymous' }
                            );
                        });
                    } else {
                        self.snapshot.detections.forEach(function (detection) {
                            if (detection.detectionType === 'Motion') {
                                return;
                            }
                            let image = new fabric.Image.fromURL(
                                self.actualSnapshot.data,
                                function (image) {
                                    let clipRect = new fabric.Rect({
                                        left: detection.rect.left - self.snapshot.width / 2,
                                        top: detection.rect.top - self.snapshot.height / 2,
                                        width: detection.rect.right - detection.rect.left,
                                        height: detection.rect.bottom - detection.rect.top,
                                        originX: 'left',
                                        originY: 'top',
                                        selectable: false
                                    });
                                    image.setOptions({
                                        left: 0,
                                        top: 0,
                                        opacity: 1,
                                        width: self.canvas.width,
                                        height: self.canvas.height,
                                        selectable: false,
                                        clipPath: clipRect,
                                    });
                                    image.filters.push(
                                        new fabric.Image.filters.Pixelate({ blocksize: 20 })
                                    );
                                    image.applyFilters();
                                    self.canvas.add(image);
                                    image.moveTo(10);
                                    self.canvas.renderAll();
                                },
                                { crossOrigin: 'anonymous' }
                            );
                        });
                    }
                }

                if (self.showOverlay.lines && self.currentCamera) {
                    let lineConfigs =
                        self.currentCamera.camera.configuration.analyticsConfiguration
                            .motionConfiguration.crossLines;

                    let tripLines = [];
                    if (self.snapshot.reason && self.snapshot.reason === 'LineTrip') {
                        self.snapshot.detections.forEach((detection) => {
                            if (detection.detectionType === 'Motion') {
                                if (detection.lines && detection.lines.length > 0) {
                                    detection.lines.forEach((line) => {
                                        tripLines.push(line);
                                    });
                                }
                            }
                        });
                    }
                    lineConfigs &&
                        lineConfigs.forEach((config) => {
                            let stats = _.find(self.snapshot.peopleCounters, (obj) => {
                                return obj.line === config.id;
                            });

                            if (stats !== undefined) {
                                let color = 'rgba(255,0,0,0.8)';
                                let dashArray = [25, 10];

                                if (tripLines.includes(config.id)) {
                                    color = 'rgba(0, 255, 0, 0.8)';
                                    dashArray = [10, 2];
                                }

                                let line = new fabric.Line(
                                    [
                                        config.line[0].x * self.canvas.width,
                                        config.line[0].y * self.canvas.height,
                                        config.line[1].x * self.canvas.width,
                                        config.line[1].y * self.canvas.height,
                                    ],
                                    {
                                        strokeWidth: 3,
                                        strokeDashArray: dashArray,
                                        stroke: `${color}`,
                                        originX: 'center',
                                        originY: 'center',
                                        perPixelTargetFind: true,
                                        selectable: false,
                                        targetFindTolerance: 10,
                                        //padding: 4,
                                        hasControls: false,
                                    }
                                );
                                self.canvas.add(line);

                                line.set(
                                    'shadow',
                                    new fabric.Shadow({
                                        color: 'rgba(20, 20, 20, 1)',
                                        blur: 2,
                                        offsetX: 1,
                                        offsetY: 1,
                                    })
                                );

                                let coords = Geometry.calculatePerpendicularBisectPoints(line, 50);

                                let textOptions = {
                                    fontSize: 16,
                                    fontFamily: 'Monospace',
                                    textAlign: 'center',
                                    // left: coords[2],
                                    // top: coords[3],
                                    // textBackgroundColor: 'rgba(0,200,0,1)',
                                    originX: 'center',
                                    originY: 'center',
                                    shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
                                    selectable: false,
                                    hasBorders: false,
                                    hasControls: false,
                                };

                                let textA = new fabric.Text(`A\n${stats.numBToA}`, {
                                    left: coords[2],
                                    top: coords[3],
                                    textBackgroundColor: 'rgba(0,200,0,1)',
                                    ...textOptions
                                });

                                let textB = new fabric.Text(`B\n${stats.numAToB}`, {
                                    left: coords[0],
                                    top: coords[1],
                                    fill: 'white',
                                    textBackgroundColor: 'rgba(0,0,200,1)',
                                    ...textOptions
                                });

                                let textAlias = new fabric.Text(config.alias, {
                                    left: line.left,
                                    top: line.top - line.height / 2 - 20,
                                    textBackgroundColor: 'rgba(200,200,200,0.5)',
                                    ...textOptions
                                });

                                self.canvas.add(textA);
                                self.canvas.add(textB);
                                self.canvas.add(textAlias);
                                self.canvas.sendToBack(textAlias);
                                line.textA = textA;
                                line.textB = textB;
                                line.textAlias = textAlias;
                            }
                        });
                }

                if (self.showOverlay.heatmap) {
                    let url = `${self.currentCamera.heatmap}&ts=${+self.moment.now()}`;
                    let image = new fabric.Image.fromURL(
                        url,
                        function (image) {
                            image.setOptions({
                                left: 0,
                                top: 0,
                                opacity: 0.5,
                                scaleX: self.canvas.width / image.width,
                                scaleY: self.canvas.height / image.height,
                                originX: 'left',
                                originY: 'top',
                            });
                            image.set('selectable', false);
                            self.canvas.add(image);
                            image.moveTo(20);
                            self.canvas.renderAll();
                        },
                        { crossOrigin: 'anonymous' }
                    );
                }

                if (self.hasAnalytics.faces && self.showOverlay.faces) {
                    const faces = self.snapshot.identities ? self.snapshot.identities : self.snapshot.snapRecognitions;
                    faces.forEach((rec) => {
                        let pix =
                            rec.boundingBox.Width *
                            self.actualSnapshot.width *
                            rec.boundingBox.Height *
                            self.actualSnapshot.height;
                        let rect = new fabric.Rect({
                            fill: 'rgba(0,0,0,0)',
                            width: rec.boundingBox.Width * image.width,
                            height: rec.boundingBox.Height * image.height,
                            left: rec.boundingBox.Left * image.width,
                            top: rec.boundingBox.Top * image.height,
                            stroke: 'rgba(0,255,0, 0.5)',
                            strokeDashArray: [10, 5],
                            selectable: false,
                            strokeWidth: 3,
                        });

                        if (self.Auth.hasUserPrivilegeSync('debug')) {
                            let text = new fabric.Text(`${Math.round(pix)}`, {
                                fontSize: 12,
                                fontFamily: 'Monospace',
                                textAlign: 'center',
                                left: rect.left + rect.width / 2,
                                top: rect.top + rect.height + 20,
                                shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
                                textBackgroundColor: 'rgba(200,200,200,0.5)',
                                originX: 'center',
                                originY: 'center',
                                //padding: 10,
                                selectable: false,
                                hasBorders: false,
                                hasControls: false,
                            });
                            self.canvas.add(text);
                        }
                        self.canvas.add(rect);
                        rect.moveTo(30);
                        rect.bringToFront();
                    });
                }

                if (
                    self.showOverlay.people &&
                    self.snapshot.openvinoPeopleDetections &&
                    self.snapshot.openvinoPeopleDetections.length > 0 &&
                    self.Auth.hasRoleSync('secuvue.Analytics.Cloud.PeopleDetector')
                ) {
                    self.snapshot.openvinoPeopleDetections.forEach((rec) => {
                        let rect = new fabric.Rect({
                            fill: 'rgba(0,0,0,0)',
                            width: rec.boundingBox.width * image.width,
                            height: rec.boundingBox.height * image.height,
                            left: rec.boundingBox.left * image.width,
                            top: rec.boundingBox.top * image.height,
                            stroke: 'rgba(255,255,0, 0.5)',
                            strokeDashArray: [10, 5],
                            selectable: false,
                            strokeWidth: 3,
                        });
                        let text = new fabric.Text(`${Math.round(rec.confidence * 10000) / 100}%`, {
                            fontSize: 12,
                            fontFamily: 'Monospace',
                            textAlign: 'center',
                            left: rect.left + rect.width / 2,
                            top: rect.top + 20,
                            shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
                            textBackgroundColor: 'rgba(255,255,0,0.5)',
                            originX: 'center',
                            originY: 'center',
                            //padding: 10,
                            selectable: false,
                            hasBorders: false,
                            hasControls: false,
                        });
                        self.canvas.add(text);
                        self.canvas.add(rect);
                        rect.moveTo(30);
                        rect.bringToFront();
                    });
                }

                if (
                    self.showOverlay.poses &&
                    self.snapshot.humanPoseResults.length > 0 &&
                    self.Auth.hasRoleSync('secuvue.Analytics.Cloud.HumanPoseDetector')
                ) {
                    let pixels = image.width * image.height;
                    let circRadius = pixels < 500000 ? 1 : 4;
                    self.snapshot.humanPoseResults.forEach((pose) => {
                        self.keypointPairs.forEach((pair, num) => {
                            let kp0 = pose.keypoints[pair[0]];
                            let kp1 = pose.keypoints[pair[1]];
                            if (![kp0.x, kp0.y, kp1.x, kp1.y].includes(-1)) {
                                let line = new fabric.Line(
                                    [
                                        kp0.x * image.width,
                                        kp0.y * image.height,
                                        kp1.x * image.width,
                                        kp1.y * image.height,
                                    ],
                                    {
                                        strokeWidth: 3,
                                        stroke: self.colormap[num],
                                        strokeDashArray: [10, 5],
                                        originX: 'center',
                                        originY: 'center',
                                        perPixelTargetFind: true,
                                        selectable: false,
                                        targetFindTolerance: 10,
                                        //padding: 4,
                                        hasControls: false,
                                    }
                                );
                                self.canvas.add(line);
                            }
                        });
                        pose.keypoints.forEach((kp, num) => {
                            //Draw circles for each keypoint
                            if (kp.x !== -1 && kp.y !== -1) {
                                let kpCircle = new fabric.Circle({
                                    fill: self.colormap[num],
                                    radius: circRadius,
                                    originX: 'center',
                                    originY: 'center',
                                    //radius:1,
                                    left: kp.x * image.width,
                                    top: kp.y * image.height,
                                    selectable: false,
                                });
                                self.canvas.add(kpCircle);
                                kpCircle.bringToFront();
                            }
                        });
                    });
                }

                if (
                    self.showOverlay.vehicles &&
                    self.snapshot.openvinoVehicleDetections &&
                    self.snapshot.openvinoVehicleDetections.length > 0 &&
                    self.Auth.hasRoleSync('secuvue.Analytics.Cloud.VehicleDetector')
                ) {
                    self.snapshot.openvinoVehicleDetections.forEach((rec) => {
                        let rect = new fabric.Rect({
                            fill: 'rgba(0,0,0,0)',
                            width: rec.boundingBox.width * image.width,
                            height: rec.boundingBox.height * image.height,
                            left: rec.boundingBox.left * image.width,
                            top: rec.boundingBox.top * image.height,
                            stroke: 'rgba(0,255,255, 0.5)',
                            strokeDashArray: [10, 5],
                            selectable: false,
                            strokeWidth: 3,
                        });
                        let text = new fabric.Text(`${Math.round(rec.confidence * 10000) / 100}%`, {
                            fontSize: 12,
                            fontFamily: 'Monospace',
                            textAlign: 'center',
                            left: rect.left + rect.width / 2,
                            top: rect.top + 20,
                            shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
                            textBackgroundColor: 'rgba(0,255,255,0.5)',
                            originX: 'center',
                            originY: 'center',
                            //padding: 10,
                            selectable: false,
                            hasBorders: false,
                            hasControls: false,
                        });
                        self.canvas.add(text);
                        self.canvas.add(rect);
                        rect.moveTo(30);
                        rect.bringToFront();
                    });
                }

                self.snapshot.detections.forEach(function (detection) {
                    let detWidth = detection.rect.right - detection.rect.left;
                    let detHeight = detection.rect.bottom - detection.rect.top;
                    let pixels = image.width * image.height;

                    let rectOptions = {
                        fill: 'rgba(0,0,0,0)',
                        width: detWidth,
                        height: detHeight,
                        left: detection.rect.left,
                        top: detection.rect.top,
                        selectable: false,
                        strokeWidth: 3,
                    };

                    let hasText = true;
                    let textContents = '';
                    let textOptions = {
                        fontSize: 12,
                        fontFamily: 'Monospace',
                        textAlign: 'center',
                        shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
                        textBackgroundColor: 'rgba(0,255,0,0.5)',
                        originX: 'center',
                        originY: 'center',
                        selectable: false,
                        hasBorders: false,
                        hasControls: false,
                    };

                    let hasSubText = true;
                    let subTextContents = '';
                    let subTextOptions = { ... textOptions };

                    let doSkip = false;

                    switch (detection.detectionType) {
                        case 'Motion': {
                            if (!self.showOverlay.motion) {
                                doSkip = true;
                                break;
                            }

                            hasText = false;
                            rectOptions.stroke = 'rgba(0,0,255, 0.5)';
                        } break;
                        case 'FrontalFace': {
                            if (!self.showOverlay.faces && self.snapshot.snapRecognitions.length !== 0 && self.snapshot.identities?.length !== 0) {
                                doSkip = true;
                                break;
                            }

                            rectOptions.stroke = 'rgba(170, 139, 224,0.5)';
                            textContents = `${Math.round(detection.confidence * 10000) / 100} %`;
                            textOptions.textBackgroundColor = 'rgba(0,255,0,0.5)';
                        } break;
                        case 'Person': {
                            if (!self.showOverlay.people || !self.Auth.hasRoleSync('secuvue.Analytics.Onboard.PeopleDetector')) {
                                doSkip = true;
                                break;
                            }

                            rectOptions.stroke = 'rgba(255,127,0, 0.5)';
                            textContents = `${Math.round(detection.confidence * 10000) / 100} %`;
                            textOptions.textBackgroundColor = 'rgba(255,127,0,0.5)';
                        } break;
                        case 'Vehicle': {
                            if (!self.showOverlay.vehicles || !self.Auth.hasRoleSync('secuvue.Analytics.Onboard.VehicleDetector')) {
                                doSkip = true;
                                break;
                            }

                            rectOptions.stroke = 'rgba(0, 127, 255, 0.5)';
                            textContents = `${Math.round(detection.confidence * 10000) / 100} %`;
                            textOptions.textBackgroundColor = 'rgba(0,127,255,0.5)';
                        } break;
                        case 'SSD': {
                            if (!self.showOverlay.ssd || !self.Auth.hasRoleSync('secuvue.Analytics.Onboard.SSDDetector') ||
                                !self.Auth.hasUserPrivilegeSync('debug')) {
                                doSkip = true;
                                break;
                            }

                            rectOptions.width *= self.snapshot.width;
                            rectOptions.height *= self.snapshot.height;
                            rectOptions.left *= self.snapshot.width;
                            rectOptions.top *= self.snapshot.height;
                            rectOptions.stroke = 'rgba(18, 138, 0, 0.5)';

                            textContents = `${detection.label[0].toUpperCase() + detection.label.substring(1)}`;
                            textOptions.textBackgroundColor = 'rgba(18, 138, 0, 0.5)';
                        } break;
                        case 'HumanPose': {
                            if (!self.showOverlay.poses || !self.Auth.hasRoleSync('secuvue.Analytics.Onboard.HumanPoseDetector')) {
                                doSkip = true;
                                break;
                            }

                            let circRadius = pixels < 500000 ? 1 : 4;
                            self.keypointPairs.forEach((pair, num) => {
                                let kp0 = detection.keypoints[pair[0]];
                                let kp1 = detection.keypoints[pair[1]];
                                if (![kp0.x, kp0.y, kp1.x, kp1.y].includes(-1)) {
                                    let line = new fabric.Line(
                                        [
                                            kp0.x * image.width,
                                            kp0.y * image.height,
                                            kp1.x * image.width,
                                            kp1.y * image.height,
                                        ],
                                        {
                                            strokeWidth: 3,
                                            stroke: self.colormap[num],
                                            originX: 'center',
                                            originY: 'center',
                                            perPixelTargetFind: true,
                                            selectable: false,
                                            targetFindTolerance: 10,
                                            hasControls: false,
                                        }
                                    );
                                    self.canvas.add(line);
                                }
                            });
                            detection.keypoints.forEach((kp, num) => {
                                // Draw circles for each keypoint
                                if (kp.x !== -1 && kp.y !== -1) {
                                    let kpCircle = new fabric.Circle({
                                        fill: self.colormap[num],
                                        radius: circRadius,
                                        originX: 'center',
                                        originY: 'center',
                                        left: kp.x * image.width,
                                        top: kp.y * image.height,
                                        selectable: false,
                                    });
                                    self.canvas.add(kpCircle);
                                    kpCircle.bringToFront();
                                }
                            });

                            rectOptions.width *= self.snapshot.width;
                            rectOptions.height *= self.snapshot.height;
                            rectOptions.left *= self.snapshot.width;
                            rectOptions.top *= self.snapshot.height;
                            rectOptions.stroke = 'rgba(80, 8, 153, 0.5)';

                            if (
                                detection.poseLabel !== undefined &&
                                detection.poseLabel !== 'Unknown'
                            ) {
                                textContents = `${detection.poseLabel}`;
                                textOptions.textBackgroundColor = 'rgba(80,8,153,0.5)';

                                let confidencePercentage =
                                    Math.round(detection.poseConfidence * 10000) / 100;
                                if (!isNaN(confidencePercentage)) {
                                    hasSubText = true;
                                    subTextContents = `${confidencePercentage}%`
                                    subTextOptions.textBackgroundColor = 'rgba(80,8,153,0.5)';
                                }
                            } else {
                                hasText = false;
                            }
                        } break;
                    }

                    if (!doSkip) {
                        let rect = new fabric.Rect(rectOptions);
                        if (hasText) {
                            textOptions.left = rect.left + detWidth / 2;
                            textOptions.top = rect.top + 10;

                            if (detection.detectionType === 'SSD') {
                                textOptions.left = rect.left + (detWidth * self.snapshot.width) / 2;
                            }

                            if (detection.detectionType === 'HumanPose') {
                                textOptions.left = rect.left + (detWidth * self.snapshot.width) / 2;
                                textOptions.top = rect.top - 7;
                            }
                            let text = new fabric.Text(textContents, textOptions);
                            self.canvas.add(text);
                        }
                        if (hasSubText) {
                            subTextOptions.left = rect.left + (detWidth * self.snapshot.width) / 2;
                            subTextOptions.top = rect.top + 10;
                            let subText = new fabric.Text(subTextContents, subTextOptions);
                            self.canvas.add(subText);
                        }
                        self.canvas.add(rect);
                        rect.moveTo(30);
                        rect.bringToFront();
                    }
                });

                if (self.showOverlay.osdTime) {
                    let textAlias = new fabric.Text(
                        self.moment(self.actualSnapshot.timestamp).format(),
                        {
                            fontSize: 28,
                            fontFamily: 'Monospace',
                            textAlign: 'center',
                            left: 0,
                            top: 0,
                            shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
                            textBackgroundColor: 'rgba(200,200,200,0.5)',
                            originX: 0,
                            originY: 0,
                            padding: 10,
                            selectable: false,
                            hasBorders: false,
                            hasControls: false,
                        }
                    );
                    self.canvas.add(textAlias);
                    self.canvas.renderAll();
                }

                if (self.showOverlay.osdSite) {
                    let textAlias = new fabric.Text(self.actualSnapshot.sitename, {
                        fontSize: 28,
                        fontFamily: 'Monospace',
                        textAlign: 'center',
                        left: 0,
                        top: 0,
                        shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
                        textBackgroundColor: 'rgba(200,200,200,0.5)',
                        originX: 'left',
                        originY: 'bottom',
                        padding: 10,
                        selectable: false,
                        hasBorders: false,
                        hasControls: false,
                    });
                    textAlias.set({ left: 0, top: self.canvas.height });
                    self.canvas.add(textAlias);
                    self.canvas.renderAll();
                }

                if (self.showOverlay.osdZone) {
                    let textAlias = new fabric.Text(self.actualSnapshot.cameraname, {
                        fontSize: 28,
                        fontFamily: 'Monospace',
                        textAlign: 'center',
                        left: 0,
                        top: 0,
                        shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
                        textBackgroundColor: 'rgba(200,200,200,0.5)',
                        originX: 'right',
                        originY: 'bottom',
                        padding: 10,
                        selectable: false,
                        hasBorders: false,
                        hasControls: false,
                    });
                    textAlias.set({ left: self.canvas.width, top: self.canvas.height });
                    self.canvas.add(textAlias);
                    self.canvas.renderAll();
                }

                self.canvas.renderAll();
            },
            { crossOrigin: 'anonymous' }
        );
    }

    requestHeatmap() {
        this.$http
            .post(`/api/cameras/${this.snapshot.camera}/requestHeatmap?${+this.moment.now()}`, {})
            .then(
                (response) => {},
                (err) => {
                    if (err.status !== 401 && err.status !== 403) {
                        console.error(err);
                    }
                }
            );
    }

    getClipStyle(bb) {
        return `inset(${bb.Top * 100}% ${100 - (bb.Left + bb.Width) * 100}% ${
            100 - (bb.Top + bb.Height) * 100
        }% ${bb.Left * 100}%)`;
    }

    formatDate() {
        let self = this;
        return self.moment(this.snapshot.timestamp).local().format('ll LTS');
    }

    ok() {
        this.$uibModalInstance.close();
    }

    // getVideo() {
    //   console.log(this.snapshot);
    //   this.$uibModalInstance.close();
    //   this.$state.go('fileManager', {id: this.snapshot.site, cameras: [this.snapshot.camera], date: this.snapshot.timestamp})
    //   .catch(err => {
    //     console.error(err);
    //   })
    // }

    detectLabels() {
        let self = this;
        self.labelling = true;
        self.$http.get(`api/snapshots/detectLabels/${self.snapshot._id}`).then((response) => {
            self.labelling = false;
            self.snapshot = response.data;
            self.toastr.success('Scene Analysis complete', { preventOpenDuplicates: true });
        });
    }

    loadPlateMore(id) {
        let self = this;
        self.$http
            .get(`api/snapshots/snapPlates/${id}`, {
                params: {
                    snapId: self.snapshot._id,
                    timestamp:
                        +self.plateSnaps[`${id}`][self.plateSnaps[`${id}`].length - 1].timestamp,
                    //timestamp: +self.plateSnaps[`${id}`][0].timestamp
                },
            })
            .then((response) => {
                if (response.data.length > 0) {
                    self.plateSnaps[`${id}`] = self.plateSnaps[`${id}`].concat(response.data);
                }
                if (response.data.length < 4) {
                    self.toastr.warning('There are no more photos to be loaded', {
                        preventOpenDuplicates: true,
                    });
                    self.plateMore = false;
                }
            });
    }

    loadMore(id) {
        let self = this;
        self.$http
            .get(`api/snapshots/snapFaces/${id}`, {
                params: {
                    snapId: self.snapshot._id,
                    timestamp:
                        +self.tempSnaps[`${id}`][self.tempSnaps[`${id}`].length - 1].timestamp,
                    //timestamp: +self.tempSnaps[`${id}`][0].timestamp
                },
            })
            .then((response) => {
                if (response.data.length > 0) {
                    self.tempSnaps[`${id}`] = self.tempSnaps[`${id}`].concat(response.data);
                }
                if (response.data.length < 4) {
                    self.toastr.warning('There are no more photos to be loaded', {
                        preventOpenDuplicates: true,
                    });
                    self.more = false;
                }
            });
    }

    populatePlate() {
        let self = this;
        let query = [];
        self.snapshot.lprResults.forEach((obj) => {
            query.push(obj.plate);
        });
        self.$http
            .get('api/plates/populateWanted', { params: { plates: query } })
            .then((response) => {
                response.data.forEach((obj) => {
                    self.plateWanted[obj.plate] = obj;
                    let snapInd = _.findIndex(self.snapshot.lprResults, (o) => {
                        return o.plate == obj.plate;
                    });
                    if (snapInd !== -1) {
                        self.snapshot.lprResults[snapInd].metagratedInfo = obj.metagratedInfo;
                    }
                });
            });
    }

    findPlateResults(plate) {
        let self = this;
        if (self.snapshot.lprResults.length > 0 && !self.plateSnaps[`${plate}`]) {
            self.populatePlate();
            self.$http
                .get(`api/snapshots/snapPlates/${plate}`, {
                    params: {
                        snapId: self.snapshot._id,
                        timestamp: +self.moment().utc(),
                    },
                })
                .then((response) => {
                    if (response.data.length > 0) {
                        if (response.data.length < 4) {
                            self.plateMore = false;
                        }
                        self.plateSnaps[`${plate}`] = response.data;
                    } else {
                        self.plateSnaps[`${plate}`] = [];
                    }
                });
        }
    }

    findRecogResults(id) {
        let self = this;
        if (self.snapshot.identities?.length > 0 && !self.tempSnaps[`${id}`] && id?.length) {
            self.populateWanted();
            self.$http
                .get(`api/snapshots/snapFaces/${id}`, {
                    params: {
                        snapId: self.snapshot._id,
                        timestamp: +self.moment().utc(),
                    },
                })
                .then((response) => {
                    if (response.data.length > 0) {
                        if (response.data.length < 4) { 
                            self.more = false;
                        }
                        self.tempSnaps[`${id}`] = response.data;
                    }
                });
        }
        if (self.snapshot.identities?.length > 0 && !self.identityFaces[`${id}`] && id?.length) {
            self.$http.get(`api/identities/${id}/face`).then((response) => {
                if (!response.data) return;
                const identity = response.data;
                const face = identity.faces[0];
                let image = new fabric.Image.fromURL(
                    face?.data,
                    function (image) {
                        image.setOptions({
                            left: 0,
                            top: 0,
                            opacity: 1,
                            width: face?.width,
                            height: face?.height,
                        });
                        self.$timeout(function () {
                            self.identityFaces[`${id}`] = image.toDataURL({
                                multiplier: 3,
                                left: face?.boundingBox.Left * face?.width,
                                top: face?.boundingBox.Top * face?.height,
                                width: face?.boundingBox.Width * face?.width,
                                height: face?.boundingBox.Height * face?.height,
                            });
                        });
                    },
                    { crossOrigin: 'anonymous' }
                );
            });
        }

        if (self.snapshot.identities?.length > 0 && !self.tempCrop[`${id}`]) {
            let image = new fabric.Image.fromURL(
                self.actualSnapshot.data,
                function (image) {
                    image.setOptions({
                        left: 0,
                        top: 0,
                        opacity: 1,
                        width: self.snapshot.width,
                        height: self.snapshot.height,
                    });
                    let ident = _.find(self.snapshot.identities, (i) => {
                        return i.userId === id;
                    });
                    if (!ident && !id?.length) {
                        ident = self.snapshot.identities[id];
                    }
                    if (ident) {
                        self.tempCrop[`${id}`] = image.toDataURL({
                            multiplier: 3,
                            left: ident.boundingBox.Left * self.snapshot.width,
                            top: ident.boundingBox.Top * self.snapshot.height,
                            width: ident.boundingBox.Width * self.snapshot.width,
                            height: ident.boundingBox.Height * self.snapshot.height,
                        });
                    }
                },
                { crossOrigin: 'anonymous' }
            );
        }
    }

    verifyPlate(correct, index) {
        let self = this;
        if (correct) {
            self.snapshot.lprResults[index].userVerified = true;
            self.snapshot.lprResults[index].userCorrect = true;
        } else {
            self.snapshot.lprResults[index].userVerified = true;
            self.snapshot.lprResults[index].userCorrect = false;
        }
        self.$http
            .patch(`api/snapshots/${self.snapshot._id}`, {
                lprResults: self.snapshot.lprResults,
            })
            .then((response) => {
                //console.log("RESPONSE: ", response);
            });
    }

    correctPlate(plate) {
        let self = this;
        //console.log("PLATE: ", plate);
        self.$ngConfirm({
            title: 'Verify Plate',
            escapeKey: false,
            backgroundDismiss: false,
            scope: self.$scope,
            content: `
            Please enter the correct licence plate number:
            <input ng-model="$ctrl.correctedPlate" type="text">
            `,
            buttons: {
                enter: {
                    text: 'Enter',
                    btnClass: 'btn-blue',
                    action(scope, button) {
                        if (self.correctedPlate) {
                            self.updatePlateResult(plate.plate, self.correctedPlate, false);
                            self.correctedPlate = undefined;
                        } else {
                            self.toastr.warning('Plate cannot be empty when correcting', {
                                preventOpenDuplicates: true,
                            });
                            return false;
                        }
                    },
                },
                noPlate: {
                    text: 'Not a Plate',
                    btnClass: 'btn-red',
                    action(scope, button) {
                        self.updatePlateResult(plate.plate, undefined, true);
                        self.correctedPlate = undefined;
                    },
                },
                close(scope, button) {},
            },
        });
    }

    updatePlateResult(oldPlate, newPlate, noPlate) {
        let self = this;
        self.$http
            .patch(`/api/snapshots/updatePlateResult/${self.snapshot._id}`, {
                oldPlate,
                noPlate,
                newPlate,
            })
            .then((response) => {
                self.toastr.info('LPR Results Updated Successfully', {
                    preventOpenDuplicates: true,
                });
            });
    }

    metaQuery(plateIndex) {
        let self = this;
        let plate = self.snapshot.lprResults[plateIndex];
        let actualPlate = self.plateWanted[plate.plate];
        if (actualPlate && self.Auth.hasPrivilegeSync(['secuvue.plate.metagrate'])) {
            let params = {
                plateNum: plate.plate,
            };
            self.$http
                .post(`/api/plates/metagrate`, params)
                .then((response) => {
                    if (response.data) {
                        actualPlate.metagratedUUID = response.data.uuid;
                        actualPlate.metagratedFlagged = response.data.status === 'Y';
                        plate.metagratedUUID = response.data.uuid;
                        plate.metagratedFlagged = response.data.status === 'Y';
                        self.$http
                            .patch(
                                `/api/plates/${actualPlate._id}`,
                                _.pick(actualPlate, ['metagratedUUID', 'metagratedFlagged'])
                            )
                            .then(() => {
                                self.snapshot.lprResults[plateIndex] = plate;
                                console.log('Updated plate VOI status');
                            })
                            .catch((err) => {
                                console.error('Failed to update plate VOI status:', err);
                            });
                    }
                })
                .catch((err) => {
                    console.log('VOI Request error occurred:', err);
                });
        }
    }

    formatSnapDate(date) {
        return this.moment(date).local().format('ll LTS');
    }

    faceRecall() {
        let self = this;
        self.$http.get(`api/snapshots/faceRecall/${self.snapshot._id}`).then(() => {
            self.recalling = true;
            self.toastr.info('Snapshot added to FaceRecall pipeline', {
                preventOpenDuplicates: true,
            });
            //console.log('facerecall', response.data);
            //if (response.data.IndexFaces.FaceRecords.length>0){
            //self.toastr.success("FaceRecall completed successfully", {preventOpenDuplicates: true})
            //self.drawCanvas();
            //self.snapshot.snapRecognitions = response.data.up.snapRecognitions;
            //self.tempIdentities = response.data.up.snapRecognitions;
            //this.tempIdentities.forEach(recog=>{
            //recog['tempSimilarity'] = Math.floor(recog.similarity);
            //this.findRecogResults(recog.faceId);
            //});
            //}else{
            //self.toastr.error("FaceRecall could not find any faces", {preventOpenDuplicates: true})
            //}
        });
    }

    vehicleDetection() {
        let self = this;
        self.$http
            .get(`api/snapshots/queueAnalytics/${self.snapshot._id}`, {
                params: { detectionType: 'vehicle' },
            })
            .then(() => {
                self.doingAnalytics = true;
                self.toastr.info('Snapshot added to vehicle detection pipeline', {
                    preventOpenDuplicates: true,
                });
            });
    }

    peopleDetection() {
        let self = this;
        self.$http
            .get(`api/snapshots/queueAnalytics/${self.snapshot._id}`, {
                params: { detectionType: 'people' },
            })
            .then(() => {
                self.doingAnalytics = true;
                self.toastr.info('Snapshot added to people detection pipeline', {
                    preventOpenDuplicates: true,
                });
            });
    }

    humanPoseDetection() {
        let self = this;
        self.$http
            .get(`api/snapshots/queueAnalytics/${self.snapshot._id}`, {
                params: { detectionType: 'humanPose' },
            })
            .then(() => {
                self.doingAnalytics = true;
                self.toastr.info('Snapshot added to people detection pipeline', {
                    preventOpenDuplicates: true,
                });
            });
    }

    getVideo() {
        let self = this;
        let modalScope = this.$scope.$new();
        modalScope.getParams = function () {
            return {
                id: self.snapshot.site,
                cameras: [self.snapshot.camera],
                date: self.snapshot.timestamp,
            };
        };
        modalScope.isModal = true;
        let fileManagerModalInstance = self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('../fileManager/fileManager.html'),
            controller: require('../fileManager/fileManager.component').FileManagerComponent,
            controllerAs: '$ctrl',
            size: 'lg',
            scope: modalScope,
        });
        modalScope.fileManagerModalInstance = fileManagerModalInstance;
        fileManagerModalInstance.result.then(
            function (result) {},
            function () {}
        );
    }

    jumpToSettings() {
        let self = this;
        this.$uibModalInstance.close({ settingsJump: self.snapshot.camera });
    }

    requestFromGlacier() {
        const self = this;
        self.$http
            .post(`api/snapshots/restoreS3Image/${self.snapshot._id}`)
            .then((response) => {
                console.log(response);
                if (response.status !== 400) {
                    console.log(response.data);
                    self.actualSnapshot = response.data;
                    self.toastr.info('Snapshot retrieval requested', {
                        preventOpenDuplicates: true,
                    });
                }
            })
            .catch((response) => {
                console.error(response);
            });
    }
}
