import _ from 'lodash';

export default class ChooseRoleModalController {
    $http;
    $scope;
    $uibModalInstance;
    $uibModal;
    $state;
    userRoleOptions;
    /* @ngInject */

    constructor($uibModalInstance, account, userRoleOptions, currentAccount, $state, $uibModal, user, $http, $scope, toastr) {
        this.$http = $http;
        this.$state = $state;
        this.toastr = toastr;
        this.newUser = {};
        this.$scope = $scope;
        this.user = user;
        this.account = account;
        this.currentAccount = currentAccount;
        this.userRoleOptions = userRoleOptions;
        this.$uibModalInstance = $uibModalInstance;
        this.$uibModal = $uibModal;
    }

    $onInit() {
        const self = this;
        const accInd = _.findIndex(self.user.accounts, (o) => o.accountId == self.account.accountId);
        if (accInd !== -1) {
            self.updateRole = self.user.accounts[accInd].role;
        }
    }

    cancel() {
        const self = this;
        self.$uibModalInstance.dismiss();
    }

    changeRole(user, role) {
        const self = this;
        self.$uibModalInstance.close({ userId: user, roleId: role });
    }
}
