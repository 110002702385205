import _ from 'lodash';
import angular from 'angular';
import { fabric } from 'fabric';

import UploadController from './upload.controller';
import OptionsController from './options.controller';
import MetaQueryController from './metaQuery.controller';
import SnapshotModalController from '../live/modal.controller';

const PlateTemplate = require('./plate.html');
const UploadTemplate = require('./upload.html');
const OptionsTemplate = require('./options.html');
const MetaQueryTemplate = require('./metaQuery.html');
const SnapshotModalTemplate = require('../live/modal.html');

export class PlateComponent {
    $http;
    $document;
    $state;
    $scope;
    moment;
    Auth;
    $uibModal;
    plateRefs;
    tempCrop = {};
    newUser;
    filter = {};
    socket;
    plateDataSource = {};
    plateAdapter = {
        adapter: { remain: true },
    };
    tempFilter = {};
    tempWanted = {};
    tempSnaps = {};
    lastFilter = {};

    // eslint-disable-next-line spaced-comment
    /*@ngInject*/

    constructor(
        $http,
        NgTableParams,
        $window,
        socket,
        $uibModal,
        moment,
        $document,
        $state,
        $scope,
        Auth,
        appConfig,
        toastr,
        $timeout,
        $sce,
        $filter
    ) {
        this.$http = $http;
        this.moment = moment;
        this.$sce = $sce;
        this.$filter = $filter;
        this.NgTableParams = NgTableParams;
        this.tClass = [
            '',
            'fa fa-fw fa-flag text-warning',
            'fa fa-fw fa-ban text-success',
            'fa fa-fw fa-exclamation-triangle text-danger',
        ];
        this.flaggedMessage = ['Flagged: Any', ' Flagged', ' Not Flagged', ' VOI Flagged'];
        this.flagged = [undefined, 'true', 'false', undefined];
        this.flaggedVOI = [undefined, undefined, undefined, 'true'];
        this.showIgnored = false;
        this.tI = 0;
        this.socket = socket;
        this.$uibModal = $uibModal;
        this.$document = $document;
        this.$timeout = $timeout;
        this.$window = $window;
        this.$state = $state;
        this.$scope = $scope;
        this.toastr = toastr;
        this.Auth = Auth;
        this.countryList = appConfig.default.countryList;
        this.custClass = 'hidden-panel';
    }

    doLog() {
        console.log('DEBUG:', this);
    }

    $onInit() {
        const self = this;
        // self.plateDataSource = {};
        // self.plateDataSource.minIndex = 0;
        if (self.$state.params.filter) {
            self.lastFilter.alias = self.$state.params.filter;
            self.applyFilter(self.lastFilter);
        }
        self.socket.socket.on('plate:save', (item) => {
            self.tableParams.reload();
        });
        self.socket.socket.on('plate:remove', (item) => {
            self.tableParams.reload();
        });

        self.selectedColumns = [];

        self.cols = [
            {
                title: 'Alias',
                field: 'alias',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Plate',
                field: 'plate',
                show: true,
                sortable: 'plate',
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Region',
                field: 'region',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Last Seen Time',
                field: 'lastActiveTime',
                show: true,
                sortable: 'lastActiveTime',
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Tags',
                field: 'tags',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Last Seen Snapshot',
                field: 'lastActiveSnap',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'SA VOI Flagged',
                field: 'metagratedFlagged',
                show: true,
                sortable: 'metagratedFlagged',
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Flagged',
                field: 'flag',
                show: true,
                sortable: 'flag',
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Ignored',
                field: 'ignore',
                show: false,
                sortable: 'ignore',
                getValue: self.handleDisplay.bind(this),
            },
        ];

        /**
         * The purpose of colValues is to contain the HTML-code that we want
         * to be assumed in NgTable.
         * TODO: This still needs to be implemented, but I think this should stop
         * angular from calling handleDisplay() function continuously
         * We can then use $interval to handle update-intervals.
         * @type {Object}
         */
        self.colValues = {};

        _.forEach(self.cols, (col) => {
            if (col.show) {
                self.selectedColumns.push(col.title);
            }
            self.colValues[col.field] = '';
        });

        self.tableParams = new self.NgTableParams(
            {
                page: 1, // start with first page
                count: 10, // count per page
                sorting: {
                    lastActiveTime: 'desc', // initial sorting
                },
            },
            {
                total: 0,
                getData(params) {
                    if (params && params.sorting) {
                        return self.getPlatesTable(params).then((response) => {
                            self.plateRefs = response[0].data;
                            self.total = response[1].data;
                            params.total(self.total);
                            return self.plateRefs;
                        });
                    }
                    return null;
                },
            }
        );

        this.tableParams.reload();
    }

    getPlatesTable(params) {
        const self = this;
        const order = params.sorting();

        const queryParams = [];
        if (self.flagged[self.tI])
            queryParams.push({ field: 'flag', type: 'boolean', value: self.flagged[self.tI] });
        if (self.flaggedVOI[self.tI])
            queryParams.push({
                field: 'metagratedFlagged',
                type: 'boolean',
                value: self.flaggedVOI[self.tI],
            });
        if (!self.showIgnored)
            queryParams.push({ field: 'ignore', type: 'boolean', value: true, operator: '$ne' });

        const query = {
            filter: self.filter.filter ? self.filter.filter : undefined,
            skip: (params.page() - 1) * params.count(),
            limit: params.count(),
            by: Object.keys(order)[0],
            order: order[Object.keys(order)[0]],
            params: JSON.stringify(queryParams),
        };

        const dataPromise = self.$http.get('/api/plates', { params: query }).then((response) => {
            response.data.forEach((plate) => {
                if (plate.lastActiveSnap && plate.lastActiveSnap.id) {
                    const lastSnap = plate.lastActiveSnap.id;
                    if (
                        self.selectedColumns.indexOf('Last Seen Snapshot') !== -1 &&
                        lastSnap.data
                    ) {
                        try {
                            fabric.Image.fromURL(
                                lastSnap.data,
                                (image) => {
                                    image.setOptions({
                                        left: 0,
                                        top: 0,
                                        opacity: 1,
                                    });
                                    const snapPlate = _.find(
                                        lastSnap.lprResults,
                                        (o) => o.plate === plate.plate
                                    );
                                    if (snapPlate) {
                                        let left;
                                        let top;
                                        let bottom;
                                        let right;
                                        snapPlate.boundingBox.forEach((point) => {
                                            if (!left || point.x < left) {
                                                left = point.x;
                                            }
                                            if (!right || point.x > right) {
                                                right = point.x;
                                            }
                                            if (!bottom || point.y > bottom) {
                                                bottom = point.y;
                                            }
                                            if (!top || point.y < top) {
                                                top = point.y;
                                            }
                                        });
                                        left *= image.width;
                                        right *= image.width;
                                        top *= image.height;
                                        bottom *= image.height;
                                        plate.lastSeenCrop = image.toDataURL({
                                            left,
                                            top,
                                            width: right - left,
                                            height: bottom - top,
                                        });
                                    }
                                },
                                { crossOrigin: 'anonymous' }
                            );
                        } catch (e) {
                            console.error('Fabric error', e);
                        }
                    }
                }
            });
            return response;
        });
        const countPromise = self.$http.get('/api/plates/count', {
            params: {
                filter: self.filter.filter ? self.filter.filter : undefined,
                params: JSON.stringify(queryParams),
            },
        });
        return Promise.all([dataPromise, countPromise]);
    }

    applyFilter(filter) {
        const self = this;
        self.filter = _.clone(filter);
        this.tableParams.page(1);
        self.tableParams.reload();
    }

    update(id, value) {
        const self = this;
        self.$http
            .patch(
                `/api/plates/${id}`,
                _.pick(value, [
                    'alias',
                    'plate',
                    'ignore',
                    'flag',
                    'description',
                    'tags',
                    'province',
                    'metagratedUUID',
                    'metagratedFlagged',
                ])
            )
            .then(() => {
                self.tableParams.reload();
            });
    }

    editPlate(plate) {
        const self = this;
        self.$uibModal
            .open({
                animation: true,
                backdrop: 'static',
                template: OptionsTemplate,
                controller: OptionsController,
                controllerAs: '$ctrl',
                size: 'm',
                resolve: {
                    plate() {
                        return plate;
                    },
                },
            })
            .result.then((result) => {
                if (result.plate) {
                    return self.update(result.plate._id, result.plate);
                }
                if (result.deleting) {
                    return self.deletePlate(result.deleting);
                }
                if (result.snap) {
                    return self.openSnap(result.snap);
                }
                return null;
            });
    }

    openSnap(snapshot) {
        const self = this;
        self.$uibModal
            .open({
                animation: true,
                backdrop: true,
                template: SnapshotModalTemplate,
                controller: SnapshotModalController,
                controllerAs: '$ctrl',
                size: 'lg',
                resolve: {
                    snapshot() {
                        return snapshot;
                    },
                    snapPlaceholder() {
                        return [];
                    },
                    overlay() {
                        return undefined;
                    },
                },
            })
            .result.then(
                (result) => {
                    if (result && result.newSnap) {
                        self.openSnap(result.newSnap);
                    }
                },
                () => {}
            );
    }

    deletePlate(plate) {
        const self = this;
        self.$http.delete(`/api/plates/${plate}`).then((response) => {
            console.info('Plate deleted', response);
            self.toastr.info('Plate has been deleted');
            self.plateRefs -= 1;
        });
    }

    uploadWanted() {
        const self = this;
        self.$uibModal
            .open({
                animation: true,
                backdrop: 'static',
                template: UploadTemplate,
                controller: UploadController,
                controllerAs: '$ctrl',
                size: 'm',
                resolve: {
                    plate() {
                        return undefined;
                    },
                },
            })
            .result.then(
                () => {},
                () => {}
            );
    }

    clearFilter() {
        const self = this;
        self.lastFilter = {};
        self.applyFilter(self.lastFilter);
    }

    openMetaQuery() {
        const self = this;
        self.$uibModal
            .open({
                animation: true,
                backdrop: 'static',
                template: MetaQueryTemplate,
                controller: MetaQueryController,
                controllerAs: '$ctrl',
                size: 'm',
                resolve: {},
            })
            .result.then(
                () => {},
                () => {}
            );
    }

    onColumnSelected($item, $model) {
        $item.show = true;
    }

    onColumnRemoved($item, $model) {
        $item.show = false;
    }

    toggleIgnored() {
        this.showIgnored = !this.showIgnored;
        const ignoreIndex = _.findIndex(this.cols, { field: 'ignore' });
        this.cols[ignoreIndex].show = this.showIgnored;
        this.tableParams.reload();
    }

    handleDisplay(self, col, plate) {
        let html = '';
        switch (col.field) {
            case 'alias':
                return plate.alias ? plate.alias : plate.plate;

            case 'region':
                if (plate.region) {
                    return self.$filter('regionFilter')(plate.region, self.countryList);
                }
                return 'Region Unknown';

            case 'metagratedFlagged':
                if (plate[col.field]) {
                    html =
                        '<i class="fa fa-exclamation-triangle text-danger" uib-tooltip="VOI Identified"></i>';
                } else if (plate.metagratedUUID) {
                    html = '<i class="fa fa-ban text-success" uib-tooltip="Vehicle clear"></i>';
                } else {
                    html =
                        '<i class="fa fa-question-circle text-muted" uib-tooltip="Unconfirmed"></i>';
                }
                return self.$sce.trustAsHtml(html);

            case 'flag':
                if (plate[col.field]) {
                    html = '<i class="fa fa-flag text-warning" uib-tooltip="Flagged vehicle"></i>';
                } else {
                    html = '<i class="fa fa-ban text-success"></i>';
                }
                return self.$sce.trustAsHtml(html);

            case 'ignore':
                if (plate[col.field]) {
                    return 'Yes';
                }
                return 'No';

            case 'lastActiveTime':
                if (plate.lastActiveTime) {
                    html += "{{plate.lastActiveTime | amDateFormat: 'HH:mm:ss DD-MM-YYYY'}}";
                } else if (plate.timestamp) {
                    html += "{{plate.timestamp | amDateFormat: 'HH:mm:ss DD-MM-YYYY'}}";
                } else {
                    return plate[col.field];
                }
                return self.$sce.trustAsHtml(html);

            case 'lastActiveSnap':
                if (plate.lastSeenCrop) {
                    html +=
                        '<img crossorigin="anonymous" ng-src="{{plate.lastSeenCrop}}" alt="Licence Plate Recognition Crop" style="width:20em">';
                } else if (plate.lastActiveSnap && plate.lastActiveSnap.data) {
                    html +=
                        '<img crossorigin="anonymous" ng-src="{{plate.lastActiveSnap.data}}" alt="Licence Plate Recognition Snapshot" style="width:20em">';
                } else {
                    return 'No last seen snapshot';
                }
                return self.$sce.trustAsHtml(html);

            case 'tag':
                html +=
                    '<span style="margin-left:0.1em; margin-right:0.1em;" class="label label-info" ng-repeat="tag in plate.tags">{{tag}}</span>';
                return self.$sce.trustAsHtml(html);

            default:
                return plate[col.field];
        }
    }
}

export default angular
    .module('cameraViewerApp.plate')
    .component('plate', {
        template: PlateTemplate,
        controller: PlateComponent,
        controllerAs: '$ctrl',
    })
    .filter(
        'regionFilter',
        () =>
            function processRegion(input, source) {
                const provinceOptions = {
                    'za-nl': 'KwaZulu Natal',
                    'za-gt': 'Gauteng',
                    'za-nw': 'North West Province',
                    'za-mp': 'Mpumalanga',
                    'za-lp': 'Limpopo',
                    'za-ec': 'Eastern Cape',
                    'za-wc': 'Western Cape',
                    'za-fs': 'Free State',
                    'za-nc': 'Northern Cape',
                };

                const countriesWithSubRegions = {
                    ae: 'United Arab Emirates',
                    au: 'Australia',
                    br: 'Brazil',
                    ca: 'Canada',
                    pe: 'Peru',
                    th: 'Thailand',
                    us: 'United States',
                };

                if (input === undefined) {
                    return 'Region Unknown';
                }

                const countryItem = _.find(source, (value) => value.region === input);

                if (countryItem) {
                    return countryItem.country;
                }
                if (input === 'other') {
                    return 'Other';
                }
                if (Object.hasOwnProperty.call(provinceOptions, input)) {
                    return provinceOptions[input];
                }
                if (input.length > 2) {
                    const inputSubstring = input.substring(0, 2);
                    for (const [regionCode, regionName] of Object.entries(
                        countriesWithSubRegions
                    )) {
                        if (inputSubstring === regionCode) {
                            return regionName;
                        }
                    }
                }
                return `Other (${input})`;
            }
    ).name;
