import _ from 'lodash-es';
import angular from 'angular';
import exceljs from 'exceljs';
import FileSaver from 'file-saver';

export class SiteNotesComponent {
    $http;
    $state;
    socket;
    moment;
    toastr;
    Auth;
    sites;

    /* @ngInject */
    constructor($http, toastr, $timeout, moment, socket, $state, $scope, Auth, appConfig) {
        this.$http = $http;
        this.$state = $state;
        this.socket = socket;
        this.Auth = Auth;
        this.$timeout = $timeout;
        this.toastr = toastr;
        this.moment = moment;
        this.currentRooms = [];
        this.sites = [];
        this.loading = true;
        this.isProcessing = false;
        this.allAccounts = false;
        this.hasNotes = false;
        this.noteCount = 0;
        this.accountsWithNotes = 0;
        this.searchQuery = '';
        this.onlyAttention = false;
        this.newestFirst = true;
        this.availableTags = appConfig.default.siteNoteTagOptions;
        this.selectedTags = [];
        this.exportColumns = [
            'Site',
            'Last Activity',
            'Note Topic',
            'Note Content',
            'Tags',
            'Creator',
            'Creation Time',
            'Attention',
        ];
        this.noteTagCounts = {};
        this.minTime = new Date().getTime();
        this.maxTime = new Date().getTime();
    }

    $onDestroy() {

    }

    $onInit() {
        const self = this;
        self.refresh();
    }

    toggleItem(item) {
        const self = this;
        switch (item) {
        case 'order':
            self.newestFirst = !self.newestFirst;
            break;
        case 'attention':
            self.onlyAttention = !self.onlyAttention;
            break;
        case 'accounts':
            self.allAccounts = !self.allAccounts;
            break;
        default:
            break;
        }
        self.refresh();
    }

    handleTag(tag) {
        const self = this;
        if (tag === 'Reset') {
            self.selectedTags = [];
        } else if (self.selectedTags.includes(tag)) {
            self.selectedTags = _.pull(self.selectedTags, tag);
        } else {
            self.selectedTags.push(tag);
        }
        self.refresh();
    }

    clearSearch() {
        const self = this;
        self.searchQuery = '';
        self.refresh();
    }

    refresh(userRequested) {
        const self = this;
        self.loading = true;
        const params = {
            order: self.newestFirst ? 'desc' : 'asc',
            tags: self.selectedTags,
            search: self.searchQuery,
            onlyAttention: self.onlyAttention,
        };
        const headers = {};
        if (self.allAccounts) headers['x-js-all-accounts'] = 'true';
        if (this.dateSlider && !userRequested) {
            if (this.dateSlider?.min !== this.minTime) {
                params.minTime = this.dateSlider.min;
            }
            if (this.dateSlider?.max !== this.maxTime) {
                params.maxTime = this.dateSlider.max;
            }
        }

        self.$http.get('api/sites/notes', { headers, params })
            .then((response) => {
                self.sites = [];
                self.hasNotes = false;
                self.noteCount = 0;
                self.availableTags.forEach((tag) => {
                    self.noteTagCounts[tag] = 0;
                });
                self.accountsWithNotes = [];
                response.data.sites.forEach(self.analyzeSites.bind(self));
                if (!self.dateSlider || userRequested) {
                    self.setupRangeSlider(response.data.minTime, response.data.maxTime);
                }
                self.loading = false;
            });
    }

    setupRangeSlider(minTime, maxTime) {
        const self = this;

        const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

        self.minTime = self.moment(minTime).startOf('day');
        self.maxTime = self.moment(maxTime).endOf('day');
        self.startTime = self.moment(minTime);

        if (self.maxTime.diff(self.startTime, 'days') > 7) {
            self.startTime = self.maxTime.clone().subtract(7, 'days');
        }

        self.dateSlider = {
            min: +self.startTime,
            max: +self.maxTime,
            options: {
                floor: +self.minTime,
                ceil: +self.maxTime,
                step: 24 * 60 * 60 * 1000,
                showTicks: false,
                draggableRange: true,
                translate(timestamp) {
                    if ((timestamp !== null)) {
                        const date = new Date(timestamp);
                        return `${date.getDate()}-${monthNames[date.getMonth()]}-${date.getFullYear()}`;
                    }
                    return '';
                },
                onEnd() {
                    self.refresh();
                },
            },
        };

        if (+self.startTime !== +self.minTime) {
            self.refresh();
        }
    }

    analyzeSites(site) {
        const self = this;

        if (site.notes?.length > 0) {
            self.hasNotes = true;
            self.noteCount += site.notes.length;
            self.sites.push({
                id: site._id,
                alias: site.alias,
                lastActivity: site.lastActivity,
                account: site.accountname,
                accountId: site.accountId,
                notes: site.notes,
                configured: site.configured,
                down: site.down,
                units: site.units,
            });
            site.notes.forEach((note) => {
                note.tags?.forEach((tag) => {
                    self.noteTagCounts[tag] += 1;
                });
            });
            if (!_.includes(self.accountsWithNotes, site.accountname)) {
                self.accountsWithNotes.push(site.accountname);
            }
        }
    }

    doLog() {
        console.log('DEBUG:', this);
    }

    formatDate(date) {
        if (!date) {
            return 'Never';
        }
        return this.moment(date)
            .local()
            .format('ll LTS');
    }

    download() {
        const self = this;
        self.isProcessing = true;
        const account = self.Auth.getCurrentAccountSync();
        const workbook = new exceljs.Workbook();
        const fullSheet = workbook.addWorksheet('Note Summary');
        fullSheet.pageSetup.horizontalCentered = true;
        fullSheet.pageSetup.verticalCentered = true;
        const dataRows = [self.allAccounts ? ['Account', ...self.exportColumns] : self.exportColumns];

        const addRows = function (site) {
            site.notes.forEach((note) => {
                let tagString = '';
                note.tags?.forEach((tag, idx) => {
                    tagString += tag;
                    if (idx + 1 !== note.tags.length) {
                        tagString += ', ';
                    }
                });
                let rowEntry = [
                    site.alias,
                    self.formatDate(site.lastActivity),
                    note.topic,
                    note.content,
                    tagString,
                    note.creatorName,
                    note.creationTime,
                    note.attention,
                ];
                if (self.allAccounts) {
                    rowEntry = [site.account, ...rowEntry];
                }
                dataRows.push(rowEntry);
            });
        };

        const addHeader = function (rowSet, sheet) {
            const requestDate = self.moment()
                .format('DD-MM-YYYY HH:mm:ss');
            let accList = account.name;
            if (self.allAccounts) {
                accList = '';
                self.accountsWithNotes.forEach((acc, idx) => {
                    accList += acc;
                    if (idx + 1 !== self.accountsWithNotes.length) {
                        accList += ', ';
                    }
                });
            }
            rowSet.unshift(['Date & Time:', requestDate]);
            rowSet.unshift([self.allAccounts ? 'Accounts:' : 'Account:', accList]);
            rowSet.forEach((row) => {
                sheet.addRow(row);
            });
        };

        const doFormatting = function (sheet) {
            sheet.getRow(1).font = { italic: true };
            sheet.getRow(2).font = { italic: true };
            sheet.getRow(3).font = { bold: true };
            // Column auto-width and alignment
            sheet.columns.forEach((column) => {
                let dataMax = 0;
                column.alignment = { vertical: 'top', horizontal: 'center' };
                column.values.forEach((value, index) => {
                    // Exclude row 1 and 2 as they have cells that are allowed to overlap.
                    if (value && value.length && index !== 1 && index !== 2) {
                        const columnLength = value.length;
                        if (columnLength > dataMax) {
                            dataMax = columnLength + 1;
                        }
                    }
                });
                column.width = dataMax < 10 ? 10 : dataMax;
            });
            // Alignment exceptions
            sheet.getRow(1).alignment = { horizontal: 'left' };
            sheet.getRow(2).alignment = { horizontal: 'left' };
            sheet.getCell('A1').alignment = { horizontal: 'right' };
            sheet.getCell('A2').alignment = { horizontal: 'right' };
        };

        self.sites.forEach(addRows);
        addHeader(dataRows, fullSheet);
        doFormatting(fullSheet);

        workbook.xlsx.writeBuffer()
            .then((buffer) => {
                const timestamp = self.moment().format('DD_MM_YYYY_HH:mm:ss');
                FileSaver.saveAs(new Blob([buffer]), `${self.allAccounts ? 'Full_Estate' : account.name}_Notes_Summary_${timestamp}.xlsx`);
                self.isProcessing = false;
            })
            .catch((err) => {
                self.toastr.error('Failed to export', { preventOpenDuplicates: true });
                console.log('Error while writing buffer', err);
                self.isProcessing = false;
            });
    }
}

export default angular.module('cameraViewerApp.siteNotes')
    .component('siteNotes', {
        template: require('./siteNotes.html'),
        controller: SiteNotesComponent,
        controllerAs: '$ctrl',
    })
    .name;
