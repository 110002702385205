import _ from 'lodash-es';
import angular from 'angular';

export default class AccountModalController {
    $uibModalInstance;
    $uibModal;
    Auth;
    $http;
    $state;
    modalInstance;

    /* @ngInject */
    constructor($uibModalInstance, Auth, $state, $uibModal, $stateParams, $http, $scope, $timeout, moment, toastr, apiKey, edit) {
        this.$stateParams = $stateParams;
        this.Auth = Auth;
        this.edit = edit;
        this.$state = $state;
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
        this.apiKey = apiKey;
        this.moment = moment;
        this.toastr = toastr;
        this.$http = $http;
    }

    $onInit() {
        const self = this;
        self.accounts = [];
        self.oldAccount = self.apiKey.accounts[0]?.accountId;

        self.Auth.getCurrentUserSync().accounts.forEach((acc) => {
            const ind = _.findIndex(self.apiKey.accounts, (o) => o.accountId == acc.accountId);
            if (ind == -1) {
                self.accounts.push({ accountId: acc.accountId, name: acc.name });
            }
        });
    }
    hexToBase64(str) {
        return btoa(String.fromCharCode.apply(
            null,
            str.replace(/\r|\n/g, '').replace(/([\da-fA-F]{2}) ?/g, '0x$1 ').replace(/ +$/, '').split(' '),
        ));
    }

    copyKey(key) {
        const self = this;
        const text_to_share = key;

        // create temp element
        const copyElement = document.createElement('span');
        copyElement.appendChild(document.createTextNode(text_to_share));
        copyElement.id = 'tempCopyToClipboard';
        angular.element(document.body.append(copyElement));

        // select the text
        const range = document.createRange();
        range.selectNode(copyElement);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);

        // copy & cleanup
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        copyElement.remove();
    }

    doLog() {
        // console.log(this.schedule.rruleSet.between(self.moment().subtract(2,'days').toDate(),self.moment().add(7,'days').toDate()));
        console.log(this);
    }

    // Setup all the models for display
    // Modal accept: send resulting object back to promise in parent controller
    ok() {
        const self = this;
        if (self.edit) {
        // TODO: Do the save...
            const headers = { 'x-js-assume-account': self.oldAccount };
            self.$http.patch(`/api/apiKeys/${self.apiKey._id}`, self.apiKey, { headers }).then((response) => {
                self.$uibModalInstance.close();
            });
        } else {
            self.$http.post('/api/apiKeys', self.apiKey).then((response) => {
                self.$uibModalInstance.close();
            });
        }
    }

    // Modal cancelled: either cancel pressed or clicked off screen
    cancel() {
        this.$uibModalInstance.dismiss('Cancel pressed');
    }

    ensureNoDups() {
        const self = this;
        self.accounts = _.uniqBy(self.accounts, (o) => o.accountId);
    }

    removeAcc(item) {
        const self = this;
        self.accounts.push(item);
    }
    selectAcc(item) {
        const self = this;
        self.accounts.splice(self.accounts.indexOf(item), 1);
    }
}
