/**
 * Tests if a specific version string is greater or equal to a minimum firmware version string
 *
 * @param evalVersion the version string to be tested in format `'0.1.2.3'`
 * @param minVersion the minimum version string required format `'0.1.2.3'`
 * @returns true if evalVersion is greater or equal to minVersion
 */
function checkVersionNumber(evalVersion, minVersion) {
    const getVerNums = function (version) {
        try {
            const versionResult = [];
            const regExp = /([0-9]+).([0-9]+).([0-9]+).([0-9]+)/;
            const result = version.match(regExp);
            if (!result?.length) return [0, 0, 0, 0];
            for (let i = 1; i <= 4; i++) {
                versionResult.push(parseInt(result[i]));
            }
            return versionResult;
        } catch (e) {
            // console.log('Utility function checkVersionNumber failed in getVerNums!');
        }
        return [0, 0, 0, 0];
    };

    if (!evalVersion) {
        // console.log('Utility function checkVersionNumber failed: No evalVersion');
        return false;
    }

    if (!minVersion) {
        // console.log('Utility function checkVersionNumber failed: No minVersion');
        return false;
    }

    const evalVersionNums = getVerNums(evalVersion);
    const minVersionNums = getVerNums(minVersion);

    if (evalVersionNums.every((i) => i == 0) || minVersionNums.every((i) => i == 0)) return false;

    for (let i = 0; i < 4; i++) {
        if (evalVersionNums[i] > minVersionNums[i]) {
            return true;
        }
        if (evalVersionNums[i] < minVersionNums[i]) {
            return false;
        }
    }

    return true;
}

export default { checkVersionNumber };

export { checkVersionNumber };
