import angular from 'angular';

export default angular.module('cameraViewerApp.directives', [])
    .name;

// require('./clockpicker/clockpicker.directive');
// require('./clockpicker/smartadmin-clockpicker');
// require('./grow/grow.directive');
// require('./grow-button/grow-button.directive');
// require('./material-button/material-button.directive');
require('./movable/movable.directive.js');
// require('./myMap/my-map.directive');
require('./myUiSelect/my-ui-select.directive');
// require('./number-spinner/number-spinner.directive');
require('./phone-input/phone-input.directive');
require('./resizable/resizable.directive.js');
require('./valid-email/valid-email.directive');
require('./wizard/app-wizard.directive');
require('./horizontal-scroll/horizontal-scroll.directive');
require('./overflow-tooltip/overflow-tooltip.directive');
require('./time-parser/time-parser.directive');
require('./percentage-formatter/percentage-formatter.directive');
require('./to-minutes/to-minutes.directive');
require('./image-fallback/image-fallback.directive');
