import _ from 'lodash';
import angular from 'angular';

const defaultPlaceholderPath = './assets/images/placeholders/noPhoto.jpg';

angular.module('cameraViewerApp.directives')
    .directive('fallbackSrc', () => ({
        link: function postLink(scope, element, attrs) {
            if (_.isEmpty(attrs.ngSrc)) {
                element.attr('src', attrs.fallbackSrc || defaultPlaceholderPath);
            }
            element.bind('error', () => {
                element.attr('src', attrs.fallbackSrc || defaultPlaceholderPath);
            });
        },
    }));
