export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.event', {
            url: '/events',
            views: {
                'content@main': {
                    template: '<events></events>',
                },
            },
            authenticate: 'Events',
            data: {
                title: 'Events',
            },
        });
}
