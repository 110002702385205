import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import routing from './main.routes';

export class MainController {
    $http;
    socket;

    /* @ngInject */
    constructor($http, $scope, socket, Auth, unitService, $uibModal, appConfig) {
        this.$http = $http;
        this.socket = socket;
        this.Auth = Auth;
        this.unitService = unitService;
        this.$uibModal = $uibModal;
        this.appConfig = appConfig;
    }

    $onInit() {
        const self = this;
        self.Auth.getCurrentUser()
            .then((user) => {
                self.Auth.refreshToken();
                self.checkConsent(user);
                if (!user.noIdle) {
                    self.Auth.startIdle(user.idleDuration, user.tokenTTL / 2);
                }
            });
        self.unitService.init();
    }

    checkConsent(user) {
        const self = this;
        const appConfig = self.appConfig.default;
        const privacyDate = appConfig.policyUpdated.privacy;
        const termsDate = appConfig.policyUpdated.terms;
        const disclaimerDate = appConfig.policyUpdated.disclaimer;

        if (user.consent && user.consent.privacy && user.consent.terms
            && new Date(user.consent.disclaimer) > disclaimerDate
            && new Date(user.consent.privacy) > privacyDate
            && new Date(user.consent.terms) > termsDate
        ) {
            // NOP
        } else {
            // Open privacy modal
            self.$uibModal.open({
                component: 'consent',
                backdrop: 'static',
                // size:'lg',
                keyboard: false,
                resolve: {
                    user,
                },
            });
        }
    }
}

export default angular.module('cameraviewerApp.main', [uiRouter])
    .config(routing)
    .component('main', {
        template: require('./main.html'),
        controller: MainController,
        controllerAs: '$ctrl',
    })
    .name;
