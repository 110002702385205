import { fabric } from 'fabric';
import uiBootstrap from 'angular-ui-bootstrap';

export default class OptionsController {
    $state;
    $http;
    $scope;
    $uibModal;
    $uibModalInstance;
    plate;
    moment;
    more;
    canvas;
    canvasContext;
    toastr;
    otherSightings;
    Auth;
    canEdit;
    allTags;

    // eslint-disable-next-line spaced-comment
    /*@ngInject*/

    constructor($uibModalInstance, moment, Auth, appConfig, toastr, $http, $state, $scope, $uibModal, plate) {
        this.$uibModalInstance = $uibModalInstance;
        this.$state = $state;
        this.moment = moment;
        this.toastr = toastr;
        this.canEdit = false;
        this.Auth = Auth;
        this.$http = $http;
        this.$scope = $scope;
        this.otherSightings = [];
        this.more = true;
        this.$uibModal = $uibModal;
        this.plate = plate;
        this.countryList = appConfig.default.countryList;
    }

    doLog() {
        console.log('DEBUG:', this);
    }

    $onInit() {
        const self = this;
        // console.log("OPENING:", self.plate);
        self.canEdit = self.Auth.hasPrivilegeSync('secuvue.plate.updateWanted');
        self.$http.get('api/plates/findTags')
            .then((res) => {
                self.allTags = res.data;
                self.drawCanvas();
            });

        if (!Object.hasOwnProperty.call(self.plate, 'ignore')) {
            self.plate.ignore = false;
        }

        self.$http.get(`api/snapshots/snapPlates/${self.plate.plate}`, {
            params: {
                timestamp: +self.moment()
                    .utc(),
            },
        })
            .then((response) => {
                if (response.data.length > 0) {
                    if (response.data.length < 4) {
                        self.more = false;
                    }
                    // console.log("Response was returned:", response.data);
                    self.otherSightings = response.data;
                    return self.otherSightings;
                }
                self.more = false;
                return null;
            });
    }

    metaQuery() {
        const self = this;
        if (self.Auth.hasPrivilegeSync(['secuvue.plate.metagrate'])) {
            const params = {
                plateNum: self.plate.plate,
            };
            self.$http.post('/api/plates/metagrate', params)
                .then((response) => {
                    if (response.data) {
                        self.plate.metagratedUUID = response.data.uuid;
                        self.plate.metagratedFlagged = response.data.status === 'Y';
                    }
                })
                .catch((err) => {
                    console.log('VOI Request error occurred:', err);
                });
        }
    }

    drawCanvas() {
        const self = this;
        if (!self.canvas) {
            self.canvas = new fabric.Canvas('snapshotCanvas', { stopContextMenu: true, enableRetinaScaling: false });
        }
        self.canvas.clear();
        self.canvas.containerClass = 'snapshot-wrapper';
        fabric.Image.fromURL(self.plate.data, (image) => {
            image.setOptions({
                left: 0,
                top: 0,
                opacity: 1,
                width: self.plate.width,
                height: self.plate.height,
            });
            self.canvas.setBackgroundImage(image, self.canvas.renderAll.bind(self.canvas), {
                originX: 'left',
                originY: 'top',
            });
            self.canvas.setWidth(image.width, { backstoreOnly: true });
            self.canvas.setHeight(image.height, { backstoreOnly: true });
            self.canvas.setWidth('100%', { cssOnly: true });
            self.canvas.setHeight('auto', { cssOnly: true });
            self.canvas.selection = false;

            const rect = new fabric.Rect({
                fill: 'rgba(0,0,0,0)',
                width: self.plate.boundingBox.Width * self.plate.width,
                height: self.plate.boundingBox.Height * self.plate.height,
                left: self.plate.boundingBox.Left * self.plate.width,
                top: self.plate.boundingBox.Top * self.plate.height,
                stroke: 'rgba(0,255,0, 0.5)',
                selectable: false,
                strokeWidth: 4,
            });
            self.canvas.add(rect);
            rect.moveTo(30);
            rect.bringToFront();
            self.canvas.renderAll();
        }, { crossOrigin: 'anonymous' });
    }

    close() {
        const self = this;
        self.$uibModalInstance.dismiss();
    }

    openSnapshot(snap) {
        const self = this;
        self.$uibModalInstance.close({ snap });
    }

    ignore() {
        const self = this;
        self.plate.ignore = !self.plate.ignore;
        self.$uibModalInstance.close({ plate: self.plate });
    }

    update() {
        const self = this;
        self.$uibModalInstance.close({ plate: self.plate });
    }

    delete() {
        const self = this;
        const r = confirm('Are you sure you want to delete?');
        if (r === true) {
            self.$uibModalInstance.close({ deleting: self.plate.plate });
        }
    }

    loadMore() {
        const self = this;
        // console.log("THIS IS OTHER SIGHTINGS: ", self.otherSightings);
        self.$http.get(`api/snapshots/snapPlates/${self.plate.plate}`, {
            params: {
                timestamp: +self.otherSightings[self.otherSightings.length - 1].timestamp,
            },
        })
            .then((response) => {
                // console.log("RESPONSE: ", response.data);
                if (response.data.length > 0) {
                    self.otherSightings = self.otherSightings.concat(response.data);
                }
                if (response.data.length < 4) {
                    self.toastr.warning('There are no more photos to be loaded', {
                        preventOpenDuplicates: true,
                    });
                    self.more = false;
                }
            });
    }

    removeTags(tags) {
        const label = ' (Create new tag)';
        tags.forEach((tag, index) => {
            if (tag.slice(-label.length) === label) {
                tags[index] = tag.slice(0, -label.length);
            }
        });
    }
}
