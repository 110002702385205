import _ from 'lodash';
import angular from 'angular';
import exceljs from 'exceljs';
import FileSaver from 'file-saver';

export class MaintenanceTimelineComponent {
    $http;
    $scope;
    $state;
    availableSites = [];
    moment;
    self;
    heatmapsOpen = {};
    NgTableParams;
    toastr;
    $sce;
    quickReportHour = ['Today', 'Last 7 Days', 'Last 31 Days', 'This Week', 'This Month'];

    /* @ngInject */
    constructor(
        $state,
        $window,
        $document,
        $timeout,
        $sce,
        NgTableParams,
        $rootScope,
        moment,
        $scope,
        $http,
        socket,
        Auth,
        toastr,
        $uibModal
    ) {
        this.$state = $state;
        this.$window = $window;
        this.filterResults = false;
        this.$document = $document;
        this.$timeout = $timeout;
        this.$sce = $sce;
        this.NgTableParams = NgTableParams;
        this.$rootScope = $rootScope;
        this.moment = moment;
        this.timezoneOffset = new Date().getTimezoneOffset();
        this.$scope = $scope;
        this.$http = $http;
        this.socket = socket;
        this.Auth = Auth;
        this.toastr = toastr;
        this.$uibModal = $uibModal;

        this.filterTypes = {};
        this.filterEntities = [];
        this.filterOptions = [];
        this.filterEventOptions = [];
        this.filterEvents = [
            {
                id: 'storageDown',
                alias: 'Storage Down',
                type: 'Unit State',
            },
            {
                id: 'storageRestored',
                alias: 'Storage Restored',
                type: 'Unit State',
            },
            {
                id: 'nocomms',
                alias: 'No Comms',
                type: 'Unit State',
            },
            {
                id: 'comms',
                alias: 'Comms',
                type: 'Zone State',
            },
            {
                id: 'auth',
                alias: 'Auth',
                type: 'Zone State',
            },
            {
                id: 'unknown',
                alias: 'Unknown',
                type: 'Zone State',
            },
            {
                id: 'commsRestored',
                alias: 'Comms Restored',
                type: 'Unit State',
            },
            {
                id: 'live',
                alias: 'Live',
                type: 'Zone State',
            },
            {
                id: 'configChange',
                alias: 'Configuration Changed',
                type: 'Event',
            },
            {
                id: 'softwareVersionChange',
                alias: 'Software Version Changed',
                type: 'Event',
            },
            {
                id: 'boot',
                alias: 'Boot',
                type: 'Event',
            },
        ];

        this.currentSite = undefined;
        this.metadataTemplates = [];
        this.regionOptions = [];
        this.selectedRegion = undefined;

        this.showCharts = true;

        this.snapFiltered = false;
        this.frFiltered = false;
        this.lprFiltered = false;

        this.summaryTableData = [];
        this.summaryTableOpen = false;
        this.summaryQueried = false;
        this.isColumnsCollapsed = true;

        this.showQuery = false;

        this.charts = {};

        this.resolutionOptions = [
            { alias: 'Hourly', type: 'hour' },
            { alias: 'Daily', type: 'day' },
            { alias: 'Monthly', type: 'month' },
        ];

        this.resInd = 2;
        this.selectedRes = this.resolutionOptions[this.resInd];
        this.chosenResolution = this.resolutionOptions[this.resInd].type;
        this.formattedRes = this.resolutionOptions[this.resInd].alias;

        this.typeInd = 2;
        this.typeOptions = [
            { alias: 'Account', type: 'account' },
            { alias: 'Site', type: 'site' },
            { alias: 'Zone', type: 'zone' },
        ];
        this.grouping = this.typeOptions[this.typeInd].type;
        this.groupingSelect = this.grouping;

        this.endDate = moment().endOf('day').toDate();

        this.endFormat = this.moment(this.endDate).format('YYYY-MM-DD');

        this.startDate = moment().startOf('day').toDate();

        this.startFormat = this.moment(this.startDate).format('YYYY-MM-DD HH:mm');
    }

    $onInit() {
        const self = this;

        self.getMetadataFields();
        self.getSites();

        this.selectedColumns = ['Timestamp', 'Type', 'Alias', 'State'];

        this.cols = [
            {
                title: 'Timestamp',
                field: 'timestamp',
                show: true,
                sortable: 'timestamp',
                getValue: this.handleDisplay.bind(this),
            },
            {
                title: 'Type',
                field: 'type',
                show: true,
                sortable: false,
                getValue: this.handleDisplay.bind(this),
            },
            {
                title: 'Alias',
                field: 'alias',
                show: true,
                sortable: false,
                getValue: this.handleDisplay.bind(this),
            },
            {
                title: 'State',
                field: 'state',
                show: true,
                sortable: false,
                getValue: this.handleDisplay.bind(this),
            },
            {
                title: 'Event',
                field: 'event',
                show: true,
                sortable: false,
                getValue: this.handleDisplay.bind(this),
            },
        ];

        this.tableParams = new this.NgTableParams(
            {
                page: 1, // start with first page
                count: 10, // count per page
                sorting: {
                    timestamp: 'desc', // initial sorting
                },
            },
            {
                total: 0,
                getData(params) {
                    let order;
                    if (params && params.sorting) {
                        order = params.sorting();
                        const queryParams = {
                            site: self.currentSite ? self.currentSite._id : undefined,
                            filter: self.filter && self.filter.length ? self.filter : undefined,
                            skip: (params.page() - 1) * params.count(),
                            limit: params.count(),
                            by: Object.keys(order)[0],
                            order: order[Object.keys(order)[0]],
                            allAccounts: self.allAccounts,
                        };
                        const filterUnits = [];
                        const filterZones = [];
                        const filterEvents = [];
                        const filterStates = [];
                        if (self.filterResults) {
                            self.filterEntities.forEach((ent) => {
                                if (self.filterTypes[ent] === 'unit') {
                                    filterUnits.push(ent);
                                } else if (self.filterTypes[ent] === 'zone') {
                                    filterZones.push(ent);
                                }
                            });
                            self.filterEventOptions.forEach((ev) => {
                                if (
                                    ['configChange', 'softwareVersionChange', 'boot'].includes(ev)
                                ) {
                                    filterEvents.push(ev);
                                } else {
                                    filterStates.push(ev);
                                }
                            });

                            if (filterUnits.length > 0) {
                                queryParams.units = filterUnits;
                            }
                            if (filterZones.length > 0) {
                                queryParams.zones = filterZones;
                            }
                            if (filterEvents.length > 0) {
                                queryParams.events = filterEvents;
                            }
                            if (filterStates.length > 0) {
                                queryParams.states = filterStates;
                            }

                            queryParams.startDate = self.startDate.getTime();
                            queryParams.endDate = self.endDate.getTime();
                        }

                        return self.$http
                            .get('/api/maintenance/', { params: queryParams })
                            .then((response) => {
                                self.events = response.data.data;
                                self.total = response.data.total;
                                params.total(response.data.total);
                                return self.events;
                            })
                            .catch((err) => {
                                console.error('Error caught when getting data for units: ', err);
                            });
                    }
                },
            }
        );
    }

    doLog() {
        console.log(this);
    }

    getMetadataFields() {
        const self = this;
        self.$http
            .get('/api/accounts/metadataTemplates')
            .then((res) => {
                if (res.data) {
                    self.metadataTemplates = res.data;
                    const regionTemplate = self.metadataTemplates?.find(
                        (option) => option.key === 'Region'
                    );
                    if (regionTemplate) {
                        self.regionOptions = regionTemplate.options;
                        self.regionOptions.unshift('All Regions');
                        self.regionOptions.push('No Region');
                        self.selectedRegion = self.regionOptions[0];
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    getSites() {
        const self = this;
        self.$http
            .get('/api/sites/lite')
            .then((response) => {
                if (response.data) {
                    self.availableSites = response.data;
                    if (self.selectedRegion) {
                        if (self.selectedRegion !== 'All Regions') {
                            const filterTarget = self.selectedRegion === 'No Region' ? undefined : self.selectedRegion;
                            self.availableSites = _.filter(
                                self.availableSites, (o) => o.region === filterTarget
                            );
                        }
                    }
                }
            })
            .catch((err) => {
                console.error('Error finding sites', err);
            });
    }

    handleDisplay(self, col, maintenanceEvent) {
        let html = '';
        switch (col.field) {
            case 'alias':
                if (maintenanceEvent.zoneAlias) {
                    return maintenanceEvent.zoneAlias;
                }
                if (maintenanceEvent.unitAlias) {
                    return `${maintenanceEvent.unitAlias} (${maintenanceEvent.serial ? maintenanceEvent.serial : 'No Serial'})`;
                }
                return '[Alias]';

            case 'timestamp':
                html += `${self.moment(maintenanceEvent.timestamp).format('YYYY-MM-DD HH:mm:ss')}`;
                return self.$sce.trustAsHtml(html);

            case 'type':
                if (maintenanceEvent.zone) {
                    return 'Zone';
                }
                if (maintenanceEvent.unit) {
                    return 'Unit';
                }
                return '[Type]';

            case 'state':
                if (maintenanceEvent.state) {
                    let returnVar =
                        maintenanceEvent.state.charAt(0).toUpperCase() +
                        maintenanceEvent.state.slice(1);
                    if (maintenanceEvent.state === 'commsRestored') {
                        returnVar = 'Comms Restored';
                    }
                    if (maintenanceEvent.state === 'storageRestored') {
                        returnVar = 'Storage Restored';
                    }
                    if (maintenanceEvent.state === 'storageDown') {
                        returnVar = 'Storage Down';
                    }
                    return returnVar;
                }
                return 'N/A';

            case 'event':
                if (maintenanceEvent.event) {
                    return (
                        maintenanceEvent.event.charAt(0).toUpperCase() +
                        maintenanceEvent.event.slice(1)
                    );
                }
                if (maintenanceEvent.state && maintenanceEvent.oldState) {
                    let state =
                        maintenanceEvent.state.charAt(0).toUpperCase() +
                        maintenanceEvent.state.slice(1);
                    let oldState =
                        maintenanceEvent.oldState.charAt(0).toUpperCase() +
                        maintenanceEvent.oldState.slice(1);
                    if (maintenanceEvent.state === 'commsRestored') {
                        state = 'Comms Restored';
                    }
                    if (maintenanceEvent.oldState === 'commsRestored') {
                        oldState = 'Comms Restored';
                    }
                    return `${oldState} -> ${state}`;
                }
                return 'N/A';

            default:
                // The default cases are those who adopt boolean values
                // "internet || chreosis || ethernet || vpn || usb || wan"
                return maintenanceEvent[col.field];
        }
    }

    siteSelected($item) {
        const self = this;
        self.filterTypes = {};
        self.filterOptions = [];
        this.$http.get(`/api/sites/${$item._id}`).then((site) => {
            self.currentSite = site.data;
            self.currentSite.units.forEach((unit) => {
                self.filterTypes[unit._id] = 'unit';
                self.filterOptions.push({ id: unit._id, alias: unit.alias, type: 'Units' });
            });
            self.currentSite.zones.forEach((zone) => {
                self.filterTypes[zone._id] = 'zone';
                self.filterOptions.push({ id: zone._id, alias: zone.alias, type: 'Zones' });
            });
            self.tableParams.reload();
        });
    }

    dateFormat() {
        const self = this;
        if (self.chosenResolution === 'Monthly') {
            return 'yyyy/MM';
        }
        return 'yyyy/MM/dd';
    }

    openDateTimePicker(index, picker, $event) {
        $event.preventDefault();
        $event.stopPropagation();
        this[picker] = !this[picker];
    }

    clearFilters() {
        const self = this;
        self.filterEntities = [];
        self.filterEventOptions = [];
        self.tableParams.reload();
    }

    quickPick(option) {
        const self = this;
        const to = this.moment();
        const from = this.moment();
        switch (option) {
            case 'Today':
                self.startDate = from.startOf('day').toDate();
                self.endDate = to.endOf('day').toDate();
                break;
            case 'Last 7 Days':
                self.startDate = from.subtract(7, 'days').startOf('hour').toDate();
                self.endDate = to.endOf('day').toDate();
                break;
            case 'Last 31 Days':
                self.startDate = from.subtract(31, 'days').startOf('hour').toDate();
                self.endDate = to.endOf('day').toDate();
                break;
            // case "Today":
            // self.endDate = to.add(1,"days").endOf('day')
            // .toDate();
            // self.startDate = from.startOf('day').toDate();
            // break;
            case 'This Week':
                self.endDate = to.add(1, 'weeks').endOf('week').toDate();
                self.startDate = from.startOf('week').toDate();
                break;
            case 'Last Month':
                self.endDate = to.endOf('month').toDate();
                self.startDate = from.subtract(1, 'months').startOf('month').toDate();
                break;
            case 'This Month':
                self.endDate = to.endOf('month').toDate();
                self.startDate = from.startOf('month').toDate();
                break;
            default:
        }
    }

    exportCSV() {
        const self = this;
        if (!self.filterResults) {
            self.toastr.warn('Please filter results according to time before exporting to csv', {
                preventOpenDuplicates: true,
            });
        }
        const numEvents = 100;

        async function recurseFunc(returnArr, skip) {
            if (skip >= 100) {
                self.toastr.warning(
                    'Please choose a more selective filter',
                    'Number of entries too large (>10 000)',
                    {
                        preventOpenDuplicates: true,
                    }
                );
                throw new Error('Too many iterations');
            }
            const order = self.tableParams.sorting();
            const queryParams = {
                site: self.currentSite ? self.currentSite._id : undefined,
                filter: self.filter && self.filter.length ? self.filter : undefined,
                // Get numEvents results at a time
                limit: numEvents,
                // The skip variable acts as the placeholder for the params.page(),starting at 0, and incrementing
                skip: skip * numEvents,
                by: Object.keys(order)[0],
                order: order[Object.keys(order)[0]],
            };
            if (self.filterResults) {
                const filterUnits = [];
                const filterZones = [];
                const filterEvents = [];
                const filterStates = [];
                self.filterEntities.forEach((ent) => {
                    if (self.filterTypes[ent] === 'unit') {
                        filterUnits.push(ent);
                    } else if (self.filterTypes[ent] === 'zone') {
                        filterZones.push(ent);
                    }
                });
                self.filterEventOptions.forEach((ev) => {
                    if (['configChange', 'softwareVersionChange', 'boot'].includes(ev)) {
                        filterEvents.push(ev);
                    } else {
                        filterStates.push(ev);
                    }
                });

                if (filterUnits.length > 0) {
                    queryParams.units = filterUnits;
                }
                if (filterZones.length > 0) {
                    queryParams.zones = filterZones;
                }
                if (filterEvents.length > 0) {
                    queryParams.events = filterEvents;
                }
                if (filterStates.length > 0) {
                    queryParams.states = filterStates;
                }

                queryParams.startDate = self.startDate.getTime();
                queryParams.endDate = self.endDate.getTime();
            }
            const response = await self.$http.get('/api/maintenance/', { params: queryParams });
            if (response.data && response.data.data && response.data.data.length < numEvents) {
                returnArr = returnArr.concat(response.data.data);
                return returnArr;
            }
            if (response.data) {
                returnArr = returnArr.concat(response.data.data);
                skip++;
                return recurseFunc(returnArr, skip);
            }
            throw new Error('No response.data');
        }

        return recurseFunc([], 0)
            .then((data) => {
                self.saveWB(data);
            })
            .catch((err) => {
                console.log('Error with async function: ', err);
            });
    }

    saveWB(data) {
        const self = this;
        const workbook = new exceljs.Workbook();
        const sheet = workbook.addWorksheet('Maintenance Timeline');
        sheet.pageSetup.horizontalCentered = true;
        sheet.pageSetup.verticalCentered = true;

        let tsStart;
        if (self.startDate) {
            tsStart = self.moment(self.startDate).format('YYYY-MM-DD');
        }
        let tsEnd;
        if (self.endDate) {
            tsEnd = self.moment(self.endDate).format('YYYY-MM-DD');
        }

        const acc = self.Auth.getCurrentAccountSync();
        sheet.addRow(['Date & Time:', `${self.moment().format('DD-MM-YYYY HH:mm:ss')}`]);
        sheet.addRow(['Site:', `${self.currentSite.alias}`]);
        sheet.addRow(['Account:', `${acc.name}`]);
        sheet.addRow([]);
        const headers = ['Timestamp', 'Type', 'Alias', 'State', 'Event'];
        sheet.addRow(['Time Range:', 'From:', `${tsStart}`, 'To:', `${tsEnd}`]);
        sheet.addRow([]);
        sheet.addRow(headers);

        data.forEach((ev) => {
            const arr = [];
            arr.push(self.moment(ev.timestamp).format('YYYY-MM-DD HH:mm:ss'));
            if (ev.zone) {
                arr.push('Zone');
            } else if (ev.unit) {
                arr.push('Unit');
            } else {
                arr.push('Site');
            }
            if (ev.unitAlias) {
                arr.push(ev.unitAlias);
            } else if (ev.zoneAlias) {
                arr.push(ev.zoneAlias);
            } else {
                arr.push(self.currentSite.alias);
            }
            if (ev.state) {
                arr.push(ev.state.charAt(0).toUpperCase() + ev.state.slice(1));
                const state = ev.state.charAt(0).toUpperCase() + ev.state.slice(1);
                const oldState = ev.oldState.charAt(0).toUpperCase() + ev.oldState.slice(1);
                arr.push(`${oldState} -> ${state}`);
            } else if (ev.event) {
                arr.push('N/A');
                arr.push(ev.event.charAt(0).toUpperCase() + ev.event.slice(1));
            }
            sheet.addRow(arr);
        });
        workbook.xlsx
            .writeBuffer()
            .then((buffer) => {
                FileSaver.saveAs(
                    new Blob([buffer]),
                    `${self.currentSite.alias}_${self.moment().format('DD_MM_YYYY_HH:mm:ss')}.xlsx`
                );
                self.toastr.success('Report Downloaded', { preventOpenDuplicates: true });
            })
            .catch((err) => {
                console.log('Error while writing buffer', err);
            });
    }
}

export default angular
    .module('cameraViewerApp.maintenanceTimeline')
    .component('maintenanceTimeline', {
        template: require('./maintenanceTimeline.html'),
        controller: MaintenanceTimelineComponent,
        controllerAs: '$ctrl',
    }).name;
