export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.userAdmin', {
            url: '/userAdmin',
            views: {
                'content@main': {
                    template: '<user-admin></user-admin>',
                },
            },
            authenticate: 'UserManagement',
            data: {
                title: 'User Admin',
            },
        });
}
