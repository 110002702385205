import _ from 'lodash';
import angular from 'angular';
import ChooseTemplateAccountsController from './choose-accounts/choose-accounts.component';
import RenameTemplateAccountsController from './rename-template/rename-template.component';

export class ZoneTemplateComponent {
    $http;
    $scope;
    $state;
    availableSites = [];
    moment;
    self;
    heatmapsOpen = {};
    NgTableParams;
    toastr;
    $sce;

    /* @ngInject */
    constructor($state, $window, $document, $timeout, $sce, NgTableParams, $rootScope, moment, $scope, $http, socket, Auth, toastr, $uibModal, $ngConfirm) {
        this.$state = $state;
        this.$window = $window;
        this.$document = $document;
        this.$timeout = $timeout;
        this.$sce = $sce;
        this.NgTableParams = NgTableParams;
        this.$rootScope = $rootScope;
        this.moment = moment;
        this.$scope = $scope;
        this.$http = $http;
        this.socket = socket;
        this.Auth = Auth;
        this.filter = '';
        this.toastr = toastr;
        this.$uibModal = $uibModal;
        this.$ngConfirm = $ngConfirm;
        this.availableTemplates = [];
        this.currentRooms = [];
    }

    $onDestroy() {
        const self = this;
        self.socket.unsyncUpdates('template');
        if (self.currentRooms.length > 0) {
            self.currentRooms.forEach((room) => {
                self.socket.leaveRoom(room);
            });
        }
    }

    $onInit() {
        const self = this;
        // self.initAvailableTemplates();
        self.Auth.getCurrentUser()
            .then((user) => {
                self.currentUser = user;
                // self.$http.get(`api/users/${this.currentUser._id}/queryUsers`)
                //     .then(response => {
                //         self.availableUsers = response.data;
                //     });
            });
        self.initTable();
    }

    initTable() {
        const self = this;
        self.selectedColumns = [];

        self.cols = [
            {
                title: 'Name',
                field: 'name',
                show: true,
                sortable: 'name',
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Description',
                field: 'description',
                show: true,
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Accounts',
                field: 'accounts',
                show: true,
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Add/Remove Accounts',
                field: 'editAccounts',
                show: true,
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Delete Template',
                field: 'deleteTemplate',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
        ];

        self.colValues = {};

        _.forEach(self.cols, (col) => {
            if (col.show) {
                self.selectedColumns.push(col.title);
            }
            self.colValues[col.field] = '';
        });

        self.tableParams = new self.NgTableParams(
            {
                page: 1, // start with first page
                count: 10, // count per page
                sorting: {
                    name: 'asc', // initial sorting
                },
            },
            {
                total: 0,
                getData(params) {
                    let order;
                    if (params && params.sorting) {
                        order = params.sorting();
                        const filterParams = JSON.stringify([ { field: 'allAccounts', type: 'boolean', value: true, operator: '$ne' } ]);
                        const dataPromise = self.$http.get('/api/templates', {
                            params: {
                                filter: self.filter.length ? self.filter : undefined,
                                skip: (params.page() - 1) * params.count(),
                                limit: params.count(),
                                by: Object.keys(order)[0],
                                order: order[Object.keys(order)[0]],
                                params: filterParams
                            },
                        })
                        const countPromise = self.$http.get('/api/templates/count', {
                            params: {
                                filter: self.filter.length ? self.filter : undefined,
                                params: filterParams
                            },
                        })
                        return Promise.all([dataPromise, countPromise]).then((response) => {
                                self.availableTemplates = response[0].data;
                                self.total = response[1].data;
                                params.total(self.total);
                                return self.availableTemplates;
                            })
                            .catch((err) => {
                                console.error('Error caught when getting data for templates:', err);
                            });
                    }
                    return null;
                },
            },
        );
        self.tableParams.reload();
    }

    onColumnSelected($item, $model) {
        $item.show = true;
    }

    onColumnRemoved($item, $model) {
        $item.show = false;
    }

    renameTemplate(template) {
        const self = this;
        console.log('This is the template:', template);
        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('./rename-template/rename-template.html'),
            controller: RenameTemplateAccountsController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
                template() {
                    return template;
                },
            },
        })
            .result
            .then((result) => {
                if (result && result.save) {
                    const headers = { 'x-js-assume-account': template.accounts[0].accountId };
                    self.$http.patch(`/api/templates/${template._id}`, result.save, { headers })
                        .then((response) => {
                            if (response && response.status === 200) {
                                self.toastr.success('Template Updated');
                                self.tableParams.reload();
                            }
                        })
                        .catch((err) => {
                            console.error('Error updating template:', err);
                        });
                }
            });
    }

    handleDisplay(self, col, template) {
        let html = '';
        switch (col.field) {
        case 'name':
            html = `<a ng-click="$ctrl.renameTemplate(template)">${template.name}</a>`;
            return self.$sce.trustAsHtml(html);

        case 'description':
            return template[col.field];

        case 'accounts':
            html += `<span class="label label-info" style='margin-right:0.2em;margin-left:0.2em;text-align:center;' ng-repeat="account in template.accounts">
                         {{account.name}}
                     </span>`;
            return self.$sce.trustAsHtml(html);

        case 'editAccounts':
            html += `<span class="cursorPointer" ng-click="$ctrl.accountManagement(template)">
                         <i class="fa fa-bars"></i>
                     </span>`;
            return self.$sce.trustAsHtml(html);

        case 'deleteTemplate':
            html += `<span class="cursorPointer" ng-click="$ctrl.deleteTemplate(template)">
                         <i class="fa fa-trash"></i>
                     </span>`;
            return self.$sce.trustAsHtml(html);

        default:
            // The default cases are those who adopt boolean values
            // "internet || chreosis || ethernet || vpn || usb || wan"
            return '';
        }
    }

    initAvailableTemplates() {
        const self = this;
        self.$http.get('/api/templates')
            .then((response) => {
                self.availableTemplates = response.data;
            }, (err) => {
                if (err.status !== 401 && err.status !== 403) console.error(err);
            });
    }

    doLog() {
        console.log(this);
    }

    accountManagement(template) {
        const self = this;

        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('./choose-accounts/choose-accounts.html'),
            controller: ChooseTemplateAccountsController,
            controllerAs: '$ctrl',
            size: 'md',
            resolve: {
                template() {
                    return template;
                },
                currentAccount() {
                    return self.Auth.getCurrentAccountSync();
                },
                currentUser() {
                    return self.currentUser;
                },
            },
        })
            .result
            .then((result) => {
                const accounts = _.map(result.accounts, 'accountId');
                self.$http.post(`api/templates/${template._id}/manageAccounts`, { accounts }).then(() => {
                    self.tableParams.reload();
                    self.toastr.success(`Successfully Updated Accounts for ${template.name}`);
                });
            });
    }

    deleteTemplate(template) {
        const self = this;
        self.$ngConfirm({
            title: 'Delete template?',
            escapeKey: false,
            backgroundDismiss: false,
            scope: self.$scope,
            content:
                `This action will delete the selected template.
                Do you wish to proceed?`,
            buttons: {
                enter: {
                    text: 'Yes',
                    btnClass: 'btn-danger',
                    action(scope, button) {
                        self.$http.delete(`api/templates/${template._id}`)
                            .then(() => {
                                self.toastr.success('Template has been deleted successfully', { preventOpenDuplicates: true });
                                self.tableParams.reload();
                            });
                    },
                },
                close(scope, button) {},
            },
        });
    }
}

export default angular.module('cameraViewerApp.zoneTemplate')
    .component('zoneTemplate', {
        template: require('./zoneTemplate.html'),
        controller: ZoneTemplateComponent,
        controllerAs: '$ctrl',
    })
    .name;
