import * as _ from 'lodash-es';
import angular from 'angular';
import io from 'socket.io-client';

function Socket(Auth, $state) {
    'ngInject';

    // socket.io now autoconfigures its connection when we omit a connection url
    const socket = io('', {
        path: '/socket.io-client',
        reconnection: true,
        transports: ['websocket'],
        // reconnectionAttempts:10, //Never stop trying to reconnect connecting
        reconnectionDelay: 1000,
    });

    const listenerMap = new Map();

    const rooms = {};
    let reAuthDisconnect = false;
    let initialConnection = true;

    socket.on('error', (error) => {
        if (error.type === 'UnauthorizedError' || error.code == 'invalid_token') {
            // redirect user to login page perhaps?
            console.error("User's token has expired");
        } else {
            console.error('Unknown socket error: ', error);
        }
        if (Auth.isLoggedInSync() && socket.disconnected) {
            //Offline.trigger('down'); // TODO
        }
    });

    socket.on('disconnect', (reason) => {
        console.error('Socket Disconnected', reason);
        if (!reAuthDisconnect) {
            console.debug('Disconnected not reauth');
            if (Auth.isLoggedInSync()) {
                console.debug('Disconnected and signed in');
                //Offline.trigger('down');
            }
        }
    });

    socket.on('connect', () => {
        console.debug('Connected ', socket.connected);
        if (!initialConnection) {
            for (const roomName in rooms) {
                socket.emit('join', { room: roomName });
            }
        }
        if (initialConnection || reAuthDisconnect) {
            initialConnection = false;
            reAuthDisconnect = false;
        }
        //Offline.trigger('up');
    });

    return {
        socket,

        /**
         * Register listeners to sync an array with updates on a model
         *
         * Takes the array we want to sync, the model name that socket updates are sent from,
         * and an optional callback function after new items are updated.
         *
         * @param {String} modelName
         * @param {Array} array
         * @param {Function} cb
         * @param {Object} context
         * @param {Boolean} merge
         * @param {Boolean} append
         */
        syncUpdates(modelName, array, cb, context, merge, append) {
            // console.log('syncing', modelName);
            cb = cb || angular.noop;
            append = append !== false;
            // console.log("CHECKING THE CONTEXT: ", context);

            /**
             * Syncs item creation/updates on 'model:save'
             */
            const saveFunc = function (item) {
                // console.log("Socket Saving: ", item);
                const oldItem = _.find(array, {
                    _id: item._id,
                });
                const index = array.indexOf(oldItem);
                let event = 'created';

                if (merge === true && index >= 0) {
                    _.mergeWith(item, oldItem, (objValue, srcValue) => {
                        if (_.isArray(objValue)) {
                            return _.unionBy(objValue, srcValue, 'accountId');
                        }
                    });
                }
                // replace oldItem if it exists
                // otherwise just add item to the collection
                if (oldItem) {
                    array.splice(index, 1, item);
                    event = 'updated';
                } else if (append) {
                    array.push(item);
                } else {
                    array.unshift(item);
                }

                cb(event, item, array);
            };
            socket.on(`${modelName}:save`, saveFunc);

            const removeFunc = function (item) {
                const oldItem = _.find(array, {
                    _id: item._id,
                });
                const index = array.indexOf(oldItem);
                let event = 'deleted';
                if (merge === true && index >= 0) {
                    _.mergeWith(item, oldItem, (objValue, srcValue) => {
                        if (_.isArray(objValue)) {
                            // console.log("Value", objValue, srcValue);
                            const tempIn = _.findIndex(
                                srcValue,
                                (o) => o.accountId === objValue[0].accountId
                            );
                            srcValue.splice(tempIn, 1);
                            return srcValue;
                        }
                    });
                    if (item.accounts && item.accounts.length > 0) {
                        event = 'updated';
                        if (oldItem) {
                            array.splice(index, 1, item);
                        }
                    } else {
                        _.remove(array, {
                            _id: item._id,
                        });
                    }
                } else {
                    _.remove(array, {
                        _id: item._id,
                    });
                }
                cb(event, item, array);
            };
            /**
             * Syncs removed items on 'model:remove'
             */
            socket.on(`${modelName}:remove`, removeFunc);

            if (typeof context !== 'undefined') {
                const listeners = listenerMap.get(context);
                if (listeners) {
                    socket.removeListener(`${modelName}:save`, listeners.save);
                    socket.removeListener(`${modelName}:remove`, listeners.remove);
                }
                listenerMap.set(context, { save: saveFunc, remove: removeFunc });
            }
        },

        /**
         * Removes listeners for a models updates on the socket
         *
         * @param modelName
         * @param context
         */
        unsyncUpdates(modelName, context) {
            // console.log('unsyncing', modelName);

            if (typeof context !== 'undefined') {
                const listeners = listenerMap.get(context);
                if (listeners) {
                    socket.removeListener(`${modelName}:save`, listeners.save);
                    socket.removeListener(`${modelName}:remove`, listeners.remove);
                    listenerMap.delete(context);
                    return;
                }
            }
            listenerMap.clear();
            socket.removeAllListeners(`${modelName}:save`);
            socket.removeAllListeners(`${modelName}:remove`);
        },

        reAuth() {
            console.debug('Socket.io ReAuth');
            reAuthDisconnect = true;
            const onclose = () => {
                console.debug('Reauth on close');
                socket.connect();
            };
            socket.once('disconnect', onclose);
            if (socket.connected) {
                console.debug('Reauth connected');
                socket.disconnect();
            } else {
                console.debug('Reauth was disconnected');
                socket.connect();
            }
        },

        /**
         * Joins a room
         *
         * @param roomName
         */
        joinRoom(roomName) {
            rooms[roomName] = rooms[roomName] ? rooms[roomName] + 1 : 1;
            socket.emit('join', { room: roomName });
            // console.log('JOINROOM', JSON.stringify(rooms));
        },

        leaveAll() {
            return new Promise((ful, rej) => {
                const roomArr = Object.keys(rooms);
                for (let iterator = 0; iterator < roomArr.length; iterator++) {
                    const count = rooms[roomArr[iterator]];
                    for (let i = 0; i < count; i++) {
                        socket.emit('leave', { room: roomArr[iterator] });
                    }
                }
                return ful(true);
            });
        },

        /**
         * Leaves a room
         *
         * @param roomName
         */
        leaveRoom(roomName) {
            rooms[roomName] = rooms[roomName] ? rooms[roomName] - 1 : 0;
            if (!rooms[roomName] || rooms[roomName] === 0) {
                socket.emit('leave', { room: roomName });
                Reflect.deleteProperty(rooms, roomName);
            }
            // console.log('leaveRoom', JSON.stringify(rooms));
        },
    };
}

export default angular.module('cameraViewerApp.socket', []).factory('socket', Socket).name;
