import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import angularMoment from 'angular-moment';
// import xlsx from 'xlsx';
import moment from 'moment';
import NgTable from 'ng-table/bundles/ng-table'; // TODO figure out why this is needed
import routes from './zoneOverview.routes';

export default angular.module('cameraViewerApp.zoneOverview', [uiRouter, 'ngTable',
    'angularMoment'])
    .config(routes)
    .name;

require('./zoneOverview.component');
