import uiBootstrap from 'angular-ui-bootstrap';
import { Dropzone } from 'dropzone';
import 'dropzone/dist/dropzone.css';
import 'ngdropzone';

Dropzone.autoDiscover = false;

export default class LookupController {
    $state;
    $http;
    Auth;
    $scope;
    searching;
    $uibModal;
    $uibModalInstance;
    allTags;
    lookupResults = [];
    bestMatch = [];
    reference = {};
    currentAccount;

    /* @ngInject */
    constructor($uibModalInstance, Auth, $http, $state, $scope, toastr, $uibModal) {
        this.$uibModalInstance = $uibModalInstance;
        this.$state = $state;
        this.searching = false;
        this.toastr = toastr;
        this.Auth = Auth;
        this.$http = $http;
        this.$scope = $scope;
        this.resultsFound = false;
        this.searchDone = false;
        this.waiting = false;
        this.$uibModal = $uibModal;
    }

    doLog() {
        const self = this;
        console.log('DEBUG: ', self);
    }

    $onInit() {
        const self = this;
        // this.bestMatch = testData.splice(0, 1);
        self.currentAccount = self.Auth.getCurrentAccountSync().accountId;

        self.dzCallbacks = {
            addedfile(file) {
                // console.log("ADDED FILE", file);
            },

            maxfilesexceeded(file) {
                self.dzMethods.removeFile(file);
                self.toastr.warning('Only one photo can be uploaded at a time', {
                    preventOpenDuplicates: true,
                });
            },

            sending(file, xhr, formData) {
                self.waiting = true;
                // console.log("SENDING");
                // console.log(formData);
                // console.log(self.reference);
                self.toastr.info('Searching, Please Wait..', {
                    preventOpenDuplicates: true,
                });
            },

            error(test, res) {
                console.log('ERROR: ', res);
            },

            removedfile(file) {
            },

            success(test, res) {
                // console.log("SUCCESS: ", res);
                self.searching = false;
                if (res.length > 0) {
                    self.toastr.success('Possible match found', {
                        preventOpenDuplicates: true,
                    });
                    self.resultsFound = true;
                    self.bestMatch = res.splice(0, 1)[0];
                    // console.log("BESTMATCH: ", self.bestMatch);
                    self.lookupResults = res;
                } else {
                    self.toastr.warning('No Result', {
                        preventOpenDuplicates: true,
                    });
                }
            },
        };

        self.dzOptions = {
            url: '/api/identities/lookup',
            maxFilesize: 5,
            maxFiles: 1,
            headers: {
                'X-JS-ACCOUNT': self.currentAccount,
            },
            autoProcessQueue: false,
            acceptedFiles: 'image/jpeg, image/jpg',
            addRemoveLinks: true,
            dictDefaultMessage: 'Click to add photo (1 max)',
            dictRemoveFile: 'Remove Photo',
            dictResponseError: 'Could not upload this photo',
        };
        self.dzMethods = {};
    }

    $postLink() {
        const self = this;
        const dz = self.dzMethods.getDropzone();
    }

    lookup() {
        const self = this;
        self.searching = true;
        if (self.Auth.hasPrivilegeSync(['secuvue.reference.lookup', 'secuvue.snapshot.index'])) {
            self.dzMethods.processQueue();
        }
    }

    // upload() {
    // let self = this;
    // if (self.hasPrivilege('secuvue.references.upload')) {
    // self.dzMethods.processQueue();
    // } else {
    // self.toastr.error('You do not have privileges for this action', {
    // preventOpenDuplicates: true
    // });
    // }
    // self.$uibModalInstance.dismiss();
    // }

    close() {
        const self = this;
        self.$uibModalInstance.dismiss();
    }

    openRef(face) {
        const self = this;
        self.$uibModalInstance.close({ face });
    }

    removeTags(tags) {
        const label = ' (create new tag)';
        tags.forEach((tag, index) => {
            if (tag.slice(-label.length) === label) {
                tags[index] = tag.slice(0, -label.length);
            }
        });
    }
}
