import _ from 'lodash';
import angular from 'angular';
import { commands } from './shellModal.commands';

export class ShellModalComponent {
    /* @ngInject */
    constructor($ngConfirm, $scope, toastr, $timeout, $uibModal, $http, moment, socket, Auth, $location, $anchorScroll) {
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.$ngConfirm = $ngConfirm;
        this.$scope = $scope;
        this.toastr = toastr;
        this.$timeout = $timeout;
        this.$uibModal = $uibModal;
        this.$http = $http;
        this.moment = moment;
        this.socket = socket;
        this.shellScript = '';
        this.commandList = commands;
        this.Auth = Auth;
        this.conversations = [];
        this.currentRooms = [];
    }

    doLog() {
        console.log('DEBUG: ', this);
    }

    $onDestroy() {
        const self = this;
        if (self.theUnitEventListener) {
            self.socket.socket.removeListener('unit:save', self.theUnitEventListener);
        }
        if (self.currentRooms.length > 0) {
            self.currentRooms.forEach((room) => {
                self.socket.leaveRoom(room);
            });
        }
    }

    $onInit() {
        const self = this;
        if (self.resolve && self.resolve.unit) {
            self.unit = self.resolve.unit;
        }

        self.socket.socket.on('shellresponse', (item) => {
            if (item.unit === self.unit._id) {
                if (item.output && item.output.length > 1) {
                    self.conversations.push({
                        sender: 'Unit',
                        message: item.output.slice(1, item.output.length - 1),
                        type: 'stdout',
                    });
                } else if (item.error && item.error.length > 1) {
                    self.conversations.push({
                        sender: 'Unit',
                        message: item.error.slice(1, item.error.length - 1),
                        type: 'stderr',
                    });
                }
                self.$timeout(() => {
                    self.$anchorScroll('bottom');
                }, 0);
            }
        });
    }

    pasteCommand(command) {
        const self = this;

        self.shellScript = command.example;
        const input = document.getElementById('shellScriptInput');
        self.$timeout(() => {
            input.focus();
            if (command.varLength > 0) {
                input.setSelectionRange(command.command.length + 1, command.command.length + 1 + command.vars[0].length);
            }
        }, 0);
    }

    keyPress(key) {
        if (key.which === 13) {
            this.send();
        }
    }

    send() {
        const self = this;

        self.conversations.push({ sender: 'Server', message: self.shellScript });
        self.$http.post(`/api/units/${self.unit._id}/executeScript`, { msg: self.shellScript })
            .then((response) => {

            });
        // self.$timeout(()=>{
        // self.$anchorScroll("bottom");
        // },0);
    }

    onReceiptOfUnit(newUnit) {
        const self = this;
        const index = _.findIndex(self.units, (unit) => unit._id === newUnit._id);
        if (index !== -1) {
            if (newUnit.assigned) {
                self.units.splice(1, index);
            } else {
                self.units[index] = newUnit;
            }
        } else if (!newUnit.assigned) {
            self.units.push(newUnit);
        }
    }

    closeModal() {
        this.modalInstance.close();
    }
}

export default angular.module('cameraViewerApp.units')
    .component('shellModal', {
        template: require('./shellModal.html'),
        controller: ShellModalComponent,
        controllerAs: '$ctrl',
        bindings: {
            resolve: '<',
            modalInstance: '<',
        },
    })
    .name;
