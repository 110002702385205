// Base list of columns available in the overview
const baseColumns = [
    {
        title: 'Reference Photo',
        field: 'referenceShot',
        show: true,
    },
    {
        title: 'Alias',
        field: 'alias',
        show: true,
    },
    {
        title: 'Active',
        field: 'active',
        show: true,
    },
    {
        title: 'VVSec Enabled',
        field: 'vvsecEnabled',
        show: false,
    },
    {
        title: 'VVSec Number',
        field: 'vvsecNum',
        show: false,
    },
    {
        title: 'IP Address',
        field: 'ipAddress',
        show: false,
    },
    {
        title: 'Analytics Trigger Stream',
        field: 'atStream',
        show: true,
    },
    {
        title: 'Analytics Trigger HW Decoding',
        field: 'atHWDec',
        show: true,
    },
    {
        title: 'Analytics Trigger HW Encoding',
        field: 'atHWEnc',
        show: false,
    },
    {
        title: 'Analytics Trigger Force TCP',
        field: 'atTCP',
        show: true,
    },
    {
        title: 'Analytics Trigger Resolution',
        field: 'atResolution',
        show: false,
    },
    {
        title: 'Analytics Trigger Frame Rate',
        field: 'atFrameRate',
        show: false,
    },
    {
        title: 'Analyse Motion',
        field: 'motionAnalysis',
        show: true,
    },
    {
        title: 'Learning Rate',
        field: 'learningRate',
        show: false,
    },
    {
        title: 'Motion Algorithm',
        field: 'motionAlgorithm',
        show: false,
    },
    {
        title: 'Motion Cooloff',
        field: 'motionCooloff',
        show: false,
    },
    {
        title: 'Burst Cooloff',
        field: 'burstCooloff',
        show: false,
    },
    {
        title: 'Burst Delay',
        field: 'burstDelay',
        show: false,
    },
    {
        title: 'Initial Delay',
        field: 'initialDelay',
        show: false,
    },
    {
        title: 'Maximum Bursts',
        field: 'maxBursts',
        show: false,
    },
    {
        title: 'Minimum Bursts',
        field: 'minBursts',
        show: false,
    },
    {
        title: 'Erosions',
        field: 'erosions',
        show: false,
    },
    {
        title: 'Dilations',
        field: 'dilations',
        show: false,
    },
    {
        title: 'Delta Threshold',
        field: 'deltaThreshold',
        show: false,
    },
    {
        title: 'Trigger on Motion',
        field: 'motionTrigger',
        show: true,
    },
    {
        title: 'Motion: Minimum Area',
        field: 'motionMinArea',
        show: false,
    },
    {
        title: 'Motion: Maximum Area',
        field: 'motionMaxArea',
        show: false,
    },
    {
        title: 'Motion: Linked Cameras',
        field: 'motionLinkedCameras',
        show: false,
    },
    {
        title: 'Keepout Zones',
        field: 'keepoutZones',
        show: true,
    },
    {
        title: 'Line Trip Trigger',
        field: 'lineTrip',
        show: true,
    },
    {
        title: 'Line Trip: Linked Cameras',
        field: 'lineLinkedCameras',
        show: false,
    },
    {
        title: 'Line Counting',
        field: 'lineCounts',
        show: true,
    },
    {
        title: 'Facial Recognition Analytics',
        field: 'frAnalytics',
        show: true,
    },
    {
        title: 'Facial Recognition: Minimum Face Size',
        field: 'frMinFaceSize',
        show: false,
    },
    {
        title: 'LPR Analytics',
        field: 'lprAnalytics',
        show: true,
    },
    {
        title: 'LPR Region',
        field: 'lprRegion',
        show: false,
    },
    {
        title: 'Heatmaps Enabled',
        field: 'heatmaps',
        show: true,
    },
    {
        title: 'Cloud Images: Scale',
        field: 'cloudScale',
        show: true,
    },
    {
        title: 'Cloud Images: Schedule',
        field: 'cloudSchedule',
        show: false,
    },
    {
        title: 'LS Active',
        field: 'lsActive',
        show: true,
    },
    {
        title: 'Local Storage',
        field: 'lsType',
        show: true,
    },
    {
        title: 'LS Stream',
        field: 'lsStream',
        show: false,
    },
    {
        title: 'LS HW Decoding',
        field: 'lsHWDec',
        show: false,
    },
    {
        title: 'LS HW Encoding',
        field: 'lsHWEnc',
        show: false,
    },
    {
        title: 'LS Force TCP',
        field: 'lsForceTCP',
        show: false,
    },
    {
        title: 'LS Resolution',
        field: 'lsResolution',
        show: false,
    },
    {
        title: 'LS Frame Rate',
        field: 'lsFrameRate',
        show: false,
    },
];

// List of columns to be displayed when the camera metadata role is present for the user
const cameraMetadataColumns = [
    {
        title: 'Metadata: Serial',
        field: 'serialMeta',
        show: false,
    },
    {
        title: 'Metadata: Manufacturer',
        field: 'manufacturerMeta',
        show: false,

    },
    {
        title: 'Metadata: Model Code',
        field: 'modelCodeMeta',
        show: false,

    },
    {
        title: 'Metadata: Camera Type',
        field: 'camTypeMeta',
        show: false,

    },
    {
        title: 'Metadata: Communication Type',
        field: 'commsTypeMeta',
        show: false,

    },
    {
        title: 'Metadata: IP Address',
        field: 'ipAddressMeta',
        show: false,

    },
    {
        title: 'Metadata: Channel Number',
        field: 'channelNumberMeta',
        show: false,

    },
    {
        title: 'Metadata: Port',
        field: 'portMeta',
        show: false,

    },
    {
        title: 'Metadata: Location',
        field: 'locationMeta',
        show: false,

    },
    {
        title: 'Metadata: User Name',
        field: 'usernameMeta',
        show: false,

    },
    {
        title: 'Metadata: Password',
        field: 'passwordMeta',
        show: false,

    },
    {
        title: 'Metadata: Installed Date',
        field: 'installedDateMeta',
        show: false,

    },
    {
        title: 'Metadata: Warranty Date',
        field: 'warrantyDateMeta',
        show: false,
    },
];

// Starting columns meant to be visible in normal mode
const defaultSelectedColumns = [
    // 'Account',
    'Reference Photo',
    'Alias',
    'Active',
    // 'VVSec Number',
    // 'VVSec Enabled',
    // 'IP Address',
    'Analytics Trigger Stream',
    'Analytics Trigger HW Decoding',
    // 'Analytics Trigger HW Encoding',
    'Analytics Trigger Force TCP',
    // 'Analytics Trigger Resolution',
    // 'Analytics Trigger Frame Rate',
    'Analyse Motion',
    'Trigger on Motion',
    // 'Delta Threshold',
    // 'Motion: Minimum Area',//(%)
    // 'Motion: Maximum Area',//(%)
    'Keepout Zones', // (Number)
    // 'Motion: Linked Cameras',
    'Line Trip Trigger',
    // 'Line Trip: Linked Cameras',
    'Line Counting',
    'Facial Recognition Analytics',
    // 'Facial Recognition: Minimum Face Size', // (%)'
    'LPR Analytics',
    // 'LPR Region',
    'Heatmaps Enabled',
    'Cloud Images: Scale',
    // 'Cloud Images: Schedule',
    'LS Active',
    'Local Storage', // (24h, motion only, scheduled)
    // 'LS Stream',
    // 'LS HW Decoding',
    // 'LS HW Encoding',
    // 'LS Force TCP',
    // 'LS Resolution',
    // 'LS Frame Rate',
];

export default {
    baseColumns,
    cameraMetadataColumns,
    defaultSelectedColumns,
};
