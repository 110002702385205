import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';

export default class OptionsController {
    $state;
    $http;
    $scope;
    $uibModal;
    $uibModalInstance;
    Auth;
    reference;
    lastFilter;
    tempFilter = {};
    allTags = [];

    /* @ngInject */
    constructor($uibModalInstance, Auth, $http, $state, $scope, $uibModal, lastFilter) {
        this.$uibModalInstance = $uibModalInstance;
        this.Auth = Auth;
        this.$state = $state;
        this.$http = $http;
        this.$scope = $scope;
        this.$uibModal = $uibModal;
        this.lastFilter = lastFilter;
    }

    $onInit() {
        const self = this;
        self.$http.get('api/identities/findTags').then((response) => {
            self.allTags = response.data;
        });
        // console.log(self.reference);
        self.tempFilter = _.cloneDeep(self.lastFilter);
    }

    doLog() {
        const self = this;
        console.log('DEBUG: ', self);
    }

    close() {
        const self = this;
        self.$uibModalInstance.dismiss();
    }

    applyFilter(filter) {
        const self = this;
        self.$uibModalInstance.close({ filter: self.tempFilter });
    }

    clearFilter() {
        const self = this;
        self.$uibModalInstance.close({
            filter: {
                alias: undefined, tags: undefined, description: undefined, flag: undefined,
            },
        });
    }
}
