import _ from 'lodash-es';
import moment from 'moment';
import angular from 'angular';
import angularXEditable from 'angular-xeditable';

export class scheduleComponent {
    Auth;
    /* @ngInject */
    constructor(Auth) {
        this.days = [
            { name: 'Sunday', value: 0 },
            { name: 'Monday', value: 1 },
            { name: 'Tuesday', value: 2 },
            { name: 'Wednesday', value: 3 },
            { name: 'Thursday', value: 4 },
            { name: 'Friday', value: 5 },
            { name: 'Saturday', value: 6 },
        ];
        this.Auth = Auth;
        this.weekday = new Array(7);
        this.weekday[0] = 'Sunday';
        this.weekday[1] = 'Monday';
        this.weekday[2] = 'Tuesday';
        this.weekday[3] = 'Wednesday';
        this.weekday[4] = 'Thursday';
        this.weekday[5] = 'Friday';
        this.weekday[6] = 'Saturday';

        this.limitTo = new Array(3);
        this.limitTo[0] = 'All';
        this.limitTo[1] = 'Motion';
        this.limitTo[2] = 'None';

        this.limitToCloud = new Array(2);
        this.limitToCloud[0] = 'Motion & Lines';
        this.limitToCloud[1] = 'Lines';
        this.limitToCloud[2] = 'Motion';
        this.limitToCloud[3] = 'None';

        this.motionEvents = new Array(2);
        this.motionEvents[0] = 'Raise';
        this.motionEvents[1] = 'Do Not Raise';

        this.auto = new Array(2);
        this.auto[0] = 'every';
        this.auto[1] = 'never';

        this.baseDate = new Date();
        this.startOfWeek = this.getStartOfWeekUTC(this.baseDate);
    }

    $onInit() {
        const self = this;
        if (typeof this.scheduleconfig.defaults.lines === 'undefined') {
            this.scheduleconfig.defaults.lines = true;
        }
        this.scheduleconfig.defaultState = this.limitFlagsToString(this.scheduleconfig.defaults.motion, this.scheduleconfig.defaults.nonmotion, this.scheduleconfig.defaults.lines);
        _.forEach(this.scheduleconfig.schedules, (schedule) => {
            if (typeof schedule.lines === 'undefined') {
                schedule.lines = true;
            }
            schedule.startTime = self.dowAndTimeToJS(self.startOfWeek, schedule.startDay, schedule.startHour, schedule.startMinute);
            schedule.startDayLocal = schedule.startTime.getDay();
            schedule.endTime = self.dowAndTimeToJS(self.startOfWeek, schedule.endDay, schedule.endHour, schedule.endMinute);
            schedule.endDayLocal = schedule.endTime.getDay();
            schedule.limit = self.limitFlagsToString(schedule.motion, schedule.nonmotion, schedule.lines);
            schedule.motionDisplay = self.motionEventToString(schedule.motionEvent);
            schedule.autoDisplay = self.autosnapshotToString(schedule.autosnapshot);
            // schedule.autosnapshot = schedule.autosnapshot;
        });

        self.enabledSwitchId = `${self.cameraid}enableScheduleSwitch`;
    }

    getStartOfWeekUTC(baseDate) {
        const bdUTC = moment(baseDate).utc();
        bdUTC.day(7);
        bdUTC.hour(0);
        bdUTC.minute(0);
        bdUTC.second(0);
        return bdUTC.toDate();
    }

    // The DOW and TOD entries are in UTC by convention
    dowAndTimeToJS(sow, dow, hod, mod) {
        const sowutc = moment(sow).utc(); // Convert our base start of week to UTC
        sowutc.add(dow, 'days');
        sowutc.add(hod, 'hours');
        sowutc.add(mod, 'minutes');
        return sowutc.toDate();
    }

    limitStringToFlags(limit) {
        switch (limit) {
        case 'All':
            return { motion: true, nonmotion: true, lines: true };
        case 'Motion & Lines':
            return { motion: true, nonmotion: false, lines: true };
        case 'Motion':
            return { motion: true, nonmotion: false, lines: false };
        case 'Lines':
            return { motion: false, nonmotion: false, lines: true };
        case 'None':
            return { motion: false, nonmotion: false, lines: false };
        default:
            return { motion: false, nonmotion: false };
        }
    }

    motionEventToString(motionEvent) {
        if (motionEvent) {
            return 'Raise';
        }
        return 'Do Not Raise';
    }

    limitFlagsToString(motion, nonmotion, lines) {
        if (motion && nonmotion && lines) return 'All';
        if (motion && !nonmotion && !lines) return 'Motion';
        if (motion && !nonmotion && lines) return 'Motion & Lines';
        if (!motion && !nonmotion && lines) return 'Lines';
        return 'None';
    }

    addSchedule() {
        const self = this;

        if (!this.scheduleconfig.schedules) {
            this.scheduleconfig.schedules = [];
        }

        const schedule = {
            enabled: true,
            startDay: 0,
            startHour: 0,
            startMinute: 0,
            endDay: 0,
            endHour: 0,
            endMinute: 0,
            motion: true,
            lines: true,
            nonmotion: true,
            limit: this.limitFlagsToString(true, this.localstorage, true),
            motionDisplay: this.motionEventToString(true),
            autosnapshot: 0,
            autoDisplay: 'never',
        };

        schedule.startTime = self.dowAndTimeToJS(self.startOfWeek, schedule.startDay, schedule.startHour, schedule.startMinute);
        schedule.startDayLocal = schedule.startTime.getDay();
        schedule.endTime = self.dowAndTimeToJS(self.startOfWeek, schedule.endDay, schedule.endHour, schedule.endMinute);
        schedule.endDayLocal = schedule.endTime.getDay();

        this.scheduleconfig.schedules.push(schedule);
    }

    motionEventStringToBool(motionEvent) {
        return motionEvent === 'Raise';
    }

    updateMotionEvent(schedule) {
        schedule.motionEvent = this.motionEventStringToBool(schedule.motionDisplay);
    }

    updateAuto(schedule) {
        if (schedule.autoDisplay === 'every') {
            schedule.autosnapshot = 5;
        } else {
            schedule.autosnapshot = 0;
        }
    }

    autosnapshotToString(auto) {
        if (auto) {
            return 'every';
        }
        return 'never';
    }

    updateStartTime(schedule) {
        schedule.startTime = moment(schedule.startTime).day(schedule.startDayLocal)
            .toDate();
        schedule.startDay = moment(schedule.startTime).utc()
            .day();
        schedule.startHour = moment(schedule.startTime).utc()
            .hour();
        schedule.startMinute = moment(schedule.startTime).utc()
            .minute();
    }

    updateEndTime(schedule) {
        schedule.endTime = moment(schedule.endTime).day(schedule.endDayLocal)
            .toDate();
        schedule.endDay = moment(schedule.endTime).utc()
            .day();
        schedule.endHour = moment(schedule.endTime).utc()
            .hour();
        schedule.endMinute = moment(schedule.endTime).utc()
            .minute();
    }

    updateLimits(schedule) {
        const flags = this.limitStringToFlags(schedule.limit);
        schedule.motion = flags.motion;
        schedule.nonmotion = flags.nonmotion;
        schedule.lines = flags.lines;
    }

    defaultStateChanged() {
        const flags = this.limitStringToFlags(this.scheduleconfig.defaultState);
        this.scheduleconfig.defaults.motion = flags.motion;
        this.scheduleconfig.defaults.nonmotion = flags.nonmotion;
        this.scheduleconfig.defaults.lines = flags.lines;
    }

    removeSchedule(index, schedule) {
        this.scheduleconfig.schedules.splice(index, 1);
    }
}

export default angular.module('cameraViewerApp.schedule', ['xeditable'])
    .component('schedule', {
        template: require('./schedule.html'),
        bindings: {
            localstorage: '<',
            scheduleconfig: '=',
            eventstack: '<',
            schedulechanged: '&',
            cameraid: '<',
        },
        controller: scheduleComponent,
        controllerAs: '$ctrl',
    })
    .name;
