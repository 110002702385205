export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.heatmaps', {
            url: '/heatmaps',
            views: {
                'content@main': {
                    template: '<heatmaps></heatmaps>',
                },
            },
            authenticate: 'Analytics',
            data: {
                title: 'Heatmaps Analytics',
            },
        });
}
