import _ from 'lodash';
import angular from 'angular';

export class headerComponent {
    headerIcon;
    $window;
    Auth;
    currentAccount;
    setCurrentAccount;
    accounts;
    $rootScope;
    $state;

    /* @ngInject */
    constructor($window, socket, Auth, $rootScope, $location, $state, $http) {
        this.$window = $window;
        this.Auth = Auth;
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.socket = socket;
        this.setCurrentAccount = Auth.setCurrentAccount;
        this.$state = $state;
        this.$http = $http;
    }

    $onInit() {
        const self = this;
        this.currentAccount = this.Auth.getCurrentAccountSync();
        this.refreshAccountList();
        this.setAccountIcon();

        this.$rootScope.$on('accountChanged', (event) => {
            self.Auth.refreshUser();
            self.currentAccount = self.Auth.getCurrentAccountSync();
            self.setAccountIcon();
        });

        this.$rootScope.$on('accountsUpdated', () => {
            self.Auth.refreshUser();
            self.refreshAccountList();
            self.setAccountIcon();
        });
    }

    // TODO: Get this working && set a default icon if corresponding domain's icon is not found
    setAccountIcon() {
        this.headerIcon = `assets/images/accounticons/${this.currentAccount.name}.png`;
    }

    refreshAccountList() {
        const self = this;
        self.accounts = self.Auth.getCurrentUserSync().accounts;
        if (!self.accounts || self.accounts.length === 0) {
            self.Auth.getCurrentUser()
                .then((user) => {
                    self.accounts = user.accounts;
                    self.currentAccount = self.Auth.getCurrentAccountSync();
                    self.filterAccounts();
                });
        } else {
            self.filterAccounts();
        }
    }

    showSettings() {
        this.$state.go('main.userSettings')
            .catch((err) => {
                console.log(err);
            });
    }

    sTest(val) {
        // console.log("Switching to: ",val);
        if (this.$location.path() !== '/login') {
            // console.log("Setting current account from header");
            this.socket.leaveAll()
                .then(() => {
                    this.setCurrentAccount(val.accountId);
                });
        }
    }

    filterAccounts() {
        const self = this;
        self.favouriteAccounts = _.filter(self.accounts, (o) => o.favourite === true && o.hidden === false);
        self.normalAccounts = _.filter(self.accounts, (o) => o.favourite === false && o.hidden === false);
    }

    debug() {
        console.log(this);
    }
}

export default angular.module('directives.header', [])
    .component('smartheader', {
        template: require('./header.html'),
        controller: headerComponent,
        controllerAs: '$ctrl',
    })
    .name;
