import _ from 'lodash';
import angular from 'angular';

export class ImageModalComponent {
    /* @ngInject */
    constructor(Auth) {
        this.Auth = Auth;
    }

    doLog() {
        const self = this;
        console.log('DEBUG: ', self);
    }

    $onInit() {
        // console.log("INITIALIZING");
        const self = this;
        self.event = self.resolve.settings.event;
        self.image = self.resolve.settings.image;
        self.context = self.resolve.settings.context;
        self.src = `assets/images/events/${self.image.label}.jpg`;
        self.footageType = self.determineFootage(self.image.camera);
        self.contactDetails = self.fetchContactDetailsFromSiteID(self.image.site);

        self.eventDetails = {
            footageType: {
                title: 'Footage Type',
                value: self.footageType,
            },
            dateTaken: {
                title: 'Date Taken',
                value: self.image.date,
            },
            currentDate: {
                title: 'Current Date',
                value: self.image.date,
            },
        };
    }

    close() {
        const result = 'stuff';
        this.modalInstance.close(result);
    }

    // This is a only a dummy method for practical demo-purposes and should
    // be replaced by something much more sophisticated.
    determineFootage(cameraID) {
        if (cameraID.indexOf('camera') !== -1) {
            return 'Image';
        }
        return 'Video';
    }

    fetchContactDetailsFromSiteID(siteID) {
        const self = this;
        const site = _.find(self.context.sites, { alias: siteID });
        if (site !== undefined) {
            return site.contacts;
        }
    }
}
export default angular.module('cameraViewerApp.events')
    .component('imagemodal', {
        template: require('./image-modal.html'),
        bindings: {
            resolve: '<',
            //     close: '&',
            //     dismiss: '&',
            modalInstance: '<',
        },
        controller: ImageModalComponent,
        controllerAs: '$ctrl',
    })
    .name;
