import _ from 'lodash';
import angular from 'angular';
import { fabric } from 'fabric';
import angularXEditable from 'angular-xeditable';
// import c3 from "c3";

import { v1 as uuid } from 'uuid';

export class HeatmapsComponent {
    $http;
    $scope;
    $state;
    currentSite = undefined;
    availableSites = [];
    moment;
    self;
    heatmapsOpen = {};
    isModal;
    toastr;

    /* @ngInject */
    constructor($state, $rootScope, moment, $scope, $http, socket, Auth, tasks, toastr, $timeout, $document) {
        this.$state = $state;
        this.$rootScope = $rootScope;
        this.isROICanvasCollapsed = false;
        this.$document = $document;
        this.$timeout = $timeout;
        this.listenerAdded = false;
        this.moment = moment;

        this.modes = new Array(3);
        this.modes[0] = 'Average';
        this.modes[1] = 'Min';
        this.modes[2] = 'Max';

        this.selectedROIs = [];
        this.points = [];
        this.invalid = false;
        this.currentShape;
        this.topHeatmapsOpen = false;
        // this.mode = "add";
        this.shapes = [];
        this.heatmapOpacity = {};
        this.taskOpacity = {};
        this.currentUser = Auth.getCurrentUserSync();

        this.charts = {};

        this.groupOptions = [
            { display: '5 Minutes', group: 5 },
            { display: '10 Minutes', group: 10 },
            { display: '15 Minutes', group: 15 },
            { display: '30 Minutes', group: 30 },
            { display: '1 Hour', group: 1 * 60 },
            { display: '2 Hours', group: 2 * 60 },
            { display: '3 Hours', group: 3 * 60 },
            { display: '4 Hours', group: 4 * 60 },
            { display: '5 Hours', group: 5 * 60 },
            { display: '6 Hours', group: 6 * 60 },
            { display: '12 Hours', group: 12 * 60 },
            { display: '24 Hours', group: 24 * 60 },
            { display: '7 Days', group: 24 * 7 * 60 },
        ];

        this.resOptions = [
            { resolution: 'Minutes', step: 5 },
            { resolution: 'Hours', step: 1 },
            { resolution: 'Days', step: 1 },
        ];
        this.resolution = { resolution: 'Minutes', step: 5 };
        this.group = 5;
        this.params = {};
        if (this.$state.params) {
            this.params = this.$state.params;
        }

        this.$scope = $scope;
        this.camera;

        this.formatOptions = [
            { alias: 'Video', format: 'Video' },
            // {alias: "Zip", format:"Zip"},
            // {alias: "Raw Zip", format: "RawZip"},
            { alias: 'Stats', format: 'Stats' },
        ];
        this.format = { alias: 'Video', format: 'Video' };

        this.socket = socket;
        this.$http = $http;
        this.Auth = Auth;
        this.cleanUpFunctions = [];
        this.heatmapsOpen = {};
        this.mode = 'Select';
        this.multiple = false;
        this.single = true;

        this.taskService = tasks;

        this.heatmaps = [];
        this.currentRooms = [];
        this.cameras = [];
        this.toastr = toastr;

        this.slider = {
            options: {
                min: 0,
                max: 1,
                step: 0.01,
                change: this.opacityChanged.bind(this),
            },
        };

        this.vidSlider = {
            options: {
                min: 0,
                max: 1,
                step: 0.01,
                change: this.vidOpChanged.bind(this),
            },
        };

        const self = this;

        this.renderChart = function (task) {
            let statIndex = 2;
            if (self.charts[task._id].mode == 'Min') {
                statIndex = 0;
            } else if (self.charts[task._id].mode == 'Max') {
                statIndex = 1;
            } else if (self.charts[task._id].mode == 'Average') {
                statIndex = 2;
            }
            const drawColumns = [];
            const timeseries = _.map(task.taskResults.stats.timestamps, (o) => self.moment.unix(o).toDate());
            let column = timeseries;
            column.unshift('x');
            drawColumns.push(column);
            if (task.taskOptions.hasOwnProperty('rois')) {
                for (const i in task.taskResults.stats.rois) {
                    column = [];
                    const stat = task.taskResults.stats.rois[i];
                    column.push(task.taskOptions.rois[i].alias);
                    for (const k in stat) {
                        column.push(stat[k][statIndex]);
                    }
                    drawColumns.push(column);
                }
            } else {
                // This is where there are no rois, but only the main heatmap
                column = [];
                const stat = task.taskResults.stats.rois[0];
                column.push('Global');
                for (const k in stat) {
                    column.push(stat[k][statIndex]);
                }
                drawColumns.push(column);
            }
            if (!self.charts[task._id].chart) {
                self.charts[task._id].chart = c3.generate({
                    bindto: `#Chart_${task._id}`,
                    data: {
                        x: 'x',
                        columns: drawColumns,
                    },
                    axis: {
                        x: {
                            label: {
                                text: 'Timeline',
                                position: 'outer-center',
                            },
                            type: 'timeseries',
                            tick: {
                                format: '%Y-%m-%d %H:%M:%S',
                            },
                        },
                        y: {
                            label: {
                                text: 'Motion',
                                position: 'outer-middle',
                            },
                        },
                    },
                });
            } else {
                drawColumns.splice(0, 1);
                self.charts[task._id].chart.load({
                    columns: drawColumns,
                    unload: true,
                });
            }
        };
    }

    vidOpChanged(event, slider) {
        const sliderid = event.target.id.replace('heatmapVidOpSlider_', '');
        if (sliderid !== '{{heatmap._id}}') {
            const vidEl = document.getElementById(`heatmapVidCanvas_${sliderid}`);
            vidEl.style.opacity = slider.value;
        }
    }

    drawStatsROICanvas(task) {
        const self = this;
        const canvasName = `statsROICanvas_${task._id}`;
        if (typeof this[canvasName] !== 'undefined') {
            this[canvasName].dispose();
        }
        if (!self.isCollapsed[task._id]) {
            delete self[canvasName];
            return;
        }
        this[canvasName] = new fabric.Canvas(canvasName, { stopContextMenu: true, enableRetinaScaling: false });
        const thiscanvas = this[canvasName];
        thiscanvas.clear();

        const imageEl = this.$document[0].querySelector(`#statsROIImage_${task._id}`);
        const canvasEl = this.$document[0].querySelector(`#${canvasName}`);

        const image = new fabric.Image(imageEl, {
            left: 0, top: 0, opacity: 1,
        });
        thiscanvas.containerClass = 'snapshot-wrapper';

        thiscanvas.setWidth('100%', { cssOnly: true });
        thiscanvas.setHeight('auto', { cssOnly: true });
        thiscanvas.selection = false;
        self.$timeout(() => {
            thiscanvas.setWidth(canvasEl.clientWidth, { backstoreOnly: true });
            thiscanvas.setHeight(canvasEl.clientWidth / (image.width / image.height), {
                backstoreOnly: true,
            });
            image.set({
                scaleX: thiscanvas.width / image.width,
                scaleY: thiscanvas.height / image.height,
                originX: 'left',
                originY: 'top',
            });
            if (task.taskOptions.hasOwnProperty('rois')) {
                task.taskOptions.rois.forEach((roi) => {
                    const points = [];
                    roi.points.forEach((point) => {
                        points.push({ x: point[0] * canvasEl.clientWidth, y: point[1] * canvasEl.clientHeight });
                    });
                    const poly = new fabric.Polyline(points, {
                        id: roi.id,
                        opacity: 0.5,
                        fill: 'blue',
                        selectable: false,
                    });
                    poly.lockRotation = true;
                    poly.lockMovementX = true;
                    poly.lockMovementY = true;
                    poly.lockScalingY = true;
                    poly.lockScalingX = true;
                    poly.hasControls = false;
                    thiscanvas.add(poly);
                    thiscanvas.renderAll();
                    const textId = new fabric.Text(roi.alias, {
                        fontSize: 16,
                        fontFamily: 'Monospace',
                        textAlign: 'center',
                        top: poly.top + poly.height / 2,
                        left: poly.left + poly.width / 2,
                        textBackgroundColor: 'white',
                        originX: 'center',
                        originY: 'center',
                        shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
                        selectable: false,
                        hasBorders: false,
                        hasControls: false,
                    });
                    thiscanvas.add(textId);
                    thiscanvas.renderAll();
                });
                thiscanvas.setBackgroundImage(image, thiscanvas.renderAll.bind(thiscanvas));
            }
        }, 0);
    }

    opacityChanged(event, slider) {
        const self = this;
        const sliderid = event.target.id.replace('heatmapOpSlider_', '');
        const canvasName = `heatmapImgCanvas_${sliderid}`;
        if (typeof self[canvasName] !== 'undefined') {
            const thiscanvas = self[canvasName];
            if (thiscanvas.heatmap) {
                thiscanvas.heatmap.set('opacity', slider.value);
                thiscanvas.renderAll();
            }
        }
    }

    $onDestroy() {
        const self = this;
        self.socket.unsyncUpdates('site');
        self.socket.unsyncUpdates('camera');
        if (self.currentRooms.length > 0) {
            self.currentRooms.forEach((room) => {
                self.socket.leaveRoom(room);
            });
        }
        for (const i in self.cleanUpFunctions) {
            self.cleanUpFunctions[i]();
        }
    }

    $onInit() {
        const self = this;
        const now = self.moment().startOf('minute');
        const remainder = 5 - now.minute() % 5;
        self.startTime = self.moment(now).subtract(10 - remainder, 'minutes')
            .toDate();
        self.startDate = self.moment(now).startOf('day')
            .toDate();

        self.endTime = self.moment(self.startTime).add(5, 'minutes')
            .toDate();
        self.endDate = self.moment(self.startDate).add(5, 'minutes')
            .startOf('day')
            .toDate();
        self.$http.get('/api/sites/basic').then((response) => {
            self.availableSites = _.map(response.data, self.formatSiteDown);
            self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().accountId}:*:basic:sites`);
            self.currentRooms.push(`${self.Auth.getCurrentAccountSync().accountId}:*:basic:sites`);
        });

        if (self.params.id) {
            self.currentSite = _.find(self.availableSites, (o) => o._id === self.params.id);
        }

        if (self.currentSite && self.currentSite._id) {
            self.$http
                .get('/api/heatmaps/', {
                    params: {
                        params: JSON.stringify([
                            {
                                field: 'site',
                                type: 'string',
                                value: [self.currentSite._id],
                                operator: '$in',
                            },
                        ]),
                    },
                })
                .then((response) => {
                    self.heatmaps = response.data;
                });

            const cleanUpFunc = self.$rootScope.$on('taskUpdate', self.newTaskCallback);
            self.cleanUpFunctions.push(cleanUpFunc);
            self.taskService.getTasks().then((tasks) => {
                self.tasks = tasks.filter((o) => o.user === self.currentUser._id && o.site === self.currentSite._id && o.type === 'retrieveHeatmap');
                self.tasks.forEach((hm) => {
                    const camInd = _.findIndex(self.cameras, (o) => o.camera.camera == hm.taskOptions.camera.camera);
                    if (camInd !== -1) {
                        hm.referenceShot = self.cameras[camInd].referenceShot;
                    }
                });
            });
        }

        this.newTaskCallback = function (event, eventObj) {
            if (eventObj.item.type === 'retrieveHeatmap') {
                if ((eventObj.event == 'created' || eventObj.event === 'updated') && eventObj.item.user === self.currentUser._id && eventObj.item.site === self.currentSite._id) {
                    self.tasks = eventObj.array.filter((o) => o.user === self.currentUser._id && o.site === self.currentSite._id && o.type === 'retrieveHeatmap');
                    if (eventObj.item.status == 'done') {
                        if (eventObj.item.taskResults.hasOwnProperty('singleHeatmap')) {
                            // if(eventObj.item.taskOptions.hasOwnProperty("multiple") && eventObj.item.taskOptions.multiple.format == "Video"){
                            // let taskInd = _.findIndex(self.tasks, o => {
                            // return o._id ==eventObj.item._id;
                            // });
                            // if(taskInd !== -1) {
                            // let tR = eventObj.item.taskResults;
                            // tR.multiHeatmap = self.tasks[taskInd].taskResults.multiHeatmap;
                            // self.tasks[taskInd] = eventObj.item;
                            // self.tasks[taskInd].taskResults = tR;
                            // }
                            // }
                            if (self.heatmapsOpen[eventObj.item._id]) {
                                self.$timeout(() => {
                                    self.drawTaskHeatmapCanvas(eventObj.item);
                                }, 0);
                            }
                        }

                        if (eventObj.item.taskResults.hasOwnProperty('multiHeatmap') || eventObj.item.taskResults.hasOwnProperty('stats')) {
                            const camInd = _.findIndex(self.cameras, (o) => o.camera.camera == eventObj.item.taskOptions.camera.camera);
                            if (camInd !== -1) {
                                eventObj.item.referenceShot = self.cameras[camInd].referenceShot;
                            }
                        }

                        if (eventObj.item.taskResults.hasOwnProperty('stats')) {
                            if (self.heatmapsOpen[eventObj.item._id]) {
                                self.drawGraph(eventObj.item, true);
                            }
                        }
                        self.toastr.success(`Heatmap Received from Unit: ${eventObj.item.alias}`, {
                            preventOpenDuplicates: true,
                        });
                    }
                    if (eventObj.item.status == 'failed') {
                        if (eventObj.item.taskResults.failReason == 'noResult') {
                            self.toastr.warning(`Heatmap Task failed: ${eventObj.item.alias}`, {
                                preventOpenDuplicates: true,
                            });
                        }
                    }
                } else if (eventObj.event === 'deleted') {
                    self.tasks = eventObj.array.filter((o) => o.user === self.currentUser._id && o.site === self.currentSite._id && o.type === 'retrieveHeatmap');
                } else {
                    const index = eventObj.array.indexOf(eventObj.item);
                    if (index !== -1) {
                        eventObj.array.splice(index, 1);
                    }
                    self.tasks = eventObj.array.filter((o) => o.user === self.currentUser._id && o.site === self.currentSite._id);
                }
            }
        };
    }

    selected($item, $model) {
        const self = this;
        self.socket.unsyncUpdates('heatmap');
        self.$http
            .get('/api/cameras', { params: { site: self.currentSite._id, showUnbounded: true } })
            .then((response) => {
                self.cameras = response.data;
                self.$http
                    .get('/api/heatmaps/', {
                        params: {
                            params: JSON.stringify([
                                {
                                    field: 'site',
                                    type: 'string',
                                    value: [self.currentSite._id],
                                    operator: '$in',
                                },
                            ]),
                        },
                    })
                    .then((hmResponse) => {
                        self.heatmaps = hmResponse.data;
                        self.heatmaps.forEach((hm) => {
                            const camInd = _.findIndex(
                                self.cameras,
                                (o) => o.camera.camera == hm.zone
                            );
                            if (camInd !== -1) {
                                hm.referenceShot = self.cameras[camInd].referenceShot;
                            }
                            self.heatmapOpacity[hm._id] = 0.5;
                        });
                    });
                self.currentSite = $item;
                self.tasks = self.taskService
                    .getTasksSync()
                    .filter(
                        (o) =>
                            o.user === self.currentUser._id &&
                            o.site === self.currentSite._id &&
                            o.type === 'retrieveHeatmap'
                    );
                self.tasks.forEach((hm) => {
                    const camInd = _.findIndex(
                        self.cameras,
                        (o) => o.camera.camera == hm.taskOptions.camera.camera
                    );
                    if (camInd !== -1) {
                        hm.referenceShot = self.cameras[camInd].referenceShot;
                    }
                });
                const cleanUpFunc = self.$rootScope.$on('taskUpdate', self.newTaskCallback);
                self.cleanUpFunctions.push(cleanUpFunc);
            });

        self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().accountId}:${$item._id}:*:heatmaps`);
        self.currentRooms.push(`${self.Auth.getCurrentAccountSync().accountId}:${$item._id}:*:heatmaps`);
        // self.socket.socket.on("site:save", site => {
        // });
        self.socket.syncUpdates('heatmap', self.heatmaps, (event, item, array) => {
            if (event == 'created') {
                self.heatmapOpacity[item._id] = 0.5;
                const camInd = _.findIndex(self.cameras, (o) => o.camera.camera == item.zone);
                if (camInd !== -1) {
                    item.referenceShot = self.cameras[camInd].referenceShot;
                }
                self.heatmaps.push(item);
            } else if (event == 'deleted') {
                const hmInd = _.findIndex(self.heatmaps, (o) => o._id == item._id);
                if (hmInd !== -1) {
                    self.heatmaps.splice(hmInd, 1);
                }
            }
        });
        self.isROICanvasCollapsed = false;
        self.camera = null;
        self.shapes = [];
        self.drawROICanvas(false);
    }

    addROI(roi) {
        const self = this;
        const pointTest = [];
        roi.points.forEach((point) => {
            pointTest.push({ x: point[0], y: point[1] });
        });
        const valid = self.testValid(pointTest);
        roi.valid = valid;
        self.shapes.push(roi);
        const thiscanvas = self.roiCanvas;
        if (thiscanvas) {
            const points = [];
            roi.points.forEach((point) => {
                points.push({ x: point[0] * thiscanvas.getWidth(), y: point[1] * thiscanvas.getHeight() });
            });
            const poly = new fabric.Polygon(points, {
                id: roi.id,
                saved: roi.saved,
                alias: roi.alias,
                opacity: 0.5,
                selectable: true,
            });
            if (!valid) {
                poly.fill = 'orange';
            } else {
                poly.fill = 'green';
            }

            poly.lockRotation = true;
            poly.lockMovementX = true;
            poly.lockMovementY = true;
            poly.lockScalingY = true;
            poly.lockScalingX = true;
            poly.hasControls = false;

            thiscanvas.add(poly);
            thiscanvas.renderAll();
        }
    }

    removeSelectedROI(roi) {
        const self = this;
        const shapeInd = _.findIndex(self.shapes, (o) => o.id == roi.id);
        self.shapes.splice(shapeInd, 1);
        if (self.currentShape && self.currentShape.id == roi.id) {
            self.currentShape = null;
        }
        if (roi) {
            const thiscanvas = self.roiCanvas;
            if (thiscanvas) {
                thiscanvas.getObjects().some((o) => {
                    if (o.id == roi.id) {
                        roi = o;
                        return true;
                    }
                    return false;
                });
                thiscanvas.remove(roi);
            }
        }
        // let roiInd = _.findIndex(self.shapes, o => {
        // return o.id == roi.id;
        // });
        // self.shapes.splice(roiInd, 1);
    }

    drawTaskHeatmapCanvas(task) {
        const self = this;
        if (task.taskResults && task.taskResults.hasOwnProperty('singleHeatmap') || task.taskResults.hasOwnProperty('multiHeatmap')) {
            const dummyHM = {
                multiple: false,
                data: task.taskResults.singleHeatmap,
                _id: task._id,
                drawAnyway: true,
                zone: task.taskOptions.camera.camera,
            };
            self.heatmapOpacity[dummyHM._id] = 0.5;
            self.drawHeatmapCanvas(dummyHM);
        }
    }

    drawGraph(task, draw) {
        const self = this;
        if (!self.charts[task._id]) {
            self.charts[task._id] = { mode: 'Average' };
        }
        if (task.taskResults.stats && draw) {
            self.renderChart(task);
        } else if (task.taskResults.stats && !draw) {
            self.charts[task._id].chart = self.charts[task._id].chart.destroy();
        }
    }

    drawHeatmapCanvas(heatmap) {
        const self = this;
        const canvasName = `heatmapImgCanvas_${heatmap._id}`;
        if (typeof this[canvasName] !== 'undefined') {
            this[canvasName].dispose();
        }
        this[canvasName] = new fabric.Canvas(canvasName, { stopContextMenu: true, enableRetinaScaling: false });
        const thiscanvas = this[canvasName];
        thiscanvas.clear();
        if ((!heatmap.multiple && !self.heatmapsOpen[heatmap._id]) || heatmap.drawAnyway) {
            const canvasEl = this.$document[0].querySelector(`#${canvasName}`);
            if (!canvasEl) {
                return;
            }
            const camInd = _.findIndex(self.cameras, (cam) => cam.camera.camera == heatmap.zone);
            if (camInd >= 0 && self.cameras[camInd].referenceShot) {
                const image = new fabric.Image.fromURL(self.cameras[camInd].referenceShot, ((image) => {
                    image.setOptions({ left: 0, top: 0, opacity: 1 });
                    image.set('selectable', false);
                    const heatmapImg = new fabric.Image.fromURL(heatmap.data, ((tmp) => {
                        tmp.setOptions({ left: 0, top: 0, opacity: self.heatmapOpacity[heatmap._id] || 0.5 });
                        tmp.set('selectable', false);
                        thiscanvas.heatmap = tmp;
                        thiscanvas.add(tmp);
                        thiscanvas.renderAll();
                        self.$timeout(() => {
                            // this
                            thiscanvas.setWidth(canvasEl.clientWidth, { backstoreOnly: true });
                            thiscanvas.setHeight(canvasEl.clientWidth / (image.width / image.height), { backstoreOnly: true });
                            tmp.set({
                                scaleX: thiscanvas.width / tmp.width, scaleY: thiscanvas.height / tmp.height, originX: 'left', originY: 'top',
                            });
                        }, 0);
                    }), { crossOrigin: 'anonymous' });
                    self.$timeout(() => {
                        thiscanvas.setWidth(canvasEl.clientWidth, { backstoreOnly: true });
                        thiscanvas.setHeight(canvasEl.clientWidth / (image.width / image.height), { backstoreOnly: true });
                        image.set({
                            scaleX: thiscanvas.width / image.width, scaleY: thiscanvas.height / image.height, originX: 'left', originY: 'top',
                        });
                        thiscanvas.setBackgroundImage(image, thiscanvas.renderAll.bind(thiscanvas));
                    }, 0);
                }), { crossOrigin: 'anonymous' });
            } else {
                self.heatmapOpacity[heatmap._id] = 1;
                const heatmapImg = new fabric.Image.fromURL(heatmap.data, ((tmp) => {
                    tmp.setOptions({ left: 0, top: 0, opacity: 1 });
                    tmp.set('selectable', false);
                    thiscanvas.heatmap = tmp;
                    thiscanvas.add(tmp);
                    thiscanvas.renderAll();
                    self.$timeout(() => {
                        thiscanvas.setWidth(canvasEl.clientWidth, { backstoreOnly: true });
                        thiscanvas.setHeight(canvasEl.clientWidth / (tmp.width / tmp.height), { backstoreOnly: true });
                        tmp.set({
                            scaleX: thiscanvas.width / tmp.width, scaleY: thiscanvas.height / tmp.height, originX: 'left', originY: 'top',
                        });
                    }, 0);
                }), { crossOrigin: 'anonymous' });
            }
            thiscanvas.containerClass = 'snapshot-wrapper';
            thiscanvas.setWidth('100%', { cssOnly: true });
            thiscanvas.setHeight('auto', { cssOnly: true });
            thiscanvas.selection = false;
            thiscanvas.renderAll();
        }
    }

    createTask() {
        const self = this;
        const taskCamera = _.pick(self.camera, ['camera', '_id', 'alias', 'unit']);
        taskCamera.camera = taskCamera.camera._id;
        if (taskCamera.camera.startsWith(taskCamera.unit)) {
            taskCamera.camera = taskCamera.camera.slice(taskCamera.unit.length + 2);
        }

        const startyear = self.moment(self.startDate).get('year');
        const startmonth = self.moment(self.startDate).get('month') + 1;
        const startday = self.moment(self.startDate).get('date');
        const starthour = self.moment(self.startTime).get('hour');
        const startminute = self.moment(self.startTime).get('minute');
        const startTime = `${startyear}-${startmonth}-${startday} ${starthour}:${startminute}`;

        const endyear = self.moment(self.endDate).get('year');
        const endmonth = self.moment(self.endDate).get('month') + 1;
        const endday = self.moment(self.endDate).get('date');
        const endhour = self.moment(self.endTime).get('hour');
        const endminute = self.moment(self.endTime).get('minute');
        const endTime = `${endyear}-${endmonth}-${endday} ${endhour}:${endminute}`;

        // return;

        self.currentTask = {
            alias: `Heatmap from ${self.moment(startTime).toDate().toLocaleString()} to ${self.moment(endTime).toDate().toLocaleString()}`,
            user: self.Auth.getCurrentUserSync()._id,
            site: self.currentSite._id,
            unit: taskCamera.unit,
            type: 'retrieveHeatmap',
            status: 'starting',
            processes: [{
                type: 'Retrieving Heatmap',
                progress: 0,
            }],
            taskOptions: {
                from: +self.moment(startTime),
                to: +self.moment(endTime),
                camera: taskCamera,
                single: self.single,
            },
            taskResults: {},
            createdAt: Date.now(),
        };
        if (self.multiple) {
            self.currentTask.taskOptions.multiple = {
                group: self.group,
                cumulative: self.cumulative,
                format: self.format.format,
            };
        }
        if (self.shapes.length > 0) {
            const rois = self.mapROIs();
            if (typeof rois === 'boolean') {
                // TODO: toastr
                self.toastr.warning('There are invalid regions of interest', {
                    preventOpenDuplicates: true,
                });
                return false;
            } if (rois.length > 0) {
                self.currentTask.taskOptions.rois = rois;
            }
        }
        self.$http.post(`/api/tasks/createTask`, self.currentTask)
            .then((response) => {
                if (response.data) {
                    self.heatmapsOpen[response.data._id] = true;
                    self.progressOpen = true;
                    self.toastr.info('The heatmap task has been created, waiting for response', {
                        preventOpenDuplicates: true,
                    });
                }
            });
    }

    mapROIs() {
        const self = this;
        const returnROIs = [];
        let valid = true;
        self.shapes.forEach((shape) => {
            if (shape.valid) {
                const roi = [];
                if (shape.points.length > 2) {
                    returnROIs.push({ alias: shape.alias, points: shape.points });
                }
            } else {
                valid = false;
            }
        });
        if (valid) {
            return returnROIs;
        }
        return valid;
    }

    formatSiteDown(site) {
        site.zones.forEach((zone) => {
            site.units.forEach((unit) => {
                unit.cameras.forEach((cam) => {
                    if (zone.camera == cam._id) {
                        zone.down = cam.down;
                        zone.unitDown = unit.down;
                    }
                });
            });
        });
        return site;
    }

    doLog() {
        const self = this;
        console.log('DEBUG: ', self);
        // console.log("DEBUG: ", self.group);
    }

    getDownloadName(task) {
        return task.taskAlias;
    }

    removeLastPoint() {
        const self = this;
        if (self.currentShape) {
            if (self.currentShape.saved == true) {
                const selectInd = _.findIndex(self.camera.camera.configuration.rois, (o) => o.id == self.currentShape.id);
                self.camera.camera.configuration.rois[selectInd].saved = false;
            }
            const points = self.currentShape.get('points');
            points.pop();
            const thiscanvas = this.roiCanvas;
            thiscanvas.remove(self.currentShape);
            const poly = new fabric.Polygon(points, {
                id: self.currentShape.id,
                alias: self.currentShape.alias,
                saved: false,
                opacity: 0.5,
                selectable: true,
            });
            self.currentShape = poly;
            const valid = self.testValid(self.currentShape.get('points'));
            if (!valid) {
                self.currentShape.fill = 'red';
            } else {
                self.currentShape.fill = 'green';
            }
            self.currentShape.lockRotation = true;
            self.currentShape.lockMovementX = true;
            self.currentShape.lockMovementY = true;
            self.currentShape.lockScalingY = true;
            self.currentShape.lockScalingX = true;
            self.currentShape.hasControls = false;
            thiscanvas.add(self.currentShape);
            thiscanvas.renderAll();
            const roi = [];
            points.forEach((point) => {
                roi.push([point.x / thiscanvas.getWidth(), point.y / thiscanvas.getHeight()]);
            });
            const ind = _.findIndex(self.shapes, (o) => o.id == self.currentShape.id);
            if (ind !== -1) {
                self.shapes[ind].points = roi;
                self.shapes[ind].valid = valid;
            }
        }
    }

    removeROI(roi) {
        const self = this;
        if (self.currentShape) {
            const selectInd = _.findIndex(self.selectedROIs, (o) => o.id == self.currentShape.id);
            const thiscanvas = this.roiCanvas;
            thiscanvas.remove(self.currentShape);
            const shapeInd = _.findIndex(self.shapes, (o) => o.id == self.currentShape.id);
            self.currentShape = null;
            self.shapes.splice(shapeInd, 1);
            if (selectInd !== -1) {
                self.selectedROIs.splice(selectInd, 1);
            }
        }
    }

    selectMode() {
        const self = this;
        if (self.mode === 'Edit' || self.mode === 'Add' && self.currentShape) {
            self.mode = 'Select';
            const thiscanvas = this.roiCanvas;
            const points = self.currentShape.get('points');
            points.pop();
            thiscanvas.remove(self.currentShape);
            const poly = new fabric.Polygon(points, {
                // fill: 'green',
                id: self.currentShape.id,
                alias: self.currentShape.alias,
                saved: self.currentShape.saved,
                opacity: 0.5,
                selectable: true,
            });
            self.currentShape = poly;
            const valid = self.testValid(self.currentShape.get('points'));
            if (!valid) {
                self.currentShape.fill = 'red';
            } else {
                self.currentShape.fill = 'green';
            }
            self.currentShape.lockRotation = true;
            self.currentShape.lockMovementX = true;
            self.currentShape.lockMovementY = true;
            self.currentShape.lockScalingY = true;
            self.currentShape.lockScalingX = true;
            self.currentShape.hasControls = false;
            thiscanvas.add(self.currentShape);
            const ind = _.findIndex(self.shapes, (o) => o.id == self.currentShape.id);

            const roi = [];
            points.forEach((point) => {
                roi.push([point.x / thiscanvas.getWidth(), point.y / thiscanvas.getHeight()]);
            });
            if (ind == -1) {
                self.shapes.push({
                    id: self.currentShape.id,
                    saved: self.currentShape.saved,
                    alias: self.currentShape.alias,
                    points: roi,
                    valid,
                    // height: thiscanvas.getHeight(),
                    // width: thiscanvas.getWidth()
                });
            } else {
                self.shapes[ind].points = self.currentShape.get('points');
            }
        }
        self.currentShape = null;
    }

    editMode() {
        const self = this;
        if (self.currentShape && self.mode == 'Select') {
            if (self.currentShape.saved == true) {
                const selectInd = _.findIndex(self.camera.camera.configuration.rois, (o) => o.id == self.currentShape.id);
                self.camera.camera.configuration.rois[selectInd].saved = false;
            }
            self.mode = 'Edit';
            const points = self.currentShape.get('points');
            points.push(points[points.length - 1]);
            const thiscanvas = this.roiCanvas;
            thiscanvas.remove(self.currentShape);
            const poly = new fabric.Polygon(points, {
                id: self.currentShape.id,
                alias: self.currentShape.alias,
                saved: false,
                opacity: 0.5,
                selectable: false,
            });
            self.currentShape = poly;
            const valid = self.testValid(self.currentShape.get('points'));
            if (!valid) {
                self.currentShape.fill = 'red';
            } else {
                self.currentShape.fill = 'green';
            }
            thiscanvas.add(self.currentShape);
            thiscanvas.renderAll();
        }
    }

    addMode() {
        if (this.mode == 'Select') {
            this.mode = 'Add';
        }
    }

    drawROICanvas(isCollapsed) {
        const self = this;

        const canvasName = 'roiCanvas';
        if (typeof self[canvasName] !== 'undefined') {
            self[canvasName].dispose();
        }
        if (!isCollapsed) {
            delete self[canvasName];
            delete self.currentShape;
            self.mode = 'Select';
            return;
        }
        self[canvasName] = new fabric.Canvas(canvasName, { stopContextMenu: true, enableRetinaScaling: false });
        const thiscanvas = self[canvasName];

        thiscanvas.clear();
        const imageEl = self.$document[0].querySelector('#roiImg');
        const canvasEl = self.$document[0].querySelector(`#${canvasName}`);

        const image = new fabric.Image(imageEl, {
            left: 0, top: 0, opacity: 1,
        });
        thiscanvas.containerClass = 'snapshot-wrapper';

        thiscanvas.setWidth('100%', { cssOnly: true });
        thiscanvas.setHeight('auto', { cssOnly: true });
        thiscanvas.selection = false;

        self.$timeout(() => {
            thiscanvas.setWidth(canvasEl.clientWidth, { backstoreOnly: true });
            thiscanvas.setHeight(canvasEl.clientWidth / (image.width / image.height), {
                backstoreOnly: true,
            });
            image.set({
                scaleX: thiscanvas.width / image.width,
                scaleY: thiscanvas.height / image.height,
                originX: 'left',
                originY: 'top',
            });
            if (self.shapes.length > 0) {
                self.shapes.forEach((shape) => {
                    const points = [];
                    shape.points.forEach((point) => {
                        points.push({ x: point[0] * thiscanvas.getWidth(), y: point[1] * thiscanvas.getHeight() });
                    });
                    const poly = new fabric.Polygon(points, {
                        id: shape.id,
                        alias: shape.alias,
                        saved: shape.saved,
                        opacity: 0.5,
                        selectable: true,
                    });
                    const valid = self.testValid(points);
                    const selectInd = _.findIndex(self.selectedROIs, (o) => o.id == shape.id);
                    if (selectInd == -1) {
                        if (!valid) {
                            poly.fill = 'red';
                        } else {
                            poly.fill = 'green';
                        }
                    } else {
                        poly.selectable = true;
                        if (!valid) {
                            poly.fill = 'orange';
                        } else {
                            poly.fill = 'green';
                        }
                    }
                    poly.lockRotation = true;
                    poly.lockMovementX = true;
                    poly.lockMovementY = true;
                    poly.lockScalingY = true;
                    poly.lockScalingX = true;
                    poly.hasControls = false;
                    thiscanvas.add(poly);
                    thiscanvas.renderAll();
                });
            }
            thiscanvas.setBackgroundImage(image, thiscanvas.renderAll.bind(thiscanvas));
        }, 0);

        // thiscanvas.renderAll();

        let valid = true;

        thiscanvas.on('mouse:dblclick', (evt) => {
            if (self.mode === 'Edit' || self.mode === 'Add' && self.currentShape && self.currentShape.length > 2) {
                self.mode = 'Select';
                const points = self.currentShape.get('points');
                points.pop();
                points.pop();
                thiscanvas.remove(self.currentShape);
                const poly = new fabric.Polygon(points, {
                    // fill: 'green',
                    id: self.currentShape.id,
                    alias: self.currentShape.alias,
                    saved: false,
                    opacity: 0.5,
                    selectable: true,
                });
                self.currentShape = poly;
                valid = self.testValid(self.currentShape.get('points'));
                if (!valid) {
                    self.currentShape.fill = 'red';
                } else {
                    self.currentShape.fill = 'green';
                }
                self.currentShape.lockRotation = true;
                self.currentShape.lockMovementX = true;
                self.currentShape.lockMovementY = true;
                self.currentShape.lockScalingY = true;
                self.currentShape.lockScalingX = true;
                self.currentShape.hasControls = false;
                thiscanvas.add(self.currentShape);
                const ind = _.findIndex(self.shapes, (o) => o.id == self.currentShape.id);
                const roi = [];
                points.forEach((point) => {
                    roi.push([point.x / thiscanvas.getWidth(), point.y / thiscanvas.getHeight()]);
                });
                if (ind == -1) {
                    self.shapes.push({
                        id: self.currentShape.id,
                        alias: self.currentShape.alias,
                        saved: self.currentShape.saved,
                        points: roi,
                        valid,
                        // height: thiscanvas.getHeight(),
                        // width: thiscanvas.getWidth()
                    });
                } else {
                    self.shapes[ind].points = roi;
                }
            } else if (self.currentShape) {
                const ind = _.findIndex(self.shapes, (o) => o.id == self.currentShape.id);
                if (ind !== -1) {
                    self.shapes.splice(ind, 1);
                }
                // self.mode = "Add";
            }
            self.currentShape = null;
        });

        thiscanvas.on('mouse:move', (evt) => {
            const pos = thiscanvas.getPointer(evt.e);
            if (self.mode === 'Edit' && self.currentShape) {
                const points = self.currentShape.get('points');
                points.pop();
                points.push({ x: pos.x, y: pos.y });
                thiscanvas.remove(self.currentShape);
                const poly = new fabric.Polygon(points, {
                    // fill: "green",
                    id: self.currentShape.id,
                    alias: self.currentShape.alias,
                    saved: false,
                    stroke: 'black',
                    strokeWidth: 3,
                    opacity: 0.5,
                    selectable: false,
                });
                self.currentShape = poly;
                // console.time("validMove")
                valid = self.testValid(self.currentShape.get('points'));
                // console.timeEnd("validMove")
                if (!valid) {
                    self.currentShape.fill = 'red';
                } else {
                    self.currentShape.fill = 'green';
                }
                thiscanvas.add(self.currentShape);
                thiscanvas.renderAll();
            }
        });

        thiscanvas.on('mouse:down', (evt) => {
            const pos = thiscanvas.getPointer(evt.e);
            if (self.mode === 'Add') {
                const polygon = new fabric.Polygon([
                    {
                        x: pos.x, y: pos.y,
                    }, {
                        x: pos.x + 0.5,
                        y: pos.y + 0.5,
                    },
                ], {
                    id: uuid(),
                    alias: `Alias ${self.shapes.length + 1}`,
                    saved: false,
                    fill: 'green',
                    opacity: 0.5,
                    selectable: false,
                });
                self.currentShape = polygon;
                thiscanvas.add(self.currentShape);
                self.mode = 'Edit';
            } else if (self.mode === 'Edit' && self.currentShape && self.currentShape.type === 'polygon') {
                const points = self.currentShape.get('points');
                points.push({
                    x: pos.x, y: pos.y,
                });
                thiscanvas.remove(self.currentShape);
                const poly = new fabric.Polygon(points, {
                    // fill: 'green',
                    id: self.currentShape.id,
                    alias: self.currentShape.alias,
                    saved: false,
                    opacity: 0.5,
                    selectable: false,
                });
                self.currentShape = poly;
                valid = self.testValid(self.currentShape.get('points'));
                if (!valid) {
                    self.currentShape.fill = 'red';
                } else {
                    self.currentShape.fill = 'green';
                }
                thiscanvas.add(self.currentShape);
            } else if (self.mode == 'Select') {
                self.currentShape = thiscanvas.getActiveObject();
            }
        });

        const keyupHandler = function (e) {
            const keyCanvas = self[canvasName];
            if (e.keyCode === 27) {
                if (self.mode === 'Edit' && self.currentShape && self.currentShape.get('points').length > 3) {
                    self.mode = 'Select';
                    const points = self.currentShape.get('points');
                    points.pop();
                    keyCanvas.remove(self.currentShape);
                    const poly = new fabric.Polygon(points, {
                        // fill: 'green',
                        id: self.currentShape.id,
                        saved: false,
                        alias: self.currentShape.alias,
                        opacity: 0.5,
                        selectable: true,
                    });
                    self.currentShape = poly;
                    valid = self.testValid(self.currentShape.get('points'));
                    if (!valid) {
                        self.currentShape.fill = 'red';
                    } else {
                        self.currentShape.fill = 'green';
                    }
                    self.currentShape.lockRotation = true;
                    self.currentShape.lockMovementX = true;
                    self.currentShape.lockMovementY = true;
                    self.currentShape.lockScalingY = true;
                    self.currentShape.lockScalingX = true;
                    self.currentShape.hasControls = false;
                    keyCanvas.add(self.currentShape);
                    const ind = _.findIndex(self.shapes, (o) => o.id == self.currentShape.id);
                    const roi = [];
                    points.forEach((point) => {
                        roi.push([point.x / thiscanvas.getWidth(), point.y / thiscanvas.getHeight()]);
                    });
                    if (ind == -1) {
                        self.shapes.push({
                            id: self.currentShape.id,
                            alias: self.currentShape.alias,
                            points: roi,
                            valid,
                            saved: self.currentShape.saved,
                            // height: keyCanvas.getHeight(),
                            // width: keyCanvas.getWidth()
                        });
                    } else {
                        self.shapes[ind].points = roi;
                    }
                } else if (self.mode === 'Add') {
                    self.mode = 'Select';
                } else if (self.mode === 'Edit' && self.currentShape.get('points').length <= 3) {
                    self.mode = 'Select';
                    keyCanvas.remove(self.currentShape);
                    const ind = _.findIndex(self.shapes, (o) => o.id == self.currentShape.id);
                    if (ind !== -1) {
                        self.shapes.splice(ind, 1);
                    }
                } else if (self.currentShape) {
                    const ind = _.findIndex(self.shapes, (o) => o.id == self.currentShape.id);
                    if (ind !== -1) {
                        self.shapes.splice(ind, 1);
                    }
                    // self.mode = "Add";
                }
                self.currentShape = null;
            }
        };
        if (!self.listenerAdded) {
            fabric.util.addListener(fabric.document, 'keyup', keyupHandler);
            self.listenerAdded = true;
        }
    }

    updateAlias() {
        const self = this;
        const ind = _.findIndex(self.shapes, (o) => o.id === self.currentShape.id);
        self.currentShape.saved = false;
        if (ind !== -1) {
            self.shapes[ind].alias = self.currentShape.alias;
        }
    }

    testValid(testpoints) {
        const self = this;
        const points = _.cloneDeep(testpoints);
        points.push(points[0]);

        let valid = true;
        if (points.length > 3) {
            for (let i = 0; i <= points.length - 4; i++) {
                const testLine = [points[i], points[i + 1]];
                for (let k = i + 2; k < points.length - 1; k++) {
                    const testLine2 = [points[k], points[k + 1]];
                    const testBool = self.testIntersection(testLine[0], testLine[1], testLine2[0], testLine2[1]);
                    if (testBool) {
                        valid = false;
                        break;
                    }
                }
                if (!valid) {
                    break;
                }
            }
        }
        return valid;
    }

    testIntersection(b1, e1, b2, e2) {
        const det = (e1.x - b1.x) * (e2.y - b2.y) - (e2.x - b2.x) * (e1.y - b1.y);
        if (det === 0) {
            return false;
        }
        const lambda = ((e2.y - b2.y) * (e2.x - b1.x) + (b2.x - e2.x) * (e2.y - b1.y)) / det;
        const gamma = ((b1.y - e1.y) * (e2.x - b1.x) + (e1.x - b1.x) * (e2.y - b1.y)) / det;
        return (lambda > 0 && lambda < 1) && (gamma > 0 && gamma < 1);
    }

    // testeroonie(variable) {
    // let self = this;
    /// /console.log("VARIABVLE? ", self.resolution);
    /// /console.log("VARIABVLE2 ", self.group);
    // console.log(variable);
    // console.log(self.group);
    /// /TODO: Stop hacking this to work..
    /// /if(variable == "Minutes" && self.group < 5) {
    /// /console.log("HELLO1");
    /// /self.$timeout(() => {
    /// /self.group = 5;
    /// /}, 0);
    /// /self.$timeout(() => {
    /// /self.group = self.group*10;
    /// /}, 0);
    /// /self.$timeout(() => {
    /// /self.group = self.group/10;
    /// /}, 0);
    /// /} else if(variable == "Hours" && self.group < 60) {
    /// /console.log("HELLO?");
    /// ///self.$timeout(() => {
    /// /self.group = 60;
    /// ///}, 0);
    /// /} else if(variable == "Days" && self.group < 60*24) {
    /// /console.log("HELLO2");
    /// ///self.$timeout(() => {
    /// /self.group = 60*24;
    /// ///}, 0);
    /// /}
    // console.log(self.group);
    // if(variable == "Minutes") {
    // self.$timeout(() => {
    // self.group = self.group * 10;
    // }, 0);
    // self.$timeout(() => {
    // self.group = self.group / 10;
    // }, 0);
    // } else {
    // self.$timeout(() => {
    // self.group = self.group / 10;
    // }, 0);
    // self.$timeout(() => {
    // self.group = self.group * 10;
    // }, 0);
    // }
    // }

    downloadStats(task) {
        const self = this;
        const drawColumns = [];
        const timeseries = _.map(task.taskResults.stats.timestamps, (o) => self.moment.unix(o).format('DD-MMM-YYYY HH:mm:ss'));
        let column = timeseries;
        column.unshift('Alias');
        drawColumns.push(column);
        if (task.taskOptions.hasOwnProperty('rois')) {
            for (let j = 0; j < 3; j++) {
                for (const i in task.taskResults.stats.rois) {
                    let alias;
                    if (j == 0) {
                        alias = `${task.taskOptions.rois[i].alias}_Min`;
                    } else if (j == 1) {
                        alias = `${task.taskOptions.rois[i].alias}_Max`;
                    } else if (j == 2) {
                        alias = `${task.taskOptions.rois[i].alias}_Average`;
                    }
                    column = [];
                    const stat = task.taskResults.stats.rois[i];
                    column.push(alias);
                    for (const k in stat) {
                        column.push(stat[k][j]);
                    }
                    drawColumns.push(column);
                }
            }
        } else {
            // This is where there are no rois, but only the main heatmap
            for (let j = 0; j < 3; j++) {
                column = [];
                const stat = task.taskResults.stats.rois[0];
                if (j == 0) {
                    column.push('Global_Min');
                } else if (j == 1) {
                    column.push('Global_Max');
                } else {
                    column.push('Global_Average');
                }
                for (const k in stat) {
                    column.push(stat[k][j]);
                }
                drawColumns.push(column);
            }
        }
        const link = document.createElement('a');
        link.setAttribute('download', `${task.alias}.csv`);
        link.setAttribute('href', encodeURI(`data:text/csv;charset=utf-8,${drawColumns.join('\n')}`));
        link.click();
    }

    deleteHeatmap(hm) {
        const self = this;
        self.$http.delete(`/api/heatmaps/${hm._id}`).then((res) => {
            self.toastr.success('Successfully deleted heatmap', {
                preventOpenDuplicates: true,
            });
        });
    }

    deleteROI(shape) {
        const self = this;

        const ind = _.findIndex(self.shapes, (o) => o.id === shape.id);
        if (ind !== -1) {
            self.$http.patch(`/api/cameras/deleteROI/${self.currentSite._id}/${self.camera._id}`, { roi: self.shapes[ind] }).then((response) => {
                self.toastr.success('Heatmap deleted from camera settings', {
                    preventOpenDuplicates: true,
                });
                self.shapes[ind].saved = false;
                shape.saved = false;
                const selectInd = _.findIndex(self.selectedROIs, (o) => o.id == shape.id);
                if (selectInd !== -1) {
                    self.selectedROIs.splice(selectInd, 1);
                }
                const wind = _.findIndex(self.camera.camera.configuration.rois, (o) => o.id == shape.id);
                if (wind !== -1) {
                    self.$timeout(() => {
                        self.camera.camera.configuration.rois.splice(wind, 1);
                    }, 0);
                }
            });
        }
    }

    saveROI(shape) {
        const self = this;
        const ind = _.findIndex(self.shapes, (o) => o.id === shape.id);
        if (ind !== -1) {
            self.$http.patch(`/api/cameras/saveROI/${self.currentSite._id}/${self.camera._id}`, { roi: self.shapes[ind] }).then((response) => {
                if (response.data) {
                    if (response.data.edit) {
                        self.toastr.success('Heatmap saved to camera settings', {
                            preventOpenDuplicates: true,
                        });
                        shape.saved = true;
                        self.shapes[ind].saved = true;
                        const sind = _.findIndex(self.camera.camera.configuration.rois, (o) => o.id == shape.id);
                        if (sind !== -1) {
                            self.camera.camera.configuration.rois.splice(sind, 1, self.shapes[ind]);
                        }
                    } else if (response.data.save) {
                        self.toastr.success('Heatmap saved to camera settings', {
                            preventOpenDuplicates: true,
                        });
                        shape.saved = true;
                        self.shapes[ind].saved = true;
                        self.$timeout(() => {
                            self.camera.camera.configuration.rois.push(self.shapes[ind]);
                        }, 0);
                        self.selectedROIs.push(self.shapes[ind]);
                    }
                }
            });
        }
    }

    deleteTask(task) {
        const self = this;
        this.$http.delete(`api/tasks/deleteTask/${task._id}`).then((response) => {
            if (response) {
                self.toastr.success('Heatmap task deleted', {
                    preventOpenDuplicates: true,
                });
                // TODO: Display message
            }
        });
    }

    downloadROI(task) {
        const self = this;
        const canvasName = `statsROICanvas_${task._id}`;
        const thiscanvas = this[canvasName];
        thiscanvas.setAttribute('crossorigin', 'anonymous');
        const url = thiscanvas.toDataURL({ format: 'jpeg' });
        const link = document.createElement('a');
        link.setAttribute('download', `${task.alias}.jpeg`);
        link.setAttribute('href', url);
        link.click();
    }
}

export default angular.module('cameraViewerApp.heatmaps')
    .component('heatmaps', {
        template: require('./heatmaps.html'),
        controller: HeatmapsComponent,
        controllerAs: '$ctrl',
    })
    .name;
