import angularXEditable from 'angular-xeditable';

import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import angularMoment from 'angular-moment';
import NgTable from 'ng-table/bundles/ng-table'; // TODO figure out why this is needed
import uiSelect from 'ui-select';
import moment from 'moment';
import async from 'async-es';
import _ from 'lodash-es';
import * as xlsx from 'xlsx';
import routes from './zoneTemplate.routes';

export default angular.module('cameraViewerApp.zoneTemplate', [
    uiRouter,
    'sticky',
    uiSelect,
    'angularMoment'])
    .config(routes)
    .name;

require('./zoneTemplate.component');
