import _ from 'lodash';
import { v1 as uuid } from 'uuid';

export default class BatchSetupController {
    $state;
    $http;
    $scope;
    $uibModal;
    $uibModalInstance;
    Auth;

    /* @ngInject */
    constructor($uibModalInstance, Auth, $http, $state, $scope, $uibModal, $timeout, socket, currentSite, toastr, $window) {
        this.$uibModalInstance = $uibModalInstance;
        this.$window = $window;
        this.Auth = Auth;
        this.toastr = toastr;
        this.$state = $state;
        this.$http = $http;
        this.$scope = $scope;
        this.socket = socket;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;

        this.zoneEdit = [];
        this.headers = [];
        this.queryZones = [];

        this.currentSite = currentSite;
        this.cameraCount = 0;
    }

    $onDestroy() {
        const self = this;
        if (self.unitListenerClean) {
            self.socket.socket.removeListener('unit:setupSave', self.unitListenerClean);
        }
    }

    $onInit() {
        const self = this;
        self.$timeout(() => {
            const file = document.getElementById('csvFile');
            file.addEventListener('change', self.add.bind(self));
        }, 0);
        self.unitListenerClean = self.onReceiptOfUnitClean.bind(self);
        self.socket.socket.on('unit:setupSave', self.unitListenerClean);
    }

    onReceiptOfUnitClean(newUnit) {
        const self = this;
        if (newUnit.eventType === 'PollSetup') {
            self.cameraCount--;
            newUnit.availableCams.forEach((cam) => {
                // TODO: Change to some
                cam.profiles.some((prof) => {
                    const zone = _.find(self.queryZones, (o) => o.rtsp === prof.streamuri || self.removeAuthFromURL(o.rtsp) === prof.streamuri || o.rtsp === self.removeAuthFromURL(prof.streamuri));
                    if (zone) {
                        clearTimeout(zone.timeoutID);
                        if (cam.authenticationNeeded && cam.authenticationFailed && !zone.username) {
                            zone.status = 'authError';
                            zone._id = cam._id;
                            zone.zoneConfiguration = cam;
                        } else {
                            zone.status = 'gettingRef';
                            zone._id = cam._id;
                            zone.zoneConfiguration = cam;
                            zone.timeoutID = self.$window.setTimeout(self.timeoutZone.bind(self, zone), 5000);
                            if (!zone.mac_address) {
                                zone.mac_address = cam._id.slice(0, 17);
                            }
                            self.requestSetupReference(zone);
                        }
                        return true;
                    }
                });
            });
            if (self.cameraCount === 0) {
                self.queryZones.forEach((zone) => {
                    if (zone.status === 'querying') {
                        zone.status = 'validationFailed';
                        zone.reason = 'missingCam';
                        zone.validationFailedReasonFormatted = 'Could not find camera';
                    }
                });
            }
        } else if (newUnit.eventType === 'RequestSetupReference') {
            const zone = _.find(self.queryZones, (o) => o._id === newUnit.camera);
            if (zone) {
                clearTimeout(zone.timeoutID);
                self.$timeout(() => {
                    zone.status = 'present';
                    zone.referenceShot = newUnit.referenceShot;
                }, 0);
            }
        }
    }

    requestSetupReference(zone) {
        const self = this;
        if (zone.unit_mac) {
            self.$http.post(
                `/api/units/${zone.unit_mac}/requestReferenceShot`,
                {
                    camera: zone._id,
                    username: zone.username,
                    password: zone.password,
                },
            )
                .then(() => {});
        }
    }

    doLog() {
        const self = this;
        console.log('DEBUG:', self);
    }

    close() {
        const self = this;
        self.$uibModalInstance.dismiss();
    }

    add(ev) {
        const self = this;

        const f = ev.target.files[0];
        if (f) {
            const reader = new FileReader();
            reader.onload = (e) => {
                self.headers = [];
                self.queryZones = [];
                const data = e.target.result;
                const lines = data.split('\n');
                lines.splice(lines.length - 1, 1);

                // Headers should be rtsp_url,username,password,zone_alias then also ip_address,mac_address
                self.headers = lines.splice(0, 1)[0].split(',');

                // Find indexes for the necessary things;
                const unitSerialInd = self.headers.indexOf('unit_serial');
                const unitMacInd = self.headers.indexOf('unit_mac');
                const rtspInd = self.headers.indexOf('stream_url');
                const unameInd = self.headers.indexOf('username');
                const pwInd = self.headers.indexOf('password');
                const aliasInd = self.headers.indexOf('zone_alias');
                const ipInd = self.headers.indexOf('ip_address');
                const macInd = self.headers.indexOf('mac_address');
                if ([unitSerialInd, rtspInd, aliasInd].indexOf(-1) !== -1) {
                    let missingField = '';
                    if (unitSerialInd === -1) {
                        missingField += 'unit_serial';
                        if ([rtspInd, aliasInd].indexOf(-1) !== -1) {
                            missingField += ', ';
                        }
                    }
                    if (rtspInd === -1) {
                        missingField += 'stream_url';
                        if (aliasInd === -1) {
                            missingField += ', ';
                        }
                    }
                    if (aliasInd === -1) {
                        missingField += 'zone_alias';
                    }
                    self.toastr.warning(`The following field(s): ${missingField}`, 'Field(s) required');
                    return;
                }
                lines.forEach((line) => {
                    line = line.split(',');
                    const newZone = {
                        _id: uuid(),
                        rtsp: rtspInd !== -1 ? line[rtspInd] : undefined,
                        ip_address: ipInd !== -1 ? line[ipInd] : undefined,
                        alias: aliasInd !== -1 ? line[aliasInd] : undefined,
                        password: pwInd !== -1 ? line[pwInd] : undefined,
                        username: unameInd !== -1 ? line[unameInd] : undefined,
                        mac_address: macInd !== -1 ? line[macInd] : undefined,
                        unit_serial: unitSerialInd !== -1 ? line[unitSerialInd] : undefined,
                        unit_mac: unitMacInd !== -1 ? line[unitMacInd] : undefined,
                        status: 'idle',
                    };

                    // if(!newZone.rtsp || !newZone.alias || !newZone.unit_serial) {
                    // TODO: Show these are required
                    // }

                    // Get username and password
                    let password = pwInd !== -1 ? line[pwInd] : undefined;
                    let username = unameInd !== -1 ? line[unameInd] : undefined;

                    if (!username || !password) {
                        // We don't have uname and pw, let's attempt to get it from the url
                        const getAuth = self.getAuthFromURL(newZone.rtsp);
                        if (!username && getAuth.username) {
                            username = getAuth.username;
                        }
                        if (!password && getAuth.password) {
                            password = getAuth.password;
                        }
                    } else if (newZone.rtsp.indexOf('@') === -1 && username) {
                        // We have uname and pw, but not in url, let's add it there
                        let addAuth = '';
                        if (username) {
                            addAuth += `${username}`;
                            if (password) {
                                addAuth += `:${password}`;
                            }
                            addAuth += '@';
                        }
                        if (addAuth.length > 0) {
                            const startPos = newZone.rtsp.indexOf('://') + 3;
                            newZone.rtsp = newZone.rtsp.substring(0, startPos) + addAuth + newZone.rtsp.slice(startPos);
                        }
                    }

                    newZone.password = password;
                    newZone.username = username;
                    self.queryZones.push(newZone);
                });
            };

            reader.readAsText(f);
        }
    }

    // Returns Stream URL with authentication stripped (No matter whether authentication exists or not)
    removeAuthFromURL(url) {
        const startPos = url.indexOf('://') + 3;
        const endPos = url.indexOf('@');
        if (endPos === -1) {
            return url;
        }
        return url.substring(0, startPos) + url.slice(endPos + 1);
    }

    queryAll() {
        const self = this;
        if (self.queryZones.length > 0) {
            self.queryZones.forEach((zone) => {
                self.queryZone(zone);
            });
        } else {
            self.toastr.warning('No zone information available');
        }
    }

    finalSave() {
        const self = this;
        // DONE: Here we must build up the new zones and cameras within the required units..
        // Two options are available:
        // 1. Do everything on the frontend. This is the status quo, and how it is done with the site setup page
        // 2. Make a new route to the backend to add everything. This will send all the necessary zone info
        let breakEarly = false;
        const cloneSite = _.cloneDeep(self.currentSite);

        self.queryZones.some((zone) => {
            // TODO: Every necessary check that the zone is present, valid, and should be added
            if (zone.status === 'present' && zone.zoneConfiguration && zone.alias) {
                // DONE: Check for duplicate aliases
                // DONE: Add camera config to relevant unit.cameras array
                const unit = _.find(cloneSite.units, (o) => o.serial === zone.unit_serial);
                if (unit) {
                    // Search for the MAC here in the _id
                    const macRegex = /([0-9a-f]{2}:){5}([0-9a-f]{2})/ig;
                    const cam = _.find(unit.cameras, (o) => o._id === zone.zoneConfiguration._id);

                    if (cam && macRegex.test(cam._id)) {
                        // DONE: Duplication of camera _id
                        self.toastr.warning(`Camera linked to ${zone.alias} already assigned`, 'Camera already exists in site');
                        breakEarly = true;
                    } else {
                        cloneSite.zones.push({
                            alias: zone.alias,
                            unit: zone.unit_mac,
                            camera: zone.zoneConfiguration._id,
                        });
                        unit.cameras.push(zone.zoneConfiguration);
                    }
                } else {
                    // TODO: What to do here? Because it's a problem
                    self.toastr.warning(`Error with zone: ${zone.alias}`, `Unit: ${zone.unit_serial} not found in site`);
                    breakEarly = true;
                }
            } else {
                if (zone.status === 'idle') {
                    zone.status = 'validationFailed';
                    zone.validationFailedReason = 'notQueried';
                    zone.validationFailedReasonFormatted = 'Zone Not Queried';
                }
                if (!zone.zoneConfiguration) {
                    self.toastr.warning('Zone not present', `Zone: ${zone.alias} has not been found in unit query.`);
                } else {
                    self.toastr.warning('Incomplete zone', `Zone: ${zone.alias}. Please complete details or remove`);
                }
                breakEarly = true;
            }
            return breakEarly;
        });

        if (breakEarly) {
            return;
        }
        self.currentSite.zones = cloneSite.zones;
        self.currentSite.units = cloneSite.units;

        return self.$http.patch(`/api/sites/${self.currentSite._id}/initialize`, _.omit(self.currentSite, ['__v']))
            .then((response) => {
                if (response && response.status === 200) {
                    self.toastr.info('Site Saved Successfully', 'Operation Successful', {
                        preventOpenDuplicates: true,
                        allowHtml: true,
                        timeOut: 2000,
                        tapToDismiss: true,
                        progressBar: true,
                    });
                    self.$uibModalInstance.close();
                }
            })
            .catch((err) => {
                console.error('This is the error: ', err);
            });
    }

    fakeClick() {
        const self = this;
        self.$timeout(() => {
            const file = document.getElementById('csvFile');
            file.click();
        }, 0);
    }

    validateZoneInfo(zone) {
        const self = this;
        return new Promise((res, rej) => {
            // Test when unit MAC does not exist
            // Test when Duplicate alias loaded

            // DONE: Check unit MAC, if assigned to site, fine
            if (!zone.rtsp) {
                return rej({ reason: 'rtspMissing', reasonFormatted: 'Query URL required' });
            }
            if (zone.unit_serial) {
                const unitIndex = _.findIndex(self.currentSite.units, (o) => o.serial === zone.unit_serial);
                if (unitIndex === -1) {
                    return rej({ reason: 'unitMissing', reasonFormatted: 'Unit is not assigned to this site' });
                }
                zone.unit_mac = self.currentSite.units[unitIndex]._id;
            } else {
                return rej({ reason: 'unitMissing', reasonFormatted: 'Unit serial number required' });
            }
            return res();
        });
    }

    manualPoll(zone) {
        const self = this;
        if (!zone.username) {
            zone.username = self.getAuthFromURL(zone.rtsp).username;
        }
        if (!zone.password) {
            zone.password = self.getAuthFromURL(zone.rtsp).password;
        }
        if (!zone.unit_mac) {
            const unit = _.find(self.currentSite.units, (o) => o.serial === zone.unit_serial);
            if (unit) {
                zone.unit_mac = unit._id;
            }
        }
        if (zone.unit_mac) {
            self.$http.post(`api/units/${zone.unit_mac}/manualPoll`, { manualUrl: zone.rtsp })
                .then(() => {
                    self.cameraCount++;
                    zone.status = 'querying';
                    zone.timeoutID = self.$window.setTimeout(self.timeoutZone.bind(self, zone), 5000);
                })
                .catch((err) => {
                    if (err.status === 500) {
                        console.error('HTTP 500 Server Error', err.data);
                    } else if (err.status === 404) {
                        console.error('HTTP 404 Not Found', err.data);
                    }
                });
        }
    }

    timeoutZone(zone) {
        const self = this;
        self.cameraCount++;
        if (zone.status === 'querying') {
            zone.status = 'validationFailed';
            zone.reason = 'missingCam';
            zone.validationFailedReasonFormatted = 'Query Timed Out';
        }
    }

    queryZone(zone) {
        const self = this;
        self.zoneEdit[zone._id] = false;
        zone.status = 'idle';
        self.validateZoneInfo(zone)
            .then(() => {
                self.manualPoll(zone);
            })
            .catch((err) => {
                // DONE: There was something wrong, so let's mark the zone as such
                zone.status = 'validationFailed';
                zone.validationFailedReason = err.reason;
                zone.validationFailedReasonFormatted = err.reasonFormatted;
            });
    }

    addZone() {
        const self = this;
        const zone = {
            _id: uuid(),
            rtsp: undefined,
            username: undefined,
            password: undefined,
            alias: 'Unnamed Zone',
            ip_address: undefined,
            mac_address: undefined,
            unit_serial: undefined,
            unit_mac: undefined,
            status: 'idle',
        };
        self.$timeout(() => {
            self.queryZones.push(zone);
            self.zoneEdit[zone._id] = true;
        }, 0);
    }

    getAuthFromURL(url) {
        const returnObj = { password: undefined, username: undefined };
        const startPos = url.indexOf('://') + 3;
        const endPos = url.indexOf('@');

        if (endPos === -1) {
            return returnObj;
        }

        const authDetails = url.slice(startPos, endPos)
            .split(':');
        if (authDetails[0].length > 0) {
            returnObj.username = authDetails[0];
        }
        if (authDetails.length === 2 && authDetails[0].length > 0) {
            returnObj.password = authDetails[1];
        }

        return returnObj;
    }
}

// Possible zone statuses:
// 1. querying
// 2. present
// 3. missing
// 4. validationFailed
// ?5. validating

// Steps:
// Step 1: Upload CSV and Display, query
// Step 2: Show whether camera is present, request and display reference shot
// Step 3: User verifies every camera and locks it in
// Step 4: User saves, we build up the site definition and then initialize the site with the new cameras
//
// Important TODOs:
// DONE: Send request reference shots when camera info comes in from the socket..
// DONE: Count cameras to show whether cameras are present or not
// DONE: Add spinner for "querying" zones
// DONE: Check whether unit MAC is assigned to this site, and whether zone MAC/IP is not already used..
// DONE: Check that there aren't camera duplicates
// DONE: Make it so that you can edit, add, and remove cameras from the batch setup (inputs, etc.)
// TODO: Give users an option of which zones to add before the final step.. (ng-confirm are you sure, remove any that have errored?)
// DONE: Build up a siteDefinition as you get info from units..
//              eg: If the unit does not already exist, add to the definition,
//              THEN add the camera to the zone/unit as it comes in, or when the user verifies?
//              MAYBE Populate the unit definition throughout, and then on final save we do the final binding to the zones, and add everything to the site..
// TODO: Maybe add a new route to add new zones to an existing site..
// TODO: What should we do when other people are also doing a site setup??
// TODO: Camera MAC (Optional...)
// DONE: Get username and password from url
// DONE: Handle Auth Issues
//
//
// Optimizations:
// DONE: Style upload CSV button
// TODO: Style spinner to better fit the design
// TODO: Style status area to better display everything
// TODO: Export final file to csv
// TODO: Fill in missing data as it comes in from the unit
//
