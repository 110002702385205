import angular from 'angular';
import _ from 'lodash';

export class UserSettingsComponent {
    user = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    };
    errors = {
        other: undefined,
    };
    message = '';
    submitted = false;
    Auth;

    /* @ngInject */
    constructor(Auth, $sce, $http, $ngConfirm, toastr, socket, $rootScope, NgTableParams) {
        this.Auth = Auth;
        this.$sce = $sce;
        this.$http = $http;
        this.toastr = toastr;
        this.socket = socket;
        this.$ngConfirm = $ngConfirm;
        this.$rootScope = $rootScope;
        this.NgTableParams = NgTableParams;
        this.passwordValid = false;
        this.currentRooms = [];
    }

    $onDestroy() {
        const self = this;
        if (self.userEventListener) {
            self.socket.socket.removeListener('user:save', self.userEventListener);
        }
        if (self.currentRooms.length > 0) {
            self.currentRooms.forEach((room) => {
                self.socket.leaveRoom(room);
            });
        }
    }

    $onInit() {
        const self = this;

        self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().accountId}:${self.Auth.getCurrentUserSync()._id}:users`);
        self.currentRooms.push(`${self.Auth.getCurrentAccountSync().accountId}:${self.Auth.getCurrentUserSync()._id}:users`);

        self.userEventListener = self.onReceiptOfUpdate.bind(self);

        self.socket.socket.on('user:save', self.userEventListener);

        self.cols = [
            {
                field: 'name',
                title: 'Name',
                sortable: 'name',
                show: true,
                getValue: self.handleValue.bind(self),
            },
            {
                field: 'hidden',
                title: 'Visibility',
                show: true,
                getValue: self.handleValue.bind(self),
            },
            {
                field: 'favourite',
                title: 'Favourite',
                show: true,
                getValue: self.handleValue.bind(self),
            },
        ];

        self.tableParams = new self.NgTableParams({
            page: 1, // start with first page
            count: 10, // count per page
            sorting: {
                name: 'asc', // initial sorting
            },
        }, {
            total: 0,
            getData() {
                return self.$http.get('/api/users/me')
                    .then((response) => {
                        self.thisUser = response.data;
                        self.accounts = self.thisUser.accounts;
                        self.telegramState = self.checkTelegramStatus(self.thisUser);
                        self.whatsappState = self.checkWhatsAppStatus(self.thisUser);
                        return self.thisUser.accounts;
                    })
                    .catch((err) => {
                        console.error('Error when retrieving user data:', err.data.err);
                    });
            },
        });

        self.tgCheckFn = () => {
            if (self.telegramState === 'complete') {
                console.log('Finished checking');
                clearInterval(self.tgCheck);
            }
            if (self.telegramState === 'pending') {
                console.log('Checking?');
                self.tableParams.reload();
            }
        };

        self.whatsappCheckFn = () => {
            if (self.whatsappState === 'complete') {
                console.log('Finished checking');
                clearInterval(self.whatsappCheck);
            }
            if (self.whatsappState === 'pending') {
                console.log('Checking?');
                self.tableParams.reload();
            }
        };
    }

    doLog() {
        console.log(this);
    }

    onReceiptOfUpdate() {
        const self = this;
        self.tableParams.reload();
    }

    handleValue(self, col, account) {
        let html = '';
        switch (col.field) {
        case 'favourite': {
            if (account[col.field] === true) {
                html += '<i ng-show="{{account.favourite}}" ng-click="$ctrl.toggleFavourite(account)" class="fa-solid fa-star toggle-icon" style="color: orange;"></i>';
            }
            html += '<i ng-hide="{{account.favourite}}" ng-click="$ctrl.toggleFavourite(account)" class="fa-solid fa-star toggle-icon" style="color: lightgrey;"></i>';
            return self.$sce.trustAsHtml(html);
        }
        case 'hidden': {
            if (account[col.field] === true) {
                html += '<i ng-show="{{account.hidden}}" ng-click="$ctrl.toggleHidden(account)" class="fa-solid fa-eye-slash toggle-icon" style="color: lightgrey;"></i>';
            }
            html += '<i ng-hide="{{account.hidden}}" ng-click="$ctrl.toggleHidden(account)" class="fa-solid fa-eye toggle-icon"></i>';
            return self.$sce.trustAsHtml(html);
        }
        case 'name': {
            html += '<div>{{account.name}}</div>';
            return self.$sce.trustAsHtml(html);
        }
        default:
            return '';
        }
    }

    changePassword(form) {
        this.submitted = true;

        if (form.$valid) {
            this.Auth.changePassword(this.user.oldPassword, this.user.newPassword)
                .then(() => {
                    this.message = 'Password successfully changed.';
                    this.toastr.info('Password successfully changed', 'Changed Password');
                })
                .catch(() => {
                    form.password.$setValidity('mongoose', false);
                    this.errors.other = 'Incorrect password';
                    this.message = '';
                });
        }
    }

    updateAccounts(account, msg) {
        const self = this;
        self.$http.put('/api/users/accUpdate', {
            account: {
                accountId: account.accountId,
                hidden: account.hidden,
                favourite: account.favourite,
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    self.toastr.success(`Account ${msg} updated`, {
                        preventOpenDuplicates: true,
                    });
                    self.$rootScope.$emit('accountsUpdated');
                }
                // console.log(response.data);
            });
    }

    toggleHidden(account) {
        account.hidden = !account.hidden;
        this.updateAccounts(account, 'visibility');
    }

    toggleFavourite(account) {
        account.favourite = !account.favourite;
        this.updateAccounts(account, 'favourite state');
    }

    checkTelegramStatus(user) {
        if (user.telegram) {
            if (user.telegram.enabled && !user.telegram.enrolled && user.telegram.token && !user.telegram.userId) {
                return 'pending';
            }
            if (user.telegram.enabled && user.telegram.enrolled && user.telegram.token?.length > 0 && user.telegram.userId?.length > 0) {
                return 'complete';
            }
        }
        return 'uninitialised';
    }

    initialiseTelegramProcess() {
        const self = this;

        self.$http.get('/api/users/telegram/setup')
            .then((res) => {
                if (res.data && res.data.qrImage && res.data.token) {
                    self.qrImageTelegram = res.data.qrImage;
                    self.telegramToken = res.data.token;
                    self.telegramUrl = res.data.url;
                    self.telegramState = 'pending';
                    self.tgCheck = setInterval(self.tgCheckFn, 1500);
                } else {
                    throw new Error('Setup failed');
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    completeTelegramProcess() {
        const self = this;
        self.tableParams.reload();
    }

    unlinkTelegram() {
        const self = this;
        self.$ngConfirm(
            {
                title: '<span style="text-align:center; display:flex; justify-content:center; font-weight:bold;">Unlink Telegram</span>',
                theme: 'light',
                animation: 'top',
                // scope: self.$scope,
                closeAnimation: 'bottom',
                content: 'Please confirm removal of Telegram account',
                escapeKey: true,
                backgroundDismiss: true,
                buttons: {
                    // long hand button definition
                    ok: {
                        text: 'Remove',
                        btnClass: 'btn-warning',
                        keys: ['enter'], // will trigger when enter is pressed
                        action() {
                            self.$http.get('/api/users/telegram/reset')
                                .then((res) => {
                                    if (res.data) {
                                        console.log('User unlinked!');
                                    } else {
                                        throw new Error('Reset failed');
                                    }
                                })
                                .catch((err) => {
                                    console.error(err);
                                });
                        },
                    },
                    close() {

                    },
                },
            },
        );
    }

    testTelegram() {
        const self = this;
        self.$http.get('/api/users/telegram/test')
            // .then((res) => {
            //     if (res.data) {
            //         console.log('User unlinked!');
            //     } else {
            //         throw new Error('Reset failed');
            //     }
            // })
            .catch((err) => {
                console.error(err);
            });
    }

    checkWhatsAppStatus(user) {
        if (user.whatsapp) {
            if (user.whatsapp.enabled && !user.whatsapp.enrolled && user.whatsapp.token && !user.whatsapp.waId) {
                return 'pending';
            }
            if (user.whatsapp.enabled && user.whatsapp.enrolled && user.whatsapp.token?.length > 0 && user.whatsapp.waId?.length > 0) {
                return 'complete';
            }
        }
        return 'uninitialised';
    }

    initialiseWhatsAppProcess() {
        const self = this;

        self.$http.get('/api/users/whatsapp/setup')
            .then((res) => {
                if (res.data && res.data.qrImage && res.data.token) {
                    self.qrImageWhatsApp = res.data.qrImage;
                    self.whatsappToken = res.data.token;
                    self.whatsappUrl = res.data.url;
                    self.whatsappState = 'pending';
                    self.whatsappCheck = setInterval(self.whatsAppCheckFn, 1500);
                } else {
                    throw new Error('Setup failed');
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    completeWhatsAppProcess() {
        const self = this;
        self.tableParams.reload();
    }

    unlinkWhatsApp() {
        const self = this;
        self.$ngConfirm(
            {
                title: '<span style="text-align:center; display:flex; justify-content:center; font-weight:bold;">Unlink WhatsApp</span>',
                theme: 'light',
                animation: 'top',
                // scope: self.$scope,
                closeAnimation: 'bottom',
                content: 'Please confirm removal of WhatsApp account',
                escapeKey: true,
                backgroundDismiss: true,
                buttons: {
                    // long hand button definition
                    ok: {
                        text: 'Remove',
                        btnClass: 'btn-warning',
                        keys: ['enter'], // will trigger when enter is pressed
                        action() {
                            self.$http.get('/api/users/whatsapp/reset')
                                .then((res) => {
                                    if (res.data) {
                                        console.log('User unlinked!');
                                    } else {
                                        throw new Error('Reset failed');
                                    }
                                })
                                .catch((err) => {
                                    console.error(err);
                                });
                        },
                    },
                    close() {

                    },
                },
            },
        );
    }

    testWhatsApp() {
        const self = this;
        self.$http.get('/api/users/whatsapp/test')
            // .then((res) => {
            //     if (res.data) {
            //         console.log('User unlinked!');
            //     } else {
            //         throw new Error('Reset failed');
            //     }
            // })
            .catch((err) => {
                console.error(err);
            });
    }

    openLink(url) {
        window.open(url, '_blank');
    }
}

export default angular.module('cameraViewerApp.userSettings')
    .component('userSettings', {
        template: require('./userSettings.html'),
        controller: UserSettingsComponent,
        controllerAs: '$ctrl',
    })
    .name;
