/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import _ from 'lodash';
import angular from 'angular';
import exceljs from 'exceljs';
import FileSaver from 'file-saver';
import { calculateTCO } from '../../../server/components/utilities/s3cost';

const REKOGNITION_COST = 0.001;

export class UsageDashboardComponent {
    $http;
    $scope;
    $state;
    availableSites = [];
    canvases = {};
    moment;
    self;
    heatmapsOpen = {};
    NgTableParams;
    toastr;
    $sce;
    quickReportHour = [
        'Last 24 Hours',
        'Last 7 Days',
        'Last 31 Days',
        'Today',
        'This Week',
        'This Month',
    ];
    quickReportMonth = ['Last Month', 'This Month'];
    startTime;
    startDate;
    endTime;
    endDate;
    tsStart;
    tsEnd;
    totals;

    /* @ngInject */
    constructor(
        $state,
        $window,
        $document,
        $timeout,
        $sce,
        NgTableParams,
        $rootScope,
        moment,
        $scope,
        $http,
        socket,
        Auth,
        toastr,
        $uibModal
    ) {
        this.$state = $state;
        this.$window = $window;
        this.$document = $document;
        this.$timeout = $timeout;
        this.$sce = $sce;
        this.NgTableParams = NgTableParams;
        this.$rootScope = $rootScope;
        this.moment = moment;
        this.timezoneOffset = new Date().getTimezoneOffset();
        this.$scope = $scope;
        this.$http = $http;
        this.socket = socket;
        this.Auth = Auth;
        this.toastr = toastr;
        this.$uibModal = $uibModal;
        this.showCharts = true;
        this.showBilling = Auth.hasUserPrivilegeSync('billing');

        this.snapFiltered = false;
        this.frFiltered = false;
        this.lprFiltered = false;

        this.detailsGathered = false;

        this.summaryTableData = [];
        this.summaryTableOpen = false;
        this.summaryQueried = false;
        this.isColumnsCollapsed = true;

        this.showQuery = false;

        this.accountDetails = {};
        this.siteDetails = {};
        this.zoneDetails = {};

        this.charts = {};

        this.resolutionOptions = [
            { alias: 'Hourly', type: 'hour' },
            { alias: 'Daily', type: 'day' },
            { alias: 'Monthly', type: 'month' },
        ];

        this.resInd = 2;
        this.selectedRes = this.resolutionOptions[this.resInd];
        this.chosenResolution = this.resolutionOptions[this.resInd].type;
        this.formattedRes = this.resolutionOptions[this.resInd].alias;

        this.typeInd = 1;
        this.typeOptions = [
            { alias: 'Account', type: 'account' },
            { alias: 'Site', type: 'site' },
            { alias: 'Zone', type: 'zone' },
        ];
        this.grouping = this.typeOptions[this.typeInd].type;
        this.groupingSelect = this.grouping;

        this.endTime = moment().startOf('hour').toDate();
        this.endDate = moment().startOf('day').toDate();

        this.endFormat = this.moment(this.endDate)
            .hours(this.endTime.getHours())
            .minutes(this.endTime.getMinutes())
            .format('YYYY-MM-DD HH:mm');

        this.startTime = moment().startOf('hour').subtract(1, 'days').toDate();
        this.startDate = moment().startOf('day').subtract(1, 'days').toDate();

        this.startFormat = this.moment(this.startDate)
            .hours(this.startTime.getHours())
            .minutes(this.startTime.getMinutes())
            .format('YYYY-MM-DD HH:mm');

        this.totals = {
            snapshots: 0,
            snapshotCost: 0,
            faceRecognitions: 0,
            faceRecognitionCost: 0,
            plateRecognitions: 0,
            plateRecognitionCost: 0,
        };
    }

    $onInit() {
        const self = this;
        self.tableParams = new self.NgTableParams(
            {
                page: 1,
                count: 10,
                sorting: {
                    snapshotNumTotal: 'desc',
                },
            },
            {
                total: 0,
                getData(params) {
                    const order = params.sorting();
                    if (order) {
                        const key = Object.keys(order)[0];
                        self.summaryTableData.sort((a, b) => {
                            if (typeof a[key] === 'number') {
                                if (order[key] == 'asc') {
                                    return a[key] - b[key];
                                }
                                if (order[key] == 'desc') {
                                    return b[key] - a[key];
                                }
                            } else if (typeof a[key] === 'string') {
                                if (order[key] == 'desc') {
                                    return a[key].localeCompare(b[key]);
                                }
                                return -1 * a[key].localeCompare(b[key]);
                            }
                        });
                    }

                    params.total(self.summaryTableData.length);
                    const returnData = self.summaryTableData.slice(
                        (params.page() - 1) * params.count(),
                        (params.page() - 1) * params.count() + params.count()
                    );
                    return returnData;
                },
            }
        );

        self.Auth.getCurrentUserSync().accounts.forEach((acc) => {
            self.accountDetails[acc.accountId] = { alias: acc.name, zoneCount: 0, siteCount: 0 };
        });

        self.cols = [
            {
                title: 'Account',
                field: 'accountAlias',
                show: true,
                sortable: 'accountAlias',
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Site',
                field: 'siteAlias',
                show: true,
                sortable: 'siteAlias',
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Zone',
                field: 'zoneAlias',
                show: true,
                sortable: 'zoneAlias',
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Snapshot Total',
                field: 'snapshotNumTotal',
                show: true,
                sortable: 'snapshotNumTotal',
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Snapshot Avg',
                field: 'snapshotNumAvg',
                show: true,
                sortable: 'snapshotNumAvg',
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Snapshot Data Usage Total',
                field: 'snapshotUsageTotal',
                show: true,
                sortable: 'snapshotUsageTotal',
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Snapshot Data Usage Avg',
                field: 'snapshotUsageAvg',
                show: true,
                sortable: 'snapshotUsageAvg',
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'FR Credits Total',
                field: 'frCreditsTotal',
                show: true,
                sortable: 'frCreditsTotal',
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'FR Credits Avg',
                field: 'frCreditsAvg',
                show: true,
                sortable: 'frCreditsAvg',
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'LPR Credits Total',
                field: 'lprCreditsTotal',
                show: true,
                sortable: 'lprCreditsTotal',
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'LPR Credits Avg',
                field: 'lprCreditsAvg',
                show: true,
                sortable: 'lprCreditsAvg',
                getValue: self.handleDisplay.bind(this),
            },
            {
                title: 'Time Period',
                field: 'nPeriods',
                show: true,
                sortable: 'nPeriods',
                getValue: self.handleDisplay.bind(this),
            },
        ];

        self.selectedColumns = [
            'Account',
            'Site',
            'Zone',
            'Snapshot Total',
            'Snapshot Avg',
            'Snapshot Data Usage Total',
            'Snapshot Data Usage Avg',
            'FR Credits Total',
            'FR Credits Avg',
            'LPR Credits Total',
            'LPR Credits Avg',
            'Time Period',
        ];

        if (self.showBilling) {
            const snapshotCostColumns = [
                {
                    title: 'Storage Cost Total',
                    field: 'storageCostTotal',
                    show: true,
                    sortable: 'storageCostTotal',
                    getValue: self.handleDisplay.bind(this),
                },
                {
                    title: 'Storage Cost Avg',
                    field: 'storageCostAvg',
                    show: true,
                    sortable: 'storageCostAvg',
                    getValue: self.handleDisplay.bind(this),
                },
            ];
            const frCostColumns = [
                {
                    title: 'FR Cost Total',
                    field: 'frCostTotal',
                    show: true,
                    sortable: 'frCostTotal',
                    getValue: self.handleDisplay.bind(this),
                },
                {
                    title: 'FR Cost Avg',
                    field: 'frCostAvg',
                    show: true,
                    sortable: 'frCostAvg',
                    getValue: self.handleDisplay.bind(this),
                },
            ];
            self.cols.splice(5, 0, ...snapshotCostColumns);
            self.cols.splice(11, 0, ...frCostColumns);
            self.selectedColumns.splice(5, 0, ...['Storage Cost Total', 'Storage Cost Avg']);
            self.selectedColumns.splice(11, 0, ...['FR Cost Total', 'FR Cost Avg']);
        }

        self.drawDonuts();
        self.$window.onresize = function (h, a, d) {
            let width = Math.floor(document.getElementById('snapshotNumDiv')?.clientWidth);
            if (width === 0 || !self.showCharts) width = self.$window.innerWidth;
            if (self.$window.innerWidth > 600) {
                // Desktop View
                width /= 3;
            }
            if (_.get(self.charts, ['snapAccChart', 'chart'])) {
                self.charts.snapAccChart.chart.resize({ width });
            }
            if (_.get(self.charts, ['frAccChart', 'chart'])) {
                self.charts.frAccChart.chart.resize({ width });
            }
            if (_.get(self.charts, ['lprAccChart', 'chart'])) {
                self.charts.lprAccChart.chart.resize({ width });
            }
            if (_.get(self.charts, ['snapSiteChart', 'chart'])) {
                self.charts.snapSiteChart.chart.resize({ width });
            }
            if (_.get(self.charts, ['frSiteChart', 'chart'])) {
                self.charts.frSiteChart.chart.resize({ width });
            }
            if (_.get(self.charts, ['lprSiteChart', 'chart'])) {
                self.charts.lprSiteChart.chart.resize({ width });
            }
            if (_.get(self.charts, ['snapZoneChart', 'chart'])) {
                self.charts.snapZoneChart.chart.resize({ width });
            }
            if (_.get(self.charts, ['frZoneChart', 'chart'])) {
                self.charts.frZoneChart.chart.resize({ width });
            }
            if (_.get(self.charts, ['lprZoneChart', 'chart'])) {
                self.charts.lprZoneChart.chart.resize({ width });
            }
        };
    }

    handleColumns() {
        const self = this;
        if (self.grouping == 'account') {
            const siteInd = _.findIndex(self.selectedColumns, (o) => o == 'Site');
            if (siteInd !== -1) {
                self.selectedColumns.splice(siteInd, 1);
                self.cols.splice(siteInd, 1);
            }
            const zoneInd = _.findIndex(self.selectedColumns, (o) => o == 'Zone');
            if (zoneInd !== -1) {
                self.selectedColumns.splice(zoneInd, 1);
                self.cols.splice(zoneInd, 1);
            }
        } else if (self.grouping == 'site') {
            const siteObj = {
                title: 'Site',
                field: 'siteAlias',
                show: true,
                sortable: 'siteAlias',
                getValue: self.handleDisplay.bind(this),
            };
            const siteInd = _.findIndex(self.selectedColumns, (o) => o == 'Site');
            if (siteInd == -1) {
                self.selectedColumns.splice(1, 0, 'Site');
                self.cols.splice(1, 0, siteObj);
            }
            const zoneInd = _.findIndex(self.selectedColumns, (o) => o == 'Zone');
            if (zoneInd !== -1) {
                self.selectedColumns.splice(zoneInd, 1);
                self.cols.splice(zoneInd, 1);
            }
        } else if (self.grouping == 'zone') {
            const siteObj = {
                title: 'Site',
                field: 'siteAlias',
                show: true,
                sortable: 'siteAlias',
                getValue: self.handleDisplay.bind(this),
            };
            const zoneObj = {
                title: 'Zone',
                field: 'zoneAlias',
                show: true,
                sortable: 'zoneAlias',
                getValue: self.handleDisplay.bind(this),
            };
            const siteInd = _.findIndex(self.selectedColumns, (o) => o == 'Site');
            if (siteInd == -1) {
                self.selectedColumns.splice(1, 0, 'Site');
                self.cols.splice(1, 0, siteObj);
            }
            const zoneInd = _.findIndex(self.selectedColumns, (o) => o == 'Zone');
            if (zoneInd == -1) {
                self.selectedColumns.splice(1, 0, 'Zone');
                self.cols.splice(1, 0, zoneObj);
            }
        }
    }

    gatherDetails(siteDetails) {
        const self = this;
        siteDetails.forEach((site) => {
            let zoneCount = 0;
            site.zones.forEach((zone) => {
                if (!self.zoneDetails[zone._id]) {
                    self.zoneDetails[zone._id] = {
                        alias: zone.alias,
                        site: site._id,
                        siteAlias: site.alias,
                        enabled: true,
                        account: site.accountId,
                        accountAlias: self.accountDetails[site.accountId].alias,
                    };
                }
                zoneCount++;
                // let currentU = _.find(site.units, o => {
                // return o._id == zone.unit;
                // });

                // if(currentU) {
                // let currentC = _.find(currentU.cameras, o => {
                // return o._id == zone.camera;
                // });
                // if(currentC && currentC.enabled) {
                // self.zoneDetails[zone._id].enabled = currentC.enabled;
                // zoneCount ++;
                // }
                // }
            });
            if (!self.siteDetails[site._id]) {
                self.siteDetails[site._id] = {
                    alias: site.alias,
                    zoneCount,
                    accountId: site.accountId,
                    accountAlias: self.accountDetails[site.accountId].alias,
                };
            }
            self.accountDetails[site.accountId].zoneCount += zoneCount;
            self.accountDetails[site.accountId].siteCount++;
        });
        self.detailsGathered = true;
    }

    formatTable(totals, grouping, startTime, endTime, timeGrouping) {
        const self = this;
        const resultsObj = [];

        let measurement = 'months';
        if (timeGrouping == 'day') {
            measurement = 'days';
        } else if (timeGrouping == 'hour') {
            measurement = 'hours';
        }

        const diff = Math.ceil(self.moment(endTime).diff(startTime, measurement, true));

        const totalResults = {
            snapshotUsageTotal: 0,
            snapshotNumTotal: 0,
            storageCostTotal: 0,
            frCreditsTotal: 0,
            frCostTotal: 0,
            lprCreditsTotal: 0,
            snapshotUsageAvg: 0,
            snapshotNumAvg: 0,
            storageCostAvg: 0,
            frCreditsAvg: 0,
            frCostAvg: 0,
            lprCreditsAvg: 0,
            zoneCount: 0,
            nPeriods: `${diff} ${measurement}`,
        };

        totals.forEach((tot) => {
            let go = true;
            let zoneAlias;
            let siteAlias;
            let accountAlias;
            let zoneCount = 0;
            if (grouping == 'account') {
                if (self.accountDetails[tot._id[grouping]]) {
                    accountAlias = self.accountDetails[tot._id[grouping]].alias;
                    zoneCount = self.accountDetails[tot._id[grouping]].zoneCount;
                } else {
                    go = false;
                }
            } else if (grouping == 'site') {
                if (self.siteDetails[tot._id[grouping]]) {
                    siteAlias = self.siteDetails[tot._id[grouping]].alias;
                    zoneCount = self.siteDetails[tot._id[grouping]].zoneCount;
                    accountAlias = self.siteDetails[tot._id[grouping]].accountAlias;
                } else {
                    go = false;
                }
            } else if (
                self.zoneDetails[tot._id[grouping]] &&
                self.zoneDetails[tot._id[grouping]].enabled
            ) {
                zoneAlias = self.zoneDetails[tot._id[grouping]].alias;
                siteAlias = self.zoneDetails[tot._id[grouping]].siteAlias;
                accountAlias = self.zoneDetails[tot._id[grouping]].accountAlias;
            } else {
                go = false;
            }

            if (go) {
                let ind = _.findIndex(resultsObj, (o) => o._id == tot._id[grouping]);

                if (ind == -1) {
                    resultsObj.push({
                        snapshotUsageTotal: 0,
                        snapshotNumTotal: 0,
                        storageCostTotal: 0,
                        frCreditsTotal: 0,
                        frCostTotal: 0,
                        lprCreditsTotal: 0,
                        snapshotUsageAvg: 0,
                        snapshotNumAvg: 0,
                        storageCostAvg: 0,
                        frCreditsAvg: 0,
                        frCostAvg: 0,
                        lprCreditsAvg: 0,
                        _id: tot._id[grouping],
                        zoneCount,
                        zoneAlias,
                        siteAlias,
                        nPeriods: `${diff} ${measurement}`,
                        accountAlias,
                    });
                    ind = _.findIndex(resultsObj, (o) => o._id == tot._id[grouping]);
                }
                const result = resultsObj[ind];
                if (tot._id.metric == 'Snapshot Usages') {
                    result.snapshotUsageTotal += tot.value / 1000000;
                    result.snapshotNumTotal += tot.n;
                    totalResults.snapshotUsageTotal += tot.value / 1000000;
                    totalResults.snapshotNumTotal += tot.n;
                    result.snapshotNumAvg += tot.n;
                    result.snapshotUsageAvg += tot.value / 1000000;
                    totalResults.snapshotNumAvg += tot.n;
                    totalResults.snapshotUsageAvg += tot.value / 1000000;
                } else if (tot._id.metric == 'FR Credits') {
                    result.frCreditsTotal += tot.value;
                    totalResults.frCreditsTotal += tot.value;
                    result.frCreditsAvg += tot.value;
                    totalResults.frCreditsAvg += tot.value;
                } else if (tot._id.metric == 'LPR Credits') {
                    result.lprCreditsTotal += tot.value;
                    totalResults.lprCreditsTotal += tot.value;
                    result.lprCreditsAvg += tot.value;
                    totalResults.lprCreditsAvg += tot.value;
                }
            }
        });

        totalResults.lprCreditsAvg /= diff;
        totalResults.frCreditsAvg /= diff;
        totalResults.frCostAvg = totalResults.frCreditsAvg * REKOGNITION_COST;
        totalResults.frCostTotal = totalResults.frCreditsTotal * REKOGNITION_COST;
        totalResults.snapshotUsageAvg /= diff;
        totalResults.snapshotNumAvg /= diff;
        totalResults.storageCostAvg = calculateTCO(
            totalResults.snapshotNumAvg,
            totalResults.snapshotUsageAvg / 1024
        );
        totalResults.storageCostTotal = calculateTCO(
            totalResults.snapshotNumTotal,
            totalResults.snapshotUsageTotal / 1024
        );

        resultsObj.forEach((result) => {
            result.lprCreditsAvg = Math.round((result.lprCreditsAvg / diff) * 100) / 100;
            result.frCreditsAvg = Math.round((result.frCreditsAvg / diff) * 100) / 100;
            result.frCostAvg = result.frCreditsAvg * REKOGNITION_COST;
            result.frCostTotal = result.frCreditsTotal * REKOGNITION_COST;
            result.snapshotUsageAvg = Math.round((result.snapshotUsageAvg / diff) * 100) / 100;
            result.snapshotTotal = Math.round(result.snapshotUsageTotal * 100) / 100;
            result.storageCostTotal = calculateTCO(
                result.snapshotNumTotal,
                result.snapshotUsageTotal / 1024
            );
            result.snapshotNumAvg = Math.round((result.snapshotNumAvg / diff) * 100) / 100;
            result.storageCostAvg = calculateTCO(
                result.snapshotNumAvg,
                result.snapshotUsageAvg / 1024
            );
        });
        return resultsObj;
    }

    redrawGraph(id, metric, level) {
        const self = this;
        const met = metric == 'Snapshot Usages' ? 'snap' : metric == 'FR Credits' ? 'fr' : 'lpr';
        let tsStart;
        this.startFormat = this.moment(this.startDate)
            .hours(this.startTime.getHours())
            .minutes(this.startTime.getMinutes())
            .format('YYYY-MM-DD HH:mm');
        if (self.startDate && self.startTime) {
            tsStart = +self
                .moment(self.startDate)
                .hours(self.startTime.getHours())
                .minutes(self.startTime.getMinutes());
        }
        let tsEnd;
        if (self.endDate && self.endTime) {
            tsEnd = +self
                .moment(self.endDate)
                .hours(self.endTime.getHours())
                .minutes(self.endTime.getMinutes());
        }
        this.endFormat = this.moment(this.endDate)
            .hours(this.endTime.getHours())
            .minutes(this.endTime.getMinutes())
            .format('YYYY-MM-DD HH:mm');

        let sortby;
        if (metric == 'Snapshot Usages') {
            sortby = 'n';
        }

        const zoneParams = {
            params: {
                startTime: tsStart,
                endTime: tsEnd,
                metrics: [metric],
                timezoneOffset: self.timezoneOffset,
                timeGroup: 'sum',
                sortby,
                limitAmount: 10,
                grouping: 'zone',
            },
        };

        if (level == 'account') {
            zoneParams.params.accFilter = id;
            self.$http
                .get('/api/metrics/', {
                    params: {
                        startTime: tsStart,
                        endTime: tsEnd,
                        metrics: [metric],
                        sortby,
                        accFilter: id,
                        timezoneOffset: self.timezoneOffset,
                        timeGroup: 'sum',
                        limitAmount: 10,
                        grouping: 'site',
                    },
                })
                .then((res) => {
                    let data;
                    data = self.calculateData(res.data.data, 'site', metric, false);
                    const unloadVar = [];
                    self.charts[`${met}SiteChart`].focus = undefined;
                    self.charts[`${met}SiteChart`].chart.focus();

                    const names = self.charts[`${met}SiteChart`].chart.data.names();
                    Object.keys(names).forEach((o) => {
                        if (!data.names[o]) {
                            unloadVar.push(o);
                        }
                    });

                    self.charts[`${met}SiteChart`].chart.data.names(data.names);
                    self.charts[`${met}SiteChart`].chart.load({
                        columns: data.data,
                    });
                    self.charts[`${met}SiteChart`].chart.unload(unloadVar);
                    self.charts[`${met}SiteChart`].totals = res.data.totals[metric];
                });
        } else if (level == 'site') {
            zoneParams.params.siteFilter = id;
        }
        if (level !== 'zone') {
            self.$http.get('/api/metrics/', zoneParams).then((res) => {
                let data;
                data = self.calculateData(res.data.data, 'zone', metric, false);
                const unloadVar = [];

                const names = self.charts[`${met}ZoneChart`].chart.data.names();
                Object.keys(names).forEach((o) => {
                    if (!data.names[o]) {
                        unloadVar.push(o);
                    }
                });

                self.charts[`${met}ZoneChart`].chart.data.names(data.names);
                self.charts[`${met}ZoneChart`].chart.load({
                    columns: data.data,
                });
                self.charts[`${met}ZoneChart`].chart.unload(unloadVar);
                self.charts[`${met}ZoneChart`].totals = res.data.totals[metric];
            });
        }
    }

    drawDonuts() {
        const self = this;
        self.snapFiltered = false;
        self.frFiltered = false;
        self.lprFiltered = false;

        this.startFormat = this.moment(this.startDate)
            .hours(this.startTime.getHours())
            .minutes(this.startTime.getMinutes())
            .format('YYYY-MM-DD HH:mm');
        if (self.startDate && self.startTime) {
            self.tsStart = +self
                .moment(self.startDate)
                .hours(self.startTime.getHours())
                .minutes(self.startTime.getMinutes());
        }

        this.endFormat = this.moment(this.endDate)
            .hours(this.endTime.getHours())
            .minutes(this.endTime.getMinutes())
            .format('YYYY-MM-DD HH:mm');
        if (self.endDate && self.endTime) {
            self.tsEnd = +self
                .moment(self.endDate)
                .hours(self.endTime.getHours())
                .minutes(self.endTime.getMinutes());
        }

        self.$http
            .get('/api/metrics/', {
                params: {
                    startTime: self.tsStart,
                    endTime: self.tsEnd,
                    metrics: ['Snapshot Usages', 'FR Credits', 'LPR Credits'],
                    sortby: 'n',
                    timezoneOffset: self.timezoneOffset,
                    timeGroup: 'summary',
                    grouping: 'account',
                },
            })
            .then((res) => {
                self.totals.snapshots = res.data.totals['Snapshot Usages'].n;
                self.totals.snapshotCost = calculateTCO(self.totals.snapshots);
                self.totals.faceRecognitions = res.data.totals['FR Credits'].value;
                self.totals.faceRecognitionCost = self.totals.faceRecognitions * REKOGNITION_COST;
                self.totals.plateRecognitions = res.data.totals['LPR Credits'].value;
            });

        self.$http
            .get('/api/metrics/', {
                params: {
                    startTime: self.tsStart,
                    endTime: self.tsEnd,
                    metrics: ['Snapshot Usages'],
                    sortby: 'n',
                    timezoneOffset: self.timezoneOffset,
                    timeGroup: 'sum',
                    limitAmount: 10,
                    grouping: 'account',
                },
            })
            .then((res) => {
                // TODO: THINK ABOUT HOW TO HANDLE THIS ONCE SITES CAN BE DELETED
                let width = Math.floor(document.getElementById('snapshotNumDiv')?.clientWidth);
                if (width === 0 || !self.showCharts) width = self.$window.innerWidth;
                if (self.$window.innerWidth > 600) {
                    width /= 3;
                }
                const metric = 'Snapshot Usages';
                const chart = 'snapAccChart';
                if (!self.detailsGathered) {
                    self.gatherDetails(res.data.siteDetails);
                }

                let data;
                data = self.calculateData(res.data.data, 'account', metric, false);

                if (self.charts[`${chart}`] && self.charts[`${chart}`].chart) {
                    const unloadVar = [];
                    const names = self.charts[`${chart}`].chart.data.names();
                    Object.keys(names).forEach((o) => {
                        if (!data.names[o]) {
                            unloadVar.push(o);
                        }
                    });

                    self.charts[`${chart}`].chart.data.names(data.names);
                    self.charts[`${chart}`].chart.load({
                        columns: data.data,
                    });

                    self.charts[`${chart}`].chart.unload(unloadVar);
                    self.charts[`${chart}`].chart.resize({
                        width,
                    });
                    self.charts[`${chart}`].totals = res.data.totals[metric];
                } else {
                    self.charts[`${chart}`] = {
                        chart: self.doGraph(`#${chart}`, data, width, metric, 'account'),
                        normalized: false,
                        grouping: 'account',
                        totals: res.data.totals[metric],
                    };
                }
            });

        self.$http
            .get('/api/metrics/', {
                params: {
                    startTime: self.tsStart,
                    endTime: self.tsEnd,
                    metrics: ['FR Credits'],
                    timezoneOffset: self.timezoneOffset,
                    timeGroup: 'sum',
                    limitAmount: 10,
                    grouping: 'account',
                },
            })
            .then((res) => {
                let width = Math.floor(document.getElementById('snapshotNumDiv')?.clientWidth);
                if (width === 0 || !self.showCharts) width = self.$window.innerWidth;
                if (self.$window.innerWidth > 600) {
                    width /= 3;
                }
                const metric = 'FR Credits';
                const chart = 'frAccChart';
                if (!self.detailsGathered) {
                    self.gatherDetails(res.data.siteDetails);
                }
                let data;
                data = self.calculateData(res.data.data, 'account', metric, false);

                if (self.charts[`${chart}`] && self.charts[`${chart}`].chart) {
                    const unloadVar = [];
                    const names = self.charts[`${chart}`].chart.data.names();
                    Object.keys(names).forEach((o) => {
                        if (!data.names[o]) {
                            unloadVar.push(o);
                        }
                    });

                    self.charts[`${chart}`].chart.data.names(data.names);
                    self.charts[`${chart}`].chart.load({
                        columns: data.data,
                    });

                    self.charts[`${chart}`].chart.unload(unloadVar);
                    self.charts[`${chart}`].chart.resize({
                        width,
                    });
                    self.charts[`${chart}`].totals = res.data.totals[metric];
                } else {
                    self.charts[`${chart}`] = {
                        chart: self.doGraph(`#${chart}`, data, width, metric, 'account'),
                        normalized: false,
                        grouping: 'account',
                        totals: res.data.totals[metric],
                    };
                }
            });

        self.$http
            .get('/api/metrics/', {
                params: {
                    startTime: self.tsStart,
                    endTime: self.tsEnd,
                    metrics: ['LPR Credits'],
                    timezoneOffset: self.timezoneOffset,
                    timeGroup: 'sum',
                    limitAmount: 10,
                    grouping: 'account',
                },
            })
            .then((res) => {
                let width = Math.floor(document.getElementById('snapshotNumDiv')?.clientWidth);
                if (width === 0 || !self.showCharts) width = self.$window.innerWidth;
                if (self.$window.innerWidth > 600) {
                    width /= 3;
                }
                const metric = 'LPR Credits';
                const chart = 'lprAccChart';
                if (!self.detailsGathered) {
                    self.gatherDetails(res.data.siteDetails);
                }
                let data;
                data = self.calculateData(res.data.data, 'account', metric, false);

                if (self.charts[`${chart}`] && self.charts[`${chart}`].chart) {
                    const unloadVar = [];
                    const names = self.charts[`${chart}`].chart.data.names();
                    Object.keys(names).forEach((o) => {
                        if (!data.names[o]) {
                            unloadVar.push(o);
                        }
                    });

                    self.charts[`${chart}`].chart.data.names(data.names);
                    self.charts[`${chart}`].chart.load({
                        columns: data.data,
                    });

                    self.charts[`${chart}`].chart.unload(unloadVar);
                    self.charts[`${chart}`].chart.resize({
                        width,
                    });
                    self.charts[`${chart}`].totals = res.data.totals[metric];
                } else {
                    self.charts[`${chart}`] = {
                        totals: res.data.totals[metric],
                        chart: self.doGraph(`#${chart}`, data, width, metric, 'account'),
                        normalized: false,
                        grouping: 'account',
                    };
                }
            });

        self.$http
            .get('/api/metrics/', {
                params: {
                    startTime: self.tsStart,
                    endTime: self.tsEnd,
                    metrics: ['Snapshot Usages'],
                    sortby: 'n',
                    timezoneOffset: self.timezoneOffset,
                    timeGroup: 'sum',
                    limitAmount: 10,
                    grouping: 'site',
                },
            })
            .then((res) => {
                let width = Math.floor(document.getElementById('snapshotNumDiv')?.clientWidth);
                if (width === 0 || !self.showCharts) width = self.$window.innerWidth;
                if (self.$window.innerWidth > 600) {
                    width /= 3;
                }
                const metric = 'Snapshot Usages';
                const chart = 'snapSiteChart';
                // TODO: THINK ABOUT HOW TO HANDLE THIS ONCE SITES CAN BE DELETED
                if (!self.detailsGathered) {
                    self.gatherDetails(res.data.siteDetails);
                }

                let data;
                data = self.calculateData(res.data.data, 'site', metric, false);

                if (self.charts[`${chart}`] && self.charts[`${chart}`].chart) {
                    const unloadVar = [];
                    const names = self.charts[`${chart}`].chart.data.names();
                    Object.keys(names).forEach((o) => {
                        if (!data.names[o]) {
                            unloadVar.push(o);
                        }
                    });

                    self.charts[`${chart}`].chart.data.names(data.names);
                    self.charts[`${chart}`].chart.load({
                        columns: data.data,
                    });

                    self.charts[`${chart}`].chart.unload(unloadVar);
                    self.charts[`${chart}`].chart.resize({
                        width,
                    });
                    self.charts[`${chart}`].totals = res.data.totals[metric];
                } else {
                    self.charts[`${chart}`] = {
                        totals: res.data.totals[metric],
                        chart: self.doGraph(`#${chart}`, data, width, metric, 'site'),
                        normalized: false,
                        grouping: 'site',
                    };
                }
            });
        self.$http
            .get('/api/metrics/', {
                params: {
                    startTime: self.tsStart,
                    endTime: self.tsEnd,
                    metrics: ['FR Credits'],
                    timezoneOffset: self.timezoneOffset,
                    timeGroup: 'sum',
                    limitAmount: 10,
                    grouping: 'site',
                },
            })
            .then((res) => {
                let width = Math.floor(document.getElementById('snapshotNumDiv')?.clientWidth);
                if (width === 0 || !self.showCharts) width = self.$window.innerWidth;
                if (self.$window.innerWidth > 600) {
                    width /= 3;
                }
                const metric = 'FR Credits';
                const chart = 'frSiteChart';
                if (!self.detailsGathered) {
                    self.gatherDetails(res.data.siteDetails);
                }

                let data;
                data = self.calculateData(res.data.data, 'site', metric, false);

                if (self.charts[`${chart}`] && self.charts[`${chart}`].chart) {
                    const unloadVar = [];
                    const names = self.charts[`${chart}`].chart.data.names();
                    Object.keys(names).forEach((o) => {
                        if (!data.names[o]) {
                            unloadVar.push(o);
                        }
                    });

                    self.charts[`${chart}`].chart.data.names(data.names);
                    self.charts[`${chart}`].chart.load({
                        columns: data.data,
                    });

                    self.charts[`${chart}`].chart.unload(unloadVar);
                    self.charts[`${chart}`].chart.resize({
                        width,
                    });
                    self.charts[`${chart}`].totals = res.data.totals[metric];
                } else {
                    self.charts[`${chart}`] = {
                        totals: res.data.totals[metric],
                        chart: self.doGraph(`#${chart}`, data, width, metric, 'site'),
                        normalized: false,
                        grouping: 'site',
                    };
                }
            });
        self.$http
            .get('/api/metrics/', {
                params: {
                    startTime: self.tsStart,
                    endTime: self.tsEnd,
                    metrics: ['LPR Credits'],
                    timezoneOffset: self.timezoneOffset,
                    timeGroup: 'sum',
                    limitAmount: 10,
                    grouping: 'site',
                },
            })
            .then((res) => {
                let width = Math.floor(document.getElementById('snapshotNumDiv')?.clientWidth);
                if (width === 0 || !self.showCharts) width = self.$window.innerWidth;
                if (self.$window.innerWidth > 600) {
                    width /= 3;
                }
                const metric = 'LPR Credits';
                const chart = 'lprSiteChart';
                if (!self.detailsGathered) {
                    self.gatherDetails(res.data.siteDetails);
                }

                let data;
                data = self.calculateData(res.data.data, 'site', metric, false);

                if (self.charts[`${chart}`] && self.charts[`${chart}`].chart) {
                    const unloadVar = [];
                    const names = self.charts[`${chart}`].chart.data.names();
                    Object.keys(names).forEach((o) => {
                        if (!data.names[o]) {
                            unloadVar.push(o);
                        }
                    });

                    self.charts[`${chart}`].chart.data.names(data.names);
                    self.charts[`${chart}`].chart.load({
                        columns: data.data,
                    });

                    self.charts[`${chart}`].chart.unload(unloadVar);
                    self.charts[`${chart}`].chart.resize({
                        width,
                    });
                    self.charts[`${chart}`].totals = res.data.totals[metric];
                } else {
                    self.charts[`${chart}`] = {
                        totals: res.data.totals[metric],
                        chart: self.doGraph(`#${chart}`, data, width, metric, 'site'),
                        normalized: false,
                        grouping: 'site',
                    };
                }
            });
        self.$http
            .get('/api/metrics/', {
                params: {
                    startTime: self.tsStart,
                    endTime: self.tsEnd,
                    metrics: ['Snapshot Usages'],
                    sortby: 'n',
                    timezoneOffset: self.timezoneOffset,
                    timeGroup: 'sum',
                    limitAmount: 10,
                    grouping: 'zone',
                },
            })
            .then((res) => {
                let width = Math.floor(document.getElementById('snapshotNumDiv')?.clientWidth);
                if (width === 0 || !self.showCharts) width = self.$window.innerWidth;
                if (self.$window.innerWidth > 600) {
                    width /= 3;
                }
                // TODO: THINK ABOUT HOW TO HANDLE THIS ONCE SITES CAN BE DELETED
                const metric = 'Snapshot Usages';
                const chart = 'snapZoneChart';
                if (!self.detailsGathered) {
                    self.gatherDetails(res.data.siteDetails);
                }

                let data;
                data = self.calculateData(res.data.data, 'zone', metric, false);

                if (self.charts[`${chart}`] && self.charts[`${chart}`].chart) {
                    const unloadVar = [];
                    const names = self.charts[`${chart}`].chart.data.names();
                    Object.keys(names).forEach((o) => {
                        if (!data.names[o]) {
                            unloadVar.push(o);
                        }
                    });

                    self.charts[`${chart}`].chart.data.names(data.names);
                    self.charts[`${chart}`].chart.load({
                        columns: data.data,
                    });

                    self.charts[`${chart}`].chart.unload(unloadVar);
                    self.charts[`${chart}`].chart.resize({
                        width,
                    });
                    self.charts[`${chart}`].totals = res.data.totals[metric];
                } else {
                    self.charts[`${chart}`] = {
                        totals: res.data.totals[metric],
                        chart: self.doGraph(`#${chart}`, data, width, metric, 'zone'),
                        normalized: false,
                        grouping: 'zone',
                    };
                }
            });

        self.$http
            .get('/api/metrics/', {
                params: {
                    startTime: self.tsStart,
                    endTime: self.tsEnd,
                    metrics: ['FR Credits'],
                    timezoneOffset: self.timezoneOffset,
                    timeGroup: 'sum',
                    limitAmount: 10,
                    grouping: 'zone',
                },
            })
            .then((res) => {
                let width = Math.floor(document.getElementById('snapshotNumDiv')?.clientWidth);
                if (width === 0 || !self.showCharts) width = self.$window.innerWidth;
                if (self.$window.innerWidth > 600) {
                    width /= 3;
                }
                const metric = 'FR Credits';
                const chart = 'frZoneChart';
                if (!self.detailsGathered) {
                    self.gatherDetails(res.data.siteDetails);
                }
                let data;
                data = self.calculateData(res.data.data, 'zone', metric, false);

                if (self.charts[`${chart}`] && self.charts[`${chart}`].chart) {
                    const unloadVar = [];
                    const names = self.charts[`${chart}`].chart.data.names();
                    Object.keys(names).forEach((o) => {
                        if (!data.names[o]) {
                            unloadVar.push(o);
                        }
                    });

                    self.charts[`${chart}`].chart.data.names(data.names);
                    self.charts[`${chart}`].chart.load({
                        columns: data.data,
                    });

                    self.charts[`${chart}`].chart.unload(unloadVar);
                    self.charts[`${chart}`].chart.resize({
                        width,
                    });
                    self.charts[`${chart}`].totals = res.data.totals[metric];
                } else {
                    self.charts[`${chart}`] = {
                        totals: res.data.totals[metric],
                        chart: self.doGraph(`#${chart}`, data, width, metric, 'zone'),
                        normalized: false,
                        grouping: 'zone',
                    };
                }
            });

        self.$http
            .get('/api/metrics/', {
                params: {
                    startTime: self.tsStart,
                    endTime: self.tsEnd,
                    metrics: ['LPR Credits'],
                    timezoneOffset: self.timezoneOffset,
                    timeGroup: 'sum',
                    limitAmount: 10,
                    grouping: 'zone',
                },
            })
            .then((res) => {
                let width = Math.floor(document.getElementById('snapshotNumDiv')?.clientWidth);
                if (width === 0 || !self.showCharts) width = self.$window.innerWidth;
                if (self.$window.innerWidth > 600) {
                    width /= 3;
                }
                const metric = 'LPR Credits';
                const chart = 'lprZoneChart';

                if (!self.detailsGathered) {
                    self.gatherDetails(res.data.siteDetails);
                }

                let data;
                data = self.calculateData(res.data.data, 'zone', metric, false);

                if (self.charts[`${chart}`] && self.charts[`${chart}`].chart) {
                    const unloadVar = [];
                    const names = self.charts[`${chart}`].chart.data.names();
                    Object.keys(names).forEach((o) => {
                        if (!data.names[o]) {
                            unloadVar.push(o);
                        }
                    });

                    self.charts[`${chart}`].chart.data.names(data.names);
                    self.charts[`${chart}`].chart.load({
                        columns: data.data,
                    });

                    self.charts[`${chart}`].chart.unload(unloadVar);
                    self.charts[`${chart}`].chart.resize({
                        width,
                    });
                    self.charts[`${chart}`].totals = res.data.totals[metric];
                } else {
                    self.charts[`${chart}`] = {
                        totals: res.data.totals[metric],
                        chart: self.doGraph(`#${chart}`, data, width, metric, 'zone'),
                        normalized: false,
                        grouping: 'zone',
                    };
                }
            });
    }

    // redrawGraph(chart) {
    //     let self = this;
    //     let chartData = chart.chart.data();
    //     let values = [];
    //     let unloadArr = [];
    //     chartData.forEach((dataPoint) => {
    //         let singleVals = [];
    //         singleVals.push(dataPoint.id);
    //         dataPoint.values.forEach((value) => {
    //             if (chart.normalized && chart.grouping !== 'zone') {
    //                 if (self.zoneDetails[dataPoint.id].zoneCount) {
    //                     singleVals.push(
    //                         Math.round(
    //                             (value.value / self.zoneDetails[dataPoint.id].zoneCount) * 100
    //                         ) / 100
    //                     );
    //                 } else {
    //                     unloadArr.push(dataPoint.id);
    //                 }
    //             } else {
    //                 singleVals.push(
    //                     Math.round(value.value * self.zoneDetails[dataPoint.id].zoneCount)
    //                 );
    //             }
    //         });
    //         values.push(singleVals);
    //     });
    //     chart.chart.unload();
    //     chart.chart.load({ columns: values });
    // }

    calculateData(totals, grouping, metric, normalized) {
        const self = this;
        const returnVar = [];
        const nameVar = {};
        totals.forEach((tot) => {
            let alias;
            let enabled = true;
            if (grouping == 'zone' && self.zoneDetails[tot._id[grouping]]) {
                const zoneAlias = self.zoneDetails[tot._id[grouping]].alias;
                const { siteAlias } = self.zoneDetails[tot._id[grouping]];
                const { accountAlias } = self.zoneDetails[tot._id[grouping]];
                alias = `${zoneAlias} (${siteAlias} - ${accountAlias})`;
                enabled = self.zoneDetails[tot._id[grouping]].enabled;
            } else if (grouping == 'site' && self.siteDetails[tot._id[grouping]]) {
                const siteAlias = self.siteDetails[tot._id[grouping]].alias;
                const { accountAlias } = self.siteDetails[tot._id[grouping]];
                alias = `${siteAlias} (${accountAlias})`;
            } else if (grouping == 'account' && self.accountDetails[tot._id[grouping]]) {
                alias = self.accountDetails[tot._id[grouping]].alias;
            } else {
                enabled = false;
            }
            if (enabled) {
                let val = tot.value;

                if (metric == 'Snapshot Usages') {
                    val = tot.n;
                }

                if (tot._id.metric == metric) {
                    returnVar.push([tot._id[grouping], val]);
                    nameVar[tot._id[grouping]] = alias;
                }
            }
        });
        return { data: returnVar, names: nameVar };
    }

    doLog() {
        console.log(this);
    }

    handleDisplay(selfScope, col, o) {
        const html = '';
        switch (col.field) {
            case 'storageCostTotal':
            case 'storageCostAvg':
                return `$${o[col.field].toFixed(4)}`;
            case 'frCostTotal':
            case 'frCostAvg':
                return `$${o[col.field].toFixed(3)}`;
            case 'snapshotUsageTotal':
            case 'snapshotUsageAvg':
                return `${o[col.field].toFixed(2)}MiB`;
            default:
                return o[col.field];
        }
    }

    quickPick(option) {
        const self = this;
        const to = this.moment();
        const from = this.moment();
        switch (option) {
            case 'Last 24 Hours':
                self.startDate = self.startTime = from
                    .subtract(24, 'hours')
                    .startOf('hour')
                    .toDate();
                break;
            case 'Last 7 Days':
                self.startDate = self.startTime = from.subtract(7, 'days').startOf('hour').toDate();
                break;
            case 'Last 31 Days':
                self.startDate = self.startTime = from
                    .subtract(31, 'days')
                    .startOf('hour')
                    .toDate();
                break;
            case 'Today':
                self.endTime = self.endDate = to.add(1, 'days').startOf('day').toDate();
                self.startDate = self.startTime = from.startOf('day').toDate();
                break;
            case 'This Week':
                self.endTime = self.endDate = to.add(1, 'weeks').startOf('week').toDate();
                self.startDate = self.startTime = from.startOf('week').toDate();
                break;
            case 'Last Month':
                self.endTime = self.endDate = to.startOf('month').toDate();
                self.startDate = self.startTime = from
                    .subtract(1, 'months')
                    .startOf('month')
                    .toDate();
                break;
            case 'This Month':
                self.endTime = self.endDate = to.add(1, 'months').startOf('month').toDate();
                self.startDate = self.startTime = from.startOf('month').toDate();
                break;
            default:
        }
    }

    openDateTimePicker(index, picker, $event) {
        $event.preventDefault();
        $event.stopPropagation();
        this[picker] = !this[picker];
    }

    onColumnSelected($item, $model) {
        $item.show = true;
    }

    onColumnRemoved($item, $model) {
        $item.show = false;
    }

    resetGraphs(metric) {
        const self = this;
        const met = metric == 'Snapshot Usages' ? 'snap' : metric == 'FR Credits' ? 'fr' : 'lpr';

        self.charts[`${met}AccChart`].focus = undefined;
        self.charts[`${met}AccChart`].chart.focus();

        self.charts[`${met}SiteChart`].focus = undefined;
        self.charts[`${met}SiteChart`].chart.focus();

        self.charts[`${met}ZoneChart`].focus = undefined;
        self.charts[`${met}ZoneChart`].chart.focus();

        self[`${met}Filtered`] = false;

        self.redrawGraph(undefined, metric, 'account');
    }

    doGraph(id, data, width, metric, level) {
        const self = this;
        return c3.generate({
            bindto: id,
            transition: {
                duration: 200,
            },
            data: {
                columns: data.data,
                type: 'pie',
                names: data.names,
                onclick(d, i) {
                    if (level !== 'zone') {
                        if (metric == 'Snapshot Usages') {
                            self.snapFiltered = true;
                        } else if (metric == 'FR Credits') {
                            self.frFiltered = true;
                        } else if (metric == 'LPR Credits') {
                            self.lprFiltered = true;
                        }
                        self.redrawGraph(d.id, metric, level);
                        self.charts[id.slice(1)].focus = d.id;
                        self.charts[id.slice(1)].chart.focus(self.charts[id.slice(1)].focus);
                    }
                },
                onmouseover(d, i) {
                    if (self.charts[id.slice(1)].focus) {
                        self.charts[id.slice(1)].chart.focus(self.charts[id.slice(1)].focus);
                    }
                },
                onmouseout(d, i) {
                    if (self.charts[id.slice(1)].focus) {
                        self.charts[id.slice(1)].chart.focus(self.charts[id.slice(1)].focus);
                    }
                },
            },
            size: {
                width,
            },
            legend: {
                show: false,
            },
            pie: {
                label: {
                    format: (value, ratio, id2) => {
                        let length = 5;
                        if (ratio >= 0.3) {
                            length = 10;
                        } else if (ratio >= 0.2) {
                            length = 8;
                        } else if (ratio >= 0.1) {
                            length = 7;
                        }
                        return data.names[id2].slice(0, length);
                    },
                },
            },
            tooltip: {
                format: {
                    value: (value, ratio, id, index) => {
                        if (self.showBilling) {
                            if (metric == 'Snapshot Usages') {
                                return `${value} (~$${calculateTCO(value).toFixed(3)})`;
                            } else if (metric == 'FR Credits') {
                                return `${value} ($${(value * REKOGNITION_COST).toFixed(3)})`;
                            }
                        }
                        return value;
                    },
                },
            },
        });
    }

    querySummaryTable() {
        const self = this;

        self.grouping = self.groupingSelect;
        self.formattedRes = self.selectedRes.alias;
        self.chosenResolution = self.selectedRes.type;

        return self.$http
            .get('/api/metrics/', {
                params: {
                    grouping: self.grouping,
                    timeGroup: self.chosenResolution,
                    metrics: [],
                    filter: [],
                    startTime: self.tsStart,
                    endTime: self.tsEnd,
                    timezoneOffset: self.timezoneOffset,
                    isSummaryTable: true,
                    // skip: (params.page()-1) * params.count(),
                    // order: order[Object.keys(order)[0]],
                    // by: Object.keys(order)[0],
                },
            })
            .then((res) => {
                // let asyncFunc = async function() {
                // await self.gatherDetails(res.data.siteDetails);
                self.handleColumns();
                if (!self.detailsGathered) {
                    self.gatherDetails(res.data.siteDetails);
                }
                let timeStart;
                if (self.startDate && self.startTime) {
                    timeStart = +self
                        .moment(self.startDate)
                        .hours(self.startTime.getHours())
                        .minutes(self.startTime.getMinutes());
                }
                let timeEnd;
                if (self.endDate && self.endTime) {
                    timeEnd = +self
                        .moment(self.endDate)
                        .hours(self.endTime.getHours())
                        .minutes(self.endTime.getMinutes());
                }
                self.summaryTableData = self.formatTable(
                    res.data.data,
                    self.grouping,
                    self.moment(timeStart),
                    self.moment(timeEnd),
                    self.chosenResolution
                );
                self.summaryQueried = true;
                self.tableParams.reload();
            })
            .catch((err) => {
                console.error('Error with getData: ', err);
            });
    }

    exportSummaryTable() {
        const self = this;

        const workbook = new exceljs.Workbook();
        const sheet = workbook.addWorksheet('Usage Summary');
        sheet.pageSetup.horizontalCentered = true;
        sheet.pageSetup.verticalCentered = true;

        const arrs = [];

        const dataColumns = ['snapshotUsageTotal', 'snapshotUsageAvg'];
        const costColumns = ['storageCostTotal', 'storageCostAvg', 'frCostTotal', 'frCostAvg'];

        arrs.push(self.selectedColumns);
        console.log(self.summaryTableData);
        self.summaryTableData.forEach((point) => {
            const arr = [];
            self.selectedColumns.forEach((col) => {
                const column = _.find(self.cols, (o) => o.title == col);
                if (column) {
                    if (_.includes(costColumns, column.field) && self.showBilling) {
                        arr.push(`$${point[column.field].toFixed(4)}`);
                    } else if (_.includes(dataColumns, column.field)) {
                        arr.push(`${point[column.field].toFixed(2)}MiB`);
                    } else {
                        arr.push(point[column.field]);
                    }
                }
            });
            arrs.push(arr);
        });
        arrs.unshift([]);
        arrs.unshift(['From:', self.startFormat, 'To:', self.endFormat]);
        arrs.forEach((row) => {
            sheet.addRow(row);
        });
        workbook.xlsx
            .writeBuffer()
            .then((buffer) => {
                FileSaver.saveAs(
                    new Blob([buffer]),
                    `Summary_Report_${self.moment().format('DD_MM_YYYY_HH:mm:ss')}.xlsx`
                );
                self.toastr.success('Summary Table Downloaded', { preventOpenDuplicates: true });
                self.busyMessage = 'Report Downloaded';
            })
            .catch((err) => {
                console.error('Error while writing buffer', err);
            });
    }
}

export default angular.module('cameraViewerApp.usageDashboard').component('usageDashboard', {
    template: require('./usageDashboard.html'),
    controller: UsageDashboardComponent,
    controllerAs: '$ctrl',
}).name;
