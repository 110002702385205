import { fabric } from 'fabric';
import _ from 'lodash';
import async from 'async-es';
import angular from 'angular';
import OptionsController from './options.controller';
import UploadController from './upload.controller';
import FilterController from './filter.controller';
import SnapshotModalController from '../live/modal.controller';
import LookupController from './lookup.controller';

export class ReferenceComponent {
    $http;
    $document;
    $state;
    $scope;
    moment;
    Auth;
    $uibModal;
    numIdentities;
    tempCrop = {};
    newUser;
    filter = {};
    socket;
    faceDataSource = {};
    faceAdapter = {
        adapter: {
            remain: true,
        },
    };
    tempFilter = {};
    tempWanted = {};
    tempsnaps = {};
    lastFilter = {};

    /* @ngInject */
    constructor($http, $window, socket, $uibModal, moment, $document, $state, $scope, Auth, $timeout) {
        this.$http = $http;
        this.moment = moment;
        this.socket = socket;
        this.$uibModal = $uibModal;
        this.$document = $document;
        this.$timeout = $timeout;
        this.$window = $window;
        this.$state = $state;
        this.$scope = $scope;
        this.Auth = Auth;
        this.custClass = 'hidden-panel';
    }

    doLog() {
        const self = this;
        console.log('DEBUG: ', self);
    }

    $onInit() {
        const self = this;
        // self.faceDataSource={};
        // self.faceDataSource.minIndex=0;
        self.faceDataSource.get = self.getFaces.bind(self);
        if (self.$state.params.filter) {
            self.lastFilter.alias = self.$state.params.filter;
            self.applyFilter(self.lastFilter);
        }
        self.socket.socket.on('reference:save', (item) => {
            // console.log('SAVING', item);
            // console.log(self.faceAdapter);
            self.faceAdapter.reload();
        });
        self.socket.socket.on('reference:remove', (item) => {
            // console.log("REMOVING: ", item);
            self.faceAdapter.reload();
        });
        self.$http.get('/api/identities/count', { params: self.buildQuery() }).then((response) => {
            self.numIdentities = response.data;
        });
    }

    buildQuery() {
        const self = this;

        const queryParams = [];
        if (self.filter?.tags)
            queryParams.push({
                field: 'tags',
                type: 'string',
                value: self.filter.tags,
                operator: '$in',
            });
        if (self.filter?.description)
            queryParams.push({
                field: 'description',
                type: 'string',
                value: self.filter.description,
            });
        if (self.filter?.flag != undefined)
            queryParams.push({ field: 'flag', type: 'boolean', value: self.filter.flag });
        const query = {
            filter: self.filter?.alias ? self.filter.alias : undefined,
            params: JSON.stringify(queryParams),
        };

        return query;
    }

    getFaces(descriptor, success) {
        const self = this;
        // console.log(self.filter);
        const query = self.buildQuery();
        query.skip = 0;
        query.limit = descriptor.count;

        if (descriptor.append) {
            // let timestamp = +self.moment(+descriptor.append.timestamp).utc();
            query.order = -1;
            query.by = '_id';
            const params = JSON.parse(query.params);
            params.push({
                field: '_id',
                type: 'objectId',
                value: descriptor.append._id,
                operator: '$lt',
            });
            query.params = JSON.stringify(params);
        } else if (descriptor.prepend) {
            // let timestamp = +self.moment(+descriptor.prepend.timestamp).utc();
            query.order = 1;
            query.by = '_id';
            const params = JSON.parse(query.params);
            params.push({
                field: '_id',
                type: 'objectId',
                value: descriptor.prepend._id,
                operator: '$gt',
            });
            query.params = JSON.stringify(params);
        }

        self.$http.get('/api/identities/face', { params: query }).then((response) => {
            // console.log(response.data);
            async.each(
                response.data,
                (identity, callback) => {
                    if (identity.faces[0].dataCropped) {
                        self.tempCrop[`${identity._id}`] = identity.faces[0].dataCropped;
                    } else if (!self.tempCrop[`${identity._id}`]) {
                        self.cropFace(identity);
                    }
                    callback();
                },
                (err) => {
                    if (err) {
                        console.error(err);
                    }
                    success(response.data);
                }
            );
        });
    }

    applyFilter(filter) {
        const self = this;
        self.filter = _.clone(filter);
        self.$http.get('/api/identities/count', { params: self.buildQuery() }).then((response) => {
            self.numIdentities = response.data;
        });
        self.faceAdapter.reload();
    }

    update(id, value) {
        const self = this;
        self.$http.patch(`/api/identities/${id}`, _.pick(value, ['alias', 'flag', 'description', 'tags']));
    }

    reverseArray(array) {
        const tempArr = [];
        for (let i = array.length - 1; i >= 0; i--) {
            tempArr.push(array[i]);
        }
        // console.log(tempArr);
        return tempArr;
    }

    cropFace(identity) {
        const self = this;
        const face = identity.faces[0];
        const img = new fabric.Image.fromURL(face.data, ((image) => {
            image.setOptions({
                left: 0,
                top: 0,
                opacity: 1,
                width: face.width,
                height: face.height,
            });
            self.$timeout(() => {
                self.tempCrop[identity._id] = image.toDataURL({
                    multiplier: 3,
                    left: face.boundingBox.Left * face.width,
                    top: face.boundingBox.Top * face.height,
                    width: face.boundingBox.Width * face.width,
                    height: face.boundingBox.Height * face.height,
                });
            });
        }), { crossOrigin: 'anonymous' });
    }

    editIdentity(ident) {
        const self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('./options.html'),
            controller: OptionsController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
                identity() {
                    return ident;
                },
                smallFace() {
                    return self.tempCrop[ident._id];
                },
            },
        }).result.then((result) => {
            if (result.identity) {
                return self.update(result.identity._id, result.identity);
            } if (result.deleting) {
                return self.deleteIdentity(result.deleting);
            } if (result.snap) {
                return self.openSnap(result.snap);
            }
        }, () => {});
    }

    openSnap(snapshot) {
        const self = this;
        self.$uibModal
            .open({
                animation: true,
                backdrop: true,
                template: require('../live/modal.html'),
                controller: SnapshotModalController,
                controllerAs: '$ctrl',
                size: 'lg',
                resolve: {
                    snapshot() {
                        return snapshot;
                    },
                    snapPlaceholder() {
                        return [];
                    },
                    overlay() {
                        return undefined;
                    },
                },
            })
            .result.then(
                (result) => {
                    if (result && result.newSnap) {
                        self.openSnap(result.newSnap);
                    }
                },
                (err) => {
                    console.error(err);
                }
            );
    }

    deleteIdentity(identity) {
        const self = this;
        // console.log(reference);
        self.$http.delete(`/api/identities/${identity}`).then((response) => {
            this.applyFilter(self.lastFilter)
        });
    }

    lookup() {
        const self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('./lookup.html'),
            controller: LookupController,
            controllerAs: '$ctrl',
            size: 'xlg',
            resolve: {

            },
        }).result.then((result) => {
            if (result.face) {
                self.editIdentity(result.face);
            }
        }, () => {});
    }

    uploadWanted() {
        const self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('./upload.html'),
            controller: UploadController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
            },
        }).result.then((result) => {
        }, () => {});
    }

    clearFilter() {
        const self = this;
        self.lastFilter = {};
        self.applyFilter(self.lastFilter);
    }

    openFilter() {
        const self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('./filter.html'),
            controller: FilterController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
                lastFilter() {
                    return self.lastFilter;
                },
            },
        }).result.then((result) => {
            if (result.filter) {
                self.lastFilter = result.filter;
                return self.applyFilter(result.filter);
            }
        }, () => {});
    }
}

export default angular.module('cameraViewerApp.reference')
    .component('reference', {
        template: require('./reference.html'),
        controller: ReferenceComponent,
        controllerAs: '$ctrl',
    })
    .name;
