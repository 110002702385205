import _ from 'lodash-es';
import moment from 'moment';

function formatZone(zone, includeCamMetadata) {
    if (zone && zone.camera && zone.camera._id) {
        const motionConf = zone.camera.configuration.analyticsConfiguration.motionConfiguration;
        const analyticsConf = zone.camera.configuration.analyticsConfiguration;
        const faceConf = zone.camera.configuration.analyticsConfiguration.faceConfiguration;
        const ls = zone.camera.configuration.localstorage;
        const lsStream = _.find(zone.camera.profiles, (o) => o.profileToken === ls.profileToken);
        const cloudStream = _.find(zone.camera.profiles, (o) => o.profileToken === analyticsConf.profileToken);

        let budCount = 0;
        let lineCounts = 0;
        motionConf.crossLines.forEach((line) => {
            budCount += line.buddyTripAToB;
            budCount += line.buddyTripBToA;
            if (line.countAToB || line.countBToA) {
                lineCounts += 1;
            }
        });
        let returnZone = {
            _id: zone._id,
            referenceShot: zone.referenceShot,
            alias: zone.alias,
            active: !zone.unit.down && !zone.camera.down && zone.camera.enabled,
            vvsecEnabled: zone.camera.VVSECEnabled,
            vvsecNum: zone.camera.VVSECNumber,
            ipAddress: zone.camera.url,
            motionAnalysis: analyticsConf.detectmotion,
            learningRate: analyticsConf.motionConfiguration.learningRate,
            motionCooloff: analyticsConf.motionConfiguration.motionCooloff,
            burstCooloff: analyticsConf.motionConfiguration.burstCooloff,
            burstDelay: analyticsConf.motionConfiguration.burstDelay,
            initialDelay: analyticsConf.motionConfiguration.initialDelay,
            motionAlgorithm: analyticsConf.motionConfiguration.algorithm,
            maxBursts: analyticsConf.motionConfiguration.maxBursts,
            minBursts: analyticsConf.motionConfiguration.minBursts,
            erosions: analyticsConf.motionConfiguration.erosions,
            dilations: analyticsConf.motionConfiguration.dilations,
            motionTrigger: analyticsConf.motionConfiguration.motionTrigger,
            deltaThreshold: analyticsConf.motionConfiguration.deltaThreshold,
            motionMaxArea: 0,
            motionLinkedCameras: motionConf.buddyMotion.length,
            keepoutZones: motionConf.keepoutZones.length,
            lineTrip: 0,
            lineLinkedCameras: budCount,
            lineCounts: 0,
            frAnalytics: zone.cloudAnalytics,
            lprAnalytics: zone.lprAnalytics,
            lprRegion: Object.prototype.hasOwnProperty.call(zone, 'countryList') ? zone.countryList : zone.country,
            heatmaps: motionConf.recordHeatmap,
            cloudScale: motionConf.scaledown,
            cloudSchedule: undefined,
            lsActive: ls.enabled,
            lsType: undefined,
        };
        if (includeCamMetadata) {
            returnZone = {
                ...returnZone,
                serialMeta: zone.cameraMetadata?.serial,
                manufacturerMeta: zone.cameraMetadata?.manufacturer,
                modelCodeMeta: zone.cameraMetadata?.modelCode,
                camTypeMeta: zone.cameraMetadata?.camType,
                commsTypeMeta: zone.cameraMetadata?.commsType,
                ipAddressMeta: zone.cameraMetadata?.ipAddress,
                channelNumberMeta: zone.cameraMetadata?.channelNumber,
                portMeta: zone.cameraMetadata?.port,
                locationMeta: zone.cameraMetadata?.location,
                usernameMeta: zone.cameraMetadata?.username,
                passwordMeta: zone.cameraMetadata?.password,
                installedDateMeta: zone.cameraMetadata?.installedDate,
                warrantyDateMeta: zone.cameraMetadata?.warrantyDate,
            };
            if (zone.cameraMetadata) {
                console.log('Zone has metadata', zone.cameraMetadata);
            }
            if (zone.cameraMetadata?.customFields?.length > 0) {
                console.log('Zone has custom fields', zone.cameraMetadata.customFields);
                zone.cameraMetadata.customFields.forEach((field, index) => {
                    if (!returnZone[`meta_${field.key}`]) {
                        returnZone[`meta_${field.key}`] = `${field.value}`;
                    }
                });
            }
        }

        let lsWidth;
        let lsHeight;
        if (lsStream) {
            lsWidth = lsStream.videoEncoderConfiguration.resolution.width;
            lsHeight = lsStream.videoEncoderConfiguration.resolution.height;
            returnZone.lsStream = lsStream.profileName;
            // returnZone.lsPix = lsWidth * lsHeight;
            returnZone.lsHWDec = lsStream.hardwaredecoding;
            returnZone.lsHWEnc = lsStream.hardwareencoding;
            returnZone.lsForceTCP = lsStream.forceTCP;
            returnZone.lsResolution = `${lsWidth}x${lsHeight}`;
            returnZone.lsFrameRate = lsStream.videoEncoderConfiguration.frameRateLimit;
        } else {
            returnZone.lsStream = 'No encoding profile';
            returnZone.lsHWDec = 'No encoding profile';
            returnZone.lsHWEnc = 'No encoding profile';
            returnZone.lsForceTCP = 'No encoding profile';
            returnZone.lsResolution = 'No encoding profile';
            returnZone.lsFrameRate = 'No encoding profile';
        }
        let atPix;
        let atHeight;
        let atWidth;
        if (cloudStream) {
            atWidth = cloudStream.videoEncoderConfiguration.resolution.width;
            atHeight = cloudStream.videoEncoderConfiguration.resolution.height;
            returnZone.atStream = cloudStream.profileName;
            returnZone.atHWDec = cloudStream.hardwaredecoding;
            returnZone.atHWEnc = cloudStream.hardwareencoding;
            returnZone.atTCP = cloudStream.forceTCP;
            atPix = (zone.camera.configuration.scaleDown * (atWidth * atHeight)) / motionConf.scaledown;
            returnZone.frMinFaceSize = Math.round(((faceConf.minsize[0] * faceConf.minsize[1]) / atPix) * 10000) / 100;
            returnZone.motionMinArea = Math.round(((motionConf.minarea * 4) / atPix) * 10000) / 100;
            returnZone.atResolution = `${atWidth}x${atHeight}`;
            returnZone.atFrameRate = cloudStream.videoEncoderConfiguration.frameRateLimit;
        } else {
            returnZone.atStream = 'No encoding profile';
            returnZone.atHWDec = 'No encoding profile';
            returnZone.atHWEnc = 'No encoding profile';
            returnZone.atTCP = 'No encoding profile';
            returnZone.atResolution = 'No encoding profile';
            returnZone.atFrameRate = 'No encoding profile';
        }

        motionConf.crossLines.forEach((line) => {
            if (line.tripAToB || line.tripBToA) {
                returnZone.lineTrip += 1;
            }
            if (line.countAToB || line.countBToA) {
                returnZone.lineCounts += 1;
            }
        });

        if (zone.camera.configuration.cloudSchedule.enabled) {
            const ind = _.findIndex(zone.camera.configuration.cloudSchedule.schedules, (o) => o.enabled === true);
            returnZone.cloudSchedule = ind !== -1;
        } else {
            returnZone.cloudSchedule = false;
        }
        if (motionConf.maxarea === -1 || !atPix) {
            returnZone.motionMaxArea = 'N/A';
        } else {
            returnZone.motionMaxArea = (motionConf.maxarea / atPix) * 100;
        }
        if (ls.enabled) {
            if (ls.onlyMotion) {
                returnZone.lsType = 'OnlyMotion';
            } else if (ls.localSchedule.enabled) {
                const schedInd = _.findIndex(ls.localSchedule.schedules, (o) => o.enabled === true);
                if (schedInd !== -1) {
                    returnZone.lsType = 'Schedule';
                } else {
                    returnZone.lsType = 'N/A';
                }
            } else {
                returnZone.lsType = '24H';
            }
        } else {
            returnZone.lsType = 'N/A';
        }
        return returnZone;
    }
    return {
        referenceShot: undefined,
        alias: zone.alias,
        active: 'Unbounded',
        vvsecEnabled: 'Unbounded',
        vvsecNum: 'Unbounded',
        ipAddress: 'Unbounded',
        atStream: 'Unbounded',
        atHWDec: 'Unbounded',
        atHWEnc: 'Unbounded',
        atTCP: 'Unbounded',
        atResolution: 'Unbounded',
        atFrameRate: 'Unbounded',
        motionAnalysis: 'Unbounded',
        motionTrigger: 'Unbounded',
        maxBursts: 'Unbounded',
        minBursts: 'Unbounded',
        initialDelay: 'Unbounded',
        burstDelay: 'Unbounded',
        burstCooloff: 'Unbounded',
        motionCooloff: 'Unbounded',
        motionAlgorithm: 'Unbounded',
        learningRate: 'Unbounded',
        deltaThreshold: 'Unbounded',
        dilations: 'Unbounded',
        erosions: 'Unbounded',
        motionMinArea: 'Unbounded',
        motionMaxArea: 'Unbounded',
        motionLinkedCameras: 'Unbounded',
        keepoutZones: 'Unbounded',
        lineTrip: 'Unbounded',
        lineLinkedCameras: 'Unbounded',
        lineCounts: 'Unbounded',
        frAnalytics: zone.cloudAnalytics,
        frMinFaceSize: 'Unbounded',
        lprAnalytics: zone.lprAnalytics,
        lprRegion: Object.prototype.hasOwnProperty.call(zone, 'countryList') ? zone.countryList : zone.country,
        heatmaps: 'Unbounded',
        cloudScale: 'Unbounded',
        cloudSchedule: 'Unbounded',
        lsActive: 'Unbounded',
        lsType: 'Unbounded',
        lsStream: 'Unbounded',
        lsHWDec: 'Unbounded',
        lsHWEnc: 'Unbounded',
        lsForceTCP: 'Unbounded',
        lsResolution: 'Unbounded',
        lsFrameRate: 'Unbounded',
    };
}

function prepareSite(siteData) {
    const formattedZones = [];

    siteData.zones = _.filter(siteData.zones, (o) => !o.disabled);
    siteData.zones.forEach((zone) => {
        let cUnit = zone.unit;
        let cCam = zone.camera;
        const unitIsNotObject = zone.unit && !Object.prototype.hasOwnProperty.call(zone.unit, '_id');
        const cameraIsNotObject = zone.camera && !Object.prototype.hasOwnProperty.call(zone.camera, '_id');
        if (unitIsNotObject && cameraIsNotObject) {
            cUnit = _.find(siteData.units, (o) => o._id === zone.unit);
            if (cUnit) {
                cCam = _.find(cUnit.cameras, (o) => o._id === zone.camera);
            }
        }
        zone.camera = cCam;
        zone.unit = cUnit;
        const formattedZone = formatZone(zone);
        if (formattedZone) {
            formattedZones.push(formattedZone);
        }
    });
    return {
        site: siteData,
        zones: formattedZones,
    };
}

function addHeaderBlock(sheet, columns, account, site) {
    sheet.addRow([`Date & Time: ${moment()
        .format('DD-MM-YYYY HH:mm:ss')}`]);
    sheet.addRow([`Site: ${site.alias}`]);
    sheet.addRow([`Account: ${account.name}`]);

    const headers = columns.map((o) => o.title);
    headers.splice(1, 0, 'Reference Shot');
    sheet.addRow(headers);
}

function populateData(sheet, columns, zones, ctx) {
    zones.forEach((zone) => {
        const zoneRow = [];
        columns.forEach((obj) => {
            const k = obj.field;
            if (zone[k] === true) {
                zoneRow.push('True');
            } else if (zone[k] === false || !Object.prototype.hasOwnProperty.call(zone, k)) {
                zoneRow.push('False');
            } else if (k === 'lprRegion') {
                if (Array.isArray(zone[k])) {
                    let list = '';
                    const listLength = zone[k].length;
                    zone[k].forEach((region, index) => {
                        try {
                            list += ctx.$filter('regionFilter')(region, ctx.countryList);
                        } catch {
                            list += region;
                        }
                        if (index + 1 !== listLength) {
                            list += ', ';
                        }
                    });
                    zoneRow.push(list);
                } else {
                    try {
                        zoneRow.push(ctx.$filter('regionFilter')(zone[k], ctx.countryList));
                    } catch {
                        zoneRow.push(zone[k]);
                    }
                }
            } else {
                zoneRow.push(zone[k]);
            }
        });
        zoneRow.splice(1, 0, '');
        sheet.addRow(zoneRow);
    });
}

function processColumnWidths(sheet) {
    const defaultWidth = 10;
    sheet.columns.forEach((column) => {
        let dataMax = 0;
        column.alignment = {
            vertical: 'top',
            horizontal: 'center',
        };
        column.values.forEach((value) => {
            if (value && value.length) {
                const columnLength = value.length;
                if (columnLength > dataMax) {
                    dataMax = columnLength + 1;
                }
            }
        });
        column.width = dataMax < defaultWidth ? defaultWidth : dataMax;
    });
}

function getb64Image(img) {
    const canvas = document.createElement('canvas');
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
    img.setAttribute('crossorigin', 'anonymous');

    // Copy the image contents to the canvas
    const ctx = canvas.getContext('2d', { willReadFrequently: true });
    ctx.drawImage(img, 0, 0);

    // Get the data-URL formatted image
    // Firefox supports PNG and JPEG. You could check img.src to
    // guess the original format, but be aware the using "image/jpg"
    // will re-encode the image.
    const dataURL = canvas.toDataURL('image/jpeg');

    return {
        img: dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
        width: img.naturalWidth,
        height: img.naturalHeight,
    };
}

function prepareImages(workbook, sheet, zones, document) {
    const maxWidth = 400;
    const maxHeight = 400;
    const zoneImages = {};

    zones.forEach((zone) => {
        const zoneEl = document.querySelector(`#reference_${zone._id}`);
        let zoneImg;
        if (zoneEl) {
            zoneImg = getb64Image(zoneEl);
        }
        if (zoneImg) {
            zoneImages[zone._id] = {
                _id: workbook.addImage({
                    base64: zoneImg.img,
                    extension: 'jpeg',
                }),
            };
            zoneImages[zone._id].width = zoneImg.width;
            zoneImages[zone._id].height = zoneImg.height;
        }
    });

    let maxImgWidth = 0;
    zones.forEach((zone, i) => {
        if (Object.prototype.hasOwnProperty.call(zoneImages, zone._id)) {
            const image = zoneImages[zone._id];
            let height;
            let width;
            if (image.height >= image.width) {
                height = maxHeight;
                width = maxWidth * (image.width / image.height);
            } else if (image.height < image.width) {
                height = maxHeight * (image.height / image.width);
                width = maxWidth;
            }
            if (width > maxImgWidth) {
                maxImgWidth = width;
            }
            sheet.addImage(image._id, {
                tl: {
                    col: 1,
                    row: i + 4,
                },
                ext: {
                    width,
                    height,
                },
            });
            sheet.getRow(i + 5).height = height * 0.75;
        }
    });
    sheet.columns[1].width = maxImgWidth / 7;
}

export default {
    formatZone,
    prepareSite,
    prepareImages,
    addHeaderBlock,
    populateData,
    processColumnWidths,
};
