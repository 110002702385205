const angular = require('angular');

export default angular.module('directives.filterContent', [])
    .directive('newPageHeaderContent', () => ({
        restrict: 'A',
        link(scope, element, attr) {
            if (attr.newPageHeaderContent != 'false') {
                const filterHeader = angular.element($('.new-page-header')[0]);
                const content = angular.element($('#content')[0]);
                if (filterHeader[0]) {
                    scope.$watch(() => filterHeader[0].offsetHeight, () => {
                        const height = filterHeader[0].offsetHeight;
                        element[0].style.marginTop = `${height}px`;
                    });
                    scope.$watch(() => content[0].clientWidth, () => {
                        const width = content[0].clientWidth;
                        filterHeader[0].style.width = `${width}px`;
                    });
                }
            }
        },
    }))
    .name;
