import _ from 'lodash';
import hasprivileges from 'hasprivileges';

export default class ChooseAccountsModalController {
    $http;
    $scope;
    $uibModalInstance;
    $uibModal;
    $ngConfirm;
    $state;
    Auth;
    /* @ngInject */

    constructor($uibModalInstance, $ngConfirm, currentUser, Auth, currentAccount, userRoleOptions, $state, $uibModal, user, $http, $scope, toastr) {
        this.$http = $http;
        this.$state = $state;
        this.$ngConfirm = $ngConfirm;
        this.toastr = toastr;
        this.newUser = {};
        this.$scope = $scope;
        this.user = user;
        this.Auth = Auth;
        this.accounts = [];
        this.userAccounts = [];
        this.currentUser = currentUser;
        this.userRoleOptions = userRoleOptions;
        this.currentAccount = currentAccount;
        this.$uibModalInstance = $uibModalInstance;
        this.$uibModal = $uibModal;
    }

    $onInit() {
        const self = this;
        self.user.accounts.forEach((acc) => {
            self.userAccounts.push({ accountId: acc.accountId, name: acc.name });
        });
        self.origAccounts = _.cloneDeep(self.userAccounts);

        self.currentUser.accounts.forEach((account) => {
            if (hasprivileges.hasPrivilegeFunc('secuvue.UserManagement', account.role.roles)) {
                const tempInd = _.findIndex(self.user.accounts, (o) => o.accountId == account.accountId);
                if (tempInd == -1) {
                    self.accounts.push({ accountId: account.accountId, name: account.name });
                }
            }
        });
    }

    removeAcc(item) {
        const self = this;
        self.accounts.push(item);
    }
    selectAcc(item) {
        const self = this;
        self.accounts.splice(self.accounts.indexOf(item), 1);
    }

    cancel() {
        const self = this;
        self.$uibModalInstance.dismiss();
    }

    newAccounts() {
        const self = this;
        const testers = _.cloneDeep(self.userAccounts);
        self.origAccounts.forEach((acc) => {
            const ind = _.findIndex(testers, (o) => o.accountId == acc.accountId);
            if (ind !== -1) {
                testers.splice(ind, 1);
            }
        });
        if (testers.length > 0) {
            return true;
        }
        return false;
    }

    confirmAccounts() {
        const self = this;
        const removeAccs = [];
        self.origAccounts.forEach((acc) => {
            const ind = _.findIndex(self.userAccounts, (o) => o.accountId == acc.accountId);
            if (ind !== -1) {
                // self.userAccounts.splice(ind, 1);
            } else {
                removeAccs.push(acc);
            }
        });
        if (removeAccs.length == self.origAccounts.length && self.userAccounts.length == 0) {
            self.$ngConfirm({
                title: 'Disable User',
                escapeKey: false,
                backgroundDismiss: false,
                scope: self.$scope,
                content: 'By removing all accounts from this user, they will be disabled and unable to access the Secuvue platform. Do you wish to proceed?',
                buttons: {
                    enter: {
                        text: 'Yes',
                        btnClass: 'btn-blue',
                        action(scope, button) {
                            return self.$uibModalInstance.close({ accounts: self.userAccounts, roleId: self.role });
                        },
                    },
                    close(scope, button) {
                    },
                },
            });
        } else {
            return self.$uibModalInstance.close({ accounts: self.userAccounts, roleId: self.role });
        }
    }
}
