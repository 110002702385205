import angular from 'angular';
import SnapshotModalController from '../live/modal.controller';

export class PreviewComponent {
    /* @ngInject */
    constructor($uibModal, $http) {
        this.$uibModal = $uibModal;
        this.$http = $http;
    }

    $onInit() {
        const self = this;
    }

    closeModal() {
        const self = this;
        self.modalInstance.close();
    }

    ok() {
        const self = this;
        self.modalInstance.close();
    }
    doLog() {
        console.log(this);
    }

    openSnapshot(snapshotId) {
        const self = this;

        const template = require('../live/modal.html');
        self.$http.get(`/api/snapshots/${snapshotId}`).then((response) => {
            if (response.data) {
                self.modalInstance.close();
                self.$uibModal
                    .open({
                        animation: true,
                        backdrop: 'static',
                        keyboard: true,
                        template,
                        controller: SnapshotModalController,
                        controllerAs: '$ctrl',
                        size: 'lg',
                        resolve: {
                            snapshot() {
                                return response.data;
                            },
                            snapPlaceholder() {
                                return [];
                            },
                            overlay() {
                                return undefined;
                            },
                        },
                    })
                    .result.then(
                        (result) => {
                            if (result && result.newSnap) {
                                self.openSnapshot(result.newSnap);
                            } else if (result && result.next) {
                                // self.openNext();
                            } else if (result && result.previous) {
                                // self.openPrevious();
                            }
                        },
                        (err) => {
                            console.error(err);
                        }
                    );
            }
        });
    }
}

export default angular.module('cameraViewerApp.accessEvents')
    .component('previewModal', {
        template: require('./tooltipTemplate.html'),
        controller: PreviewComponent,
        controllerAs: '$ctrl',
        bindings: {
            resolve: '<',
            modalInstance: '<',
        },
    }).name;
