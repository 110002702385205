'use strict';
// @flow

import _ from 'lodash';
import hasprivileges from 'hasprivileges';

class _User {
    _id: string = '';
    name: string = '';
    username: string = '';
    role: string = '';
    $promise = undefined;
}

class _Account {
    accountId: string = '';
    name: string = '';
    privileges: Object = {};
    $promise = undefined;
}


export function AuthService(moment, $location, $http, $q, Util, User, Idle, Keepalive, $state, $stateParams, $uibModal, $rootScope, $localStorage, $uibModalStack) {
    'ngInject';

    const safeCb = Util.safeCb;
    let currentUser: _User = new _User();
    let currentAccount: _Account = new _Account();
    let warning = undefined;
    let timedOut = undefined;
    let started = false;
    const timeoutDuration = 30;
    const idleDuration = 60;
    const keepaliveInterval = 15 * 60;

    /**
     * Check if userRole is >= role
     * @param {String} userRole - role of current user
     * @param {String} role - role to check against
     */
    //var hasRole = function(userRole, role) {
    //return userRoles.indexOf(userRole) >= userRoles.indexOf(role);
    //};

    if($location.path()
        .includes('logout') || $location.path()
        .includes('reset') || $location.path()
        .includes('verify')) {
        //NOP
    } else {
        currentUser = User.get();
    }


    function closeModals() {
        if(warning) {
            warning.close();
            warning = null;
        }

        if(timedOut) {
            timedOut.close();
            timedOut = null;
        }
    }

    function startIdleFn(userIdle, userKeep) {
        if(userIdle) {
            Idle.setIdle(userIdle);
        } else {
            Idle.setIdle(idleDuration);
        }
        if(userKeep) {
            Keepalive.setInterval(userKeep);
        } else {
            Keepalive.setInterval(keepaliveInterval);
        }
        Idle.setTimeout(timeoutDuration);
        //$rootScope.timeoutDuration = timeoutDuration;
        closeModals();
        Idle.watch(); //also starts Keepalive
        started = true;
    }

    function setCurrentAccountFn(newCurrentAccount) {
        if(newCurrentAccount) {
            currentAccount = findAccountById(currentUser, newCurrentAccount);
            $localStorage.currentAccount = currentAccount.accountId;
        } else if(!!$stateParams.accountID && findAccountById(currentUser, $stateParams.accountID)) {
            currentAccount = findAccountById(currentUser, $stateParams.accountID);
            $localStorage.currentAccount = currentAccount.accountId;
        } else if($localStorage.currentAccount && findAccountById(currentUser, $localStorage.currentAccount)) {
            currentAccount = findAccountById(currentUser, $localStorage.currentAccount);
        } else if(currentUser.accounts && currentUser.accounts.length > 0) {
            currentAccount = currentUser.accounts[0];
            $localStorage.currentAccount = currentUser.accounts[0].accountId;
        }
        //console.log(currentAccount);
        if(!$state.current.abstract && $state.$current.name !== 'login' && $state.$current.name !== '') {
            //console.log("RELOADING", $state.current);
            console.log("WE ARE GOING TO (DOT)");
            // ATT: Check accountId vs accountID here and above
            $state.go('.', {accountID: currentAccount.accountId}, {reload: true})
                .catch(err => {
                    console.log("State.go(.) catch error", err);
                });
        }
    }

    function findAccountById(user, accId) {
        if(user && user.accounts && user.accounts.length > 0) {
            let ind = user.accounts.findIndex(x => x.accountId == accId);
            return user.accounts[ind];
        } else {
            return false;
        }
    }

    function stop() {
        closeModals();
        Idle.unwatch();
        started = false;
    }

    const Auth = {
        /**
         * Authenticate user and save token
         *
         * @param  {Object}   user     - login info
         * @param  {Function} callback - function(error, user)
         * @return {Promise}
         */
        login({username, password}, callback?: Function) {
            return $http.post('/auth/local', {username, password})
                .then(res => {
                    //console.log("LASSA: ", $cookies.get("lastPosition"));
                    if(res.data.mfa) {
                        return {mfaRequired: true, mfa: res.data.mfa};
                    } else {
                        currentUser = User.get();
                        return currentUser.$promise;
                    }
                })
                .then(data => {
                    if(data.mfaRequired) {
                        return data;
                    } else {
                        //This is a user
                        let user = data;
                        //startIdleFn(user.idleDuration, (user.tokenTTL/2));
                        setCurrentAccountFn();
                        $rootScope.$emit("accountChanged");
                        safeCb(callback)(null, user);
                        return user;
                    }
                })
                .catch(err => {
                    Auth.logout();
                    safeCb(callback)(err.data);
                    return $q.reject(err.data);
                });
        },

        mfaLogin({code}, callback?: Function) {
            return $http.post('/auth/mfa', {code})
                .then(res => {
                    if(res.data.mfa) {
                        return {mfaRequired: true, mfa: res.data.mfa};
                    } else {
                        currentUser = User.get();
                        return currentUser.$promise;
                    }
                })
                .then(data => {
                    if(data.mfaRequired) {
                        return data;
                    } else {
                        let user = data;
                        setCurrentAccountFn();
                        $rootScope.$emit("accountChanged");
                        safeCb(callback)(null, user);
                        return user;
                    }
                })
                .catch(err => {
                    //Auth.logout();
                    safeCb(callback)(err.data);
                    return $q.reject(err.data);
                });
        },

        /**
         * Delete access token and user info
         */
        logout() {
            return $http.post('/auth/logout', {}).then(() => {
                currentUser = new _User();
                currentAccount = new _Account();
                stop();
                if($state.current.name !== 'login') {
                    $state.go('login')
                        .catch((err) => {
                            //console.log("State.go(login) catch error")
                        });
                }
            })
        },

        /**
         * Create a new user
         *
         * @param  {Object}   user     - user info
         * @param  {Function} callback - function(error, user)
         * @return {Promise}
         */
        createUser(user, callback?: Function) {
            return User.save(user, function(data) {
                currentUser = User.get();
                currentAcc.$promise = currentUser.$promise;
                return safeCb(callback)(null, user);
            }, function(err) {
                Auth.logout();
                return safeCb(callback)(err);
            })
                .$promise;
        },

        /**
         * Create a new user silently
         *
         * @param  {Object}   user     - user info
         * @param  {Function} callback - function(error, user)
         * @return {Promise}
         */
        createUserSilent(user, callback?: Function) {
            return User.save(user, function(data) {
                return safeCb(callback)(null, user);
            }, function(err) {
                return safeCb(callback)(err);
            })
                .$promise;
        },

        /**
         * Change password
         *
         * @param  {String}   oldPassword
         * @param  {String}   newPassword
         * @param  {Function} callback    - function(error, user)
         * @return {Promise}
         */
        changePassword(oldPassword, newPassword, callback?: Function) {
            return User.changePassword({
                id: currentUser._id
            }, {
                oldPassword,
                newPassword
            }, function() {
                return safeCb(callback)(null);
            }, function(err) {
                return safeCb(callback)(err);
            })
                .$promise;
        },

        /**
         * Change role
         *
         * @param  {String}   role
         * @param   user
         * @param  {Function} callback    - function(error, user)
         * @return {Promise}
         */
        changeRole(role, user, callback?: Function) {
            return User.changeRole({
                id: currentUser._id
            }, {
                role, user
            }, function() {
                return safeCb(callback)(null);
            }, function(err) {
                return safeCb(callback)(err);
            })
                .$promise;
        },

        /**
         * Gets all available info on a user
         *
         * @param  {Function} [callback] - function(user)
         * @return {Promise}
         */
        getCurrentUser(callback?: Function) {
            let value = _.get(currentUser, '$promise')
                ? currentUser.$promise
                : currentUser;
            return $q.when(value)
                .then(user => {
                    safeCb(callback)(user);
                    return user;
                }, () => {
                    safeCb(callback)({});
                    return {};
                });
        },

        resendVerifyEmail() {
            return $http.get(`/api/users/${currentUser?._id}/verify`)
        },

        verifyEmail(userId, token) {
            return $http.get(`/api/users/${userId}/verify/${token}`)
        },

        getCurrentAccount(callback?: Function) {
            let value = _.get(currentAccount, '$promise')
                ? currentAccount.$promise
                : currentAccount;

            return $q.when(value)
                .then(user => {
                    // TODO use localstorage/url state param...
                    // TODO: Secure localstorage(store id, get privileges from mongo)..
                    setCurrentAccountFn();
                    if(currentAccount.accountId) {

                        $rootScope.$emit("accountChanged");
                    }
                    if(currentUser && currentUser.accounts && currentUser.accounts[0]) {
                        safeCb(callback)(currentUser.accounts[0]);
                    } else {
                        //safeCb(callback)("No User");
                        return false;
                    }
                    return currentAccount;
                }, () => {
                    safeCb(callback)({});
                    return {};
                });
        },

        /**
         * Gets all available info on a user
         *
         * @return {Object}
         */
        getCurrentUserSync() {
            return currentUser;
        },

        /**
         * Check if a user is logged in
         *
         * @param  {Function} [callback] - function(is)
         * @return {Promise}
         */
        isLoggedIn(callback?: Function) {
            return Auth.getCurrentUser(undefined)
                .then(user => {
                    let is = false;
                    if(user._id) {
                        is = true;
                        if(currentAccount.accountId) {
                            safeCb(callback)(is);
                            return is;
                        } else {
                            return Auth.getCurrentAccount()
                                .then((currentAccount) => {
                                    safeCb(callback)(is);
                                    return is;
                                });
                        }
                    } else {
                        safeCb(callback)(is);
                        return is;
                    }
                });
        },

        /**
         * Check if a user is logged in
         *
         * @return {Boolean}
         */
        isLoggedInSync() {
            return currentUser._id !== '';
        },

        /**
         * Check if a user has a specified role or higher
         *
         * @param  {String}     role     - the role to check against
         * @param  {Function} [callback] - function(has)
         * @return {Promise}
         */
        //hasRole(role, callback?: Function) {
        //return Auth.getCurrentUser(undefined)
        //.then(user => {
        //let has = hasRole(_.get(user, 'role'), role);

        //safeCb(callback)(has);
        //return has;
        //});
        //},

        /**
         * Check if a user has a specified role or higher
         *
         * @param  {String} role - the role to check against
         * @return {Boolean}
         */
        //hasRoleSync(role) {
        //return hasRole(_.get(currentUser, 'role'), role);
        //},

        /**
         * Check if a user is an admin
         *   (synchronous|asynchronous)
         *
         * @return {Boolean|Promise}
         */
        isAdmin() {
            return Auth.hasRole(...[].concat.apply(['admin'], arguments));
        },

        /**
         * Check if a user is an admin
         *
         * @return {Boolean}
         */
        isAdminSync() {
            return Auth.hasRoleSync('admin');
        },

        /**
         * Check if a user is a superadmin
         *   (synchronous|asynchronous)
         *
         * @return {Boolean|Promise}
         */
        isSuperAdmin() {
            return Auth.hasRole(...[].concat.apply(['superadmin'], arguments));
        },

        /**
         * Check if a user is a superadmin
         *
         * @return {Boolean}
         */
        isSuperAdminSync() {
            return Auth.hasRoleSync('superadmin');
        },

        getCurrentAccountSync() {
            return currentAccount;
        },

        /**
         * Return landing/default page path
         *
         * @return {String} - path
         */
        getLandingPage() {
            return 'main.site';
        },

        startIdle: startIdleFn,

        setCurrentAccount: setCurrentAccountFn,
        /****************************/

        //hasPrivilegeMulti(paths, test, callback?: Function)

        hasPrivilege(path, test, callback?: Function) {
            return Auth.getCurrentUser(undefined)
                .then(user => {
                    //console.log("THIS IS THE USER: ", user);
                    let privArr = [];
                    let has = false;
                    if(currentAccount.accountId) {
                        if(typeof path == 'string') {
                            privArr.push(path);
                        } else if(Array.isArray(path)) {
                            privArr = path;
                        }
                        has = privArr.some(priv => {
                            return hasprivileges.hasPrivilegeFunc(priv, currentAccount.role.privileges, test);
                        });
                        if(!has) {
                            if(user.hyper) {
                                has = true;
                            }
                        }
                        safeCb(callback)(has);
                        return has;
                    } else {
                        return Auth.getCurrentAccount()
                            .then(currAccount => {
                                if(typeof path == 'string') {
                                    privArr.push(path);
                                } else if(Array.isArray(path)) {
                                    privArr = path;
                                }
                                has = privArr.some(priv => {
                                    return hasprivileges.hasPrivilegeFunc(priv, currAccount.role.privileges, test);
                                });
                                if(!has) {
                                    if(user.hyper) {
                                        has = true;
                                    }
                                }
                                safeCb(callback)(has);
                                return has;
                            });
                    }
                });
        },

        hasRole(path, test, callback?: Function) {
            return Auth.getCurrentUser(undefined)
                .then(user => {
                    //console.log("THIS IS THE USER2222222: ", user);
                    let roleArr = [];
                    let has = false;
                    if(currentAccount.accountId) {
                        if(typeof path == 'string') {
                            roleArr.push(path);
                        } else if(Array.isArray(path)) {
                            roleArr = path;
                        }
                        //console.log("Checking this role: ", roleArr);
                        //console.log("Against these: ", currentAccount.roles);
                        has = roleArr.some(role => {
                            return hasprivileges.hasPrivilegeFunc(role, currentAccount.role.roles, test);
                        });
                        if(!has) {
                            if(user.hyper) {
                                has = true;
                            }
                        }
                        //console.log("RETURNING HAS: ", has);
                        safeCb(callback)(has);
                        return has;
                    } else {
                        return Auth.getCurrentAccount()
                            .then(currAccount => {
                                if(typeof path == 'string') {
                                    roleArr.push(path);
                                } else if(Array.isArray(path)) {
                                    roleArr = path;
                                }
                                //console.log("Checking this role: ", roleArr);
                                //console.log("Against these: ", currentAccount.roles);
                                has = roleArr.some(role => {
                                    return hasprivileges.hasPrivilegeFunc(role, currAccount.role.roles, test);
                                });
                                if(!has) {
                                    if(user.hyper) {
                                        has = true;
                                    }
                                }
                                //console.log("RETURNING HAS: ", has);
                                safeCb(callback)(has);
                                return has;
                            });
                    }
                });
        },

        hasPrivilegeSync(path, test) {
            let privArr = [];
            let has = false;
            if(typeof path == "string") {
                privArr.push(path);
            } else if(Array.isArray(path)) {
                privArr = path;
            }

            if(currentAccount && currentAccount.role && currentAccount.role.privileges) {
                has = privArr.some(priv => {
                    return hasprivileges.hasPrivilegeFunc(priv, currentAccount.role.privileges, test);
                });
            }
            if(!has) {
                if(currentUser.hyper) {
                    has = true;
                }
            }
            return has;
        },

        hasRoleSync(path, test) {
            let roleArr = [];
            let has = false;
            if(typeof path === "string") {
                roleArr.push(path);
            } else if(Array.isArray(path)) {
                roleArr = path;
            }

            has = roleArr.some(role => {
                if(currentAccount && currentAccount.role) {
                    return hasprivileges.hasPrivilegeFunc(role, currentAccount.role.roles, test);
                } else {
                    return false;
                }
            });
            if(!has) {
                if(currentUser.hyper) {
                    has = true;
                }
            }
            return has;
        },

        hasUserPrivilege(path, test, callback?: Function) {
            return Auth.getCurrentUser(undefined)
                .then(user => {
                    let has = hasprivileges.hasPrivilegeFunc(path, user.privileges, test);
                    if(!has) {
                        if(user.hyper) {
                            has = true;
                        }
                    }
                    safeCb(callback)(has);
                    return has;
                });
        },

        hasUserPrivilegeSync(path, test) {
            let has = hasprivileges.hasPrivilegeFunc(path, currentUser.privileges, test);
            if(!has) {
                if(currentUser.hyper) {
                    has = true;
                }
            }
            return has;
        },

        /**
         * Get new token and save
         *
         */
        refreshToken() {
            // console.log("Refresh Token Called");
            let self = this;
            return $http.post('/api/users/refreshToken', {
                _id: currentUser._id
            }, {headers: {"X-JS-ACCOUNT": currentAccount.accountId}})
                .then(res => {
                    self.getCurrentUser()
                        .then(user => {
                            self.stop();
                            if(!user.noIdle) {
                                self.start(user.idleDuration, user.tokenTTL / 2);
                            }
                        });
                })
                .catch(err => {
                    console.log("Error with refreshing token:", err.data);
                });
        },

        refreshUser() {
            currentUser = User.get();
            return currentUser.$promise;
        },

        start: startIdleFn,
        stop
    };

    $rootScope.$on('IdleStart', function() {
        closeModals();

        warning = $uibModal.open({
            template: require('./inactive/warning-dialog.html'),
            windowClass: 'modal-danger',
            resolve: {
                data() {
                    return timeoutDuration;
                }
            },
            controller($uibModalInstance, data) {
                this.data = data;
                this.countdown = 0;
            },
            controllerAs: "$ctrl"
        });
    });

    $rootScope.$on('Keepalive', function() {
        Auth.refreshToken();
    });

    $rootScope.$on('IdleEnd', function() {
        closeModals();
    });

    $rootScope.$on('IdleTimeout', function() {
        closeModals();
        $uibModalStack.dismissAll('Idle Timed Out');
        timedOut = $uibModal.open({
            template: require('./inactive/timedout-dialog.html'),
            windowClass: 'modal-danger',
            backdrop: 'static',
            controller($uibModalInstance) {
                this.expanded = false;
                this.close = function() {
                    $uibModalInstance.close();
                };
                this.expand = function() {
                    this.expanded = !this.expanded;
                };
            },
            controllerAs: "$ctrl"
        });

        timedOut.result.then(result => {
            this.$uibModalInstance.close(result);
        });

        Auth.logout();
    });

    // if ($cookies.get('token') && $location.path() !== '/logout') {
    //   currentUser = User.get();
    //   currentAccount.$promise = currentUser.$promise;
    //   currentUser.$promise.then((user) =>{
    //     console.log("Startup");
    //     Auth.start(currentUser.idleDuration, (currentUser.tokenTTL/2));
    //     Auth.refreshToken();
    //     Auth.setCurrentAccount();
    //     $rootScope.$emit('accountChanged');
    //   })
    // }
    return Auth;
}
