import _ from 'lodash';
import angular from 'angular';
import hasprivileges from 'hasprivileges';

export class MoveUnitComponent {
    /* @ngInject */
    constructor($ngConfirm, $scope, toastr, $timeout, $uibModal, $http, moment, socket, Auth) {
        this.$ngConfirm = $ngConfirm;
        this.$scope = $scope;
        this.toastr = toastr;
        this.$timeout = $timeout;
        this.$uibModal = $uibModal;
        this.$http = $http;
        this.moment = moment;
        this.socket = socket;
        this.Auth = Auth;
        this.currentRooms = [];
        this.privilegedAccounts = [];
        this.units = [];
    }

    doLog() {
        console.log('DEBUG:', this);
    }

    $onDestroy() {
        const self = this;
        if (self.theUnitEventListener) {
            self.socket.socket.removeListener('unit:save', self.theUnitEventListener);
        }
        if (self.currentRooms.length > 0) {
            self.currentRooms.forEach((room) => {
                self.socket.leaveRoom(room);
            });
        }
    }

    $onInit() {
        const self = this;

        self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().accountId}:*:unassigned:units`);
        self.currentRooms.push(`${self.Auth.getCurrentAccountSync().accountId}:*:unassigned:units`);
        self.theUnitEventListener = self.onReceiptOfUnit.bind(self);
        self.socket.socket.on('unit:save', self.theUnitEventListener);
        self.$http.get('/api/units', {
                params: {
                    params: JSON.stringify([ { field: 'assigned', type: 'boolean', value: false } ])
                }
            })
            .then((response) => {
                if (response && response.data) {
                    self.units = response.data;
                }
                return response.data;
            });

        const user = self.Auth.getCurrentUserSync();
        self.privilegedAccounts = _.filter(user.accounts, (account) => {
            let has = hasprivileges.hasPrivilegeFunc('secuvue.unit.changeAccount', account.role.privileges);
            if (user.hyper) {
                has = true;
            }
            if (account.accountId === self.Auth.getCurrentAccountSync().accountId) {
                has = false;
            }
            return has;
        });
    }

    onReceiptOfUnit(newUnit) {
        const self = this;
        const index = _.findIndex(self.units, (unit) => unit._id === newUnit._id);
        if (index !== -1) {
            if (newUnit.assigned) {
                self.units.splice(1, index);
            } else {
                self.units[index] = newUnit;
            }
        } else if (!newUnit.assigned) {
            self.units.push(newUnit);
        }
    }

    closeModal() {
        const self = this;
        self.modalInstance.close();
    }

    ok() {
        const self = this;
        const promisses = [];
        self.selectedUnits.forEach((unit) => {
            promisses.push(this.$http.post(`/api/units/${unit._id}/changeAccount`, {
                account: self.changeToAccount.accountId,
            }));
        });
        Promise.all(promisses)
            .then((results) => {
                let err = false;
                results.forEach((result) => {
                    if (!result || !result.data) {
                        err = true;
                        console.error('No data returned : ', result);
                    }
                });
                if (err) {
                    self.toastr.error('Something went wrong', 'Some units might not have been moved', {
                        preventOpenDuplicates: true,
                    });
                    self.modalInstance.close();
                } else {
                    self.toastr.info('Units moved successfully', {
                        preventOpenDuplicates: true,
                    });
                    self.modalInstance.close();
                }
            })
            .catch((err) => {
                self.toastr.error('Something went wrong', err, {
                    preventOpenDuplicates: true,
                });
            });
    }
}

export default angular.module('cameraViewerApp.units')
    .component('moveUnit', {
        template: require('./move-unit.html'),
        controller: MoveUnitComponent,
        controllerAs: '$ctrl',
        bindings: {
            resolve: '<',
            modalInstance: '<',
        },
    })
    .name;
