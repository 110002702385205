import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import uiBootstrap from 'angular-ui-bootstrap';

import routes from './fileManager.routes';

export default angular.module('fileManager', [uiRouter, uiBootstrap, 'angularMoment'])
    .config(routes)
    .name;

require('./fileManager.component');
