export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.fileManager', {
            url: '/fileManager/:id',
            params: {
                id: { dynamic: true, value: '' },
                cameras: { dynamic: true },
                date: { dynamic: true },
                openTasks: { dynamic: true },
            },
            views: {
                'content@main': {
                    template: '<file-manager></file-manager>',
                },
            },
            authenticate: 'LocalStorage',
            data: {
                title: 'On-Site Storage',
            },
        });
}
