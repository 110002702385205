import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import angularMoment from 'angular-moment';
import NgTable from 'ng-table/bundles/ng-table'; // TODO figure out why this is needed
import routes from './units.routes';

export default angular.module('cameraViewerApp.units', [uiRouter, 'ngTable',
    'angularMoment'])
    .config(routes)
    .name;

require('./units.component');
require('./move-unit/move-unit.component');
require('./shellModal/shellModal.component');
require('./directives/compile-template');
