export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.zoneTemplate', {
            url: '/zoneTemplate',
            views: {
                'content@main': {
                // Remember that camel case gets changed to dash-case
                // ie. zoneTemplate becomes maintenance-dashboard
                    template: '<zone-template><zone-template>',
                },
            },
            authenticate: 'ZoneTemplate',
            data: {
                title: 'Zone Templates',
            },
        });
}
