import _ from 'lodash-es';
import { v1 as uuid } from 'uuid';
import { fabric } from 'fabric';
import hasprivileges from 'hasprivileges';
import options from './lists';
import Geometry from '../../../../server/components/utilities/geometry';
import NotesController from './notes.controller';
import NewContactController from './newContact.controller';
import MigrateSiteController from './migrateSite.controller';
import SimulatorController from '../simulator/simulator.controller';
import TemplateSaveController from '../templates/save-load-template.controller';
import TelegramAlertsController from './telegramAlerts.controller';

export default class SettingsController {
    $uibModalInstance;
    $scope;
    $http;
    refInvalid = false;
    Auth;

    currentSite;
    socket;
    availableZones = [];
    availableLines = [];
    unitAliases = {};
    shellScript = {};
    shellResponse = {};
    installDateOpen = {};
    warrantyDateOpen = {};
    camerasOpen = {};
    unitsOpen = {};
    canvasFlags = {
        heatmap: false,
        roi: false,
        crossLine: false,
        keepout: false,
        minMotion: false,
        maxMotion: false,
        minFace: false,
        minFaceCloud: false,
        minPerson: false,
        minPersonCloud: false,
        minHumanPose: false,
        minVehicle: false,
        minVehicleCloud: false,
        minSSD: false,
    };

    /* @ngInject */
    constructor(
        $scope,
        $uibModalInstance,
        $http,
        $document,
        $uibModal,
        $timeout,
        $rootScope,
        $ngConfirm,
        tasks,
        socket,
        Auth,
        Modal,
        toastr,
        moment,
        unitService,
        liveStreamService,
        appConfig,
        currentSite,
        jumpToZone,
        jumpToUnit
    ) {
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.$http = $http;
        this.$document = $document;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
        this.$rootScope = $rootScope;
        this.$ngConfirm = $ngConfirm;

        this.Auth = Auth;
        this.toastr = toastr;
        this.moment = moment;
        this.socket = socket;
        this.taskService = tasks;
        this.unitService = unitService;
        this.liveStreamService = liveStreamService;
        this.jumpToZone = jumpToZone;
        this.jumpToUnit = jumpToUnit;

        this.contactsOpen = [];
        this.refStyle = {};
        this.unitRelay = { open: false };
        this.shapes = {};
        this.currentShape = {};
        this.deleteModal = Modal.confirm.delete;

        this.currentSite = currentSite;
        this.currentUser = Auth.getCurrentUserSync();
        this.availableTime = ['Seconds', 'Milliseconds'];
        this.currentRooms = [];
        this.cleanupFunctions = [];
        this.opacity = 0.5;
        this.roiMode = {};
        this.secutraqUnits = {};
        this.camInitialDelay = {};
        this.camBurstDelay = {};
        this.camBurstCooloff = {};
        this.camMotionCooloff = {};
        this.camLineRateLimit = {};
        this.getCurrentUser = Auth.getCurrentUserSync;
        this.userId = this.Auth.getCurrentUserSync()._id;

        this.metadataTemplates = [];
        this.regionOptions = [];

        this.NotesModule = {
            tagCounts: {},
            searchQuery: '',
            onlyAttention: false,
            orderDescending: true,
            visibleNotes: [],
            selectedTags: [],
            availableTags: appConfig.default.siteNoteTagOptions,
        };

        this.ahdTypes = options.ahdTypes;
        this.relayTypes = options.relayTypes;
        this.faceAlgorithms = options.faceAlgorithms;
        this.billingOptions = options.billingOptions;
        this.availableEvents = options.availableEvents;
        this.motionAlgorithms = options.motionAlgorithms;
        this.availableByteUnits = options.availableByteUnits;
        this.connectivityOptions = options.connectivityOptions;
        this.poseClassifications = options.poseClassifications;
        this.secutraqEventOptions = options.secutraqEventOptions;
        this.liveStreamEncodingOptions = options.liveStreamEncodingOptions;
        this.countryOptions = appConfig.default.countryList;

        this.hasUnboundedZones = false;
        this.showUnbounded = false;
        this.slider = {
            options: {
                min: 0,
                max: 1,
                step: 0.01,
                change: this.opacityChanged.bind(this),
            },
        };
        this.hikvisionLogs = {
            address: undefined,
            username: 'admin',
            password: undefined,
            startTime: this.moment().subtract(7, 'days').startOf('hour').toDate(),
            endTime: this.moment().endOf('hour').toDate(),
            startOpen: false,
            endOpen: false,
            pending: false,
            show: false,
            tasks: [],
        };

        this.ensureBillingDefaults();
        this.ensureReportingDefaults();
        if (!_.get(this, 'currentSite.lprSettings.dailyResetTime')) {
            this.lprResetTime = this.moment()
                .hours(7)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .toDate();
        } else {
            this.lprResetTime = this.moment(
                _.get(this, 'currentSite.lprSettings.dailyResetTime')
            ).toDate();
        }

        const self = this;
        this.$scope.$on('$destroy', () => {
            socket.socket.removeListener('site:save', self.siteListener);
            socket.socket.removeListener('camera:save', self.cameraListener);
            if (self.currentRooms.length > 0) {
                self.currentRooms.forEach((room) => {
                    self.socket.leaveRoom(room);
                    // console.log(room, 'left');
                });
                self.cleanupFunctions.forEach((fn) => fn());
            }
        });
    }

    ensureReportingDefaults() {
        // Ensure defaults for reportingConfiguration
        if (!_.get(this, 'currentSite.reportingConfiguration')) {
            this.currentSite.reportingConfiguration = {
                enabled: false,
                entranceCameras: [],
                exitCameras: [],
                ATMCameras: [],
                queueCameras: [],
                countingLines: [],
            };
        }
        if (!_.get(this, 'currentSite.reportingConfiguration.queueCameras')) {
            this.currentSite.reportingConfiguration.queueCameras = [];
        }
    }

    ensureBillingDefaults() {
        if (!_.get(this, 'currentSite.billingDetails')) {
            this.currentSite.billingDetails = {
                designation: 'active',
                expiryDesignation: 'inactive',
                pocExpiryDate: this.moment().startOf('day'),
                pocContacts: [],
            };
        }
        if (!_.get(this, 'currentSite.billingDetails.pocExpiryDate')) {
            this.expiryDate = this.moment().startOf('day').toDate();
            this.expiryTime = this.moment(new Date()).add(1, 'hours').startOf('hour').toDate();
        } else {
            this.expiryDate = this.moment(
                _.get(this, 'currentSite.billingDetails.pocExpiryDate')
            ).toDate();
            this.expiryTime = this.moment(
                _.get(this, 'currentSite.billingDetails.pocExpiryDate')
            ).toDate();
        }
        if (!_.get(this, 'currentSite.billingDetails.expiryDesignation')) {
            this.currentSite.billingDetails.expiryDesignation = 'active';
        }
    }

    doLog() {
        console.log('DEBUG:', this);
    }

    replaceUnit(unit) {
        const self = this;
        self.$http
            .get('/api/units', {
                params: {
                    params: JSON.stringify([{ field: 'assigned', type: 'boolean', value: false }]),
                },
            })
            .then((response) => {
                self.unassignedUnits = response.data;
                self.$ngConfirm({
                    title: '<span style="display:flex; justify-content:center;">Replace Unit</span>',
                    theme: 'light',
                    animation: 'top',
                    scope: self.$scope,
                    closeAnimation: 'bottom',
                    escapeKey: false,
                    columnClass: 'col-xs-6 col-xs-offset-3',
                    backgroundDismiss: true,
                    content: `
                        Select new unit:
                        <ui-select ng-model="$ctrl.newU" theme="bootstrap" my-ui-select grow-open="250px">
                        <ui-select-match placeholder="Select unit...">{{$select.selected.serial}}</ui-select-match>
                        <ui-select-choices repeat="unit._id as unit in $ctrl.unassignedUnits | filter: $select.search track by unit._id" >
                        <div ng-bind-html="unit.serial | highlight: $select.search"></div>
                        </ui-select-choices>
                        </ui-select>
                        `,
                    buttons: {
                        // long hand button definition
                        enter: {
                            btnClass: 'btn-primary',
                            action(scope) {
                                self.$http
                                    .patch(`/api/sites/${self.currentSite._id}/changeUnit`, {
                                        originalUnit: unit._id,
                                        newUnit: self.newU,
                                    })
                                    .then((replaceResponse) => {
                                        if (
                                            replaceResponse.data &&
                                            replaceResponse.data === 'emitFail'
                                        ) {
                                            self.toastr.success(
                                                'Successfully replaced unit, please refresh page'
                                            );
                                        } else {
                                            self.toastr.success('Successfully replaced unit');
                                        }
                                        self.unassignedUnits = null;
                                    })
                                    .catch((err) => {
                                        if (err.status === 500) {
                                            if (err.data === 'swapFail') {
                                                console.error('Error', err.data);
                                            }
                                            self.toastr.error('Unit swap failed');
                                        } else if (err.status === 404) {
                                            console.error('HTTP 404 Not Found', err.data);
                                        }
                                    });
                            },
                        },
                    },
                });
            });
    }

    applyContactSetting() {
        const self = this;
        self.$http
            .patch(
                `/api/sites/${self.currentSite._id}`,
                _.pick(self.currentSite, ['_id', 'contacts'])
            )
            .then(() => {
                self.toastr.success('Site settings applied successfully', {
                    preventOpenDuplicates: true,
                });
            });
    }

    initHikvisionLogs() {
        const self = this;

        const cleanupFunc = self.$rootScope.$on('taskUpdate', self.hikvisionTaskCB);
        self.cleanupFunctions.push(cleanupFunc);
        self.taskService
            .getTasks()
            .then((tasks) => {
                self.hikvisionLogs.tasks = tasks?.filter(
                    (o) =>
                        o.user === self.currentUser._id &&
                        o.site === self.currentSite._id &&
                        o.type === 'hikvisionLogRetrieval'
                );
                self.calculateTaskDurations();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    $onInit() {
        const self = this;

        self.activeEncodingProfile = {};

        if (_.find(self.currentSite.zones, (o) => o.camera === 'UNBOUNDED' && !o.disabled)) {
            self.hasUnboundedZones = true;
        }

        if (self.currentSite.initialized) {
            self.installedFormattedDate = self
                .moment(self.currentSite.installed)
                .format('YYYY-MM-DD HH:MM A');
        }

        if (self.currentSite.events) {
            self.currentSite.eventStack = _.some(self.currentSite.events, (o) => o === true);
        }

        if (!self.currentSite.contacts || self.currentSite.contacts.length === 0) {
            self.currentSite.contacts = [];
            self.currentSite.contacts.push({
                firstname: '',
                lastname: '',
                phone: '',
                email: '',
            });
        }

        if (self.currentSite && self.currentSite.units && self.currentSite.units.length > 0) {
            self.currentSite.units.forEach((unit, index) => {
                self.unitAliases[unit._id] = unit.alias;
                self.unitsOpen[index] = { open: false };
                if (
                    unit.localstorage &&
                    unit.localstorage.roots &&
                    unit.localstorage.roots.length > 0
                ) {
                    unit.localstorage.roots.forEach((root) => {
                        self.convertBytesToSize(root);
                    });
                }
            });

            // If only unit, already open its settings
            if (self.currentSite.units.length === 1) {
                self.unitsOpen[0].open = true;
            }
        }

        this.ok = function dismissModal() {
            this.$uibModalInstance.dismiss();
        };
        if (self.Auth.hasPrivilegeSync('secuvue.site.index')) {
            // TODO think if we need this maybe (never worked)
            self.socket.joinRoom(
                `${self.Auth.getCurrentAccountSync().accountId}:${self.currentSite._id}:sites`
            );
            self.currentRooms.push(
                `${self.Auth.getCurrentAccountSync().accountId}:${self.currentSite._id}:sites`
            );
        } else {
            // self.toastr.info(`You require secuvue.site.configuration privileges`, 'Unprivileged:', {
            //     preventOpenDuplicates:true
            // })
        }
        if (self.Auth.hasPrivilegeSync('secuvue.camera.index')) {
            this.$http
                .get('/api/cameras', {
                    params: { site: this.currentSite._id, showUnbounded: true },
                })
                .then(
                    (response) => {
                        self.zonesBackup = _.cloneDeep(response.data);
                        self.availableZones = response.data;
                        if (self.jumpToZone) {
                            const zone = _.find(
                                self.availableZones,
                                (o) => o._id === self.jumpToZone
                            );
                            if (zone && !zone.disabled && zone.camera !== 'UNBOUNDED') {
                                self.active = 4;
                                self.selectedZone = zone;
                                self.redrawZones(zone);
                            } else if (!zone) {
                                self.toastr.error('Zone not found');
                            } else if (zone.camera === 'UNBOUNDED') {
                                self.showUnbounded = true;
                                self.showUnboundedZones();
                                self.active = 3;
                                self.selectedZone = zone;
                                self.redrawZones(zone);
                            } else if (zone.disabled) {
                                self.toastr.error('Zone disabled');
                            }
                        }
                        if (self.jumpToUnit) {
                            const unit = _.find(
                                self.currentSite.units,
                                (o) => o._id === self.jumpToUnit
                            );
                            if (unit && !unit.disabled) {
                                self.active = 3;
                                const indexOf = _.findIndex(
                                    self.currentSite.units,
                                    (o) => o._id === self.jumpToUnit
                                );
                                self.unitsOpen[indexOf].open = true;
                            } else if (!unit) {
                                self.toastr.error('Unit not found');
                            } else if (unit.disabled) {
                                self.toastr.error('Unit disabled');
                            }
                        }
                        // zone-camera ambiguity here
                        self.availableZones = _.filter(
                            self.availableZones,
                            (o) => o.camera !== 'UNBOUNDED' && !o.disabled
                        );
                        self.availableZones.forEach((camera) => {
                            self.camerasOpen[camera._id] = 'true';
                            self.camInitialDelay[camera._id] = 'Milliseconds';
                            self.camBurstDelay[camera._id] = 'Milliseconds';
                            self.camBurstCooloff[camera._id] = 'Milliseconds';
                            self.camMotionCooloff[camera._id] = 'Milliseconds';

                            const subCam = camera.camera;

                            if (Object.prototype.hasOwnProperty.call(subCam, '_id')) {
                                self.roiMode[camera.camera._id] = 'Select';
                                self.shapes[camera.camera._id] = [];
                            }

                            if (
                                camera.camera.configuration.analyticsConfiguration
                                    .motionConfiguration.crossLines
                            ) {
                                camera.camera.configuration.analyticsConfiguration.motionConfiguration.crossLines.forEach(
                                    (line) => {
                                        if (line.rateLimit === undefined) {
                                            line.rateLimit = 500;
                                        }
                                        self.camLineRateLimit[line.id] = 'Milliseconds';
                                    }
                                );
                            }

                            const unit = _.find(
                                self.currentSite.units,
                                (o) => o._id === camera.unit
                            );
                            if (unit) {
                                self.$timeout(() => {
                                    camera.unit = unit;
                                }, 0);
                                camera.unitAlias = camera.unit.alias;
                                if (camera.camera.profiles && camera.camera.profiles.length > 0) {
                                    if (!camera.camera.configuration.localstorage.profileToken) {
                                        camera.camera.configuration.localstorage.profileToken =
                                            camera.camera.profiles[0].profileToken;
                                    }
                                    if (
                                        !camera.camera.configuration.analyticsConfiguration
                                            .profileToken
                                    ) {
                                        camera.camera.configuration.analyticsConfiguration.profileToken =
                                            camera.camera.profiles[0].profileToken;
                                    }
                                }
                            }
                            if (camera.camera !== 'UNBOUNDED') {
                                self.ensureDefaults(camera);
                                self.initializeZoneDefaults(camera);
                                self.ensureThresholdDefaults(camera);
                                // Populate availableLines
                                if (
                                    camera.camera.configuration.analyticsConfiguration
                                        .motionConfiguration.lineCrossingDetection
                                ) {
                                    camera.camera.configuration.analyticsConfiguration.motionConfiguration.crossLines.forEach(
                                        (line) => {
                                            self.availableLines.push({
                                                zoneId: camera._id,
                                                zoneAlias: camera.alias,
                                                lineId: line.id,
                                                lineAlias: line.alias,
                                            });
                                        }
                                    );
                                }
                            }
                        });
                        self.refreshSecutraqUnits().catch(console.error);
                        self.socket.joinRoom(
                            `${self.Auth.getCurrentAccountSync().accountId}:${
                                self.currentSite._id
                            }:*:cameras`
                        );
                        self.currentRooms.push(
                            `${self.Auth.getCurrentAccountSync().accountId}:${
                                self.currentSite._id
                            }:*:cameras`
                        );
                    },
                    (err) => {
                        if (err.status !== 401 && err.status !== 403) console.error(err);
                    }
                );
        }

        self.$http
            .get('/api/accounts/metadataTemplates')
            .then((res) => {
                if (res.data) {
                    self.metadataTemplates = res.data;
                    self.regionOptions = self.metadataTemplates?.find((o) => o.key === 'Region');
                    self.metadataTemplates = _.filter(self.metadataTemplates, (o) => o.key !== 'Region');
                    if (!_.find(self.metadataTemplates, (o) => o.key === 'Custom')) {
                        self.metadataTemplates.push({ key: 'Custom', type: 'String', topic: 'Any' });
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            });

        self.siteListener = function siteListener(item) {
            if (item._id === self.currentSite._id) {
                self.$timeout(() => {
                    if (
                        self.currentSite &&
                        self.currentSite.units &&
                        self.currentSite.units.length > 0
                    ) {
                        self.currentSite.units.forEach((unit) => {
                            const newUnit = item.units?.filter((i) => i._id == unit._id);
                            if (!newUnit) return;
                            // Just keep configVersion in sync...
                            unit.configVersion = newUnit.configVersion;
                        });
                    }
                }, 0);
            }
        };

        self.cameraListener = function cameraListener(item) {
            const index = _.findIndex(self.availableZones, (site) => site._id === item._id);
            if (index >= 0) {
                // self.availableZones[index] = item; TODO add again with dirty checks
                if (
                    (self.availableZones[index].camera.authenticationFailed &&
                        !item.camera.authenticationFailed) ||
                    (!self.availableZones[index].camera.authenticationFailed &&
                        item.camera.authenticationFailed) ||
                    (!self.availableZones[index].camera.down && item.camera.down) ||
                    (self.availableZones[index].camera.down && !item.camera.down)
                ) {
                    self.$timeout(() => {
                        self.availableZones[index] = item;
                    }, 0);
                }

                self.availableZones[index].referenceShot = item.referenceShot;
                self.availableZones[index].heatmap = item.heatmap;
                self.availableZones[index].camera.lastActivity = item.camera.lastActivity;
                self.availableZones[index].lastActivity = item.lastActivity;
                self.availableZones[index].camera.down = item.camera.down;
                self.availableZones[index].camera.authenticationFailed =
                    item.camera.authenticationFailed;

                item.camera.configuration.analyticsConfiguration.motionConfiguration.crossLines.forEach(
                    (line) => {
                        const zoneLine = _.find(
                            self.availableZones[index].camera.configuration.analyticsConfiguration
                                .motionConfiguration.crossLines,
                            (o) => o.id === line.id
                        );
                        if (zoneLine) {
                            zoneLine.numAToB = line.numAToB;
                            zoneLine.numBToA = line.numBToA;
                            if (zoneLine.rateLimit === undefined) {
                                zoneLine.rateLimit = 500;
                            }
                        }
                    }
                );

                if (self.selectedZone) {
                    if (self.canvasFlags.heatmap) {
                        self.updateAnalyticsDisplay(
                            'heatmapCanvas',
                            self.selectedZone,
                            self.canvasFlags.heatmap
                        );
                    }
                    if (self.canvasFlags.keepout) {
                        self.updateAnalyticsDisplay(
                            'keepoutCanvas',
                            self.selectedZone,
                            self.canvasFlags.keepout
                        );
                    }
                    if (self.canvasFlags.crossLine) {
                        self.updateAnalyticsDisplay(
                            'crossLineCanvas',
                            self.selectedZone,
                            self.canvasFlags.crossLine
                        );
                    }
                    if (self.canvasFlags.roi) {
                        self.drawROICanvas(self.selectedZone.camera, self.canvasFlags.roi);
                    }
                    if (self.canvasFlags.mixMotion) {
                        self.updateAnalyticsDisplay(
                            'motionMinAreaCanvas',
                            self.selectedZone,
                            self.canvasFlags.mixMotion
                        );
                    }
                    if (self.canvasFlags.maxMotion) {
                        self.updateAnalyticsDisplay(
                            'motionMaxAreaCanvas',
                            self.selectedZone,
                            self.canvasFlags.maxMotion
                        );
                    }
                    // Edge analytics
                    if (self.canvasFlags.minFace) {
                        self.updateAnalyticsDisplay(
                            'faceMinSizeCanvas',
                            self.selectedZone,
                            self.canvasFlags.minFace
                        );
                    }
                    if (self.canvasFlags.minPerson) {
                        self.updateAnalyticsDisplay(
                            'personMinSizeCanvas',
                            self.selectedZone,
                            self.canvasFlags.minPerson
                        );
                    }
                    if (self.canvasFlags.minVehicle) {
                        self.updateAnalyticsDisplay(
                            'vehicleMinSizeCanvas',
                            self.selectedZone,
                            self.canvasFlags.minVehicle
                        );
                    }

                    // Cloud analytics
                    if (self.canvasFlags.minFaceCloud) {
                        self.updateAnalyticsDisplay(
                            'faceMinSizeCanvasCloud',
                            self.selectedZone,
                            self.canvasFlags.minFaceCloud
                        );
                    }
                    if (self.canvasFlags.minPersonCloud) {
                        self.updateAnalyticsDisplay(
                            'personMinSizeCanvasCloud',
                            self.selectedZone,
                            self.canvasFlags.minPersonCloud
                        );
                    }

                    const cUnit = _.find(
                        self.currentSite.units,
                        (o) => o._id === self.selectedZone.unit
                    );

                    if (cUnit) {
                        self.$timeout(() => {
                            self.selectedZone.unit = cUnit;
                        }, 0);
                    }
                }
                if (
                    _.find(
                        self.currentSite.zones,
                        (o) => o.camera === 'UNBOUNDED' && o.disabled !== true
                    )
                ) {
                    self.hasUnboundedZones = true;
                }

                self.checkRef();
                // self.$scope.apply();
            } else {
                self.availableZones.push(item);
            }
        };
        // TODO listen for specific events...
        self.socket.socket.on('site:save', self.siteListener);
        self.socket.socket.on('camera:save', self.cameraListener);

        self.socket.socket.on('shellresponse', (item) => {
            self.shellResponse[item.unit] = JSON.stringify(_.omit(item, ['unit']), null, '\t')
                .split('\\n')
                .join('\n');
        });

        self.privilegedAccounts = _.filter(self.getCurrentUser().accounts, (account) => {
            let has = hasprivileges.hasPrivilegeFunc(
                'secuvue.site.create',
                account.role.privileges
            );
            if (account.accountId === self.Auth.getCurrentAccountSync().accountId) {
                has = false;
            }
            return has;
        });

        self.hikvisionTaskCB = function hikvisionTaskCB(event, eventObj) {
            const currentUser = self.currentUser._id;
            const currentSite = self.currentSite._id;
            const isSameUser = eventObj.item.user === currentUser;
            const isSameSite = eventObj.item.site === currentSite;

            switch (eventObj.event) {
                case 'created':
                case 'updated':
                    if (
                        isSameUser &&
                        isSameSite &&
                        eventObj.item.type === 'hikvisionLogRetrieval'
                    ) {
                        self.hikvisionLogs.tasks = eventObj.array.filter(
                            (o) =>
                                o.user === currentUser &&
                                o.site === currentSite &&
                                o.type === 'hikvisionLogRetrieval'
                        );
                    }
                    break;
                case 'deleted':
                    self.hikvisionLogs.tasks = eventObj.array.filter(
                        (o) =>
                            o.user === currentUser &&
                            o.site === currentSite &&
                            o.type === 'hikvisionLogRetrieval'
                    );
                    break;
                default: {
                    const index = eventObj.array.indexOf(eventObj.item);
                    if (index !== -1) {
                        eventObj.array.splice(index, 1);
                    }
                    self.hikvisionLogs.tasks = eventObj.array.filter(
                        (o) => o.user === currentUser && o.site === currentSite
                    );
                }
            }
            self.calculateTaskDurations();
        };

        self.initHikvisionLogs();
        self.refreshNotes();
    }

    initializeZoneDefaults(zone) {
        if (zone?.cameraMetadata?.installedDate) {
            const installDate = new Date(zone.cameraMetadata.installedDate);
            zone.cameraMetadata.installDatePicker = installDate;
            zone.cameraMetadata.installTimePicker = installDate;
        }
        if (zone?.cameraMetadata?.warrantyDate) {
            const warrantyDate = new Date(zone.cameraMetadata.warrantyDate);
            zone.cameraMetadata.warrantyDatePicker = warrantyDate;
            zone.cameraMetadata.warrantyTimePicker = warrantyDate;
        }
    }

    ensureDefaults(zone) {
        // ZONE
        if (!zone.analyticsConfiguration) {
            zone.analyticsConfiguration = {};
        }
        if (!zone.analyticsConfiguration.faceConfiguration) {
            zone.analyticsConfiguration.faceConfiguration = {
                enabled: false,
                minsize: [80, 80],
                triggerOn: false,
                scaledown: 1.0,
                minConfidence: 80,
                countThresholdEnabled: false,
                countThreshold: 2,
            };
        }

        if (!zone.analyticsConfiguration.peopleConfiguration) {
            zone.analyticsConfiguration.peopleConfiguration = {
                enabled: false,
                minsize: [80, 80],
                minConfidence: 80,
                triggerOn: false,
                scaledown: 1.0,
            };
        } else if (!_.get(zone.analyticsConfiguration.peopleConfiguration, 'scaledown')) {
            zone.analyticsConfiguration.peopleConfiguration.scaledown = 0.5;
        }
        if (!_.get(zone, 'analyticsConfiguration.humanPoseConfiguration')) {
            zone.analyticsConfiguration.humanPoseConfiguration = {
                enabled: false,
                minsize: [80, 80],
                minConfidence: 60,
                scaledown: 1.0,
            };
        } else if (!_.get(zone, 'analyticsConfiguration.humanPoseConfiguration.scaledown')) {
            zone.analyticsConfiguration.humanPoseConfiguration.scaledown = 0.5;
        }
        if (!_.get(zone, 'analyticsConfiguration.ssdConfiguration')) {
            zone.analyticsConfiguration.ssdConfiguration = {
                enabled: false,
                minsize: [80, 80],
                minConfidence: 80,
                scaledown: 1.0,
            };
        } else if (!_.get(zone, 'analyticsConfiguration.ssdConfiguration.scaledown')) {
            zone.analyticsConfiguration.ssdConfiguration.scaledown = 0.5;
        }
        if (!_.get(zone, 'analyticsConfiguration.vehicleConfiguration')) {
            zone.analyticsConfiguration.vehicleConfiguration = {
                enabled: false,
                minsize: [80, 80],
                minConfidence: 80,
                scaledown: 1.0,
            };
        } else if (!_.get(zone.analyticsConfiguration.vehicleConfiguration, 'scaledown')) {
            zone.analyticsConfiguration.vehicleConfiguration.scaledown = 1.0;
        }

        // CAMERA
        if (!_.get(zone, 'camera.configuration.analyticsConfiguration.humanPoseConfiguration')) {
            zone.camera.configuration.analyticsConfiguration.humanPoseConfiguration = {
                enabled: false,
                minsize: [40, 40],
                minConfidence: 60,
                triggerOn: false,
                scaledown: 0.5,
                countThresholdEnabled: false,
                countThreshold: 2,
                minClassConfidence: 80,
                poseFilter: [],
            };
        } else if (
            !_.get(
                zone,
                'camera.configuration.analyticsConfiguration.humanPoseConfiguration.scaledown'
            )
        ) {
            zone.camera.configuration.analyticsConfiguration.humanPoseConfiguration.scaledown = 0.5;
        } else if (
            !_.get(
                zone,
                'camera.configuration.analyticsConfiguration.humanPoseConfiguration.poseFilter'
            )
        ) {
            zone.camera.configuration.analyticsConfiguration.humanPoseConfiguration.minClassConfidence = 80;
            zone.camera.configuration.analyticsConfiguration.humanPoseConfiguration.poseFilter = [];
        }
        if (!_.get(zone, 'camera.configuration.analyticsConfiguration.ssdConfiguration')) {
            zone.camera.configuration.analyticsConfiguration.ssdConfiguration = {
                enabled: false,
                minsize: [40, 40],
                minConfidence: 80,
                triggerOn: false,
                scaledown: 0.5,
                triggerOnLabels: [],
            };
        } else if (
            !_.get(zone, 'camera.configuration.analyticsConfiguration.ssdConfiguration.scaledown')
        ) {
            zone.camera.configuration.analyticsConfiguration.ssdConfiguration.scaledown = 0.5;
        }

        if (
            !_.get(zone, 'camera.configuration.analyticsConfiguration.faceConfiguration.algorithm')
        ) {
            zone.camera.configuration.analyticsConfiguration.faceConfiguration.algorithm =
                'Openvino';
        }
        if (!zone.camera.configuration.analyticsConfiguration.peopleConfiguration) {
            zone.camera.configuration.analyticsConfiguration.peopleConfiguration = {
                enabled: false,
                minsize: [40, 40],
                minConfidence: 80,
                triggerOn: false,
                scaledown: 0.5,
                countThresholdEnabled: false,
                countThreshold: 2,
            };
        } else if (
            !_.get(
                zone.camera.configuration.analyticsConfiguration.peopleConfiguration,
                'scaledown'
            )
        ) {
            zone.camera.configuration.analyticsConfiguration.peopleConfiguration.scaledown = 0.5;
        }

        if (!_.get(zone, 'camera.configuration.analyticsConfiguration.vehicleConfiguration')) {
            zone.camera.configuration.analyticsConfiguration.vehicleConfiguration = {
                enabled: false,
                minsize: [40, 40],
                minConfidence: 80,
                triggerOn: false,
                scaledown: 0.5,
                countThresholdEnabled: false,
                countThreshold: 2,
            };
        } else if (
            !_.get(
                zone.camera.configuration.analyticsConfiguration.vehicleConfiguration,
                'scaledown'
            )
        ) {
            zone.camera.configuration.analyticsConfiguration.vehicleConfiguration.scaledown = 0.5;
        }
    }

    ensureThresholdDefaults(zone) {
        if (
            !_.get(
                zone,
                'camera.configuration.analyticsConfiguration.faceConfiguration.countThreshold'
            )
        ) {
            zone.camera.configuration.analyticsConfiguration.faceConfiguration.countThreshold = 2;
            zone.camera.configuration.analyticsConfiguration.faceConfiguration.countThresholdEnabled = false;
        }
        if (
            !_.get(
                zone,
                'camera.configuration.analyticsConfiguration.peopleConfiguration.countThreshold'
            )
        ) {
            zone.camera.configuration.analyticsConfiguration.peopleConfiguration.countThreshold = 2;
            zone.camera.configuration.analyticsConfiguration.peopleConfiguration.countThresholdEnabled = false;
        }
        if (
            !_.get(
                zone,
                'camera.configuration.analyticsConfiguration.humanPoseConfiguration.countThreshold'
            )
        ) {
            zone.camera.configuration.analyticsConfiguration.humanPoseConfiguration.countThreshold = 2;
            zone.camera.configuration.analyticsConfiguration.humanPoseConfiguration.countThresholdEnabled = false;
        }
        if (
            !_.get(
                zone,
                'camera.configuration.analyticsConfiguration.vehicleConfiguration.countThreshold'
            )
        ) {
            zone.camera.configuration.analyticsConfiguration.vehicleConfiguration.countThreshold = 2;
            zone.camera.configuration.analyticsConfiguration.vehicleConfiguration.countThresholdEnabled = false;
        }
    }

    // initializeAHDZones(unit) {
    //     let self = this;
    //
    //     if (unit?.ahdModules?.length > 0) {
    //         unit.ahdModules.forEach(ahd => {
    //             let camTypes = ['faceCams', 'ssdCams', 'humanPoseCams'];
    //             camTypes.forEach(type => {
    //                 ahd[type].forEach(cam => {
    //                     let zone = _.find(self.currentSite.zones, o => {
    //                         return o.camera == cam;
    //                     });
    //                     if (zone) {
    //                         zone.ahdAssigned = true;
    //                     }
    //                 });
    //             });
    //         });
    //     }
    // }

    configChanged(conf) {
        const self = this;
        // TODO: Add configChange for pose filter function too
        if (conf === 'people') {
            self.selectedZone.camera.configuration.analyticsConfiguration.peopleConfiguration.scaledown =
                self.selectedZone.analyticsConfiguration.peopleConfiguration.scaledown;
            self.selectedZone.camera.configuration.analyticsConfiguration.peopleConfiguration.minsize =
                self.selectedZone.analyticsConfiguration.peopleConfiguration.minsize;
            self.selectedZone.camera.configuration.analyticsConfiguration.peopleConfiguration.minConfidence =
                self.selectedZone.analyticsConfiguration.peopleConfiguration.minConfidence;
        } else if (conf === 'face') {
            self.selectedZone.camera.configuration.analyticsConfiguration.faceConfiguration.scaledown =
                self.selectedZone.analyticsConfiguration.faceConfiguration.scaledown;
            self.selectedZone.camera.configuration.analyticsConfiguration.faceConfiguration.minsize =
                self.selectedZone.analyticsConfiguration.faceConfiguration.minsize;
            self.selectedZone.camera.configuration.analyticsConfiguration.faceConfiguration.minConfidence =
                self.selectedZone.analyticsConfiguration.faceConfiguration.minConfidence;
        } else if (conf === 'vehicle') {
            self.selectedZone.camera.configuration.analyticsConfiguration.vehicleConfiguration.scaledown =
                self.selectedZone.analyticsConfiguration.vehicleConfiguration.scaledown;
            self.selectedZone.camera.configuration.analyticsConfiguration.vehicleConfiguration.minsize =
                self.selectedZone.analyticsConfiguration.vehicleConfiguration.minsize;
            self.selectedZone.camera.configuration.analyticsConfiguration.vehicleConfiguration.minConfidence =
                self.selectedZone.analyticsConfiguration.vehicleConfiguration.minConfidence;
        } else if (conf === 'humanPose') {
            self.selectedZone.camera.configuration.analyticsConfiguration.humanPoseConfiguration.scaledown =
                self.selectedZone.analyticsConfiguration.humanPoseConfiguration.scaledown;
            self.selectedZone.camera.configuration.analyticsConfiguration.humanPoseConfiguration.minsize =
                self.selectedZone.analyticsConfiguration.humanPoseConfiguration.minsize;
            self.selectedZone.camera.configuration.analyticsConfiguration.humanPoseConfiguration.minConfidence =
                self.selectedZone.analyticsConfiguration.humanPoseConfiguration.minConfidence;
        } else if (conf === 'ssd') {
            self.selectedZone.camera.configuration.analyticsConfiguration.ssdConfiguration.scaledown =
                self.selectedZone.analyticsConfiguration.ssdConfiguration.scaledown;
            self.selectedZone.camera.configuration.analyticsConfiguration.ssdConfiguration.minsize =
                self.selectedZone.analyticsConfiguration.ssdConfiguration.minsize;
            self.selectedZone.camera.configuration.analyticsConfiguration.ssdConfiguration.minConfidence =
                self.selectedZone.analyticsConfiguration.ssdConfiguration.minConfidence;
        }
    }

    redrawZones() {
        const self = this;

        self.scheduleShow = false;

        self.$timeout(() => {
            self.scheduleShow = true;
        }, 0);

        if (self.canvasFlags.keepout) {
            self.canvasFlags.keepout = false;
            self.updateAnalyticsDisplay(
                'keepoutCanvas',
                self.selectedZone,
                self.canvasFlags.keepout
            );
        }
        if (self.canvasFlags.crossLine) {
            self.canvasFlags.crossLine = false;
            self.updateAnalyticsDisplay(
                'crossLineCanvas',
                self.selectedZone,
                self.canvasFlags.crossLine
            );
        }
        if (self.canvasFlags.roi) {
            self.canvasFlags.roi = false;
            self.drawROICanvas(self.selectedZone.camera, self.canvasFlags.roi);
        }
        if (self.canvasFlags.mixMotion) {
            self.canvasFlags.mixMotion = false;
            self.updateAnalyticsDisplay(
                'motionMinAreaCanvas',
                self.selectedZone,
                self.canvasFlags.mixMotion
            );
        }
        if (self.canvasFlags.maxMotion) {
            self.canvasFlags.maxMotion = false;
            self.updateAnalyticsDisplay(
                'motionMaxAreaCanvas',
                self.selectedZone,
                self.canvasFlags.maxMotion
            );
        }
        if (self.canvasFlags.minFace) {
            self.canvasFlags.minFace = false;
            self.updateAnalyticsDisplay(
                'faceMinSizeCanvas',
                self.selectedZone,
                self.canvasFlags.minFace
            );
        }
        if (self.canvasFlags.minFaceCloud) {
            self.canvasFlags.minFaceCloud = false;
            self.updateAnalyticsDisplay(
                'faceMinSizeCanvasCloud',
                self.selectedZone,
                self.canvasFlags.minFaceCloud
            );
        }
        if (self.canvasFlags.minPersonCloud) {
            self.canvasFlags.minPersonCloud = false;
            self.updateAnalyticsDisplay(
                'personMinSizeCanvasCloud',
                self.selectedZone,
                self.canvasFlags.minPersonCloud
            );
        }
        if (self.canvasFlags.minPerson) {
            self.canvasFlags.minPerson = false;
            self.updateAnalyticsDisplay(
                'personMinSizeCanvas',
                self.selectedZone,
                self.canvasFlags.minPerson
            );
        }
        if (self.canvasFlags.heatmap) {
            self.canvasFlags.heatmap = false;
            self.updateAnalyticsDisplay(
                'heatmapCanvas',
                self.selectedZone,
                self.canvasFlags.heatmap
            );
        }
        self.$timeout(() => {
            self.checkRef();
        }, 1000);
    }

    checkRef() {
        const self = this;
        const imageEl2 = self.$document[0].querySelector('#referenceShot');
        if (imageEl2) {
            const { naturalHeight } = imageEl2;
            const { naturalWidth } = imageEl2;
            const profIndex = _.findIndex(
                self.selectedZone.camera.profiles,
                (o) =>
                    self.selectedZone.camera.configuration.analyticsConfiguration.profileToken ===
                    o.profileToken
            );
            if (profIndex !== -1) {
                const expectedHeight = Math.ceil(
                    self.selectedZone.camera.profiles[profIndex].videoEncoderConfiguration
                        .resolution.height * self.selectedZone.camera.configuration.scaleDown
                );
                const expectedWidth = Math.ceil(
                    self.selectedZone.camera.profiles[profIndex].videoEncoderConfiguration
                        .resolution.width * self.selectedZone.camera.configuration.scaleDown
                );
                if (expectedHeight !== naturalHeight || expectedWidth !== naturalWidth) {
                    self.refInvalid = true;
                    self.refStyle = { border: '3px solid red' };
                } else {
                    self.refStyle = {};
                    self.refInvalid = false;
                }
            } else {
                console.log('Could not find');
            }
        }
    }

    redraw(config) {
        const self = this;

        if (config === 'motionConfiguration') {
            if (self.canvasFlags.keepout) {
                self.updateAnalyticsDisplay(
                    'keepoutCanvas',
                    self.selectedZone,
                    self.canvasFlags.keepout
                );
            }
            if (self.canvasFlags.crossLine) {
                self.updateAnalyticsDisplay(
                    'crossLineCanvas',
                    self.selectedZone,
                    self.canvasFlags.crossLine
                );
            }
            if (self.canvasFlags.roi) {
                self.drawROICanvas(self.selectedZone.camera, self.canvasFlags.roi);
            }
            if (self.canvasFlags.mixMotion) {
                self.updateAnalyticsDisplay(
                    'motionMinAreaCanvas',
                    self.selectedZone,
                    self.canvasFlags.mixMotion
                );
            }
            if (self.canvasFlags.maxMotion) {
                self.updateAnalyticsDisplay(
                    'motionMaxAreaCanvas',
                    self.selectedZone,
                    self.canvasFlags.maxMotion
                );
            }
            if (self.canvasFlags.heatmap) {
                self.updateAnalyticsDisplay(
                    'heatmapCanvas',
                    self.selectedZone,
                    self.canvasFlags.heatmap
                );
            }
        } else {
            if (self.canvasFlags.minFace) {
                self.updateAnalyticsDisplay(
                    'faceMinSizeCanvas',
                    self.selectedZone,
                    self.canvasFlags.minFace
                );
            }
            if (self.canvasFlags.minFaceCloud) {
                self.updateAnalyticsDisplay(
                    'faceMinSizeCanvasCloud',
                    self.selectedZone,
                    self.canvasFlags.minFaceCloud
                );
            }
            if (self.canvasFlags.minPersonCloud) {
                self.updateAnalyticsDisplay(
                    'personMinSizeCanvasCloud',
                    self.selectedZone,
                    self.canvasFlags.minPersonCloud
                );
            }
            if (self.canvasFlags.minPerson) {
                self.updateAnalyticsDisplay(
                    'personMinSizeCanvas',
                    self.selectedZone,
                    self.canvasFlags.minPerson
                );
            }
            if (self.canvasFlags.minVehicle) {
                self.updateAnalyticsDisplay(
                    'vehicleMinSizeCanvas',
                    self.selectedZone,
                    self.canvasFlags.minVehicle
                );
            }
        }
    }

    refreshSecutraqAccounts() {
        const self = this;
        this.$http
            .get('/api/accounts/secutraqAccounts')
            .then((response) => {
                self.availableSecutraqAccounts = response.data;
            })
            .catch((err) => {
                console.error(err);
            });
    }

    createSecutraqUnit(type, data) {
        const self = this;
        const unitData = {};
        if (
            type === 'zone' &&
            data.location &&
            data.location.coordinates &&
            data.location.coordinates.length === 2 &&
            data.location.coordinates[0] &&
            data.location.coordinates[1]
        ) {
            [unitData.lng, unitData.lat] = data.location.coordinates;
        } else if (
            self.currentSite.location &&
            self.currentSite.location.coordinates &&
            self.currentSite.location.coordinates.length === 2 &&
            self.currentSite.location.coordinates[0] &&
            self.currentSite.location.coordinates[1]
        ) {
            [unitData.lng, unitData.lat] = self.currentSite.location.coordinates;
            data.location = {
                type: 'Point',
                coordinates: [unitData.lng, unitData.lat],
            };
        } else {
            self.toastr.error('Site location is required to enable Secutraq integration.');
            return null;
        }
        if (!data.secutraqAccountId) {
            self.toastr.error('Secutraq Account is required to enable Secutraq integration.');
            return null;
        }
        if (type === 'zone') {
            unitData.apiType = 'Secuvue Camera';
            unitData.name = `${self.currentSite.alias} - ${data.alias}`;
            unitData.description = `Secuvue Account - ${self.currentSite.accountname}`;
            // TODO: API Callback  <01-08-19, Liaan> //
        } else {
            unitData.apiType = 'Secuvue Controller';
            unitData.name = `${self.currentSite.alias} - ${data.serial}`;
            unitData.description = `Secuvue Account - ${self.currentSite.accountname}`;
        }
        const callbackPromises = [];
        if (type === 'zone') {
            const apiCallbackSnapshot = {
                metaData: {
                    zone: data._id,
                },
                name: unitData.name,
                operation: 'requestSnapshot',
            };
            const snapshotCallbackPromise = self.$http
                .post('api/apiCallbacks/', apiCallbackSnapshot)
                .then((response) => {
                    unitData.requestSnapshotCallback = `${window.location.origin}/api/apiCallbacks/${response.data.account}/${response.data._id}/executeCallback`;
                    self.toastr.info('API Callback created');
                })
                .catch((err) => {
                    console.error(err);
                    self.toastr.error('API Callback create failed');
                });
            callbackPromises.push(snapshotCallbackPromise);
            const apiCallbackVideo = {
                metaData: {
                    zone: data._id,
                },
                name: unitData.name,
                operation: 'requestVideo',
            };
            const videoCallbackPromise = self.$http
                .post('api/apiCallbacks/', apiCallbackVideo)
                .then((response) => {
                    unitData.requestVideoCallback = `${window.location.origin}/api/apiCallbacks/${response.data.account}/${response.data._id}/executeCallback`;
                    self.toastr.info('API Callback created');
                })
                .catch((err) => {
                    console.error(err);
                    self.toastr.error('API Callback create failed');
                });
            callbackPromises.push(videoCallbackPromise);
            const apiCallbackStream = {
                metaData: {
                    zone: data._id,
                },
                name: unitData.name,
                operation: 'initiateStream',
            };
            const streamCallbackPromise = self.$http
                .post('api/apiCallbacks/', apiCallbackStream)
                .then((response) => {
                    unitData.initiateStreamCallback = `${window.location.origin}/api/apiCallbacks/${response.data.account}/${response.data._id}/executeCallback`;
                    self.toastr.info('API Callback created');
                })
                .catch((err) => {
                    console.error(err);
                    self.toastr.error('API Callback create failed');
                });
            callbackPromises.push(streamCallbackPromise);
        }
        return Promise.all(callbackPromises).then(() => {
            this.$http
                .post(`/api/units/${data.secutraqAccountId}/createSecutraqUnit`, unitData)
                .then((response) => {
                    if (response && response.data) {
                        const secutraqUnit = response.data;
                        self.secutraqUnits[secutraqUnit._id] = secutraqUnit;
                        data.secutraqUnitId = secutraqUnit._id;
                        if (type === 'zone') {
                            return self.applySecutraqConfigZone(data);
                        }
                        return self.applySecutraqConfigUnit(data);
                    }
                    console.error('Failed to create Secutraq Unit');
                    throw new Error('Secutraq Unit create failed');
                })
                .then(() => {
                    self.toastr.info('Secutraq Unit Linked');
                })
                .catch((err) => {
                    console.error('Failed to create Secutraq Unit');
                    self.toastr.error(err.message || err, 'Error linking Secutraq');
                    console.error(err);
                });
        });
    }

    removeSecutraqLink(type, data) {
        const self = this;
        if (type === 'zone') {
            const zone = data;
            self.$http
                .post(`/api/cameras/${zone._id}/unlinkSecutraqUnit`)
                .then((response) => {
                    if (response) {
                        self.secutraqUnits[zone.secutraqUnitId] = undefined;
                        zone.secutraqUnitId = undefined;
                        zone.secutraqAccountId = undefined;
                        return null;
                    }
                    console.error('Failed to remove Secutraq link');
                    throw new Error('Secutraq link remove failed');
                })
                .then(() => {
                    self.toastr.info('Secutraq link removed');
                })
                .catch((err) => {
                    console.error('Failed to remove Secutraq link');
                    self.toastr.error(err.message || err, 'Error linking Secutraq');
                    console.error(err);
                });
        } else {
            const unit = data;
            self.$http
                .post(`/api/units/${unit._id}/unlinkSecutraqUnit`)
                .then((response) => {
                    if (response) {
                        self.secutraqUnits[unit.secutraqUnitId] = undefined;
                        unit.secutraqUnitId = undefined;
                        unit.secutraqAccountId = undefined;
                        return null;
                    }
                    console.error('Failed to remove Secutraq link');
                    throw new Error('Secutraq link remove failed');
                })
                .then(() => {
                    self.toastr.info('Secutraq link removed');
                })
                .catch((err) => {
                    console.error('Failed to remove Secutraq link');
                    self.toastr.error(err.message || err, 'Error linking Secutraq');
                    console.error(err);
                });
        }
    }

    refreshSecutraqUnits() {
        const self = this;
        const getUnits = [];
        _.forEach(self.availableZones, (zone) => {
            if (zone.secutraqUnitId && zone.secutraqAccountId) {
                getUnits.push({
                    unitId: zone.secutraqUnitId,
                    accountId: zone.secutraqAccountId,
                });
            }
        });
        _.forEach(self.currentSite.units, (unit) => {
            if (unit.secutraqUnitId && unit.secutraqAccountId) {
                getUnits.push({
                    unitId: unit.secutraqUnitId,
                    accountId: unit.secutraqAccountId,
                });
            }
        });
        const promises = [];
        _.forEach(getUnits, (unit) => {
            const unitPromise = this.$http
                .get(`/api/units/secutraqUnit/${unit.accountId}/${unit.unitId}`)
                .then((response) => {
                    if (response && response.data) {
                        const secutraqUnit = response.data;
                        self.secutraqUnits[secutraqUnit._id] = secutraqUnit;
                    }
                });
            promises.push(unitPromise);
        });
        return Promise.all(promises);
    }

    executeScript(unit) {
        this.shellResponse[unit._id] = '';
        console.log(this.shellScript);
        this.$http
            .post(`/api/sites/${this.currentSite._id}/executeScript`, {
                unit: unit._id,
                msg: this.shellScript[unit._id],
            })
            .then(() => {});
    }

    newAddressLine() {
        if (this.currentSite.address) {
            this.currentSite.address.push('');
        } else {
            this.currentSite.address = [''];
        }
    }

    removeAddressLine(index) {
        this.currentSite.address.splice(index, 1);
    }

    applySiteConfig() {
        const self = this;
        this.$http
            .patch(
                `/api/sites/${this.currentSite._id}`,
                _.omit(this.currentSite, ['__v', 'units', 'zones'])
            )
            .then(() => {
                self.toastr.success('Site settings applied successfully', {
                    preventOpenDuplicates: true,
                });
            });
    }

    applyUnitConfig(m_unit) {
        const self = this;
        const unit = _.cloneDeep(m_unit);

        if (unit.localstorage && unit.localstorage.roots && unit.localstorage.roots.length > 0) {
            unit.localstorage.roots.forEach((path) => {
                delete path.sizeQuotaUnit;
                delete path.sizeQuotaConverted;
            });
        }
        this.$http
            .patch(
                `/api/units/${unit._id}`,
                _.omit(unit, ['endpoint', '__v', 'cameras', 'availableCameras'])
            )
            .then(
                () => {
                    self.toastr.success('Unit settings applied successfully', {
                        preventOpenDuplicates: true,
                    });
                },
                (err) => {
                    if (err.status !== 401 && err.status !== 403) console.error(err);
                }
            );
    }

    applySecutraqConfigZone(zone) {
        const self = this;
        let newCam = zone;
        if (typeof newCam.unit === 'object' && newCam.unit._id) {
            newCam.unit = newCam.unit._id;
        }
        newCam = _.pick(newCam, [
            'enableSecutraqIntegration',
            'secutraqUnitId',
            'secutraqAccountId',
            'location',
            'alias',
        ]);
        return self.$http.patch(`api/cameras/${zone._id}`, newCam).then((response) => {
            if (response) {
                return null;
            }
            throw new Error('Zone update failed');
        });
    }

    applySecutraqConfigUnit(unit) {
        const self = this;
        unit.location = self.currentSite.location;
        const nunit = _.pick(unit, [
            '_id',
            'enableSecutraqIntegration',
            'secutraqUnitId',
            'secutraqAccountId',
            'location',
            'alias',
        ]);
        return self.$http.patch(`api/units/${unit._id}`, nunit).then((response) => {
            if (response) {
                return null;
            }
            throw new Error('Unit update failed');
        });
    }

    applyCameraConfig(zone) {
        const self = this;
        self.checkRef();

        const newCam = _.cloneDeep(zone);
        if (typeof newCam.unit === 'object' && newCam.unit._id) {
            newCam.unit = newCam.unit._id;
        }

        self.$http
            .patch(`api/cameras/${zone._id}`, _.omit(newCam, ['camera.endpoint', '__v']))
            .then(() => {
                self.toastr.success(
                    'Camera settings applied successfully',
                    {
                        preventOpenDuplicates: true,
                    },
                    (err) => {
                        if (err.status !== 401 && err.status !== 403) console.error(err);
                    }
                );
            });
    }

    applyReportingConfig() {
        const self = this;

        this.$http
            .patch(
                `/api/sites/${this.currentSite._id}`,
                _.omit(this.currentSite, ['__v', 'units', 'zones'])
            )
            .then(() => {
                self.toastr.success('Reporting settings applied successfully', {
                    preventOpenDuplicates: true,
                });
            });
    }

    applyTelegramConfig() {
        const self = this;

        // TODO: Handle pause toggle better
        if (
            this.currentSite.telegramConfiguration.paused &&
            !this.currentSite.telegramConfiguration.pauseTime
        ) {
            this.currentSite.telegramConfiguration.pauseTime = Date.now();
        }

        this.$http
            .patch(
                `/api/sites/${this.currentSite._id}`,
                _.omit(this.currentSite, ['__v', 'units', 'zones'])
            )
            .then(() => {
                self.toastr.success('Telegram settings applied successfully', {
                    preventOpenDuplicates: true,
                });
            });
    }

    deleteCamera(camera) {
        const self = this;
        this.deleteModal(() => {
            self.$http.post(`/api/cameras/${camera._id}/removeCamera`, {}).then(
                () => {},
                (err) => {
                    if (err.status !== 401 && err.status !== 403) console.error(err);
                }
            );
        })(camera.alias);
    }

    rebootCamera(zone) {
        this.$http.post(`/api/cameras/${zone._id}/reboot`, {}).then(
            () => {},
            (err) => {
                if (err.status !== 401 && err.status !== 403) console.error(err);
            }
        );
    }

    requestReferenceShot(zone) {
        const self = this;
        this.$http.post(`/api/cameras/${zone._id}/requestReferenceShot`, {}).then(
            (response) => {
                if (response.status === 200) {
                    self.refStyle = {};
                    self.refInvalid = false;
                }
            },
            (err) => {
                if (err.status !== 401 && err.status !== 403) {
                    console.error(err);
                }
            }
        );
    }

    requestAllReferenceShots() {
        const self = this;
        self.toastr.info(`Requested reference shots for ${self.availableZones.length} sites.`, {
            preventOpenDuplicates: true,
        });
        self.availableZones.forEach((zone) => {
            self.requestReferenceShot(zone);
        });
    }

    testeroonie(yes, one, variable) {
        const self = this;
        // TODO: Stop hacking this to work...
        if (yes === 'Milliseconds') {
            self.$timeout(() => {
                one.camera.configuration.analyticsConfiguration.motionConfiguration[variable] *= 10;
            }, 0);
            self.$timeout(() => {
                one.camera.configuration.analyticsConfiguration.motionConfiguration[variable] /= 10;
            }, 0);
        } else {
            self.$timeout(() => {
                one.camera.configuration.analyticsConfiguration.motionConfiguration[variable] /= 10;
            }, 0);
            self.$timeout(() => {
                one.camera.configuration.analyticsConfiguration.motionConfiguration[variable] *= 10;
            }, 0);
        }
    }

    testeroonie2(yes, one, variable) {
        const self = this;
        // TODO: Stop hacking this to work...
        if (yes === 'Milliseconds') {
            self.$timeout(() => {
                one[variable] *= 10;
            }, 0);
            self.$timeout(() => {
                one[variable] /= 10;
            }, 0);
        } else {
            self.$timeout(() => {
                one[variable] /= 10;
            }, 0);
            self.$timeout(() => {
                one[variable] *= 10;
            }, 0);
        }
    }

    requestSnapshot(zone) {
        const self = this;
        this.$http.post(`/api/cameras/${zone._id}/requestSnapshot`, {}).then(
            () => {},
            (err) => {
                if (err.status !== 401 && err.status !== 403) {
                    console.error(err);
                    if (err.status === 400) {
                        if (err.data.reason === 'ZoneDown') {
                            self.toastr.warn('The camera at this zone is down', 'Zone Down:', {
                                preventOpenDuplicates: true,
                            });
                        } else {
                            self.toastr.warn('The unit at this zone is down', 'Unit Down:', {
                                preventOpenDuplicates: true,
                            });
                        }
                    } else if (err.status === 404) {
                        self.toastr.info('The zone has no camera assigned', 'Zone Not Assigned:', {
                            preventOpenDuplicates: true,
                        });
                    }
                }
            }
        );
    }

    groupUnits(item) {
        if (Object.prototype.hasOwnProperty.call(item.unit, '_id')) {
            return this.unitAliases[item.unit._id]
                ? this.unitAliases[item.unit._id]
                : item.unit._id;
        }
        return this.unitAliases[item.unit] ? this.unitAliases[item.unit] : item.unit;
    }

    requestHeatmap(zone) {
        const self = this;
        self.$http.post(`/api/cameras/${zone._id}/requestHeatmap`, {}).then(
            () => {},
            (err) => {
                if (err.status !== 401 && err.status !== 403) console.error(err);
            }
        );
    }

    clearHeatmap(zone) {
        this.$http.post(`/api/cameras/${zone._id}/clearHeatmap`, {}).then(
            () => {},
            (err) => {
                if (err.status !== 401 && err.status !== 403) console.error(err);
            }
        );
    }

    clearLines(zone) {
        this.$http.post(`/api/cameras/${zone._id}/clearLines`, {}).then(
            () => {},
            (err) => {
                if (err.status !== 401 && err.status !== 403) console.error(err);
            }
        );
    }

    clearLine(zone, line) {
        this.$http.post(`/api/cameras/${zone._id}/clearLine/${line.id}`, {}).then(
            () => {},
            (err) => {
                if (err.status !== 401 && err.status !== 403) console.error(err);
            }
        );
    }

    removeLastPoint(camera) {
        const self = this;
        const camInd = _.findIndex(self.availableZones, (o) => o.camera._id === camera._id);
        if (self.currentShape[camera._id]) {
            const points = self.currentShape[camera._id].get('points');
            points.pop();
            const thisCanvas = self.roiCanvas;
            thisCanvas.remove(self.currentShape[camera._id]);
            self.currentShape[camera._id] = new fabric.Polygon(points, {
                id: self.currentShape[camera._id].id,
                alias: self.currentShape[camera._id].alias,
                opacity: 0.5,
                selectable: true,
            });
            const valid = self.testValid(self.currentShape[camera._id].get('points'));
            if (!valid) {
                self.currentShape[camera._id].fill = 'red';
            } else {
                self.currentShape[camera._id].fill = 'green';
            }
            self.currentShape[camera._id].lockRotation = true;
            self.currentShape[camera._id].lockMovementX = true;
            self.currentShape[camera._id].lockMovementY = true;
            self.currentShape[camera._id].lockScalingY = true;
            self.currentShape[camera._id].lockScalingX = true;
            self.currentShape[camera._id].hasControls = false;
            thisCanvas.add(self.currentShape[camera._id]);
            thisCanvas.renderAll();
            const roi = [];
            points.forEach((point) => {
                roi.push([point.x / thisCanvas.getWidth(), point.y / thisCanvas.getHeight()]);
            });
            const ind = _.findIndex(
                self.availableZones[camInd].camera.configuration.rois,
                (o) => o.id === self.currentShape[camera._id].id
            );
            if (ind !== -1) {
                self.availableZones[camInd].camera.configuration.rois[ind].points = roi;
                self.availableZones[camInd].camera.configuration.rois[ind].valid = valid;
            }
        }
    }

    removeROI(camera) {
        const self = this;
        const camInd = _.findIndex(self.availableZones, (o) => o.camera._id === camera._id);
        if (self.currentShape[camera._id]) {
            const thisCanvas = self.roiCanvas;
            thisCanvas.remove(self.currentShape[camera._id]);
            const shapeInd = _.findIndex(
                self.availableZones[camInd].camera.configuration.rois,
                (o) => o.id === self.currentShape[camera._id].id
            );
            self.currentShape[camera._id] = null;
            self.availableZones[camInd].camera.configuration.rois.splice(shapeInd, 1);
        }
    }

    selectMode(camera) {
        const self = this;
        const camInd = _.findIndex(self.availableZones, (o) => o.camera._id === camera._id);
        if (
            self.roiMode[camera._id] === 'Edit' ||
            (self.roiMode[camera._id] === 'Add' && self.currentShape[camera._id])
        ) {
            self.roiMode[camera._id] = 'Select';
            const thisCanvas = self.roiCanvas;
            const points = self.currentShape[camera._id].get('points');
            points.pop();
            thisCanvas.remove(self.currentShape[camera._id]);
            self.currentShape[camera._id] = new fabric.Polygon(points, {
                // fill: 'green',
                id: self.currentShape[camera._id].id,
                alias: self.currentShape[camera._id].alias,
                opacity: 0.5,
                selectable: true,
            });
            const valid = self.testValid(self.currentShape[camera._id].get('points'));
            if (!valid) {
                self.currentShape[camera._id].fill = 'red';
            } else {
                self.currentShape[camera._id].fill = 'green';
            }
            self.currentShape[camera._id].lockRotation = true;
            self.currentShape[camera._id].lockMovementX = true;
            self.currentShape[camera._id].lockMovementY = true;
            self.currentShape[camera._id].lockScalingY = true;
            self.currentShape[camera._id].lockScalingX = true;
            self.currentShape[camera._id].hasControls = false;
            thisCanvas.add(self.currentShape[camera._id]);
            const ind = _.findIndex(
                self.availableZones[camInd].camera.configuration.rois,
                (o) => o.id === self.currentShape[camera._id].id
            );

            const roi = [];
            points.forEach((point) => {
                roi.push([point.x / thisCanvas.getWidth(), point.y / thisCanvas.getHeight()]);
            });
            if (ind === -1) {
                self.availableZones[camInd].camera.configuration.rois.push({
                    id: self.availableZones[camInd].camera.configuration.rois.length.toString(),
                    alias: self.currentShape[camera._id].alias,
                    points: roi,
                    valid,
                });
            } else {
                self.availableZones[camInd].camera.configuration.rois[ind].points =
                    self.currentShape[camera._id].get('points');
            }
        }
        self.currentShape[camera._id] = null;
    }

    editMode(camera) {
        const self = this;
        // const camInd = _.findIndex(self.availableZones, (o) => o.camera._id === camera._id);
        if (self.currentShape[camera._id] && self.roiMode[camera._id] === 'Select') {
            self.roiMode[camera._id] = 'Edit';
            const points = self.currentShape[camera._id].get('points');
            points.push(points[points.length - 1]);
            const thisCanvas = self.roiCanvas;
            thisCanvas.remove(self.currentShape[camera._id]);
            self.currentShape[camera._id] = new fabric.Polygon(points, {
                id: self.currentShape[camera._id].id,
                alias: self.currentShape[camera._id].alias,
                opacity: 0.5,
                selectable: false,
            });
            const valid = self.testValid(self.currentShape[camera._id].get('points'));
            if (!valid) {
                self.currentShape[camera._id].fill = 'red';
            } else {
                self.currentShape[camera._id].fill = 'green';
            }
            thisCanvas.add(self.currentShape[camera._id]);
            thisCanvas.renderAll();
        }
    }

    addMode(camera) {
        if (this.roiMode[camera._id] === 'Select') {
            this.roiMode[camera._id] = 'Add';
        }
    }

    drawROICanvas(camera, isCollapsed) {
        const self = this;
        const camInd = _.findIndex(self.availableZones, (o) => o.camera._id === camera._id);
        const canvasName = `roiCanvas_${camera._id}`;
        if (typeof self[canvasName] !== 'undefined') {
            self[canvasName].dispose();
        }
        if (!isCollapsed) {
            delete self[canvasName];
            delete self.currentShape[camera._id];
            self.roiMode[camera._id] = 'Select';
            return;
        }
        self[canvasName] = new fabric.Canvas(canvasName, {
            stopContextMenu: true,
            enableRetinaScaling: false,
        });
        const thisCanvas = self[canvasName];

        thisCanvas.clear();
        const imageEl = self.$document[0].querySelector('#referenceShot');
        const canvasEl = self.$document[0].querySelector(`#${canvasName}`);
        if (imageEl && canvasEl) {
            const image = new fabric.Image(imageEl, {
                left: 0,
                top: 0,
                opacity: 1,
            });
            thisCanvas.containerClass = 'snapshot-wrapper';

            thisCanvas.setWidth('100%', { cssOnly: true });
            thisCanvas.setHeight('auto', { cssOnly: true });
            thisCanvas.selection = false;

            self.$timeout(() => {
                thisCanvas.setWidth(canvasEl.clientWidth, { backstoreOnly: true });
                thisCanvas.setHeight(canvasEl.clientWidth / (image.width / image.height), {
                    backstoreOnly: true,
                });
                image.set({
                    scaleX: thisCanvas.width / image.width,
                    scaleY: thisCanvas.height / image.height,
                    originX: 'left',
                    originY: 'top',
                });
                if (self.availableZones[camInd].camera.configuration.rois.length > 0) {
                    self.availableZones[camInd].camera.configuration.rois.forEach((shape) => {
                        const points = [];
                        shape.points.forEach((point) => {
                            points.push({
                                x: point[0] * thisCanvas.getWidth(),
                                y: point[1] * thisCanvas.getHeight(),
                            });
                        });
                        const poly = new fabric.Polygon(points, {
                            id: shape.id,
                            alias: shape.alias,
                            opacity: 0.5,
                            selectable: true,
                        });
                        const valid = self.testValid(points);
                        if (!valid) {
                            poly.fill = 'red';
                        } else {
                            poly.fill = 'green';
                        }
                        poly.lockRotation = true;
                        poly.lockMovementX = true;
                        poly.lockMovementY = true;
                        poly.lockScalingY = true;
                        poly.lockScalingX = true;
                        poly.hasControls = false;
                        thisCanvas.add(poly);
                        thisCanvas.renderAll();
                    });
                }
                thisCanvas.setBackgroundImage(image, thisCanvas.renderAll.bind(thisCanvas));
            }, 0);

            // thiscanvas.renderAll();

            let valid = true;

            thisCanvas.observe('mouse:dblclick', (evt) => {
                if (
                    self.roiMode[camera._id] === 'Edit' ||
                    (self.roiMode[camera._id] === 'Add' &&
                        self.currentShape[camera._id] &&
                        self.currentShape[camera._id].length > 2)
                ) {
                    self.roiMode[camera._id] = 'Select';
                    const points = self.currentShape[camera._id].get('points');
                    points.pop();
                    points.pop();
                    thisCanvas.remove(self.currentShape[camera._id]);
                    self.currentShape[camera._id] = new fabric.Polygon(points, {
                        // fill: 'green',
                        id: self.currentShape[camera._id].id,
                        alias: self.currentShape[camera._id].alias,
                        opacity: 0.5,
                        selectable: true,
                    });
                    valid = self.testValid(self.currentShape[camera._id].get('points'));
                    if (!valid) {
                        self.currentShape[camera._id].fill = 'red';
                    } else {
                        self.currentShape[camera._id].fill = 'green';
                    }
                    self.currentShape[camera._id].lockRotation = true;
                    self.currentShape[camera._id].lockMovementX = true;
                    self.currentShape[camera._id].lockMovementY = true;
                    self.currentShape[camera._id].lockScalingY = true;
                    self.currentShape[camera._id].lockScalingX = true;
                    self.currentShape[camera._id].hasControls = false;
                    thisCanvas.add(self.currentShape[camera._id]);
                    const ind = _.findIndex(
                        self.availableZones[camInd].camera.configuration.rois,
                        (o) => o.id === self.currentShape[camera._id].id
                    );
                    const roi = [];
                    points.forEach((point) => {
                        roi.push([
                            point.x / thisCanvas.getWidth(),
                            point.y / thisCanvas.getHeight(),
                        ]);
                    });
                    if (ind === -1) {
                        self.availableZones[camInd].camera.configuration.rois.push({
                            id: self.currentShape[camera._id].id,
                            alias: self.currentShape[camera._id].alias,
                            points: roi,
                            valid,
                        });
                    } else {
                        self.availableZones[camInd].camera.configuration.rois[ind].points = roi;
                    }
                } else if (self.currentShape[camera._id]) {
                    const ind = _.findIndex(
                        self.availableZones[camInd].camera.configuration.rois,
                        (o) => o.id === self.currentShape[camera._id].id
                    );
                    if (ind !== -1) {
                        self.availableZones[camInd].camera.configuration.rois.splice(ind, 1);
                    }
                    // self.roiMode[camera._id] = "Add";
                }
                self.currentShape[camera._id] = null;
            });

            thisCanvas.observe('mouse:move', (evt) => {
                const pos = thisCanvas.getPointer(evt.e);
                if (self.roiMode[camera._id] === 'Edit' && self.currentShape[camera._id]) {
                    const points = self.currentShape[camera._id].get('points');
                    points.pop();
                    points.push({
                        x: pos.x,
                        y: pos.y,
                    });
                    thisCanvas.remove(self.currentShape[camera._id]);
                    self.currentShape[camera._id] = new fabric.Polygon(points, {
                        // fill: 'green',
                        id: self.currentShape[camera._id].id,
                        alias: self.currentShape[camera._id].alias,
                        opacity: 0.5,
                        selectable: false,
                        stroke: 'black',
                        strokeWidth: 3,
                    });
                    // console.time('validMove')
                    valid = self.testValid(self.currentShape[camera._id].get('points'));
                    // console.timeEnd('validMove')
                    if (!valid) {
                        self.currentShape[camera._id].fill = 'red';
                    } else {
                        self.currentShape[camera._id].fill = 'green';
                    }
                    thisCanvas.add(self.currentShape[camera._id]);
                    thisCanvas.renderAll();
                }
            });

            thisCanvas.on('mouse:down', (evt) => {
                const pos = thisCanvas.getPointer(evt.e);
                if (self.roiMode[camera._id] === 'Add') {
                    self.currentShape[camera._id] = new fabric.Polygon(
                        [
                            {
                                x: pos.x,
                                y: pos.y,
                            },
                            {
                                x: pos.x + 0.5,
                                y: pos.y + 0.5,
                            },
                        ],
                        {
                            id: uuid(),
                            alias: `Alias ${self.availableZones[camInd].camera.configuration.rois.length + 1}`,
                            fill: 'green',
                            opacity: 0.5,
                            selectable: false,
                        }
                    );
                    thisCanvas.add(self.currentShape[camera._id]);
                    self.roiMode[camera._id] = 'Edit';
                } else if (
                    self.roiMode[camera._id] === 'Edit' &&
                    self.currentShape[camera._id] &&
                    self.currentShape[camera._id].type === 'polygon'
                ) {
                    const points = self.currentShape[camera._id].get('points');
                    points.push({
                        x: pos.x,
                        y: pos.y,
                    });
                    thisCanvas.remove(self.currentShape[camera._id]);
                    self.currentShape[camera._id] = new fabric.Polygon(points, {
                        // fill: 'green',
                        id: self.currentShape[camera._id].id,
                        alias: self.currentShape[camera._id].alias,
                        opacity: 0.5,
                        selectable: false,
                    });
                    valid = self.testValid(self.currentShape[camera._id].get('points'));
                    if (!valid) {
                        self.currentShape[camera._id].fill = 'red';
                    } else {
                        self.currentShape[camera._id].fill = 'green';
                    }
                    thisCanvas.add(self.currentShape[camera._id]);
                } else if (self.roiMode[camera._id] === 'Select') {
                    self.currentShape[camera._id] = thisCanvas.getActiveObject();
                }
            });

            const keyupHandler = function (e) {
                const keyCanvas = self[canvasName];
                if (e.keyCode === 27) {
                    if (
                        self.roiMode[camera._id] === 'Edit' &&
                        self.currentShape[camera._id] &&
                        self.currentShape[camera._id].get('points').length > 3
                    ) {
                        self.roiMode[camera._id] = 'Select';
                        const points = self.currentShape[camera._id].get('points');
                        points.pop();
                        keyCanvas.remove(self.currentShape[camera._id]);
                        self.currentShape[camera._id] = new fabric.Polygon(points, {
                            // fill: 'green',
                            id: self.currentShape[camera._id].id,
                            alias: self.currentShape[camera._id].alias,
                            opacity: 0.5,
                            selectable: true,
                        });
                        valid = self.testValid(self.currentShape[camera._id].get('points'));
                        if (!valid) {
                            self.currentShape[camera._id].fill = 'red';
                        } else {
                            self.currentShape[camera._id].fill = 'green';
                        }
                        self.currentShape[camera._id].lockRotation = true;
                        self.currentShape[camera._id].lockMovementX = true;
                        self.currentShape[camera._id].lockMovementY = true;
                        self.currentShape[camera._id].lockScalingY = true;
                        self.currentShape[camera._id].lockScalingX = true;
                        self.currentShape[camera._id].hasControls = false;
                        keyCanvas.add(self.currentShape[camera._id]);
                        const ind = _.findIndex(
                            self.availableZones[camInd].camera.configuration.rois,
                            (o) => o.id === self.currentShape[camera._id].id
                        );
                        const roi = [];
                        points.forEach((point) => {
                            roi.push([
                                point.x / thisCanvas.getWidth(),
                                point.y / thisCanvas.getHeight(),
                            ]);
                        });
                        if (ind === -1) {
                            self.availableZones[camInd].camera.configuration.rois.push({
                                id: self.currentShape[camera._id].id,
                                alias: self.currentShape[camera._id].alias,
                                points: roi,
                                valid,
                            });
                        } else {
                            self.availableZones[camInd].camera.configuration.rois[ind].points = roi;
                        }
                    } else if (self.mode === 'Add') {
                        self.mode = 'Select';
                    } else if (
                        self.mode === 'Edit' &&
                        self.currentShape[camera._id].get('points') <= 3
                    ) {
                        self.mode = 'Select';
                        keyCanvas.remove(self.currentShape[camera._id]);
                        const ind = _.findIndex(
                            self.availableZones[camInd].camera.configuration.rois,
                            (o) => o.id === self.currentShape[camera._id].id
                        );
                        if (ind !== -1) {
                            self.availableZones[camInd].camera.configuration.rois.splice(ind, 1);
                        }
                        // self.roiMode[camera._id] = "Add";
                    } else if (self.currentShape) {
                        const ind = _.findIndex(
                            self.availableZones[camInd].camera.configuration.rois,
                            (o) => o.id === self.currentShape[camera._id].id
                        );
                        if (ind !== -1) {
                            self.availableZones[camInd].camera.configuration.rois.splice(ind, 1);
                        }
                    }
                    self.currentShape[camera._id] = null;
                }
            };
        } else {
            console.log('The canvas or image is missing');
        }
    }

    updateKeepoutZone(operation, camera) {
        const canvasName = 'keepoutCanvas';
        const thisCanvas = this[canvasName];

        if (operation === 'add') {
            const zone = {
                left: 0,
                top: 0,
                right: 500,
                bottom: 500,
            };
            camera.configuration.analyticsConfiguration.motionConfiguration.keepoutZones.push(zone);
            // create a rectangle object
            const rect = new fabric.Rect({
                left: zone.left,
                top: zone.top,
                fill: 'rgba(255,0,0, 0.5)',
                width: zone.right - zone.left,
                height: zone.bottom - zone.top,
                transparentCorners: false,
                hasRotatingPoint: false,
            });

            thisCanvas.add(rect);
            rect.center();
            rect.setCoords();
        }
        if (operation === 'delete') {
            const obj = thisCanvas.getActiveObject();
            const objects = thisCanvas.getObjects();
            const idx = _.indexOf(objects, obj);
            camera.configuration.analyticsConfiguration.motionConfiguration.keepoutZones.splice(
                idx,
                1
            );
            thisCanvas.remove(obj);
        }

        thisCanvas.renderAll();
    }

    createLine(camera, canvasName, thisCanvas, line) {
        const self = this;

        const fabricLine = new fabric.Line(
            [
                line.line[0].x * thisCanvas.width,
                line.line[0].y * thisCanvas.height,
                line.line[1].x * thisCanvas.width,
                line.line[1].y * thisCanvas.height,
            ],
            {
                strokeWidth: 5,
                strokeDashArray: [25, 10],
                stroke: 'rgba(255,0,0,0.8)',
                originX: 'center',
                originY: 'center',
                perPixelTargetFind: true,
                targetFindTolerance: 10,
                hasControls: false,
            }
        );

        fabricLine.set(
            'shadow',
            new fabric.Shadow({
                color: 'rgba(20, 20, 20, 1)',
                blur: 2,
                offsetX: 1,
                offsetY: 1,
            })
        );

        const coords = Geometry.calculatePerpendicularBisectPoints(fabricLine, 50);

        const textA = new fabric.Text(`A\n${line.numBToA}`, {
            fontSize: 16,
            fontFamily: 'Monospace',
            textAlign: 'center',
            left: coords[2],
            top: coords[3],
            textBackgroundColor: 'rgba(0,200,0,1)',
            originX: 'center',
            originY: 'center',
            shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
            selectable: false,
            hasBorders: false,
            hasControls: false,
        });

        const textB = new fabric.Text(`B\n${line.numAToB}`, {
            fontSize: 16,
            fontFamily: 'Monospace',
            textAlign: 'center',
            left: coords[0],
            top: coords[1],
            fill: 'white',
            shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
            textBackgroundColor: 'rgba(0,0,200,1)',
            originX: 'center',
            originY: 'center',
            selectable: false,
            hasBorders: false,
            hasControls: false,
        });

        const textAlias = new fabric.Text(line.alias, {
            fontSize: 16,
            fontFamily: 'Monospace',
            textAlign: 'center',
            left: fabricLine.left,
            top: fabricLine.top - fabricLine.height / 2 - 20,
            shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
            textBackgroundColor: 'rgba(200,200,200,0.5)',
            originX: 'center',
            originY: 'center',
            // padding: 10,
            selectable: false,
            hasBorders: false,
            hasControls: false,
        });

        thisCanvas.add(textA);
        thisCanvas.add(textAlias);
        thisCanvas.add(textB);
        thisCanvas.sendToBack(textAlias);
        fabricLine.textA = textA;
        fabricLine.textB = textB;
        fabricLine.textAlias = textAlias;

        const firstCircle = new fabric.Circle({
            left: fabricLine.x1,
            top: fabricLine.y1,
            strokeWidth: 3,
            radius: 6,
            fill: 'rgba(0,0,0,0)',
            stroke: 'rgba(0,100,0,1)',
            hasControls: false,
            hasBorders: false,
            originX: 'center',
            originY: 'center',
        });

        const lastCircle = new fabric.Circle({
            left: fabricLine.x2,
            top: fabricLine.y2,
            strokeWidth: 3,
            radius: 6,
            fill: 'rgba(0,0,0,0)',
            stroke: 'rgba(0,0,255,1)',
            hasControls: false,
            hasBorders: false,
            originX: 'center',
            originY: 'center',
        });

        firstCircle.on('moving', () => {
            firstCircle.fabricLine.set({
                x1: firstCircle.left,
                y1: firstCircle.top,
            });
            const firstCircleCoords = Geometry.calculatePerpendicularBisectPoints(
                firstCircle.fabricLine,
                50
            );
            firstCircle.fabricLine.textB.set({
                left: firstCircleCoords[0],
                top: firstCircleCoords[1],
            });
            firstCircle.fabricLine.textB.setCoords();
            firstCircle.fabricLine.textA.set({
                left: firstCircleCoords[2],
                top: firstCircleCoords[3],
            });
            firstCircle.fabricLine.textA.setCoords();
            firstCircle.fabricLine.crossLine.line[0] = {
                x: firstCircle.left / thisCanvas.width,
                y: firstCircle.top / thisCanvas.height,
            };
            firstCircle.fabricLine.setCoords();
            firstCircle.fabricLine.textAlias.set({
                left: firstCircle.fabricLine.left,
                top: firstCircle.fabricLine.top - firstCircle.fabricLine.height / 2 - 20,
            });
            firstCircle.fabricLine.textAlias.setCoords();

            thisCanvas.renderAll();
            self.selectedLine = firstCircle.fabricLine.crossLine;
            return true;
        });

        lastCircle.on('moving', () => {
            lastCircle.fabricLine.set({
                x2: lastCircle.left,
                y2: lastCircle.top,
            });
            const lastCircleCoords = Geometry.calculatePerpendicularBisectPoints(
                lastCircle.fabricLine,
                50
            );
            lastCircle.fabricLine.textB.set({
                left: lastCircleCoords[0],
                top: lastCircleCoords[1],
            });
            lastCircle.fabricLine.textB.setCoords();
            lastCircle.fabricLine.textA.set({
                left: lastCircleCoords[2],
                top: lastCircleCoords[3],
            });
            lastCircle.fabricLine.textA.setCoords();
            lastCircle.fabricLine.crossLine.line[1] = {
                x: lastCircle.left / thisCanvas.width,
                y: lastCircle.top / thisCanvas.height,
            };
            lastCircle.fabricLine.setCoords();
            lastCircle.fabricLine.textAlias.set({
                left: lastCircle.fabricLine.left,
                top: lastCircle.fabricLine.top - lastCircle.fabricLine.height / 2 - 20,
            });
            lastCircle.fabricLine.textAlias.setCoords();
            thisCanvas.renderAll();
            self.selectedLine = lastCircle.fabricLine.crossLine;
            return true;
        });

        fabricLine.on('moving', () => {
            let y1 = fabricLine.top - fabricLine.height / 2;
            let y2 = fabricLine.top - fabricLine.height / 2 + fabricLine.height;
            // Top is bottom and bottom is top
            if (fabricLine.firstCircle.top > fabricLine.lastCircle.top) {
                y2 = fabricLine.top - fabricLine.height / 2;
                y1 = fabricLine.top - fabricLine.height / 2 + fabricLine.height;
            }

            let x1;
            let x2;
            if (fabricLine.firstCircle.left < fabricLine.lastCircle.left) {
                x2 = fabricLine.left + fabricLine.width - fabricLine.width / 2;
                x1 = fabricLine.left - fabricLine.width / 2;
            } else {
                x1 = fabricLine.left + fabricLine.width - fabricLine.width / 2;
                x2 = fabricLine.left - fabricLine.width / 2;
            }

            fabricLine.set({
                x1,
                y1,
                x2,
                y2,
            });

            {
                const lineCoords = Geometry.calculatePerpendicularBisectPoints(fabricLine, 50);
                fabricLine.textB.set({
                    left: lineCoords[0],
                    top: lineCoords[1],
                });
                fabricLine.textB.setCoords();
                fabricLine.textA.set({
                    left: lineCoords[2],
                    top: lineCoords[3],
                });
                fabricLine.textA.setCoords();
            }

            fabricLine.firstCircle.set({
                left: fabricLine.x1,
                top: fabricLine.y1,
            });
            fabricLine.firstCircle.setCoords();
            fabricLine.lastCircle.set({
                left: fabricLine.x2,
                top: fabricLine.y2,
            });
            fabricLine.lastCircle.setCoords();
            fabricLine.crossLine.line[0] = {
                x: fabricLine.x1 / thisCanvas.width,
                y: fabricLine.y1 / thisCanvas.height,
            };
            fabricLine.crossLine.line[1] = {
                x: fabricLine.x2 / thisCanvas.width,
                y: fabricLine.y2 / thisCanvas.height,
            };
            fabricLine.textAlias.set({
                left: fabricLine.left,
                top: fabricLine.top - fabricLine.height / 2 - 20,
            });
            fabricLine.textAlias.setCoords();
            thisCanvas.renderAll();
            self.selectedLine = fabricLine.crossLine;
            return true;
        });

        fabricLine.on('selected', () => {
            self.selectedLine = fabricLine.crossLine;
            return true;
        });

        firstCircle.fabricLine = fabricLine;
        lastCircle.fabricLine = fabricLine;
        fabricLine.firstCircle = firstCircle;
        fabricLine.lastCircle = lastCircle;
        fabricLine.crossLine = line;

        thisCanvas.add(fabricLine);
        thisCanvas.add(firstCircle);
        thisCanvas.add(lastCircle);
        thisCanvas.renderAll();

        return fabricLine;
    }

    updateAlias(id, index) {
        const self = this;
        const ind = _.findIndex(
            self.availableZones[index].camera.configuration.rois,
            (o) => o.id === self.currentShape[id].id
        );
        if (ind !== -1) {
            self.availableZones[index].camera.configuration.rois[ind].alias =
                self.currentShape[id].alias;
        }
    }

    updateLineCross(operation, camera) {
        const self = this;
        const canvasName = 'crossLineCanvas';
        const thisCanvas = this[canvasName];

        if (operation === 'add') {
            const line = {
                id: uuid(),
                alias: `New Line ${Math.round(Math.random() * 1000)}`,
                areaThreshold: 0,
                line: [
                    { x: 0.5, y: 0.8 },
                    { x: 0.5, y: 0.2 },
                ],
                countAToB: true,
                countBToA: true,
                tripAToB: false,
                tripRightToA: false,
                numAToB: 0,
                numBToA: 0,
                rateLimit: 500,
            };

            self.camLineRateLimit[line.id] = 'Milliseconds';
            if (
                camera.configuration.analyticsConfiguration.motionConfiguration.crossLines ===
                undefined
            ) {
                camera.configuration.analyticsConfiguration.motionConfiguration.crossLines = [];
            }
            camera.configuration.analyticsConfiguration.motionConfiguration.crossLines.push(line);
            const crossLine =
                camera.configuration.analyticsConfiguration.motionConfiguration.crossLines[
                    camera.configuration.analyticsConfiguration.motionConfiguration.crossLines
                        .length - 1
                ];
            this.createLine(camera, canvasName, thisCanvas, crossLine);
        }
        if (operation === 'delete') {
            let obj = thisCanvas.getActiveObject();
            if (obj.fabricLine) {
                obj = obj.fabricLine;
            }
            const idx = _.indexOf(
                camera.configuration.analyticsConfiguration.motionConfiguration.crossLines,
                obj.crossLine
            );
            delete self.camLineRateLimit[obj.crossLine.id];
            camera.configuration.analyticsConfiguration.motionConfiguration.crossLines.splice(
                idx,
                1
            );
            thisCanvas.remove(obj.firstCircle);
            thisCanvas.remove(obj.lastCircle);
            thisCanvas.remove(obj.textAlias);
            thisCanvas.remove(obj.textA);
            thisCanvas.remove(obj.textB);
            thisCanvas.remove(obj);
        }
        thisCanvas.renderAll();
    }

    showUnboundedZones() {
        const self = this;
        if (!self.showUnbounded) {
            self.availableZones = _.filter(self.zonesBackup, (o) => !o.disabled);
        } else {
            self.availableZones = _.filter(
                self.zonesBackup,
                (o) => o.camera !== 'UNBOUNDED' && !o.disabled
            );
        }
    }

    updateAnalyticsDisplay(canvasName, zone, isCollapsed) {
        const self = this;
        if (typeof this[canvasName] !== 'undefined') {
            this[canvasName].dispose();
        }
        if (!isCollapsed) {
            delete this[canvasName];
            return;
        }
        this[canvasName] = new fabric.Canvas(canvasName, {
            stopContextMenu: true,
            enableRetinaScaling: false,
        });
        const thisCanvas = this[canvasName];
        thisCanvas.clear();

        let xScale;
        let yScale;

        const imageEl = this.$document[0].querySelector('#referenceShot');
        const canvasEl = this.$document[0].querySelector(`#${canvasName}`);

        if (imageEl && canvasEl) {
            let url;
            let subConfig;
            let scaleFactor = 1;
            const image = new fabric.Image(imageEl, {
                left: 0,
                top: 0,
                opacity: 1,
            });
            thisCanvas.containerClass = 'snapshot-wrapper';
            thisCanvas.setWidth('100%', { cssOnly: true });
            thisCanvas.setHeight('auto', { cssOnly: true });

            switch (canvasName) {
                case 'motionMinAreaCanvas':
                case 'motionMaxAreaCanvas':
                    subConfig =
                        zone.camera.configuration.analyticsConfiguration.motionConfiguration;
                    break;
                case 'humanPoseMinSizeCanvas':
                    subConfig =
                        zone.camera.configuration.analyticsConfiguration.humanPoseConfiguration;
                    // eslint-disable-next-line prefer-destructuring
                    subConfig.minsize[1] = subConfig.minsize[0];
                    break;
                case 'SSDMinSizeCanvas':
                    subConfig = zone.camera.configuration.analyticsConfiguration.ssdConfiguration;
                    // eslint-disable-next-line prefer-destructuring
                    subConfig.minsize[1] = subConfig.minsize[0];
                    break;
                case 'faceMinSizeCanvas':
                    subConfig = zone.camera.configuration.analyticsConfiguration.faceConfiguration;
                    // eslint-disable-next-line prefer-destructuring
                    subConfig.minsize[1] = subConfig.minsize[0];
                    break;
                case 'faceMinSizeCanvasCloud':
                    subConfig = zone.analyticsConfiguration.faceConfiguration;
                    // eslint-disable-next-line prefer-destructuring
                    subConfig.minsize[1] = subConfig.minsize[0];
                    break;
                case 'vehicleMinSizeCanvas':
                    subConfig = zone.analyticsConfiguration.vehicleConfiguration;
                    // eslint-disable-next-line prefer-destructuring
                    subConfig.minsize[1] = subConfig.minsize[0];
                    break;
                case 'personMinSizeCanvasCloud':
                    subConfig = zone.analyticsConfiguration.peopleConfiguration;
                    // eslint-disable-next-line prefer-destructuring
                    subConfig.minsize[1] = subConfig.minsize[0];
                    break;
                case 'personMinSizeCanvas':
                    subConfig =
                        zone.camera.configuration.analyticsConfiguration.peopleConfiguration;
                    // eslint-disable-next-line prefer-destructuring
                    subConfig.minsize[1] = subConfig.minsize[0];
                    break;
                case 'heatmapCanvas':
                    if (zone.camera.heatmap) {
                        url = `${zone.camera.heatmap}&ts=${+self.moment.now()}`;
                        fabric.Image.fromURL(
                            url,
                            (heatmap) => {
                                heatmap.setOptions({
                                    left: 0,
                                    top: 0,
                                    opacity: self.opacity || 0.5,
                                });
                                heatmap.set('selectable', false);
                                thisCanvas.heatmap = heatmap;
                                thisCanvas.add(heatmap);
                                thisCanvas.renderAll();
                                self.$timeout(() => {
                                    thisCanvas.setWidth(canvasEl.clientWidth, {
                                        backstoreOnly: true,
                                    });
                                    thisCanvas.setHeight(
                                        canvasEl.clientWidth / (image.width / image.height),
                                        { backstoreOnly: true }
                                    );
                                    heatmap.set({
                                        scaleX: thisCanvas.width / heatmap.width,
                                        scaleY: thisCanvas.height / heatmap.height,
                                        originX: 'left',
                                        originY: 'top',
                                    });
                                }, 0);
                            },
                            { crossOrigin: 'anonymous' }
                        );
                    }
                    break;
                case 'keepoutCanvas':
                case 'crossLineCanvas':
                    subConfig =
                        zone.camera.configuration.analyticsConfiguration.motionConfiguration;
                    break;
                default:
                    console.error('Invalid canvas type: ', canvasName);
                    break;
            }
            scaleFactor = subConfig?.scaledown !== undefined ? subConfig.scaledown : 1;

            if (canvasName === 'crossLineCanvas') {
                thisCanvas.on('selection:cleared', () => {
                    self.selectedLine = undefined;
                });
            }

            const drawRect = function (rectWidth, rectHeight) {
                const rect = new fabric.Rect({
                    left: 0,
                    top: 0,
                    fill: 'rgba(0,255,0, 0.5)',
                    width: rectWidth * xScale,
                    height: rectHeight * yScale,
                    transparentCorners: false,
                    hasRotatingPoint: false,
                });

                thisCanvas.add(rect);
                rect.center();
                rect.setCoords();
                thisCanvas.renderAll();
            };

            const drawKeepoutZones = function () {
                _.each(subConfig.keepoutZones, (koZone) => {
                    const rect = new fabric.Rect({
                        left: koZone.left * xScale,
                        top: koZone.top * yScale,
                        fill: 'rgba(255,0,0, 0.5)',
                        width: (koZone.right - koZone.left) * xScale,
                        height: (koZone.bottom - koZone.top) * yScale,
                        transparentCorners: false,
                        hasRotatingPoint: false,
                    });
                    thisCanvas.add(rect);
                    thisCanvas.renderAll();
                });
            };

            const configureZoneBounds = function (obj) {
                const objects = thisCanvas.getObjects();
                const idx = _.indexOf(objects, obj.target);
                const zoneBounds = {};
                zoneBounds.left = obj.target.left / xScale;
                zoneBounds.top = obj.target.top / yScale;
                zoneBounds.right = obj.target.oCoords.br.x / xScale;
                zoneBounds.bottom = obj.target.oCoords.br.y / yScale;
                subConfig.keepoutZones[idx] = zoneBounds;
            };

            // Render rectangles/squares
            self.$timeout(() => {
                thisCanvas.setWidth(canvasEl.clientWidth, { backstoreOnly: true });
                thisCanvas.setHeight(canvasEl.clientWidth / (image.width / image.height), {
                    backstoreOnly: true,
                });
                image.set({
                    scaleX: thisCanvas.width / image.width,
                    scaleY: thisCanvas.height / image.height,
                    originX: 'left',
                    originY: 'top',
                });
                thisCanvas.setBackgroundImage(image, thisCanvas.renderAll.bind(thisCanvas));

                xScale =
                    (zone.camera.configuration.scaleDown * (thisCanvas.width / image.width)) /
                    scaleFactor;
                yScale =
                    (zone.camera.configuration.scaleDown * (thisCanvas.height / image.height)) /
                    scaleFactor;

                switch (canvasName) {
                    case 'motionMinAreaCanvas':
                        const width = Math.sqrt(subConfig.minarea);
                        drawRect(width, width);
                        break;
                    case 'motionMaxAreaCanvas':
                        if (subConfig.maxarea !== -1) {
                            const width = Math.sqrt(subConfig.maxarea);
                            drawRect(width, width);
                        }
                        break;
                    case 'humanPoseMinSizeCanvas':
                    case 'SSDMinSizeCanvas':
                    case 'faceMinSizeCanvas':
                    case 'faceMinSizeCanvasCloud':
                    case 'vehicleMinSizeCanvas':
                    case 'personMinSizeCanvas':
                    case 'personMinSizeCanvasCloud':
                        drawRect(subConfig.minsize[0], subConfig.minsize[1]);
                        break;
                    case 'heatmapCanvas':
                        break;
                    case 'keepoutCanvas':
                        drawKeepoutZones();
                        break;
                    case 'crossLineCanvas':
                        _.each(subConfig.crossLines, (line) => {
                            self.createLine(zone.camera, canvasName, thisCanvas, line);
                        });
                        break;
                    default:
                        break;
                }
            }, 0);

            // Update upon changes
            thisCanvas.on('object:modified', (obj) => {
                let side;
                const width = Math.abs(
                    (obj.target.oCoords.tl.x - obj.target.oCoords.br.x) / xScale
                );
                const height = Math.abs(
                    (obj.target.oCoords.tl.y - obj.target.oCoords.br.y) / yScale
                );
                switch (canvasName) {
                    case 'motionMinAreaCanvas':
                        subConfig.minarea = Math.round(width * height);
                        break;
                    case 'motionMaxAreaCanvas':
                        subConfig.maxarea = Math.round(width * height);
                        break;
                    case 'humanPoseMinSizeCanvas':
                    case 'SSDMinSizeCanvas':
                    case 'faceMinSizeCanvas':
                    case 'faceMinSizeCanvasCloud':
                    case 'vehicleMinSizeCanvas':
                    case 'personMinSizeCanvas':
                    case 'personMinSizeCanvasCloud':
                        side = Math.abs(
                            Math.round(
                                obj.target.oCoords.tl.x / xScale - obj.target.oCoords.br.x / xScale
                            )
                        );
                        subConfig.minsize = [side, side];
                        break;
                    case 'heatmapCanvas':
                        break;
                    case 'keepoutCanvas':
                        configureZoneBounds(obj);
                        break;
                    case 'crossLineCanvas':
                        break;
                    default:
                        break;
                }
                if (canvasName === 'faceMinSizeCanvas' || canvasName === 'faceMinSizeCanvasCloud') {
                    self.configChanged('face');
                }
                if (
                    canvasName === 'personMinSizeCanvas' ||
                    canvasName === 'personMinSizeCanvasCloud'
                ) {
                    self.configChanged('people');
                }
                return true;
            });

            thisCanvas.selection = false;
            thisCanvas.renderAll();
        }
    }

    opacityChanged(event, slider) {
        const self = this;
        // const sliderId = +event.target.id.replace('heatmapSlider_', '');
        // const canvasName = `heatmapCanvas_${sliderId}`;
        const canvasName = 'heatmapCanvas';
        if (typeof self[canvasName] !== 'undefined') {
            const thisCanvas = self[canvasName];
            thisCanvas.heatmap.set('opacity', slider.value);
            thisCanvas.renderAll();
        }
    }

    /**
     * Allows a user to specify a new type of field he desires to include for a contact
     */
    newContactField(contact) {
        const self = this;

        self.$timeout(() => {
            if (!self.newContactFieldOpened) {
                self.newContactFieldOpened = true;
                self.$timeout(() => {
                    self.newContactFieldOpened = true;
                }, 200);
            }
        }, 200);

        const index = _.findIndex(
            self.currentSite.contacts,
            (storedContact) => storedContact.firstname === contact.firstname
        );

        self.$ngConfirm({
            title: '<span style="display:flex; justify-content:center;">Add Contact Field</span>',
            theme: 'light',
            animation: 'top',
            scope: self.$scope,
            closeAnimation: 'bottom',
            content: require('../new-site/new-contact-field.html'),
            escapeKey: true,
            backgroundDismiss: true,
            buttons: {
                // long hand button definition
                ok: {
                    text: 'Ok',
                    btnClass: 'btn-primary',
                    keys: ['enter'], // will trigger when enter is pressed
                    action(scope) {
                        const result =
                            self.selectedField.alias === 'Custom'
                                ? self.customField.toLowerCase()
                                : self.selectedField.alias.toLowerCase();
                        self.selectedField = '';
                        self.currentSite.contacts[index][result] = '';
                        self.newContactFieldOpened = false;
                    },
                },
                close(scope) {
                    self.newContactFieldOpened = false;
                },
            },
        });
    }

    addRoot(unit) {
        const self = this;
        if (!unit.localstorage.roots) {
            unit.localstorage.roots = [];
        }
        unit.localstorage.roots.push({
            sizeQuotaUnit: 'GB',
            sizeQuotaConverted: 1,
        });
        self.convertBytesToSize(unit.localstorage.roots[unit.localstorage.roots.length - 1]);
    }

    deleteRoot(unit, root, $index) {
        unit.localstorage.roots.splice($index, 1);
    }

    addAHDModule(unit) {
        if (!unit.ahdModules) {
            unit.ahdModules = [];
        }
        unit.ahdModules.push({
            enabled: false,
            alias: `AHD Module ${unit.ahdModules.length + 1}`,
            consensus: 1,
            ahdType: '',
            faceCams: [],
            peopleDetectorCams: [],
            humanPoseCams: [],
        });
    }

    removeAHDModule(unit, $index) {
        unit.ahdModules.splice($index, 1);
    }

    addAccessDevice(unit) {
        if (!unit.accessControlDevices) {
            unit.accessControlDevices = [];
        }
        unit.accessControlDevices.push({ deviceType: 'matrix' });
    }

    deleteAccessDevice(unit, root, $index) {
        unit.accessControlDevices.splice($index, 1);
    }

    factoryResetUnitFunc(unit, type) {
        const self = this;
        self.factoryResetUnit = unit;
        let content;
        try {
            switch (type) {
                case 'syncConfigFromServer':
                    content =
                        '  <p>Are you sure you want to sync config from the server to the unit?</p>';
                    break;
                case 'syncConfigFromUnit':
                    content =
                        '  <p>Are you sure you want to sync config from the unit to the server?</p>';
                    break;
                case 'resetConfigs':
                    content = `  <p>Are you sure you want to reset all config for this unit?</p>
                        <p>All unit and camera configurations associated with this unit will be set to the default configurations</p>`;
                    break;
                default:
            }
            self.$ngConfirm({
                title: '<span style="display:flex; justify-content:center;">Reset configurations</span>',
                theme: 'light',
                animation: 'top',
                scope: self.$scope,
                closeAnimation: 'bottom',
                content,
                escapeKey: true,
                backgroundDismiss: true,
                buttons: {
                    // long hand button definition
                    ok: {
                        text: 'Reset',
                        btnClass: 'btn-danger',
                        action(scope) {
                            // TODO: Send factory reset
                            return self.$http.post(
                                `/api/units/${scope.$ctrl.factoryResetUnit._id}/${type}`
                            );
                        },
                    },
                    close(scope) {},
                },
            });
        } catch (e) {
            // console.log("EROOROROROO : ",e);
        }
    }

    // setKeepData(camera) {
    //     if (!camera.configuration.localstorage.enabled && !this.keepData[camera.camera]) {
    //         camera.configuration.localstorage.sizeQuota = -1;
    //     } else if (!camera.configuration.localstorage.enabled && this.keepData[camera.camera]) {
    //         if (camera.configuration.localstorage.sizeQuota < 0 || !camera.configuration.localstorage.sizeQuota) {
    //             camera.configuration.localstorage.sizeQuota = 0;
    //         }
    //     } else if (camera.configuration.localstorage.enabled && camera.configuration.localstorage.sizeQuota < 0) {
    //         camera.configuration.localstorage.sizeQuota = 0;
    //         this.keepData[camera.camera] = false;
    //     } else {
    //         this.keepData[camera.camera] = false;
    //     }
    // }

    setCameraLSEnabled(camera) {
        if (!camera.configuration.localstorage.enabled) {
            // this.keepData[camera.camera] = true;
        } else if (
            camera.configuration.localstorage.enabled &&
            (camera.configuration.localstorage.sizeQuota < 0 ||
                !camera.configuration.localstorage.sizeQuota)
        ) {
            camera.configuration.localstorage.sizeQuota = 0;
            // this.keepData[camera.camera] = false;
        } else {
            // this.keepData[camera.camera] = false;
        }
    }

    migrateAccount() {
        const self = this;
        const modalTemplate = `
            <div class="modal-header">
                <h3 class="modal-title">Migrate Account</h3>
            </div>
            <div class="modal-body">
                <h4>Are you sure you want to migrate this site to {{$ctrl.changeToAccount.name}}</h4>
            </div>
            <div class="controls modal-footer">
                <button class="btn pull-right btn-cancel" type="button" ng-click="$ctrl.cancel()">Cancel</button>
                <button class="btn pull-right btn-primary" type="button" ng-click="$ctrl.migrate()">Change</button>
            </div>`;

        this.$uibModal
            .open({
                animation: true,
                backdrop: true,
                template: modalTemplate,
                controller: MigrateSiteController,
                controllerAs: '$ctrl',
                size: 'lg',
                resolve: {
                    changeToAccount() {
                        return self.changeToAccount;
                    },
                    currentSiteId() {
                        return self.currentSite._id;
                    },
                    asideModalInstance() {
                        return self.$uibModalInstance;
                    },
                },
            })
            .result.then((result) => {
                if (result.done) {
                    self.$uibModalInstance.close({ migrate: true });
                }
            });
    }

    editSite($event, site) {
        this.$uibModalInstance.close({
            edit: {
                event: $event,
                site,
            },
        });
    }

    onToggleShowEvents($event) {
        const self = this;
        const shouldShow = self.currentSite.eventStack;
        const keys = Object.keys(self.currentSite.events);
        if (shouldShow) {
            // All Events should not be enabled when show notification is enabled only on disable should all be disabled.
            // keys.forEach((key) => {
            //     self.currentSite.Events[key] = true;
            // });
        } else {
            keys.forEach((key) => {
                self.currentSite.events[key] = false;
            });
        }
    }

    convertSizeToBytes(pathSettings) {
        if (pathSettings && pathSettings.sizeQuotaUnit && pathSettings.sizeQuotaConverted) {
            const units = ['bytes', 'kB', 'MB', 'GB', 'TB'];
            let number = units.indexOf(pathSettings.sizeQuotaUnit);
            if (!number || number < 2) {
                number = 2;
            }
            pathSettings.sizeQuota = pathSettings.sizeQuotaConverted * 1024 ** Math.floor(number);
        } else {
            pathSettings.sizeQuota = 0;
        }
    }

    openTemplateModal(zone, loadTemplate, unit, multi) {
        const self = this;
        this.$uibModal
            .open({
                animation: true,
                backdrop: true,
                template: require('../templates/save-load-template.html'),
                controller: TemplateSaveController,
                controllerAs: '$ctrl',
                size: 'lg',
                resolve: {
                    unit() {
                        if (loadTemplate) {
                            return unit;
                        }
                        return undefined;
                    },
                    selectedZone() {
                        if (!loadTemplate) {
                            return _.cloneDeep(zone);
                        }
                        return undefined;
                    },
                    loadTemplate() {
                        return loadTemplate;
                    },
                    multiTemplate() {
                        return multi;
                    },
                },
            })
            .result.then((result) => {
                if (result) {
                    if (result.saveTemplate) {
                        return self.$http
                            .post('/api/templates/', result.saveTemplate)
                            .then(() => {
                                self.toastr.success('Successfully saved template', {
                                    preventOpenDuplicates: true,
                                });
                            })
                            .catch((err) => {
                                self.toastr.error('Could not save template', {
                                    preventOpenDuplicates: true,
                                });
                                console.error('Error saving template:', err.data);
                            });
                    }
                    if (result.loadTemplate && !result.multi) {
                        return self.applyTemplate(result.loadTemplate);
                    }
                    if (result.loadTemplate && result.multi) {
                        return self.applyMultiTemplate(result.loadTemplate);
                    }
                }
                return null;
            });
    }

    applyTemplate(template) {
        const self = this;

        self.selectedZone = self.mergeConfig(self.selectedZone, template.zoneConfig);
        self.selectedZone.camera.configuration = self.mergeConfig(
            self.selectedZone.camera.configuration,
            template.cameraConfig.configuration
        );

        self.scheduleShow = false;

        self.$timeout(() => {
            self.scheduleShow = true;
        }, 0);
        self.applyCameraConfig(self.selectedZone);
    }

    async applyMultiTemplate(template) {
        const self = this;
        let completedZones = 0;
        const totalZones = self.availableZones.length;
        // Any nominal values or arrays will be the source value,
        self.toastr.info(`Applying template to ${totalZones} zones.`, {
            preventOpenDuplicates: true,
        });
        // Any objects will be investigated deeper
        const processZone = async (zone) => {
            zone = self.mergeConfig(zone, template.zoneConfig);
            zone.camera.configuration = self.mergeConfig(
                zone.camera.configuration,
                template.cameraConfig.configuration
            );

            const updatedZone = _.cloneDeep(zone);
            if (typeof updatedZone.unit === 'object' && updatedZone.unit._id) {
                updatedZone.unit = updatedZone.unit._id;
            }

            try {
                await self.$http.patch(
                    `api/cameras/${zone._id}`,
                    _.omit(updatedZone, ['camera.endpoint', '__v'])
                );
                completedZones += 1;
            } catch (err) {
                console.error(err);
            }
        };

        const promiseList = [];
        const zoneKeys = Object.values(self.availableZones);
        zoneKeys.forEach((zone) => {
            promiseList.push(processZone(zone));
        });
        await Promise.all(promiseList);

        self.toastr.success(`Applied template to ${completedZones}/${totalZones} zones.`, {
            preventOpenDuplicates: true,
        });
    }

    mergeConfig(inPlace, newConfig) {
        // Any nominal values or arrays will be the source value,
        // Any objects will be investigated deeper
        const customizer = (obj, src, key) => {
            if (typeof src !== 'undefined') {
                if (_.isArray(obj)) {
                    return src;
                }
                if (_.isObject(obj)) {
                    return _.mergeWith(obj, src, customizer);
                }
                return src;
            }
            return obj;
            // if (src && _.isArray(obj) || _.isObject(obj)) {
            //     console.log('Going deeper:', key);
            //     return _.mergeWith(obj, src, customizer);
            // } else if (typeof src !== 'undefined') {
            //     return src;
            // } else {
            //     return obj;
            // }
        };
        return _.mergeWith(inPlace, newConfig, customizer);
    }

    testIntersection(b1, e1, b2, e2) {
        const det = (e1.x - b1.x) * (e2.y - b2.y) - (e2.x - b2.x) * (e1.y - b1.y);
        if (det === 0) {
            return false;
        }
        const lambda = ((e2.y - b2.y) * (e2.x - b1.x) + (b2.x - e2.x) * (e2.y - b1.y)) / det;
        const gamma = ((b1.y - e1.y) * (e2.x - b1.x) + (e1.x - b1.x) * (e2.y - b1.y)) / det;
        return lambda > 0 && lambda < 1 && gamma > 0 && gamma < 1;
    }

    testValid(testpoints) {
        const self = this;
        const points = _.cloneDeep(testpoints);
        points.push(points[0]);

        let valid = true;
        if (points.length > 3) {
            for (let i = 0; i <= points.length - 4; i++) {
                const testLine = [points[i], points[i + 1]];
                for (let k = i + 2; k < points.length - 1; k++) {
                    const testLine2 = [points[k], points[k + 1]];
                    const testBool = self.testIntersection(
                        testLine[0],
                        testLine[1],
                        testLine2[0],
                        testLine2[1]
                    );
                    if (testBool) {
                        valid = false;
                        break;
                    }
                }
                if (!valid) {
                    break;
                }
            }
        }
        return valid;
    }

    convertBytesToSize(pathSettings, precision) {
        const value = pathSettings.sizeQuota;
        if (Number.isNaN(parseFloat(value)) || !Number.isFinite(value)) {
            pathSettings.sizeQuotaUnit = 'MB';
            pathSettings.sizeQuotaConverted = 0;
            return null;
        }
        if (typeof precision === 'undefined') precision = 1;
        const units = ['bytes', 'kB', 'MB', 'GB', 'TB'];
        const number = Math.floor(Math.log(value) / Math.log(1024));
        if (value === 0) {
            pathSettings.sizeQuotaConverted = 0;
            pathSettings.sizeQuotaUnit = 'MB';
            return null;
        }
        if (number < 2) {
            pathSettings.sizeQuotaConverted = value / 1024 ** Math.floor(number);
            pathSettings.sizeQuotaUnit = 'MB';
            return null;
        }
        if (number > 4) {
            pathSettings.sizeQuotaConverted = value / 1024 ** 4;
            pathSettings.sizeQuotaUnit = 'TB';
            return null;
        }
        pathSettings.sizeQuotaConverted = value / 1024 ** Math.floor(number);
        pathSettings.sizeQuotaUnit = units[number];
        return null;
    }

    removeContact(contactInd) {
        const self = this;
        self.$ngConfirm({
            title: '<span style="display:flex; justify-content:center;">Remove Contact</span>',
            theme: 'light',
            animation: 'top',
            scope: self.$scope,
            closeAnimation: 'bottom',
            escapeKey: true,
            content: `<strong>Do you want to remove ${self.currentSite.contacts[contactInd].firstname} ${self.currentSite.contacts[contactInd].lastname}?</strong>`,
            backgroundDismiss: true,
            buttons: {
                // long hand button definition
                ok: {
                    text: 'Ok',
                    btnClass: 'btn-primary',
                    keys: ['enter'], // will trigger when enter is pressed
                    action(scope) {
                        self.currentSite.contacts.splice(contactInd, 1);
                        self.contactsOpen.splice(contactInd, 1);
                        self.$http
                            .patch(
                                `/api/sites/${self.currentSite._id}`,
                                _.pick(self.currentSite, ['_id', 'contacts'])
                            )
                            .then(() => {
                                self.toastr.success('Contact removed', {
                                    preventOpenDuplicates: true,
                                });
                            });
                    },
                },
                close(scope) {},
            },
        });
    }

    newContact() {
        const self = this;
        const modalTemplate = `
        <div class="modal-header">
            <h3 class="modal-title">New Contact</h3>
        </div>
        <div class="modal-body">
            <div style="display:flex;flex-flow:column;">
                <div style="padding-bottom:1em">
                    <label class="col-lg-2" style="text-transform:capitalize;">First Name</label>
                    <input type="text" ng-model="$ctrl.firstname" placeholder="Enter first name">
                </div>
                <div style="padding-bottom:1em">
                    <label class="col-lg-2" style="text-transform:capitalize;">Last Name</label>
                    <input type="text" ng-model="$ctrl.lastname" placeholder="Enter last name">
                </div>
                <div style="padding-bottom:1em">
                    <label class="col-lg-2" style="text-transform:capitalize;">Phone</label>
                    <input type="text" ng-model="$ctrl.phone" placeholder="Enter phone">
                </div>
                <div style="padding-bottom:1em">
                    <label class="col-lg-2" style="text-transform:capitalize;">Email</label>
                    <input type="text" ng-model="$ctrl.email" placeholder="Enter email">
                </div>
            </div>
        </div>
        <div class="controls  modal-footer">
            <button class="btn pull-right btn-cancel" type="button" ng-click="$ctrl.cancel()">Cancel</button>
            <button class="btn pull-right btn-primary" type="button" ng-click="$ctrl.create()">Create</button>
        </div>
        `;

        this.$uibModal
            .open({
                animation: true,
                backdrop: true,
                template: modalTemplate,
                controller: NewContactController,
                controllerAs: '$ctrl',
                size: 'lg',
                resolve: {
                    currentSite() {
                        return self.currentSite;
                    },
                    asideModalInstance() {
                        return self.$uibModalInstance;
                    },
                },
            })
            .result.then((result) => {
                if (result.done) {
                    self.toastr.success('Added new contact', {
                        preventOpenDuplicates: true,
                    });
                }
            });
    }

    updateNoteFilter(item) {
        const self = this;
        switch (item) {
            case 'order':
                self.NotesModule.orderDescending = !self.NotesModule.orderDescending;
                break;
            case 'attention':
                self.NotesModule.onlyAttention = !self.NotesModule.onlyAttention;
                break;
            case 'search':
                self.NotesModule.searchQuery = '';
                break;
            default:
                break;
        }
        self.refreshNotes();
    }

    handleNoteTag(tag) {
        const self = this;
        if (tag === 'Reset') {
            self.NotesModule.selectedTags = [];
        } else if (self.NotesModule.selectedTags.includes(tag)) {
            self.NotesModule.selectedTags = _.pull(self.NotesModule.selectedTags, tag);
        } else {
            self.NotesModule.selectedTags.push(tag);
        }
        self.refreshNotes();
    }

    refreshNotes() {
        const self = this;

        self.NotesModule.availableTags.forEach((tag) => {
            self.NotesModule.tagCounts[tag] = 0;
        });

        self.NotesModule.visibleNotes = _.orderBy(
            self.currentSite.notes,
            'creationTime',
            self.NotesModule.orderDescending ? 'desc' : 'asc'
        );
        // Filter by tags
        if (
            self.NotesModule.selectedTags.length > 0 &&
            self.NotesModule.selectedTags.length !== self.NotesModule.availableTags.length
        ) {
            self.NotesModule.visibleNotes = _.filter(
                self.NotesModule.visibleNotes,
                (o) => _.intersection(o.tags, self.NotesModule.selectedTags).length > 0
            );
        }
        // Filter by searched text
        if (self.NotesModule.searchQuery) {
            const searchTerm = self.NotesModule.searchQuery.toLowerCase();
            self.NotesModule.visibleNotes = _.filter(
                self.NotesModule.visibleNotes,
                (o) =>
                    o.content.toLowerCase().includes(searchTerm) ||
                    o.topic.toLowerCase().includes(searchTerm)
            );
        }
        // Filter by attention flag
        if (self.NotesModule.onlyAttention) {
            self.NotesModule.visibleNotes = _.filter(
                self.NotesModule.visibleNotes,
                (o) => o.attention
            );
        }

        self.NotesModule.visibleNotes.forEach((note) => {
            note.tags?.forEach((tag) => {
                self.NotesModule.tagCounts[tag] += 1;
            });
        });
    }

    newNote() {
        const self = this;

        if (!Object.prototype.hasOwnProperty.call(self.currentSite, 'notes')) {
            self.currentSite.notes = [];
        }

        this.$uibModal
            .open({
                animation: true,
                backdrop: true,
                template: require('./notes.html'),
                controller: NotesController,
                controllerAs: '$ctrl',
                size: 'm',
                resolve: {
                    currentSite() {
                        return self.currentSite;
                    },
                    asideModalInstance() {
                        return self.$uibModalInstance;
                    },
                    originalNote() {
                        return undefined;
                    },
                },
            })
            .result.then((result) => {
                if (result.done) {
                    self.toastr.success('Added new note', { preventOpenDuplicates: true });
                    self.currentSite.notes = result.notes;
                    self.refreshNotes();
                }
            });
    }

    editNote(note) {
        const self = this;

        this.$uibModal
            .open({
                animation: true,
                backdrop: true,
                template: require('./notes.html'),
                controller: NotesController,
                controllerAs: '$ctrl',
                size: 'm',
                resolve: {
                    currentSite() {
                        return self.currentSite;
                    },
                    asideModalInstance() {
                        return self.$uibModalInstance;
                    },
                    originalNote() {
                        return note;
                    },
                },
            })
            .result.then((result) => {
                if (result.done) {
                    self.toastr.success('Updated note', { preventOpenDuplicates: true });
                    self.refreshNotes();
                }
            });
    }

    updateNoteToggle(note, field) {
        note[field] = !note[field];
        this.$http
            .patch(`/api/sites/${this.currentSite._id}/updateNote`, { note })
            .then((result) => {
                this.currentSite.notes = result.data.notes;
                this.toastr.success('Note updated', { preventOpenDuplicates: true });
                this.refreshNotes();
            })
            .catch((err) => {
                this.toastr.warn('Note update failure', { preventOpenDuplicates: true });
                console.error(err);
            });
    }

    deleteNote(note) {
        const self = this;

        self.$ngConfirm({
            title: '<span style="display:flex; justify-content:center;">Delete Note</span>',
            theme: 'light',
            animation: 'top',
            scope: self.$scope,
            closeAnimation: 'bottom',
            escapeKey: true,
            content: `
            <strong>Are you sure you want to delete this note?</strong>
            `,
            backgroundDismiss: true,
            buttons: {
                ok: {
                    text: 'Ok',
                    btnClass: 'btn-primary',
                    keys: ['enter'],
                    action(scope) {
                        self.$http
                            .patch(`/api/sites/${self.currentSite._id}/deleteNote`, { note })
                            .then((result) => {
                                self.currentSite.notes = result.data.notes;
                                self.toastr.success('Note deleted', {
                                    preventOpenDuplicates: true,
                                });
                                self.refreshNotes();
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    },
                },
                close(scope) {},
            },
        });
    }

    addSecutraqCallbacks(unit) {
        const self = this;
        const secutraqUnit = self.secutraqUnits[unit.secutraqUnitId];
        if (!secutraqUnit) {
            self.toastr.error('No Secutraq unit found');
        }
        const callbackPromises = [];
        if (!secutraqUnit.requestSnapshotCallback) {
            const apiCallbackSnapshot = {
                metaData: {
                    zone: unit._id,
                },
                name: secutraqUnit.name,
                operation: 'requestSnapshot',
            };
            const snapshotCallbackPromise = self.$http
                .post('api/apiCallbacks/', apiCallbackSnapshot)
                .then((response) => {
                    secutraqUnit.requestSnapshotCallback = `${window.location.origin}/api/apiCallbacks/${response.data.account}/${response.data._id}/executeCallback`;
                    self.toastr.info('API Callback created');
                });
            callbackPromises.push(snapshotCallbackPromise);
        }

        if (!secutraqUnit.requestVideoCallback) {
            const apiCallbackVideo = {
                metaData: {
                    zone: unit._id,
                },
                name: secutraqUnit.name,
                operation: 'requestVideo',
            };
            const videoCallbackPromise = self.$http
                .post('api/apiCallbacks/', apiCallbackVideo)
                .then((response) => {
                    secutraqUnit.requestVideoCallback = `${window.location.origin}/api/apiCallbacks/${response.data.account}/${response.data._id}/executeCallback`;
                    self.toastr.info('API Callback created');
                });
            callbackPromises.push(videoCallbackPromise);
        }
        if (!secutraqUnit.initiateStreamCallback) {
            const apiCallbackStream = {
                metaData: {
                    zone: unit._id,
                },
                name: secutraqUnit.name,
                operation: 'initiateStream',
            };
            const streamCallbackPromise = self.$http
                .post('api/apiCallbacks/', apiCallbackStream)
                .then((response) => {
                    secutraqUnit.initiateStreamCallback = `${window.location.origin}/api/apiCallbacks/${response.data.account}/${response.data._id}/executeCallback`;
                    self.toastr.info('API Callback created');
                });
            callbackPromises.push(streamCallbackPromise);
        }
        return Promise.all(callbackPromises)
            .then(() =>
                this.$http.patch(
                    `/api/units/secutraqUnit/${secutraqUnit.account.ref}/${secutraqUnit._id}`,
                    secutraqUnit
                )
            )
            .then((response) => {
                if (response && response.data) {
                    self.secutraqUnits[secutraqUnit._id] = response.data;
                }
                self.toastr.info('Unit updated');
            })
            .catch((err) => {
                console.error(err);
                self.toastr.error('Unit update failed');
            });
    }

    openMetaDatePicker(picker, type, $event) {
        $event.preventDefault();
        $event.stopPropagation();
        this[type][picker] = !this[type][picker];
    }

    openDateTimePicker(picker, $event) {
        $event.preventDefault();
        $event.stopPropagation();
        this[picker] = !this[picker];
    }

    applyBillingDetails() {
        const self = this;
        let tsExpiry = 0;
        const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        let success = true;
        if (
            !_.isArray(_.get(self, 'currentSite.billingDetails.pocContacts')) &&
            _.get(self, 'currentSite.billingDetails.pocContacts')['0']
        ) {
            self.currentSite.billingDetails.pocContacts = [
                self.currentSite.billingDetails.pocContacts['0'],
            ];
        }
        if (self.currentSite.billingDetails.designation === 'POC') {
            if (self.expiryDate && self.expiryTime) {
                tsExpiry = +self
                    .moment(self.expiryDate)
                    .hours(self.expiryTime.getHours())
                    .minutes(self.expiryTime.getMinutes());
            }
            if (tsExpiry.valueOf() < new Date().valueOf()) {
                success = false;
                self.toastr.error(
                    'POC expiry date and time must be later than now',
                    'Invalid expiry date'
                );
            }
            self.currentSite.billingDetails.pocContacts.forEach((c) => {
                if (!emailRegex.test(c.email)) {
                    success = false;
                    self.toastr.error(`Invalid email for ${c.name}`);
                }
                if (!c.name || !c.email) {
                    success = false;
                    self.toastr.error('Empty name or email address for contact person');
                }
            });
            if (self.currentSite.billingDetails.pocContacts.length === 0) {
                success = false;
                self.toastr.error('Contact person required');
            }
        }
        if (success) {
            self.currentSite.billingDetails.pocExpiryDate = tsExpiry;
            self.$http
                .patch(
                    `/api/sites/${self.currentSite._id}`,
                    _.pick(self.currentSite, ['_id', 'billingDetails'])
                )
                .then(() => {
                    self.toastr.success('Site settings applied successfully', {
                        preventOpenDuplicates: true,
                    });
                });
        }
    }

    applyLPRSettings() {
        const self = this;
        const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        let success = true;

        if (
            !_.isArray(_.get(self, 'currentSite.lprSettings.contactPersons')) &&
            _.get(self, 'currentSite.lprSettings.contactPersons.0')
        ) {
            self.currentSite.lprSettings.contactPersons = [
                self.currentSite.lprSettings.contactPersons['0'],
            ];
        }
        if (self.currentSite.lprSettings.contactPersons.length === 0) {
            success = false;
            self.toastr.error('Contact person required');
        }

        self.currentSite.lprSettings.contactPersons.forEach((c) => {
            if (!emailRegex.test(c.email)) {
                success = false;
                self.toastr.error(`Invalid email for ${c.name}`);
            }
            if (!c.name || !c.email) {
                success = false;
                self.toastr.error('Empty name or email address for contact person');
            }
        });
        if (
            _.get(self, 'currentSite.lprSettings.limitDaily') &&
            !_.get(self, 'currentSite.lprSettings.lprLimit')
        ) {
            success = false;
            self.toastr.error('LPR Daily Limit must be bigger than zero');
        }

        self.currentSite.lprSettings.nextReset = self
            .moment()
            .hours(self.lprResetTime.getHours())
            .minutes(self.lprResetTime.getMinutes())
            .startOf('minute');

        if (self.moment.utc() > self.currentSite.lprSettings.nextReset) {
            self.currentSite.lprSettings.nextReset.add(1, 'days');
        }

        self.currentSite.lprSettings.nextReset = +self.currentSite.lprSettings.nextReset;
        self.currentSite.lprSettings.dailyResetTime = self.currentSite.lprSettings.nextReset;
        // TODO: Let's check that the form is valid

        const updateDoc = _.pick(self.currentSite, ['_id', 'lprSettings']);
        updateDoc.lprSettings = _.omit(updateDoc.lprSettings, ['creditsUsed']);

        if (success) {
            self.$http.patch(`/api/sites/${self.currentSite._id}`, updateDoc).then(() => {
                // console.log('SITE SETTINGS:', response.data);
                self.toastr.success('Site settings applied successfully', {
                    preventOpenDuplicates: true,
                });
            });
        }
    }

    disableZone() {
        const self = this;
        self.$ngConfirm({
            title: '<span style="display:flex; justify-content:center;">Disable Zone</span>',
            theme: 'light',
            animation: 'top',
            scope: self.$scope,
            closeAnimation: 'bottom',
            escapeKey: true,
            content: `
        <strong>Are you sure you want to disable ${self.selectedZone.alias}?</strong>
        <div>This will hide the zone from all features on the Secuvue platform, except for operational dashboards and reports.</div>
        `,
            backgroundDismiss: true,
            buttons: {
                // long hand button definition
                ok: {
                    text: 'Ok',
                    btnClass: 'btn-primary',
                    keys: ['enter'], // will trigger when enter is pressed
                    action(scope) {
                        self.selectedZone.disabled = true;
                        self.selectedZone.emitApi = false;
                        self.$http
                            .patch(
                                `api/cameras/${self.selectedZone._id}`,
                                _.omit(self.selectedZone, ['unit', 'camera', '__v'])
                            )
                            .then(() => {
                                const avInd = _.findIndex(
                                    self.availableZones,
                                    (o) => o._id === self.selectedZone._id
                                );
                                if (avInd !== -1) {
                                    self.availableZones.splice(avInd, 1);
                                }
                                self.toastr.success(`${self.selectedZone.alias}`, 'Zone disabled', {
                                    preventOpenDuplicates: true,
                                });
                                self.selectedZone = undefined;
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    },
                },
                close(scope) {},
            },
        });
    }

    disableSite() {
        const self = this;
        self.$ngConfirm({
            title: '<span style="display:flex; justify-content:center;">Disable Zone</span>',
            theme: 'light',
            animation: 'top',
            scope: self.$scope,
            closeAnimation: 'bottom',
            escapeKey: true,
            content: `
            <strong>Are you sure you want to disable ${self.currentSite.alias}?</strong>
            <div>This will hide the site from all features on the Secuvue platform, except for operational dashboards and reports.</div>
        `,
            backgroundDismiss: true,
            buttons: {
                // long hand button definition
                ok: {
                    text: 'Ok',
                    btnClass: 'btn-primary',
                    keys: ['enter'], // will trigger when enter is pressed
                    action(scope) {
                        let canDisable = true;
                        // Check
                        const ind = _.findIndex(
                            self.currentSite.zones,
                            (o) => o.unit !== 'UNBOUNDED' && o.camera !== 'UNBOUNDED'
                        );
                        if (self.currentSite.units.length > 0 || ind !== -1) {
                            // Cannot disable
                            canDisable = false;
                            self.toastr.warning(
                                'Please unbound all units and zones from site before disabling.',
                                'Cannot disable site',
                                { preventOpenDuplicates: true }
                            );
                            return;
                        }
                        if (canDisable) {
                            // Update
                            self.currentSite.disabled = true;
                            self.currentSite.emitApi = false;
                            self.currentSite.zones.forEach((zone) => {
                                zone.emitApi = false;
                            });
                            // TODO: TOASTR
                            // TODO: CLOSE and reload page
                            self.$http
                                .patch(
                                    `/api/sites/${self.currentSite._id}`,
                                    _.omit(self.currentSite, ['__v', 'units', 'zones'])
                                )
                                .then(() =>
                                    self.$uibModalInstance.close({
                                        disabled: true,
                                        site: self.currentSite._id,
                                    })
                                )
                                .catch((err) => {
                                    console.error('Error disabling site:', err);
                                });
                        }
                    },
                },
                close(scope) {},
            },
        });
    }

    changeDateTime(type) {
        const self = this;
        let date;
        if (type === 'install') {
            if (self.selectedZone.cameraMetadata.installDatePicker) {
                date = self.selectedZone.cameraMetadata.installDatePicker;
                if (self.selectedZone.cameraMetadata.installTimePicker) {
                    date.setHours(self.selectedZone.cameraMetadata.installTimePicker.getHours());
                    date.setMinutes(
                        self.selectedZone.cameraMetadata.installTimePicker.getMinutes()
                    );
                }
                self.selectedZone.cameraMetadata.installedDate = date;
            }
        } else if (type === 'warranty') {
            if (self.selectedZone.cameraMetadata.warrantyDatePicker) {
                date = self.selectedZone.cameraMetadata.warrantyDatePicker;
                if (self.selectedZone.cameraMetadata.warrantyTimePicker) {
                    date.setHours(self.selectedZone.cameraMetadata.warrantyTimePicker.getHours());
                    date.setMinutes(
                        self.selectedZone.cameraMetadata.warrantyTimePicker.getMinutes()
                    );
                }
                self.selectedZone.cameraMetadata.warrantyDate = date;
            }
        }
    }

    newCustomField() {
        const self = this;
        if (self.selectedZone.cameraMetadata?.customFields?.length > 0) {
            self.selectedZone.cameraMetadata.customFields.push({
                key: 'New Field',
                value: 'New Value',
            });
        } else {
            self.selectedZone.cameraMetadata.customFields = [
                {
                    key: 'New Field',
                    value: 'New Value',
                },
            ];
        }
    }

    removeCustomField(index) {
        const self = this;
        self.selectedZone.cameraMetadata.customFields.splice(index, 1);
    }

    syncMetaFromOnvif() {
        const self = this;

        if (!self.selectedZone.cameraMetadata) {
            self.selectedZone.cameraMetadata = {};
        }
        const deviceInfo = self.selectedZone.camera?.deviceInfo;
        if (deviceInfo) {
            self.selectedZone.cameraMetadata.manufacturer = deviceInfo.manufacturer;
            self.selectedZone.cameraMetadata.modelCode = deviceInfo.cameraModel;
            self.selectedZone.cameraMetadata.serial = deviceInfo.serialNumber;
            // self.selectedZone.cameraMetadata.commsType = deviceInfo.manufacturer ? deviceInfo.manufacturer;
            // self.selectedZone.cameraMetadata.ipAddress = deviceInfo.manufacturer ? deviceInfo.manufacturer;
        }
        self.selectedZone.cameraMetadata.username = self.selectedZone.camera?.username;
        self.selectedZone.cameraMetadata.password = self.selectedZone.camera?.password;

        const ipRegex =
            /(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/gm;
        self.selectedZone.cameraMetadata.ipAddress =
            self.selectedZone.camera?.profiles[0]?.streamuri?.match(ipRegex)[0];
    }

    openSimulator() {
        const me = this;
        this.$uibModal
            .open({
                animation: true,
                backdrop: false,
                template: require('../simulator/simulator.html'),
                controller: SimulatorController,
                controllerAs: '$ctrl',
                size: 'xlg',
                resolve: {
                    selectedZone() {
                        return me.selectedZone;
                    },
                    motionAlgorithms() {
                        return me.motionAlgorithms;
                    },
                },
            })
            .result.then(() => {
                // TODO process result
            });
    }

    updateTelegramAlerts() {
        const self = this;

        this.$uibModal
            .open({
                animation: true,
                backdrop: true,
                template: require('./telegramAlerts.html'),
                controller: TelegramAlertsController,
                controllerAs: '$ctrl',
                size: 'm',
                resolve: {
                    currentSite() {
                        return self.currentSite;
                    },
                    asideModalInstance() {
                        return self.$uibModalInstance;
                    },
                },
            })
            .result.then((result) => {
                if (!result.noChange) {
                    self.currentSite.telegramConfiguration.paused = result.pauseState;
                    self.currentSite.telegramConfiguration.pauseTime = result.pauseTime;
                    self.currentSite.telegramConfiguration.resumeTime = result.resumeTime;
                }
            });
    }

    getHikvisionLogName(task) {
        const self = this;
        const startTime = self.moment(task.taskOptions.from).format('Y-MM-DD hh-mm A');
        const endTime = self.moment(task.taskOptions.to).format('Y-MM-DD hh-mm A');
        return `${self.currentSite.alias} ${task.taskOptions?.device?.address} ${startTime} to ${endTime}.log`;
    }

    calculateTaskDurations() {
        this.hikvisionLogs.tasks.forEach((task) => {
            task.taskOptions.duration = Math.round(
                this.moment.duration(task.taskOptions.to - task.taskOptions.from).asDays()
            );
        });
    }

    requestHikvisionLogs(unit) {
        const self = this;

        if (self.hikvisionLogs.startTime > self.hikvisionLogs.endTime) {
            const tempTime = self.hikvisionLogs.startTime;
            self.hikvisionLogs.startTime = self.hikvisionLogs.endTime;
            self.hikvisionLogs.endTime = tempTime;
        }

        const timeZoneOffset = new Date().getTimezoneOffset() * -1;

        const startTime = self.hikvisionLogs.startTime.toLocaleString();
        const endTime = self.hikvisionLogs.endTime.toLocaleString();
        const logTask = {
            alias: `Log for ${self.hikvisionLogs.address} from ${startTime} to ${endTime}`,
            user: self.Auth.getCurrentUserSync()._id,
            site: self.currentSite._id,
            unit: unit._id,
            type: 'hikvisionLogRetrieval',
            status: 'starting',
            processes: [
                {
                    type: 'Retrieve Logs',
                    progress: 0,
                },
            ],
            taskOptions: {
                from: +self.moment.utc(self.hikvisionLogs.startTime),
                to: +self.moment.utc(self.hikvisionLogs.endTime),
                tzHours: Math.floor(timeZoneOffset / 60),
                tzMinutes: timeZoneOffset % 60,
                device: {
                    address: self.hikvisionLogs.address,
                    username: self.hikvisionLogs.username,
                    password: self.hikvisionLogs.password,
                },
                logLevel: self.hikvisionLogs.logLevel,
            },
            taskResults: {},
            createdAt: Date.now(),
        };
        self.$http
            .post(`api/tasks/createTask`, logTask)
            .then(() => {})
            .catch((err) => {
                console.log('Error:', err.data.err);
                if (err.data && err.data.err) {
                    if (err.data.err === 'DeviceNotFound') {
                        self.toastr.error('Hikvision device not on network or IP incorrect', {});
                    }
                } else {
                    self.toastr.error('Error retrieving logs', {});
                }
            })
            .finally(() => {
                self.hikvisionLogs.pending = false;
            });
    }

    taskOperation(type, task) {
        switch (type) {
            case 'retry':
                this.$http.post(`api/tasks/retryTask`, task).then((response) => {
                    if (response.data) {
                        this.toastr.info('Task retry issued', { preventOpenDuplicates: true });
                    }
                });
                break;
            case 'delete':
                this.$http.delete(`api/tasks/deleteTask/${task._id}`).then((response) => {
                    if (response.data) {
                        this.toastr.info('Task deleted', { preventOpenDuplicates: true });
                    }
                });
                break;
            case 'cancel':
                task.status = 'cancel';
                this.$http.post(`api/tasks/cancelTask`, task).then((response) => {
                    if (response.data) {
                        this.toastr.info('Task cancelled', { preventOpenDuplicates: true });
                    }
                });
                break;
            default:
                console.error('Unknown task operation type:', type);
        }
    }

    initiateStream(site, zone) {
        const self = this;
        const camera = {
            id: zone._id,
            name: zone.alias,
            account: site.accountId,
            site: zone.site._id,
            siteName: zone.site.alias,
            unit: zone.unit._id,
            source: 'SiteSettings',
            user: self.currentUser._id,
            userName: self.currentUser.name,
        };
        self.liveStreamService.addStream(camera);
    }
}
