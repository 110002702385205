import angular from 'angular';

export class TOTPComponent {
    /* @ngInject */
    constructor($scope, Auth, $http, $state, socket, $timeout, $cookies) {
        this.$scope = $scope;
        this.$cookies = $cookies;
        this.Auth = Auth;
        this.$http = $http;
        this.$state = $state;
        this.$timeout = $timeout;
        this.socket = socket;
    }

    $onInit() {
        const self = this;
        this.errorStates = {
            totpFail: false,
            totpEnrollFail: false,
        };
        if (self.resolve) {
            self.$timeout(() => {
                let targetInput;

                if (self.resolve.mfa.enrolled) {
                    targetInput = angular.element(document.querySelector('#OTP'));
                } else {
                    targetInput = angular.element(document.querySelector('#verifyOTP'));
                }
                targetInput.focus();
            });
        }
    }

    $onDestroy() {

    }

    requestSetup() {
        const self = this;
        if (!self.resolve.mfa.enrolled) {
            self.$http.get('/api/users/mfaSetup')
                .then((res) => {
                    if (res.data && res.data.qrImage && res.data.key) {
                        self.qrImage = res.data.qrImage;
                        self.key = res.data.key;
                    } else {
                        throw new Error('Setup failed');
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }

    verifyCode() {
        const self = this;
        // TODO: Validate code is valid  <22-01-20, Liaan> //
        return self.$http.post('/api/users/mfaSetup', { code: self.verifyOTP })
            .then((res) => {
                if (res) {
                    return self.mfaLogin(self.verifyOTP);
                }
            })
            .catch((err) => {
                self.errorStates.totpEnrollFail = true;
            });
    }

    mfaLogin(code) {
        const self = this;
        return self.Auth.mfaLogin({ code })
            .then((data) => {
                self.socket.reAuth();
                if (data && !data.verified) return self.$state.go('verifyRequest');
                const account = self.Auth.getCurrentAccountSync();
                if (self.modalInstance && !self.resolve.forced) {
                    let lastPos = self.$cookies.get('lastPosition');
                    const landingPage = self.Auth.getLandingPage();
                    if (!lastPos || lastPos === 'login') {
                        lastPos = landingPage;
                    }
                    return self.$state.go(lastPos, { accountID: account.accountId })
                        .catch((err) => {
                            console.error('Error going to last position from login:', err);
                            self.$state.go(landingPage, { accountID: self.Auth.getCurrentAccountSync().accountId })
                                .catch((err2) => {
                                    console.error(`Error going to ${landingPage} from login:`, err2);
                                });
                        });
                }
                return self.$state.reload();
            })
            .then(() => {
                if (self.modalInstance) {
                    self.modalInstance.close();
                }
            })
            .catch((err) => {
                console.error(err);
                self.errorStates.totpFail = true;
                return null;
            });
    }

    copyKey() {
        const self = this;
        const text_to_share = self.key;

        // create temp element
        const copyElement = document.createElement('span');
        copyElement.appendChild(document.createTextNode(text_to_share));
        copyElement.id = 'tempCopyToClipboard';
        angular.element(document.body.append(copyElement));

        // select the text
        const range = document.createRange();
        range.selectNode(copyElement);
        window.getSelection()
            .removeAllRanges();
        window.getSelection()
            .addRange(range);

        // copy & cleanup
        document.execCommand('copy');
        window.getSelection()
            .removeAllRanges();
        copyElement.remove();
    }

    ok() {

    }

    doLog() {
        console.log(this);
    }

    cancel() {
        const self = this;
        self.modalInstance.close();
    }
}

export default angular.module('directives.totp', [])
    .component('totp', {
        template: require('./totp.html'),
        controller: TOTPComponent,
        controllerAs: '$ctrl',
        bindings: {
            modalInstance: '=',
            resolve: '=',
        },
    })
    .name;
