import angular from 'angular';

export default angular.module('cameraViewerApp.directives')
    .directive('overflowTooltip', ($timeout) => ({
        restrict: 'A',
        link(scope, element, attr) {
            $timeout(() => {
                if (element[0].offsetWidth < element[0].scrollWidth) {
                    const title = element[0].innerText;
                    element.attr('title', title);
                }
            });
        },
    }))
    .name;
