export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.userSettings', {
            url: '/userSettings',
            views: {
                'content@main': {
                    template: '<user-settings></user-settings>',
                },
            },
            authenticate: true,
            data: {
                title: 'User Settings',
            },
        });
}
