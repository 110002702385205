export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.mfaSettings', {
            url: '/mfaSettings',
            views: {
                'content@main': {
                    // Remember that camel case gets changed to dash-case
                    // ie. operationalReport becomes operational-report
                    template: '<mfa-settings></mfa-settings>',
                },
            },
            authenticate: true,
            data: {
                title: 'MFA Settings',
            },
        });
}
