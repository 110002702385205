export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.siteOverview', {
            url: '/siteOverview',
            views: {
                'content@main': {
                // Remember that camel case gets changed to dash-case
                // ie. operationalReport becomes operational-report
                    template: '<site-overview></site-overview>',
                },
            },
            authenticate: 'SiteOverview',
            data: {
                title: 'Site Overview',
            },
        });
}
