import angular from 'angular';

const movementHandler = function ($document, $timeout) {
    return {
        restrict: 'A',
        link(scope, elm, attrs) {
            elm.css({ position: 'absolute' });

            let startX;
            let startY;
            let initialMouseX;
            let initialMouseY;

            $timeout(() => {
                const mover = elm.find('.moveable-mover');

                const moveFn = ($event) => {
                    startX = elm.prop('offsetLeft');
                    startY = elm.prop('offsetTop');
                    initialMouseX = $event.clientX;
                    initialMouseY = $event.clientY;
                    $document.bind('mousemove', mousemove);
                    $document.bind('mouseup', mouseup);
                    return false;
                };

                if (mover) {
                    mover.css('cursor', 'move');
                    mover.bind('mousedown', moveFn);
                } else {
                    elm.bind('mousedown', moveFn);
                }
            });

            function mousemove($event) {
                if (!elm.hasClass('moving')) {
                    elm.addClass('moving');
                }
                const dx = $event.clientX - initialMouseX;
                const dy = $event.clientY - initialMouseY;
                elm.css({
                    top: `${startY + dy}px`,
                    left: `${startX + dx}px`,
                });
                return false;
            }

            function mouseup() {
                $document.unbind('mousemove', mousemove);
                $document.unbind('mouseup', mouseup);

                if (elm.hasClass('moving')) {
                    $timeout(() => {
                        elm.removeClass('moving');
                    }, 50);
                }
            }
        },
    };
}

angular.module('cameraViewerApp.directives')
    .directive('movable', ['$document', '$timeout', movementHandler]);
