// import fontawesome from '@fortawesome/fontawesome-pro/js/all'
import '@fortawesome/fontawesome-pro/js/v4-shims';
import angular from 'angular';
import ngAnimate from 'angular-animate';
import ngCookies from 'angular-cookies';
import ngResource from 'angular-resource';
import ngSanitize from 'angular-sanitize';

// NOTE: I was having problems with getting angular-dragdrop working
// I had to npm -i --save jquery-ui-bundle.
import 'jquery-ui-bundle';

import uiRouter from '@uirouter/angularjs';
import uiRouterEvents from '@uirouter/angularjs/release/stateEvents';
import uiBootstrap from 'angular-ui-bootstrap';
import jQuery from 'jquery';
import jQueryUi from 'jquery-ui/ui/widgets/slider';
import uiSlider from 'angular-ui-slider';
import rzSlider from 'angularjs-slider';
import toastr from 'angular-toastr';
import angularMoment from 'angular-moment';
import d3 from 'd3';
// import c3 from 'c3';
import Restangular from 'restangular';
import angularUiTree from 'angular-ui-tree';
import 'slick-carousel';
import 'angular-slick';
import angularConfirm from 'angular-confirm1';

import ngIdle from 'ng-idle';
import gridster from 'angular-gridster';
import ngStorage from 'ngstorage';
import ngTagsInput from 'ng-tags-input';
import ngDragDrop from 'angular-dragdrop';
import NgTable from 'ng-table/bundles/ng-table';
import ngValidationMatch from 'angular-validation-match';
import smartAdminConfig from './smartadmin.config';
import smartAdmin from './_common/module';

import { routeConfig } from './app.config';

import _Auth from '../components/auth/auth.module';
import account from './account';
import navbar from '../components/navbar/navbar.component';
import header from '../components/header/header.component';
import filterHeader from '../components/filterHeader/filterHeader.component';
import footer from '../components/footer/footer.component';
import modal from '../components/modal/modal.service';
import consent from '../components/consent/consent.component';
import live from './live';
import LiveStreamContainer from '../components/live-stream/container/live-stream-container.component';
import LiveStreamService from '../services/live-stream.service';
import materialSwitch from '../components/material-switch/material-switch.component';
import metadataInputs from '../components/metadata-inputs/metadata-inputs.component';
import totp from '../components/totp/totp.component';
import sites from './sites';
import siteNotes from './siteNotes';
import apiCallbacks from './apiCallbacks';
import dashboard from './dashboard';
import maintenanceTimeline from './maintenanceTimeline';
import heatmaps from './heatmaps';
import events from './events';
import main from './main/main.component';
import maintenance from './maintenance';
import units from './units';
import siteOverview from './siteOverview';
import zoneOverview from './zoneOverview';
import accessEvents from './accessEvents';
import report from './report';
import biReport from './biReport';
import operationalReport from './operationalReport';
import usageDashboard from './usageDashboard';
import zoneTemplate from './zoneTemplate';
import fileManager from './fileManager';
import userAdmin from './userAdmin';
import userSettings from './userSettings';
import streamUsage from './streamUsage';
import mfaSettings from './mfaSettings';
import constants from './app.constants';
import util from '../components/util/util.module';
import socket from '../components/socket/socket.service';
import tasks from '../components/tasks/tasks.service';
import unitService from '../services/unit.service';
import accountManagement from './accountManagement';
import apiKeys from './apiKeys';
import reference from './reference';
import plate from './plate';
import directives from './shared/directives';
import services from './shared/services';
import PasswordStrengthMeter from '../components/password-strength-meter/password-strength-meter.component';

import './app.scss';

window.appConfig = smartAdminConfig;

require('cookieconsent');

window.cookieconsent.initialise({
    palette: {
        popup: {
            background: '#252e39',
        },
        button: {
            background: '#14a7d0',
        },
    },
    content: {
        href: '/assets/policy/privacy.html',
    },
});

angular
    .module('cameraViewerApp', [
        ngAnimate,
        header,
        filterHeader,
        ngCookies,
        ngResource,
        ngSanitize,
        uiRouter,
        'SmartAdmin',
        'SmartAdmin.Layout',
        'SmartAdmin.UI',
        'SmartAdmin.Forms',
        'ui.router.state.events',
        uiBootstrap,
        Restangular,
        uiSlider,
        'rzSlider',
        'toastr',
        LiveStreamContainer,
        LiveStreamService,
        materialSwitch,
        metadataInputs,
        totp,
        'ngStorage',
        'ui.tree',
        'slick',
        'cp.ngConfirm',
        'gridster',
        'ngTable',
        'angularMoment',
        'validation.match',
        _Auth,
        ngIdle,
        account,
        userAdmin,
        userSettings,
        mfaSettings,
        main,
        navbar,
        footer,
        live,
        heatmaps,
        units,
        siteOverview,
        zoneOverview,
        accessEvents,
        report,
        biReport,
        operationalReport,
        usageDashboard,
        streamUsage,
        zoneTemplate,
        maintenance,
        dashboard,
        maintenanceTimeline,
        sites,
        siteNotes,
        apiCallbacks,
        events,
        fileManager,
        constants,
        socket,
        accountManagement,
        apiKeys,
        tasks,
        unitService,
        reference,
        plate,
        directives,
        services,
        'ngTagsInput',
        'ngDragDrop',
        modal,
        util,
        PasswordStrengthMeter,
        consent,
    ])
    .config(routeConfig)
    .constant('APP_CONFIG', smartAdminConfig)
    .config((toastrConfig) => {
        'ngInject';

        angular.extend(toastrConfig, {
            containerId: 'toast-container',
            newestOnTop: true,
            positionClass: 'toast-top-right',
            preventOpenDuplicates: true,
        });
    })
    .run(($rootScope, $location, Auth, editableOptions, $state) => {
        'ngInject';

        editableOptions.theme = 'bs3';
        // Redirect to login if route requires auth and you're not logged in
        // $rootScope.$on('$stateChangeStart', function (event, next, nextParams, current) {
        //     console.log('Current From App', current);
        //     console.log('next  From App', next);
        //     console.log('Call is logged in  From App');
        //     Auth.isLoggedIn(function (loggedIn) {
        //         if (next.authenticate && !loggedIn) {
        //             $state.go('login')
        //                 .catch((err) => {
        //                     console.log(err);
        //                 });
        //         }
        //     });
        // });

        $rootScope.$on(
            '$stateChangeError',
            (event, toState, toParams, fromState, fromParams, error) => {
                console.error('State rejected rather use state.target');
            }
        );
    })
    .directive('imgError', function () {
        return {
            link: function (scope, element, attrs) {
                element.bind('error', function (e) {
                    scope.$apply(attrs.imgError);
                });
            },
        };
    });

angular.element(document).ready(() => {
    angular.bootstrap(document, ['cameraViewerApp'], { strictDi: true });
});
