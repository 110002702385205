import angular from 'angular';

export class MetadataInputsComponent {
    /* @ngInject */
    constructor($scope) {
        this.$scope = $scope;
        this.identifier = '';
        this.datePickers = [];
    }

    $onInit() {
        if (!this.metadata) this.metadata = [];
        if (!this.metadataOptions) this.metadataOptions = [];

        this.metadata.forEach((data) => {
            if (!data.type) {
                data.type = 'Custom';
            }
        });
    }

    $onDestroy() {}

    typeChanged(type, data) {
        if (type.key === 'Custom') {
            data.key = '';
            data.type = 'Custom';
        } else {
            data.key = type.key;
            data.type = type.key;
            data.datatype = type.type;
            if (type.type === 'Date') {
                data.value = new Date(data.value);
            }
        }
    }

    openDateTimePicker(index, picker, $event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.datePickers[picker] = !this.datePickers[picker];
    }

    addField() {
        this.metadata.push({ key: '', value: '', type: 'Custom' });
    }

    removeField(i) {
        this.metadata.splice(i, 1);
    }

    doLog() {
        console.log(this);
    }
}

export default angular.module('directives.metadataInputs', []).component('metadataInputs', {
    template: require('./metadata-inputs.component.html'),
    controller: MetadataInputsComponent,
    controllerAs: '$ctrl',
    bindings: {
        metadata: '=',
        metadataOptions: '<',
    },
}).name;
