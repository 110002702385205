import _ from 'lodash';

export default class EditUserModalController {
    $http;
    $scope;
    $uibModalInstance;
    Auth;
    user;
    /* @ngInject */

    constructor($uibModalInstance, Auth, user, $http, $scope, toastr) {
        this.$http = $http;
        this.Auth = Auth;
        this.toastr = toastr;
        this.user = user;
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
    }

    $onInit() {
        const self = this;
        self.currentU = self.Auth.getCurrentUserSync();
    }

    applyChanges(valid) {
        const self = this;
        const pickArr = ['_id', 'name', 'tokenTTL', 'idleDuration'];
        if (self.user._id !== self.currentU._id) {
            pickArr.push('restrictDomains');
            pickArr.push('allowedDomains');
        }
        self.$http.patch(`api/users/${self.user._id}`, _.pick(self.user, pickArr)).then((response) => {
            if (response.status == 200 || response.status == 204) {
                self.toastr.info('User Successfully Updated.');
                self.$uibModalInstance.close();
            }
        }).catch((err) => {
            console.error(err.data);
            self.toastr.error(err.data);
        });
    }

    newDomain() {
        if (this.user.allowedDomains) {
            this.user.allowedDomains.push('');
        } else {
            this.user.allowedDomains = [''];
        }
    }

    removeDomain(index) {
        this.user.allowedDomains.splice(index, 1);
    }

    cancel() {
        this.$uibModalInstance.close({ canceled: true });
    }

    doLog() {
        console.log(this);
    }
}
