import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import routes from './apiKeys.routes';

export default angular.module('cameraViewerApp.apiKeys', [
    uiRouter,
])
    .config(routes)
    .name;

require('./apiKeys.component');
