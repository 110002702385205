export default class RenameTemplateAccountsController {
    $http;
    $scope;
    $uibModalInstance;
    $uibModal;
    $ngConfirm;
    $state;
    Auth;

    /* @ngInject */

    constructor($uibModalInstance, $ngConfirm, Auth, $state, $uibModal, template, $http, $scope, toastr) {
        this.$http = $http;
        this.$state = $state;
        this.$ngConfirm = $ngConfirm;
        this.toastr = toastr;
        this.newUser = {};
        this.$scope = $scope;
        this.Auth = Auth;
        this.accounts = [];
        this.userAccounts = [];
        this.$uibModalInstance = $uibModalInstance;
        this.template = template;
        this.$uibModal = $uibModal;
    }

    $onInit() {
        const self = this;
        self.name = self.template.name;
        self.description = self.template.description;
    }

    save() {
        const self = this;
        self.$uibModalInstance.close({
            save: {
                name: self.name,
                description: self.description,
            },
        });
    }
}
