import _ from 'lodash';

export default class CreateUserModalController {
    $http;
    $scope;
    $uibModalInstance;
    parent;
    userRoleOptions;
    /* @ngInject */

    constructor($uibModalInstance, userRoleOptions, $http, $scope, parent, toastr, Auth) {
        this.$http = $http;
        this.toastr = toastr;
        this.newUser = { idleDuration: 3600, tokenTTL: 7200 };
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.userRoleOptions = userRoleOptions;
        this.parent = parent;
        this.passwordValid = false;
        this.Auth = Auth;
    }

    $onInit() {

    }

    doLog() {
        console.log(this);
    }

    close() {
        this.$uibModalInstance.close();
    }

    create(form) {
        const self = this;
        const valid = form.$valid;
        if (valid) {
            self.newUser.username = self.newUser.username.toLowerCase();
            this.$http.post('api/users/', self.newUser).then((response) => {
                if (response.data) {
                    self.parent.createdUserId = response.data._id;
                    const index = _.findIndex(self.parent.filteredUsers, (user) => user._id === self.parent.createdUserId);
                    if (index >= 0) {
                        self.parent.selectedUsers = [self.parent.filteredUsers[index]];
                    }
                    // TODO : Display error message if create failed
                    const retUser = response.data.name;
                    this.toastr.info(retUser, 'Users created: ', {
                        preventOpenDuplicates: true,
                    });
                    self.$uibModalInstance.close();
                }
            })
                .catch((err) => {
                    if (err.status === 400 && typeof err.data === 'string') {
                        self.toastr.error(err.data, {
                            preventOpenDuplicates: true,
                        });
                    } else {
                        self.toastr.error('Username was already taken', {
                            preventOpenDuplicates: true,
                        });
                    }
                });
        } else {
            self.toastr.warning('Invalid or missing information');
        }
    }

    newDomain() {
        if (this.newUser.allowedDomains) {
            this.newUser.allowedDomains.push('');
        } else {
            this.newUser.allowedDomains = [''];
        }
    }

    removeDomain(index) {
        this.newUser.allowedDomains.splice(index, 1);
    }
}
