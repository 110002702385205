export default function ($stateProvider) {
    'ngInject';

    $stateProvider.state('main.usageDashboard', {
        url: '/usageDashboard',
        views: {
            'content@main': {
                // Remember that camel case gets changed to dash-case
                // ie. usageDashboard becomes usage-dashboard
                template: '<usage-dashboard></usage-dashboard>',
            },
        },
        authenticate: 'UsageDashboard',
        data: {
            title: 'Usage Dashboard',
        },
    });
}
