export default class MigrateSiteController {
    $http;
    $scope;
    changeToAccount;
    currentSiteId;
    $uibModalInstance;
    asideModalInstance;

    /* @ngInject */

    constructor($uibModalInstance, $http, changeToAccount, currentSiteId, toastr, asideModalInstance) {
        this.$http = $http;
        this.$uibModalInstance = $uibModalInstance;
        this.toastr = toastr;
        this.changeToAccount = changeToAccount;
        this.currentSiteId = currentSiteId;
        this.message = 'Hello World';
        this.asideModalInstance = asideModalInstance;
    }

    migrate() {
        const self = this;
        this.$http.post(`/api/sites/${this.currentSiteId}/changeAccount`, {
            account: self.changeToAccount.accountId,
            accountname: self.changeToAccount.name,
        })
            .then((response) => {
                if (response) {
                    self.$uibModalInstance.close({ done: true });
                }
            });
    }

    cancel() {
        this.$uibModalInstance.close();
    }
}
