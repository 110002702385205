import _ from "lodash";

const eventTypeMap = {
    ['motiontrigger']: 'Motion Detected',
    ['nocomms']: 'Site Offline',
    ['camdown']: 'Camera Down',
    ['recognition']: 'Facial Recognition',
    ['lowbattery']: 'Low Battery',
    ['persondetected']: 'Person Detected',
    ['tampers']: 'Tamper Detected',
    ['acfail']: 'Power Failure',
    ['imagerequest']: 'User Request',
    ['flaggedVehicle']: 'Flagged Vehicle Detected',
    ['hddfail']: 'Hard Drive Failure',
    ['linetrip']: 'Line Trip Detected',
    ['peerline']: 'Peer Line Trip',
    ['peermotion']: 'Peer Motion Trip',
    ['metaFlaggedVehicle']: 'SA VOI Flagged Vehicle',
    ['accesscontrol']: 'Access Control',
    ['armed']: 'Site Armed',
    ['disarmed']: 'Site Disarmed',
    ['bypassed']: 'Zone Bypassed',
    ['bypasscancelled']: 'Zone Bypass Removed',
}

export class EventsComponent {
    $http;
    $state;
    socket;
    Auth;
    sites: [];
    cameras: [];
    events: [];
    currentRooms: [];
    filteredEvents: {};
    eventsAll: [];
    NgTableParams;
    moment;
    $sce;

    /*@ngInject*/
    constructor($http, socket, $state, $scope, Auth, $uibModal, $document, $timeout, $interval, NgTableParams, moment, $sce) {
        this.$http = $http;
        this.$state = $state;
        this.socket = socket;
        this.$scope = $scope;
        this.Auth = Auth;
        this.$uibModal = $uibModal;
        this.$document = $document;
        // this.modalToDisplay = '';
        this.$timeout = $timeout;
        this.$interval = $interval;
        this.currentRooms = [];
        this.filteredEvents = {};
        this.NgTableParams = NgTableParams;
        this.moment = moment;
        this.$sce = $sce;

        let self = this;
        this.$scope.$on('$destroy', function() {
            socket.socket.removeListener('event:save', self.theEventListener);
            if(self.currentRooms.length > 0) {
                self.currentRooms.forEach(room => {
                    self.socket.leaveRoom(room);
                });
            }
        });
    }

    $onInit() {
        let self = this;
        //set the preferred default view to true
        self.motiontriggersOpenStatus = false;
        self.flaggedVehicleOpenStatus = false;
        self.persondetectedOpenStatus = false;
        self.metaFlaggedVehicleOpenStatus = false;
        self.nocommsOpenStatus = false;
        self.camdownOpenStatus = false;
        self.hddfailOpenStatus = false;
        self.recognitionOpenStatus = false;
        self.lowBatteryOpenStatus = false;
        self.showSlick = false;

        this.currentSite = ''; //The name of the current site. Should become ID
        this.currentSiteEvents = {}; //Once a site has been selected we load its events here.
        self.eventStack = {};
        self.eventStackBySite = {};

        // self.selectedEvent = null;
        // https://www.npmjs.com/package/slick-carousel --> For list of settings

        self.eventTypes = [
            'metaFlaggedVehicle',
            'flaggedVehicle',
            'linetrip',
            'motiontrigger',
            'nocomms',
            'camdown',
            'hddfail',
            'persondetected',
            'recognition',
            'lowbattery',
            'tampers',
            'acfail',
            'imagerequest'
        ];

        self.eventTypes.forEach(eventType => {
            self.filteredEvents[eventType] = true;
        });

        self.eventIcons = {
            motiontrigger: 'fa-fw fa fa-exclamation-triangle',
            nocomms: 'fa-fw fa fa-unlink',
            camdown: 'fa-fw fa fa-video-slash',
            hddfail: 'fa-fw fa fa-floppy-disk-circle-xmark',
            recognition: 'fa-fw fa fa-user-secret',
            persondetected: 'fa-fw fa fa-male',
            lowbattery: 'fa-fw fa fa-battery-quarter',
            tampers: 'fa-fw fa fa-solid fa-eye-slash',
            acfail: 'fa-fw fa fa-plug',
            imagerequest: 'fa-fw fa fa-picture-o',
            linetrip: 'fas fa-walking',
            metaFlaggedVehicle: 'fas fa-car',
            flaggedVehicle: 'fas fa-car'
        };

        self.panelClasses = {
            persondetected: {
                panel: `border-color: #d6e9c6;`,
                panelHeading: `color: #3c763d; background-color: #dff0d8; border-color: #d6e9c6; border-width: 2px; border-style: solid; padding-top: 3px;`,
                //iconClass: 'recognitionIcon',
                description: 'Person Detected',
                modalStyles: {
                    panelHeading: `color: #3c763d; background-color: #dff0d8; border-color: #d6e9c6`,
                    boxes: 'color: #3c763d; background-color: #e6f1e8; border: 2px solid rgb(193, 216, 196);'
                }
            },
            recognition: {
                panel: `border-color: #d6e9c6;`,
                panelHeading: `color: #3c763d; background-color: #dff0d8; border-color: #d6e9c6; border-width: 2px; border-style: solid; padding-top: 3px;`,
                //iconClass: 'recognitionIcon',
                description: 'Recognition',
                modalStyles: {
                    panelHeading: `color: #3c763d; background-color: #dff0d8; border-color: #d6e9c6`,
                    boxes: 'color: #3c763d; background-color: #e6f1e8; border: 2px solid rgb(193, 216, 196);'
                }
            },
            linetrip: {
                panel: `border-color: #d6e9c6;`,
                panelHeading: `color: #3c763d; background-color: #dff0d8; border-color: #d6e9c6; border-width: 2px; border-style: solid; padding-top: 3px;`,
                //iconClass: 'recognitionIcon',
                description: 'Line Trip',
                modalStyles: {
                    panelHeading: `color: #3c763d; background-color: #dff0d8; border-color: #d6e9c6`,
                    boxes: 'color: #3c763d; background-color: #e6f1e8; border: 2px solid rgb(193, 216, 196);'
                }
            },
            metaFlaggedVehicle: {
                panel: `border-color: #d6e9c6;`,
                panelHeading: `color: #3c763d; background-color: #dff0d8; border-color: #d6e9c6; border-width: 2px; border-style: solid; padding-top: 3px;`,
                //iconClass: 'motiontriggerIcon',
                description: 'SA VOI DB Flagged Vehicle',
                modalStyles: {
                    panelHeading: `color: #3c763d; background-color: #dff0d8; border-color: #d6e9c6`,
                    boxes: 'color: #3c763d; background-color: #e6f1e8; border: 2px solid rgb(193, 216, 196);'
                }
            },
            flaggedVehicle: {
                panel: `border-color: #d6e9c6;`,
                panelHeading: `color: #3c763d; background-color: #dff0d8; border-color: #d6e9c6; border-width: 2px; border-style: solid; padding-top: 3px;`,
                //iconClass: 'motiontriggerIcon',
                description: 'Licence Plate Recognition',
                modalStyles: {
                    panelHeading: `color: #3c763d; background-color: #dff0d8; border-color: #d6e9c6`,
                    boxes: 'color: #3c763d; background-color: #e6f1e8; border: 2px solid rgb(193, 216, 196);'
                }
            },
            motiontrigger: {
                panel: `border-color: #d6e9c6;`,
                panelHeading: `color: #ca762d; background-color: #f9e0ca; border-color: #e0ccbb; border-width: 2px; border-style: solid; padding-top: 3px;`,
                //iconClass: 'motiontriggerIcon',
                description: 'Motion Trigger',
                modalStyles: {
                    panelHeading: `color: #ca762d; background-color: #f9e0ca; border-color: #e0ccbb;`,
                    boxes: 'color: #2c352c; background-color: #eae5e1; border: 2px solid rgb(191, 179, 168);'
                }
            },
            //imagerequest: {
            //panel: `border-color: #bce8f1;`,
            //panelHeading: `color: #274440; background-color: #c7f2f7; border-color: #aecfe8; border-width: 2px; border-style: solid; padding-top: 3px;`,
            //iconClass: 'imagerequestIcon',
            //description: 'Image Request',
            //modalStyles: {
            //panelHeading: `color: #274440; background-color: #c7f2f7; border-color: #ffffff;`,
            //boxes: 'color: #274440; background-color: #e8f1f5; border: 2px solid rgba(70,184,218,0.25);'

            //}
            //},
            //tampers: { //should give tampers unique values
            //panel: `border-color: #ebccd1;`,
            //panelHeading: `color: #a94442; background-color: #f2dede; border-color: #ebccd1; border-width: 2px; border-style: solid; padding-top: 3px;`,
            //iconClass: 'tampersIcon',
            //description: 'Tampers',
            //modalStyles: {
            //panelHeading: `color: #a94442; background-color: #f2dede; border-color: #ebccd1;`,
            //boxes: 'color: #a94442; background-color: rgb(247, 238, 238); border: 2px solid rgb(236, 224, 223);'

            //}
            //},
            //acfail: {
            //panel: `border-color: #d6e9c6;`,
            //panelHeading: `color: #5a5252; background-color: #d2d2d2; border-color: #bdb8b8; border-width: 2px; border-style: solid; padding-top: 3px;`,
            //iconClass: 'acfailIcon',
            //description: 'AC Fail',
            //modalStyles: {
            //panelHeading: `color: #5a5252; background-color: #d2d2d2; border-color: #dcd0d0;`,
            //boxes: 'color: #5a5252; background-color: #e8e8e8; border: 2px solid rgb(202, 202, 202);'

            //}
            //},
            //lowbattery: {
            //panel: `border-color: #d6e9c6;`,
            //panelHeading: `color: #5a5252; background-color: #d2d2d2; border-color: #bdb8b8; border-width: 2px; border-style: solid; padding-top: 3px;`,
            //iconClass: 'lowbatteryIcon',
            //description: 'Low Battery',
            //modalStyles: {
            //panelHeading: `color: #5a5252; background-color: #d2d2d2; border-color: #dcd0d0;`,
            //boxes: 'color: #5a5252; background-color: #e8e8e8; border: 2px solid rgb(202, 202, 202);'

            //}
            //},
            camdown: {
                panel: `border-color: #d6e9c6;`,
                panelHeading: `color: #5a5252; background-color: #d2d2d2; border-color: #bdb8b8; border-width: 2px; border-style: solid; padding-top: 3px;`,
                //iconClass: 'camdownIcon',
                description: 'Camera Down',
                modalStyles: {
                    panelHeading: `color: #5a5252; background-color: #d2d2d2; border-color: #dcd0d0;`,
                    boxes: 'color: #5a5252; background-color: #e8e8e8; border: 2px solid rgb(202, 202, 202);'

                }
            },
            hddfail: {
                panel: `border-color: #d6e9c6;`,
                panelHeading: `color: #5a5252; background-color: #d2d2d2; border-color: #bdb8b8; border-width: 2px; border-style: solid; padding-top: 3px;`,
                //iconClass: 'camdownIcon',
                description: 'Storage Down',
                modalStyles: {
                    panelHeading: `color: #5a5252; background-color: #d2d2d2; border-color: #dcd0d0;`,
                    boxes: 'color: #5a5252; background-color: #e8e8e8; border: 2px solid rgb(202, 202, 202);'

                }
            },
            nocomms: {
                panel: `border-color: #d6e9c6;`,
                panelHeading: `color: #5a5252; background-color: #d2d2d2; border-color: #bdb8b8; border-width: 2px; border-style: solid; padding-top: 3px;`,
                //iconClass: 'nocommsIcon',
                description: 'No Comms',
                modalStyles: {
                    panelHeading: `color: #5a5252; background-color: #d2d2d2; border-color: #dcd0d0;`,
                    boxes: 'color: #5a5252; background-color: #e8e8e8; border: 2px solid rgb(202, 202, 202);'
                }
            }
        };

        //NOTE that the order of this list determines the order in which
        //NOTE events would be prioritised in the stack
        self.eventStack = {
            recognition: [],
            persondetected: [],
            motiontrigger: [],
            metaFlaggedVehicle: [],
            flaggedVehicle: [],
            imagerequest: [],
            linetrip: [],
            tampers: [],
            acfail: [],
            hddfail: [],
            lowbattery: [],
            camdown: [],
            nocomms: []
        };

        self.colors = [
            '#fff9f5'
        ];

        this.$http
            .get('/api/events', {
                params: {
                    params: JSON.stringify([
                        {
                            field: 'active',
                            type: 'boolean',
                            value: true,
                        },
                    ]),
                },
            })
            .then((response) => {
                //console.log(response.data);
                self.events = response.data;
                console.log(self.events.length);

                self.$timeout(function () {
                    self.events.forEach((event) => {
                        self.onReceiptOfEvent(event);
                    });
                });

                self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().accountId}:*:*:events`);
                self.currentRooms.push(`${self.Auth.getCurrentAccountSync().accountId}:*:*:events`);
                self.theEventListener = self.eventListener.bind(self);
                self.socket.socket.on('event:save', self.theEventListener);
            });

        // self.generateEvent();

        /* ------------------------------ Event history ----------------------------- */

        this.cols = [
            {
                title: 'Type',
                field: 'eventType',
                show: true,
                sortable: false,
                getValue: this.handleDisplay.bind(this),
            }, {
                title: 'Created At',
                field: 'createdAt',
                show: true,
                sortable: true,
                getValue: this.handleDisplay.bind(this),
            }, {
                title: 'Site',
                field: 'sitename',
                show: true,
                sortable: false,
                getValue: this.handleDisplay.bind(this),
            }, {
                title: 'Zone',
                field: 'zoneAlias',
                show: true,
                sortable: false,
                getValue: this.handleDisplay.bind(this),
            }, {
                title: 'User',
                field: 'userName',
                show: true,
                sortable: false,
                getValue: this.handleDisplay.bind(this),
            }, {
                title: 'Active',
                field: 'active',
                show: true,
                sortable: false,
                getValue: this.handleDisplay.bind(this),
            },
        ];

        this.tableParams = new this.NgTableParams({
            page: 1,
            count: 10,
            sorting: {
                createdAt: 'desc',
            },
        }, {
            total: 0,
            getData(params) {
                let order;
                if (params && params.sorting) {
                    order = params.sorting();
                    const dataPromise = self.$http.get('/api/events', {
                        params: {
                            skip: (params.page() - 1) * params.count(),
                            limit: params.count(),
                            by: Object.keys(order)[0],
                            order: order[Object.keys(order)[0]],
                            allAccounts: self.allAccounts,
                        }
                    })
                    const countPromise = self.$http.get('/api/events/count', {
                        params: {},
                    })

                    return Promise.all([dataPromise, countPromise])
                        .then((response) => {
                            self.eventsAll = response[0].data;
                            params.total(response[1].data);
                            return self.eventsAll;
                        })
                        .catch((err) => {
                            console.error('Error caught when getting data for events: ', err);
                        });
                }
            },
        });
    }

    handleDisplay(self, col, event) {
        let html = '';
        switch (col.field) {
            case 'eventType':
                if(event && event.eventType) return eventTypeMap[event.eventType];
                return '';
            case 'createdAt':
                html += `<span uib-tooltip="{{event.createdAt | date:\'yyyy-MM-dd HH:mm:ss\'}}" tooltip-append-to-body="true" am-time-ago="event.createdAt"></span>`;
                return self.$sce.trustAsHtml(html);
            case 'sitename':
                if(event && event.sitename) return event.sitename;
                return '';
            case 'zoneAlias':
                if(event && event.zoneAlias) return event.zoneAlias;
                return '';
            case 'userName':
                if(event && event.userName) return event.userName;
                return ''
            case 'active':
                if(event && event.active !== undefined) return event.active;
                return ''
            default:
                if(event[col.field]) return event[col.field];
                return '';
        }
    }

    doLog() {
        let self = this;
        console.log("DEBUG:", self);
    }

    eventListener(event) {
        let self = this;
        // console.log('New event from socket',event);
        self.onReceiptOfEvent(event);
    }

    generateDate() {
        let today = new Date();
        let time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
        let date = `${today.getFullYear()}-${today.getMonth()}-${today.getDate()}`;
        return `${date} ${time}`;
    }

    categorizeEvents(event) {
        let self = this;
        let index = _.indexOf(self.eventTypes, event.eventType);
        if(index !== -1) {
            let eventType = self.eventTypes[index];
            let insertionArea = self.eventStack[eventType];
            let idx = _.findIndex(self.eventStack[eventType], {site: event.site});
            if(idx !== -1) {
                if(insertionArea[idx]._id === event._id) {
                    if(event.active) {
                        insertionArea[idx] = _.mergeWith(insertionArea[idx], event, (objValue, srcValue) => {
                            if(_.isArray(objValue)) {
                                return srcValue;
                            }
                        });
                    } else {
                        insertionArea.splice(idx, 1);
                    }
                    return;
                }
                if(typeof insertionArea[idx].childEvent === 'undefined') {
                    insertionArea[idx].childEvent = [event];
                } else {
                    let childIdx = _.findIndex(insertionArea[idx].childEvent, function(childEvent) {
                        return childEvent._id === event._id;
                    });
                    if(childIdx === -1) {
                        if(event.active) {
                            insertionArea[idx].childEvent.push(event);
                        }
                    } else if(event.active) {
                        insertionArea[idx].childEvent[childIdx] = _.mergeWith(insertionArea[idx].childEvent[childIdx], event, (objValue, srcValue) => {
                            if(_.isArray(objValue)) {
                                return srcValue;
                            }
                        });
                    } else {
                        insertionArea[idx].childEvent.splice(childIdx, 1);
                    }
                }
            } else if(event.active) {
                self.eventStack[eventType].push(event);
            }
        }
    }

    determineIconsandPanels(event) {
        let self = this;
        event.eventIcon = self.eventIcons[event.eventType];
        event.panel = self.panelClasses[event.eventType].panel;
        event.description = self.panelClasses[event.eventType].description;
        event.panelHeading = self.panelClasses[event.eventType].panelHeading;
        event.iconClass = self.panelClasses[event.eventType].iconClass;
    }

    sortBySites(eventStack) {
        let keys = Object.keys(eventStack);
        for(let i = 0; i < keys.length; i++) {
            eventStack[keys[i]] = _.mapValues(_.groupBy(eventStack[keys[i]], 'sitename'),
                siteList => siteList.map(site => _.omit(site, 'sitename'))
            );
        }
    }

    openEventModal(event, eventType) {
        let self = this;
        this.modalInstance = this.$uibModal.open({
            component: 'eventmodal',
            animation: false,
            bindToController: true,
            backdrop: 'static',
            size: 'lg',
            resolve: {
                settings() {
                    return {
                        eventType,
                        event,
                        context: self
                        // contacts: self.getContactsFromSite(event.site),
                        // loc: self.getLocationFromSite(event.site)
                    };
                }
            },
            windowClass: "event-modal-class",
            windowTopClass: "event-modal-top-class",
            backdropClass: 'event-modal-backdrop'
        });

        this.modalInstance.result.then(result => {

        }, function(err) {
            // ignore error
        });

        this.modalInstance.closed.then(result => {

        }, function(err) {
            // ignore error
        });
    }


    clickedEvent(event, eventType) {
        let self = this;
        self.openEventModal(event, eventType);
    }

    /*setCurrentEvent(event) {
        let self = this;
        console.log("setting event");
        self.selectedEvent = event;
    }*/

    getEventsFromID(eventID) {
        let self = this;
        return self.eventStack[eventID];
    }

    // getContactsFromSite(site) {
    //     let self = this;
    //     let result = _.find(self.sites, {'alias': site});
    //     return result.contacts;
    // }

    // getLocationFromSite(site) {
    //     let self = this;
    //     let result = _.find(self.sites, {'alias': site});
    //     return result.loc
    // }

    getEventCount(event) {
        let self = this;
        let eventsForCategory = self.eventStack[event];
        return eventsForCategory.length;
    }

    getTriggerCount(event) {
        let self = this;
        switch (event.eventType) {
            case 'persondetected': {
                let results = event.snapshots.length;
                if(event.childEvent) {
                    //results += event.childEvent.length;
                    event.childEvent.forEach((event) => {
                        results += event.snapshots.length;
                    });
                }
                return results;
            }
            case 'motiontrigger': {
                let results = event.snapshots.length;
                if(event.childEvent) {
                    event.childEvent.forEach((event) => {
                        results += event.snapshots.length;
                    });
                }
                return results;
            }
            case 'camdown': {
                let results = 1;
                if(event.childEvent) {
                    results += event.childEvent.length;
                }
                return results;
            }
            case 'hddfail': {
                let results = 1;
                if(event.childEvent) {
                    results += event.childEvent.length;
                }
                return results;
            }
            case 'nocomms': {
                let results = 1;
                if(event.childEvent) {
                    results += event.childEvent.length;
                }
                return results;
            }
            case 'linetrip': {
                let results = 1;
                if(event.childEvent) {
                    results += event.childEvent.length;
                }
                return results;
            }
            case 'metaFlaggedVehicle': {
                let results = 1;
                if(event.childEvent) {
                    results += event.childEvent.length;
                }
                return results;
            }
            case 'flaggedVehicle': {
                let results = 1;
                if(event.childEvent) {
                    results += event.childEvent.length;
                }
                return results;
            }
            case 'recognition': {
                let results = 1;
                if(event.childEvent) {
                    results += event.childEvent.length;
                }
                return results;
            }
        }
        return 0;
    }


/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////
///////////////  FOR TESTING PURPOSES  //////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////

    onReceiptOfEvent(event) {
        let self = this;
        self.categorizeEvents(event);
        self.determineIconsandPanels(event);
        // console.log(self.eventStack);
        // self.createChildEvents(self.eventStack);
    }

    generateNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    generateEvent() {
        let self = this;
        let eventTypes = [
            'metaFlaggedVehicle',
            'flaggedVehicle',
            'linetrip',
            'motiontrigger',
            'nocomms',
            'camdown',
            'hddfail',
            'persondetected',
            'recognition',
            'lowbattery',
            'tampers',
            'acfail',
            'imagerequest'
        ];

        let eventTypeInfo = {
            metaFlaggedVehicle: {
                description: "SA VOI Database Flagged Vehicle",
                sop: [
                    "1: LPR",
                    "2: LPR",
                    "3: LPR",
                ]
            },
            flaggedVehicle: {
                description: "Licence Plate Recognition",
                sop: [
                    "1: LPR",
                    "2: LPR",
                    "3: LPR",
                ]
            },
            linetrip: {
                description: "Line Trip",
                sop: [
                    "1: Line Trip",
                    "2: Line Trip",
                    "3: Line Trip",
                ]
            },
            motiontrigger: {
                description: "Motion Trigger",
                sop: [
                    "1: Check received images for suspicious activities",
                    "2: If suspicious activities are observed, escalate and contact reaction.",
                    "3: If no suspicious activities are observed, request additional images",
                ]
            },
            nocomms: {
                description: "No Communication",
                sop: [
                    "1: Inspect any of the last photos from the site for abnormalities.",
                    "2: Notify the site manager.",
                    "3: Log a maintenance call with the service provider"
                ]
            },
            camdown: {
                description: "Camera Down",
                sop: [
                    "1: Ensure the camera is still offline.",
                    "2: Inspect last images for any suspicious activities.",
                    "3: Log a maintenance call with the service provider"
                ]
            },
            hddfail: {
                description: "Storage Down",
                sop: [
                    "1: Ensure the disk is properly attached.",
                    "2: Ensure the disk is mounted in the OS.",
                    "3: Log a maintenance call with the service provider"
                ]
            },
            persondetected: {
                description: "Person Detected",
                sop: [
                    "1: Verify the detection.",
                    "2: In case of a positive detection, escalate to manager."
                ]
            },
            recognition: {
                description: "Recognition",
                sop: [
                    "1: Verify the detection from the reference image.",
                    "2: In case of a positive detection, escalate to manager."
                ]
            },
            lowbattery: {
                description: "Low Battery",
                sop: [
                    "1: See if other low-battery events from this site exist.",
                    "2: If so, contact the site manager"
                ]
            },
            tampers: {
                description: "Tampers",
                sop: [
                    "1: Do this.",
                    "2: Phone that",
                    "3: Execute something then"
                ]
            },
            acfail: {
                description: "AC Fail",
                sop: [
                    "1: Do this.",
                    "2: Phone that",
                    "3: Execute something then"
                ]
            },
            imagerequest: {
                description: "Image Request",
                sop: [
                    "1: Check received images for suspicious activities",
                    "2: If suspicious activities are observed, escalate and contact reaction.",
                    "3: If no suspicious activities are observed, request additional images",
                ]
            }
        };
        let snapshots = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
        let snapshotNumber = self.generateNumber(0, 10);

        let eventNumber = self.generateNumber(0, 7);
        let eventType = eventTypes[eventNumber];
        let sites = [
            'The Pavillion', 'Mooirivier-Centre', 'The-Bluff', 'Westville', 'Seepunt'
        ];
        let siteNumber = self.generateNumber(0, 4);

        let zones = [
            'Entrance South', 'Entrance North', 'Stairs', 'Exit', 'Packaging-area'
        ];
        let zoneNumber = self.generateNumber(0, 4);

        let camNames = [
            'cam1', 'cam2', 'cam3', 'cam4'
        ];
        let camNumber = self.generateNumber(0, 4);

        let event = {
            _id: self.generateNumber(1, 10000),
            eventType,
            description: eventTypeInfo[eventType].description || "Dummy description",
            site: sites[siteNumber],
            sitename: sites[siteNumber],
            zone: {
                type: "ObjectID",
                ref: "MongoID"
            },
            cameraname: camNames[camNumber],
            zoneAlias: zones[zoneNumber],
            createdAt: self.generateDate(),
            snapshots: [{
                site: sites[siteNumber],
                camera: zones[zoneNumber],
                data: "https://picsum.photos/640/480",
                dataThumbnail: "https://picsum.photos/200/100",
                storage: 'lorem'
            }, {
                site: sites[siteNumber],
                camera: zones[zoneNumber],
                data: "https://picsum.photos/640/480",
                dataThumbnail: "https://picsum.photos/200/100",
                storage: 'lorem'
            }, {
                site: sites[siteNumber],
                camera: zones[zoneNumber],
                data: "https://picsum.photos/640/480",
                dataThumbnail: "https://picsum.photos/200/100",
                storage: 'lorem'
            },
                {
                    site: sites[siteNumber],
                    camera: zones[zoneNumber],
                    data: "https://picsum.photos/640/480",
                    dataThumbnail: "https://picsum.photos/200/100",
                    storage: 'lorem'
                }],
            closedAt: undefined,
            closer: {
                type: "ObjectID",
                ref: "User"
            },
            closerName: "",
            sop: [
                "1: Ensure the camera is still offline.",
                "2: Inspect last images for any suspicious activities.",
                "3: Log a maintenance call with the service provider"
            ],
            loc: {
                lng: 27.0970475, lat: -26.7145297
            },
            account: {
                type: "ObjectID",
                ref: "Account"
            },
            accountname: "JerichoSystems",
            active: true
        };

        self.onReceiptOfEvent(event);
    }

    sortDisplayByEvent(event) {
        let self = this;
        if(self.filteredEvents[event] !== undefined) {
            self.filteredEvents[event] = !self.filteredEvents[event];
        }
    }
}

export default angular.module('cameraViewerApp.events')
    .component('events', {
        template: require('./events.html'),
        controller: EventsComponent,
        controllerAs: '$ctrl'
    })
    .name;
