/**
 * Jarvis Widget Directive
 *
 *    colorbutton="false"
 *    editbutton="false"
      togglebutton="false"
       deletebutton="false"
        fullscreenbutton="false"
        custombutton="false"
        collapsed="true"
          sortable="false"
 *
 *
 */
"use strict";
angular.module('SmartAdmin.Layout').directive('jarvisWidget', function($rootScope) {
    return {
        restrict: "A",
        compile(element, attributes) {
            if(element.data('widget-color')) {
element.addClass(`jarviswidget-color-${element.data('widget-color')}`);
}


            element.find('.widget-body').prepend('<div class="jarviswidget-editbox"><input class="form-control" type="text"></div>');

            element.addClass('jarviswidget');
            $rootScope.$emit('jarvisWidgetAdded', element );

        }
    };
});
