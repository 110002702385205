import _ from 'lodash';
import angular from 'angular';
import hasprivileges from 'hasprivileges';

export class MFASettingsComponent {
    /* @ngInject */
    constructor(Auth, toastr, $http, $ngConfirm) {
        this.Auth = Auth;
        this.toastr = toastr;
        this.$http = $http;
        this.$ngConfirm = $ngConfirm;
        this.mayDisable = false;
    }

    $onInit() {
        const self = this;
        self.Auth.getCurrentUser().then((user) => {
            self.user = user;
            if (!user.mfa) {
                user.mfa = {
                    enabled: false,
                    enrolled: false,
                };
            }
            self.resolve = { mfa: _.cloneDeep(user.mfa) };
            return self.$http.get('/api/accounts').then((res) => {
                self.mayDisable = _.every(res.data, (acc) => {
                    const may = acc.mfaEnforce == false;
                    return may;
                });
            });
        }).catch((err) => {
            console.error(err);
        });
    }

    doLog() {
        console.log(this);
    }

    toggleMFAEnabled() {
        const self = this;
        if (self.resolve.mfa.enabled) {
            self.$ngConfirm({
                title: 'Enable Two-factor Authentication',
                escapeKey: false,
                backgroundDismiss: false,
                scope: self.$scope,
                content: 'Are you sure you want to enable two-factor authentication? To disable two-factor authentication, the setup and enrolment needs to be completed successfully.',
                buttons: {
                    enter: {
                        text: 'Enable',
                        btnClass: 'btn-blue',
                        action(scope, button) {
                            self.$http.post('/api/users/mfaEnable').then((res) => {
                                if (res && res.data) {
                                    self.user = res.data;
                                    self.resolve = { mfa: _.cloneDeep(self.user.mfa) };
                                }
                            }).catch((err) => {
                                console.error(err);
                            });
                        },
                    },
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-warning',
                        action(scope, button) {
                            self.resolve.mfa.enabled = false;
                        },
                    },
                },
            });
        } else {
            self.$ngConfirm({
                title: 'Disable Two-factor Authentication',
                escapeKey: false,
                backgroundDismiss: false,
                scope: self.$scope,
                content: 'Are you sure you want to disable two-factor authentication?',
                buttons: {
                    enter: {
                        text: 'Disable',
                        btnClass: 'btn-blue',
                        action(scope, button) {
                            self.$http.post('/api/users/mfaDisable').then((res) => {
                                if (res && res.data) {
                                    self.user = res.data;
                                    self.resolve = { mfa: _.cloneDeep(self.user.mfa) };
                                }
                            }).catch((err) => {
                                console.error(err);
                            });
                        },
                    },
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-warning',
                        action(scope, button) {
                            self.resolve.mfa.enabled = true;
                        },
                    },
                },
            });
        }
    }
}

export default angular.module('cameraViewerApp.mfaSettings')
    .component('mfaSettings', {
        template: require('./mfaSettings.html'),
        controller: MFASettingsComponent,
        controllerAs: '$ctrl',
    })
    .name;
