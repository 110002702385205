export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.site', {
            url: '/sites/:id',
            params: { id: { dynamic: true, value: '' }, filter: null },
            views: {
                'content@main': {
                    template: '<sites></sites>',
                },
            },
            authenticate: true,
            data: {
                title: 'Site View',
            },
        });
}
