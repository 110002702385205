import angular from 'angular';
import uiBootstrap from 'angular-ui-bootstrap';
import uiRouter from '@uirouter/angularjs';
import _ from 'lodash-es';
import moment from 'moment';
import routes from './events.routes';

export default angular.module('cameraViewerApp.events', [uiRouter, uiBootstrap])
    .config(routes)
    .name;

require('./events.component');
require('./events.image-modal.component');
require('./events.modal.component');
