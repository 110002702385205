import moment from 'moment';

export default class NotesController {
    $http;
    $uibModalInstance;
    currentSite;
    asideModalInstance;

    /* @ngInject */
    constructor($http, $uibModalInstance, asideModalInstance, currentSite, originalNote, Auth, appConfig) {
        this.$uibModalInstance = $uibModalInstance;
        this.asideModalInstance = asideModalInstance;
        this.currentSite = currentSite;
        this.note = originalNote;
        this.$http = $http;
        this.Auth = Auth;
        this.edit = false;
        this.topic = '';
        this.content = '';
        this.tags = [];
        this.attention = false;
        this.userId = this.Auth.getCurrentUserSync()._id;
        this.userName = this.Auth.getCurrentUserSync().name;
        this.availableTags = appConfig.default.siteNoteTagOptions;
    }

    $onInit() {
        const self = this;
        if (self.note !== undefined) {
            self.edit = true;
            self.topic = self.note.topic;
            self.content = self.note.content;
            self.attention = self.note.attention;
            self.tags = self.note.tags;
        }
    }

    cancel() {
        this.$uibModalInstance.close();
    }

    create() {
        const self = this;
        const newNote = {
            topic: self.topic,
            content: self.content.trim(),
            attention: self.attention,
            creationTime: moment.now(),
            creator: self.userId,
            creatorName: self.userName,
            tags: self.tags,
        };
        this.$http.post(`/api/sites/${this.currentSite._id}/addNote`, { note: newNote })
            .then((result) => self.$uibModalInstance.close({ done: true, notes: result.data.notes }));
    }

    update() {
        const self = this;

        const updatedNote = self.note;
        updatedNote.tags = self.tags;
        updatedNote.attention = self.attention;
        updatedNote.edited = true;
        updatedNote.editTime = moment.now();

        this.$http.patch(`/api/sites/${this.currentSite._id}/updateNote`, { note: updatedNote })
            .then((result) => self.$uibModalInstance.close({ done: true, notes: result.data.notes }))
            .catch((err) => {
                self.$uibModalInstance.close({ error: true });
                console.error(err);
            });
    }
}
