import angular from 'angular';

export class LiveStreamContainer {
    /*@ngInject*/
    constructor(liveStreamService, $element, $timeout) {
        this.liveStreamService = liveStreamService;
        this.$element = $element;
        this.$timeout = $timeout;
    }

    $onInit() {
        //     const self = this;
        //     self.$scope.$watchGroup(
        //         ['$ctrl.stream.stats.frameWidth', '$ctrl.stream.stats.frameHeight'],
        //         (newVal) => {
        //             let width = newVal[0];
        //             let height = newVal[1];
        //             if (width && height) {
        //                 self.resizePanel(stream);
        //             }
        //         }
        //     );
        //     self.$timeout(() => {
        //         let videoElement = document.getElementById(`${stream.guid}`);
        //         videoElement.addEventListener("enterpictureinpicture", () => {
        //             self.minimize(stream);
        //         });
        //
        //         videoElement.addEventListener("leavepictureinpicture", () => {
        //             self.maximize(stream);
        //         });
        //     });
    }

    $onDestroy() {
        this.liveStreamService.clear();
    }

    resizePanel(stream) {
        const self = this;
        if (!stream.minimized) {
            let aspect = 3 / 2;
            if (stream && stream.stats && stream.stats.frameWidth && stream.stats.frameHeight && !stream.minimized) {
                aspect = stream.stats.frameWidth / stream.stats.frameHeight;
            }
            let videoElement = self.$element.find(`#${stream.guid}`);
            let panel = videoElement.parent().parent();
            let panelWidth = panel[0].clientWidth;
            let newHeight = panelWidth / aspect;
            panel.css('height', newHeight);
        }
    }

    close(stream) {
        const self = this;
        self.liveStreamService.removeStream(stream);
    }

    requestFullscreen(stream) {
        const self = this;
        self.liveStreamService.requestFullscreen(stream);
    }

    minimize(stream) {
        const self = this;
        stream.minimized = true;
        const videoElement = self.$element.find(`#${stream.guid}`);
        const panel = videoElement.parent().parent();
        panel.css('height', 0);
    }

    maximize(stream) {
        const self = this;
        stream.minimized = false;
        self.resizePanel(stream);
    }

    resetStream(stream) {
        const self = this;
        self.liveStreamService.resetStream(stream);
    }
}

export default angular.module('directives.liveStreamContainer', [])
    .component('liveStreamContainer', {
        template: require('./live-stream-container.html'),
        controller: LiveStreamContainer,
        controllerAs: "$ctrl",
        bindings: {}
    })
    .name;
