import angular from 'angular';
import uiSelect from 'ui-select';
import uiRouter from '@uirouter/angularjs';
import routes from './biReport.routes';

export default angular.module('cameraViewerApp.biReport', [
    uiRouter,
    'sticky',
    uiSelect,
    'angularMoment'])
    .config(routes)
    .name;

require('./biReport.component');
