import _ from 'lodash';
import angular from 'angular';
import environment from '../../../server/config/environment/shared';

export class FooterComponent {
    $http;
    socket;

    /* @ngInject */
    constructor($http, $scope, socket, Auth, toastr) {
        this.$http = $http;
        this.toastr = toastr;
        this.version = environment.version;
        this.Auth = Auth;
        this.socket = socket;
        this.faultySites = [];
        this.currentRooms = [];
        this.debugFaultySites = [];
    }

    $onDestroy() {
        const self = this;
        if (self.currentRooms.length > 0) {
            self.currentRooms.forEach((room) => {
                self.socket.leaveRoom(room);
            });
        }
    }

    $onInit() {
        const self = this;
        self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().accountId}:*:maintenance:sites`);
        self.currentRooms.push(`${self.Auth.getCurrentAccountSync().accountId}:*:maintenance:sites`);
        // if(self.Auth.hasRoleSync("secuvue.SiteView.Settings.UnitConfig.Relays")) {
        //     self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().accountId}:*:units`);
        //     self.currentRooms.push(`${self.Auth.getCurrentAccountSync().accountId}:*:units`);
        //     self.socket.socket.on('unit:relay', self.relayListener.bind(this));
        // }

        self.socket.socket.on('site:save', self.siteListener.bind(this));
        self.$http.get('/api/sites/active')
            .then((response) => {
                response.data.forEach((site) => {
                    self.siteListener(site);
                });
            });
    }

    // relayListener(unit) {
    //     let self = this;
    //     if(unit.relays[0].engaged) {
    //         self.toastr.success(`Relay: ${unit.relays[0].alias}, engaged at site: ${unit.siteAlias}`);
    //     } else {
    //         self.toastr.success(`Relay: ${unit.relays[0].alias}, disengaged at site: ${unit.siteAlias}`);
    //     }
    // }

    handleUnitStatus(unit, unitList, reason) {
        const index = _.findIndex(unitList, (o) => o.id === unit._id);
        if (index === -1) {
            unitList.push({
                id: unit._id,
                alias: unit.alias,
                reasons: [reason],
            });
        } else {
            unitList[index].reasons.push(reason);
        }
    }

    siteListener(site, event) {
        const self = this;
        const faultyUnits = [];
        const debugUnits = [];

        const siteIndex = _.findIndex(self.faultySites, (o) => o.id === site._id);
        if (siteIndex !== -1) {
            self.faultySites.splice(siteIndex, 1);
        }
        if (!site.enabled || !site.configured) {
            return;
        }

        site.units.forEach((unit) => {
            if (unit.configured && unit.enabled) {
                // Check unit status
                if (unit.down) {
                    self.handleUnitStatus(unit, faultyUnits, 'Unit Down');
                }
                if (unit.storageDown) {
                    self.handleUnitStatus(unit, faultyUnits, 'Storage Down');
                }
                if (unit.bootCounter >= 5) {
                    self.handleUnitStatus(unit, faultyUnits, 'Boot Loop');
                }

                // Check camera status
                unit.cameras.some((cam) => {
                    if ((cam.authenticationFailed || cam.down) && cam.enabled) {
                        self.handleUnitStatus(unit, faultyUnits, 'Camera Issues');
                    }
                    return true;
                });
                unit.cameras.some((cam) => {
                    if ((cam.hungCam || cam.localstorageDown) && cam.enabled) {
                        self.handleUnitStatus(unit, debugUnits, 'Hung cam, or localstorage');
                    }
                    return true;
                });
            }
        });

        // Populate site lists
        if (debugUnits.length > 0) {
            self.debugFaultySites.push({
                id: site._id,
                alias: site.alias,
                units: debugUnits,
            });
        }
        if (faultyUnits.length > 0) {
            self.faultySites.push({
                id: site._id,
                alias: site.alias,
                units: faultyUnits,
            });
        }
    }

    doLog() {
        console.log(this);
    }
}

export default angular.module('directives.footer', [])
    .component('navfooter', {
        template: require('./footer.html'),
        controller: FooterComponent,
    })
    .name;
