import _ from 'lodash-es';

export default class NewContactController {
    $http;
    $uibModalInstance;
    currentSite;
    asideModalInstance;

    /* @ngInject */
    constructor($http, $uibModalInstance, asideModalInstance, currentSite) {
        this.$uibModalInstance = $uibModalInstance;
        this.asideModalInstance = asideModalInstance;
        this.currentSite = currentSite;
        this.$http = $http;
        this.firstname = '';
        this.lastname = '';
        this.phone = '';
        this.email = '';
    }

    cancel() {
        this.$uibModalInstance.close();
    }

    create() {
        const self = this;
        self.currentSite.contacts.push({
            firstname: self.firstname,
            lastname: self.lastname,
            phone: self.phone,
            email: self.email,
            notifications: {
                motiontrigger: false,
                linetrip: false,
                metaFlaggedVehicle: false,
                camdown: false,
                recognition: false,
                nocomms: false,
                persondetected: false,
            },
        });
        this.$http.patch(`/api/sites/${this.currentSite._id}`, _.pick(this.currentSite, ['_id', 'contacts']))
            .then(() => {
                this.$uibModalInstance.close({ done: true });
            });
    }
}
