import angular from 'angular';

export class WizardService {
    /* @ngInject */
    constructor($timeout) {
        this.$timeout = $timeout;
    }

    /**
     * Writing this to manipulate our current active step number.
     * TODO: Finish this function to not only handle 'prev' and 'next'
     */
    goToWizardStep(stepNumber) {
        const self = this;
        const elems = angular.element(document.getElementsByClassName('navi-circles'));
        const activeEl = elems.find('li.active');

        if (stepNumber === 'next') {
            const nextEl = activeEl.next();
            const roundTab = nextEl.find('.round-tab');
            self.$timeout(() => {
                roundTab.triggerHandler('click');
            });
        } else if (stepNumber === 'prev') {
            // console.log("getting prev");
            // console.log(activeEl);
            const prevElNode = activeEl[0].previousElementSibling;
            const prevEl = angular.element(prevElNode);
            // console.log(prevEl);
            self.$timeout(() => {
                const roundTab = prevEl.find('.round-tab');
                roundTab.triggerHandler('click');
            });
        }
    }
}

export default angular.module('cameraViewerApp.services')
    .service('wizardService', WizardService);
