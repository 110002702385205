export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.accountManagement', {
            url: '/accountManagement',
            views: {
                'content@main': {
                    template: '<account-management></account-management>',
                },
            },
            authenticate: 'Analytics',
            data: {
                title: 'Account Management',
            },
        });
}
