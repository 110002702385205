export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.accessEvents', {
            url: '/accessEvents',
            views: {
                'content@main': {
                    template: '<access-events></access-events>',
                },
            },
            authenticate: 'AccessEvents',
            data: {
                title: 'Access Control Events',
            },
        });
}
