import angular from 'angular';

export default angular.module('cameraViewerApp.directives')
    .directive('horizontalScroll', () => ({
        restrict: 'A',
        link(scope, element, attr) {
            element.on('mousewheel', ($event) => {
                if ($event.originalEvent) {
                    const scrollE = $event.originalEvent;
                    const elm = angular.element(element);
                    const left = elm.scrollLeft();
                    if (scrollE.deltaY > 0) {
                        const newLeft = left + 90;
                        // $(elm).scrollLeft(newLeft);
                        elm.scrollLeft(newLeft);
                    } else {
                        const newLeft = left - 90;
                        elm.scrollLeft(newLeft);
                    }
                }
            });
        },
    }))
    .name;
