export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.siteNotes', {
            url: '/siteNotes',
            views: {
                'content@main': {
                    template: '<site-notes></site-notes>',
                },
            },
            authenticate: 'SiteNotes',
            data: {
                title: 'Site Notes',
            },
        });
}
