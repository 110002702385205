import * as xlsx from 'xlsx';
import angular from 'angular';

export class ReportComponent {
    $http;
    $scope;
    $state;
    moment;
    self;
    toastr;
    quickReportHour = ['Last 24 Hours', 'Last 7 Days', 'Last 31 Days', 'Today', 'This Week', 'This Month'];
    quickReportMonth = ['Last Month', 'This Month'];

    /* @ngInject */
    constructor($state, $rootScope, moment, $scope, $http, socket, Auth, toastr) {
        this.$state = $state;
        this.moment = moment;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.busyMessage = '';
        this.socket = socket;
        this.filterToOpen = false;
        this.filterFromOpen = false;
        this.$http = $http;
        this.Auth = Auth;
        this.getCurrentUser = Auth.getCurrentUserSync;
        this.cameraUsageOpen = false;
        this.currentRouteView = '';
        this.currentRooms = [];
        this.toastr = toastr;

        this.typeOptions = [
            { alias: 'Account Usages', type: 'Account' },
            { alias: 'Site Usages', type: 'Site' },
            { alias: 'Zone Usages', type: 'Zone' },
            { alias: 'Line Trip Counts', type: 'Line' },
        ];
        this.resolutionOptions = ['Hourly', 'Daily', 'Monthly'];
        this.lineMetrics = [
            {
                alias: 'A to B',
                type: 'People Count: A to B',
                group: 'People Counters',
            },
            {
                alias: 'B to A',
                type: 'People Count: B to A',
                group: 'People Counters',
            },
        ];
        this.availableMetrics = [
            {
                alias: 'Snapshot Usages',
                type: 'Snapshot Usages',
                group: 'Snapshot Usages',
            },
            {
                alias: 'Recognitions',
                type: 'FR Recognitions',
                group: 'Facial Recognition',
            },
            {
                alias: 'New',
                type: 'FR New',
                group: 'Facial Recognition',
            },
            {
                alias: 'Unknown',
                type: 'FR Unknown',
                group: 'Facial Recognition',
            },
            {
                alias: 'Credits',
                type: 'FR Credits',
                group: 'Facial Recognition',
            },
            {
                alias: 'FR Metadata',
                type: 'FR Metadata',
                group: 'Facial Recognition',
            },
            {
                alias: 'Recognitions',
                type: 'LPR Recognitions',
                group: 'Licence Plate Recognition',
            },
            {
                alias: 'New',
                type: 'LPR New',
                group: 'Licence Plate Recognition',
            },
            {
                alias: 'Credits',
                type: 'LPR Credits',
                group: 'Licence Plate Recognition',
            },
            {
                alias: 'Cloud Detections',
                type: 'People Cloud Detections',
                group: 'People Detection',
            },
            {
                alias: 'Edge Detections',
                type: 'People Edge Detections',
                group: 'People Detection',
            },
            {
                alias: 'Cloud Detections',
                type: 'Pose Cloud Detections',
                group: 'Pose Detection',
            },
            {
                alias: 'Edge Detections',
                type: 'Pose Edge Detections',
                group: 'Pose Detection',
            },
            {
                alias: 'Cloud Detections',
                type: 'Vehicle Cloud Detections',
                group: 'Vehicle Detection',
            },
            {
                alias: 'Edge Detections',
                type: 'Vehicle Edge Detections',
                group: 'Vehicle Detection',
            },
        ];

        this.chosenType = 'Account';
        this.chosenResolution = 'Hourly';
        this.chosenMetrics = [];

        this.chosenZones = [];

        this.siteUsageData = {};

        this.availableSites = [];
        this.availableZones = [];
        this.availableLines = [];

        this.endTime = moment().add(1, 'hours')
            .startOf('hour')
            .toDate();
        this.endDate = moment().startOf('day')
            .toDate();
        this.startTime = moment().startOf('hour')
            .subtract(1, 'days')
            .toDate();
        this.startDate = moment().startOf('day')
            .subtract(1, 'days')
            .toDate();
        this.availableEncoding = ['H264', 'H265'];

        this.cameras = [];
        this.cleanUpFuncions = [];
        this.onlymotion = false;
        this.filterOpen = false;
        this.progressOpen = false;
        this.videoOpen = false;
        this.siteVideos = [];
        this.videosOpen = {};
        this.params = {};
        if (this.$state.params) {
            this.params = this.$state.params;
        }

        try {
            this.isModal = this.$scope.isModal;
            this.params = this.$scope.getParams();
            // console.log('Params : ', this.params);
        } catch (e) {
            // console.log(e);
        }

        this.formatBytes = function (bytes, decimals) {
            if (bytes < 0) {
                return bytes;
            }
            if (bytes === 0) return '0 Bytes';
            const k = 1024;
            const dm = decimals + 1 || 3;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
        };

        this.formatSeconds = function (secondsIn) {
            const oneMinute = 60;
            const oneHour = 60 * oneMinute;
            const oneDay = 24 * oneHour;
            const oneMonth = 30 * oneDay;
            const oneYear = 12 * oneMonth;

            const seconds = parseInt(secondsIn, 10);
            const years = Math.floor(seconds / oneYear);
            let left = seconds - years * oneYear;
            const months = Math.floor(left / oneMonth);
            left -= months * oneMonth;
            const days = Math.floor(left / oneDay);
            left -= days * oneDay;
            const hours = Math.floor(left / oneHour);
            left -= hours * oneHour;
            const minutes = Math.floor(left / oneMinute);
            left = Math.floor(left - minutes * oneMinute);
            let returnStr = '';
            if (years !== 0) {
                returnStr += `${years} Years `;
            }
            if (months !== 0) {
                returnStr += `${months} Months `;
            }
            if (days !== 0) {
                returnStr += `${days} Days `;
            }
            if (hours !== 0) {
                returnStr += `${hours} Hours `;
            }
            if (minutes !== 0 && returnStr === '') {
                returnStr += `${minutes} Minutes `;
            }
            if (left !== 0 && returnStr === '') {
                returnStr += `${left} Seconds `;
            }
            return returnStr;
        };
        this.$scope.$on('$destroy', () => {
            socket.unsyncUpdates('site');
            socket.unsyncUpdates('camera');
            if (self.currentRooms && self.currentRooms.length > 0) {
                self.currentRooms.forEach((room) => {
                    socket.leaveRoom(room);
                    // console.log(room, 'left');
                });
            }
            // console.log("DESTROYING");
        });
    }

    doLog() {
        console.log(this);
    }

    $onInit() {
        const self = this;
        self.$http.get('/api/metrics/fetchReportParams')
            .then((response) => {
                if (response.data) {
                    self.availableSites = response.data.sites;
                    self.availableZones = response.data.zones;
                    self.availableLines = response.data.lines;
                }
            });
    }

    changedType() {
        const self = this;
        self.chosenZones = [];
        self.chosenMetrics.forEach((metric) => {
            if (self.chosenType === 'Line' && (metric !== 'People Count: A to B' && metric !== 'People Count: B to A')) {
                self.chosenMetrics = [];
            } else if (self.chosenType !== 'Line' && (metric === 'People Count: A to B' || metric === 'People Count: B to A')) {
                self.chosenMetrics = [];
            }
        });
    }

    getReport() {
        // TODO: Make FE Prettier
        // TODO: Remove Unnecessary logs

        // TESTS:
        //*  Site: Daily: Multiple single, Multiple Multiple
        //*  Site: Hourly: Multiple single, Multiple Multiple
        //*  Site: Monthly:Single Single, Multiple single, Multiple Multiple, Single Multiple
        const self = this;
        const siteArr = [];
        const zoneArr = [];
        const timezoneOffset = new Date().getTimezoneOffset();
        let tsStart;
        if (self.startDate && self.startTime) {
            tsStart = +self.moment(self.startDate).hours(self.startTime.getHours())
                .minutes(self.startTime.getMinutes());
        }
        let tsEnd;
        if (self.endDate && self.endTime) {
            tsEnd = +self.moment(self.endDate).hours(self.endTime.getHours())
                .minutes(self.endTime.getMinutes());
        }
        if (self.chosenType !== 'Account') {
            self.chosenZones.forEach((z) => {
                if (self.chosenType === 'Zone') {
                    zoneArr.push(z._id);
                    if (!siteArr.includes(z.site)) {
                        siteArr.push(z.site);
                    }
                } else if (self.chosenType === 'Line') {
                    zoneArr.push(z.id);
                    if (!siteArr.includes(z.site)) {
                        siteArr.push(z.site);
                    }
                } else if (self.chosenType === 'Site') {
                    zoneArr.push(z._id);
                    siteArr.push(z._id);
                }
            });
        }
        if ((self.chosenType !== 'Account' && siteArr && siteArr.length > 0 && zoneArr && zoneArr.length > 0 || self.chosenType === 'Account')
			&& self.chosenMetrics && self.chosenMetrics.length > 0 && tsStart && tsEnd && self.chosenResolution && tsStart < tsEnd) {
            self.busyMessage = 'Downloading Report...';
            this.$http.post('/api/metrics/createReport', {
                option: self.chosenType,
                siteArr,
                zoneArr,
                metricArr: self.chosenMetrics,
                timezoneOffset,
                tsStart,
                tsEnd,
                chosenResolution: self.chosenResolution,
            }).then((response) => {
                // console.log("This is the response: ", response.data);
                if (response.data) {
                    const wb = xlsx.utils.book_new();
                    const sheetNames = response.data.splice(0, 1);
                    // console.log("sheetNames: Jkla,", sheetNames);

                    const allNames = [];
                    response.data.forEach((sheet, i) => {
                        // console.log("Sheet ", i, sheetNames[0][i]);
                        const ws = xlsx.utils.aoa_to_sheet(sheet);
                        // ws.Range("A:Z").EntireColumn.AutoFit;
                        let sheetname = sheetNames[0][i].length < 31 ? sheetNames[0][i] : `Page${i}`;
                        if (allNames.indexOf(sheetname) === -1) {
                            allNames.push(sheetname);
                        } else {
                            sheetname = `Page ${i}`;
                            allNames.push(sheetname);
                        }
                        xlsx.utils.book_append_sheet(wb, ws, `${sheetname}`);
                    });
                    xlsx.write(wb, { bookType: 'xlsx', bookSST: true, type: 'base64' });
                    // console.log(wb);
                    // wb.Sheets.forEach(sheet => {
                    // sheet.Range("A:Z".EntireColumn.AutoFit);
                    // });
                    const title = self.reportTitle ? self.reportTitle : `${self.Auth.getCurrentAccountSync().name}_${self.chosenResolution}_${self.chosenType}_Report_${self.moment().format('DD-MM-YYYY hh:mm:ss')}.xlsx`;
                    xlsx.writeFile(wb, title);
                    self.busyMessage = 'Download Complete';
                }
            })
                .catch((err) => {
                    console.log('This is the err', err);
                    if (err.status === 504) {
                        self.busyMessage = 'Process Timed Out...';
                        self.toastr.error('Process Timed Out...', {
                            preventOpenDuplicates: true,
                        });
                    } else {
                        self.busyMessage = 'Error occurred.';
                        self.toastr.error('An error occurred.', {
                            preventOpenDuplicates: true,
                        });
                    }
                });
        } else if (tsEnd < tsStart) {
            self.toastr.warning('Please ensure the \'To\' time is after the \'From\' time', {
                preventOpenDuplicates: true,
            });
        } else {
            self.toastr.warning('Info Required or Incorrect', {
                preventOpenDuplicates: true,
            });
        }
    }

    openDateTimePicker(index, picker, $event) {
        $event.preventDefault();
        $event.stopPropagation();
        this[picker] = !this[picker];
    }

    quickPick(option) {
        const self = this;
        const to = this.moment();
        const from = this.moment();
        switch (option) {
        case 'Last 24 Hours':
            self.startDate = self.startTime = from.subtract(24, 'hours').startOf('hour')
                .toDate();
            break;
        case 'Last 7 Days':
            self.startDate = self.startTime = from.subtract(7, 'days').startOf('hour')
                .toDate();

            break;
        case 'Last 31 Days':
            self.startDate = self.startTime = from.subtract(31, 'days').startOf('hour')
                .toDate();

            break;
        case 'Today':
            self.endTime = self.endDate = to.add(1, 'days').startOf('day')
                .toDate();
            self.startDate = self.startTime = from.startOf('day').toDate();
            break;
        case 'This Week':
            self.endTime = self.endDate = to.add(1, 'weeks').startOf('week')
                .toDate();
            self.startDate = self.startTime = from.startOf('week').toDate();
            break;
        case 'Last Month':
            self.endTime = self.endDate = to.startOf('month').toDate();
            self.startDate = self.startTime = from.subtract(1, 'months').startOf('month')
                .toDate();
            break;
        case 'This Month':
            self.endTime = self.endDate = to.add(1, 'months').startOf('month')
                .toDate();
            self.startDate = self.startTime = from.startOf('month').toDate();
            break;
        default:
        }
    }

    dateFormat() {
        const self = this;
        if (self.chosenResolution === 'Monthly') {
            return 'yyyy/MM';
        }
        return 'yyyy/MM/dd';
    }
}

export default angular.module('cameraViewerApp.report')
    .component('report', {
        template: require('./report.html'),
        controller: ReportComponent,
        controllerAs: '$ctrl',
    })
    .name;
