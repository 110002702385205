import angular from 'angular';

export class VerifyEmailRequestComponent {
    $stateParams;
    Auth;
    $log;
    url;
    sent = false;
    error;

    /* @ngInject */
    constructor($stateParams, Auth, $log, $window, $http) {
        this.$stateParams = $stateParams;
        this.Auth = Auth;
        this.$log = $log;
        this.url = this.$stateParams.next;
        this.$window = $window;
        this.$http = $http;
    }

    $onInit() {
        this.hostnameIcon = `assets/images/domainicons/${this.$window.location.hostname}.png`;
        this.$http
            .get(`assets/images/domainicons/${this.$window.location.hostname}.png`)
            .then((data) => {
                this.hostnameIcon = `assets/images/domainicons/${this.$window.location.hostname}.png`;
            })
            .catch((err) => {
                //
                this.hostnameIcon = 'assets/images/domainicons/secuvue.net.png';
            });
    }

    resendLink() {
        this.Auth.resendVerifyEmail().then(
            () => {
                this.sent = true;
            },
            function () {
                this.sent = false;
                this.error = 'Failed to send email';
            }
        );
    }
}

export default angular.module('cameraViewerApp.verify').component('verifyemailrequest', {
    template: require('./verify.email.request.html'),
    controller: VerifyEmailRequestComponent,
    controllerAs: '$ctrl',
}).name;
