import _ from 'lodash';
import angular from 'angular';

export class NewApiCallbackComponent {
    $stateParams;
    Restangular;
    settings;
    modalInstance;

    /* @ngInject */
    constructor($stateParams, $uibModal, $timeout, $http) {
        this.$stateParams = $stateParams;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
        this.$http = $http;
    }

    $onInit() {
        const self = this;
        if (self.resolve.create) {
            self.unit = {};
        } else if (self.resolve.edit) {
            self.unit = self.resolve.unit;
        } else {
            console.error('It is not certain whether a unit is being created or edited');
        }
        self.availableOperations = [
            {
                id: 'requestSnapshot',
                alias: 'Request Snapshot',
            },
        ];
        self.availableZones = [];
        self.$http.get('/api/sites/basic').then((result) => {
            console.log('Sites : ', result.data);
            self.availableZones = _.reduce(result.data, (zones, site) => {
                site.zones.forEach((zone) => {
                    zone.siteName = site.alias;
                    zones.push(zone);
                });
                return zones;
            }, []);
        });
    }

    ok(form) {
        const self = this;
        if (form.$valid) {
            self.modalInstance.close(self.apiCallback);
        }
    }

    cancel() {
        this.modalInstance.dismiss('Cancel pressed');
    }

    doLog() {
        console.log(this);
    }

    refreshZoneList() {
    }
}

export default angular.module('cameraViewerApp.apiCallbacks')
    .component('newApiCallback', {
        template: require('./new-apiCallback-modal.html'),
        bindings: {
            resolve: '<',
            modalInstance: '<',
        },
        controller: NewApiCallbackComponent,
        controllerAs: '$ctrl',
    })
    .name;
