import _ from 'lodash';
import angular from 'angular';
import { v1 as uuid } from 'uuid';

export class NewSiteComponent {
    /* @ngInject */
    constructor($ngConfirm, $scope, toastr, $timeout, $uibModal, $http, moment, socket, Auth, wizardService) {
        this.$ngConfirm = $ngConfirm;
        this.$scope = $scope;
        this.toastr = toastr;
        this.$timeout = $timeout;
        this.$uibModal = $uibModal;
        this.$http = $http;
        this.moment = moment;
        this.socket = socket;
        this.unavailableUnits = [];
        this.Auth = Auth;
        this.unavailErr = false;
        this.resetUnits = [];
        this.wizardService = wizardService;
        this.currentRooms = [];
        this.manualUrl = [''];
        this.manualMacEnabled = false;
        this.manualMac = '';
        this.origSite = {};
        this.contactTemplate = {
            firstname: 'First Name',
            lastname: 'Last Name',
            email: 'Email',
            phone: 'Phone',
        };
    }

    $onInit() {
        const self = this;

        self.zoneOnlyDragImage = self.handleZoneOnlyDragImage.bind(self);
        self.cameraMoveDragImage = self.handleCameraMoveDragImage.bind(self);
        self.cameraSwapDragImage = self.handleCameraSwapDragImage.bind(self);

        self.autoPoll = true;
        /**
         * The object that will contain the basic definition of our site
         * @type {Object}
         */
        self.siteDefinition = {};

        /**
         * When we add a new contact field in step 2, the ui-dropdown has
         * an is-open attribute pointing to this variable
         * @type {Boolean}
         */
        self.newContactFieldOpened = false;

        /**
         * As the name implies, we wish to handle some CSS display and ng-show when
         * this boolean is true
         * @see getAllCameras()
         * @type {Boolean}
         */
        self.allCamerasAreAllocated = false;

        /**
         * The array that contains all the units to be added to the site
         * @type {Array}
         */
        self.selectedUnits = [];

        // /**
        //  * Whether the user has selected to provide a default username and
        //  * password for polling cameras
        //  * @type {Boolean}
        //  */
        // self.definedUserPass = false;

        // self.defaultUsername = '';
        // self.defaultPassword = '';

        /**
         * We use this object to store any specifically defined username/password combinations
         * for specific cameras that require a different password than the default.
         * This way, we don't have to keep asking the user for it
         * @type {Object}
         */
        self.specifiedUserPass = {};

        /**
         * The array that contains all the units that the user will be able to choose from
         * @type {Array}
         */
        self.availableUnits = [];

        /**
         * Possible predefined options for new fields in contact-document
         * @type {Array}
         */
        self.newContactOptions = [
            {
                alias: 'Organization',
                description: 'Details for this contact\'s organization',
            },
            {
                alias: 'Address',
                description: 'A physical address for this contact',
            },
            {
                alias: 'Email',
                description: 'An e-mail address for this contact',
            },
            {
                alias: 'Notes',
                description: 'Some important notes concerning this contact',
            },
            {
                alias: 'Phonetic name',
                description: 'The phonetic pronunciation of this contact\'s name',
            },
            {
                alias: 'Custom',
                description: 'Choose to enter your own option',
            },
        ];

        /**
         * Being used at @see readySite().
         * If false, we know that this is a new site and that it hasn't
         * been created in our DB yet
         * @type {Boolean}
         */
        self.siteInDB = false;

        /**
         * This will become the array of cameras that can be seen by selected units
         * @type {Array}
         */
        self.availableCameras = [];

        /**
         * Array of cameras that we 'remove' and want to ignore.
         * @type {Array}
         */
        self.ignoredCameras = [];

        /**
         * When a user wants to highlight a camera's units
         * @type {Object}
         */
        self.highlightCamera = {};

        /**
         * @see readyReview();
         * We call this function when we are going to the last step in our wizard
         * to get some information for us
         * @type {Object}
         */
        self.zoneCollection = {};

        /**
         * We have a ng-if on this variable. We set it true after our first list
         * of availableCameras arrive.
         * This is used purely for CSS purposes
         * @type {Boolean}
         */
        self.camerasLoaded = false;

        /**
         * An object that allows us to retrieve information per assigned camera
         * @see updateCameraCollection();
         * @type {Object}
         */
        self.cameraCollection = {};

        /**
         * Have a ng-show on this variable to only show all cameras
         * after units have been selected.
         * @type {Boolean}
         */
        self.confirmedSelection = false;

        /**
         * Show spinner for each unit while polling for cameras.
         * @type {Object}
         */
        self.pollingForCamerasPerUnit = {};

        // TODO: Handle when a site is edited differently
        if (self.resolve.site && self.resolve.isEdit) {
            // this is an edit
            self.isEdit = true;
            self.siteInDB = true;
            self.siteDefinition = self.resolve.site;
            // self.siteDefinition.zones = _.filter(self.siteDefinition.zones,o=>{
            // return !o.disabled;
            // });
            self.siteDefinition.zones.forEach((zone, idx) => {
                if (zone.camera && (typeof zone.camera !== 'string')) {
                    self.siteDefinition.zones[idx].camera = self.siteDefinition.zones[idx].camera._id;
                }
                if (zone.unit && (typeof zone.unit !== 'string')) {
                    self.siteDefinition.zones[idx].unit = self.siteDefinition.zones[idx].unit._id;
                }
            });
            self.origSite = _.cloneDeep(self.resolve.site);
            self.registerSiteSocket(self.resolve.site._id);

            // Set site.initialized = false to disable site until config done
            // self.siteDefinition.initialized = false;
            if (self.resolve.site.units && self.resolve.site.units.length > 0) {
                self.confirmedSelection = false;
                self.selectedUnits = self.resolve.site.units;

                self.pollingForCameras = false;
                // _.forEach(self.selectedUnits, unit => {
                // unit.availableCameras = [];
                // self.pollCameras(unit._id);
                // });
            }

            self.onReceiptOfSiteClean(self.siteDefinition);
            // self.saveSite(self.siteDefinition).then(newSite => {
            // })
            // .catch(e => {
            // self.toastr.error(e.message || e, `Error saving site`, {
            // preventOpenDuplicates: true
            // });
            // });
            // self.onReceiptOfSite(self.resolve.site);
        } else {
            // this is a new site
            self.siteDefinition.address = ['', '', ''];
            self.siteDefinition.alias = 'Unnamed Site';
            self.siteDefinition.contacts = [{
                firstname: '',
                lastname: '',
                phone: '',
                email: '',
            }];
            self.siteDefinition.location = {
                type: 'Point',
                coordinates: [],
            };
            self.siteInDB = false;
            // self.isNew = true;
        }

        self.getAvailableUnits();
    }

    $onDestroy() {
        const self = this;

        if (self.origSite) {
            self.origSite.units?.forEach((unit) => {
                self.updateUnit(unit._id, {
                    assigned: unit.assigned,
                    site: unit.site,
                });
            });
        }
        if (self.theSiteEventListener) {
            self.socket.socket.removeListener('site:save', self.theSiteEventListener);
        }
        if (self.theUnitEventListener) {
            self.socket.socket.removeListener('unit:save', self.theUnitEventListener);
        }
        if (self.unitListenerClean) {
            self.socket.socket.removeListener('unit:setupSave', self.unitListenerClean);
        }
        if (self.siteListenerClean) {
            self.socket.socket.removeListener('site:setupSave', self.siteListenerClean);
        }
        if (self.theUnitPollCamerasEventListener) {
            self.socket.socket.removeListener('unit:pollCamerasResult', self.theUnitPollCamerasEventListener);
        }
        if (self.currentRooms.length > 0) {
            self.currentRooms.forEach((room) => {
                self.socket.leaveRoom(room);
            });
        }
    }

    /**
     * To act as an intermediary between our component and wizardService, this allows
     * us to firstly do some logic before we decide to go to the next step
     */
    nextStep(form) {
        const self = this;
        if (form) {
            if (form.$valid || form.$pristine) {
                self.wizardService.goToWizardStep('next');
            } else {
                const list = [];
                if (form.$error.required) {
                    _.forEach(form.$error.required, (err) => {
                        const description = self.getErrDescriptionFromName(err.$name);
                        list.push(description);
                    });
                }

                // The html here is not complete, we do so when we add the last <li></li>
                let message = `
                <span>
                    Please carefully inspect the details entered at the following elements:
                    <ul>
                `;

                _.forEach(list, (listItem, index) => {
                    let itemString;
                    if (index !== list.length - 1) {
                        itemString = `<li>${listItem} </li>`;
                    } else {
                        itemString = `<li>${listItem} </li> </ul> </span>`;
                    }

                    message = message.concat(itemString);
                });

                self.toastr.error(message, 'Incorrect information Entered', {
                    preventOpenDuplicates: true,
                    allowHtml: true,
                });
            }
        }
    }

    /**
     * This function is being used in conjunction with our form-validation at step 1
     */
    getErrDescriptionFromName(name) {
        const el = angular.element(document.getElementById(name).parentElement.parentElement);
        const identifierEl = el.find('.identifier');
        return identifierEl[0].innerText;
    }

    onReceiptOfSiteClean(newSite) {
        const self = this;
        if (newSite.units && newSite.units.length > 0) {
            newSite.units.forEach((unit) => {
                if (unit.availableCameras && unit.availableCameras.length > 0) {
                    if (self.pollingForCameras) {
                        self.pollingForCameras = false;
                    }
                    unit.availableCameras.forEach((cam) => {
                        let notFound = true;
                        let referenceShot;
                        self.siteDefinition.units.forEach((currUnit) => {
                            if (currUnit.availableCameras) {
                                currUnit.availableCameras.forEach((currCam) => {
                                    if (currCam._id === cam._id) {
                                        if (Object.prototype.hasOwnProperty.call(currCam, 'username') && currCam.username !== undefined) {
                                            currCam.username = cam.username;
                                        }
                                        if (Object.prototype.hasOwnProperty.call(currCam, 'password') && currCam.password !== undefined) {
                                            currCam.password = cam.password;
                                        }
                                        notFound = false;
                                        if (currCam.referenceShot) {
                                            referenceShot = currCam.referenceShot;
                                        }
                                    }
                                });
                            }
                        });
                        // TODO: Test this
                        if (notFound) {
                            self.onRequestReferenceShot(unit._id, cam);
                        }
                        if (referenceShot && !cam.referenceShot) {
                            cam.referenceShot = referenceShot;
                        }
                    });
                } else {
                    console.log('This unit does not see any cameras');
                }
            });
        }

        self.groupUnitsByCameras(newSite);
        self.updateCameraCollection(newSite);
        self.updateZoneCollection(newSite);
        self.siteDefinition = newSite;
        return self.siteDefinition;
    }

    /**
     * To iterate through our units and find all unique cameras
     * @param  {[type]} site [description]
     * @return {[type]}       [description]
     */
    groupUnitsByCameras(site) {
        const self = this;
        self.availableCameras = self.getAllCameras(site);
        // self.availableCameras.forEach((cam) => {
        //     cam.referenceShot = cam.referenceShot;
        // });
        self.ensureAllCamsInUnit(site);
    }

    getAllCameras(site) {
        const self = this;
        const allCameras = [];

        // We iterate through every unit in siteDefinition
        _.forEach(site.units, (unit) => {
            // We iterate over this unit's availableCameras
            _.forEach(unit.availableCameras, (cam) => {
                // Just making sure that the camera being in availableCameras is not down
                if (!cam.down) {
                    // NOTE temporarily deleting endpoint
                    if (cam.endpoint) {
                        delete cam.endpoint;
                    }

                    // We check if this cam is already in our allCameras
                    const idx = _.findIndex(allCameras, (o) => o._id === cam._id);
                    if (idx !== -1) {
                        const unitIdx = _.findIndex(allCameras[idx].units, (unitID) => unitID === unit._id);
                        if (Object.prototype.hasOwnProperty.call(cam, 'password') && cam.password !== undefined) {
                            allCameras[idx].password = cam.password;
                        }
                        if (Object.prototype.hasOwnProperty.call(cam, 'username') && cam.password !== undefined) {
                            allCameras[idx].username = cam.username;
                        }

                        if (unitIdx === -1) {
                            allCameras[idx].units.push(unit._id);
                        }
                    } else {
                        // This cam is not in our allCameras
                        const tempCam = _.cloneDeep(cam);
                        tempCam.units = [unit._id];
                        tempCam.ignored = self.isIgnored(tempCam._id);
                        if (self.isAssignedToUnit(tempCam._id) && self.isAssignedToZone(tempCam._id)) {
                            tempCam.assigned = true;
                        } else if (self.isAssignedToUnit(tempCam._id) || self.isAssignedToZone(tempCam._id)) {
                            console.error(`412: This camera is assigned to either a zone or a unit, ID: ${tempCam._id}, UNIT: ${unit._id}`);
                        } else {
                            console.log(`414: Camera not assigned: ${tempCam._id}, UNIT: ${unit._id}`);
                            tempCam.assigned = false;
                        }

                        allCameras.push(tempCam);
                    }
                }
            });
        });

        // We iterate through every unit in siteDefinition, again, but this time allCameras already contains each availableCamera
        _.forEach(site.units, (unit) => {
            // We iterate over this unit's cameras
            _.forEach(unit.cameras, (cam) => {
                const idx = _.findIndex(allCameras, (o) => o._id === cam._id);
                if (idx !== -1) {
                    // This camera is already in allCameras

                    // We take this camera and see whether this unit is already in its units array
                    const unitIdx = _.findIndex(allCameras[idx].units, (unitID) => unitID === unit._id);
                    if (Object.prototype.hasOwnProperty.call(cam, 'password') && cam.password !== undefined) {
                        allCameras[idx].password = cam.password;
                    }
                    if (Object.prototype.hasOwnProperty.call(cam, 'username') && cam.password !== undefined) {
                        allCameras[idx].username = cam.username;
                    }

                    // If it isn't, we push it
                    if (unitIdx === -1) {
                        allCameras[idx].units.push(unit._id);
                    }
                } else {
                    // This camera is not yet in our allCameras
                    const tempCam = _.cloneDeep(cam);
                    tempCam.units = [unit._id];
                    // tempCam.down = true;
                    tempCam.ignored = self.isIgnored(tempCam._id);
                    if (self.isAssignedToUnit(tempCam._id) && self.isAssignedToZone(tempCam._id)) {
                        tempCam.assigned = true;
                    } else if (self.isAssignedToUnit(tempCam._id) || self.isAssignedToZone(tempCam._id)) {
                        console.error(`457: This camera is assigned to either a zone or a unit, ID: ${tempCam._id}, UNIT: ${unit._id}`);
                    } else {
                        console.log(`460: Camera not assigned: ${tempCam._id}, UNIT: ${unit._id}`);
                        tempCam.assigned = false;
                    }
                    allCameras.push(tempCam);
                }
            });
        });

        let allCamerasAreAllocated = true;
        _.forEach(allCameras, (cam) => {
            // We now have allCameras and go through each once, checking it if it may be assigned
            cam.canSubstitute = self.canSubstitute(cam, cam.units);

            // If a single camera is assigned, our boolean gets made true
            if (!cam.assigned && !cam.down && !cam.ignored) {
                allCamerasAreAllocated = false;
            }
        });
        self.allCamerasAreAllocated = allCamerasAreAllocated;
        return allCameras;
    }

    /**
     * Since we iterate through unit.availableCameras in HTML, we wish to put some units there
     * even if they aren't there truthfully. For example, when a unit has a camera in CAMERAS but
     * not in AVAILABLECAMERAS
     */
    ensureAllCamsInUnit(site) {
        const self = this;
        _.forEach(site.units, (unit) => {
            const downCams = _.differenceBy(unit.cameras, unit.availableCameras, '_id');
            downCams.forEach((cam) => {
                // cam.down = true;
                cam.removeBeforeSave = true;
                if (unit.availableCameras === undefined) {
                    unit.availableCameras = [];
                }
                unit.availableCameras.push(cam);
            });
            unit.availableCameras.forEach((tempCam) => {
                // NOTE: temporarily deleting this
                if (tempCam.endpoint) {
                    delete tempCam.endpoint;
                }

                // Just making sure that we're not somehow receiving a camera in our availableCameras that is offline
                // if(tempCam.down) {
                // console.log("This cam is down....", tempCam.alias);
                // let tcIndex = _.findIndex(unit.availableCameras, (cam) => {
                // return cam._id === tempCam._id;
                // });
                // unit.availableCameras.splice(tcIndex, 1);
                // } else {
                // The tempCam is in fact, online and NOT down
                // We check whether the cam is on our ignored list
                tempCam.ignored = self.isIgnored(tempCam._id);

                if (self.isAssignedToUnit(tempCam._id) && self.isAssignedToZone(tempCam._id)) {
                    const index = _.findIndex(unit.cameras, (o) => o._id === tempCam._id);

                    if (index !== -1) {
                        tempCam.isAssignedToUnit = true;
                        tempCam.isAssignedToZone = true; // NOTE:
                    } else {
                        tempCam.isAssignedToUnit = false;
                        tempCam.isAssignedToZone = false; // NOTE:
                    }
                    tempCam.assigned = true;
                } else if (self.isAssignedToUnit(tempCam._id) || self.isAssignedToZone(tempCam._id)) {
                    console.error(`549: This camera is assigned to either to a zone or a unit, but not both. ID: ${tempCam._id}, UNIT: ${unit._id}`);
                } else {
                    console.log(`551: Camera not assigned: ${tempCam._id}, UNIT: ${unit._id}`);
                    tempCam.assigned = false;
                    tempCam.isAssignedToUnit = false;
                    tempCam.isAssignedToZone = false;
                }
                // }
            });
        });
    }

    onReceiptOfUnitClean(newUnit) {
        const self = this;
        let unitIdx = -1;
        const siteCopy = _.cloneDeep(self.siteDefinition);

        if (siteCopy.units) {
            unitIdx = _.findIndex(siteCopy.units, (o) => o._id === newUnit._id);
        }

        if (unitIdx !== -1) {
            if (newUnit.eventType === 'PollSetup') {
                if (siteCopy && siteCopy.units) {
                    siteCopy.units[unitIdx].availableCameras = newUnit.availableCams;
                }
                self.pollingForCamerasPerUnit[newUnit._id] = false;
            } else if (newUnit.eventType === 'RequestSetupReference') {
                const currentCamIdx = _.findIndex(siteCopy.units[unitIdx].availableCameras, (o) => o._id === newUnit.camera);
                if (currentCamIdx !== -1) {
                    siteCopy.units[unitIdx].availableCameras[currentCamIdx].referenceShot = newUnit.referenceShot;
                    siteCopy.units[unitIdx].availableCameras[currentCamIdx].lastActivity = newUnit.lastActivity;
                    siteCopy.units[unitIdx].availableCameras[currentCamIdx].authenticationFailed = newUnit.jsonParsed.authenticationFailed || false;
                    siteCopy.units[unitIdx].availableCameras[currentCamIdx].authenticationNeeded = newUnit.jsonParsed.authenticationNeeded || true;
                    if (newUnit.jsonParsed.password) {
                        siteCopy.units[unitIdx].availableCameras[currentCamIdx].password = newUnit.jsonParsed.password;
                    }
                    if (newUnit.jsonParsed.username) {
                        siteCopy.units[unitIdx].availableCameras[currentCamIdx].username = newUnit.jsonParsed.username;
                    }
                }
            }
            siteCopy.units.forEach((unit) => {
                if (unit.availableCameras && unit.availableCameras.length > 0) {
                    if (self.pollingForCameras) {
                        self.pollingForCameras = false;
                    }
                    unit.availableCameras.forEach((cam) => {
                        let notFound = true;
                        let referenceShot;
                        self.siteDefinition.units.forEach((currUnit) => {
                            if (currUnit.availableCameras) {
                                currUnit.availableCameras.forEach((currCam) => {
                                    if (currCam._id === cam._id) {
                                        if (Object.prototype.hasOwnProperty.call(currCam, 'username') && currCam.username !== undefined) {
                                            currCam.username = cam.username;
                                        }
                                        if (Object.prototype.hasOwnProperty.call(currCam, 'password') && currCam.password !== undefined) {
                                            currCam.password = cam.password;
                                        }
                                        notFound = false;
                                        if (currCam.referenceShot) {
                                            referenceShot = currCam.referenceShot;
                                        }
                                    }
                                });
                            }
                        });
                        // TODO: Test this
                        if (notFound) {
                            self.onRequestReferenceShot(unit._id, cam);
                        }
                        if (referenceShot && !cam.referenceShot) {
                            cam.referenceShot = referenceShot;
                        }
                    });
                } else {
                    self.pollingForCameras = false;
                }
            });
            self.groupUnitsByCameras(siteCopy);
            self.updateCameraCollection(siteCopy);
            self.updateZoneCollection(siteCopy);
            self.siteDefinition = siteCopy;
        }
    }

    onReceiptOfUnit(newUnit) {
        const self = this;
        const unIdx = _.findIndex(self.unavailableUnits, (o) => o._id === newUnit._id);
        if (newUnit.assigned && newUnit.site !== self.siteDefinition._id) {
            if (unIdx === -1) {
                self.unavailableUnits.push(newUnit);
            }
        } else if (unIdx !== -1) {
            self.unavailableUnits.splice(unIdx, 1);
        }

        const index = _.findIndex(self.availableUnits, (unit) => unit._id === newUnit._id);

        if (index !== -1) {
            // This unit is in availableUnits
            const selInd = _.findIndex(self.selectedUnits, (o) => o._id === newUnit._id);
            if (selInd !== -1) {
                if (newUnit.assigned) {
                    // TODO: What to do now?
                } else {
                    self.selectedUnits[selInd] = newUnit;
                }
            }

            if (newUnit.assigned) {
                self.availableUnits.splice(index, 1);
            } else {
                self.availableUnits[index] = newUnit;
            }
            // This unit is NOT in availableUnits
        } else if (newUnit.assigned === false) {
            self.availableUnits.push(newUnit);
        }

        const selectIdx = _.findIndex(self.selectedUnits, (unit) => unit._id === newUnit._id);

        if (selectIdx !== -1) {
            if (unIdx !== -1) {
                self.unavailErr = true;
            }
            if (newUnit.site && newUnit.site !== self.siteDefinition._id) {
                self.toastr.warning(`This unit has been assigned to another site: ${newUnit._id}\nPlease select a different unit`, 'Unit no longer available', {
                    preventOpenDuplicates: true,
                });
                if (!self.confirmedSelection) {
                    self.selectedUnits.splice(selectIdx, 1);
                }
            }
        }

        // self.availableUnits = _.uniq(self.availableUnits);
    }

    createSite(site) {
        const self = this;
        site.zones?.forEach((zone) => {
            if (zone.hasTempID) {
                delete zone._id;
            }
        });
        return self.$http.post('/api/sites/', site)
            .then((response) => {
                if (response && response.data) {
                    self.registerSiteSocket(response.data._id);
                    return response.data;
                }
                return 'No account created';
            });
    }

    getAvailableUnits() {
        const self = this;
        self.pollingForAvailableUnits = true;
        return self.$http.get('/api/units', {
                params: {
                    params: JSON.stringify([ { field: 'assigned', type: 'boolean', value: false } ])
                }
            })
            .then((unitResponse) => {
                if (unitResponse && unitResponse.data) {
                    self.availableUnits = _.filter(unitResponse.data, (o) => o.assigned === false);
                    self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().accountId}:*:units`);
                    self.currentRooms.push(`${self.Auth.getCurrentAccountSync().accountId}:*:units`);
                    self.theUnitEventListener = self.onReceiptOfUnit.bind(self);
                    self.socket.socket.on('unit:save', self.theUnitEventListener);

                    self.unitListenerClean = self.onReceiptOfUnitClean.bind(self);
                    self.socket.socket.on('unit:setupSave', self.unitListenerClean);

                    self.theUnitPollCamerasEventListener = self.onReceiptOfPollingCameras.bind(self);
                    self.socket.socket.on('unit:pollCamerasResult', self.theUnitPollCamerasEventListener);
                    self.pollingForAvailableUnits = false;
                }
                return unitResponse.data;
            });
    }

    registerSiteSocket(siteId) {
        const self = this;

        self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().accountId}:${siteId}:sites`);
        self.currentRooms.push(`${self.Auth.getCurrentAccountSync().accountId}:${siteId}:sites`);

        // self.theSiteEventListener = self.onReceiptOfSite.bind(self);
        // self.socket.socket.on('site:save', self.theSiteEventListener);

        self.siteListenerClean = self.onReceiptOfSiteClean.bind(self);
        self.socket.socket.on('site:setupSave', self.siteListenerClean);
    }

    saveSite(site) {
        const tempSite = _.cloneDeep(site);
        if (tempSite.units && tempSite.units.length > 0) {
            tempSite.units.forEach((unit) => {
                if (unit.availableCameras && unit.availableCameras.length > 0) {
                    unit.availableCameras.forEach((cam) => {
                        if (cam.referenceShot) {
                            delete cam.referenceShot;
                        }
                    });
                }
                if (unit.cameras && unit.cameras.length > 0) {
                    unit.cameras.forEach((cam) => {
                        if (cam.referenceShot) {
                            delete cam.referenceShot;
                        }
                    });
                }
            });
        }

        return this.$http.patch(`/api/sites/${tempSite._id}`, _.omit(tempSite, ['__v']))
            .then((response) => {
                if (response && response.data) {
                    return response.data;
                }
                return 'No site returned';
            });
    }

    finalSaveSite(site) {
        console.log('This is the final save site:', site);
        const self = this;
        site.zones?.forEach((zone) => {
            if (zone.hasTempID) {
                delete zone._id;
            }
        });
        let tempSite = _.cloneDeep(site);
        if (tempSite.units && tempSite.units.length > 0) {
            tempSite.units.forEach((unit) => {
                delete unit.availableCameras;
                if (unit.cameras && unit.cameras.length > 0) {
                    unit.cameras.forEach((cam) => {
                        if (cam.referenceShot) {
                            delete cam.referenceShot;
                        }
                    });
                }
            });
        }
        site.units.forEach((unit) => {
            self.updateUnit(unit._id, {
                assigned: unit.assigned,
                site: unit.site,
            })
                .then(() => {

                });
        });

        // If the site has not been initialized yet, units do not need to be reset.
        if (self.siteDefinition.initialized) {
            self.resetUnits.forEach((unit) => {
                self.updateUnit(unit._id, {
                    assigned: false,
                    site: null,
                })
                    .then(() => {});
                self.resetUnit(unit._id);
            });
        }
        if (tempSite.contacts.length === 1 && tempSite.contacts[0].firstname === '' && tempSite.contacts[0].lastname === '') {
            tempSite = _.omit(tempSite, ['contacts']);
        }
        console.log('This is the tempSite: ', tempSite);
        return this.$http.patch(`/api/sites/${tempSite._id}/initialize`, _.omit(tempSite, ['__v']))
            .then((response) => {
                if (response && response.data) {
                    return response.data;
                }
                return 'No site returned';
            });
    }

    removeSite(site) {
        const self = this;
        self.siteDefinition.units.forEach((unit) => {
            unit.assigned = false;
            unit.site = null;
            self.onReceiptOfUnit(unit);
            // self.updateUnit(unit._id, {
            //     assigned: false,
            //     site: null
            // })
            //     .then(unit => {
            //         if (!unit._id) {
            //             console.error('Unit could not be unnassigned');
            //         }
            //     });
        });
        return this.$http.delete(`/api/sites/${site._id}`)
            .then((response) => {
                if (response && response.data) {
                    return response.data;
                }
                return 'No site removed';
            });
    }

    getRealUnitByID(unitID) {
        const self = this;
        return _.find(self.siteDefinition.units, (storedUnit) => storedUnit._id === unitID);
    }

    getRealZoneByID(zoneID) {
        const self = this;
        return _.find(self.siteDefinition.zones, (storedZone) => storedZone._id === zoneID);
    }

    getCameraByID(cameraID) {
        const self = this;
        return _.find(self.availableCameras, (cam) => cam._id === cameraID);
    }

    //* *****************************PERTAINING TO STEP 1********************************//

    /**
     * Being called from our ng-tags-input in STEP 1 whenever a new tag gets created by the user
     * NOTE that we are manually putting a click handler onto the removeButton (@see onEditZoneTag)
     * TODO Find out whether this 'click'-event should be removed?
     */
    onZoneTagAdded($tag) {
        const self = this;
        console.log('Tagging');
        if (!$tag._id) {
            $tag._id = uuid();
        }
        $tag.hasTempID = true;
        console.log('Tagged');
        $tag.unit = 'UNBOUNDED';
        $tag.camera = 'UNBOUNDED';
        console.log(self.siteDefinition.zones);
        self.$timeout(() => {
            const removeButton = angular.element(document.getElementById(`zone-remove-${$tag.alias}`));
            removeButton.on('click', ($event) => {
                self.deleteTag($tag);
            });
        });
    }

    /**
     * I have manipulated ng-tags-input's on-tag-removing attribute. Instead of removing the tag, I use this function
     * and return false as to NOT remove the tag.
     * I then use ng-confirm to handle editing the tag.
     * This is because as of this writing, ng-tags-input does not provide for editing tags.
     * @param  {[type]} $tag [description]
     */
    onEditZoneTag($tag) {
        const self = this;
        self.$ngConfirm(
            {
                title: '<span style="text-align:center; display:flex; justify-content:center; font-weight:bold;">Change zone alias</span>',
                theme: 'light',
                animation: 'top',
                // scope: self.$scope,
                closeAnimation: 'bottom',
                content: require('./remove-tag.html'),
                escapeKey: true,
                backgroundDismiss: true,
                buttons: {
                    // long hand button definition
                    ok: {
                        text: 'Ok',
                        btnClass: 'btn-primary',
                        keys: ['enter'], // will trigger when enter is pressed
                        action(scope) {
                            // DO LOGIC HERE
                            const newAlias = scope.$ctrl.newZoneAlias;
                            let oldZone;

                            if ($tag._id) {
                                oldZone = _.find(self.siteDefinition.zones, (zone) => zone._id === $tag._id);
                                oldZone.alias = newAlias;
                            } else {
                                oldZone = _.find(self.siteDefinition.zones, (zone) => zone.alias === $tag.alias);
                                if (oldZone !== undefined) {
                                    oldZone.alias = newAlias;
                                } else {
                                    console.error('Can not find the zone to rename: ', $tag.alias);
                                }
                            }
                        },
                    },
                    close(scope) {

                    },
                },
            },
        );

        // I return false so that the tag will not be removed
        return false;
    }

    /**
     * The  function that removes a zone tag. NOTE that we currently only call this from
     * the remove button in ng-tags-input to which we BIND a 'click'event
     * @param  {[type]} $tag [description]
     * @return {[type]}      [description]
     */
    deleteTag($tag) {
        const self = this;

        const index = _.findIndex(self.siteDefinition.zones, (zone) => zone.alias === $tag.alias);
        if (index !== -1) {
            if (self.siteDefinition.zones[index].unit === 'UNBOUNDED') {
                self.siteDefinition.zones.splice(index, 1);
            } else {
                self.toastr.info(
                    '<span>This zone is currently bound to another unit and can therefore not be removed</span><br/>'
                    + '<span>Unbind the zone first, should you wish to remove it</span>',
                    'Active Zone',
                    {
                        preventOpenDuplicates: true,
                        allowHtml: true,
                        timeOut: 10000,
                        tapToDismiss: true,
                        progressBar: true,
                    },
                );
            }
        } else {
            console.error('Cannot find the zone:', $tag);
        }
    }

    newAddressLine() {
        const self = this;
        self.siteDefinition.address.push('');
    }

    removeAddressLine(index) {
        const self = this;
        self.siteDefinition.address.splice(index, 1);
    }

    newContact(contact) {
        const self = this;

        if (contact) {
            self.siteDefinition.contacts.push(contact);
        } else {
            self.siteDefinition.contacts.push({
                firstname: '',
                lastname: '',
                phone: '',
                email: '',
            });
        }
    }

    removeContact(contact) {
        const self = this;
        const index = _.findIndex(self.siteDefinition.contacts, (storedContact) => storedContact.firstname === contact.firstname);

        if (index !== -1) {
            self.siteDefinition.contacts.splice(index, 1);
        } else {
            console.error('Trying to remove a contact that shouldn\'t exist in the first place');
        }

        if (self.siteDefinition.contacts.length === 0) {
            self.newContact();
        }
    }

    /**
     * Allows a user to specify a new type of field he desires to include for a contact
     */
    newContactField(contact) {
        const self = this;

        self.$timeout(() => {
            if (!self.newContactFieldOpened) {
                self.newContactFieldOpened = true;

                self.$timeout(() => {
                    self.newContactFieldOpened = true;
                }, 200);
            }
        }, 200);

        const index = _.findIndex(self.siteDefinition.contacts, (storedContact) => storedContact.firstname === contact.firstname);

        self.$ngConfirm(
            {
                title: '<span style="display:flex; justify-content:center;">Add Contact Field</span>',
                theme: 'light',
                animation: 'top',
                scope: self.$scope,
                closeAnimation: 'bottom',
                content: require('./new-contact-field.html'),
                escapeKey: true,
                backgroundDismiss: true,
                buttons: {
                    // long hand button definition
                    ok: {
                        text: 'Ok',
                        btnClass: 'btn-primary',
                        keys: ['enter'], // will trigger when enter is pressed
                        action(scope) {
                            const result = self.selectedField.alias === 'Custom' ? self.customField.toLowerCase() : self.selectedField.alias.toLowerCase();
                            self.selectedField = '';
                            self.siteDefinition.contacts[index][result] = '';
                            self.newContactFieldOpened = false;
                        },
                    },
                    close(scope) {
                        self.newContactFieldOpened = false;
                    },
                },
            },
        );
    }

    /**
     * A function that calls on the ContactsListComponent to make contacts more generic
     */
    importContacts() {
        const self = this;
        const modalInstance = self.$uibModal.open({
            component: 'contactslist',
            backdrop: 'static',
            size: 'xlg',
            resolve: {
                definingSite: true,
                isModal: true,
            },
        });

        modalInstance.result.then((result) => {
            if (self.siteDefinition.contacts.length === 1) {
                const contact = self.siteDefinition.contacts[0];
                if (contact.firstname === '' || contact.firstname === '') {
                    self.siteDefinition.contacts[0] = result;
                } else {
                    self.newContact(result);
                }
            } else {
                const numOfContacts = self.siteDefinition.contacts.length;
                const lastContact = self.siteDefinition.contacts[numOfContacts - 1];
                if (lastContact.firstname === '' || lastContact.firstname === '') {
                    self.siteDefinition.contacts[numOfContacts - 1] = result;
                } else {
                    self.newContact(result);
                }
            }
        });
    }

    /**
     * When the user wants to choose coordinates by using a map
     * @return {[type]} [description]
     */
    openMapModal() {
        const self = this;
        const loc = {
            lat: self.siteDefinition.location.coordinates[1],
            lng: self.siteDefinition.location.coordinates[0],
        };
        if (loc.lat && loc.lng) {
            if (typeof loc.lat === 'string') {
                loc.lat = parseFloat(loc.lat.replace(/,/, '.'));
            }
            if (typeof loc.lng === 'string') {
                loc.lng = parseFloat(loc.lng.replace(/,/, '.'));
            }
        }

        const modalInstance = self.$uibModal.open({
            component: 'minimapmodal',
            backdrop: 'static',
            size: 'lg',
            keyboard: false,
            resolve: {
                loc,
            },
        });

        modalInstance.result.then((result) => {
            self.siteDefinition.location.coordinates[0] = result.lng;
            self.siteDefinition.location.coordinates[1] = result.lat;

            if (result.address.street && result.address.street !== '') {
                self.siteDefinition.address[0] = result.address.street;
            }

            if (result.address.locality && result.address.locality !== '') {
                self.siteDefinition.address[1] = result.address.locality;
            }
        });

        self.siteDefinition = {};
    }

    readySite() {
        const self = this;

        if (!self.isEdit) {
            // self.onReceiptOfSiteClean(self.siteDefinition);
            if (!self.siteDefinition._id) {
                // The user is going to step 2. The basic site structure should be created in our DB
                return self.createSite(self.siteDefinition)
                    .then((newSite) => {
                        self.siteDefinition = newSite;
                        self.isEdit = true;
                        return {
                            error: null,
                            data: newSite,
                        };
                    })
                    .catch((e) => {
                        console.error(e);
                        self.toastr.error(e.message || e, 'Error creating site', {
                            preventOpenDuplicates: true,
                        });
                        return {
                            error: e,
                            data: null,
                        };
                    });
            }
            return self.saveSite(self.siteDefinition)
                .then((newSite) => {
                    // self.siteDefinition = newSite;
                    self.isEdit = true;
                    return {
                        error: null,
                        data: newSite,
                    };
                })
                .catch((e) => {
                    console.error(e);
                    self.toastr.error(e.message || e, 'Error saving site', {
                        preventOpenDuplicates: true,
                    });
                    return {
                        error: e,
                        data: null,
                    };
                });
            // This site has already been created in our DB

            // } else {
            // self.origSite = self.onReceiptOfSiteClean(self.siteDefinition);
        }
        return {
            error: null,
            data: null,
        };
    }

    /** ******************************PERTAINING TO STEP 2********************************* */

    /**
     * To see whether a camera has already been assigne to a unit
     * @return {Boolean}       Return true if the camID can be found within
     * a siteDefinition's unit's CAMERAS array
     */
    isAssignedToUnit(camID) {
        const self = this;
        let found = false;

        _.forEach(self.siteDefinition.units, (storedUnit) => {
            const index = _.findIndex(storedUnit.cameras, (o) => o._id === camID);
            if (index > -1) {
                found = true;
            }
        });
        return found;
    }

    /**
     * Whether a camera has been assigned to a zone
     * @param  {[type]}  camID [description]
     * @return {Boolean}       [description]
     */
    isAssignedToZone(camID) {
        const self = this;
        let found = false;

        _.forEach(self.siteDefinition.zones, (zone) => {
            if (zone.camera === camID) {
                found = true;
            }
        });
        return found;
    }

    /**
     * To see whether a camera is in our array of ignoredCameras
     * @see self.ignoredCameras
     */
    isIgnored(camID) {
        const self = this;
        let ignored = false;

        const idx = _.findIndex(self.ignoredCameras, (ignoredCamera) => ignoredCamera._id === camID);

        if (idx !== -1) {
            ignored = true;
        }
        return ignored;
    }

    /**
     * When a camera (that is unassigned) may be seen as a potential candidate for substitution
     * replacement of another camera (that is assigned)
     * @param cam - A camera object
     * @param unitIDs - An array of unitIDs
     */
    canSubstitute(cam, unitIDs) {
        const self = this;
        let canSubstitute = false;

        if (cam.ignored || cam.assigned || cam.down) {
            return false;
        }

        _.forEach(unitIDs, (unitID) => {
            const idx = _.findIndex(self.siteDefinition.units, (o) => o._id === unitID);
            if (idx !== -1) {
                if (self.siteDefinition.units[idx].cameras && self.siteDefinition.units[idx].cameras.length > 0) {
                    canSubstitute = true;
                    return false;
                }
            } else {
                console.error('This unit cannot be found:', unitID);
            }
        });
        return canSubstitute;
    }

    /**
     * We are calling this function form the select in step 2 simply to warn a user
     * if he removes a unit that was in the siteDefinition
     */
    onRemovedSelectionUnit(unit) {
        const self = this;

        const index = _.findIndex(self.siteDefinition.units, (o) => o._id === unit._id);

        if (index !== -1) {
            self.$ngConfirm(
                {
                    title: '<span style="display:flex; justify-content:center; font-weight:bold;">Remove Unit?</span>',
                    theme: 'supervan',
                    animation: 'top',
                    // scope: self.$scope,
                    closeAnimation: 'bottom',
                    content:
                        `<span> You are trying to remove a unit that has been added (and potentially configured) to this site.</span> <br> <br>
                    <span> Are you sure that you wish to remove this unit?</span> <br>
                    <small>Note that zones and cameras allocated to this unit will become unbounded</small>`,
                    escapeKey: true,
                    backgroundDismiss: true,
                    buttons: {
                        // long hand button definition
                        ok: {
                            text: 'Remove',
                            btnClass: 'btn-primary',
                            keys: ['enter'], // will trigger when enter is pressed
                            action(scope) {
                                self.siteDefinition.units.splice(index, 1);
                                self.siteDefinition.zones.forEach((zone) => {
                                    if (zone.unit === unit._id) {
                                        zone.unit = 'UNBOUNDED';
                                        zone.camera = 'UNBOUNDED';
                                    }
                                });
                                unit.assigned = false;
                                delete unit.site;
                                const unIdx = _.findIndex(self.unavailableUnits, (o) => o._id === unit._id);
                                if (unIdx === -1) {
                                    self.availableUnits.push(unit);
                                    // self.unavailableUnits.splice(unIdx, 1);
                                }
                                if (_.intersectionBy(self.unavailableUnits, self.selectedUnits, '_id').length === 0) {
                                    self.unavailErr = false;
                                }
                                unit.assigned = false;
                                unit.site = null;
                                self.onReceiptOfUnit(unit);
                                // self.updateUnit(unit._id, {assigned: false, site: null});
                                self.resetUnits.push(unit);
                                // self.resetUnit(unit._id);

                                self.onReceiptOfSiteClean(self.siteDefinition);
                                // self.saveSite(self.siteDefinition).then(newSite => {
                                // });
                            },
                        },
                        close: {
                            text: 'Cancel',
                            btnClass: 'btn-default',
                            keys: ['enter'], // will trigger when enter is pressed
                            action(scope) {
                                self.selectedUnits.push(unit);
                            },
                        },
                    },
                },
            );
        }
    }

    resetUnit(unitId) {
        const self = this;
        self.$http.post(`/api/units/${unitId}/resetUnit`, {})
            .then((response) => {
                console.log('Unit has been reset');
            });
    }

    /**
     * After selection units, the user clicks on an HTML button. This has to:
     * 1) Take each selected units
     * 1.1) Push into site units
     * 2)Poll cameras on each unit
     * @return {[Promise]} [Resolves when polling started successfully]
     */
    confirmUnitsSelection() {
        const self = this;
        const selectionCount = Object.keys(self.selectedUnits).length;

        if (self.siteDefinition.useDefaultAuthentication && self.siteDefinition.defaultUsername === '') {
            self.toastr.warning('Please enter a valid username and password', 'Invalid credentials', {
                preventOpenDuplicates: true,
            });
            return;
        }

        if (selectionCount > 0) {
            if (self.siteDefinition.units) {
                // We see whether any units have been removed
                const removedUnits = _.differenceBy(self.siteDefinition.units, self.selectedUnits, '_id');
                if (removedUnits.length > 0) {
                    // We have removedUnits and remove them from our siteDefinition
                    removedUnits.forEach((unit) => {
                        const idx = _.findIndex(self.siteDefinition.units, (o) => o._id === unit._id);
                        if (idx !== -1) {
                            self.siteDefinition.units.splice(idx, 1);
                            unit.assigned = false;
                            unit.site = null;
                            self.onReceiptOfUnit(unit);
                            // self.updateUnit(unit._id, {assigned: false, site: null});
                        } else {
                            console.error('We have a removed unit that is not in our siteDefinition (to be removed) ', unit._id);
                        }
                    });
                }
            }
            // self.siteDefinition.units = [];
            self.selectedUnits.forEach((unit) => {
                // Now we push the selectedUnits into our siteDefinition
                const unitIdx = _.findIndex(self.siteDefinition.units, (o) => o._id === unit._id);
                const resetIdx = _.findIndex(self.resetUnits, (o) => o._id === unit._id);
                if (resetIdx !== -1) {
                    self.resetUnits.splice(resetIdx, 1);
                }
                if (unitIdx === -1) {
                    // We don't have this unit in our site yet
                    unit.assigned = true;
                    unit.site = self.siteDefinition._id;

                    const tempUnit = _.cloneDeep(unit);
                    if (self.siteDefinition.units) {
                        self.siteDefinition.units.push(tempUnit);
                    }
                    self.onReceiptOfUnit(unit);
                    // self.updateUnit(unit._id, {assigned: true, site: self.siteDefinition._id});
                } else {
                    // We already have this unit in our site. Don't need to push it again... I think?
                }
            });

            // remove availableCameras
            _.forEach(self.siteDefinition.units, (siteUnit) => {
                siteUnit.availableCameras = [];
            });

            self.onReceiptOfSiteClean(self.siteDefinition);
            // return self.saveSite(self.siteDefinition).then(site => {
            if (self.siteDefinition) {
                if (self.autoPoll) {
                    const promises = [];
                    self.pollingForCameras = true;
                    if (self.siteDefinition.units) {
                        self.siteDefinition.units.forEach((unit) => {
                            promises.push(self.pollCameras(unit._id));
                        });
                    }
                    Promise.all(promises)
                        .then(() => {
                            self.confirmedSelection = true;
                        })
                        .catch((e) => {
                            console.error(e);
                        });
                } else {
                    self.confirmedSelection = true;
                }
            }
            // })
            // .catch(e => {
            // console.error(e);
            // });
        } else {
            self.toastr.warning('Please select at least one unit', 'No units selected', {
                preventOpenDuplicates: true,
            });
        }
    }

    handleDragging($event, helper, data) {
        const self = this;

        if (data.type === 'zoneonly') {
            self.zoneOnlyDragStart(data.unit, data.camera);
        } else if (data.type === 'cameraswap') {
            self.cameraSwapDragStart(data.camera);
        } else if (data.type === 'cameramove') {
            self.cameraMoveDragStart(data.unit, data.camera);
        }
    }

    handleDropping($event, helper, data) {
        const self = this;

        if (data.type === 'zoneonly') {
            self.zoneOnlyDragDrop(data.unit, data.camera);
        } else if (data.type === 'cameraswap') {
            self.cameraSwapDragDrop(data.unit, data.camera);
        } else if (data.type === 'cameramove') {
            self.cameraMoveDragDrop(data.unit);
        }
    }

    // TODO: Just as handleDragging and handleDropping, this function should become
    // the generic function we call for handling drag images. I'm not using it YET because
    // with angular-dragdrop, when setting a helper (see the HTML), I'm not able to pass
    // variables as I am doing with the other jqyoui-draggable / jqyoui-droppable
    // NOTE that I am using a class 'id-me' in HTML. This will fall away once we are able
    // to send variables properly and make use of this function
    handleDragImage($event, data) {
        const self = this;
    }

    handleZoneOnlyDragImage($event, data) {
        const self = this;
        if ($event.target) {
            const elm = angular.element($event.currentTarget);
            const identifier = elm.find('.id-me');
            const zoneID = identifier[0].id;
            const zone = self.getRealZoneByID(zoneID);
            const html = `<span style="background-color: white; box-shadow: 0 0 1px 0 #64B5F6">
                <i class="fa fa-check-square-o id-me" style="color: #64B5F6;"></i>
                <span style="margin-left: 5px;">${zone.alias}</span>
            </span>
            `;
            return $(html);
        }
        return null;
    }

    handleCameraSwapDragImage($event) {
        const self = this;
        if ($event.target) {
            const elm = angular.element($event.currentTarget);
            const identifier = elm.find('.id-me');
            const cameraID = identifier[0].id;
            // let camera = self.getCameraByID(cameraID);

            const cam = document.getElementById(`list-box-item-${cameraID}`);
            const camCopy = angular.element(cam.cloneNode(true));
            camCopy.addClass('slide-to-the-left');

            const imageArea = camCopy.find('.list-box-item-image');
            const footer = camCopy.find('.list-box-item-footer');

            imageArea.addClass('my-disappear');
            footer.addClass('my-disappear');
            camCopy.addClass('shorter-animate');

            self.$timeout(() => {
                // Some notes on the animation. I'd prefer NOT to use $timeout, but I'm struggling as the DOM
                // is behaving quite strangely. If I use css to {display:none}, it's different from removing
                // the element like I'm doing below and the display ends up weird.
                imageArea.remove();
                footer.remove();
            }, 500);
            return camCopy;
        }
        return null;
    }

    handleCameraMoveDragImage($event) {
        const self = this;
        if ($event.target) {
            const elm = angular.element($event.currentTarget);
            const identifier = elm.find('.id-me');
            const cameraID = identifier[0].id;
            const camera = self.getCameraByID(cameraID);
            const zoneAlias = self.cameraCollection[cameraID].zone.alias;
            const html = `
            <span class="shrink-animate" style="background-color:white; box-shadow: 0 0 1px 0 #64B5F6">
                <table class="camera-table">
                    <tr>
                        <td>
                            ${camera.alias}
                        </td>
                        <td>
                            <i class="fa fa-check-square-o id-me" style="color:#64B5F6;"> </i>
                            ${zoneAlias}
                        </td>
                    </tr>
                </table>
            </span>
            `;
            return $(html);
        }
        return null;
    }

    handleHoverOver($event, helper, data) {
        const self = this;
        if ($event.target) {
            const expectedType = self.busyDropHere.type;
            if (data.type === expectedType) {
                // Making sure that we only add the class for truly valid dropzones
                const dropArea = angular.element($event.target);
                dropArea.addClass('valid-hover-point');
            }
        }
    }

    handleHoverOut($event, helper, data) {
        const self = this;
        if ($event.target) {
            const expectedType = self.busyDropHere.type;

            if (data.type === expectedType) {
                const dropArea = angular.element($event.target);
                dropArea.removeClass('valid-hover-point');
            }
        }
    }

    /**
     * When we want to drag a zone in order to assign it to a different camera
     * @param  {[type]} unit   [description]
     * @param  {[type]} camera [description]
     */
    zoneOnlyDragStart(unit, camera) {
        const self = this;

        // Firstly, determine and highlight valid dropareas
        const allUnits = self.siteDefinition.units;
        const validUnits = [];
        const validCameras = [];
        const relevantDropZones = [];

        _.forEach(allUnits, (siteUnit) => {
            // if (true) { // check if unit online
                validUnits.push(siteUnit);
            // }
        });

        _.forEach(validUnits, (validUnit) => {
            _.forEach(validUnit.availableCameras, (cam) => {
                // We exclude the current camera that the zone is being dragged from
                // if(cam._id !== camera._id) {
                // We make sure that camera doesn't have an active zone
                if (!cam.isAssignedToZone) {
                    // TODO: Not sure whether I should be checking assignedToUnit
                    const tempCam = _.cloneDeep(cam);
                    tempCam.unit = validUnit._id;
                    validCameras.push(tempCam);
                }
                // }
            });
        });

        _.forEach(validCameras, (validCam) => {
            const stringID = `zone-only-drop-option-${validCam.unit}-${validCam._id}`;
            relevantDropZones.push(document.getElementById(stringID));
        });

        angular.element(relevantDropZones)
            .addClass('drop-here');
        self.busyDropHere = {};
        self.busyDropHere.dropzones = relevantDropZones;
        self.busyDropHere.unit = unit;
        self.busyDropHere.camera = camera;
        self.busyDropHere.type = 'zoneonly';
    }

    /**
     * When we STOP dragging zone
     */
    dragStop() {
        const self = this;

        if (self.busyDropHere && self.busyDropHere.dropzones) {
            angular.element(self.busyDropHere.dropzones)
                .removeClass('drop-here');
        }
    }

    zoneOnlyDragDrop(toUnit, toCamera) {
        const self = this;
        if (self.busyDropHere.type === 'zoneonly') {
            const fromUnit = self.busyDropHere.unit;
            const fromCamera = self.busyDropHere.camera;
            delete self.busyDropHere;
            self.moveZone(fromCamera, toCamera, toUnit);
        }
    }

    moveCamera(camera, fromUnit, toUnit) {
        const self = this;
        const zone = self.unlinkZone(fromUnit, camera);
        self.linkZone(zone, toUnit, camera);
        self.onReceiptOfSiteClean(self.siteDefinition);
        // self.saveSite(self.siteDefinition).then((newSite) => {
        // })
        // .catch((e) => {
        // self.toastr.error(e.message || e,`Error saving site`,{
        // preventOpenDuplicates:true
        // });
        // });

        const html = `
            <table class="toastr-table">
            <col width="50%">
            <col width="50%">
                <tr>
                    <td>
                        Moved Camera:
                    </td>
                    <td>
                        ${camera._id}
                    </td>
                </tr>
                <tr>
                    <td>
                        From Unit:
                    </td>
                    <td>
                        ${fromUnit._id}
                    </td>
                </tr>
                <tr>
                    <td>
                        To Unit:
                    </td>
                    <td>
                        ${toUnit._id}
                    </td>
                </tr>
            </table>
        `;

        self.toastr.info(html, 'Operation Successful', {
            preventOpenDuplicates: true,
            allowHtml: true,
            timeOut: 10000,
            tapToDismiss: true,
            progressBar: true,
        });
    }

    /**
     * A helper function that moves a zone from one camera to another
     * @param  {[type]} fromCamera [description]
     * @param  {[type]} toCamera   [description]
     * @param  {[type]} toUnit     [description]
     */
    moveZone(fromCamera, toCamera, toUnit) {
        const self = this;
        const { zone } = self.cameraCollection[fromCamera._id];
        const fromUnit = self.cameraCollection[fromCamera._id].unit;
        self.unlinkZone(fromUnit, fromCamera);
        self.linkZone(zone, toUnit, toCamera);

        self.onReceiptOfSiteClean(self.siteDefinition);
        // self.saveSite(self.siteDefinition).then((newSite) => {
        // })
        // .catch((e) => {
        // self.toastr.error(e.message || e,`Error saving site`,{
        // preventOpenDuplicates:true
        // });
        // });

        const html = `
            <table class="toastr-table">
            <col width="50%">
            <col width="50%">
                <tr>
                    <td>
                        Moved Zone:
                    </td>
                    <td>
                        ${zone.alias}
                    </td>
                </tr>
                <tr>
                    <td>
                        From Camera:
                    </td>
                    <td>
                        ${fromCamera._id}
                    </td>
                </tr>
                <tr>
                    <td>
                        To Camera:
                    </td>
                    <td>
                        ${toCamera._id}
                    </td>
                </tr>
            </table>
        `;

        self.toastr.info(html, 'Operation Successful', {
            preventOpenDuplicates: true,
            allowHtml: true,
            timeOut: 10000,
            tapToDismiss: true,
            progressBar: true,
        });
    }

    unlinkZone(uUnit, camera) {
        const self = this;
        const unit = self.getRealUnitByID(uUnit._id);
        const camIdx = _.findIndex(unit.cameras, (cam) => cam._id === camera._id);
        // I'm splicing this camera from the specific unit's list of .cameras
        unit.cameras.splice(camIdx, 1);
        const zoneIdx = _.findIndex(self.siteDefinition.zones, (siteZone) => siteZone.camera === camera._id);
        if (zoneIdx !== -1) {
            // I'm keeping the defined zone, but just setting its unit and camera to being unbound
            self.siteDefinition.zones[zoneIdx].unit = 'UNBOUNDED';
            self.siteDefinition.zones[zoneIdx].camera = 'UNBOUNDED';
            return self.siteDefinition.zones[zoneIdx];
        }
        return null; // TODO: Give warning
    }

    /**
     * [linkZone description]
     * @param  {[type]} zZone [description]
     * @param  {[type]} uUnit [description]
     * @param  {[type]} camera       [description]
     * @return {[type]}              [description]
     */
    linkZone(zZone, uUnit, camera) {
        const self = this;
        const unit = self.getRealUnitByID(uUnit._id);
        const zone = self.getRealZoneByID(zZone._id);
        unit.cameras.push(camera);
        zone.unit = unit._id;
        zone.camera = camera._id;
        // camera.isAssignedToZone = true;
        // camera.isAssignedToUnit = true;
    }

    cameraSwapDragStart(camera) {
        const self = this;

        // Firstly, determine and highlight valid dropareas
        const validUnits = [];
        const validCameras = [];
        const relevantDropZones = [];

        // Get all the units
        _.forEach(camera.units, (myUnitID) => {
            validUnits.push(self.getRealUnitByID(myUnitID));
        });

        // For all the cameras that are assigned
        _.forEach(validUnits, (validUnit) => {
            _.forEach(validUnit.availableCameras, (cam) => {
                // We exclude the current camera that the zone is being dragged from
                if (cam._id !== camera._id) {
                    // We make sure that camera doesn't have an active zone
                    if (cam.isAssignedToUnit && cam.isAssignedToZone) {
                        // TODO: Not sure whether I should be checking assignedToUnit
                        const tempCam = _.cloneDeep(cam);
                        tempCam.unit = validUnit._id;
                        validCameras.push(tempCam);
                    }
                }
            });
        });

        _.forEach(validCameras, (validCam) => {
            const stringID = `camera-swap-drop-option-${validCam.unit}-${validCam._id}`;
            relevantDropZones.push(document.getElementById(stringID));
        });

        angular.element(relevantDropZones)
            .addClass('drop-here');
        self.busyDropHere = {};
        self.busyDropHere.dropzones = relevantDropZones;
        // self.busyDropHere.unit = unit;
        self.busyDropHere.camera = camera;
        self.busyDropHere.type = 'cameraswap';
        //
    }

    /**
     * [cameraSwapDragDrop description]
     * @param  {[type]} toUnit   [description]
     * @param  {[type]} fromCamera [description]
     * @return {[type]}        [description]
     */
    cameraSwapDragDrop(toUnit, fromCamera) {
        const self = this;
        if (self.busyDropHere.type === 'cameraswap') {
            const toCamera = self.busyDropHere.camera;
            delete self.busyDropHere;
            self.moveZone(fromCamera, toCamera, toUnit);
        }
    }

    cameraMoveDragStart(unit, camera) {
        const self = this;

        // Firstly, determine and highlight valid dropareas
        const allUnits = self.siteDefinition.units;
        const validUnits = [];
        // let validCameras = [];
        const relevantDropZones = [];

        // Get all the units that are associated with or can see this camera
        _.forEach(allUnits, (myUnit) => {
            const unitIdx = _.findIndex(myUnit.availableCameras, (cam) => cam._id === camera._id);

            if (unitIdx !== -1 && myUnit._id !== unit._id) {
                // validUnits.push(self.getRealUnitByID(myUnit._id));
                validUnits.push(myUnit);
            }
        });

        // We have all the units, now we want to make sure they are eligible to receive
        // THIS camera into their own camera-list
        _.forEach(validUnits, (validUnit) => {
            const index = _.findIndex(validUnit.availableCameras, (cam) => cam._id === camera._id);

            // TODO: I should ignore the moved cam's unit?
            if (index !== -1) {
                const stringID = `camera-move-drop-option-${validUnit._id}`;
                relevantDropZones.push(document.getElementById(stringID));
            }

            // _.forEach(validUnit.availableCameras, (cam) => {
            //     //We exclude the current camera that the zone is being dragged from
            //     if(cam._id !== camera._id) {
            //         //We make sure that camera doesn't have an active zone
            //         if(cam.isAssignedToUnit && cam.isAssignedToZone) {
            //             // TODO: Not sure whether I should be checking assignedToUnit
            //             validCameras.push(cam);
            //         }
            //     }
            // });
        });

        // _.forEach(validCameras, (validCam) => {
        //     let stringID = `camera-swap-drop-option-${validCam._id}`
        //     relevantDropZones.push(document.getElementById(stringID));
        // });

        angular.element(relevantDropZones)
            .addClass('drop-here');
        self.busyDropHere = {};
        self.busyDropHere.dropzones = relevantDropZones;
        self.busyDropHere.unit = unit;
        self.busyDropHere.camera = camera;
        self.busyDropHere.type = 'cameramove';
        //
    }

    cameraMoveDragDrop(toUnit) {
        const self = this;
        if (self.busyDropHere.type === 'cameramove') {
            const fromUnit = self.busyDropHere.unit;
            const { camera } = self.busyDropHere;
            delete self.busyDropHere;

            // self.moveZone(fromCamera, toCamera, toUnit);

            self.moveCamera(camera, fromUnit, toUnit);
        }
    }

    /**
     * Whenever we click to go back to unit selection
     * NOTE that we're not changing any values here except for handling
     * variable that toggles NG-HIDE/SHOW
     * @return {[type]} [description]
     */
    enableDisable() {
        const self = this;
        self.confirmedSelection = !self.confirmedSelection;
    }

    /**
     * Whenever a user selects a camera to have its units highlighted
     * @param  {[type]} camera [description]
     * @return {[type]}        [description]
     */
    onHighlightCamera(camera) {
        const self = this;

        if (self.highlightCamera._id === camera._id) {
            self.highlightCamera = {};
        } else if (self.highlightCamera._id) {
            self.highlightCamera = {};
            self.onHighlightCamera(camera);
        } else {
            self.highlightCamera = camera;
        }
    }

    /**
     * When we do not want to show a camera on the list of available cameras
     * @see self.ignoredCameras
     * @see self.isIgnored()
     */
    ignoreCamera(camera) {
        const self = this;
        self.ignoredCameras.push(camera);

        self.onReceiptOfSiteClean(self.siteDefinition);
        // self.saveSite(self.siteDefinition)
        //     .then(newSite => {
        //         self.siteDefinition = newSite;
        //     })
        //     .catch((e) => {
        //         console.error(e);
        //         self.toastr.error(e.message || e, `Error saving site`, {
        //             preventOpenDuplicates: true
        //         });
        //     });
    }

    undoIgnore(unit, camera) {
        const self = this;

        const idx = _.findIndex(self.ignoredCameras, { _id: camera._id });
        if (idx !== -1) {
            self.ignoredCameras.splice(idx, 1);
        }

        if (camera.ignored) {
            camera.ignored = false;
        }

        self.onReceiptOfSiteClean(self.siteDefinition);
        // self.saveSite(self.siteDefinition)
        //     .then((newSite) => {
        //
        //     })
        //     .catch((e) => {
        //         console.error(e);
        //         self.toastr.error(e.message || e, `Error saving site`, {
        //             preventOpenDuplicates: true
        //         });
        //     });
    }

    /**
     * When the user has found a desired camera and wants to allocate it to a specified zone
     * @param {[type]} camera [description]
     */
    addCamToZone(camera) {
        const self = this;
        // This is so that I can access the camera via scope from within select-camera-window.html
        self.busyCamera = camera;
        // This is so that I can see all the camera's units from within the select-camera-window.html
        self.busyUnits = [];

        _.forEach(camera.units, (unitID) => {
            const tempUnit = _.cloneDeep(self.getRealUnitByID(unitID));
            self.busyUnits.push(tempUnit);
        });
        if (self.busyUnits.length === 1) {
            [self.unitToBindTo] = self.busyUnits;
        }

        self.$ngConfirm(
            {
                title: `<span style="display:flex; justify-content:center; font-weight:bold;">${camera.alias}</span>`,
                theme: 'light',
                animation: 'top',
                scope: self.$scope,
                closeAnimation: 'bottom',
                content: require('./select-camera-window.html'),
                escapeKey: true,
                backgroundDismiss: true,
                buttons: {
                    // long hand button definition
                    ok: {
                        text: 'Ok',
                        btnClass: 'btn-primary',
                        keys: ['enter'], // will trigger when enter is pressed
                        action(scope) {
                            const zone = _.cloneDeep(self.zoneToBindTo[0]);
                            const unit = _.cloneDeep(self.unitToBindTo);

                            if (!self.zoneToBindTo || self.zoneToBindTo.length === 0) {
                                self.toastr.error('Please choose a valid zone', {
                                    preventOpenDuplicates: true,
                                });
                                return false;
                            }
                            if (!self.unitToBindTo || !self.unitToBindTo._id) {
                                self.toastr.error('Please choose a valid unit', {
                                    preventOpenDuplicates: true,
                                });
                                return false;
                            }
                            if (zone.camera !== 'UNBOUNDED' || zone.unit !== 'UNBOUNDED') {
                                // This is a predefined zone --> Checking that it's not already in use
                                self.toastr.warning(`This zone has already been assigned to another camera: ${zone.camera}`, {
                                    preventOpenDuplicates: true,
                                });
                                return false;
                            }
                            self.bindZone(zone, unit, camera);
                            self.unitToBindTo = {};
                            self.zoneToBindTo = [];
                            self.busyCamera = {};
                            return true;
                        },
                    },
                    close(scope) {

                    },
                },
            },
        );
    }

    /**
     * When we select (or create) a zone from ui-select in select-camera-window.html
     * @param  {[type]} $item  [description]
     * @param  {[type]} camera [description]
     */
    cameraZoneSelected($item, camera) {
        const self = this;
        // let label = ' (create new zone)';
        // let tempZones = [];
        // _.forEach(self.zoneToBindTo, (zone) => {
        //     if (zone.slice(-(label.length)) === label) {
        //         zone = zone.slice(0, -(label.length));
        //     }
        //     tempZones.push(zone);
        // });
        // self.zoneToBindTo = tempZones;
    }

    onZoneAdded($item, camera) {
        const self = this;
    }

    /**
     * UI-select calls for this function to create a new Tage.
     * @see select-camera-window
     */
    newZoneTag(newZoneTag) {
        return {
            alias: newZoneTag,
            unit: 'UNBOUNDED',
            camera: 'UNBOUNDED',
        };
    }

    bindZone(zone, unit, camera) {
        const self = this;

        // Creating or updating the zone in
        if (self.siteDefinition.zones) {
            let index;

            if (zone._id) {
                index = _.findIndex(self.siteDefinition.zones, (storedZone) => storedZone._id === zone._id);
            } else {
                index = _.findIndex(self.siteDefinition.zones, (storedZone) => storedZone.alias === zone.alias);
            }

            if (index === -1) {
                // This is a new zone, NOT predefined
                self.siteDefinition.zones.push({
                    alias: zone.alias,
                    camera: camera._id,
                    unit: unit._id,
                });
            } else if (self.siteDefinition.zones[index].camera !== 'UNBOUNDED' || self.siteDefinition.zones[index].unit !== 'UNBOUNDED') {
                // This is a predefined zone --> Checking that it's not already in use
                self.toastr.warning(`This zone has already been assigned to another camera: ${self.siteDefinition.zones[index].camera}`, {
                    preventOpenDuplicates: true,
                });
                return false;
            } else {
                // This is a predefined zone that is still unbounded
                self.siteDefinition.zones[index].camera = camera._id;
                self.siteDefinition.zones[index].unit = unit._id;
            }
        }

        const unitIdx = _.findIndex(self.siteDefinition.units, (storedUnit) => storedUnit._id === unit._id);

        if (unitIdx !== -1) {
            const camIdx = _.findIndex(self.siteDefinition.units[unitIdx].cameras, (o) => o._id === camera._id);

            if (camIdx === -1) {
                if (self.siteDefinition.units[unitIdx]) {
                    if (self.siteDefinition.units[unitIdx].cameras) {
                        self.siteDefinition.units[unitIdx].cameras.push(camera);
                    } else {
                        self.siteDefinition.units[unitIdx].cameras = [camera];
                    }
                }
            } else {
                console.error(`This camera (id: ${camera._id}) is already assigned to unit: ${unit._id}`);
            }
        } else {
            console.error('No unit found');
        }

        self.onReceiptOfSiteClean(self.siteDefinition);
        // self.saveSite(self.siteDefinition).then(newSite => {
        const html = `
            <table class="toastr-table">
            <col width="50%">
            <col width="50%">
                <tr>
                    <td>Zone Linked:</td>
                    <td>${zone.alias}</td>
                </tr>
                <tr>
                    <td>To Camera:</td>
                    <td>${camera._id}</td>
                </tr>
                <tr>
                    <td>To Unit:</td>
                    <td>${unit._id}</td>
                </tr>
            </table>
            `;
        self.toastr.info(html, 'Operation Successful', {
            preventOpenDuplicates: true,
            allowHtml: true,
            timeOut: 10000,
            tapToDismiss: true,
            progressBar: true,
        });
        // .catch((e) => {
        //     console.error(e);
        //     self.toastr.error(e.message || e,`Error saving site`,{
        //         preventOpenDuplicates:true
        //     });
        // });
        return true;
    }

    /**
     * When a user wishes to remove the link between a camera and zone
     * @param  {[type]} unit   [description]
     * @param  {[type]} camera [description]
     */
    undoZone(unit, camera) {
        const self = this;

        const unitIdx = _.findIndex(self.siteDefinition.units, (siteUnit) => siteUnit._id === unit._id);
        if (unitIdx !== -1) {
            const camIdx = _.findIndex(self.siteDefinition.units[unitIdx].cameras, (siteCam) => siteCam._id === camera._id);
            if (camIdx !== -1) {
                // I'm splicing this camera from the specific unit's list of .cameras
                self.siteDefinition.units[unitIdx].cameras.splice(camIdx, 1);

                const zoneIdx = _.findIndex(self.siteDefinition.zones, (siteZone) => siteZone.camera === camera._id);
                if (zoneIdx !== -1) {
                    const zoneAlias = self.siteDefinition.zones[zoneIdx].alias;
                    const cameraID = self.zoneCollection[zoneAlias].camera._id;
                    const unitID = self.zoneCollection[zoneAlias].unit._id;

                    // I'm keeping the defined zone, but just setting its unit and camera to being unbound
                    self.siteDefinition.zones[zoneIdx].unit = 'UNBOUNDED';
                    self.siteDefinition.zones[zoneIdx].camera = 'UNBOUNDED';

                    // Saving the site
                    self.onReceiptOfSiteClean(self.siteDefinition);

                    const html = `
                        <table class="toastr-table">
                        <col width="50%">
                        <col width="50%">
                            <tr>
                                <td>Zone Unlinked:</td>
                                <td>${self.siteDefinition.zones[zoneIdx].alias}</td>
                            </tr>
                            <tr>
                                <td>From Camera:</td>
                                <td>${cameraID}</td>
                            </tr>
                            <tr>
                                <td>From Unit:</td>
                                <td>${unitID}</td>
                            </tr>
                        </table>
                        `;

                    self.toastr.info(html, 'Operation Successful', {
                        preventOpenDuplicates: true,
                        allowHtml: true,
                        timeOut: 10000,
                        tapToDismiss: true,
                        progressBar: true,
                    });
                }
            }
        }
    }

    pollCameras(unitId) {
        const self = this;
        self.pollingForCamerasPerUnit[unitId] = true;
        if (self.siteDefinition.useDefaultAuthentication) {
            return self.$http.post(`/api/units/${unitId}/pollCameras`, {
                username: self.siteDefinition.defaultUsername,
                password: self.siteDefinition.defaultPassword,
            });
        }
        return self.$http.post(`/api/units/${unitId}/pollCameras`);
    }

    requestReferenceShot(unitId, camId) {
        const self = this;
        if (self.siteDefinition.useDefaultAuthentication === true) {
            return self.$http.post(`/api/units/${unitId}/requestReferenceShot`, {
                camera: camId,
                username: self.siteDefinition.defaultUsername,
                password: self.siteDefinition.defaultPassword,
            });
        }
        return self.$http.post(`/api/units/${unitId}/requestReferenceShot`, { camera: camId });
    }

    onRequestReferenceShot(unitId, camera, doNotAsk) {
        const self = this;
        const camId = camera._id;
        const cam = camera.camera;

        if (!camera.authenticationNeeded) {
            return self.$http.post(`/api/units/${unitId}/requestReferenceShot`, { camera: cam });
        }

        if (!camera.authenticationFailed) {
            // authentication did NOT fail, i.e. success!
            if (camera.username && camera.password) {
                return self.$http.post(`/api/units/${unitId}/requestReferenceShot`, {
                    camera: cam,
                    username: camera.username,
                    password: camera.password,
                });
            }
            if (self.siteDefinition.useDefaultAuthentication) {
                // This means that camera does not have a username and password, default must be right(because it has authenticationNeeded)
                return self.$http.post(`/api/units/${unitId}/requestReferenceShot`, {
                    camera: cam,
                    username: self.siteDefinition.defaultUsername,
                    password: self.siteDefinition.defaultPassword,
                });
            }
            return self.$http.post(`/api/units/${unitId}/requestReferenceShot`, { camera: cam });
        }

        if (!doNotAsk) {
            // Auth failed, this means whatever passwords we've working with, it's wrong.
            // We should ask for new auth and delete old values if stored
            return self.$ngConfirm(
                {
                    title: `<span>Camera Login Details</span><br><h3>${camera.alias}</h3>`,
                    theme: 'light',
                    animation: 'top',
                    // scope: ,
                    closeAnimation: 'bottom',
                    content: require('./gather-camera-credentials.html'),
                    escapeKey: true,
                    backgroundDismiss: true,
                    buttons: {
                        // long hand button definition
                        ok: {
                            text: 'Ok',
                            btnClass: 'btn-primary',
                            keys: ['enter'], // will trigger when enter is pressed
                            action(scope) {
                                const selfScope = scope.$ctrl;
                                if (selfScope.username === '' || selfScope.password === '') {
                                    return false;
                                }
                                const credentials = {
                                    username: selfScope.username,
                                    password: selfScope.password,
                                };
                                self.specifiedUserPass[camera._id] = credentials;
                                // camera.username = credentials.username;
                                // camera.password = credentials.password;
                                const camInd = _.findIndex(self.availableCameras, (o) => o._id === camera._id);
                                if (camInd !== -1) {
                                    self.availableCameras[camInd].username = credentials.username;
                                    self.availableCameras[camInd].password = credentials.password;
                                }

                                const uInd = _.findIndex(self.siteDefinition.units, (o) => o._id === unitId);
                                if (uInd !== -1) {
                                    const avCamInd = _.findIndex(self.siteDefinition.units[uInd].availableCameras, (o) => o._id === camera._id);
                                    if (avCamInd !== -1) {
                                        self.siteDefinition.units[uInd].availableCameras[avCamInd].username = credentials.username;
                                        self.siteDefinition.units[uInd].availableCameras[avCamInd].password = credentials.password;
                                    }

                                    const uCamId = _.findIndex(self.siteDefinition.units[uInd].cameras, (o) => o._id === camera._id);
                                    if (uCamId !== -1) {
                                        self.siteDefinition.units[uInd].cameras[uCamId].username = credentials.username;
                                        self.siteDefinition.units[uInd].cameras[uCamId].password = credentials.password;
                                    }
                                }

                                return self.$http.post(`/api/units/${unitId}/requestReferenceShot`, {
                                    camera: cam,
                                    username: credentials.username,
                                    password: credentials.password,
                                });
                            },
                        },
                        close(scope) {

                        },
                    },
                },
            );
        }
    }

    confirmCameraUserPass() {
        const self = this;
        const credentials = {};
        const confirmDialog = self.getCredentials();
        credentials.username = confirmDialog.scope.$ctrl.username;
        credentials.password = confirmDialog.scope.$ctrl.password;
        return credentials;
    }

    getCredentials() {
        const self = this;
    }

    /**
     * @see self.cameraCollection
     * We keep our collection of cameras updated so that we know if it's assigned to
     * a unit
     * a zone
     * and what any of their values/aliases, etc. might be
     * @return {[type]} [description]
     */
    updateCameraCollection(site) {
        const self = this;
        self.cameraCollection = {};
        self.cameraswap2 = {};

        // availablecameras are those who aren't assigned to a unit or zone
        _.forEach(self.availableCameras, (cam) => {
            self.cameraCollection[cam._id] = {};
            self.cameraCollection[cam._id].zone = 'UNBOUNDED';
            self.cameraCollection[cam._id].unit = 'UNBOUNDED';
            self.cameraswap2[cam._id] = {};
            self.cameraswap2[cam._id].camera = cam;
            self.cameraswap2[cam._id].type = 'cameraswap';
        });

        _.forEach(site.zones, (zone) => {
            if (zone.camera !== 'UNBOUNDED') {
                self.cameraCollection[zone.camera] = {};
                self.cameraCollection[zone.camera].zone = zone;
                self.cameraCollection[zone.camera].unit = _.find(site.units, (o) => o._id === zone.unit);
            }
        });
    }

    updateZoneCollection(site) {
        const self = this;
        if (site && site.zones) {
            const result = {};
            _.forEach(site.zones, (zone) => {
                result[zone.alias] = {};
                if (zone.unit !== 'UNBOUNDED') {
                    result[zone.alias].unit = _.find(site.units, { _id: zone.unit });
                    result[zone.alias].camera = _.find(result[zone.alias].unit.cameras, { _id: zone.camera });
                }
            });
            self.zoneCollection = result;
        }
    }

    /** ******************************PERTAINING TO STEP 3********************************* */

    /**
     * Calling this function whenever we go to the final step to retrieve some data for us.
     * I'm doing this so that we don't have to reference a function from HTML.
     */
    readyReview() {
        const self = this;
        self.zoneCollection = {};

        if (self.siteDefinition && self.siteDefinition.zones) {
            const result = {};
            _.forEach(self.siteDefinition.zones, (zone) => {
                result[zone.alias] = {};
                if (zone.unit !== 'UNBOUNDED') {
                    result[zone.alias].unit = _.find(self.siteDefinition.units, { _id: zone.unit });
                    result[zone.alias].camera = _.find(result[zone.alias].unit.cameras, { _id: zone.camera });
                }
            });
            self.zoneCollection = result;
        }
    }

    newUrl() {
        const self = this;
        self.manualUrl.push('');
    }

    removeUrl(index) {
        const self = this;
        self.manualUrl.splice(index, 1);
    }

    manualPoll() {
        const self = this;
        self.pollingForCameras = true;
        self.siteDefinition.units.forEach((unit) => {
            self.pollingForCamerasPerUnit[unit._id] = true;
            if (self.manualMac) {
                return self.$http.post(`/api/units/${unit._id}/manualPoll`, {
                    manualUrl: self.manualUrl,
                    mac: self.manualMac,
                })
                    .then(() => {
                        self.manualUrl = [''];
                        self.manualMacEnabled = false;
                        self.manualMac = '';
                    });
            }
            return self.$http.post(`/api/units/${unit._id}/manualPoll`, { manualUrl: self.manualUrl })
                .then(() => {
                    self.manualUrl = [''];
                    self.manualMacEnabled = false;
                    self.manualMac = '';
                });
        });
    }

    parseUrl(alias, url) {
        if (alias && url) {
            const urlLength = url.split('/').length - 1;
            if (urlLength > 3) {
                return `${alias} ${url.split('/')[2]}`;
            }
            return `${alias} ${url}`;
        }
        if (alias) {
            return alias;
        }
        return '';
    }

    /**
     * Currently calling this form the final step when
     * we wish to remove an unbounded zone
     */
    removeZone(zone) {
        const self = this;
        const index = _.findIndex(self.siteDefinition.zones, { _id: zone._id });
        if (index !== -1) {
            // self.siteDefinition.zones.splice(index, 1);
            self.siteDefinition.zones[index].disabled = true;
        }
    }

    // TODO: Check this function, when to save, when to initialize, etc.
    dismissModal() {
        const self = this;

        if (self.isEdit && self.siteDefinition.initialized) {
            // This is an edit, so we patch updates
            self.$ngConfirm(
                {
                    title: '<span style="text-align:center; display:flex; justify-content:center;">Uncompleted Site</span>',
                    theme: 'light',
                    animation: 'top',
                    scope: self.$scope,
                    closeAnimation: 'bottom',
                    escapeKey: true,
                    backgroundDismiss: true,
                    content: 'By exiting, all changes to the site will be discarded',
                    buttons: {
                        close: {
                            text: 'Discard Changes',
                            btnClass: 'btn-danger',
                            action(scope) {
                                const scopeSelf = scope.$ctrl;
                                scopeSelf.origSite.units.forEach((unit) => {
                                    scopeSelf.updateUnit(unit._id, {
                                        assigned: unit.assigned,
                                        site: unit.site,
                                    })
                                        .then(() => {

                                        });
                                });
                                scopeSelf.modalInstance.close(scopeSelf.origSite);
                            },
                        },
                    },
                },
            );
        } else {
            // This site has not been created yet
            self.$ngConfirm(
                {
                    title: '<span style="text-align:center; display:flex; justify-content:center;">Uncompleted Site</span>',
                    theme: 'light',
                    animation: 'top',
                    scope: self.$scope,
                    closeAnimation: 'bottom',
                    escapeKey: true,
                    backgroundDismiss: true,
                    content: 'By exiting, the site will be deleted, are you sure?',
                    buttons: {
                        close: {
                            text: 'Delete',
                            btnClass: 'btn-danger',
                            action(scope) {
                                const selfScope = scope.$ctrl;
                                if (selfScope.siteDefinition._id) {
                                    selfScope.removeSite(selfScope.siteDefinition)
                                        .then((site) => {
                                            if (site) {
                                                selfScope.modalInstance.dismiss('Modal has been closed by the user');
                                            } else {
                                                selfScope.toastr.error('Cannot remove site', {
                                                    preventOpenDuplicates: true,
                                                });
                                            }
                                        })
                                        .catch((e) => {
                                            selfScope.toastr.error(e.message || e, 'Cannot remove site', {
                                                preventOpenDuplicates: true,
                                            });
                                        });
                                } else {
                                    selfScope.modalInstance.dismiss('Modal has been closed by the user');
                                }
                            },

                        },
                    },
                },
            );
        }
    }

    closeModal() {
        const self = this;

        if (self.siteDefinition.units) {
            self.siteDefinition.units.forEach((unit) => {
                unit.configVersion = unit.configVersion + 1 || 1;
            });
        }

        if (!self.isEdit) {
            // This is a new site
            const result = self.siteDefinition;
            result.initialized = true;
            self.finalSaveSite(result)
                .then((savedSite) => {
                    self.toastr.info('Site Saved Successfully', 'Operation Successful', {
                        preventOpenDuplicates: true,
                        allowHtml: true,
                        timeOut: 2000,
                        tapToDismiss: true,
                        progressBar: true,
                    });
                    self.modalInstance.close(savedSite);
                });
        } else {
            // This is an edit
            self.siteDefinition.initialized = true;
            self.finalSaveSite(self.siteDefinition)
                .then((savedSite) => {
                    self.toastr.info('Site Saved Successfully', 'Operation Successful', {
                        preventOpenDuplicates: true,
                        allowHtml: true,
                        timeOut: 2000,
                        tapToDismiss: true,
                        progressBar: true,
                    });
                    self.modalInstance.close(savedSite);
                });
        }
    }

    updateUnit(unitId, updates) {
        return this.$http.patch(`/api/units/${unitId}/updateAssigned`, updates)
            .then((response) => {
                if (response && response.data) {
                    return response.data;
                }
                return 'No Unit returned';
            });
    }

    doLog() {
        const self = this;
        console.log('DEBUG: ', self);
    }
}

export default angular.module('cameraViewerApp.sites')
    .component('newSite', {
        template: require('./new-site.html'),
        controller: NewSiteComponent,
        controllerAs: '$ctrl',
        bindings: {
            resolve: '<',
            modalInstance: '<',
        },
    }).name;

require('./minimap-modal/minimap-modal.component');

// Configure new siteBietjie whitespace
// CNew field by contacts.
// Chuck Die ID veld
// Event Notifications is nie 'n actual veld in die struktuur nie, dit
// is 'n opgemaakte veld van die frontend. As een van die dieper levels ticked is
// Sit almal af
// S
// Enabled /Configured alignment
// E
// Camera tabs na Zones
// Display nie meer zone.Id
//
// UNVIF URI en RTSP URI wegval
// Location --> bevestig datdit geojason is
//
// On-Site Storage is tans in minutes. Miskien 'n makliker display kry.' Low priority
//
// Daar gaan 'n ' token en name wees by Cloud Storage.
// Mens gaan 'n profile' moet kies, ons gaan basies 2 dropdowns soek
//
// ENCODING
// Ook 'n tipe van n tabs iets'. Profiles array gaan hier repeat.
// Token en Name gaan readonly word.
// -> By cloud-storage is daar 'n TOKEN ' wat dan die primarykey gaan wees van
// welke ook al profile by ENCODING
//
//
// Analytics
// -> 'n Text' field grey of italics om te wys watter een by CLOUD STORAGE gekies is.
// Kan dit nie van heir af verander nie
//
//
//
// Kyk miskien n bietie na die camera-model. Op server/api/camera.model oorcopy na die site se camera.
// Kyk daar bietjie jhoe die profile se struktuur lyk.
