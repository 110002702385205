'use strict';

export default class AsideController {
    $uibModalInstance;
    $scope;
    $http;
    sites;
    startDate;
    startTime;
    reasons;
    availReasons = [
        "Motion",
        "SnapshotRequest",
        "ReferenceRequest",
        "AHD",
        "LineTrip",
        "Unknown",
        "AccessControl",
        "PeerCameraMotion",
        "PeerCameraLine"
    ];
    endDate;
    endTime;
    Auth;
    lastFilter;
    onlyFaces = false;
    onlyPeople = false;
    onlyPoses = false;
    onlyObjects = false;
    onlyVehicles = false;
    onlyXFS = false;
    onlyPlates = false;
    onlyRecog = false;
    indexLabels = [];
    age;
    emotionFilter;
    currentSite;
    socket;
    availableZones = [];
    filterOpen = false;
    configOpen = false;
    configLSOpen = false;
    camerasOpen = false;
    shellScript = "";
    shellResponse = {};

    isLoggedIn: Function;
    isAdmin: Function;
    isSuperAdmin: Function;
    getCurrentUser: Function;

    quickPickFromOptions = [
        "Last 10 minutes",
        "Last 30 minutes",
        "Last hour",
        "Last 6 hours",
        "Last 24 hours",
        "Last 7 days",
        "Last 30 days"
    ];

    /*@ngInject*/
    constructor($scope, $uibModalInstance, $http, lastFilter, currentSite, socket, Auth, $document, Modal, $uibModal, toastr, moment, $timeout) {
        this.toastr = toastr;
        this.deleteModal = Modal.confirm.delete;
        this.$uibModalInstance = $uibModalInstance;
        this.$scope = $scope;
        this.$document = $document;
        this.socket = socket;
        this.$timeout = $timeout;
        this.Auth = Auth;
        this.emotionOptions = [
            {type: "happy", label: "Happy"},
            {type: "sad", label: "Sad"},
            {type: "angry", label: "Angry"},
            {type: "confused", label: "Confused"},
            {type: "disgusted", label: "Disgusted"},
            {type: "surprised", label: "Surprised"},
            {type: "calm", label: "Calm"},
            {type: "unknown", label: "Unknown"},
            {type: "fear", label: "Fear"},
        ];
        this.$http = $http;
        this.moment = moment;
        this.$uibModal = $uibModal;

        if (lastFilter) {
            this.sites = lastFilter.sites;
            this.currentSite = currentSite;
            this.startDate = lastFilter.startDate;
            this.endDate = lastFilter.endDate;
            this.startTime = lastFilter.startTime;
            this.reasons = lastFilter.reasons;
            this.endTime = lastFilter.endTime;
            this.onlyFaces = lastFilter.onlyFaces;
            this.onlyVehicles = lastFilter.onlyVehicles;
            this.onlyXFS = lastFilter.onlyXFS;
            this.onlyPeople = lastFilter.onlyPeople;
            this.onlyPoses = lastFilter.onlyPoses;
            this.onlyObjects = lastFilter.onlyObjects;
            this.onlyPlates = lastFilter.onlyPlates;
            this.onlyRecog = lastFilter.onlyRecog;

            this.gender = lastFilter.gender;
            this.beard = lastFilter.beard;
            this.mouthOpen = lastFilter.mouthOpen;
            this.mustache = lastFilter.mustache;
            this.eyesOpen = lastFilter.eyesOpen;
            this.age = lastFilter.age;
            if (lastFilter.age) {
                this.ageSearch = true;
            }
            this.emotionFilter = lastFilter.emotionFilter;
            this.sunglasses = lastFilter.sunglasses;
            this.eyeglasses = lastFilter.eyeglasses;
            this.smile = lastFilter.smile;
        }

        this.isLoggedIn = Auth.isLoggedInSync;
        this.isAdmin = Auth.isAdminSync;
        this.isSuperAdmin = Auth.isSuperAdminSync;
        this.getCurrentUser = Auth.getCurrentUserSync;
    }

    doLog() {
        console.log("DEBUG:", this);
    }

    $onInit() {
        const self = this;

        this.applyFilter = function (e) {
            self.$uibModalInstance.close({
                sites: self.sites,
                startDate: self.startDate,
                startTime: self.startTime,
                endDate: self.endDate,
                reasons: self.reasons,
                endTime: self.endTime,
                onlyRecog: self.onlyRecog,
                onlyPlates: self.onlyPlates,
                onlyFaces: self.onlyFaces,
                onlyPeople: self.onlyPeople,
                onlyPoses: self.onlyPoses,
                onlyObjects: self.onlyObjects,
                onlyVehicles: self.onlyVehicles,
                onlyXFS: self.onlyXFS,

                gender:self.gender,
                beard:self.beard,
                mustache:self.mustache,
                smile:self.smile,
                eyeglasses:self.eyeglasses,
                sunglasses:self.sunglasses,
                mouthOpen:self.mouthOpen,
                emotionFilter:self.emotionFilter,
                eyesOpen:self.eyesOpen,
                age:self.ageSearch ? self.age : undefined
            });
        };

        this.clearFilter = function (e) {
            self.$uibModalInstance.close({
                sites: [],
                startDate: undefined,
                startTime: undefined,
                endTime: undefined,
                endDate: undefined,
                reasons: undefined,
                onlyRecog: undefined,
                onlyPlates: undefined,
                onlyFaces: undefined,
                onlyVehicles: undefined,
                onlyXFS: undefined,
                onlyPeople: undefined,
                onlyPoses: undefined,
                onlyObjects: undefined,

                gender: undefined,
                beard: undefined,
                mustache: undefined,
                smile: undefined,
                eyeglasses: undefined,
                sunglasses: undefined,
                mouthOpen: undefined,
                emotionFilter: undefined,
                eyesOpen: undefined,
                age: undefined
            });
        };

        this.ok = function(e) {
            this.$uibModalInstance.dismiss();
        };

        this.$http
            .get('/api/cameras', { params: {
                site: this.currentSite._id,
                showUnbounded: true
            }})
            .then(
                (response) => {
                    self.$timeout(() => {
                        self.availableZones = response.data;
                    }, 0);
                },
                (err) => {
                    if (err.status !== 401 && err.status !== 403) console.error(err);
                }
            );
    }

    addAll() {
        this.sites = this.availableZones;
    }

    clearAll() {
        this.sites = [];
    }

    quickPickFrom(index, option) {
        let self = this;
        let to = this.moment();
        let from = this.moment();
        self.endDate = to.toDate();
        self.endTime = to.toDate();
        switch (option) {
            case "Last 10 minutes":
                self.startTime = from.subtract(10, "minutes").toDate();
                break;
            case "Last 30 minutes":
                self.startTime = from.subtract(30, "minutes").toDate();
                break;
            case "Last hour":
                self.startTime = from.subtract(1, "hours").toDate();
                break;
            case "Last 6 hours":
                self.startTime = from.subtract(6, "hours").toDate();
                break;
            case "Last 24 hours":
                self.startTime = from.subtract(1, "days").toDate();
                break;
            case "Last 7 days":
                self.startTime = from.subtract(7, "days").toDate();
                break;
            case "Last 30 days":
                self.startTime = from.subtract(30, "days").toDate();
                break;
            default:
        }
        self.startDate = self.startTime;
    }
}
