export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.streamUsage', {
            url: '/streamUsage',
            views: {
                'content@main': {
                    template: '<stream-usage></stream-usage>',
                },
            },
            authenticate: 'StreamUsage',
            data: {
                title: 'Stream Usage',
            },
        });
}
