import _ from 'lodash-es';
import angular from 'angular';

global.Module = {
    locateFile: (path) => {
        const url = `/assets/wasm/${path}`;
        console.log(`⬇️Downloading wasm from ${url}`);
        return url;
    },
};

const cv = require('../../../opencv').default;

const NoMotion = 0;
const InitialDelay = 1;
const BurstDelay = 2;
const BurstCooloff = 3;
const MotionCooloff = 4;

class Rectangle {
    constructor() {
        this.topleft = new cv.Point();
        this.bottomright = new cv.Point();
    }
}

class OutputData {
    constructor() {
        this.threshold = null;
        this.erode = null;
        this.dilate = null;
        this.contours = null;
        this.rectangles = null;
        this.settings = null;
        this.time = null;
        this.frame = null;
        this.lastframe = null;

        this.countdown = null;
        this.burstCount = null;
        this.motionState = null;
    }
}

export default class SimulatorController {
    $uibModalInstance;
    $scope;
    selectedZone;
    originalZone;
    motionAlgorithms;
    Auth;
    $timeout;

    // Simulator variables
    bursts = [];
    index = 0;
    video;
    output1;
    output2;
    output3;
    output4;
    output5;
    outputBursts;
    frame;
    frameMasked;
    fgbg;
    videoCap;
    outputburstsctx;
    output1ctx;
    output2ctx;
    output3ctx;
    output4ctx;
    output5ctx;
    previousFrame;
    motiondetections = [];
    lastTime = 0;
    m_MotionCountdown = 0;
    m_MotionState = NoMotion;
    timerangeObject = null;
    timerangeStart = 0;
    timerangeEnd = 0;
    timer = null;
    CVBackgroundSubtractor = null;
    processedFrames = 0;

    /* @ngInject */
    constructor($uibModalInstance, $ngConfirm, selectedZone, toastr, motionAlgorithms, Auth, $timeout) {
        this.$uibModalInstance = $uibModalInstance;
        this.$ngConfirm = $ngConfirm;
        this.toastr = toastr;
        this.originalZone = selectedZone;
        this.selectedZone = _.cloneDeep(this.originalZone);
        // this.motionAlgorithms = motionAlgorithms;
        this.motionAlgorithms = [
            'OpenCVCustom',
            'OpenCVMOG2',
        ];
        this.Auth = Auth;
        this.$timeout = $timeout;
    }

    $onInit() {
        this.self = this;

        setTimeout(() => {
            this.video = document.getElementById('mainVideo');
            const onloadeddata = angular.bind(this, this.newVideoLoaded, 'onloadeddata');
            this.gotFrame = angular.bind(this, this.updateCanvas, 'onloadeddata');
            this.video.addEventListener('loadeddata', onloadeddata, false);
        }, 500);
    }

    $onDestroy() {
        this.video.width = this.video.videoWidth;
        this.video.height = this.video.videoHeight;

        this.cleanupOpenCVVars();
        this.setCanvasSizes();
        this.cleanupFrames();
        this.cleanupBursts();
    }

    fileChosen(e) {
        this.video = document.getElementById('mainVideo');
        const file = e.target.files[0];
        const { type } = file;
        let canPlay = this.video.canPlayType(type);
        if (canPlay === '') canPlay = 'no';
        // let message = 'Can play type "' + type + '": ' + canPlay;
        const isError = canPlay === 'no';
        // displayMessage(message, isError);

        if (isError) {
            // console.log("fc8");
            return;
        }

        this.video.src = URL.createObjectURL(file);
    }

    setCanvasSizes() {
        const scale = this.selectedZone.camera.configuration.scaleDown;
        this.scaleWidth = this.video.width * scale;
        this.scaleHeight = this.video.height * scale;

        /*      Canvas size allocation      */
        this.output1.width = this.scaleWidth;
        this.output1.height = this.scaleHeight;
        this.output2.width = this.scaleWidth;
        this.output2.height = this.scaleHeight;
        this.output3.width = this.scaleWidth;
        this.output3.height = this.scaleHeight;
        this.output4.width = this.scaleWidth;
        this.output4.height = this.scaleHeight;
        this.output5.width = this.scaleWidth;
        this.output5.height = this.scaleHeight;
        this.outputBursts.width = this.video.width;
        this.outputBursts.height = this.video.height;
    }

    cleanupBursts() {
        for (let i = 0; i < this.bursts.length; i++) {
            this.bursts[i].frame.delete();
            this.bursts[i].lastframe.delete();
            this.bursts[i].threshold.delete();
            this.bursts[i].erode.delete();
            this.bursts[i].dilate.delete();
            this.bursts[i].contours.delete();
            this.bursts[i].rectangles.delete();
        }
        this.bursts = [];
        this.index = 0;
    }

    cleanupFrames() {
        this.processedFrames = 0;
        if (this.frame !== null) {
            this.frame.delete();
        }
        if (this.frameMasked !== null) {
            this.frameMasked.delete();
        }
        // if (this.previousFrame !== null) {
        //     this.previousFrame.delete();
        // }
    }

    cleanupOpenCVVars() {
        // OpenCV variable declarations
        this.lastTime = 0;
        this.videoCap = null;
        this.frame = null;
        this.frameMasked = null;
        this.fgbg = null;
        this.previousFrame = null;
    }

    setupContexts() {
        // Maybe keep outputs in an array, use iteration to setup?
        this.output1 = document.querySelector('#output1');
        this.output1ctx = this.output1.getContext('2d', { willReadFrequently: true });
        this.output2 = document.querySelector('#output2');
        this.output2ctx = this.output2.getContext('2d', { willReadFrequently: true });
        this.output3 = document.querySelector('#output3');
        this.output3ctx = this.output3.getContext('2d', { willReadFrequently: true });
        this.output4 = document.querySelector('#output4');
        this.output4ctx = this.output4.getContext('2d', { willReadFrequently: true });
        this.output5 = document.querySelector('#output5');
        this.output5ctx = this.output5.getContext('2d', { willReadFrequently: true });
        this.outputBursts = document.querySelector('#outputbursts');
        this.outputburstsctx = this.outputBursts.getContext('2d', { willReadFrequently: true });
    }

    clearCanvases() {
        this.outputburstsctx.clearRect(0, 0, this.video.width, this.video.height);
        this.output1ctx.clearRect(0, 0, this.video.width, this.video.height);
        this.output2ctx.clearRect(0, 0, this.video.width, this.video.height);
        this.output3ctx.clearRect(0, 0, this.video.width, this.video.height);
        this.output4ctx.clearRect(0, 0, this.video.width, this.video.height);
        this.output5ctx.clearRect(0, 0, this.video.width, this.video.height);
    }

    newVideoLoaded() {
        this.video = document.getElementById('mainVideo');
        this.cleanupOpenCVVars();
        this.setupContexts();
        this.clearCanvases();
        this.video.width = this.video.videoWidth;
        this.video.height = this.video.videoHeight;
        // this.video.playbackRate = 0.5;

        this.timerangeObject = this.video.seekable;
        this.timerangeStart = this.timerangeObject.start(0);
        this.timerangeEnd = this.timerangeObject.end(0);
        this.videoCap = new cv.VideoCapture(this.video);

        this.setCanvasSizes();
        this.cleanupFrames();
        this.cleanupBursts();

        this.frame = new cv.Mat(this.video.height, this.video.width, cv.CV_8UC4);
        this.frameMasked = new cv.Mat(this.video.height, this.video.width, cv.CV_8UC1);
        this.video.requestVideoFrameCallback(this.gotFrame);
    }

    fakeClick() {
        const self = this;
        self.$timeout(() => {
            document.getElementById('simVideoFile').onchange = function (event) {
                // console.log(event.target.files);
                self.fileChosen(event);
            };
            const file = document.getElementById('simVideoFile');
            file.click();
        }, 0);
    }

    ok(event) {
        this.video.pause();
        this.$uibModalInstance.close({ result: this.selectedZone });
    }

    apply(event) {
        const self = this;
        self.video.pause();
        if (!_.isEqual(self.originalZone, self.selectedZone)) {
            self.$ngConfirm(
                {
                    title: '<span style="display:flex; justify-content:center;">Confirm new settings</span>',
                    theme: 'light',
                    animation: 'top',
                    scope: self.$scope,
                    closeAnimation: 'bottom',
                    escapeKey: false,
                    columnClass: 'col-xs-6 col-xs-offset-3',
                    backgroundDismiss: true,
                    buttons: {
                        yes: {
                            btnClass: 'btn-primary',
                            action(scope) {
                                _.mergeWith(self.originalZone, self.selectedZone);
                                self.toastr.success('Movement settings applied successfully', {
                                    preventOpenDuplicates: true,
                                }, (err) => {
                                    console.error(err);
                                });
                            },
                        },
                        no: {
                            btnClass: 'btn-primary',
                            action(scope) {

                            },
                        },
                    },
                },
            );
        } else {
            self.toastr.info('Movement settings unchanged', {
                preventOpenDuplicates: true,
            }, (err) => {
                console.error(err);
            });
        }
    }

    right() {
        if (this.index < this.bursts.length - 1) {
            this.index++;
            cv.imshow('outputbursts', this.bursts[this.index].frame);
        }
    }

    left() {
        if (this.index > 0) {
            this.index--;
            cv.imshow('outputbursts', this.bursts[this.index].frame);
        }
    }
    // ...
    doLog() {
        console.log('DEBUG: ', this);
    }

    changeMotionState(motion, output) {
        for (let depth = 0; depth < 10; ++depth) {
            const countdown = this.video.currentTime - this.m_MotionCountdown;
            const { maxBursts } = this.selectedZone.camera.configuration.analyticsConfiguration.motionConfiguration;
            const { minBursts } = this.selectedZone.camera.configuration.analyticsConfiguration.motionConfiguration;
            const { burstDelay } = this.selectedZone.camera.configuration.analyticsConfiguration.motionConfiguration;
            const { initialDelay } = this.selectedZone.camera.configuration.analyticsConfiguration.motionConfiguration;
            const { burstCooloff } = this.selectedZone.camera.configuration.analyticsConfiguration.motionConfiguration;
            const { motionCooloff } = this.selectedZone.camera.configuration.analyticsConfiguration.motionConfiguration;

            output.countdown = countdown;
            switch (this.m_MotionState) {
            case NoMotion: {
                if (motion === true) {
                    // Start InitialDelay countdown
                    this.m_MotionCountdown = this.video.currentTime;
                    this.m_MotionState = InitialDelay;
                    continue;
                }
                return false;
            }
            case InitialDelay: {
                if (countdown * 1000 > initialDelay || initialDelay === 0) {
                    this.m_BurstCount = 0;
                    if (motion === true) {
                        this.m_BurstCount++;
                        if (this.m_BurstCount < maxBursts || maxBursts === 0) {
                            // Max_burst != 1, still movement, delay
                            this.m_MotionState = BurstDelay;
                        } else {
                            // Max_burst = 1, still movement, cooloff
                            this.m_BurstCount = 0;
                            this.m_MotionState = BurstCooloff;
                        }
                        this.m_MotionCountdown = this.video.currentTime;
                        // First burst
                        output.burstCount = this.m_BurstCount;
                        return true;
                    } if (minBursts === 0) {
                        this.m_MotionState = NoMotion;
                        return false;
                    }
                    this.m_BurstCount++;
                    this.m_MotionState = BurstDelay;
                    this.m_MotionCountdown = this.video.currentTime;
                    // First burst
                    output.burstCount = this.m_BurstCount;
                    return true;
                }
                return false;
            }
            case BurstDelay: {
                if (countdown * 1000 > burstDelay) {
                    let photo = false;
                    if (this.m_BurstCount >= maxBursts && maxBursts !== 0) {
                        if (motion || this.m_BurstCount >= minBursts) {
                            this.m_BurstCount = 0;
                            this.m_MotionState = BurstCooloff;
                        } else {
                            this.m_MotionState = MotionCooloff;
                        }
                    } else if (!motion && this.m_BurstCount >= minBursts) {
                        this.m_MotionState = MotionCooloff;
                    } else if (motion || this.m_BurstCount < minBursts) {
                        this.m_BurstCount++;
                        photo = true;
                    }
                    this.m_MotionCountdown = this.video.currentTime;
                    output.burstCount = this.m_BurstCount;
                    return photo;
                }
                return false;
            }
            case BurstCooloff: {
                if (countdown * 1000 > burstCooloff) {
                    if (motion) {
                        this.m_BurstCount++;
                        this.m_MotionState = BurstDelay;
                        this.m_MotionCountdown = this.video.currentTime;
                        output.burstCount = this.m_BurstCount;
                        return true;
                    }
                    this.m_MotionState = NoMotion;
                    return false;
                }
                return false;
            }
            case MotionCooloff: {
                if (countdown * 1000 > motionCooloff) {
                    if (motion) {
                        // Start InitialDelay countdown
                        this.m_MotionCountdown = this.video.currentTime;
                        this.m_MotionState = InitialDelay;
                        continue;
                    } else {
                        this.m_MotionState = NoMotion;
                    }
                }
                return false;
            }
            default:
                return false;
            }
        }
        return false;
    }

    updateCanvas() {
        const self = this;

        /* Check if current time is within acceptable window. */
        const window_width = 3;
        const into_past = self.video.currentTime < self.lastTime - window_width / 2;
        const into_future = self.video.currentTime > self.lastTime + window_width / 2;

        /* Reset some variables when video is scrolled back */
        if (into_past || into_future) {
            console.log('Time jump, resetting data');
            self.m_MotionCountdown = 0;
            self.m_MotionState = NoMotion;

            this.cleanupOpenCVVars();
            this.cleanupFrames();
            this.cleanupBursts();
            this.clearCanvases();

            this.videoCap = new cv.VideoCapture(this.video);
            this.frame = new cv.Mat(this.video.height, this.video.width, cv.CV_8UC4);
            this.frameMasked = new cv.Mat(this.video.height, this.video.width, cv.CV_8UC1);
        }
        // console.log("Video Analyzed " + video.currentTime);
        self.lastTime = self.video.currentTime;
        const output = new OutputData();
        let currentSettings = null;
        currentSettings = _.cloneDeep(self.selectedZone.camera.configuration);

        /*      Fill in output data     */
        output.settings = currentSettings;
        // output.frame = frame.clone();

        output.time = self.video.currentTime;
        output.motionState = self.m_MotionState;

        // TODO Check if iframe was had
        self.videoCap.read(self.frame);
        cv.cvtColor(self.frame, self.frameMasked, cv.COLOR_RGBA2GRAY, 0);

        const motiondetections = self.processFrame(self.frameMasked, output, self.frame);
        const detectedmotion = !(motiondetections.length === 0);
        const motionresult = self.changeMotionState(detectedmotion, output);

        if (motionresult) {
            // console.log('motion detected!');
            self.bursts.push(output);
            cv.imshow('outputbursts', output.frame);
            self.index = self.bursts.length - 1;
        } else {
            /*      Delete unused output data to prevent memory overflow       */
            if (output.lastframe !== null) {
                output.lastframe.delete();
            }
            if (output.threshold !== null) {
                output.frame.delete();
                output.threshold.delete();
                output.erode.delete();
                output.dilate.delete();
                output.contours.delete();
                output.rectangles.delete();
            }
        }
        self.processedFrames += 1;
        self.video.requestVideoFrameCallback(self.gotFrame);
    }

    processFrame(frame, output, colourFrame) {
        const results = [];
        let size = frame.size();
        const diff = new cv.Mat();
        const { width } = size;
        const { height } = size;
        const scale = this.selectedZone.camera.configuration.scaleDown;
        let subtractorType = this.selectedZone.camera.configuration.analyticsConfiguration.motionConfiguration.algorithm;
        const { learningRate } = this.selectedZone.camera.configuration.analyticsConfiguration.motionConfiguration;
        const sizeDown = new cv.Size(width * scale, height * scale);

        const thisFrame = new cv.Mat();
        const thisColourFrame = new cv.Mat();
        cv.resize(frame, thisFrame, sizeDown);
        size = thisFrame.size();
        cv.resize(colourFrame, thisColourFrame, sizeDown);

        /*  MOG2 Algorithm implementation   */
        if (subtractorType !== 'OpenCVCustom') {
            if (this.CVBackgroundSubtractor === null) {
                switch (subtractorType) {
                case 'OpenCVMOG2': {
                    /* TODO Make sure initialization is correct */
                    this.CVBackgroundSubtractor = new cv.BackgroundSubtractorMOG2();
                    break;
                }
                case 'OpenCVCustom':
                default:
                    break;
                }
            }

            // TODO use all parameters
            if (!(this.CVBackgroundSubtractor === null)) {
                this.CVBackgroundSubtractor.apply(thisFrame, diff, learningRate);
                // Throw away shadows... only for MOG2 and KNN
                if (subtractorType === 'OpenCVMOG2') {
                    cv.threshold(diff, diff, 254, 255, cv.THRESH_BINARY);
                    output.threshold = diff.clone();
                    cv.imshow('output1', diff);
                }
            } else {
                this.selectedZone.camera.configuration.analyticsConfiguration.motionConfiguration.algorithm = 'OpenCVCustom';
                subtractorType = 'OpenCVCustom';
                // This means the algorithm didn't work
                console.log('Trying uninitialized subtractor');
            }
        }

        if (subtractorType === 'OpenCVCustom') {
            if (this.previousFrame === null) {
                this.previousFrame = thisFrame.clone();
                thisFrame.delete();
                thisColourFrame.delete();
                diff.delete();
                return results;
            }

            if (this.previousFrame.size().width !== size.width || this.previousFrame.size().height !== size.height) {
                console.log(`Frame changed from ${this.previousFrame.size().width}x${this.previousFrame.size().height} to ${size.width}x${size.height}`);
                cv.resize(this.previousFrame, this.previousFrame, size);
            }

            output.lastframe = this.previousFrame.clone();

            cv.absdiff(thisFrame, this.previousFrame, diff);

            this.previousFrame.delete();
            this.previousFrame = thisFrame.clone();

            cv.threshold(diff, diff, this.selectedZone.camera.configuration.analyticsConfiguration.motionConfiguration.deltaThreshold, 255, cv.THRESH_BINARY);
            output.threshold = diff.clone();
            /*      Show effect of threshold        */
            cv.imshow('output1', diff);
        }

        const M = new cv.Mat();
        const anchor = new cv.Point(-1, -1);

        const { erosions } = this.selectedZone.camera.configuration.analyticsConfiguration.motionConfiguration;
        if (erosions > 0) {
            cv.erode(diff, diff, M, anchor, erosions);
        }
        output.erode = diff.clone();
        cv.imshow('output2', diff);

        const { dilations } = this.selectedZone.camera.configuration.analyticsConfiguration.motionConfiguration;
        if (dilations > 0) {
            cv.dilate(diff, diff, M, anchor, dilations);
        }
        output.dilate = diff.clone();
        cv.imshow('output3', diff);

        const contours = new cv.MatVector();
        const hierarchy = new cv.Mat();
        cv.findContours(diff, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);

        const conts = new cv.Mat(thisFrame);

        cv.cvtColor(conts, conts, cv.COLOR_GRAY2BGR);
        const color = new cv.Scalar(0, 255, 0);
        cv.drawContours(conts, contours, -1, color, 1);

        cv.imshow('output4', conts);

        output.contours = conts.clone();

        const numContours = contours.size();

        for (let counter = 0; counter < numContours; counter++) {
            const contour = contours.get(counter);
            const area = cv.contourArea(contour);
            const maxArea = this.selectedZone.camera.configuration.analyticsConfiguration.motionConfiguration.maxarea;
            const minArea = this.selectedZone.camera.configuration.analyticsConfiguration.motionConfiguration.minarea;
            if ((area <= maxArea || maxArea === -1) && (area >= minArea || minArea === -1)) {
                const detection = cv.boundingRect(contour);

                const red = new cv.Scalar(255, 0, 0);
                const point1 = new cv.Point(detection.x, detection.y);
                const point2 = new cv.Point(detection.x + detection.width, detection.y + detection.height);
                cv.rectangle(conts, point1, point2, red, 1);
                cv.rectangle(thisColourFrame, point1, point2, [255, 0, 0, 255]);

                /*      Show detected motions       */
                cv.imshow('output5', conts);

                const det = new Rectangle();
                // det.topleft = cv.Point((detection.x)/width, (detection.y)/height);
                // det.bottomright = cv.Point((detection.width + detection.x)/width, (detection.height + detection.y)/height);
                results.push(det);
            }
        }
        output.rectangles = conts.clone();
        output.frame = thisColourFrame.clone();

        thisFrame.delete();
        thisColourFrame.delete();
        diff.delete();
        M.delete();
        hierarchy.delete();
        conts.delete();
        contours.delete();
        return results;
    }
}
