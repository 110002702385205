export default class AsideController {
    $uibModalInstance;
    $scope;
    $http;
    sites;
    startDate;
    startTime;
    endDate;
    reasons;
    endTime;
    onlyFaces = false;
    onlyPeople = false;
    onlyPoses = false;
    onlyObjects = false;
    onlyVehicles = false;
    onlyXFS = false;
    onlyPlates = false;
    onlyRecog = false;
    lastFilter;
    availReasons = [
        'Motion',
        'SnapshotRequest',
        'ReferenceRequest',
        'LineTrip',
        'AHD',
        'Unknown',
        'AccessControl',
        'PeerCameraMotion',
        'PeerCameraLine',
    ];
    Auth;
    availableSites = [];
    toastr;
    quickPickFromOptions = [
        'Last 10 minutes',
        'Last 30 minutes',
        'Last hour',
        'Last 6 hours',
        'Last 24 hours',
        'Last 7 days',
        'Last 30 days',
    ];

    /* @ngInject */
    constructor($scope, $uibModalInstance, $http, lastFilter, Auth, toastr, moment) {
        this.$uibModalInstance = $uibModalInstance;
        this.$scope = $scope;
        this.$http = $http;
        this.Auth = Auth;
        this.emotionOptions = [
            { type: 'happy', label: 'Happy' },
            { type: 'sad', label: 'Sad' },
            { type: 'angry', label: 'Angry' },
            { type: 'confused', label: 'Confused' },
            { type: 'disgusted', label: 'Disgusted' },
            { type: 'surprised', label: 'Surprised' },
            { type: 'calm', label: 'Calm' },
            { type: 'unknown', label: 'Unknown' },
            { type: 'fear', label: 'Fear' },
        ];
        if (lastFilter) {
            this.sites = lastFilter.sites;
            this.startDate = lastFilter.startDate;
            this.endDate = lastFilter.endDate;
            this.startTime = lastFilter.startTime;
            this.endTime = lastFilter.endTime;
            this.reasons = lastFilter.reasons;
            this.onlyFaces = lastFilter.onlyFaces;
            this.onlyVehicles = lastFilter.onlyVehicles;
            this.onlyXFS = lastFilter.onlyXFS;
            this.onlyPeople = lastFilter.onlyPeople;
            this.onlyPoses = lastFilter.onlyPoses;
            this.onlyObjects = lastFilter.onlyObjects;
            this.onlyPlates = lastFilter.onlyPlates;
            this.onlyRecog = lastFilter.onlyRecog;

            this.gender = lastFilter.gender;
            this.beard = lastFilter.beard;
            this.mustache = lastFilter.mustache;
            this.eyesOpen = lastFilter.eyesOpen;
            this.age = lastFilter.age;
            if (lastFilter.age) {
                this.ageSearch = true;
            }
            this.emotionFilter = lastFilter.emotionFilter;
            this.sunglasses = lastFilter.sunglasses;
            this.eyeglasses = lastFilter.eyeglasses;
            this.smile = lastFilter.smile;
        }
        this.toastr = toastr;
        this.moment = moment;
    }

    $onInit() {
        // console.log("INITIALIZING");
        const self = this;
        self.ok = function (e) {
            self.$uibModalInstance.close({
                sites: self.sites,
                startDate: self.startDate,
                startTime: self.startTime,
                endDate: self.endDate,
                endTime: self.endTime,
                onlyRecog: self.onlyRecog,
                reasons: self.reasons,
                onlyFaces: self.onlyFaces,
                onlyPeople: self.onlyPeople,
                onlyPoses: self.onlyPoses,
                onlyObjects: self.onlyObjects,
                onlyVehicles: self.onlyVehicles,
                onlyXFS: self.onlyXFS,
                onlyPlates: self.onlyPlates,

                gender: self.gender,
                beard: self.beard,
                mustache: self.mustache,
                smile: self.smile,
                eyeglasses: self.eyeglasses,
                sunglasses: self.sunglasses,
                mouthOpen: self.mouthOpen,
                emotionFilter: self.emotionFilter,
                eyesOpen: self.eyesOpen,
                age: self.ageSearch ? self.age : undefined,
            });
        };

        self.clearFilter = function (e) {
            self.$uibModalInstance.close({
                sites: undefined,
                startDate: undefined,
                startTime: undefined,
                endDate: undefined,
                endTime: undefined,
                reasons: undefined,
                onlyRecog: undefined,
                onlyFaces: undefined,
                onlyVehicles: undefined,
                onlyXFS: undefined,
                onlyPeople: undefined,
                onlyPoses: undefined,
                onlyObjects: undefined,
                onlyPlates: undefined,

                gender: undefined,
                beard: undefined,
                mustache: undefined,
                smile: undefined,
                eyeglasses: undefined,
                sunglasses: undefined,
                mouthOpen: undefined,
                emotionFilter: undefined,
                eyesOpen: undefined,
                age: undefined,
            });
        };

        self.cancel = function (e) {
            self.$uibModalInstance.close();
        };

        if (this.Auth.hasPrivilegeSync('secuvue.camera.basicIndex')) {
            // console.log("CALLING BASIC CAMERA");
            this.$http.get('/api/cameras', {
                params: {
                    noreference: true,
                },
            }).then((response) => {
                // console.log(response.data);
                self.availableSites = [];
                response.data.forEach((camera) => {
                    self.availableSites.push({
                        siteAlias: camera.site.alias,
                        cameraAlias: camera.alias,
                        site: camera.site._id,
                        camera: camera._id,
                    });
                });
            }, (err) => {
                if (err.status !== 401 && err.status !== 403) console.error(err);
            });
        } else {
            // this.toastr.info(`You require secuvue.camera privileges`, 'Unprivileged : ', {
            // preventOpenDuplicates: true
            // });
        }
    }

    doLog() {
        const self = this;
        console.log('DEBUG: ', self);
    }

    quickPickFrom(index, option) {
        const self = this;
        const to = this.moment();
        const from = this.moment();
        self.endDate = to.toDate();
        self.endTime = to.toDate();
        switch (option) {
        case 'Last 10 minutes':
            self.startTime = from.subtract(10, 'minutes').toDate();
            break;
        case 'Last 30 minutes':
            self.startTime = from.subtract(30, 'minutes').toDate();
            break;
        case 'Last hour':
            self.startTime = from.subtract(1, 'hours').toDate();
            break;
        case 'Last 6 hours':
            self.startTime = from.subtract(6, 'hours').toDate();
            break;
        case 'Last 24 hours':
            self.startTime = from.subtract(1, 'days').toDate();
            break;
        case 'Last 7 days':
            self.startTime = from.subtract(7, 'days').toDate();
            break;
        case 'Last 30 days':
            self.startTime = from.subtract(30, 'days').toDate();
            break;
        default:
        }
        self.startDate = self.startTime;
    }
}
