'use strict';

angular.module('SmartAdmin.Layout').directive('reloadState', function($rootScope) {
    return {
        restrict: 'A',
        compile(tElement, tAttributes) {
            tElement.removeAttr('reload-state data-reload-state');
            tElement.on('click', function(e) {
                $rootScope.$state.transitionTo($rootScope.$state.current, $rootScope.$stateParams, {
                    reload: true,
                    inherit: false,
                    notify: true
                });
                e.preventDefault();
            });
        }
    };
});
