import uiBootstrap from 'angular-ui-bootstrap';
import { fabric } from 'fabric';

export default class OptionsController {
    $state;
    $http;
    $scope;
    $uibModal;
    $uibModalInstance;
    $timeout
    identity;
    moment;
    more;
    canvas;
    canvasContext;
    toastr;
    otherSightings;
    Auth;
    canEdit;
    smallFace;
    allTags;
    /* @ngInject */

    constructor($uibModalInstance, moment, Auth, toastr, $http, smallFace, $state, $scope, $uibModal, $timeout, identity) {
        this.$uibModalInstance = $uibModalInstance;
        this.$state = $state;
        this.moment = moment;
        this.toastr = toastr;
        this.canEdit = false;
        this.Auth = Auth;
        this.$http = $http;
        this.$scope = $scope;
        this.otherSightings = [];
        this.more = true;
        this.$uibModal = $uibModal;
        this.identity = identity;
        this.smallFace = smallFace;
        this.$timeout = $timeout;
    }

    doLog() {
        const self = this;
        console.log('DEBUG: ', self);
    }

    $onInit() {
        const self = this;
        // console.log(self.reference);
        self.canEdit = self.Auth.hasPrivilegeSync('secuvue.reference.updateWanted');
        self.$http.get('api/identities/findTags').then((res) => {
            self.allTags = res.data;
        });

        // console.log("SNAPFACES:");
        self.$http
            .get(`api/snapshots/snapFaces/${self.identity._id}`, {
                params: { timestamp: +self.moment().utc() },
            })
            .then((response) => {
                // console.log("RESPONSE: ", response.data);
                if (response.data.length > 0) {
                    if (response.data.length < 4) {
                        self.more = false;
                    }
                    // console.log("Response was returned: ", response.data);
                    //
                    self.otherSightings = response.data;
                    return self.otherSightings;
                }
                self.more = false;
            });

        self.$timeout(() => {
            self.drawCanvas();
        }, 0);
    }

    saveCanvas() {
        const self = this;
        if (!self.canvas) return;
        // use FabricJS's toDataURL() method to generate the data URL
        const dataURL = self.canvas.toDataURL({ format: 'jpeg', quality: 1 });

        // create a link element
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = `${this.identity.alias} - ${this.moment().format('YYYY-MM-DDTHH_mm_ss')}.jpg`;

        // trigger a click event on the link to start the download
        link.addEventListener('click', () => {
            // remove the link element when the download is complete
            document.body.removeChild(link);
        });

        // append the link to the body
        document.body.appendChild(link);

        // trigger a click event on the link
        link.click();
    }

    drawCanvas() {
        const self = this;
        const face = self.identity.faces[0];
        if (!face) return;

        if (!self.canvas) {
            self.canvas = new fabric.Canvas('snapshotCanvas', { stopContextMenu: true, enableRetinaScaling: false });
        }
        self.canvas.clear();
        self.canvas.containerClass = 'snapshot-wrapper';
        const image = new fabric.Image.fromURL(face.data, ((image) => {
            image.setOptions({
                left: 0, top: 0, opacity: 1, width: face.width, height: face.height,
            });
            self.canvas.setBackgroundImage(image, self.canvas.renderAll.bind(self.canvas), {
                originX: 'left',
                originY: 'top',
            });
            self.canvas.setWidth(image.width, { backstoreOnly: true });
            self.canvas.setHeight(image.height, { backstoreOnly: true });
            self.canvas.setWidth('100%', { cssOnly: true });
            self.canvas.setHeight('auto', { cssOnly: true });
            self.canvas.selection = false;

            const rect = new fabric.Rect({
                fill: 'rgba(0,0,0,0)',
                width: face.boundingBox.Width * face.width,
                height: face.boundingBox.Height * face.height,
                left: face.boundingBox.Left * face.width,
                top: face.boundingBox.Top * face.height,
                stroke: 'rgba(0,255,0, 0.5)',
                selectable: false,
                strokeWidth: 4,
            });
            self.canvas.add(rect);
            rect.moveTo(30);
            rect.bringToFront();
            self.canvas.renderAll();
        }), { crossOrigin: 'anonymous' });
    }

    close() {
        const self = this;
        self.$uibModalInstance.dismiss();
    }

    openSnapshot(snap) {
        const self = this;
        self.$uibModalInstance.close({ snap });
    }

    update() {
        const self = this;
        self.$uibModalInstance.close({ identity: self.identity });
    }

    delete() {
        const self = this;
        const r = confirm('Are you sure you want to delete?');
        if (r == true) {
            self.$uibModalInstance.close({ deleting: self.identity._id });
        }
    }

    loadMore() {
        const self = this;
        self.$http.get(`api/snapshots/snapFaces/${self.identity._id}`, {
            params: {
                timestamp: +self.otherSightings[self.otherSightings.length - 1].timestamp,
            },
        }).then((response) => {
            if (response.data.length > 0) {
                self.otherSightings = self.otherSightings.concat(response.data);
            }
            if (response.data.length < 4) {
                self.toastr.warning('There are no more photos to be loaded', {
                    preventOpenDuplicates: true,
                });
                self.more = false;
            }
        });
    }

    removeTags(tags) {
        const label = ' (create new tag)';
        tags.forEach((tag, index) => {
            if (tag.slice(-label.length) === label) {
                tags[index] = tag.slice(0, -label.length);
            }
        });
    }
}
