import _ from 'lodash';
import { v1 as uuid } from 'uuid';
import async from 'async-es';
import angular from 'angular';
import { fabric } from 'fabric';
import AddCamerasComponent from "./add-cameras/add-cameras.component";
import Geometry from '../../../server/components/utilities/geometry';
import moment from "moment/moment";

const INACTIVE_STREAM_SECONDS = 3; // Mark stream as inactive if no buffers have been received for this amount of seconds
const statUpdate = {
    count: 1,
    unit: 'minutes',
}

// TODO: Sync cam status properly

// TODO: Sync cam status properly

export class DashboardComponent {
    $http;
    socket;
    $document;
    $rootScope;
    Auth;
    moment;
    $state;
    $scope;
    $timeout;
    snapshotAdapter = {
        adapter: {
            remain: true,
        },
    };

    isCollapsed = true;
    startDT;
    endDT;
    onlyFaces = false;
    filter = [];
    self;

    $stateParams;
    $ngConfirm;

    /* @ngInject */
    constructor($http, $window, $document, moment, $scope, $rootScope, $uibModal, socket, $state, Auth, $stateParams, toastr, $ngConfirm, $timeout) {
        this.$http = $http;
        this.socket = socket;
        this.$rootScope = $rootScope;
        this.$window = $window;
        this.$document = $document;
        this.$state = $state;
        this.$timeout = $timeout;
        this.moment = moment;
        this.$scope = $scope;
        this.Auth = Auth;
        this.toastr = toastr;
        this.$uibModal = $uibModal;
        this.$ngConfirm = $ngConfirm;
        this.isDebug = this.Auth.hasUserPrivilege('debug');

        this.tempSnaps = [];

        const self = this;

        this.startDT = self.moment(0).utc();
        this.endDT = self.moment().utc()
            .add(1, 'y');

        this.$stateParams = $stateParams;

        this.widgetMap = {
            sizeX: 'widget.sizeX',
            sizeY: 'widget.sizeY',
            row: 'widget.row',
            col: 'widget.col',
            // maxSizeY: 'widget.maxSizeY',
            // maxSizeX: 'widget.maxSizeX',
            height: 'widget.height',
            width: 'widget.width',
        };

        $scope.$on('gridster-resized', (gridster, sizes) => {
            // console.log("RESIZED", sizes);
            this.resizeWidgets(sizes[0]);
        });

        this.siteOptions = {
            columns: 50, // the width of the grid, in columns
            pushing: true, // whether to push other items out of the way on move or resize
            floating: true, // whether to automatically float items up so they stack (you can temporarily disable if you are adding unsorted items with ng-repeat)
            swapping: false, // whether to have items of the same size switch places instead of pushing down if they are the same size
            width: 'auto', // can be an integer or 'auto'. 'auto' scales gridster to be the full width of its containing element
            colWidth: 'auto', // can be an integer or 'auto'.  'auto' uses the pixel width of the element divided by 'columns'
            rowHeight: 'match', // can be an integer or 'match'.  Match uses the colWidth, giving you square widgets.
            margins: [10, 10], // the pixel distance between each widget
            outerMargin: true, // whether margins apply to outer edges of the grid
            sparse: true, // "true" can increase performance of dragging and resizing for big grid (e.g. 20x50)
            isMobile: false, // stacks the grid items if true
            mobileBreakPoint: 600, // if the screen is not wider that this, remove the grid layout and stack the items
            mobileModeEnabled: true, // whether to toggle mobile mode when screen width is less than mobileBreakPoint
            minColumns: 20, // the minimum columns the grid must have
            minRows: 20, // the minimum height of the grid, in rows
            maxRows: 200,
            defaultSizeX: 26, // the default width of a gridster item, if not specified
            defaultSizeY: 18, // the default height of a gridster item, if not specified
            minSizeX: 4, // minimum column width of an item
            maxSizeX: null, // maximum column width of an item
            minSizeY: 4, // minimum row height of an item
            maxSizeY: null, // maximum row height of an item
            resizable: {
                enabled: true,
                start(event, $element, widget) {}, // optional callback fired when resize is started,
                resize(event, $element, widget) {}, // optional callback fired when item is resized,
                stop(event, $element, widget) {}, // optional callback fired when item is finished resizing
            },
            draggable: {
                enabled: true, // whether dragging items is supported
                handle: 'h5', // optional selector for drag handle
                start(event, $element, widget) {}, // optional callback fired when drag is started,
                drag(event, $element, widget) {}, // optional callback fired when item is moved,
                stop(event, $element, widget) {}, // optional callback fired when item is finished dragging
            },
        };

        this.dashboards = [
            {
                id: 1,
                alias: '',
                widgets: [],
            },
        ];
        this.savedDash = [];
        this.camMap = {};

        /*
         * currentDash references its corresponding dash in dashboards,
         * so we can change the one in dashboards
         */
        this.currentDash = this.dashboards[0];

        /* permanentCams keeps track of all the cameras available in the account */
        this.permanentCams = [];
        this.snapSource = {};
        this.sockRooms = [];

        /** ******WEBRTC SPECIFIC VARIABLES******* */
        this.rtc_configuration = {
            iceServers: [
                {
                    urls: ['stun:stun.jerichosystems.co.za', 'turn:stun.jerichosystems.co.za'],
                    username: 'secuvue',
                    credential: 'jerichoturn'
                },
            ],
        };
        /*
         *{
             peerId: Signalling server Id,
             ws: websocket connection,
             pc: peer connection,
             status: status of connection,
             connect_attempts: number of attempts to connect to signalling server
         } */
        this.ws_conns = [];
        /** ************************************** */
    }

    $onDestroy() {
        const self = this;
        // this.saveDash();
        self.currentDash.widgets.forEach((widget) => {
            if (widget.livestream.active === true) {
                self.closeStream(widget);
            }
        });
        self.socket.unsyncUpdates('snapshot');
        self.socket.unsyncUpdates('site');
        if (self.sockRooms && self.sockRooms.length > 0) {
            self.sockRooms.forEach((room) => {
                self.socket.leaveRoom(room);
            });
        }
    }

    $onInit() {
        const self = this;

        const tempRoom = `${self.Auth.getCurrentAccountSync().accountId}:*:sites`;
        const tempIndex = _.findIndex(self.sockRooms, (room) => room == tempRoom);
        if (tempIndex) {
            self.socket.joinRoom(tempRoom);
            self.trackRooms('join', tempRoom);
        }

        if (self.loadAll(false) === true) {
            self.currentDash = self.dashboards[0];
            self.currentDash.widgets.forEach((obj) => {
                const liveId = uuid();
                obj.livestream = { guid: liveId, debugStatus: 'Idle', active: false, lastUpdate: moment() };
                self.drawCanvas(obj);
            });
        } else {
            self.currentDash.alias = 'Custom View 1';
        }

        self.socket.socket.on('snapshot:save', (item) => {
            if(item.data)
                self.snapSource[item.camera] = item;

            self.currentDash.widgets.forEach((obj) => {
                if (obj._id === item.camera) {
                    self.drawCanvas(obj);
                }
            });
        });

        self.socket.socket.on('site:save', (site) => {
            site.zones.forEach((zone) => {
                self.currentDash.widgets.forEach((obj) => {
                    if (obj.cameraId === zone.cameraId) {
                        let cam;
                        site.units.some((unit) => {
                            const tempCam = _.find(unit.cameras, (o) => o._id === zone.cameraId && zone.siteId === site._id);
                            if (tempCam) {
                                tempCam.unitDown = unit.down;
                                cam = tempCam;
                                return true;
                            }
                            return false;
                        });
                        if (cam) {
                            self.$timeout(() => {
                                obj.down = cam.down;
                                obj.unitDown = cam.unitDown;
                                obj.alias = zone.alias;
                            }, 0);
                        }

                        self.drawCanvas(obj);
                    }
                });
            });
        });
    }

    saveCanvas(widget) {
        const self = this;
        const canvasName = `widgetCanvas_${widget._id}`;
        // use FabricJS's toDataURL() method to generate the data URL
        const dataURL = self[canvasName].toDataURL({ format: 'jpeg', quality: 1 });

        // create a link element
        const link = document.createElement('a');
        link.href = dataURL;

        const snapshot = self.snapSource[widget._id];
        // set the download attribute to the desired file name
        if (snapshot) {
            link.download = `${snapshot.sitename} - ${snapshot.cameraname} - ${this.moment(snapshot.timestamp).format('YYYY-MM-DDTHH_mm_ss')}.jpg`;
        } else {
            link.download = `${widget.siteName} - ${widget.alias} - ${this.moment().format('YYYY-MM-DDTHH_mm_ss')}.jpg`;
        }

        // trigger a click event on the link to start the download
        link.addEventListener('click', () => {
            // remove the link element when the download is complete
            document.body.removeChild(link);
        });

        // append the link to the body
        document.body.appendChild(link);

        // trigger a click event on the link
        link.click();
    }

    drawCanvas(widget) {
        const self = this;
        const canvasName = `widgetCanvas_${widget._id}`;

        if (self.snapSource[widget._id] !== undefined) {
            const image = new fabric.Image.fromURL(self.snapSource[widget._id].data, ((image) => {
                image.setOptions({ left: 0, top: 0, opacity: 1 });
                if (typeof self[canvasName] === 'undefined') {
                    self[canvasName] = new fabric.Canvas(canvasName, { stopContextMenu: true, enableRetinaScaling: false });
                    self[canvasName].on('mouse:dblclick', (options) => {
                        self.siteFiltered(widget.siteId, widget);
                    });
                }
                const thisCanvas = self[canvasName];
                thisCanvas.containerClass = 'snapshot-wrapper';

                // thisCanvas.clear();
                thisCanvas.setWidth('100%', { cssOnly: true });
                thisCanvas.setHeight('100%', { cssOnly: true });
                self.$timeout(() => {
                    const canvasEl = self.$document[0].querySelector(`#${canvasName}`);
                    thisCanvas.setWidth(canvasEl.clientWidth, { backstoreOnly: true });
                    thisCanvas.setHeight(canvasEl.clientWidth / (image.width / image.height), { backstoreOnly: true });
                    image.set({
                        scaleX: +thisCanvas.width / image.width,
                        scaleY: thisCanvas.height / image.height,
                        originX: 'left',
                        originY: 'top',
                    });
                    thisCanvas.setBackgroundImage(image, thisCanvas.renderAll.bind(thisCanvas));

                    self.drawLines(widget);
                    thisCanvas.renderAll();
                }, 0);
                thisCanvas.selection = false;

                thisCanvas.renderAll();
            }), { crossOrigin: 'anonymous' });
        }
    }

    formatDate(date) {
        return this.moment(date).local()
            .format('ll LTS');
    }

    trackRooms(act, roomName) {
        const self = this;
        if (act === 'join') {
            self.sockRooms.push(roomName);
        } else {
            self.sockRooms.splice(self.sockRooms.indexOf(roomName), 1);
        }
    }

    checkLines(widget) {
        const self = this;
        const camIndex = _.findIndex(self.permanentCams, (cam) => cam._id == widget._id);
        if (camIndex !== -1) {
            return self.permanentCams[camIndex].lines.length > 0;
        }
        return false;
    }

    drawLines(widget) {
        const self = this;
        const canvasName = `widgetCanvas_${widget._id}`;
        const thisCanvas = self[canvasName];
        if (widget.enableLines) {
            self[canvasName].remove.apply(self[canvasName], self[canvasName].getObjects().concat());
            const camIndex = _.findIndex(self.permanentCams, (cam) => cam._id == widget._id);
            if (camIndex !== -1) {
                const tripLines = [];
                if (self.snapSource[widget._id].reason && self.snapSource[widget._id] === 'LineTrip') {
                    self.snapSource[widget._id].forEach((detection) => {
                        if (detection.detectionType === 'Motion') {
                            if (detection.lines && detection.lines.length > 0) {
                                detection.lines.forEach((line) => {
                                    // console.log("Pushing line", line);
                                    tripLines.push(line);
                                });
                            }
                        }
                    });
                }

                self.permanentCams[camIndex].lines.forEach((config) => {
                    const stats = _.find(self.snapSource[widget._id].peopleCounters, (obj) => obj.line === config.id);

                    if (stats !== undefined) {
                        // console.log("Found the stats: ", stats);
                        let color = 'rgba(255,0,0,0.8)';
                        let dashArray = [25, 10];

                        if (tripLines.includes(config.id)) {
                            color = 'rgba(0, 255, 0, 0.8)';
                            dashArray = [10, 2];
                        }

                        const line = new fabric.Line([
                            config.line[0].x * thisCanvas.width,
                            config.line[0].y * thisCanvas.height,
                            config.line[1].x * thisCanvas.width,
                            config.line[1].y * thisCanvas.height,
                        ], {
                            strokeWidth: 5,
                            strokeDashArray: dashArray,
                            stroke: `${color}`,
                            originX: 'center',
                            originY: 'center',
                            perPixelTargetFind: true,
                            selectable: false,
                            targetFindTolerance: 10,
                            // padding: 4,
                            hasControls: false,
                        });
                        thisCanvas.add(line);

                        line.set('shadow', new fabric.Shadow({
                            color: 'rgba(20, 20, 20, 1)',
                            blur: 2,
                            offsetX: 1,
                            offsetY: 1,
                        }));

                        const coords = Geometry.calculatePerpendicularBisectPoints(line, 50);

                        const textA = new fabric.Text(`A\n${stats.numBToA}`, {
                            fontSize: 16,
                            fontFamily: 'Monospace',
                            textAlign: 'center',
                            left: coords[2],
                            top: coords[3],
                            textBackgroundColor: 'rgba(0,200,0,1)',
                            originX: 'center',
                            originY: 'center',
                            shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
                            selectable: false,
                            hasBorders: false,
                            hasControls: false,
                        });

                        const textB = new fabric.Text(`B\n${stats.numAToB}`, {
                            fontSize: 16,
                            fontFamily: 'Monospace',
                            textAlign: 'center',
                            left: coords[0],
                            top: coords[1],
                            fill: 'white',
                            shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
                            textBackgroundColor: 'rgba(0,0,200,1)',
                            originX: 'center',
                            originY: 'center',
                            selectable: false,
                            hasBorders: false,
                            hasControls: false,
                        });

                        const textAlias = new fabric.Text(config.alias, {
                            fontSize: 16,
                            fontFamily: 'Monospace',
                            textAlign: 'center',
                            left: line.left,
                            top: line.top - line.height / 2 - 20,
                            shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
                            textBackgroundColor: 'rgba(200,200,200,0.5)',
                            originX: 'center',
                            originY: 'center',
                            // padding: 10,
                            selectable: false,
                            hasBorders: false,
                            hasControls: false,
                        });

                        thisCanvas.add(textA);
                        thisCanvas.add(textB);
                        thisCanvas.add(textAlias);
                        thisCanvas.sendToBack(textAlias);
                        line.textA = textA;
                        line.textB = textB;
                        line.textAlias = textAlias;
                    }
                });
            }
        } else {
            self[canvasName].remove.apply(self[canvasName], self[canvasName].getObjects().concat());
        }
    }

    initiateStream(widget) {
        const self = this;
        widget.livestream.active = true;
        const peer = {
            guid: widget.livestream.guid,
            ws: null,
            pc: null,
            debugStatus: 'Connecting to server',
            connect_attempts: 0,
        };
        self.ws_conns.push(peer);
        self.setStatus('Initiating Stream', peer);
        this.websocketServerConnect(widget.livestream.guid);
    }

    closeStream(widget) {
        const self = this;
        const peerIndex = _.findIndex(self.ws_conns, (o) => o.guid == widget.livestream.guid);
        if(peerIndex < 0) {
            console.error(`CS: Peer not found: ${peerIndex}`);
            return;
        }
        const peer = self.ws_conns[peerIndex];
        if (peer) {
            if (peer.pc !== null) {
                peer.pc.close();
                peer.pc = null;
                self.resetVideoElement(peer.guid);
            }
            if (peer.ws !== null) {
                peer.ws.close();
                peer.ws = null;
            }
            self.removeConn(peer.guid, widget);
        }
    }

    // #TODO: Automatically resize widgets to keep resolution
    resizeWidgets(width) {
        const self = this;
        // console.log("RESIZING TO: ", width);
        self.currentDash.widgets.forEach((obj) => {
            // console.log("Width: ", obj.width);
            // console.log("height: ", obj.height);
            // console.log("maxSizeX: ", obj.maxSizeX);
            // console.log("maxSizeY: ", obj.maxSizeY);
            const colSize = self.siteOptions.columns / width;
            const ratio = obj.width / obj.height;

            // console.log(colSize);
            obj.maxSizeX = Math.ceil(obj.width * obj.scaleDown * colSize);
            obj.maxSizeY = Math.floor((obj.height * obj.scaleDown + 53) * colSize);
            self.drawCanvas(obj);
        });
        // console.log(sizes);
    }

    addDash() {
        const self = this;
        // var dashLength = 1;

        const dashLength = self.dashboards.length;
        const newDashID = dashLength + 1;
        const tempCustomViewName = '';

        self.tempCustomViewName = `Custom View ${newDashID}`;
        self.$ngConfirm(
            {
                title: '<span style="margin:auto;">New Custom View</span>',
                theme: 'modern',
                animation: 'top',
                scope: self.$scope,
                closeAnimation: 'bottom',
                content:
                `Please enter a name for the new Custom View <br>
                <input class="form-control" ng-model="$ctrl.tempCustomViewName"/>

                `,
                escapeKey: true,
                backgroundDismiss: true,
                buttons: {
                    // long hand button definition
                    ok: {
                        text: 'Save',
                        btnClass: 'btn-primary',
                        keys: ['enter'], // will trigger when enter is pressed
                        action(scope, button) {
                            self.dashboards.push({
                                id: newDashID,
                                alias: `${self.tempCustomViewName}`,
                                widgets: [],
                            });

                            delete self.tempCustomViewName;

                            // console.log(self.dashboards);
                            self.toastr.success('View Successfully Added', {
                                preventOpenDuplicates: true,
                            });

                            // making the new dashboard the active one
                            self.currentDash.widgets.forEach((cam) => {
                                const canvasName = `widgetCanvas_${cam._id}`;
                                delete self[canvasName];
                                // delete self.snapSource[cam._id];
                            });
                            self.currentDash = self.dashboards[dashLength];
                            self.changeDash(self.dashboards[dashLength]);

                            // TODO: Before making a new dashboard and setting it to be active
                            // we should see whether there are any 'unsaved changes'
                        },
                    },
                    close: {
                        text: 'Cancel',
                        action(scope) {

                        },
                    },
                },
            },
        );
    }

    editDash(dash) {
        const self = this;

        self.tempCustomViewName = dash.alias;
        self.$ngConfirm(
            {
                title: '<span style="margin:auto;">Edit Custom View</span>',
                theme: 'modern',
                animation: 'top',
                scope: self.$scope,
                closeAnimation: 'bottom',
                content:
                `Please enter a name for the Custom View <br>
                <input class="form-control" ng-model="$ctrl.tempCustomViewName"/>

                `,
                escapeKey: true,
                backgroundDismiss: true,
                buttons: {
                    // long hand button definition
                    ok: {
                        text: 'Save',
                        btnClass: 'btn-primary',
                        keys: ['enter'], // will trigger when enter is pressed
                        action(scope, button) {
                            dash.alias = self.tempCustomViewName;
                            delete self.tempCustomViewName;
                            self.toastr.success('View Successfully Edited', {
                                preventOpenDuplicates: true,
                            });
                        },
                    },
                    close: {
                        text: 'Cancel',
                        action(scope) {

                        },
                    },
                },
            },
        );
    }

    removeDash() {
        const self = this;
        if (self.dashboards.length > 1) {
            self.$ngConfirm(
                {
                    title: '<span style="margin:auto;">Remove View?</span>',
                    theme: 'modern',
                    animation: 'top',
                    scope: self.$scope,
                    closeAnimation: 'bottom',
                    content:
                    `
                    <span style="display:flex; flex-flow:column; text-align:center;">
                        <span>Are you sure you wish to remove <br></span>
                        <span><b>${self.currentDash.alias}</b></span>
                    </span>
                    `,
                    escapeKey: true,
                    backgroundDismiss: true,
                    buttons: {
                        // long hand button definition
                        ok: {
                            text: 'Yes',
                            btnClass: 'btn-primary',
                            keys: ['enter'], // will trigger when enter is pressed
                            action(scope, button) {
                                const delIndex = _.findIndex(self.dashboards, (dash) => dash.id == self.currentDash.id);
                                const saveIndex = _.findIndex(self.savedDash, (dash) => dash.id == self.currentDash.id);
                                self.dashboards.splice(delIndex, 1);
                                self.currentDash = self.dashboards[0];
                                self.changeDash(self.dashboards[0]);
                                self.savedDash.splice(saveIndex, 1);
                                self.saveAll(false);
                            },
                        },
                        close: {
                            text: 'Cancel',
                            action(scope) {

                            },
                        },
                    },
                },
            );
        } else {
            self.toastr.warning('User must have at least one view', 'Unable to remove view', {
                preventOpenDuplicates: true,
            });
        }
    }

    toggleDrag() {
        const self = this;
        self.siteOptions.draggable.enabled = !self.siteOptions.draggable.enabled;
        self.siteOptions.resizable.enabled = !self.siteOptions.resizable.enabled;
    }

    addCamWidget(newCamera) {
        const self = this;

        const camIndex = _.findIndex(self.currentDash.widgets, (cam) => cam._id === newCamera._id);
        if (camIndex === -1) {
            const liveId = uuid();
            self.currentDash.widgets.push({
                _id: newCamera._id,
                alias: newCamera.alias,
                siteName: newCamera.siteName,
                siteId: newCamera.siteId,
                sizeX: 13,
                sizeY: 8,
                livestream: { active: false, guid: liveId, debugStatus: 'Idle', lastUpdate: moment() },
                down: newCamera.down,
                cameraId: newCamera.cameraId,
                unit: newCamera.unit,
                unitDown: newCamera.unitDown,
                maxSizeX: null,
                maxSizeY: null,
                row: undefined,
                col: undefined,
                height: newCamera.height,
                width: newCamera.width,
                scaleDown: newCamera.scaleDown,
            });

            self.joinRoom(self.Auth.getCurrentAccountSync().accountId, newCamera.siteId, newCamera._id);
            self.$http.get('api/snapshots/latestSnap', { params: { cam: newCamera._id } })
                .then((response) => {
                    self.snapSource[newCamera._id] = response.data;
                    self.currentDash.widgets.forEach((obj) => {
                        if (obj._id === newCamera._id) {
                            self.drawCanvas(obj);
                        }
                    });
                });
            self.resizeWidgets(document.getElementById('gridTemp').clientWidth);
        } else {
            console.warn('Camera already present!', newCamera, self.currentDash.widgets[camIndex]);
        }
    }

    changeDash(item) {
        const self = this;
        if (item) {
            self.currentDash.widgets.forEach((cam) => {
                const canvasName = `widgetCanvas_${cam._id}`;
                delete self[canvasName];
            });
            self.tempSnaps = item.widgets;
            self.loadDash();
        } else {
            item = {
                id: 1,
                alias: 'View 1',
                widgets: [],
            };
            self.tempSnaps = item.widgets;
        }
    }

    saveDash() {
        const self = this;
        const dash = _.cloneDeep(self.currentDash);
        dash.widgets.forEach((obj) => {
            delete obj.livestream;
        });
        const tempIndex = _.findIndex(self.savedDash, (obj) => obj.id === dash.id);
        if (tempIndex !== -1) {
            self.savedDash[tempIndex] = _.cloneDeep(dash);
        } else {
            self.savedDash.push(dash);
        }
        self.saveAll();
    }

    saveAll(toast = true) {
        const self = this;
        self.$http.put('/api/users/dashboards', { dashboard: self.savedDash })
            .then((response) => {
                if (response.status === 200 && toast === true) {
                    self.toastr.success('View Successfully Saved', {
                        preventOpenDuplicates: true,
                    });
                }
            });
    }

    loadDash() {
        const self = this;
        const saveIndex = _.findIndex(self.savedDash, (dash) => dash.id === self.currentDash.id);
        if (saveIndex !== -1) {
            self.$http.get('/api/users/dashboards').then((response) => {
                if (response.status === 200 && response.data && response.data.length > 0 && response.data[0] !== null) {
                    for (const index in response.data) {
                        const dash = response.data[index];
                        for (const index2 in dash.widgets) {
                            const cam = dash.widgets[index2];
                            const dashCam = _.find(self.permanentCams, (o) => o.cameraId == cam.cameraId && o.siteId == cam.siteId);
                            if (dashCam) {
                                cam.alias = dashCam.alias;
                                cam.down = dashCam.down;
                                cam.unitDown = dashCam.unitDown;
                                dash.widgets[index2] = cam;
                            }
                        }
                        response.data[index] = dash;
                    }

                    const resIndex = _.findIndex(response.data, (obj) => obj.id === self.currentDash.id);

                    const tempIndex = _.findIndex(self.dashboards, (obj) => obj.id === self.currentDash.id);
                    self.dashboards[tempIndex] = _.cloneDeep(response.data[resIndex]);
                    self.currentDash = self.dashboards[tempIndex];
                    self.currentDash.widgets.forEach((obj) => {
                        const liveId = uuid();
                        obj.livestream = { guid: liveId, debugStatus: 'Idle', active: false, lastUpdate: moment() };
                    });
                    const tempSnap = _.cloneDeep(self.currentDash.widgets);
                    self.tempSnaps = _.cloneDeep(self.currentDash.widgets);
                    self.populateDash(tempSnap);
                    self.currentDash.widgets.forEach((obj) => {
                        self.drawCanvas(obj);
                    });

                    self.toastr.success('View loaded successfully', {
                        preventOpenDuplicates: true,
                    });

                    return true;
                }
                self.toastr.info('No info', 'No saved view', {
                    preventOpenDuplicates: true,
                });
                return false;
            });
        } else {
            // TODO: Fix behaviour, improve error message
            self.toastr.info('No saved view found', {
                preventOpenDuplicates: true,
            });
        }
    }

    loadAll(toast = true) {
        const self = this;
        return self.$http.get('/api/users/dashboards')
            .then((response) => {
                if (response.status === 200 && response.data && response.data.length > 0 && response.data[0] !== null) {
                    for (const index in response.data) {
                        const dash = response.data[index];
                        for (const index2 in dash.widgets) {
                            const cam = dash.widgets[index2];
                            const dashCam = _.find(self.permanentCams, (o) => o.cameraId == cam.cameraId && o.siteId == cam.siteId);
                            if (dashCam) {
                                cam.alias = dashCam.alias;
                                cam.down = dashCam.down;
                                cam.unitDown = dashCam.unitDown;
                                dash.widgets[index2] = cam;
                            }
                        }
                        response.data[index] = dash;
                    }

                    self.dashboards = _.cloneDeep(response.data);
                    self.savedDash = _.cloneDeep(response.data);

                    self.currentDash = self.dashboards[0];
                    self.currentDash.widgets.forEach((obj) => {
                        const liveId = uuid();
                        obj.livestream = { guid: liveId, debugStatus: 'Idle', active: false, lastUpdate: moment() };
                    });
                    const tempSnap = _.cloneDeep(self.currentDash.widgets);
                    self.tempSnaps = _.cloneDeep(self.currentDash.widgets);

                    self.populateDash(tempSnap);
                    self.currentDash.widgets.forEach((obj) => {
                        self.drawCanvas(obj);
                    });
                    return true;
                }
                // console.log("No Data");
                self.savedDash = _.cloneDeep(self.dashboards);
                if (toast) {
                    self.toastr.info('No info', 'No saved view', {
                        preventOpenDuplicates: true,
                    });
                }
                return false;
            });
    }

    siteFiltered(siteId, cam) {
        const self = this;
        async.each(self.sockRooms, (room, callback) => {
            self.socket.leaveRoom(room);
            self.trackRooms('leave', room);
            callback();
        }, (err) => {
            self.socket.unsyncUpdates('snapshot');
            if (self.sockRooms && self.sockRooms.length > 0) {
                self.sockRooms.forEach((room) => {
                    self.socket.leaveRoom(room);
                });
            }
            self.$state.go('main.site', { id: siteId, filter: [cam] }).catch((err) => {
                console.log(err);
            });
        });
    }

    populateDash(newDash) {
        const self = this;
        self.clear();

        newDash.forEach((cam) => {
            if (cam.livestream === null) {
                cam.livestream = { guid: uuid(), debugStatus: 'Idle', active: false, lastUpdate: moment() };
            }
            self.currentDash.widgets.push(cam);
            self.tempSnaps.push(cam);

            self.joinRoom(self.Auth.getCurrentAccountSync().accountId, cam.siteId, cam._id);

            self.$http.get('api/snapshots/latestSnap', { params: { cam: cam._id } })
                .then((response) => {
                    self.snapSource[cam._id] = response.data;
                    // TODO: Don't update everything
                    self.currentDash.widgets.forEach((obj) => {
                        if (obj._id === cam._id) {
                            self.drawCanvas(obj);
                        }
                    });
                });
        });

        self.resizeWidgets(document.getElementById('gridTemp').clientWidth);
    }

    requestAll() {
        const self = this;
        self.currentDash.widgets.forEach((cam) => {
            self.requestSnap(cam);
        });
    }

    requestSnap(cam) {
        const self = this;
        self.$http.post(`/api/cameras/${cam._id}/requestSnapshot`, {})
            .then(() => {

            }, (err) => {
                if (err.status !== 401 && err.status !== 403) {
                    console.error(err);
                    if (err.status === 400) {
                        if (err.data.reason === 'ZoneDown') {
                            self.toastr.info(`The camera at ${cam.alias} is down`, 'Zone Down: ', { preventOpenDuplicates: true });
                        } else {
                            self.toastr.info(`The unit at ${cam.alias} is down`, 'Unit Down: ', { preventOpenDuplicates: true });
                        }
                    } else if (err.status === 404) {
                        self.toastr.info(`${cam.alias} has no camera assigned`, 'Zone Not Assigned: ', { preventOpenDuplicates: true });
                    }
                }
            });
    }

    joinRoom(accId = '*', siteId = '*', camId = '*', type = 'snapshots') {
        const self = this;
        self.Auth.hasPrivilege('secuvue.snapshot.index')
            .then((has) => {
                if (has) {
                    const tempRoom = `${accId}:${siteId}:${camId}:${type}`;
                    const tempIndex = _.findIndex(self.sockRooms, (room) => room == tempRoom);
                    if (tempIndex == -1) {
                        self.socket.joinRoom(tempRoom);
                        self.trackRooms('join', tempRoom);
                    }
                }
            });
    }

    leaveRoom(accId, siteId, camId) {
        const self = this;
        const tempRoom = `${accId}:${siteId}:${camId}:snapshots`;
        self.socket.leaveRoom(tempRoom);
        self.trackRooms('leave', tempRoom);
        // console.log(`left${accId}:${siteId}:${camId}:snapshots`);
    }

    removeCam(cam) {
        const self = this;
        self.leaveRoom(self.Auth.getCurrentAccountSync().accountId, cam.siteId, cam._id);
        if (cam.livestream) {
            if (cam.livestream.active) {
                self.closeStream(cam);
            }
        }
        const tempIndex = _.findIndex(self.tempSnaps, (val) => cam._id === val._id);
        if (tempIndex !== -1) {
            self.tempSnaps.splice(tempIndex, 1);
        }
        const snapIndex = _.findIndex(self.currentDash.widgets, (val) => cam._id === val._id);
        if (snapIndex !== -1) {
            self.currentDash.widgets.splice(snapIndex, 1);
        }
        const canvasName = `widgetCanvas_${cam._id}`;
        delete self[canvasName];
        delete self.snapSource[cam._id];
    }

    addZones() {
        const self = this;

        self.$uibModal.open({
            template: require('./add-cameras/add-cameras.html'),
            backdrop: 'static',
            keyboard: false,
            size: 'm',
            controller: AddCamerasComponent,
            controllerAs: '$ctrl',
            resolve: {
                currentCameras() {
                    return self.currentDash.widgets;
                },
            },
        })
            .result
            .then((result) => {
                result.camsToAdd.forEach((cam) => {
                    self.addCamWidget(cam);
                });
            });
    }

    clear() {
        const self = this;
        _.forEachRight(self.currentDash.widgets, (widget) => {
            self.removeCam(widget);
        });
    }

    doLog() {
        console.log('DEBUG:', this);
    }

    /** **********************************************WEBRTC MAGIC WILL HAPPEN HERE************************************ */
    resetState(peer) {
        peer.ws.close();
    }

    setStatus(msg, peer) {
        const self = this;
        // console.log("SETTING STATUS WITH: ", peer);
        const peerIndex = _.findIndex(self.currentDash.widgets, (o) => o.livestream.guid === peer.guid);
        if (peerIndex !== -1) {
            // console.log(peerIndex);
            self.currentDash.widgets[peerIndex].livestream.debugStatus = msg;
        }
        peer.debugStatus = msg;
        console.log(peer.guid, ': ', msg);
    }

    handleIncomingError(error, peer) {
        const self = this;
        // console.log("ERROR: ", error);
        if (self.isDebug) {
            this.setStatus(`ERROR: ${error}`, peer);
        }
        this.resetState(peer);
    }

    removeConn(peerId, widget) {
        const peerIndex = _.findIndex(this.ws_conns, (o) => peerId == o.id);
        if (widget && widget.livestream) {
            widget.livestream.active = false;
            widget.livestream.debugStatus = 'Idle';
        }
        this.ws_conns.splice(peerIndex, 1);
    }

    requestFullscreen(widget) {
        // console.log(widget);
        // let videoElement = this.getVideoElement(widget.livestream.guid);
        let videoElement;
        if (widget.livestream.active) {
            videoElement = document.getElementById(`${widget.livestream.guid}`);
        } else {
            videoElement = document.getElementById(`widgetCanvas_${widget._id}`);
        }
        if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
        } else if (videoElement.mozRequestFullScreen) {
            videoElement.mozRequestFullScreen();
        } else if (videoElement.webkitRequestFullscreen) {
            videoElement.webkitRequestFullscreen();
        } else if (videoElement.msRequestFullscreen) {
            videoElement.msRequestFullscreen();
        }
    }

    getVideoElement(peerId) {
        return document.getElementById(`${peerId}`);
    }

    resetVideoElement(peerId) {
        const peerIndex = _.findIndex(this.currentDash.widgets, (o) => o.livestream.guid === peerId);
        const videoElement = this.getVideoElement(peerId);
        // this.drawCanvas(this.currentDash.widgets[peerIndex], true);
        // console.log("VIDEOELEMENT: ", videoElement);
        if (videoElement) {
            videoElement.pause();
            videoElement.src = '';
            videoElement.load();
        }
    }

    onIncomingSDP(sdp, peer) {
        // console.log("Incoming SDP: ", JSON.stringify(sdp));
        const self = this;
        peer.pc.setRemoteDescription(new RTCSessionDescription(sdp)).then(() => {
            if (self.isDebug) {
                this.setStatus('Remote SDP set', peer);
            }
            if (sdp.type != 'offer') {
                return;
            }
            if (self.isDebug) {
                this.setStatus('Got SDP offer, creating answer', peer);
            }
            peer.pc.createAnswer()
                .then(this.onLocalDescription.bind(this, peer))
                .catch(this.setStatus.bind(this, peer));
        });
    }

    onLocalDescription(peer, desc) {
        const self = this;
        console.log(`Got local description: ${JSON.stringify(desc)}`);
        console.log('GOING TO THE: ', peer);
        peer.pc.setLocalDescription(desc).then(() => {
            if (self.isDebug) {
                self.setStatus('Sending SDP answer', peer);
            }
            const sdp = { sdp: peer.pc.localDescription };
            peer.ws.send(JSON.stringify(sdp));
        });
    }

    onIncomingICE(ice, peer) {
        const candidate = new RTCIceCandidate(ice);
        peer.pc.addIceCandidate(candidate).catch(this.setStatus, peer);
    }

    onServerMessage(peerId, event) {
        const self = this;

        const peerIndex = _.findIndex(self.ws_conns, (o) => peerId === o.guid);
        if(peerIndex < 0) {
            console.error(`OSM: Peer not found: ${peerId}`);
            return;
        }
        const peer = self.ws_conns[peerIndex];
        switch (event.data) {
        case 'HELLO':
            self.setStatus('Waiting for incoming stream', peer);
            const camIndex = _.findIndex(self.currentDash.widgets, (o) => o.livestream.guid === peerId);
            const camId = self.currentDash.widgets[camIndex]._id;
            const { siteId } = self.currentDash.widgets[camIndex];
            self.$http.post(`/api/cameras/initiateStream/${camId}`, { peerId, siteId }).then((response) => {
                console.log('Got a response: ', response.data);
            });
            return;
        default:
            if (event.data.startsWith('ERROR')) {
                self.handleIncomingError(event.data, peer);
                return;
            }
            // Handle incoming JSON SDP and ICE messages
            let msg;
            try {
                msg = JSON.parse(event.data);
                // console.log("This is the message: ", msg);
            } catch (e) {
                if (e instanceof SyntaxError) {
                    self.handleIncomingError(`Error parsing incoming JSON: ${e}`, peer);
                } else {
                    self.handleIncomingError(`Unknown error parsing response: ${event.data}`, peer);
                }
                return;
            }
            // Incoming JSON signals the beginning of a call
            if (peer.pc === null) {
                self.createCall(msg, peer);
            }

            // console.log("THIS IS THE MESSAGE: ", msg);
            if (msg.sdp) {
                self.onIncomingSDP(msg.sdp, peer);
            } else if (msg.ice) {
                console.log('Message: ', msg);
                self.onIncomingICE(msg.ice, peer);
            } else {
                self.handleIncomingError(`Unknown incoming JSON: ${msg}`, peer);
            }
        }
    }

    onServerClose(peerId, event) {
        // console.log("On server close event fired for: ", peerId);
        const peerIndex = _.findIndex(this.ws_conns, (o) => peerId == o.guid);
        if(peerIndex < 0) {
            console.error(`OSC: Peer not found: ${peerId}`);
            return;
        }
        const peer = this.ws_conns[peerIndex];
        this.resetVideoElement(peerId);
        if (peer) {
            if (peer.pc !== null) {
                peer.pc.close();
                peer.pc = null;
            }
        }
        // Reset after a second
        this.$window.setTimeout(this.websocketServerConnect.bind(this, peerId), 1000);
    }

    onServerError(event, peerId) {
        const self = this;
        const peerIndex = _.findIndex(this.ws_conns, (o) => peerId == o.guid);
        if (self.isDebug) {
            this.setStatus('Unable to connect to server, did you add an exception for the certificate?', { id: peerId });
        }
        // Retry after 3 seconds
        this.$window.setTimeout(this.websocketServerConnect.bind(this, peerId), 3000);
    }

    websocketServerConnect(peerId) {
        const self = this;

        const peerIndex = _.findIndex(self.ws_conns, (o) => o.guid == peerId);
        console.log('PeerIndex', peerIndex);
        if (peerIndex > -1) {
            const peer = this.ws_conns[peerIndex];
            peer.connect_attempts++;
            if (peer.connect_attempts > 3) {
                self.setStatus('Error Connecting. If problem persists, contact support.', peer);
                self.removeConn(peerId);
                return;
            }
            let loc = null;
            if (self.$window.location.protocol.startsWith('file')) {
                loc = '127.0.0.1';
            } else if (self.$window.location.protocol.startsWith('http') || self.$window.location.protocol.startsWith('https')) {
                loc = 'signal.jerichosystems.co.za';
            } else {
                throw new Error(`Don't know how to connect to the signalling server with uri${window.location}`);
            }
            const webSock = `wss://${loc}:8443`;

            peer.ws = new WebSocket(webSock);
            peer.debugStatus = 'Registering with server';

            peer.ws.addEventListener('open', (event) => {
                self.ws_conns[peerIndex].ws.send(`HELLO ${peerId}`);
                self.setStatus('Stream initialising', peer);
            });

            self.ws_conns[peerIndex].ws.addEventListener('error', self.onServerError.bind(self, peerId));
            self.ws_conns[peerIndex].ws.addEventListener('close', self.onServerClose.bind(self, peerId));
            self.ws_conns[peerIndex].ws.addEventListener('message', self.onServerMessage.bind(self, peerId));
        }
    }

    sendCameraStatUpdate(stream, stat) {
        const self = this;
        const widget = self.currentDash.widgets.find((o) => o.livestream.guid === stream.guid);
        if (widget) {
            self.$http.put(`/api/cameras/updateStreamStats/${stream.guid}`,
                {
                    framesReceived: stat.framesDecoded,
                    bytesUsed: stat.bytesReceived,
                    siteId: widget.siteId,
                    siteName: widget.siteName,
                    accountId: self.Auth.getCurrentAccountSync().accountId,
                    unitId: widget.unit,
                    zoneId: widget._id,
                    zoneAlias: widget.alias,
                    source: 'Dashboard',
                })
                .then((response) => {
                    if (response.status === 200) {
                        console.log('Stat update successful');
                        stream.lastUpdate = moment();
                    }
                });
        } else {
            console.log('No widget');
        }
    }

    updateStreamStats(stream, track) {
        const self = this;
        const peer = self.ws_conns.find((o) => o.guid === stream.guid);
        if (!stream.lastUpdate) {
            stream.lastUpdate = moment();
        }
        const timeElapsed = moment().isAfter(stream.lastUpdate.clone().add(statUpdate.count, statUpdate.unit));
        if (peer && peer.pc) {
            peer.pc.getStats(track).then((report) => {
                report.forEach((o) => {
                    if (o.type === 'inbound-rtp' && o.kind === 'video') {
                        if (o.framesDecoded && (stream.stats && stream.stats.framesDecoded !== o.framesDecoded)) {
                            if (!stream.active || timeElapsed) {
                                self.sendCameraStatUpdate(stream, o);
                            }
                            stream.active = true;
                            stream.inactiveCounter = 0;
                            self.setStatus('Stream running', peer);
                        } else {
                            if (!stream.inactiveCounter) {
                                stream.inactiveCounter = 0;
                            }
                            stream.inactiveCounter++;
                            // if (stream.inactiveCounter >= INACTIVE_STREAM_SECONDS) {
                            //     stream.active = false;
                            //     self.setStatus('Stream inactive', peer);
                            // }
                        }
                        stream.stats = o;
                    }
                } );
            } );
        } else {
            stream.active = false;
            if (stream.statsUpdater) {
                clearInterval(stream.statsUpdater);
                Reflect.deleteProperty(stream,'statsUpdater');
            }
        }
    }

    onRemoteStreamAdded(peerId, event) {
        const self = this;
        const videoTracks = event.stream.getVideoTracks();
        const peerIndex = _.findIndex(self.ws_conns, (o) => o.guid === peerId);

        if (videoTracks.length > 0) {
            console.log(`Incoming stream: ${videoTracks.length} video tracks and `);
            self.setStatus('Viewing Stream', this.ws_conns[peerIndex]);
            this.getVideoElement(peerId).srcObject = event.stream;
            const currentWidget = self.currentDash.widgets.find((o) => o.livestream.guid === peerId);
            if (currentWidget) {
                currentWidget.livestream.statsUpdater = setInterval(self.updateStreamStats.bind(self, currentWidget.livestream, videoTracks[0]), 1000);
            }
        } else {
            this.handleIncomingError('Stream with unknown tracks added, resetting');
        }
    }

    errorUserMediaHandler() {
        console.log("Browser doesn't support getUserMedia!");
    }

    createCall(msg, peer) {
        const self = this;
        // Reset connection attempts because we connected successfully
        peer.connect_attempts = 0;

        peer.pc = new RTCPeerConnection(this.rtc_configuration);

        if (peer.pc.addTrack !== undefined) {
            peer.pc.ontrack = (ev) => {
                ev.streams.forEach((stream) => this.onRemoteStreamAdded(peer.guid,{ stream }));
            };
        } else {
            peer.pc.onaddstream = this.onRemoteStreamAdded.bind(this, peer.guid);
        }

        /* Send our video/audio to the other peer */

        if (!msg.sdp) {
            console.error('WARNING: First message wasn\'t an SDP message!?');
        }

        peer.pc.onicecandidate = (event) => {
            // We have a candidate, send it to the remote party with the same uuid
            if (event.candidate !== null) {
                peer.ws.send(JSON.stringify({ ice: event.candidate }));
            } else {
                console.log('ICE Candidate was null, done');
                console.log('THIS IS THE EVENT: ', event);
            }
        };

        if (self.isDebug) {
            this.setStatus('Created peer connection for call, waiting for SDP', peer);
        }
    }
/** ************************************************************************************************************** */
}

export default angular.module('cameraViewerApp.dashboard')
    .component('dashboard', {
        template: require('./dashboard.html'),
        controller: DashboardComponent,
        controllerAs: '$ctrl',
    })
    .name;
