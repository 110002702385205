"use strict";

angular.module('SmartAdmin.Layout').directive('toggleMenu', function() {
    return {
        restrict: 'A',
        link(scope, element) {
            var $body = $('body');

            var toggleMenu = function() {
                if (!$body.hasClass("menu-on-top")) {
                    $('html').toggleClass("hidden-menu-mobile-lock");
                    $body.toggleClass("hidden-menu");
                    $body.removeClass("minified");
                } else if ( $body.hasClass("menu-on-top") && $body.hasClass("mobile-view-activated") ) {
                    $('html').toggleClass("hidden-menu-mobile-lock");
                    $body.toggleClass("hidden-menu");
                    $body.removeClass("minified");
                }
            };

            element.on('click', toggleMenu);

            scope.$on('requestToggleMenu', function() {
                toggleMenu();
            });
        }
    };
});
