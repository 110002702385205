import angular from 'angular';

angular.module('cameraViewerApp.directives')
    .directive('toMinutes', ['$document',
        function ($document) {
            return {
                restrict: 'A',
                require: 'ngModel',
                link(scope, el, attrs, modelCtrl) {
                    // Changes the ng-model from variable to minutes
                    modelCtrl.$parsers.push((modelValue) => {
                        if (attrs.toMinutes == 'Hours') {
                            return modelValue * 60;
                        } if (attrs.toMinutes == 'Days') {
                            return modelValue * 60 * 24;
                        } if (attrs.toMinutes == 'Minutes') {
                            return modelValue;
                        }
                    });
                    // Changes the ng-model from minutes to variable
                    modelCtrl.$formatters.push((modelValue) => {
                        if (attrs.toMinutes == 'Hours') {
                            return Math.ceil(modelValue / 60);
                        } if (attrs.toMinutes == 'Days') {
                            return Math.ceil(modelValue / 60 / 24);
                        } if (attrs.toMinutes == 'Minutes') {
                            return modelValue;
                        }
                    });
                },
            };
        },
    ]);
