export function routerDecorator($rootScope, $state, Auth, $transitions, socket, $q, $cookies) {
    'ngInject';

    // Redirect to login if route requires auth and the user is not logged in, or doesn't have required role
    $rootScope.$on('$stateChangeError', (e, toState, toParams, fromState, fromParams, error) => {
        console.log('State Changed Error ', error);
    });
    $state.defaultErrorHandler((err) => {
        console.log('State Error', err);
    });

    $transitions.onBefore({}, (trans) => {
        const $state = trans.router.stateService;
        // console.log("transitions", trans);
        // console.log("transitions to", trans.to());
        // console.log("transitions from", trans.from());
        // console.log("transitions to auth", trans.to().authenticate);
        // console.log("Socket Connection", socket);
        // console.log("THIS IS GOING INTO COOKIES: ", trans.to().name);
        if (trans.to().name !== 'login' && trans.from().name !== '') {
            $cookies.put('lastPosition', trans.to().name);
        }
        const socketPromise = function () {
            return $q((resolve, reject) => {
                // console.log("In Socket Promise");
                if (socket.socket.connected) {
                    // console.log("Socket Connected");
                    resolve(true);
                } else {
                    // console.log("Socket Not Connected Waiting...");
                    socket.socket.on('connect', (socket) => {
                        // console.log("CALLING ON CONNECTION");
                        resolve(true);
                    });
                }
            });
        };
        if (trans.to().authenticate && typeof trans.to().authenticate === 'string') {
            return Auth.hasRole(`secuvue.${trans.to().authenticate}`)
                .then((has) => {
                    if (has) {
                        if (!trans.params().accountID) {
                            return $state.target(trans.to().name, { accountID: Auth.getCurrentAccountSync().accountId });
                        }
                        return socketPromise();
                    }
                    return $state.target(Auth.getLandingPage());
                });
        } if (trans.to().authenticate) {
            // console.log("Call is logged in  On Before");
            return Auth.isLoggedIn()
                .then((is) => {
                    // console.log("In is logged in return : ", is);
                    if (is) {
                        if (!trans.params().accountID) {
                            return $state.target(trans.to().name, { accountID: Auth.getCurrentAccountSync().accountId });
                        }
                        return socketPromise();
                    }
                    // console.log("Go to login");
                    return $state.target('login');
                });
        }
        return true;
    });

    // function (Auth, $state) {
    //     console.log('In resolve function', $state);
    //     return Auth.hasPrivilege('secuvue.dashboard')
    //         .then((has) => {
    //             console.log('Resolved wait function', has);
    //             if (has) {
    //                 return has;
    //             } else {
    //                 console.log('State go');
    //                 event.preventDefault();
    //                 $state.go('main.live')
    //                     .catch((err) => {
    //                         console.log(err);
    //                     });
    //                 return has;
    //             }
    //         });
    // }
    //
    // $rootScope.$on('$stateChangeStart', function (event, next, nextParams) {
    //     if (!next.authenticate) {
    //         return;
    //     }
    //     Auth.isLoggedIn()
    //         .then(is => {
    //             if (is) {
    //                 return;
    //             }
    //             event.preventDefault();
    //             $state.go('main.login')
    //                 .catch((err) => {
    //                     //console.log("State.go(login) catch error")
    //                 });
    //         });
    // });
}
