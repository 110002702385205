import _ from 'lodash';
import angular from 'angular';
import AsideController from './aside/aside.controller';
import SnapshotModalController from '../live/modal.controller';
import SettingsController from './settings/settings.controller';
import BatchSetupController from './batch-setups/batch-setups.controller';

export class SitesComponent {
    $state;
    $http;
    $scope;
    $aside;
    $document;
    moment;
    paused;
    reasons = [];
    pauseBuffer;
    $uibModal;
    $rootScope;
    Auth;
    currentSite = undefined;
    availableSites = [];

    snapshots = [];
    snapshotDataSource = {};
    snapshotAdapter = { adapter: { remain: true } };

    analyticsLabels = [];
    indexLabelsPos = [];
    indexLabelsNeg = [];
    awsFilter = [];

    lastFilter = {
        sites: [],
        startDate: undefined,
        startTime: undefined,
        endDate: undefined,
        endTime: undefined,
        onlyFaces: undefined,
        onlyVehicles: undefined,
        onlyXFS: undefined,
        onlyPeople: undefined,
        onlyPoses: undefined,
        onlyObjects: undefined,
        onlyPlates: undefined,
        onlyRecog: undefined,
        gender: undefined,
        beard: undefined,
        age: undefined,
        emotionFilter: undefined,
        eyeglasses: undefined,
        sunglasses: undefined,
        mustache: undefined,
        eyesOpen: undefined,
        mouthOpen: undefined,
        smile: undefined,
    };

    startDT;
    endDT;
    filter = [];
    self;
    $ngConfirm;

    /* @ngInject */
    constructor(
        Auth,
        moment,
        $state,
        $scope,
        $http,
        $aside,
        $document,
        $rootScope,
        socket,
        $uibModal,
        toastr,
        $ngConfirm,
        unitService,
        liveStreamService
    ) {
        this.$uibModal = $uibModal;
        this.$state = $state;
        this.moment = moment;
        this.unitService = unitService;
        this.pauseBuffer = [];
        this.paused = false;
        this.$aside = $aside;
        this.snapPlaceholder = [];
        this.$scope = $scope;
        this.socket = socket;
        this.$rootScope = $rootScope;
        this.$document = $document;
        this.$http = $http;
        this.Auth = Auth;
        this.currentRooms = [];
        this.metadataTemplates = [];
        this.regionOptions = [];
        this.toastr = toastr;
        this.activeRelays = 0;
        this.$ngConfirm = $ngConfirm;
        this.liveStreamService = liveStreamService;
        this.currentUser = this.Auth.getCurrentUserSync();

        this.startDT = undefined;
        this.endDT = undefined;
    }

    $onDestroy() {
        const self = this;

        self.socket.socket.removeListener(self.snapshotListener);
        self.socket.unsyncUpdates('site', 'sites:configsites');
        if (self.currentRooms.length > 0) {
            self.currentRooms.forEach((room) => {
                self.socket.leaveRoom(room);
            });
        }
    }

    $onInit() {
        const self = this;

        this.snapshotDataSource.get = this.getSnapshots.bind(this);

        self.$http
            .get('/api/accounts/metadataTemplates')
            .then((res) => {
                if (res.data) {
                    self.metadataTemplates = res.data;
                    const regionTemplate = self.metadataTemplates?.find(
                        (option) => option.key === 'Region'
                    );
                    if (regionTemplate) {
                        self.regionOptions = regionTemplate.options;
                        self.regionOptions.unshift('All Regions');
                        self.regionOptions.push('No Region');
                        self.selectedRegion = self.regionOptions[0];
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            });

        self.Auth.hasPrivilege('secuvue.site.index').then((has) => {
            if (has) {
                // TODO think if we need this maybe (never worked)
                // self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().accountId}:*:sites`);
                // self.currentRooms.push(`${self.Auth.getCurrentAccountSync().accountId}:*:sites`);
                self.populateSites();
            } else {
                /* self.toastr.info(`You require secuvue.site.configuration privileges`, 'Unprivileged : ',{ */
                /* preventOpenDuplicates:true */
                /* }) */
            }
        });

        self.snapshotListener = function (item) {
            // console.log("SNAPSHOT FOUND: ", item);
            let inPlace = false;
            const thistime = self.moment(+item.timestamp).utc();
            const camIndex = _.findIndex(self.filter, (cam) => cam._id === item.camera);

            let tempEnd = self.endDT;
            let tempStart = self.startDT;

            if (!self.endDT) {
                tempEnd = self.moment().utc().add(1, 'y');
            }
            if (!self.startDT) {
                tempStart = self.moment(0).utc();
            }

            let reasonIndex;
            if (self.reasons && self.reasons.length > 0) {
                reasonIndex = _.findIndex(self.reasons, (obj) => obj === item.reason);
            }

            let letEmit;
            if (self.onlyPeople) {
                letEmit = item.people > 0;
            }
            if (!letEmit && self.onlyPoses) {
                letEmit = item.humanPoseResults && item.humanPoseResults.length > 0 || item.poses > 0;
            }
            // TODO: DO local SSD Results
            if (!letEmit && self.onlyVehicles) {
                letEmit = item.vehicles > 0;
            }
            if (!letEmit && self.onlyXFS) {
                letEmit = item.xfsEvents && true;
            }
            if (!letEmit && self.onlyFaces) {
                letEmit = item.faces > 0;
            }
            if (!letEmit && self.onlyObjects) {
                letEmit = item.ssds > 0;
            }
            if (!letEmit && self.onlyPlates) {
                letEmit = item.lprResults && item.lprResults.length > 0;
            }
            if (!letEmit && self.onlyRecog) {
                letEmit = item.identities && item.identities.length > 0;
            }
            if (!letEmit && reasonIndex) {
                letEmit = reasonIndex === -1;
            }

            if (
                !self.currentSite ||
                (self.currentSite && self.currentSite._id !== item.site) ||
                letEmit === false ||
                thistime.isBefore(tempStart) ||
                thistime.isAfter(tempEnd) ||
                (self.filter && self.filter.length > 0 && camIndex === -1)
            ) {
                return;
            }
            if (item.indexLabels && self.lastFilter) {
                if (self.lastFilter.beard === false) {
                    if (item.indexLabels.includes('beard')) {
                        return;
                    }
                } else if (self.lastFilter.beard === true) {
                    if (!item.indexLabels.includes('beard')) {
                        return;
                    }
                }
                if (self.lastFilter.smile === false) {
                    if (item.indexLabels.includes('smile')) {
                        return;
                    }
                } else if (self.lastFilter.smile === true) {
                    if (!item.indexLabels.includes('smile')) {
                        return;
                    }
                }
                if (self.lastFilter.eyeglasses === false) {
                    if (item.indexLabels.includes('eyeglasses')) {
                        return;
                    }
                } else if (self.lastFilter.eyeglasses === true) {
                    if (!item.indexLabels.includes('eyeglasses')) {
                        return;
                    }
                }
                if (self.lastFilter.sunglasses === false) {
                    if (item.indexLabels.includes('sunglasses')) {
                        return;
                    }
                } else if (self.lastFilter.sunglasses === true) {
                    if (!item.indexLabels.includes('sunglasses')) {
                        return;
                    }
                }
                if (self.lastFilter.mouthOpen === false) {
                    if (item.indexLabels.includes('mouthOpen')) {
                        return;
                    }
                } else if (self.lastFilter.mouthOpen === true) {
                    if (!item.indexLabels.includes('mouthOpen')) {
                        return;
                    }
                }
                if (self.lastFilter.eyesOpen === false) {
                    if (item.indexLabels.includes('eyesOpen')) {
                        return;
                    }
                } else if (self.lastFilter.eyesOpen === true) {
                    if (!item.indexLabels.includes('eyesOpen')) {
                        return;
                    }
                }

                if (self.lastFilter.mustache === false) {
                    if (item.indexLabels.includes('mustache')) {
                        return;
                    }
                } else if (self.lastFilter.mustache === true) {
                    if (!item.indexLabels.includes('mustache')) {
                        return;
                    }
                }

                if (self.lastFilter.gender === 'male') {
                    if (!item.indexLabels.includes('male')) {
                        return;
                    }
                } else if (self.lastFilter.gender === 'female') {
                    if (!item.indexLabels.includes('female')) {
                        return;
                    }
                }

                if (self.lastFilter.emotionFilter) {
                    const retVal = self.lastFilter.emotionFilter.some((em) =>
                        item.indexLabels.includes(em)
                    );

                    if (!retVal) {
                        return;
                    }
                }

                if (self.lastFilter.age) {
                    const ageIndex = _.findIndex(
                        item.indexLabels,
                        (o) => o.low <= self.lastFilter.age && o.high >= self.lastFilter.age
                    );

                    if (ageIndex === -1) {
                        return;
                    }
                }
            } else if (
                self.lastFilter.beard ||
                self.lastFilter.age ||
                self.lastFilter.mustache ||
                self.lastFilter.emotionFilter ||
                self.lastFilter.gender ||
                self.lastFilter.eyesOpen ||
                self.lastFilter.mouthOpen ||
                self.lastFilter.smile ||
                self.lastFilter.eyeglasses ||
                self.lastFilter.sunglasses
            ) {
                return;
            }

            self.handleBuddyEvents(item);

            self.snapshotAdapter.applyUpdates((obj, scope, element) => {
                if (obj._id.toString() === item._id.toString()) {
                    inPlace = true;
                    obj.identities = item.identities;
                    obj.humanPoseResults = item.humanPoseResults;
                    obj.faceRecallDone = item.faceRecallDone;
                    obj.openvinoPeopleDetections = item.openvinoPeopleDetections;
                    obj.peopleDetectionDone = item.peopleDetectionDone;
                    obj.openvinoVehicleDetections = item.openvinoVehicleDetections;
                    obj.vehicleDetectionDone = item.vehicleDetectionDone;
                    obj.openvinoDone = item.openvinoDone;
                    obj.openvinoResults = item.openvinoResults;
                    obj.humanPoseDone = item.humanPoseDone;
                    obj.humanPoseResults = item.humanPoseResults;
                    obj.faceRecognitions = item.faceRecognitions;
                    obj.lprResults = item.lprResults;
                    obj.keyThumbnail = item.keyThumbnail;
                    obj.dataThumbnail = item.dataThumbnail;
                    obj.thumbSize = item.thumbSize;
                }
            });

            if (!inPlace && !self.paused) {
                self.snapshotAdapter.prepend([item]);
                if (self.snapPlaceholder[0]) {
                    self.snapPlaceholder[0].first = true;
                }
                item.first = true;
                self.snapPlaceholder.unshift(item);
            } else if (!inPlace && self.paused) {
                self.pauseBuffer.push(item);
            }
        };

        self.thumbnailListener = function (item) {
            // console.debug(`Got thumbnail update for ${item._id} (${item.sitename}:${item.cameraname})`);
            // apply the changes
            self.snapshotAdapter.applyUpdates((obj, scope, element) => {
                if (obj._id.toString() === item._id.toString()) {
                    obj.keyThumbnail = item.keyThumbnail;
                    obj.dataThumbnail = item.dataThumbnail;
                    obj.thumbSize = item.thumbSize;
                }
            });
        };

        self.socket.socket.on('snapshot:save', self.snapshotListener);
        self.socket.socket.on('snapshot:thumbnail', self.thumbnailListener);
    }

    debug(val) {
        console.log('DEBUG', val);
    }

    populateSites() {
        let self = this;
        this.$http.get('/api/sites/lite').then(
            (response) => {
                self.availableSites = response.data;
                if (self.selectedRegion) {
                    if (self.selectedRegion !== 'All Regions') {
                        const filterTarget = self.selectedRegion === 'No Region' ? undefined : self.selectedRegion;
                        self.availableSites = _.filter(
                            self.availableSites, (o) => o.region === filterTarget
                        );
                    }
                }
                self.socket.syncUpdates(
                    'site',
                    self.availableSites,
                    (event, item, array) => {
                        if (self.currentSite && item._id === self.currentSite._id) {
                            self.currentSite = item;
                        }
                        if (
                            event === 'deleted' &&
                            self.currentSite &&
                            item._id === self.currentSite._id
                        ) {
                            self.currentSite = '';
                        }
                    },
                    'sites:configsites'
                );
                if (self.$state.params.id) {
                    this.$http.get(`/api/sites/${self.$state.params.id}`).then((site) => {
                        self.currentSite = site.data;
                        if (self.currentSite) {
                            if (this.Auth.hasPrivilegeSync('secuvue.snapshot.index')) {
                                self.socket.joinRoom(
                                    `${self.currentSite.accountId}:${self.currentSite._id}:*:snapshots`
                                );
                                self.currentRooms.push(
                                    `${self.currentSite.accountId}:${self.currentSite._id}:*:snapshots`
                                );
                            } else {
                                /* self.toastr.info(`You require secuvue.snapshot privileges`, 'Unprivileged : ',{ */
                                /* preventOpenDuplicates:true */
                                /* }); */
                            }
                            self.checkRelays();
                            if (self.$state.params.filter) {
                                const filteredRooms = _.filter(this.currentRooms, (room) =>
                                    /:snapshots$/.test(room)
                                );
                                if (filteredRooms.length > 0) {
                                    filteredRooms.forEach((room) => {
                                        self.socket.leaveRoom(room);
                                    });
                                }
                                self.$state.params.filter.forEach((obj) => {
                                    // if (self.Auth.hasPrivilegeSync('secuvue.secuvue.snapshot')) {
                                    //     this.socket.socket.emit('join', {
                                    //         room: `${self.Auth.getCurrentAccountSync().accountId}:${self.currentSite._id}:${obj}:snapshots`
                                    //     });
                                    //     this.currentRooms.push(`${self.Auth.getCurrentAccountSync().accountId}:${self.currentSite._id}:${obj}:snapshots`);
                                    // } else {
                                    //     if (this.Auth.isLoggedInSync()) {
                                    //         self.toastr.info(`You require secuvue.snapshot privileges`, 'Unprivileged : ', {
                                    //             preventOpenDuplicates: true
                                    //         });
                                    //     }
                                    // }
                                    self.filter.push(obj);
                                    self.lastFilter.sites.push(obj);
                                });
                                self.refreshFilter();
                            } else if (this.Auth.hasPrivilegeSync('secuvue.camera.index')) {
                                self.filter = [];
                                self.refreshFilter();
                            }
                        }
                    });
                }
            },
            (err) => {
                if (err.status !== 401 && err.status !== 403) console.error(err);
            }
        );
    }

    handleBuddyEvents(item) {
        if (item.buddyEvents && item.buddyEvents.length > 0) {
            item.buddyLineDescript = '';
            item.buddyMotionDescript = '';
            item.buddyEvents.forEach((bud) => {
                if (bud.type === 'Motion') {
                    item.buddyMotionDescript = `${item.buddyMotionDescript} ${bud.zoneAlias},`;
                } else if (bud.type === 'LineCross') {
                    if (bud.AToB) {
                        item.buddyLineDescript = `${item.buddyLineDescript} ${bud.lineAlias} A->B (${bud.zoneAlias}),`;
                    } else {
                        item.buddyLineDescript = `${item.buddyLineDescript} ${bud.lineAlias} B->A (${bud.zoneAlias}),`;
                    }
                }
            });
            if (item.buddyMotionDescript.length > 0) {
                item.buddyMotionDescript = item.buddyMotionDescript.slice(0, -1);
            } else {
                delete item.buddyMotionDescript;
            }
            if (item.buddyLineDescript.length > 0) {
                item.buddyLineDescript = item.buddyLineDescript.slice(0, -1);
            } else {
                delete item.buddyLineDescript;
            }
        }
    }

    getSnapshots(descriptor, success) {
        const self = this;
        let tempFilter = [];
        if (self.filter && self.filter.length > 0) {
            self.filter.forEach((cam) => {
                if (!cam.disabled) {
                    tempFilter.push(cam._id);
                }
            });
        }
        if (tempFilter.length === 0) {
            tempFilter = undefined;
        }
        if (this.Auth.hasPrivilegeSync('secuvue.snapshot.index')) {
            if (!this.currentSite) {
                return success([]);
            }
            // Initial load
            if (!descriptor.append && !descriptor.prepend) {
                this.$http
                    .get('/api/snapshots', {
                        params: {
                            sites: [this.currentSite._id],
                            reasons: self.reasons,
                            pagesize: descriptor.count,
                            before: self.endDT ? +self.moment(self.endDT).utc() : undefined,
                            after: self.startDT ? +self.moment(self.startDT).utc() : undefined,
                            filter: tempFilter,
                            onlyRecog: self.onlyRecog,
                            onlyPlates: self.onlyPlates,
                            onlyFaces: self.onlyFaces,
                            onlyVehicles: self.onlyVehicle,
                            onlyPeople: self.onlyPeople,
                            onlyPoses: self.onlyPoses,
                            analyticsLabels: self.analyticsLabels,
                            indexLabelsPos: self.indexLabelsPos,
                            indexLabelsNeg: self.indexLabelsNeg,
                            age: self.age,
                            emotionFilter: self.emotionFilter,
                        },
                    })
                    .then(
                        (response) => {
                            self.snapPlaceholder = _.unionBy(
                                self.snapPlaceholder,
                                response.data,
                                '_id'
                            );
                            response.data.forEach((item) => {
                                self.handleBuddyEvents(item);
                            });
                            success(_.uniqBy(response.data, '_id'));
                            //   this.socket.syncUpdates('snapshot', this.snapshots, angular.noop, false);
                        },
                        (err) => {
                            if (err.status !== 401 && err.status !== 403) {
                                if (err.status === 404) {
                                    if (err.data.type && err.data.type === 'NoCameras') {
                                        self.toastr.warning(
                                            'This site has no cameras',
                                            'No Snapshots',
                                            {
                                                preventOpenDuplicates: true,
                                            }
                                        );
                                        success([]);
                                    }
                                }
                                console.error(err);
                            }
                        }
                    );
            } else if (descriptor.append) {
                const timestamp = +self.moment(+descriptor.append.timestamp).utc();
                this.$http
                    .get('/api/snapshots', {
                        params: {
                            sites: [self.currentSite._id],
                            pagesize: descriptor.count,
                            reasons: self.reasons,
                            after: self.startDT ? +self.moment(self.startDT).utc() : undefined,
                            before: timestamp,
                            filter: tempFilter,
                            onlyRecog: self.onlyRecog,
                            onlyPlates: self.onlyPlates,
                            onlyFaces: self.onlyFaces,
                            onlyPeople: self.onlyPeople,
                            onlyPoses: self.onlyPoses,
                            onlyVehicles: self.onlyVehicles,
                            analyticsLabels: self.analyticsLabels,
                            indexLabelsPos: self.indexLabelsPos,
                            indexLabelsNeg: self.indexLabelsNeg,
                            age: self.age,
                            emotionFilter: self.emotionFilter,
                            sort: { timestamp: -1 },
                        },
                    })
                    .then(
                        (response) => {
                            self.snapPlaceholder = _.unionBy(
                                self.snapPlaceholder,
                                response.data,
                                '_id'
                            );
                            response.data.forEach((item) => {
                                self.handleBuddyEvents(item);
                            });
                            success(_.uniqBy(response.data, '_id'));
                        },
                        (err) => {
                            if (err.status !== 401 && err.status !== 403) {
                                if (err.status === 404) {
                                    if (err.data.type && err.data.type === 'NoCameras') {
                                        self.toastr.warning(
                                            'This site has no cameras',
                                            'No Snapshots',
                                            {
                                                preventOpenDuplicates: true,
                                            }
                                        );
                                        success([]);
                                    }
                                }
                                console.error(err);
                            }
                        }
                    );
                // Add to end of descriptor
            } else {
                const timestamp = +self.moment(+descriptor.prepend.timestamp).utc();
                this.$http
                    .get('/api/snapshots', {
                        params: {
                            sites: [self.currentSite._id],
                            reasons: self.reasons,
                            pagesize: descriptor.count,
                            after: timestamp,
                            before: self.endDT ? +self.moment(self.endDT).utc() : undefined,
                            filter: tempFilter,
                            onlyRecog: self.onlyRecog,
                            onlyPlates: self.onlyPlates,
                            onlyFaces: self.onlyFaces,
                            onlyVehicles: self.onlyVehicles,
                            onlyPeople: self.onlyPeople,
                            onlyPoses: self.onlyPoses,
                            analyticsLabels: self.analyticsLabels,
                            indexLabelsPos: self.indexLabelsPos,
                            indexLabelsNeg: self.indexLabelsNeg,
                            age: self.age,
                            emotionFilter: self.emotionFilter,
                            sort: { timestamp: -1 },
                        },
                    })
                    .then(
                        (response) => {
                            response.data.forEach((item) => {
                                self.handleBuddyEvents(item);
                            });
                            if (response.data.length === 1) {
                                const ind = _.findIndex(
                                    self.snapPlaceholder,
                                    (o) => o._id === response.data[0]._id
                                );
                                if (ind === -1) {
                                    success(_.uniqBy(response.data, '_id'));
                                } else {
                                    success([]);
                                }
                            } else {
                                success(_.uniqBy(response.data, '_id'));
                            }
                        },
                        (err) => {
                            if (err.status !== 401 && err.status !== 403) {
                                if (err.status === 404) {
                                    if (err.data.type && err.data.type === 'NoCameras') {
                                        self.toastr.warning(
                                            'This site has no cameras',
                                            'No Snapshots',
                                            {
                                                preventOpenDuplicates: true,
                                            }
                                        );
                                        success([]);
                                    }
                                }
                                console.error(err);
                            }
                        }
                    );
                // Add to start of descriptor
            }
        }
    }

    sortByTimestamp() {
        angular.copy(_.orderBy(this.snapshots, ['timestamp'], ['desc']), this.snapshots);
    }

    refreshFilter() {
        this.snapshotAdapter.reload();
    }

    formatDate(date) {
        const self = this;
        return self.moment(date).local().format('ll LTS');
    }

    pauseSite() {
        const self = this;
        self.paused = true;
    }

    resumeSite() {
        const self = this;
        for (let i = 0; i < self.pauseBuffer.length; i++) {
            self.snapshotAdapter.prepend([self.pauseBuffer[i]]);
        }
        self.pauseBuffer = [];
        self.paused = false;
    }

    requestSnapshot(camera) {
        const self = this;
        this.$http.post(`/api/cameras/${camera}/requestSnapshot`, {}).then(
            () => {},
            (err) => {
                if (err.status !== 401 && err.status !== 403) {
                    const zone = _.find(self.currentSite.zones, (o) => o._id === camera);
                    if (err.status === 400) {
                        if (err.data.reason === 'ZoneDown') {
                            self.toastr.info(
                                `The camera at ${zone.alias} is down`,
                                'Zone Down : ',
                                { preventOpenDuplicates: true }
                            );
                        } else {
                            self.toastr.info(`The unit at ${zone.alias} is down`, 'Unit Down : ', {
                                preventOpenDuplicates: true,
                            });
                        }
                    } else if (err.status === 404) {
                        self.toastr.info(
                            `${zone.alias} has no camera assigned`,
                            'Zone Not Assigned: ',
                            { preventOpenDuplicates: true }
                        );
                    }
                }
            }
        );
    }

    requestAllSnapshots() {
        const self = this;
        self.currentSite.zones.forEach((zone) => {
            if (zone.camera !== 'UNBOUNDED') {
                let unit;
                if (zone.unit.hasOwnProperty('_id')) {
                    unit = _.find(self.currentSite.units, (o) => o._id === zone.unit._id);
                } else {
                    unit = _.find(self.currentSite.units, (o) => o._id === zone.unit);
                }
                if (unit) {
                    const cam = _.find(unit.cameras, (o) => o._id === zone.camera);
                    if (cam && cam.enabled) {
                        self.requestSnapshot(zone._id);
                    }
                }
            }
        });
    }

    initiateStream(card) {
        const self = this;
        const camera = {
            id: card.camera,
            name: card.cameraname,
            account: card.account,
            site: card.site,
            siteName: card.sitename,
            unit: card.unit,
            source: 'SiteView',
            user: self.currentUser._id,
            userName: self.currentUser.name,
        };
        self.liveStreamService.addStream(camera);
    }

    settingsOpen(jumpTo) {
        const self = this;

        this.$uibModal
            .open({
                template: require('./settings/settings.html'),
                // placement: 'left',
                backdrop: 'static',
                keyboard: false,
                size: 'xlg',
                controller: SettingsController,
                controllerAs: '$ctrl',
                resolve: {
                    currentSite() {
                        return self.currentSite;
                    },
                    jumpToZone() {
                        return jumpTo;
                    },
                    jumpToUnit() {
                        return undefined;
                    },
                },
            })
            .result.then((result) => {
                if (result.migrate) {
                    const index = _.findIndex(
                        self.availableSites,
                        (o) => o._id === self.currentSite._id
                    );
                    if (index !== -1) {
                        self.availableSites.splice(index, 1);
                    }
                    self.clearAndApply();
                    self.currentSite = undefined;
                } else if (result.edit) {
                    self.editSite(result.edit.event, result.edit.site);
                } else if (result.disabled) {
                    self.toastr.success('Successfully disabled site', {
                        preventOpenDuplicates: true,
                    });
                    const index = _.findIndex(
                        self.availableSites,
                        (o) => o._id === self.currentSite._id
                    );
                    if (index !== -1) {
                        self.availableSites.splice(index, 1);
                    }
                    self.clearAndApply();
                    self.currentSite = undefined;
                }
            });
    }

    clearFilter() {
        const self = this;
        self.lastFilter = {
            sites: [],
            startDate: undefined,
            startTime: undefined,
            reasons: undefined,
            endTime: undefined,
            endDate: undefined,
            onlyPlates: undefined,
            onlyRecog: undefined,
            onlyFaces: undefined,
            onlyPeople: undefined,
            onlyPoses: undefined,
            onlyObjects: undefined,
            onlyVehicles: undefined,
            onlyXFS: undefined,
            beard: undefined,
            gender: undefined,
            age: undefined,
            emotionFilter: undefined,
            eyeglasses: undefined,
            sunglasses: undefined,
            mustache: undefined,
            eyesOpen: undefined,
            mouthOpen: undefined,
            smile: undefined,
        };
    }

    clearAndApply() {
        const self = this;
        self.lastFilter = {
            sites: [],
            startDate: undefined,
            reasons: undefined,
            startTime: undefined,
            endTime: undefined,
            endDate: undefined,
            onlyPlates: undefined,
            onlyRecog: undefined,
            onlyFaces: undefined,
            onlyPeople: undefined,
            onlyPoses: undefined,
            onlyObjects: undefined,
            onlyVehicles: undefined,
            onlyXFS: undefined,
            beard: undefined,
            gender: undefined,
            age: undefined,
            emotionFilter: undefined,
            eyeglasses: undefined,
            sunglasses: undefined,
            mustache: undefined,
            eyesOpen: undefined,
            mouthOpen: undefined,
            smile: undefined,
        };
        self.snapPlaceholder = [];
        self.applyFilter(self.lastFilter);
    }

    applyFilter(filter) {
        const self = this;

        self.snapPlaceholder = [];
        self.startDT = filter.startDate;
        self.endDT = filter.endDate;
        // self.filter = [];
        self.onlyFaces = undefined;
        self.onlyPeople = undefined;
        self.onlyPoses = undefined;
        self.onlyObjects = undefined;
        self.onlyVehicles = undefined;
        self.onlyXFS = undefined;
        self.onlyRecog = undefined;
        self.onlyPlates = undefined;
        self.reasons = undefined;

        if (filter.startDate && filter.startTime) {
            self.startDT = self
                .moment(filter.startDate)
                .hours(filter.startTime.getHours())
                .minutes(filter.startTime.getMinutes());
        }
        if (filter.endDate && filter.endTime) {
            self.endDT = self
                .moment(filter.endDate)
                .hours(filter.endTime.getHours())
                .minutes(filter.endTime.getMinutes());
        }
        if (filter.sites && filter.sites.length > 0) {
            self.filter = filter.sites;
        }
        // if(filter.sites && filter.sites.length === 0 || filter.sites === undefined) {
        //     if(self.Auth.hasPrivilegeSync('secuvue.camera.index')) {
        //         self.$http.get('/api/cameras', {params: {site: self.currentSite._id}}).then(response => {
        //             self.filter = [];
        //             _.each(response.data, (cam) => {
        //                 self.filter.push(cam);
        //             });
        //             self.refreshFilter();
        //         },err => {
        //             if(err.status !== 401 && err.status !== 403) console.error(err);
        //         });
        //     }else{
        //         /*self.toastr.info(`You require secuvue.camera.configuration privileges`, 'Unprivileged : ',{*/
        //         /*preventOpenDuplicates:true*/
        //         /*})*/
        //     }
        // }

        self.onlyFaces = filter.onlyFaces;
        self.onlyPeople = filter.onlyPeople;
        self.onlyPoses = filter.onlyPoses;
        self.onlyObjects = filter.onlyObjects;
        self.onlyVehicles = filter.onlyVehicles;
        self.onlyXFS = filter.onlyXFS;
        self.reasons = filter.reasons;
        self.onlyRecog = filter.onlyRecog;
        self.onlyPlates = filter.onlyPlates;

        self.indexLabelsPos = [];
        self.indexLabelsNeg = [];
        self.awsFilter = [];
        if (filter.gender === 'male') {
            self.indexLabelsPos.push('male');
            self.awsFilter.push('Male');
        } else if (filter.gender === 'female') {
            self.indexLabelsPos.push('female');
            self.awsFilter.push('Female');
        }
        if (filter.beard === true) {
            self.indexLabelsPos.push('beard');
            self.awsFilter.push('Beard');
        } else if (filter.beard === false) {
            self.indexLabelsNeg.push('beard');
            self.awsFilter.push('No Beard');
        }
        if (filter.mustache === true) {
            self.indexLabelsPos.push('mustache');
            self.awsFilter.push('Moustache');
        } else if (filter.mustache === false) {
            self.indexLabelsNeg.push('mustache');
            self.awsFilter.push('No Moustache');
        }
        if (filter.eyesOpen === true) {
            self.indexLabelsPos.push('eyesOpen');
            self.awsFilter.push('Eyes Open');
        } else if (filter.eyesOpen === false) {
            self.indexLabelsNeg.push('eyesOpen');
            self.awsFilter.push('Eyes Closed');
        }
        if (filter.mouthOpen === true) {
            self.indexLabelsPos.push('mouthOpen');
            self.awsFilter.push('Mouth Open');
        } else if (filter.mouthOpen === false) {
            self.indexLabelsNeg.push('mouthOpen');
            self.awsFilter.push('Mouth Closed');
        }
        if (filter.eyeglasses === true) {
            self.indexLabelsPos.push('eyeglasses');
            self.awsFilter.push('Glasses');
        } else if (filter.eyeglasses === false) {
            self.indexLabelsNeg.push('eyeglasses');
            self.awsFilter.push('No Glasses');
        }
        if (filter.sunglasses === true) {
            self.indexLabelsPos.push('sunglasses');
            self.awsFilter.push('Sunglasses');
        } else if (filter.sunglasses === false) {
            self.indexLabelsNeg.push('sunglasses');
            self.awsFilter.push('No Sunglasses');
        }
        if (filter.smile === true) {
            self.indexLabelsPos.push('smile');
            self.awsFilter.push('Smiling');
        } else if (filter.smile === false) {
            self.indexLabelsNeg.push('smile');
            self.awsFilter.push('Not Smiling');
        }
        self.age = filter.age;
        if (self.age) {
            self.awsFilter.push(`Age: ${self.age}`);
        }
        self.emotionFilter = filter.emotionFilter;
        if (self.emotionFilter && self.emotionFilter.length > 0) {
            self.emotionFilter.forEach((emotion) => {
                self.awsFilter.push(`${emotion}`);
            });
        }

        self.analyticsLabels = [];
        if (filter.onlyPoses) {
            self.analyticsLabels.push('analyticsCloudPose');
            if (self.Auth.hasRoleSync('secuvue.Analytics.Onboard.HumanPoseDetector')) {
                self.analyticsLabels.push('analyticsEdgePose');
            }
        }
        if (filter.onlyObjects) {
            self.analyticsLabels.push('analyticsCloudObject');
            if (self.Auth.hasRoleSync('secuvue.Analytics.Onboard.SSDDetector')) {
                self.analyticsLabels.push('analyticsEdgeObject');
            }
        }
        if (filter.onlyPeople) {
            self.analyticsLabels.push('analyticsCloudPeople');
            if (self.Auth.hasRoleSync('secuvue.Analytics.Onboard.PeopleDetector')) {
                self.analyticsLabels.push('analyticsEdgePeople');
            }
        }
        if (filter.onlyVehicles) {
            self.analyticsLabels.push('analyticsCloudVehicle');
            if (self.Auth.hasRoleSync('secuvue.Analytics.Onboard.VehicleDetector')) {
                self.analyticsLabels.push('analyticsEdgeVehicle');
            }
        }
        if (filter.onlyXFS) {
            self.analyticsLabels.push('xfsEvent');
        }

        self.refreshFilter();
    }

    asideOpen() {
        const self = this;

        this.$uibModal
            .open({
                template: require('./aside/aside.html'),
                // placement: 'left',
                backdrop: 'static',
                keyboard: true,
                size: 'md',
                controller: AsideController,
                controllerAs: '$ctrl',
                resolve: {
                    lastFilter() {
                        return self.lastFilter;
                    },
                    currentSite() {
                        return self.currentSite;
                    },
                },
            })
            .result.then((result) => {
                if (result) {
                    self.lastFilter = result;
                    return self.applyFilter(result);
                }
            });
    }

    clearRooms() {
        const self = this;
        const filteredRooms = _.filter(self.currentRooms, (room) => /:snapshots$/.test(room));
        if (filteredRooms.length > 0) {
            filteredRooms.forEach((room) => {
                self.socket.leaveRoom(room);
                self.currentRooms.splice(self.currentRooms.indexOf(room), 1);
            });
        }
    }

    selected($item, $model) {
        const self = this;
        this.$state
            .go('.', { id: $item._id }, { notify: false })
            .then((result) => {
                self.$http.get(`/api/sites/${self.$state.params.id}`).then((site) => {
                    self.currentSite = site.data;
                    if (self.paused) {
                        self.resumeSite();
                    }
                    self.clearAndApply();
                    self.clearRooms();
                    if (self.Auth.hasPrivilegeSync('secuvue.snapshot.index')) {
                        self.socket.joinRoom(`${$item.accountId}:${$item._id}:*:snapshots`);
                        self.currentRooms.push(`${$item.accountId}:${$item._id}:*:snapshots`);
                    }
                    self.checkRelays();
                    if (self.Auth.hasPrivilegeSync('secuvue.camera.index')) {
                        self.filter = [];
                        self.refreshFilter();
                    }
                });
            })
            .catch((err) => {
                console.log('State.go(.) catch error', err);
            });
    }

    openSnapshot(snapshot, overlay = undefined) {
        const self = this;
        this.$uibModal
            .open({
                animation: true,
                backdrop: 'static',
                keyboard: true,
                template: require('../live/modal.html'),
                controller: SnapshotModalController,
                controllerAs: '$ctrl',
                size: 'lg',
                resolve: {
                    snapshot() {
                        return snapshot;
                    },
                    snapPlaceholder() {
                        return self.snapPlaceholder;
                    },
                    overlay() {
                        return overlay;
                    },
                },
            })
            .result.then(
                (result) => {
                    if (result && result.newSnap) {
                        self.openSnapshot(result.newSnap, result.overlay);
                    } else if (result.settingsJump) {
                        self.settingsOpen(result.settingsJump);
                    }
                },
                () => {}
            );
    }

    getVideo(card) {
        const modalScope = this.$scope.$new();
        modalScope.getParams = function () {
            return {
                id: card.site,
                cameras: [card.camera],
                date: card.timestamp,
            };
        };
        modalScope.isModal = true;
        const fileManagerModalInstance = this.$uibModal.open({
            animation: true,
            backdrop: 'static',
            keyboard: true,
            template: require('../fileManager/fileManager.html'),
            controller: require('../fileManager/fileManager.component').FileManagerComponent,
            controllerAs: '$ctrl',
            size: 'lg',
            scope: modalScope,
            resolve: {
                getParams() {
                    return {
                        id: card.site,
                        cameras: [card.camera],
                        date: card.timestamp,
                    };
                },
                isModal() {
                    return true;
                },
            },
        });

        modalScope.fileManagerModalInstance = fileManagerModalInstance;

        fileManagerModalInstance.result.then(
            () => {},
            () => {}
        );
    }

    hasMotion(snapshot) {
        return _.find(snapshot.detections, (detection) => detection.detectionType === 'Motion');
    }

    hasFrontalFace(snapshot) {
        let result = _.find(
            snapshot.detections,
            (detection) => detection.detectionType === 'FrontalFace'
        );

        result = result || snapshot?.snapRecognitions?.length || snapshot?.identities?.length;
        return result;
    }

    createNewSite() {
        const self = this;

        self.$http
            .get('/api/sites', {
                params: {
                    params: JSON.stringify([
                        { field: 'initialized', type: 'boolean', value: false },
                    ]),
                },
            })
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    // we have unfinished sites
                    self.uninitializedSites = response.data;
                    self.$ngConfirm({
                        title: '<span style="display:flex; justify-content:center;">Incomplete Sites</span>',
                        theme: 'light',
                        animation: 'top',
                        scope: self.$scope,
                        closeAnimation: 'bottom',
                        escapeKey: false,
                        columnClass: 'col-xs-6 col-xs-offset-3',
                        backgroundDismiss: true,
                        content: `Choose an incomplete site to proceed with <br> (or alternatively create a new site).<br>
                            <ui-select ng-model="$ctrl.selectedSite" theme="bootstrap" my-ui-select grow-open="150px">
                            <ui-select-match placeholder="Select site...">{{$select.selected.alias}}</ui-select-match>
                                <ui-select-choices repeat="site in $ctrl.uninitializedSites track by site._id | filter: $select.search">
                                    <div ng-bind-html="site.alias | highlight: $select.search"></div>
                                </ui-select-choices>
                            </ui-select>`,
                        buttons: {
                            // long hand button definition
                            continue: {
                                text: 'Continue',
                                show: self.uninitializedSites.length,
                                btnClass: 'btn-default',
                                action(scope) {
                                    const scopeSelf = scope.$ctrl;
                                    if (scopeSelf.selectedSite) {
                                        const modalInstance = scopeSelf.$uibModal.open({
                                            component: 'newSite',
                                            backdrop: 'static',
                                            size: 'xlg',
                                            keyboard: false,
                                            resolve: {
                                                isEdit: true,
                                                site: _.cloneDeep(scopeSelf.selectedSite),
                                            },
                                        });
                                        modalInstance.result.then((result) => {
                                            // console.log("We have received the result of an EDIT, what should we do?: ", result);
                                            const testIndex = _.findIndex(
                                                scopeSelf.availableSites,
                                                (o) => o._id === result._id
                                            );
                                            scopeSelf.availableSites[testIndex] = result;
                                        });
                                    } else {
                                        scopeSelf.toastr.info(
                                            '<span>Please make a valid selection from the list of site(s) below in order to continue creating it</span>',
                                            'Select Site',
                                            {
                                                preventOpenDuplicates: true,
                                                allowHtml: true,
                                                timeOut: 10000,
                                                tapToDismiss: true,
                                                progressBar: true,
                                            }
                                        );
                                        return false;
                                        // Clicked on continue, with no selected site
                                        // let modalInstance = self.$uibModal.open({
                                        //     component: 'newSite',
                                        //     backdrop: 'static',
                                        //     size: 'xlg',
                                        //     keyboard: false,
                                        //     resolve: {
                                        //         create: true
                                        //     }
                                        // });
                                        // modalInstance.result.then((result) => {
                                        //
                                        // });
                                    }
                                },
                            },
                            new: {
                                text: 'New Site',
                                btnClass: 'btn-primary',
                                action(scope) {
                                    // Choosing to create a new site
                                    const scopeSelf = scope.$ctrl;
                                    const modalInstance = scopeSelf.$uibModal.open({
                                        component: 'newSite',
                                        backdrop: 'static',
                                        size: 'xlg',
                                        keyboard: false,
                                        resolve: {
                                            create: true,
                                        },
                                    });
                                    modalInstance.result.then(() => {});
                                },
                            },
                        },
                    });
                } else {
                    // We do not have any unfinished sites
                    const modalInstance = self.$uibModal.open({
                        component: 'newSite',
                        backdrop: 'static',
                        size: 'xlg',
                        keyboard: false,
                    });
                    modalInstance.result.then(() => {});
                }
            });
    }

    editSite($event, site) {
        const self = this;

        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
        const modalInstance = self.$uibModal.open({
            component: 'newSite',
            backdrop: 'static',
            size: 'xlg',
            keyboard: false,
            resolve: {
                isEdit: true,
                site: _.cloneDeep(site),
            },
        });
        modalInstance.result.then((result) => {
            // console.log("We have received the result of an EDIT, what should we do? ", result);
            // Reset filter
            self.selected(result);
        });
    }

    doLog() {
        const self = this;
        console.log(self);
    }

    checkRelays() {
        const self = this;
        self.activeRelays = 0;
        self.currentSite.units.forEach((u) => {
            const relay = _.get(u, 'relays[0]');
            if (relay && relay._id && relay.enabled) {
                self.activeRelays++;
            }
        });
    }

    uploadCSV() {
        const self = this;

        self.$uibModal
            .open({
                template: require('./batch-setups/batch-setups.html'),
                // placement: 'left',
                backdrop: 'static',
                // keyboard: true,
                keyboard: false,
                size: 'xlg',
                controller: BatchSetupController,
                controllerAs: '$ctrl',
                resolve: {
                    lastFilter() {
                        return self.lastFilter;
                    },
                    currentSite() {
                        return self.currentSite;
                    },
                },
            })
            .result.then((result) => {
                if (result.migrate) {
                    const index = _.findIndex(
                        self.availableSites,
                        (o) => o._id === self.currentSite._id
                    );
                    if (index !== -1) {
                        self.availableSites.splice(index, 1);
                    }
                    self.clearAndApply();
                    self.currentSite = undefined;
                } else if (result.edit) {
                    self.editSite(result.edit.event, result.edit.site);
                } else if (result.disabled) {
                    self.toastr.success('Successfully disabled site', {
                        preventOpenDuplicates: true,
                    });
                    const index = _.findIndex(
                        self.availableSites,
                        (o) => o._id === self.currentSite._id
                    );
                    if (index !== -1) {
                        self.availableSites.splice(index, 1);
                    }
                    self.clearAndApply();
                    self.currentSite = undefined;
                }
            });
    }
}

export default angular
    .module('cameraViewerApp.sites')
    .component('sites', {
        template: require('./sites.html'),
        controller: SitesComponent,
        controllerAs: '$ctrl',
    })
    .filter(
        'titleCase',
        () =>
            function (input) {
                input = input || '';
                return input.replace(
                    /\w\S*/g,
                    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                );
            }
    ).name;
