import angular from 'angular';

angular.module('cameraViewerApp.units')
    .directive('compileTemplate', ($compile, $parse) => ({
        link(scope, element, attr) {
            const parsed = $parse(attr.ngBindHtml);
            function getStringValue() {
                return (parsed(scope) || '').toString();
            }

            // Recompile if the template changes
            scope.$watch(getStringValue, () => {
                $compile(element, null, -9999)(scope); // The -9999 makes it skip directives so that we do not recompile ourselves
            });
        },
    }));
