/**
 * Costing constants: Pricing options for current Europe Ireland (eu-west-1) as well as future Africa Cape Town (af-south-1) endpoints
 */
const pricingOptions = {
    s3CostIreland: {
        storage_std: 0.023, // per GB
        storage_std_ia: 0.0125, // per GB
        storage_glacier_da: 0.00099, // per GB
        api_put_std: 0.005, // per 1000
        api_put_std_ia: 0.01, // per 1000
        api_get_std: 0.0004, // per 1000
        api_get_std_ia: 0.001, // per 1000
        api_trans_glacier: 0.055, // per 1000
    },
    s3CostCPT: {
        storage_std: 0.0274, // per GB
        storage_std_ia: 0.0125, // per GB
        storage_glacier_da: 0.0018, // per GB
        api_put_std: 0.006, // per 1000
        api_put_std_ia: 0.01, // per 1000
        api_get_std: 0.0004, // per 1000
        api_get_std_ia: 0.001, // per 1000
        api_trans_glacier: 0.06, // per 1000
    },
};

/**
 * Average sizes: Checked from 1 year's worth of snapshots & thumbnails from production Snapshot collection
 */
const thumbnailAvgSize = 0.0000026; // GiB
const imageAvgSize = 0.00012; // GiB

/**
 * Calculates total cost of ownership for a given number of snapshots.
 * It assumes snapshots and their thumbnails to be of a known average size.
 * It assumes snapshots are being stored according to the following lifecycle:
 * 1. 3 Months in S3 Standard-IA.
 * 2. 7 Years (-3 Months) in S3 Glacier Deep Archive.
 * 3. Deletion.
 * Snapshot thumbnails are stored in S3 Standard for the full 7 years.
 *
 * @param snapshotCount the number of snapshots
 * @param totalSize the data size in GiB
 * @returns Numeric value representing the total cost of ownership in US Dollars ($)
 */
function calculateTCO(snapshotCount, totalSize) {
    const pricing = pricingOptions.s3CostIreland;
    const totalImageSize = totalSize ? totalSize : (snapshotCount * imageAvgSize);
    const totalThumbSize = snapshotCount * thumbnailAvgSize;

    const imageCostInStdIA = totalImageSize * pricing.storage_std_ia * 3;
    const imageTransitionCost = (snapshotCount * pricing.api_trans_glacier) / 1000;
    const imageCostInGlacier = totalImageSize * pricing.storage_glacier_da * 82.23;
    const thumbCostInStd = totalThumbSize * pricing.storage_std * 85.23;
    const imageSaveCost = (snapshotCount * pricing.api_put_std_ia) / 1000;
    const thumbSaveCost = (snapshotCount * pricing.api_put_std) / 1000;
    const imageGetCost = (snapshotCount * pricing.api_get_std_ia) / 1000;
    const thumbGetCost = (snapshotCount * pricing.api_get_std) / 1000;

    return (
        imageCostInStdIA +
        imageTransitionCost +
        imageCostInGlacier +
        thumbCostInStd +
        imageSaveCost +
        thumbSaveCost +
        imageGetCost +
        thumbGetCost
    );
}

export default { calculateTCO };

export { calculateTCO };
