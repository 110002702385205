import angular from 'angular';

export class MaterialSwitchComponent {
    /* @ngInject */
    constructor($scope) {
        this.$scope = $scope;
        this.identifier = '';
    }

    $onInit() {
        const self = this;
        if (self.unique !== undefined) {
            self.identifier = `${self.name}_${self.unique}`;
        } else {
            self.identifier = self.name;
        }

        if (self.model == undefined) self.model = false;

        // Enable/disable switch
        if (self.active == undefined) self.active = true;
    }

    $onDestroy() {

    }
}

export default angular.module('directives.materialSwitch', [])
    .component('materialSwitch', {
        template: require('./material-switch.html'),
        controller: MaterialSwitchComponent,
        controllerAs: '$ctrl',
        bindings: {
            model: '=',
            active: '=',
            leftlabel: '<',
            rightlabel: '<',
            name: '<',
            unique: '<',
            toggle: '<',
            onChange: '&',
        },
    })
    .name;
