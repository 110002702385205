import UploadController from './upload.controller';

const UploadTemplate = require('./upload.html');

export default class MetaQueryController {
    $state;
    $http;
    Auth;
    $scope;
    searching;
    $uibModal;
    $uibModalInstance;
    currentAccount;
    buttonClass = 'disabled';

    // eslint-disable-next-line spaced-comment
    /*@ngInject*/

    constructor($uibModalInstance, Auth, $http, $state, $scope, toastr, $uibModal) {
        this.$uibModalInstance = $uibModalInstance;
        this.$state = $state;
        this.toastr = toastr;
        this.Auth = Auth;
        this.$http = $http;
        this.$scope = $scope;
        this.$uibModal = $uibModal;
        this.plateNumber = undefined;
        this.errorOccurred = false;
        this.resultReturned = false;
        this.result = undefined;
    }

    doLog() {
        console.log('DEBUG:', this);
    }

    $onInit() {
        const self = this;
        self.currentAccount = self.Auth.getCurrentAccountSync().accountId;
    }

    keyPress(key) {
        if (key.which === 13) {
            this.metaQuery();
        }
    }

    metaQuery() {
        const self = this;
        this.resultReturned = false;
        this.errorOccurred = false;
        this.result = undefined;
        if (self.plateNumber && self.plateNumber !== '') {
            if (self.Auth.hasPrivilegeSync(['secuvue.plate.metagrate'])) {
                const params = {
                    plateNum: self.plateNumber,
                };
                self.$http.post('/api/plates/metagrate', params)
                    .then((response) => {
                        console.log(response);
                        if (response.data) {
                            this.resultReturned = true;
                            this.result = {
                                plate: params.plateNum,
                                status: response.data.status,
                                message: response.data.message,
                                uuid: response.data.uuid,
                            };
                        }
                    })
                    .catch((err) => {
                        console.log('VOI Request error occurred:', err);
                        self.errorOccurred = true;
                    });
            }
        }
    }

    addPlate() {
        const self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: 'static',
            keyboard: false,
            template: UploadTemplate,
            controller: UploadController,
            controllerAs: '$ctrl',
            size: 'm',
            resolve: {
                plate() {
                    return {
                        plate: self.result.plate,
                        alias: self.result.plate,
                        metagratedUUID: self.result.uuid,
                        metagratedFlagged: self.result.status === 'Y',
                    };
                },
            },
        })
            .result
            .then(
                () => {
                    self.$uibModalInstance.dismiss();
                },
                () => {},
            );
    }

    close() {
        const self = this;
        self.$uibModalInstance.dismiss();
    }
}
