const billingOptions = [
    {
        alias: 'POC',
        id: 'POC',
    },
    {
        alias: 'Active',
        id: 'active',
    },
    {
        alias: 'Inactive',
        id: 'inactive',
    },
];
const secutraqEventOptions = [
    {
        key: 'Motion',
        name: 'Motion Trigger',
    },
    {
        key: 'LineTrip',
        name: 'Line Trip',
    },
    {
        key: 'PeerCameraMotion',
        name: 'Linked Zone',
    },
    {
        key: 'PeerCameraLine',
        name: 'Linked Line',
    },
    {
        key: 'Scheduled',
        name: 'Scheduled',
    },
    {
        key: 'AccessControl',
        name: 'Access Control',
    },
    {
        key: 'PeopleDetection',
        name: 'People Detection',
    },
    {
        key: 'VehicleDetection',
        name: 'Vehicle Detection',
    },
    {
        key: 'FaceDetection',
        name: 'Face Detection',
    },
    {
        key: 'PoseExceptionDetection',
        name: 'Pose Exception Detection',
    },
];
const relayTypes = [
    {
        alias: 'Latch',
        type: 'latch',
    },
    {
        alias: 'Pulse',
        type: 'pulse',
    },
];
const faceAlgorithms = [
    {
        data: 'OpenCVHaarCascades',
        name: 'OpenCVHaarCascades',
    },
    {
        data: 'DlibHOG',
        name: 'DlibHOG',
    },
    {
        data: 'Openvino',
        name: 'Custom',
    },
];
const motionAlgorithms = [
    'OpenCVCustom', // Basic diff algorithm
    // From OpenCV's Motion Analysis collection
    'OpenCVKNN',
    'OpenCVMOG2',
    // Improved Bg/Fg Segmentation Methods
    'OpenCVMOG',
    'OpenCVCNT',
    'OpenCVGMG',
    'OpenCVGSOC',
    'OpenCVLSBP',
];
const poseClassifications = [
    {
        key: 'laying',
        text: 'Laying Down',
    },
    {
        key: 'handsup',
        text: 'Hands Up',
    },
    {
        key: 'crawling',
        text: 'Crawling',
    },
    {
        key: 'standing',
        text: 'Standing',
    },
    {
        key: 'sitting',
        text: 'Sitting',
    },
];
const availableEvents = [
    {
        key: 'motiontrigger',
        name: 'Motion Trigger',
    },
    {
        key: 'linetrip',
        name: 'Line Trip',
    },
    {
        key: 'metaFlaggedVehicle',
        name: 'SA VOI Flagged Vehicle',
    },
    {
        key: 'nocomms',
        name: 'No Comms',
    },
    {
        key: 'camdown',
        name: 'Camera Down',
    },
    {
        key: 'hddfail',
        name: 'Storage Down',
    },
    {
        key: 'flaggedVehicle',
        name: 'Plate Recognition',
    },
    {
        key: 'recognition',
        name: 'Recognition',
    },
    {
        key: 'persondetected',
        name: 'Person Detected',
    },
    // {
    //     key: 'lowbattery',
    //     name: 'Low Battery'
    // },
    // {
    //     key: 'tampers',
    //     name: 'Tampers'
    // },
    // {
    //     key: 'acfail',
    //     name: 'AC Fail'
    // },
    // {
    //     key: 'imagerequest',
    //     name: 'Image Request'
    // }
];
const liveStreamEncodingOptions = [
    'VP8',
    // 'H264',
    // 'MPEG4',
    // 'JPEG',
    // 'YUYV422',
    // 'MJPEG',
    // 'H265',
];
const availableByteUnits = [
    'MB',
    'GB',
    'TB',
];
const connectivityOptions = ['VV', 'JS Router', 'External Network'];
const ahdTypes = ['Universal', 'ITAS'];

export default {
    billingOptions,
    secutraqEventOptions,
    relayTypes,
    faceAlgorithms,
    motionAlgorithms,
    poseClassifications,
    availableEvents,
    liveStreamEncodingOptions,
    availableByteUnits,
    connectivityOptions,
    ahdTypes,
};
