import _ from 'lodash-es';
import angular from 'angular';
import EditUserModalController from './edit-user/editUser.component';
import CreateUserModalController from './create-user/createUser.component';
import ChooseRoleModalController from './choose-role/chooseRole.component';
import ChooseAccountsModalController from './choose-accounts/chooseAccounts.component';

export class UserAdminComponent {
    $http;
    $scope;
    $ngConfirm;
    /* @ngInject */
    constructor($http, $ngConfirm, $sce, NgTableParams, appConfig, Auth, $scope, toastr, $uibModal, socket) {
        this.$http = $http;
        this.$ngConfirm = $ngConfirm;
        this.$sce = $sce;
        this.NgTableParams = NgTableParams;
        this.Auth = Auth;
        this.$scope = $scope;
        this.toastr = toastr;
        this.$uibModal = $uibModal;
        this.socket = socket;
        this.defaultTTL = appConfig.tokenTTL;

        this.selectedUsers = [];
        this.currentRooms = [];
        this.users = [];
        this.newUser = {};
        this.filter = '';
        this.allAccounts = false;
    }

    $onDestroy() {
        const self = this;
        self.socket.unsyncUpdates('user');
        if (self.currentRooms.length > 0) {
            self.currentRooms.forEach((room) => {
                self.socket.leaveRoom(room);
            });
        }
    }

    userSave(item) {
        this.tableParams.reload();
    }

    $onInit() {
        const self = this;

        self.$http.get('/api/userRoles').then((response) => {
            self.userRoleOptions = response.data;
        });
        self.currentAccount = self.Auth.getCurrentAccountSync();
        self.Auth.getCurrentUser()
            .then((user) => {
                self.currentUser = user;
            });

        self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().accountId}:*:users`);
        self.currentRooms.push(`${self.Auth.getCurrentAccountSync().accountId}:*:users`);

        self.socket.socket.on('user:save', self.userSave.bind(this));
        self.socket.socket.on('user:remove', self.userSave.bind(this));

        self.selectedColumns = [];

        self.cols = [
            {
                title: 'Name',
                field: 'name',
                show: true,
                sortable: 'name',
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Email',
                field: 'username',
                show: true,
                sortable: 'username',
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Last Login',
                field: 'lastLogin',
                show: true,
                sortable: 'lastLogin',
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Login Time (s)',
                field: 'tokenTTL',
                show: true,
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Idle Time (s)',
                field: 'idleDuration',
                show: true,
                getValue: self.handleDisplay.bind(this),
            // },{
                // title: "Persistent User",
                // field: "noIdle",
                // show: true,
                // getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Accounts',
                field: 'accounts',
                show: true,
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Add/Remove Accounts',
                field: 'removeUser',
                show: true,
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Domain Restrictions',
                field: 'restrictDomains',
                show: true,
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Reset MFA',
                field: 'mfaReset',
                show: true,
                getValue: self.handleDisplay.bind(this),
            }, {
                title: 'Reset Password',
                field: 'passwordReset',
                show: true,
                getValue: self.handleDisplay.bind(this),
            },
        ];

        if (self.Auth.hasUserPrivilegeSync('debug')) {
            self.cols.push({
                title: 'Delete User',
                field: 'deleteUser',
                show: true,
                getValue: self.handleDisplay.bind(this),
            });
        }

        self.colValues = {};

        _.forEach(self.cols, (col) => {
            if (col.show) {
                self.selectedColumns.push(col.title);
            }
            self.colValues[col.field] = '';
        });

        self.tableParams = new self.NgTableParams(
            {
                page: 1, // start with first page
                count: 10, // count per page
                sorting: {
                    name: 'asc', // initial sorting
                },
            },
            {
                total: 0,
                getData(params) {
                    let order;
                    const headers = {}
                    if(self.allAccounts) headers['x-js-all-accounts'] = 'true';
                    if (params && params.sorting) {
                        order = params.sorting();
                        const dataPromise = self.$http.get('/api/users', {
                            params: {
                                filter: self.filter.length ? self.filter : undefined,
                                skip: (params.page() - 1) * params.count(),
                                limit: params.count(),
                                by: Object.keys(order)[0],
                                order: order[Object.keys(order)[0]],
                            },
                            headers
                        })
                        const countPromise = self.$http.get('/api/users/count', {
                            params: {
                                filter: self.filter.length ? self.filter : undefined,
                            },
                            headers
                        })
                        return Promise.all([dataPromise, countPromise])
                            .then((response) => {
                                self.users = response[0].data;
                                self.total = response[1].data;
                                params.total(self.total);
                                return self.users;
                            })
                            .catch((err) => {
                                console.error('Error caught when getting data for users: ', err.data.err);
                            });
                    }
                },
            },
        );
        this.tableParams.reload();
    }

    onColumnSelected($item, $model) {
        $item.show = true;
    }

    onColumnRemoved($item, $model) {
        $item.show = false;
    }

    applyFilter() {
        this.tableParams.page(1);
        this.tableParams.reload();
    }

    handleDisplay(self, col, user) {
        let html = '';
        switch (col.field) {
        case 'name':
            html = `<a ng-click="$ctrl.editUser(user)" >${user.name}</a>`;
            return self.$sce.trustAsHtml(html);

        case 'username':
            return user[col.field];

        case 'tokenTTL':
            if (user.noIdle) {
                return 'N/A';
            }
            return user[col.field];

        case 'accounts':
            html += `
                <table style="border:1px solid grey">
                <tr style="border:1px solid grey;margin-right:0.2em;">
                    <td style="font-weight:bold;border:1px solid grey;margin-right:0.2em;">
                        Role
                    </td>
                    <td style="font-weight:bold;border:1px solid grey;">
                        Accounts
                    </td>
                </tr>
                <tr style="margin-right:0.2em;" ng-repeat="role in user.roles">
                    <td style="border:1px solid grey;margin-right:0.2em;padding-bottom:0.1em;min-width:14ch;">
                        <span class="label label-primary" style="padding:6px;min-width:16ch;">
                            {{role.name}}
                        </span>
                    </td>
                    <td style="border:1px solid grey">`;

            if (user._id !== self.currentUser._id) {
                html += `<div style="display:flex; gap:3px; flex-wrap:wrap; justify-content:space-evenly; padding:3px; align-items:center;">
                            <span class="label label-info" style='cursor:pointer;text-align:center; min-width: 16ch; flex-grow: 1;' ng-click="$ctrl.changeRole(user, account)" ng-repeat="account in role.accounts">
                                {{account.name}}
                            </span>
                        </div>`;
            } else {
                html += `<div style="display:flex;gap:3px;flex-wrap:wrap;justify-content:space-evenly; padding:3px; align-items:center;">
                            <span class="label label-info" style='text-align:center; min-width:16ch; flex-grow: 1;' ng-repeat="account in role.accounts">
                                {{account.name}}
                            </span>
                        </div>`;
            }

            html += `
                    </td>
                </tr>
                </table>
                    `;
            return self.$sce.trustAsHtml(html);

        case 'mfaReset':
            if (user.mfa) {
                if (!user.mfa.enabled) {
                    html += ' <span ng-if="user._id !== $ctrl.currentUser._id">N/A</i></span>';
                } else if (!user.mfa.enrolled) {
                    html += ' <span ng-if="user._id !== $ctrl.currentUser._id">Not Enrolled</span>';
                } else {
                    html += ' <span class="cursorPointer" ng-if="user._id !== $ctrl.currentUser._id" ng-click="$ctrl.resetMFA(user)"><i class="fa fa-unlock-alt"></i></span>';
                }
            } else {
                html += ' <span ng-if="user._id !== $ctrl.currentUser._id">N/A</i></span>';
            }
            return self.$sce.trustAsHtml(html);

        case 'passwordReset':
            html += '<span class="cursorPointer" ng-if="user._id !== $ctrl.currentUser._id" ng-click="$ctrl.resetUserPassword(user)">'
                + '<i class="fa fa-unlock-alt"></i>'
                + '</span>';
            return self.$sce.trustAsHtml(html);

        case 'restrictDomains':
            if (user[col.field]) {
                html += `<span uib-tooltip="Only the following domains are allowed: ${user.allowedDomains.join(', ')}" class="fa-lg">`
                    + '<i class="fa fa-check-circle text-success"></i>'
                    + '</span>';
            } else {
                html += '<span uib-tooltip="All domains are allowed for this user." class="fa-lg">'
                    + '<i class="fa fa-ban text-danger"></i>'
                    + '</span>';
            }
            return self.$sce.trustAsHtml(html);

        case 'idleDuration':
            if (user.noIdle) {
                return 'N/A';
            }
            return user[col.field];

            // case "noIdle":
            // if(user.noIdle) {
            // return self.$sce.trustAsHtml('<span ng-click="$ctrl.changeNoIdle(user)" class="cursorPointer"><i class="fa fa-check text-success"></i></span>');
            // } else {
            // return self.$sce.trustAsHtml('<span ng-click="$ctrl.changeNoIdle(user)" class="cursorPointer"><i class="glyphicon glyphicon-remove-circle text-danger"></i></span>');
            // }
            // break;

        case 'removeUser':
            html += `<span class="cursorPointer" ng-if="user._id !== $ctrl.currentUser._id" ng-click="$ctrl.accountManagement(user)">
                        <i class="fa fa-bars"></i>
                    </span>`;
            return self.$sce.trustAsHtml(html);

        case 'deleteUser':
            html += `<span class="cursorPointer" ng-if="user._id !== $ctrl.currentUser._id" ng-click="$ctrl.deleteUser(user)">
                        <i class="fa fa-trash"></i>
                    </span>`;
            return self.$sce.trustAsHtml(html);

        case 'lastLogin':
            html += `<div uib-tooltip="${user.lastLogin}" tooltip-append-to-body="true" >`;
            html += `<span ng-if='user.lastLogin' am-time-ago='user.lastLogin'></span>
            <span ng-if='!user.lastLogin'>No login.</span>`;
            // ${self.lastHeartbeat(user)}
            html += '</div>';

            return self.$sce.trustAsHtml(html);

            // case "accounts":
            // html += `
            // <div style="display:flex;flex-wrap:wrap;max-width:100%">
            // <span class="label label-default" style="margin: 0.25em;" ng-repeat="account in user.accounts">
            // {{account.name}}
            // </span>
            // </div>
            // `
            // return self.$sce.trustAsHtml(html);

        default:
            // The default cases are those who adopt boolean values
            // "internet || chreosis || ethernet || vpn || usb || wan"
            break;
        }
        return user[col.field];
    }

    changeNoIdle(user) {
        const self = this;
        user.noIdle = !user.noIdle;
        self.$http.patch(`api/users/${user._id}`, _.pick(user, ['_id', 'noIdle']))
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    self.toastr.info('User Successfully Updated.');
                    self.$uibModalInstance.close();
                }
            });
    }

    resetMFA(user) {
        const self = this;
        const confirmReset = self.$ngConfirm({
            title: `Reset two-factor authentication for ${user.name}`,
            escapeKey: false,
            backgroundDismiss: false,
            scope: self.$scope,
            content: `Are you sure you wish to reset this user's two-factor authentication: ${user.name}?`,
            buttons: {
                enter: {
                    text: 'Yes',
                    btnClass: 'btn-blue',
                    action(scope, button) {
                        self.$http.post('api/users/mfaReset', { user: user._id })
                            .then(() => {});
                    },
                },
                close(scope, button) {
                },
            },
        });
    }

    resetUserPassword(user) {
        const self = this;
        const confirmReset = self.$ngConfirm({
            title: `Reset Password for ${user.name}`,
            escapeKey: false,
            backgroundDismiss: false,
            scope: self.$scope,
            content: `Are you sure you wish to reset this user's password: ${user.name}?`,
            buttons: {
                enter: {
                    text: 'Yes',
                    btnClass: 'btn-blue',
                    action(scope, button) {
                        self.$http.post('api/users/resetUserPassword', { user: user._id })
                            .then(() => {});
                    },
                },
                close(scope, button) {
                },
            },
        });
    }

    createUser() {
        const self = this;
        const modalInstance = self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('./create-user/createUser.html'),
            controller: CreateUserModalController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
                parent() {
                    return self;
                },
                userRoleOptions() {
                    return self.userRoleOptions;
                },
            },
        })
            .result
            .then((res) => {
                self.tableParams.reload();
            });
    }

    editUser(user) {
        const self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('./edit-user/editUser.html'),
            controller: EditUserModalController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
                user() {
                    return user;
                },
            },
        })
            .result
            .then((res) => {
                if (!res.canceled) {
                    self.toastr.success(`${user.name} Updated`);
                    self.tableParams.reload();
                }
            });
    }

    // importUser() {
    //     let self = this;
    //     self.$uibModal.open({
    //         animation: true,
    //         backdrop: true,
    //         template: require('./import-user/importUser.html'),
    //         controller: ImportUserModalController,
    //         controllerAs: '$ctrl',
    //         size: 'lg',
    //         resolve: {
    //             availableUsers() {
    //                 return self.availableUsers;
    //             },
    //             currentAccount() {
    //                 return self.currentAccount;
    //             },
    //             userRoleOptions() {
    //                 return self.userRoleOptions;
    //             }
    //         }
    //     })
    //         .result
    //         .then(res => {
    //             self.toastr.info('User Imported.');
    //             self.tableParams.reload();
    //         });
    // }

    changeRole(user, account) {
        const self = this;
        const opts = _.filter(self.userRoleOptions, (o) => o.allAccounts == true || o.accountIds.includes(account.accountId));

        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('./choose-role/chooseRole.html'),
            controller: ChooseRoleModalController,
            controllerAs: '$ctrl',
            size: 'md',
            resolve: {
                user() {
                    return user;
                },
                currentAccount() {
                    return self.currentAccount;
                },
                account() {
                    return account;
                },
                userRoleOptions() {
                    return opts;
                },
            },
        })
            .result
            .then((result) => {
                if (result && result.userId && result.roleId) {
                    self.$http.post(`api/users/${result.userId}/updateRole`, { accountId: account.accountId, userRole: result.roleId })
                        .then((response) => {
                            if (response.status === 200) {
                                self.toastr.success('User role updated successfully');
                            } else if (response.status === 204) {
                                console.log('Something is the matter, no user found');
                            }
                            self.tableParams.reload();
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                self.toastr.error('Cannot update your own role.');
                            }
                            console.error(err);
                        });
                }
            });
    }

    accountManagement(user) {
        const self = this;
        // console.log("USER: ", user);
        // console.log(self);
        const opts = _.filter(self.userRoleOptions, (o) => o.allAccounts == true);
        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('./choose-accounts/chooseAccounts.html'),
            controller: ChooseAccountsModalController,
            controllerAs: '$ctrl',
            size: 'md',
            resolve: {
                user() {
                    return user;
                },
                userRoleOptions() {
                    return opts;
                },
                currentAccount() {
                    return self.currentAccount;
                },
                currentUser() {
                    return self.currentUser;
                },
            },
        })
            .result
            .then((result) => {
                const accounts = _.map(result.accounts, 'accountId');
                self.$http.post(`api/users/${user._id}/manageAccounts`, { accounts, roleId: result.roleId }).then(() => {
                    self.tableParams.reload();
                    self.toastr.success(`Successfully Updated Accounts for ${user.name}`);
                });
            });
    }

    deleteUser(user) {
        const self = this;
        const userToBeDeleted = user.name;
        self.$ngConfirm({
            title: 'Permanently Delete User',
            escapeKey: false,
            backgroundDismiss: false,
            scope: self.$scope,
            content: `Are you sure you want to permanently remove this user: ${userToBeDeleted}?`,
            buttons: {
                enter: {
                    text: 'Yes',
                    btnClass: 'btn-blue',
                    action() {
                        self.$http.delete(`api/users/${user._id}`)
                            .then((response) => {
                                if (response) {
                                    this.toastr.info(`User ${userToBeDeleted} removed from account: `, {
                                        preventOpenDuplicates: true,
                                    });
                                }
                                self.tableParams.reload();
                            });
                    },
                },
                close() {},
            },
        });
    }

    toggleAcc() {
        const self = this;

        self.allAccounts = !self.allAccounts;
        self.tableParams.reload();
    }

    doLog() {
        console.log('DEBUG: ', this);
    }
}

_.mixin({
    unwind(o, field) {
        return _.map(o[field], (val) => {
            const cloned = _.clone(o);
            cloned[field] = val;
            return cloned;
        });
    },
});

export default angular.module('cameraViewerApp.userAdmin')
    .component('userAdmin', {
        template: require('./userAdmin.html'),
        controller: UserAdminComponent,
        controllerAs: '$ctrl',
    })
    .filter('humanizeDuration', ['moment',
        (moment) => function (value, format) {
            return moment.duration(value || 0, format || 'seconds').humanize();
        },
    ])
    .name;
