/* ------------------------------ Type Imports ------------------------------ */
// import { fabric } from 'fabric';

/**
 * Calculates the points for a perpendicular bisect line for a Fabric line.
 *
 * @param line The fabric line to bisect.
 * @param length The length of the bisect line.
 * @returns The list of points for the bisect line.
 */
// TODO Add types when moving to Angular. Webpack has a build issue if there are types.
function calculatePerpendicularBisectPoints(line, length) {
    const startX = (line.x1 + line.x2) / 2;
    const startY = (line.y1 + line.y2) / 2;
    const deltaX = -line.y1 + line.y2;
    const deltaY = line.x1 - line.x2;
    const lineLength = Math.sqrt((line.x2 - line.x1) ** 2 + (line.y2 - line.y1) ** 2);

    // TODO: Review order of operations
    return [
        startX - length * (deltaX / lineLength),
        startY - length * (deltaY / lineLength),
        startX + length * (deltaX / lineLength),
        startY + length * (deltaY / lineLength),
    ];
}

// TODO Move to export when moving to Angular
export default { calculatePerpendicularBisectPoints };
