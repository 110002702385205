export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.zoneOverview', {
            url: '/zoneOverview/:site',
            params: {
                site: { dynamic: true, value: '' },
            },
            views: {
                'content@main': {
                    template: '<zone-overview></zone-overview>',
                },
            },
            authenticate: 'SiteOverview',
            data: {
                title: 'Zones Overview',
            },
        });
}
