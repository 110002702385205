import angular from 'angular';
import uiSelect from 'ui-select';
import uiRouter from '@uirouter/angularjs';

import routes from './operationalReport.routes';

export default angular.module(
    'cameraViewerApp.operationalReport',
    [
        uiRouter,
        'sticky',
        uiSelect,
        'angularMoment',
    ],
)
    .config(routes)
    .name;

require('./operationalReport.component');
