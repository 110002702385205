import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import Restangular from 'restangular';
import routes from './signup.routes';

export default angular.module('cameraViewerApp.signup', [Restangular])
    .config(routes)
    .name;

require('./signup.component');

// TODO: Find out whether this signup.html might not be better than the jericho one
