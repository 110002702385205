import _ from 'lodash';
import angular from 'angular';
import AccountModalController from './add-account/account.modal.component';
import { v1 as uuid } from 'uuid';

// import AccountModalTemplate from "./add-account/account.modal.html"

export class AccountManagementComponent {
    $http;
    $uibModal;
    $scope;
    Auth;
    toastr;
    $ngConfirm;

    /* @ngInject */
    constructor($http, $scope, $sce, NgTableParams, $ngConfirm, $uibModal, Auth, toastr) {
        this.$http = $http;
        this.$scope = $scope;
        this.$sce = $sce;
        this.$ngConfirm = $ngConfirm;
        this.$uibModal = $uibModal;
        this.Auth = Auth;
        this.NgTableParams = NgTableParams;
        this.selectedAccount = {};
        this.availableAccounts = [];
        this.filter = '';
        this.newAccount = {};
        this.account = {};
        this.originalMetadataTemplates = {};
        this.collection = '';
        this.toastr = toastr;
        this.availableMetadataTypes = ['Dropdown', 'Date', 'Number', 'String'];
    }

    $onDestroy() {}

    $onInit() {
        const self = this;
        this.currentAccountName = this.Auth.getCurrentAccountSync().name;
        // console.log("Init Accounts Management component");

        self.keyValid = false;
        // TODO:  Get current account and check  <17-09-19, yourname> //
        if (self.Auth.hasRoleSync('secuvue.ApiCallback')) {
            self.$http
                .get('/api/accounts/currentAccount')
                .then((res) => {
                    self.account = res.data;
                    if (!self.account.metadataTemplates) {
                        self.account.metadataTemplates = [];
                    }
                    self.originalMetadataTemplates = _.clone(self.account.metadataTemplates);
                    self.checkKeyValid();
                })
                .catch((err) => {
                    console.error(err);
                });
        }

        self.cols = [
            {
                field: 'name',
                title: 'Name',
                sortable: 'name',
                show: true,
                getValue: self.handleValue.bind(self),
            },
            // {
            //     field: 'connid',
            //     title: 'Connection ID',
            //     show: true,
            //     getValue: self.handleValue.bind(self),
            // },
            {
                field: 'current',
                title: 'Current',
                show: true,
                getValue: self.handleValue.bind(self),
            },
            {
                field: 'active',
                title: 'Active',
                show: true,
                getValue: self.handleValue.bind(self),
            },
            {
                field: 'mfaEnforce',
                title: 'Two-Factor Authentication Enforced',
                show: true,
                getValue: self.handleValue.bind(self),
            },
            {
                field: 'closedCollection',
                title: 'Closed Collection for Face Recognition',
                show: true,
                getValue: self.handleValue.bind(self),
            },
        ];

        // self.$http.get('/api/accounts')
        //     .then(response => {
        //         console.log('RESPONSE: ', response.data);
        //         if (response) {
        //             self.availableAccounts = response.data.filter(o => o.active === true);
        //             console.log(self.availableAccounts);
        //         }
        //     });
        self.tableParams = new self.NgTableParams(
            {
                page: 1, // start with first page
                count: 10, // count per page
                sorting: {
                    name: 'asc', // initial sorting
                },
            },
            {
                total: 0,
                getData(params) {
                    // console.log("PARAMS: ", params);
                    let order;
                    if (params && params.sorting) {
                        order = params.sorting();
                        const dataPromise = self.$http.get('/api/accounts', {
                            params: {
                                filter: self.filter.length ? self.filter : undefined,
                                skip: (params.page() - 1) * params.count(),
                                limit: params.count(),
                                by: Object.keys(order)[0],
                                order: order[Object.keys(order)[0]],
                            },
                        });
                        const countPromise = self.$http.get('/api/accounts/count', {
                            params: {
                                filter: self.filter.length ? self.filter : undefined,
                            },
                        });
                        return Promise.all([dataPromise, countPromise])
                            .then((response) => {
                                self.accounts = response[0].data;
                                self.total = response[1].data;
                                params.total(self.total);
                                return self.accounts;
                            })
                            .catch((err) => {
                                console.error('Error when retrieving accounts:', err.data.err);
                            });
                    }
                    return null;
                },
            }
        );
    }

    handleValue(self, col, account) {
        // console.log(account);
        let html = '';
        switch (col.field) {
            case 'mfaEnforce': {
                if (account[col.field] === true) {
                    return '<i ng-show="{{account.enforceMfa}}" class="fa fa-check text-success"></i>';
                }
                return '<i ng-hide="{{account.enforceMfa}}" class="glyphicon glyphicon-remove-circle text-danger"></i>';
            }
            case 'closedCollection': {
                if (account[col.field] === true) {
                    return '<i ng-show="{{account.closedCollection}}" class="fa fa-check text-success"></i>';
                }
                return '<i ng-hide="{{account.closedCollection}}" class="glyphicon glyphicon-remove-circle"></i>';
            }
            case 'active': {
                if (account[col.field] === true) {
                    return '<i ng-show="{{account.active}}" class="fa fa-check text-success"></i>';
                }
                return '<i ng-hide="{{account.active}}" class="glyphicon glyphicon-remove-circle text-danger"></i>';
            }
            case 'name': {
                if (self.Auth.hasRoleSync('secuvue.AccountManagement.CreateAccount')) {
                    html +=
                        '<a class="cursorPointer" ng-click="$ctrl.renameAccount(account)">{{account.name}}</a>';
                } else {
                    html += '<div>{{account.name}}</div>';
                }
                return self.$sce.trustAsHtml(html);
            }
            case 'current': {
                if (self.currentAccountName === account.name) {
                    return '<i ng-show="{{account.active}}" class="fa fa-check text-success"></i>';
                }
                return '<i ng-hide="{{account.active}}" class="glyphicon glyphicon-remove-circle text-danger"></i>';
            }
            case 'removeAccount': {
                html +=
                    '<span class="cursorPointer" ng-click="$ctrl.disableAccount(account._id, account.name)">' +
                    '<i class="fa fa-times"></i>' +
                    '</span>';
                return self.$sce.trustAsHtml(html);
            }
            default:
                return '';
        }
    }

    toggleEnforceMFA() {
        const self = this;
        const enforce = self.account.mfaEnforce;
        self.$http
            .post(`/api/accounts/${self.account._id}/enforceMFA`, { enforce })
            .then((res) => {
                if (res) {
                    self.toastr.info('Account updated.', {
                        preventOpenDuplicates: true,
                    });
                    self.account = res.data;
                    // const user = self.Auth.getCurrentUserSync();
                    // if (user && user.mfa && user.mfa.enrolled) {
                    //
                    // }
                    if (self.account.mfaEnforce) {
                        self.$uibModal.open({
                            component: 'totp',
                            backdrop: 'static',
                            keyboard: false,
                            resolve: {
                                mfa: {
                                    enabled: true,
                                    enrolled: false,
                                },
                                modal: true,
                                forced: true,
                            },
                        });
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    toggleClosedCollection() {
        const self = this;
        const { closedCollection } = self.account;
        self.$http
            .patch(`api/accounts/${self.account._id}`, { closedCollection })
            .then((res) => {
                if (res) {
                    self.toastr.info('Account updated.', {
                        preventOpenDuplicates: true,
                    });
                    self.account = res.data;
                    self.tableParams.reload();
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    renameAccount(account) {
        const self = this;

        self.$ngConfirm({
            title: `Rename Account:  ${account.name}`,
            escapeKey: false,
            backgroundDismiss: false,
            scope: self.$scope,
            content: `
            <h4>What should the new account name be?</h4>
            <input ng-model="$ctrl.newName" type="text" style="width:100%"> </input>
            `,
            buttons: {
                enter: {
                    text: 'Yes',
                    btnClass: 'btn-blue',
                    action(scope, button) {
                        // console.log("NEWNAME:", self.newName);
                        self.$http
                            .patch(`/api/accounts/renameAccount/${account._id}`, {
                                newName: self.newName,
                            })
                            .then((response) => {
                                self.tableParams.reload();
                                if (response) {
                                    if (response.status === 200) {
                                        self.toastr.success(
                                            'Please refresh the webpage',
                                            'Account name changed successfully'
                                        );
                                    }
                                }
                            })
                            .catch((err) => {
                                console.error(err);
                                const codeValid = [400, 500].includes(err.status);
                                if (codeValid && err.data) {
                                    self.toastr.error(err.data, 'Could not rename account');
                                } else if (codeValid) {
                                    self.toastr.error('Could not rename account');
                                }
                            });
                    },
                },
                close(scope, button) {},
            },
        });
    }

    disableAccount(accountId, accountName) {
        const self = this;
        self.$ngConfirm({
            title: 'Disable Account',
            escapeKey: false,
            backgroundDismiss: false,
            scope: self.$scope,
            content: `Are you sure you want to disable the account: ${accountName}?`,
            buttons: {
                enter: {
                    text: 'Yes',
                    btnClass: 'btn-blue',
                    action(scope, button) {
                        self.$http
                            .patch(`/api/accounts/disableAccount/${accountId}`)
                            .then((response) => {
                                if (response) {
                                    self.toastr.info('Please refresh the page', 'Account disabled');
                                }
                            });
                    },
                },
                close(scope, button) {},
            },
        });
    }

    doLog() {
        console.log(this);
    }

    // listCollections() {
    //     let self = this;
    //     self.$http.get('/api/references/listCollection')
    //         .then((res) => {
    //             console.log(res.data);
    //         });
    // }

    // createAccount() {
    //     let self = this;
    //     let valid = true;
    //     async.each(
    //         self.availableAccounts,
    //         (acc, callback) => {
    //             if (acc.name === self.newAccount.name) {
    //                 valid = false;
    //             }
    //             callback();
    //         },
    //         () => {
    //             if (valid) {
    //                 if (this.Auth.hasUserPrivilegeSync('accountManagement')) {
    //                     self.$http.post(`api/accounts/`, { account: self.newAccount })
    //                         .then((response) => {
    //                             if (response.status == '201' && response.data) {
    //                                 self.toastr.success(`New account : ${response.data.name}`, 'Account Created:', {
    //                                     preventOpenDuplicates: true,
    //                                 });
    //                             } else {
    //                                 self.toastr.error(`The name, ${self.newAccount.name}, is already in use`, 'Name already exists', {
    //                                     preventOpenDuplicates: true,
    //                                 });
    //                             }
    //                         })
    //                         .catch((err) => {
    //                             self.toastr.warning(`ERROR : ${JSON.stringify(err.data, null, 4)}`, 'Account Create Failed:', {
    //                                 preventOpenDuplicates: true,
    //                             });
    //                         });
    //                 } else {
    //                     self.toastr.info('You require accountManagement privileges in user privileges', 'Unprivileged:', {
    //                         preventOpenDuplicates: true,
    //                     });
    //                 }
    //             } else {
    //                 self.toastr.error(`The name, ${self.newAccount.name}, is already in use`, 'Name already exists', {
    //                     preventOpenDuplicates: true,
    //                 });
    //             }
    //         },
    //     );
    // }

    updateSecutraqAPIKey() {
        const self = this;
        const { accountId } = self.Auth.getCurrentAccountSync();
        self.$http
            .patch(`api/accounts/${accountId}`, { secutraqApiKey: self.account.secutraqApiKey })
            .then((response) => {
                if (response) {
                    self.toastr.success('Key updated.');
                    self.checkKeyValid();
                } else {
                    self.toastr.error('Key update failed.');
                }
            })
            .catch((err) => {
                self.toastr.error('Key update failed.');
            });
    }

    // TEST() {
    //     this.$http.get('/api/snapshots/testRedis')
    //         .then(res => {
    //             console.log(res);
    //         });
    // }
    //
    // deleteCollection(name) {
    //     this.$http.delete(`/api/accounts/deleteColl/${name}`)
    //         .then((response) => {
    //             // console.log(response.data);
    //         });
    // }

    openCreateAccount() {
        const self = this;
        const modalInstance = self.$uibModal.open({
            controller: AccountModalController,
            controllerAs: '$ctrl',
            template: require('./add-account/account.modal.html'),
            // bindToController: true,
            size: 'md',
            backdrop: 'static',
            resolve: {
                settings() {
                    return {
                        create: true,
                    };
                },
            },
        });

        modalInstance.result.then((result) => {
            self.tableParams.reload();
        });
    }

    deleteAccount(account) {
        const self = this;
        if (this.Auth.hasUserPrivilegeSync('accountManagement')) {
            this.$http.delete(`api/accounts/${this.selectedAccount._id}`).then((response) => {
                if (response.status === 204) {
                    self.toastr.info(`Account: ${self.selectedAccount.name}`, 'Account Deleted:', {
                        preventOpenDuplicates: true,
                    });
                    _.remove(self.availableAccounts, (acc) => acc._id === self.selectedAccount._id);
                    self.selectedAccount = {};
                }
            });
        } else {
            // self.toastr.info('You require accountManagement privileges in user privileges', 'Unprivileged:',{
            //     preventOpenDuplicates:true
            // })
        }
    }

    checkKeyValid() {
        const self = this;
        this.$http
            .get('/api/accounts/secutraqAccounts')
            .then((response) => {
                self.keyValid = !!(response && response.data && response.data.length > 0);
            })
            .catch((err) => {
                self.keyValid = false;
                console.error(err);
            });
    }

    testTelegramGroup(groupId) {
        const self = this;
        self.$http
            .post('/api/accounts/telegram/test', { groupId })
            .then((response) => {
                if (response.status === 200) {
                    self.toastr.success('Telegram Test Message Sent', {
                        preventOpenDuplicates: true,
                    });
                }
            })
            .catch(() => {
                self.toastr.error('Telegram Test Failed', {
                    preventOpenDuplicates: true,
                });
            });
    }

    addMetadataTemplate() {
        if (!this.account.metadataTemplates) {
            this.account.metadataTemplates = [];
        }
        this.account.metadataTemplates.push({});
    }

    saveMetadataTemplates() {
        const self = this;
        let errorsFound = false;
        const { metadataTemplates } = self.account;

        metadataTemplates.forEach((template) => {
            const hasNoOptions = template.options === undefined || template.options?.length === 0;
            if (template.type === undefined || (template.type === 'Dropdown' && hasNoOptions)) {
                errorsFound = true;
            }
            if (template.type !== 'Dropdown' && template.options !== undefined) {
                delete template.options;
            }
        });

        if (!errorsFound) {
            self.$http
                .patch(`api/accounts/${self.account._id}`, { metadataTemplates })
                .then((res) => {
                    if (res) {
                        self.toastr.info('Account metadata updated.', {
                            preventOpenDuplicates: true,
                        });
                        self.account = res.data;
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        } else {
            self.toastr.warning('Account metadata invalid.', {
                preventOpenDuplicates: true,
            });
        }
    }

    removeMetadataTemplate(index) {
        const self = this;
        self.$ngConfirm({
            title: 'Remove Metadata Field',
            escapeKey: false,
            backgroundDismiss: false,
            scope: self.$scope,
            content: `Are you sure you want to remove the "${self.account.metadataTemplates[index].key}" field?`,
            buttons: {
                enter: {
                    text: 'Yes',
                    btnClass: 'btn-blue',
                    action(scope, button) {
                        self.account.metadataTemplates.splice(index, 1);
                    },
                },
                close(scope, button) {},
            },
        });
    }
}

export default angular.module('cameraViewerApp.accountManagement').component('accountManagement', {
    template: require('./accountManagement.html'),
    controller: AccountManagementComponent,
    controllerAs: '$ctrl',
}).name;
