export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.reference', {
            url: '/reference',
            params: { filter: null },
            views: {
                'content@main': {
                    template: '<reference></reference>',
                },
            },
            authenticate: 'FaceCollection',
            data: {
                title: 'Face Collection',
            },
        });
}
