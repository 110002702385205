export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.plate', {
            url: '/plate',
            params: { filter: null },
            views: {
                'content@main': {
                    template: '<plate></plate>',
                },
            },
            authenticate: 'Analytics',
            data: {
                title: 'Licence Plate Recognition',
            },
        });
}
