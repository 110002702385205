export default function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('main.apiCallbacks', {
            abstract: true,
            url: '/apiCallbacks',
            authenticate: 'ApiCallback',
            data: {
                title: 'API Callbacks',
            },
        })

        .state('main.apiCallbacks.list', {
            url: '/',
            views: {
                'content@main': {
                    template: '<api-callback-list></api-callback-list>',
                },
            },
            authenticate: true,
            data: {
                title: 'List',
            },
        });
}
