import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import routes from './accountManagement.routes';

export default angular.module('cameraViewerApp.accountManagement', [
    uiRouter,
])
    .config(routes)
    .name;

require('./accountManagement.component');
