import uiBootstrap from 'angular-ui-bootstrap';
import { Dropzone } from 'dropzone';
import 'dropzone/dist/dropzone.css';
import 'ngdropzone';

Dropzone.autoDiscover = false;

export default class UploadController {
    $state;
    $http;
    Auth;
    $scope;
    $uibModal;
    $uibModalInstance;
    allTags;
    reference = {};
    currentAccount;
    buttonClass = '';

    /* @ngInject */
    constructor($uibModalInstance, Auth, $http, $state, $scope, toastr, $uibModal) {
        this.$uibModalInstance = $uibModalInstance;
        this.$state = $state;
        this.toastr = toastr;
        this.Auth = Auth;
        this.$http = $http;
        this.$scope = $scope;
        this.$uibModal = $uibModal;
    }

    doLog() {
        const self = this;
        console.log('DEBUG: ', self);
    }

    $onInit() {
        const self = this;
        self.currentAccount = self.Auth.getCurrentAccountSync().accountId;

        self.dzCallbacks = {
            addedfile(file) {
                // console.log("ADDED FILE", file);
            },
            maxfilesexceeded(file) {
                self.dzMethods.removeFile(file);
                self.toastr.warning('Only one photo can be uploaded at a time', {
                    preventOpenDuplicates: true,
                });
            },
            sending(file, xhr, formData) {
                // console.log(formData);
                // console.log(self.reference);
                self.toastr.info('Uploading, Please Wait..', {
                    preventOpenDuplicates: true,
                });
                self.buttonClass = 'disabled';
                if (self.reference.alias) {
                    formData.append('alias', self.reference.alias);
                }
                if (self.reference.description) {
                    formData.append('description', self.reference.description);
                }
                if (self.reference.tags) {
                    // console.log(self.reference.tags);
                    formData.append('tags', self.reference.tags);
                }
                if (self.reference.flag) {
                    formData.append('flag', self.reference.flag);
                }
                // console.log(formData);
            },
            success(test, res) {
                if (!res.exists) {
                    self.toastr.success('Successfully added reference to database', {
                        preventOpenDuplicates: true,
                    });
                } else {
                    self.toastr.warning('Person already exists in database', {
                        preventOpenDuplicates: true,
                    });
                }
                // console.log(res);
                self.$uibModalInstance.close({});
            },
        };
        self.dzOptions = {
            url: '/api/identities/upload',
            maxFilesize: 5,
            maxFiles: 1,
            headers: {
                'X-JS-ACCOUNT': self.currentAccount,
            },
            autoProcessQueue: false,
            acceptedFiles: 'image/jpeg, image/jpg',
            addRemoveLinks: true,
            dictDefaultMessage: 'Click to add photo (1 max)',
            dictRemoveFile: 'Remove Photo',
            dictResponseError: 'Could not upload this photo',
        };
        self.dzMethods = {};
        self.$http.get('api/identities/findTags').then((response) => {
            self.allTags = response.data;
        });
    }
    $postLink() {
        const self = this;
        const dz = self.dzMethods.getDropzone();
    }

    upload() {
        const self = this;
        if (self.Auth.hasPrivilegeSync('secuvue.reference.upload')) {
            self.dzMethods.processQueue();
        } else {
            // self.toastr.error('You do not have privileges for this action', {
            // preventOpenDuplicates: true
            // });
        }
        self.$uibModalInstance.dismiss();
    }

    close() {
        const self = this;
        self.$uibModalInstance.dismiss();
    }

    removeTags(tags) {
        const label = ' (create new tag)';
        tags.forEach((tag, index) => {
            if (tag.slice(-label.length) === label) {
                tags[index] = tag.slice(0, -label.length);
            }
        });
    }
}
