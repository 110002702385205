// @flow

import angular from 'angular';

type User = {
    name: string,
    email: string,
    password: string,
};

export class LoginComponent {
    user: User = {
        name: '',
        email: '',
        password: '',
    };
    errors = {
        login: undefined,
    };
    submitted = false;
    Auth;
    $cookies;
    $state;
    $location;
    $window;
    $log;
    hostnameIcon;
    $uibModal;

    //login : Function;

    /*@ngInject*/
    constructor(Auth, $state, socket, $location, $uibModal, $window, $log, $http, $cookies) {
        this.$http = $http;
        this.socket = socket;
        this.$cookies = $cookies;
        this.Auth = Auth;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.$location = $location;
        this.$window = $window;
    }

    $onInit() {
        this.hostnameIcon = `assets/images/domainicons/${this.$window.location.hostname}.png`;
        this.errors = {};
        this.Auth.logout();

        this.user = { rememberme: true };

        this.$http
            .get(`assets/images/domainicons/${this.$window.location.hostname}.png`)
            .then(() => {
                this.hostnameIcon = `assets/images/domainicons/${this.$window.location.hostname}.png`;
            })
            .catch(() => {
                this.hostnameIcon = `assets/images/domainicons/secuvue.net.png`;
            });
    }

    clearSubmission() {
        this.submitted = false;
        this.errors.login = null;
    }

    login(form) {
        let self = this;
        self.submitted = true;

        if (form.$valid) {
            self.Auth.login({
                username: self.user.email,
                password: self.user.password,
            })
                .then((data) => {
                    if (data && data.mfaRequired && data.mfa) {
                        let modalInstance = self.$uibModal.open({
                            component: 'totp',
                            backdrop: 'static',
                            keyboard: false,
                            resolve: {
                                mfa: data.mfa,
                                modal: true,
                            },
                        });
                    } else {
                        self.socket.reAuth();
                        if (data && !data.verified) return self.$state.go('verifyRequest');
                        let landingPage = self.Auth.getLandingPage();
                        let lastPos = self.$cookies.get('lastPosition');
                        if (
                            !lastPos ||
                            lastPos === 'login' ||
                            lastPos.includes('resetpass') ||
                            lastPos.includes('verify')
                        ) {
                            lastPos = landingPage;
                        }
                        self.$state
                            .go(lastPos, { accountID: self.Auth.getCurrentAccountSync().accountId })
                            .catch((err) => {
                                console.error('Error going to last position from login:', err);
                                self.$state
                                    .go(landingPage, {
                                        accountID: self.Auth.getCurrentAccountSync().accountId,
                                    })
                                    .catch((err2) => {
                                        console.error(
                                            `Error going to ${landingPage} from login:`,
                                            err2
                                        );
                                    });
                            });
                    }
                })
                .catch((err) => {
                    console.error(`Error during login: ${err}`, err);
                    self.errors.login = err.message;
                });
        }
    }

    loginOauth(provider) {
        this.$window.location.href = `/auth/${provider}`;
    }
}

export default angular.module('cameraViewerApp.login').component('login', {
    template: require('./login.html'),
    controller: LoginComponent,
    controllerAs: '$ctrl',
}).name;
